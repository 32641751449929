import React from "react";
import { FirewatchList } from "~/components/FirewatchList";
import { useSort } from "~/pages/inventory/hooks/useSort";
import {
  AggregateScoreOrder,
  AggregateScoreOrderField,
  AggregateScoreType,
  OrderDirection,
} from "~/operations";
import { Space } from "~/lib/types";
import { AggregateScoresEdges } from "~/components/FirewatchPage";
import { Box } from "@mui/material";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";

type SoftwareVersionsTableProps = {
  space: Space;
  scope: SpaceOrWorkspaceScope;
  loading: boolean;
  edges: AggregateScoresEdges;
};

export const SoftwareVersionsTable = ({
  space,
  scope,
  loading,
  edges,
}: SoftwareVersionsTableProps) => {
  const { handleSortClick, orderBy } = useSort<AggregateScoreOrder>({
    defaultSort: {
      field: AggregateScoreOrderField.Rank,
      direction: OrderDirection.Asc,
    },
    validFields: ["RISK_VALUE", "RANK", "BLAST_RADIUS", "TITLE"],
    prefix: "versions",
  });

  return (
    <Box mt={3}>
      <FirewatchList
        field={orderBy.field}
        direction={orderBy.direction}
        {...{
          space,
          scope: scope,
          pageType: AggregateScoreType.VersionedSoftware,
          data: edges,
          handleSortClick,
          loading,
          orderBy,
        }}
      />
    </Box>
  );
};
