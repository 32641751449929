import { Link as RouterLink } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import {
  usePolicyScoreCard,
  UsePolicyScoreCardProps,
} from "../../hooks/usePolicyScoreCard";
import { PolicyResultDistribution } from "./PolicyResultDistribution";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";
import { ImpactUpdated } from "~/components/impact/Updated/impact-updated";

type Props = {
  scope: SpaceOrWorkspaceScope;
  policyScorecard: UsePolicyScoreCardProps["policyScorecard"];
  isCicd?: UsePolicyScoreCardProps["isCicd"];
};

export function PolicyScoreCard({ scope, policyScorecard, isCicd }: Props) {
  const { byMondoo, icon, policyName, policyHref, scoreStats, isPreviewMode } =
    usePolicyScoreCard({
      scope,
      policyScorecard,
      isCicd,
    });

  return (
    <Grid
      container
      item
      component={RouterLink}
      to={policyHref}
      rowGap={3}
      sx={{
        py: 3,
        borderRadius: 2,
        backgroundColor: "background.paper",
        justifyContent: "space-between",
        boxShadow: (theme) => theme.shadows[1],
        opacity: isPreviewMode ? 0.4 : 1,
      }}
    >
      <Grid container item xs={12} columnGap={1} sx={{ px: 3 }}>
        <Grid item xs="auto">
          {icon}
        </Grid>
        <Grid item xs>
          <Typography fontWeight={700}>{policyName}</Typography>
          {byMondoo && (
            <Typography
              sx={{
                color: "#BDBDBD",
                fontSize: (theme) => theme.spacing(1.5),
                fontWeight: 700,
              }}
            >
              Official - by Mondoo
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        gap={2}
        sx={{
          display: "flex",
          px: 3,
          alignItems: "flex-end",
        }}
      >
        <ImpactUpdated
          impact={{
            value: policyScorecard.score.riskValue,
            rating: policyScorecard.score.riskRating,
          }}
          isRiskScore
          hideTooltip
          contained
          isActive={!isPreviewMode}
        />
        <Grid container item columns={10}>
          {scoreStats.map((item) => (
            <Grid
              item
              xs={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
              key={item.result}
            >
              <PolicyResultDistribution result={item} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
