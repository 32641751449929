import { Box, Container, Grid2, Typography } from "@mui/material";
import { spacing } from "~/pages/reporting/reports/lib/SharedVariables";
import { SectionHeading } from "~/pages/reporting/components";
import { Stats } from "~/components/DetailsPage/components";
import { PrintScoreBlock } from "~/pages/reporting/reports/components";
import {
  CvssRiskContent,
  CvssRiskProvider,
  CvssVector,
} from "~/components/vulnerabilities";
import { RiskFactors } from "~/pages/reporting/reports/AssetReport/RiskFactors";
import { Markdown } from "~/components/markdown";
import { AffectedAssets } from "./AffectedAssets";
import { useCheckReport } from "~/pages/reporting/reports/CheckReport/hooks";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";

export const CheckReport = () => {
  const {
    title,
    description,
    hasRemediations,
    riskValue,
    rating,
    cvss,
    stats,
    failedAssets,
    passedAssets,
    riskFactorsWithDocs,
    mappings,
    remediations,
    isLoading,
    hasError,
  } = useCheckReport({});

  if (isLoading) {
    return <LoadingPage what="Check Report" />;
  }

  if (hasError) {
    return <LoadingFailedPage what="Check Report" />;
  }

  return (
    <Container sx={{ p: 4 }}>
      <Grid2 container>
        <Grid2 size={12} sx={{ mb: spacing[50] }}>
          <Typography variant="h4" fontWeight={700}>
            {title}
          </Typography>
        </Grid2>
        <Grid2 size={12}>
          <SectionHeading heading="Summary" disableUpperPadding />
        </Grid2>

        <Stats id="detail-content-stats" stats={stats} />
        <Box sx={{ position: "relative" }}>
          {riskValue != null && rating && (
            <Box
              sx={{
                float: "right",
                ml: 3,
                mb: 1,
                mt: 3.75,
              }}
            >
              <PrintScoreBlock
                mappings={mappings}
                ratingValues={{
                  value: riskValue,
                  rating: rating,
                }}
              />
            </Box>
          )}
          {description && (
            <Markdown
              source={description}
              externalLinksInNew
              copyButton={false}
              sx={{
                my: spacing[25],
                "& p": {
                  fontSize: 14,
                  fontFamily: "Roboto",
                  lineHeight: "1.43",
                  textAlign: "justify",
                },
              }}
            />
          )}
        </Box>

        <Grid2 size={12} sx={{ mb: spacing[50] }}>
          <RiskFactors dataType="check" riskFactors={riskFactorsWithDocs} />
        </Grid2>

        {cvss?.value && (
          <Grid2 size={12} sx={{ mb: spacing[50] }}>
            <CvssRiskProvider vector={cvss.vector} isPrintView>
              <SectionHeading heading="CVSS v3 score" sx={{ pb: 0 }} />
              <Box sx={{ pt: 1, pb: spacing[25] }}>
                <CvssVector />
              </Box>
              <CvssRiskContent />
            </CvssRiskProvider>
          </Grid2>
        )}

        {hasRemediations && (
          <Grid2 size={12}>
            <SectionHeading heading="Remediation" />
            <Box>
              {remediations.entries.map((remediation) => (
                <Markdown
                  key={remediation.id}
                  source={remediation.desc}
                  externalLinksInNew
                  copyButton={false}
                />
              ))}
            </Box>
          </Grid2>
        )}

        {failedAssets.length > 0 && (
          <Grid2 size={12}>
            <AffectedAssets assets={failedAssets} heading="Failed assets" />
          </Grid2>
        )}

        {passedAssets.length > 0 && (
          <Grid2 size={12} sx={{ mb: spacing[50] }}>
            <AffectedAssets assets={passedAssets} heading="Passing assets" />
          </Grid2>
        )}
      </Grid2>
    </Container>
  );
};
