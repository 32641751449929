import { useParams } from "react-router-dom";
import React, { Fragment, useEffect } from "react";
import { useSnackbar } from "notistack";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  GetWifAuthBindingsDocument,
  useCreateWifAuthBindingMutation,
} from "~/operations";
import { getError } from "~/lib/handle-error";
import {
  Box,
  Button,
  Grid2 as Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { SpaceScope } from "~/hooks/useScope";
import {
  helperTextStyles,
  isUrl,
  ValidationMessage,
} from "~/pages/integrations/validations/helpers";
import { Flex } from "~/components/Flex";
import { ChevronRightIcon } from "~/components/icons";
import { WorkloadIdentityProviderFormHeader } from "~/pages/space-settings/WorkloadIdentity/components/WorkloadIdentityProviderForm/WorkloadIdentityProviderFormHeader";
import {
  availableProviders,
  customProvider,
} from "~/pages/space-settings/WorkloadIdentity/constants";
import { WorkloadIdentityProviderFormFinalize } from "~/pages/space-settings/WorkloadIdentity/components/WorkloadIdentityProviderForm/WorkloadIdentityProviderFormFinalize";
import { setDocumentTitle } from "~/utils/commonUtils";

type IdentityProviderFormProps = {
  scope: SpaceScope;
};

type WIFBindingFormInput = {
  issuerUri: string;
  subject: string;
  expiration: number;
  name: string;
  description: string;
};

export const WorkloadIdentityProviderForm = ({
  scope,
}: IdentityProviderFormProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { type } = useParams();

  const provider =
    availableProviders.find((provider) => provider.type === type) ||
    customProvider;

  const defaultValues: WIFBindingFormInput = {
    subject: "",
    expiration: 3600,
    name: "",
    description: "",
    issuerUri: provider.issuerUrl,
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid, isSubmitSuccessful },
    getFieldState,
  } = useForm<WIFBindingFormInput>({
    mode: "onBlur",
    defaultValues,
  });

  const [createWIFAuthBinding, { data: bindingData, loading: isCreating }] =
    useCreateWifAuthBindingMutation({
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      refetchQueries: [GetWifAuthBindingsDocument],
    });

  useEffect(() => {
    reset(defaultValues);
  }, []);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(defaultValues);
    }
  }, [isSubmitSuccessful]);

  const onSubmit: SubmitHandler<WIFBindingFormInput> = async (data) => {
    try {
      await createWIFAuthBinding({
        variables: {
          input: {
            scopeMrn: scope.mrn,
            issuerUri: data.issuerUri,
            subject: data.subject,
            expiration: data.expiration,
            name: data.name.trim(),
            description: data.description.trim(),
          },
        },
      });

      enqueueSnackbar("Added workload identity provider.", {
        variant: "success",
      });
    } catch (e) {
      const msg = getError(e);
      enqueueSnackbar(msg, { variant: "error" });
    }
  };

  setDocumentTitle("Binding Setup");

  return (
    <Fragment>
      <WorkloadIdentityProviderFormHeader scope={scope} />
      {!bindingData?.createWIFAuthBinding?.config ? (
        <Box>
          <form
            onSubmit={handleSubmit(onSubmit)}
            data-testid="wif-add-provider-form"
          >
            <>
              <Box pb={4}>
                <Box>
                  <Typography fontWeight={700} sx={{ mb: 1, mt: 5 }}>
                    Issuer URL
                  </Typography>
                  <Controller
                    name="issuerUri"
                    control={control}
                    rules={{
                      required: true,
                      validate: {
                        isUrl: (value) =>
                          !value ||
                          isUrl(
                            value,
                            "This is not a valid URL. Please enter a valid issuer URL.",
                          ),
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="issuerUri-input"
                        fullWidth
                        sx={{
                          background: theme.palette.code.background,
                          borderRadius: 1,
                          color: "text.primary",
                          ...helperTextStyles,
                        }}
                        placeholder="Provide the issuer URL ..."
                        error={
                          getFieldState("issuerUri").isTouched &&
                          Boolean(errors.issuerUri)
                        }
                        helperText={
                          Boolean(errors.issuerUri) &&
                          getFieldState("issuerUri").isTouched && (
                            <ValidationMessage error={errors.issuerUri} />
                          )
                        }
                      />
                    )}
                  />
                </Box>

                <Box>
                  <Typography fontWeight={700} sx={{ mb: 1, mt: 3 }}>
                    Subject
                  </Typography>
                  <Flex sx={{ position: "relative", alignItems: "center" }}>
                    <Controller
                      name="subject"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="subject-input"
                          fullWidth
                          sx={{
                            background: theme.palette.code.background,
                            borderRadius: 1,
                            color: "text.primary",
                            ...helperTextStyles,
                          }}
                          placeholder="Provide the subject ..."
                          error={
                            getFieldState("subject").isTouched &&
                            Boolean(errors.subject)
                          }
                          helperText={
                            Boolean(errors.subject) &&
                            getFieldState("subject").isTouched && (
                              <ValidationMessage error={errors.subject} />
                            )
                          }
                        />
                      )}
                    />
                  </Flex>
                  <Typography fontWeight={700} sx={{ mb: 1, mt: 3 }}>
                    Expiration time
                  </Typography>
                  <Controller
                    name="expiration"
                    control={control}
                    render={({ field }) => (
                      <Grid size={12} sx={{ alignSelf: "flex-end" }}>
                        <Select
                          {...field}
                          fullWidth
                          sx={{
                            "&.MuiInputBase-root": {
                              bgcolor: "code.background",
                            },
                          }}
                        >
                          <MenuItem value={30 * 60}>30 minutes</MenuItem>
                          <MenuItem value={45 * 60}>45 minutes</MenuItem>
                          <MenuItem value={60 * 60}>60 minutes</MenuItem>
                        </Select>
                      </Grid>
                    )}
                  />
                  <Typography fontWeight={700} sx={{ mb: 1, mt: 3 }}>
                    Name
                  </Typography>
                  <Controller
                    name="name"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="name-input"
                        fullWidth
                        sx={{
                          background: theme.palette.code.background,
                          borderRadius: 1,
                          color: "text.primary",
                          ...helperTextStyles,
                        }}
                        placeholder="Give your provider a recognizable name ..."
                        error={
                          getFieldState("name").isTouched &&
                          Boolean(errors.name)
                        }
                        helperText={
                          Boolean(errors.name) &&
                          getFieldState("name").isTouched && (
                            <ValidationMessage error={errors.name} />
                          )
                        }
                      />
                    )}
                  />
                  <Typography fontWeight={700} sx={{ mb: 1, mt: 3 }}>
                    Description
                  </Typography>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="description-input"
                        fullWidth
                        multiline
                        rows={4}
                        sx={{
                          background: theme.palette.code.background,
                          borderRadius: 1,
                          color: "text.primary",
                          ...helperTextStyles,
                        }}
                        placeholder="Describe what the provider is used for ..."
                        error={
                          getFieldState("description").isTouched &&
                          Boolean(errors.description)
                        }
                        helperText={
                          Boolean(errors.description) &&
                          getFieldState("description").isTouched && (
                            <ValidationMessage error={errors.description} />
                          )
                        }
                      />
                    )}
                  />
                </Box>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "end", mt: 5 }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  endIcon={<ChevronRightIcon />}
                  disabled={!isValid || isCreating}
                >
                  add provider
                </Button>
              </Box>
            </>
          </form>
        </Box>
      ) : (
        <WorkloadIdentityProviderFormFinalize
          scope={scope}
          config={bindingData?.createWIFAuthBinding?.config || null}
        />
      )}
    </Fragment>
  );
};
