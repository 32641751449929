import { Box, Button, Typography } from "@mui/material";
import { Flex } from "~/components/Flex";
import { Link as RouterLink } from "react-router-dom";
import { CreateTicketingIntegrationContent } from "~/components/cases/components/CasesPage/components/CreateTicketingIntegration/CreateTicketingIntegrationContent";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";

type CreateTicketingIntegrationProps = {
  scope: SpaceOrWorkspaceScope;
  hasIntegrationCreatePermission: boolean;
};

export const CreateTicketingIntegration = ({
  scope,
  hasIntegrationCreatePermission,
}: CreateTicketingIntegrationProps) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Flex justifyContent="space-around" sx={{ mt: 10, gap: 2 }}>
        <CreateTicketingIntegrationContent />
      </Flex>
      <Flex justifyContent="center" sx={{ mt: 10 }}>
        <Button
          disabled={!hasIntegrationCreatePermission}
          component={RouterLink}
          to={`/space/integrations/add?${scope.params}&redirectTo=/space/cases?${scope.params}#ticketsystems`}
          variant="contained"
          color="primary"
          sx={{
            mt: 5,
            background: (theme) => theme.palette.primary.gradient,
            color: "common.white",
          }}
        >
          Add Integration
        </Button>
        {!hasIntegrationCreatePermission && (
          <Typography component="span" sx={{ fontSize: 10 }} color="error">
            You don't have permission to create an integration.
          </Typography>
        )}
      </Flex>
    </Box>
  );
};
