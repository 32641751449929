import { ChangeEvent, MouseEventHandler, useState } from "react";
import { SelectedEntity } from "~/components/exceptions/types";

export function useExceptionsSelection<T extends SelectedEntity>() {
  const [selectedEntities, setSelectedEntities] = useState<Array<T>>([]);

  const onCheckedChange: (checked: T[]) => void = (checked) => {
    setSelectedEntities(checked);
  };

  const isCheckChecked = (mrn: string) => {
    return Boolean(selectedEntities.find((check) => check.mrn === mrn));
  };

  const isGroupChecked = (checksArray?: T[] | null) => {
    if (!checksArray) return false;
    return checksArray.every((check) => isCheckChecked(check.mrn as string));
  };

  const isGroupIndeterminate = (checksArray?: T[] | null) => {
    if (!checksArray) return false;

    if (isGroupChecked(checksArray)) return false;
    return checksArray.some((check) => isCheckChecked(check.mrn as string));
  };

  const onGroupCheckChange = (
    _event: ChangeEvent<HTMLInputElement>,
    checksArray?: T[] | null,
  ) => {
    const paginatedChecks =
      checksArray
        ?.map((check) => ({
          mrn: check.mrn,
          exception: check.exception,
          groupId: check.groupId,
        }))
        .flatMap((mrn) => (mrn ? [mrn] : [])) || [];

    if (isGroupChecked(checksArray) || isGroupIndeterminate(checksArray)) {
      // uncheck all
      onCheckedChange(
        selectedEntities.filter(
          (check) =>
            !paginatedChecks.find((pcheck) => pcheck.mrn === check.mrn),
        ),
      );
    } else {
      // check all
      onCheckedChange([
        ...selectedEntities,
        ...paginatedChecks
          .filter(
            (pcheck) =>
              !selectedEntities.find((check) => pcheck.mrn === check.mrn),
          )
          .map(
            (pcheck) =>
              ({
                groupId: pcheck.groupId,
                mrn: pcheck.mrn,
                exception: !pcheck.exception
                  ? null
                  : {
                      justification: pcheck.exception.justification,
                      action: pcheck.exception.action,
                    },
              }) as T,
          ),
      ]);
    }
  };

  const handleCancelClick = () => {
    setSelectedEntities([]);
  };

  const handleNodeClick: MouseEventHandler = (e) => {
    e.stopPropagation();
  };

  const handleNodeChange = (
    e: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    entity: T,
  ) => {
    const nextSelected = selectedEntities.filter((s) => s.mrn !== entity.mrn);
    if (checked) {
      nextSelected.push(entity);
    }

    setSelectedEntities(nextSelected);
  };

  return {
    selectedEntities,
    setSelectedEntities,
    onCheckedChange,
    isCheckChecked,
    isGroupChecked,
    isGroupIndeterminate,
    onGroupCheckChange,
    handleCancelClick,
    handleNodeChange,
    handleNodeClick,
  };
}
