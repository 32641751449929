import { Paper, Typography } from "@mui/material";
import { pluralize } from "~/lib/pluralize";
import { ArrowUpwardIcon, AttachIcon } from "~/components/icons";
import { Flex } from "~/components/Flex";
import { Link } from "react-router-dom";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";
import { ASSET_ID_PARAM } from "~/utils/urlUtils";

type CasesSummaryProps = {
  count: number;
  scope: SpaceOrWorkspaceScope;
  assetId?: string;
};

export const CasesSummary = ({ count, scope, assetId }: CasesSummaryProps) => {
  const queryParams = new URLSearchParams(scope.params);

  if (assetId) {
    queryParams.set(ASSET_ID_PARAM, assetId);
  }

  return (
    <Link
      to={`/space/cases?${queryParams.toString()}`}
      style={{ textDecoration: "none", display: "block" }}
    >
      <Paper sx={{ px: 3, py: 1, mb: 3, cursor: "pointer" }}>
        <Flex justifyContent="space-between">
          <Flex gap={2}>
            <AttachIcon />
            <Typography fontWeight={700}>
              {count} open related {pluralize("ticket", count)}
            </Typography>
          </Flex>
          <ArrowUpwardIcon sx={{ rotate: "90deg" }} color="primary" />
        </Flex>
      </Paper>
    </Link>
  );
};
