import {
  alpha,
  Box,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { ImpactUpdated } from "~/components/impact/Updated/impact-updated";
import { getColor } from "~/lib/colors";
import { ScoreRating } from "~/operations";

type AverageRiskScoreBoxProps = {
  inPreviewMode?: boolean;
  riskValue: number;
  riskRating: ScoreRating;
  sx?: SxProps<Theme>;
};

export function AverageRiskScoreBox({
  inPreviewMode,
  riskValue,
  riskRating,
  sx = [],
}: AverageRiskScoreBoxProps) {
  const theme = useTheme();
  const colorKey = inPreviewMode ? "unknown" : riskRating;
  const color = getColor(theme, colorKey);

  return (
    <Box
      className="risk-score-box"
      sx={[
        {
          display: "flex",
          gap: 1,
          p: 1,
          backgroundColor: alpha(color, 0.1),
          borderRadius: 1,
          alignItems: "center",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box
        className="risk-score-box-score"
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Typography
          variant="h5"
          component="p"
          sx={{ textAlign: "center", fontWeight: 700, color }}
        >
          {riskValue}
        </Typography>
      </Box>
      <Box
        className="risk-score-box-rating"
        sx={{ display: "flex", alignItems: "center" }}
      >
        <ImpactUpdated
          impact={{ rating: riskRating, value: riskValue }}
          isActive={!inPreviewMode}
          isRiskScore
          hideTooltip
        />
      </Box>
    </Box>
  );
}
