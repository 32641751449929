import { Breadcrumbs, Grid, Typography } from "@mui/material";
import { FilterBar } from "~/pages/compliance/filter-bar";
import { LayoutSwitch, LayoutSwitchProps } from "../LayoutSwitch/LayoutSwitch";
import { useSearch } from "../search/useSearch";
import { Space } from "~/lib/types";
import { AdjustedAggregateScoreType } from "../FirewatchPage";
import { pageNameByType } from "../FirewatchPage/lib";
import { AggregateScoreType, DocumentType } from "~/operations";
import { useUpperSection } from "./hooks/useUpperSection";
import { Fragment } from "react";
import { breadcrumbsByType } from "~/components/FirewatchPage/lib/breadcrumbsByType";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";
import { Flex } from "../Flex";
import {
  GenerateReportButton,
  UseGenerateReportButtonProps,
} from "../../pages/compliance/components/generate-report";

type SearchProps = {
  searchId: string;
  placeholder: string;
  searchFilters: ReturnType<typeof useSearch>["searchFilters"];
  handleFilterQuery: ReturnType<typeof useSearch>["handleFilterQuery"];
};

type PageProps = {
  space: Space;
  documentType?: UseGenerateReportButtonProps["documentType"];
  scope?: SpaceOrWorkspaceScope | undefined;
  views: LayoutSwitchProps["views"];
  pageType: AdjustedAggregateScoreType;
  noEntries: string | undefined;
};

export type UpperSectionProps = {
  space: Space;
  scope?: SpaceOrWorkspaceScope | undefined;
};

type FirewatchLayoutProps = {
  searchProps: SearchProps;
  pageProps: PageProps;
};

const availableUpperSections: AdjustedAggregateScoreType[] = [
  AggregateScoreType.Asset,
  AggregateScoreType.Software,
];

export function FirewatchLayout({
  searchProps: { searchId, placeholder, searchFilters, handleFilterQuery },
  pageProps: { space, documentType, pageType, views, noEntries },
}: FirewatchLayoutProps) {
  const { upperSection } = useUpperSection({
    space,
    pageType,
  });

  const getDocumentTitle = (documentType: DocumentType) => {
    switch (documentType) {
      case DocumentType.ChecksReport:
        return "Checks Report";
      case DocumentType.SecurityReport:
        return "Assets Report";
      case DocumentType.VulnerabilitiesReport:
        return "Vulnerabilities Report";
      default:
        return "Report";
    }
  };

  const showExportButton = () => {
    // Don't show the export button for Software and Asset pages
    if (
      pageType === AggregateScoreType.Software ||
      pageType === AggregateScoreType.Asset
    ) {
      return null;
    }

    if (documentType) {
      // Show the export button only for specific document types
      // as we only support exporting these types at the moment.
      if (
        documentType === DocumentType.VulnerabilitiesReport ||
        documentType === DocumentType.ChecksReport ||
        documentType === DocumentType.SecurityReport
      ) {
        return (
          <GenerateReportButton
            documentType={documentType}
            title={getDocumentTitle(documentType)}
            space={space}
          />
        );
      }
    }
    return null;
  };

  return (
    <>
      <Breadcrumbs sx={{ mb: 3, overflowWrap: "anywhere" }} separator="›">
        {breadcrumbsByType({ space, type: pageType })}
      </Breadcrumbs>
      <Flex justifyContent="space-between" alignItems="center" sx={{ mb: 5 }}>
        <Typography
          variant="h4"
          component="h1"
          sx={{
            fontWeight: 700,
            textTransform: "uppercase",
          }}
        >
          {pageNameByType(pageType)}
        </Typography>
        {showExportButton()}
      </Flex>
      <Grid container spacing={5}>
        {availableUpperSections.includes(pageType) && (
          <Fragment>{upperSection}</Fragment>
        )}

        <LayoutSwitch
          filterBar={
            <FilterBar
              searchId={searchId}
              placeholder={placeholder}
              searchFilters={searchFilters}
              handleFilterQuery={handleFilterQuery}
              mb={0}
            />
          }
          noEntries={noEntries}
          views={views}
        />
      </Grid>
    </>
  );
}
