import { Box, Typography } from "@mui/material";
import { Format, FormatRelativeDate } from "~/lib/date";

type PublishedDateProps = {
  dateString: string | null;
  formatting?: "relative" | "absolute";
};

export const PublishedDate = ({
  dateString,
  formatting = "relative",
}: PublishedDateProps) => {
  const formatDate = (dateString: PublishedDateProps["dateString"]) => {
    if (formatting === "relative") {
      return FormatRelativeDate(dateString);
    } else {
      return Format(dateString);
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography fontSize={14}>{formatDate(dateString)}</Typography>
      <Typography variant="caption" color="text.secondary" fontSize={12}>
        {Format(dateString)}
      </Typography>
    </Box>
  );
};
