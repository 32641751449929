import { useMemo } from "react";
import * as d3 from "d3";
import { alpha, useTheme } from "@mui/material";
import { Colors, Context, Data } from "./types";
import { numberKFormat } from "~/lib/number-k-formatting";

export type DonutChartProps = {
  width: number;
  height: number;
  data: Data;
  onDatumClick?: (context: Context) => void;
  selected?: string[];
};

export const DonutChart = ({
  width,
  data,
  onDatumClick,
  selected = [],
}: DonutChartProps) => {
  const theme = useTheme();
  // restrict the width of the chart to 104px max
  let restrictedWidth = Math.min(width, 104);
  // make the height the same as the width
  const height = restrictedWidth;
  const radius = Math.min(restrictedWidth, height) / 2;

  const isClickable = Boolean(onDatumClick);

  const colors: Colors = {
    none: theme.palette.none.main,
    low: theme.palette.low.main,
    medium: theme.palette.medium.main,
    high: theme.palette.high.main,
    critical: theme.palette.critical.main,
    disabled: theme.palette.text.primary,
    error: theme.palette.error.main,
  };

  const pie = useMemo(() => {
    // Create the pie
    const pieGenerator = d3
      .pie<any, Data["context"][0]>()
      .value((d) => d.value);
    return pieGenerator(data.context);
  }, [data]);

  const arcs = useMemo(() => {
    const hasSelection = selected.length > 0;
    // Create each of the sections that make up the donut
    const arcPathGenerator = d3.arc().cornerRadius(1);
    return pie.map((p) => {
      const hasValue = p.data.value > 0;
      const isSelected = selected.includes(p.data.rating);
      const isActive =
        (hasSelection && isSelected) || (!hasSelection && hasValue);
      const isClickable = Boolean(onDatumClick) && hasValue;
      const activeFillColor = colors[p.data.rating as keyof Colors];
      const inactiveFillColor = alpha(colors.disabled, 0.3);
      const activeTextColor = theme.palette.text.primary;
      const inactiveTextColor = theme.palette.text.disabled;
      return {
        arc: arcPathGenerator({
          innerRadius: radius * 0.7,
          outerRadius: radius - 1,
          startAngle: p.startAngle,
          endAngle: p.endAngle > 0 ? p.endAngle : 2 * Math.PI,
          padAngle: 0.015,
        }),
        fillColor: isActive ? activeFillColor : inactiveFillColor,
        textColor: isActive ? activeTextColor : inactiveTextColor,
        opacity: isActive ? 1 : 0.3,
        data: p.data,
        isClickable,
      };
    });
  }, [radius, pie, data, selected]);

  return (
    <svg
      width={restrictedWidth}
      height={height}
      style={{ display: "inline-block" }}
    >
      <g transform={`translate(${restrictedWidth / 2}, ${height / 2})`}>
        {arcs.map((arc, i) => {
          return (
            <path
              key={i}
              d={arc.arc || ""}
              fill={arc.fillColor}
              opacity={arc.opacity}
              onClick={isClickable ? () => onDatumClick?.(arc.data) : undefined}
              cursor={isClickable ? "pointer" : undefined}
            />
          );
        })}
        <text
          dy="0.3em"
          style={{
            fill:
              data.total > 0
                ? theme.palette.text.primary
                : theme.palette.text.disabled,
            fontSize: 16,
            fontWeight: 900,
            textAnchor: "middle",
          }}
        >
          {numberKFormat.format(data.total || 0)}
        </text>
      </g>
    </svg>
  );
};
