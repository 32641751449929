import {
  Box,
  Checkbox,
  Grid,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { policyIcon } from "~/pages/inventory/utils/policyIcon";
import { Flex } from "~/components/Flex";
import { PreviewFlag } from "~/components/flags/preview-flag";
import { AccessTimeIcon, NavDrawerFleetIcon } from "~/components/icons";
import { FormatDate, isWithinInterval, subDays } from "~/lib/date";
import { AverageRiskScoreBox } from "~/pages/security/policies/AverageRiskScoreBox";
import { AssetResultsCell } from "~/pages/security/policies/AssetResultsCell";
import { PolicyAction } from "~/operations";
import { ActivePolicyNode } from "~/pages/security/policies/types";
import { SwitchBaseProps } from "@mui/material/internal/SwitchBase";

type PolicyType = {
  policy: ActivePolicyNode;
  canEdit?: boolean;
  onClick?: React.MouseEventHandler<HTMLTableRowElement> | undefined;
  isSelected?: boolean;
  onCheckboxSelect?: SwitchBaseProps["onChange"];
};

export const PolicyListRow = ({
  policy,
  canEdit = false,
  onClick,
  isSelected = false,
  onCheckboxSelect,
}: PolicyType) => {
  const { action, score, mrn, name, updatedAt, lastAppliedAt } = policy;

  const inPreviewMode = action === PolicyAction.Ignore;
  const isNew = lastAppliedAt
    ? isWithinInterval(new Date(lastAppliedAt), {
        start: subDays(new Date(), 7),
        end: new Date(),
      })
    : false;

  const className = isSelected ? "selected" : "";

  return (
    <TableRow
      key={mrn}
      onClick={onClick}
      className={className + " " + "policy-row"}
    >
      {canEdit && (
        <TableCell>
          <Checkbox
            checked={isSelected}
            onChange={onCheckboxSelect}
            onClick={(e) => e.stopPropagation()}
          />
        </TableCell>
      )}
      <TableCell>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {policyIcon(name, "large")}
        </Box>
      </TableCell>

      <TableCell sx={{ maxWidth: "1px!important", width: "100%" }}>
        <Box>
          <Flex alignItems="center" gap={1} mb={1}>
            <Typography
              fontWeight={700}
              className="policy-name"
              sx={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                color: inPreviewMode ? "text.disabled" : "text.primary",
              }}
            >
              {name}
            </Typography>
            {isNew && (
              <Typography
                className="new-badge"
                variant="caption"
                sx={{
                  color: "warning.main",
                  fontWeight: 600,
                  verticalAlign: "middle",
                }}
              >
                NEW
              </Typography>
            )}
          </Flex>

          <Grid item container xs={12} sx={{ columnGap: 3, rowGap: 0.5 }}>
            {inPreviewMode && (
              <Grid
                item
                xs={12}
                sm="auto"
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <PreviewFlag />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm="auto"
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {score?.blastRadius && (
                <Typography
                  className="policy-asset-count"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: (theme) => theme.spacing(1.5),
                    color: "text.secondary",
                  }}
                >
                  <NavDrawerFleetIcon fontSize="small" sx={{ mr: 1 }} />
                  {score.blastRadius.assets} Assets
                </Typography>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm="auto"
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                className="policy-last-updated"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: (theme) => theme.spacing(1.5),
                  color: "text.secondary",
                }}
              >
                <AccessTimeIcon fontSize="small" sx={{ mr: 1 }} />
                Last update: {FormatDate(updatedAt)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </TableCell>
      <TableCell>
        {score?.riskValue !== undefined && score.riskValue !== null && (
          <AverageRiskScoreBox
            inPreviewMode={inPreviewMode}
            riskValue={score.riskValue}
            riskRating={score.rating}
          />
        )}
      </TableCell>
      <AssetResultsCell
        blastRadius={score?.blastRadius}
        inPreviewMode={inPreviewMode}
      />
    </TableRow>
  );
};
