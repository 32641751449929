import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AssetAdvisoryException } from "~/operations";
import { Space } from "~/lib/types";
import { ImpactCell } from "~/components/Cells";
import { TitleCell } from "~/pages/inventory/components/Findings/FindingsTable/TitleCell";

export const AssetAdvisoryExceptionTable = ({
  exceptions,
  space,
}: {
  exceptions: AssetAdvisoryException[];
  space: Space;
}) => {
  let navigate = useNavigate();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell width="10%">Risk</TableCell>
          <TableCell>Advisory</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {exceptions.map((exception) => {
          const advisoryUrl = `/space/vulns/advisory/${exception.id}?spaceId=${space.id}`;

          const handleClick = () => {
            navigate(advisoryUrl);
          };

          return (
            <TableRow sx={{ cursor: "pointer" }} onClick={handleClick}>
              <ImpactCell
                rating={exception.rating}
                impact={exception.riskValue || 0}
                isActive
              />
              <TitleCell id={exception?.id} title={exception?.title} />
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
