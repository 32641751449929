import { Container, Grid2 } from "@mui/material";
import { Header } from "./Header";
import { ScoreDetails } from "./ScoreDetails";
import { RiskFactors } from "./RiskFactors";
import { Policies } from "./Policies";
import { Advisories } from "./Advisories";
import { Vulnerabilities } from "./Vulnerabilities";
import { spacing } from "../lib/SharedVariables";

export function AssetReport() {
  return (
    <Container sx={{ p: 4 }}>
      <Grid2 container spacing={spacing[50]}>
        <Header />
        <Grid2 size={12}>
          <ScoreDetails />
        </Grid2>
        {/* not yet possible */}
        {/* <Grid2 size={12}>
          <RiskFactors />
        </Grid2> */}
        <Grid2 size={12}>
          <Policies />
        </Grid2>
        <Grid2 size={12}>
          <Advisories />
        </Grid2>
        <Grid2 size={12}>
          <Vulnerabilities />
        </Grid2>
      </Grid2>
    </Container>
  );
}
