import { Box, Button, ButtonProps, Typography } from "@mui/material";
import { useCvssRisk } from "./useCvssRisk";
import { ExpandLessIcon, ExpandMoreIcon } from "../icons";
import { CvssVector } from "./cvss-vector";

export type CvssRiskTitle = {
  id?: string;
  open: boolean;
  onClick: ButtonProps["onClick"];
};

export function CvssRiskTitle({ id, open, onClick }: CvssRiskTitle) {
  const risk = useCvssRisk();
  return (
    <Box id={id}>
      <Button
        variant="text"
        endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        onClick={onClick}
        sx={{
          textAlign: "left",
          ml: -0.5,
          p: 0.5,
          ".MuiButton-endIcon": { color: "primary.main" },
          textTransform: "none",
        }}
      >
        <Typography fontWeight={700}>
          {`CVSS v${risk.version}`} SCORE
        </Typography>
      </Button>
      <CvssVector showTooltips={!open} />
    </Box>
  );
}
