import { Flex } from "~/components/Flex";
import { ButtonProps, Paper, Typography } from "@mui/material";
import { useSelectionRow } from "./hooks";
import { SelectionRowHeader } from "./SelectionRowHeader";
import { AddConditionRow, ConditionRow } from "../Condition";

type SelectionRowProps = {
  fieldId: string;
  selectionIndex: number;
  onRemove: ButtonProps["onClick"];
  showRemoveSelectionButton: boolean;
  isReadonly: boolean;
};

export const SelectionRow = ({
  fieldId,
  selectionIndex,
  onRemove,
  showRemoveSelectionButton,
  isReadonly,
}: SelectionRowProps) => {
  const {
    canAddCondition,
    handleAddCondition,
    handleRemoveCondition,
    fields,
    getConditionError,
    getIsConditionTouched,
  } = useSelectionRow({
    fieldId,
    selectionIndex,
  });

  return (
    <Flex flexDirection="column" gap={2}>
      {/* <SelectionRowHeader
        showRemoveSelectionButton={showRemoveSelectionButton}
        selectionIndex={selectionIndex}
        onRemove={onRemove}
      /> */}
      <Flex gap={2} flexWrap="wrap" flexDirection="column">
        {fields.map((field, conditionIndex) => {
          return (
            <Flex flexDirection="column" key={field.id}>
              <ConditionRow
                fieldId={fieldId}
                conditionIndex={conditionIndex}
                selectionIndex={selectionIndex}
                onRemove={() => handleRemoveCondition(conditionIndex)}
                isReadonly={isReadonly}
                key={field.id}
              />
              {getIsConditionTouched(conditionIndex) &&
                getConditionError(conditionIndex) && (
                  <Typography
                    variant="body2"
                    component="span"
                    color="error"
                    ml={12}
                    p={0.5}
                  >
                    {getConditionError(conditionIndex)}
                  </Typography>
                )}
            </Flex>
          );
        })}
      </Flex>
      {!isReadonly && fields.length > 0 && canAddCondition && (
        <AddConditionRow
          disabled={!canAddCondition}
          onClick={handleAddCondition}
        />
      )}
    </Flex>
  );
};
