import { TableCell } from "@mui/material";
import { BlastRadius } from "../blast/blast-radius";
import { FirewatchTooltip } from "./Tooltip";
import { BlastRadiusFieldsFragment, ScoreRating } from "~/operations";
import {
  adjustImpactColor,
  useGetImpactColorUpdated,
} from "~/components/impact/Updated/impact-result-updated";

type BlastRadiusCellProps = {
  blastRadius: BlastRadiusFieldsFragment | null | undefined;
  isActive?: boolean;
  impact: number;
  rating: ScoreRating;
};

export function BlastRadiusCell({
  blastRadius,
  isActive,
  impact,
  rating,
}: BlastRadiusCellProps) {
  // separate the blastRadius ratins from the rest of the row
  const { __typename, indicator, assets, affected, ...ratings } =
    blastRadius || {};

  // color should be same as impact
  const color = useGetImpactColorUpdated(rating);

  return (
    <TableCell>
      <BlastRadius
        radius={indicator || "none"}
        label={affected ? String(affected) : "0"}
        tooltip={<FirewatchTooltip data={ratings} />}
        color={adjustImpactColor({ color, isInactive: !isActive })}
      />
    </TableCell>
  );
}
