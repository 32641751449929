import { FormControl, MenuItem, Skeleton } from "@mui/material";
import { UseFormRegister } from "react-hook-form";
import { FormState } from "react-hook-form/dist/types/form";
import { CreateCasesFormInput } from "~/components/cases/components/CreateCaseModal/CreateCaseModal";
import { NotesField } from "~/components/cases/components/CreateCaseModal/NotesField";
import { TitleField } from "~/components/cases/components/CreateCaseModal/TitleField";
import { SelectField } from "./SelectField";
import { ZendeskTicketContextFieldsPayload } from "~/operations";

type ZendeskFields = {
  priority: ZendeskTicketContextFieldsPayload["priority"];
  type: ZendeskTicketContextFieldsPayload["type"];
};

type CreateCaseModalProps = {
  hasCreateCasePermissions: boolean;
  register: UseFormRegister<CreateCasesFormInput>;
  formState: FormState<CreateCasesFormInput>;
  fields: ZendeskFields;
  loading: boolean;
};

export const CreateCaseZendeskModal = ({
  register,
  formState,
  hasCreateCasePermissions,
  fields,
  loading,
}: CreateCaseModalProps) => {
  const { priority, type } = fields;
  const defaultPriority = priority?.some((p) => p.value === "normal")
    ? "normal"
    : "";
  const defaultType = type?.some((t) => t.value === "incident")
    ? "incident"
    : "";

  return (
    <>
      <FormControl fullWidth sx={{ mb: 3 }}>
        <TitleField
          textFieldProps={register("zendesk.title", { required: true })}
          errorMessage={formState.errors?.email?.title?.message}
          disabled={!hasCreateCasePermissions}
        />
      </FormControl>

      <FormControl fullWidth sx={{ mb: 3 }}>
        <SelectField
          title="Priority"
          loading={loading}
          errorMessage={formState.errors?.zendesk?.priority?.message}
          disabled={!hasCreateCasePermissions}
          defaultValue={defaultPriority}
          registration={register("zendesk.priority", { required: true })}
        >
          {fields.priority?.map((p) => (
            <MenuItem value={p.value} key={p.value}>
              {p.displayName}
            </MenuItem>
          ))}
        </SelectField>
      </FormControl>
      <FormControl fullWidth sx={{ mb: 3 }}>
        <SelectField
          title="Type"
          loading={loading}
          errorMessage={formState.errors?.zendesk?.zendeskType?.message}
          disabled={!hasCreateCasePermissions}
          defaultValue={defaultType}
          registration={register("zendesk.zendeskType")}
        >
          {fields.type?.map((t) => (
            <MenuItem value={t.value} key={t.value}>
              {t.displayName}
            </MenuItem>
          ))}
        </SelectField>
      </FormControl>
      <FormControl fullWidth>
        <NotesField
          errorMessage={formState.errors.zendesk?.notes?.message}
          textFieldProps={register("zendesk.notes")}
          disabled={!hasCreateCasePermissions}
        />
      </FormControl>
    </>
  );
};
