import {
  Autocomplete,
  AutocompleteProps,
  Box,
  createFilterOptions,
  TextField,
  useTheme,
} from "@mui/material";
import { getSearchPlaceholder } from "~/lib/search-placeholder";
import { KeyValueChip } from "~/components/search/search";

//TokenSearch component is used in both the Service Account and API Token settings,
// TODO: replace token search with Search component and useSearch Hook that has already been built

type Props = {
  searchFilters: string[];
  handleFilterQuery: AutocompleteProps<string, true, false, true>["onChange"];
  onDelete: (value: string) => void;
  placeholder?: string | null;
};

export function TokenSearch({
  searchFilters = [],
  handleFilterQuery,
  onDelete,
  placeholder,
}: Props) {
  const filterOptions = createFilterOptions({
    trim: true,
  });
  const theme = useTheme();

  return (
    <Box component="form" noValidate sx={{ width: 1 }}>
      <Autocomplete
        id="api-tokens-search"
        freeSolo
        multiple
        options={[]}
        value={searchFilters}
        onChange={handleFilterQuery}
        renderTags={() => null}
        {...filterOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={getSearchPlaceholder(placeholder)}
            sx={{
              background: theme.palette.code.background,
              borderRadius: 1,
              color: "text.primary",
            }}
          />
        )}
      />
      <Box>
        {searchFilters.map((value) => {
          return <KeyValueChip key={value} {...{ value, onDelete }} />;
        })}
      </Box>
    </Box>
  );
}
