import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AssetCveException } from "~/operations";
import { useAssetOutlet } from "~/pages/inventory/asset";
import { TitleCell } from "~/pages/inventory/components/Findings/FindingsTable/TitleCell";
import { ImpactCell } from "~/components/Cells";

export const AssetCveExceptionTable = ({
  exceptions,
}: {
  exceptions: AssetCveException[];
}) => {
  let navigate = useNavigate();
  const { scope, asset } = useAssetOutlet();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell width="10%">Risk</TableCell>
          <TableCell>Vulnerability</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {exceptions.map((exception) => {
          const cveUrl = `/space/inventory/${asset.id}/vulnerabilities/${exception.id}?scopeId=${scope?.id}`;

          const handleClick = () => {
            navigate(cveUrl);
          };

          return (
            <TableRow sx={{ cursor: "pointer" }} onClick={handleClick}>
              <ImpactCell
                rating={exception.rating}
                impact={exception.riskValue || 0}
                isActive
              />
              <TitleCell id={exception?.id} title={exception?.title} />
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
