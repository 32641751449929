import { AggregateScore, AggregateScoreType } from "~/operations";
import { AdjustedAggregateScoreType, EntityInfoAsset } from "..";
import { PolicyMrnToURIEncodedId } from "~/lib/mrn";

type GetLinkByTypeV2Params = {
  type: AdjustedAggregateScoreType;
  entity?: EntityInfoAsset;
  findingMrn: string;
  queryParams: URLSearchParams;
  scoreType?: AggregateScore["scoreType"];
};

export const getLinkByType = ({
  type,
  entity,
  findingMrn,
  queryParams,
  scoreType,
}: GetLinkByTypeV2Params) => {
  const id = findingMrn.split("/").pop();

  switch (type) {
    case AggregateScoreType.Asset:
      const assetId = entity?.id;
      return `/space/inventory/${assetId}?${queryParams}`;
    case AggregateScoreType.Vulnerability:
    case "NewestRelevantVulnerabilities":
      return `/space/vulns/cve/${id}?${queryParams}`;
    case AggregateScoreType.Advisory:
      return `/space/vulns/advisory/${id}?${queryParams}`;
    case AggregateScoreType.Policy:
      return `/space/findings/policies/${PolicyMrnToURIEncodedId(
        findingMrn,
      )}?${queryParams}`;
    case AggregateScoreType.Check:
      return `/space/findings/check?${queryParams}&findingMrn=${findingMrn}`;
    case AggregateScoreType.Software:
      return `/space/inventory/software/${id}?${queryParams}&genericFindingMrn=${encodeURIComponent(
        findingMrn,
      )}`;
    case AggregateScoreType.VersionedSoftware:
      return `/space/inventory/software/${id}?${queryParams}&versionedFindingMrn=${encodeURIComponent(
        findingMrn,
      )}`;
    case "AllSecurityFindings":
    case "TopSecurityFindings":
      if (scoreType && scoreType === AggregateScoreType.Check) {
        return `/space/findings/check?${queryParams}&findingMrn=${findingMrn}`;
      } else {
        return `/space/vulns/cve/${id}?${queryParams}`;
      }
    default:
      return "";
  }
};
