import { Fragment, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import {
  ExceptionType,
  FindingsOrder,
  FindingsOrderField,
  FindingType,
  GetChecksDocument,
  GetMetricsDocument,
  OrderDirection,
  ScoreRating,
  useGetChecksQuery,
} from "~/operations";
import { PolicyIdToMrn } from "~/lib/mrn";
import { KeyValueChip } from "~/components/asset-search";
import { ExceptionsToolbar } from "~/components/exceptions/exceptions-toolbar";
import { useExceptions } from "~/components/exceptions/use-exceptions";
import { ExceptionsModals } from "~/components/exceptions/exceptions-modals";
import { FilterBar } from "~/pages/compliance/filter-bar";
import { useSearch } from "~/components/search/useSearch";
import { AssetRecommendedActions } from "./assetRecommendedActions/assetRecommendedActions";
import { EmptyState } from "~/components/empty-state/empty-state";
import { useFetchExceptions } from "~/components/exceptions/use-fetch-exceptions";
import { mapSelectedEntitiesToString } from "~/components/exceptions/utils";
import { useChecksTable } from "../hooks";
import { Loading, LoadingFailed } from "~/components/loading";
import { useAssetOutlet } from "../asset";
import {
  INITIAL_PAGE_RANGE,
  Pagination,
  PaginationRange,
} from "~/components/pagination";
import { useSort } from "../hooks/useSort";
import { CheckFindingsCheckNode } from "~/pages/inventory/asset/types";
import { getAssetMrn } from "~/utils/urlUtils";
import { FindingsTable } from "~/pages/inventory/components/Findings/FindingsTable/FindingsTable";

export const formatPolicyName = (p: string, spaceId: string): string => {
  if (p?.startsWith("//policy.api.mondoo.app/spaces/")) {
    const title = p.split("/").pop();
    return `${spaceId}/${title}`;
  }
  return p?.replace("//policy.api.mondoo.app/policies/", "");
};

const isRatingFilter = (value: any): value is ScoreRating => {
  return Object.values(ScoreRating).includes(value);
};

export function AssetChecksTab() {
  const { assetId, isCicd, assetPath, asset, space } = useAssetOutlet();
  const [searchParams, setSearchParams] = useSearchParams();
  const { handleFilterQuery, searchFilters } = useSearch();
  const [pageItems, setPageItems] =
    useState<PaginationRange>(INITIAL_PAGE_RANGE);
  const jobId = searchParams.get("jobId") || "";
  const encodedPolicyMrn = searchParams.get("encodedPolicyMrn");
  const assetMrn = isCicd ? jobId : getAssetMrn(space.id, assetId);
  const policyMrn = encodedPolicyMrn
    ? PolicyIdToMrn(encodedPolicyMrn)
    : undefined;
  const ratingFilter = searchParams.get("rating")?.split(",") || [];
  const ratingFilters = ratingFilter.flatMap((i) => {
    let filter = i.toUpperCase();
    if (isRatingFilter(filter)) {
      return filter;
    } else {
      return [];
    }
  });

  const searchFilter = searchFilters.join(" ");
  const policyFilter = policyMrn ? policyMrn : undefined;
  const hasFilters =
    searchFilter.length > 0 ||
    ratingFilter.length > 0 ||
    Boolean(policyFilter?.length);

  const { orderBy, handleSortClick } = useSort<FindingsOrder>({
    defaultSort: {
      field: FindingsOrderField.RiskValue,
      direction: OrderDirection.Desc,
    },
    validFields: [FindingsOrderField.RiskValue],
  });

  const { data, error, fetchMore, networkStatus } = useGetChecksQuery({
    variables: {
      scopeMrn: assetMrn,
      first: 10,
      after: null,
      orderBy,
      filter: {
        includeCicd: isCicd,
        types: [FindingType.Check],
        policyMrn: policyFilter,
        queryTerms: searchFilters,
        rating: ratingFilters,
      },
    },
    skip: !assetMrn,
  });

  const checksFetching = networkStatus === 1;
  const checksRefetching = networkStatus === 2;
  const checksLoading = checksFetching || checksRefetching;
  const findingsUnion =
    data?.findings?.__typename === "FindingsConnection"
      ? data.findings
      : undefined;
  const checksTotalCount = findingsUnion?.totalCount ?? 0;
  const checkEdges = findingsUnion?.edges || [];
  const hasChecks = !checksFetching && checksTotalCount > 0;
  const checkFindings: CheckFindingsCheckNode[] = checkEdges.flatMap((e) =>
    e?.node?.__typename === "CheckFinding" ? e.node : [],
  );

  const {
    hasApplyExceptionMutationPermission,
    setSelectedChecks,
    selectedChecks,
    checkHandle,
  } = useChecksTable({
    sort: orderBy,
    availablePermissions: space.iamActions,
    checkEdges: checkFindings,
  });

  const { exceptionGroups } = useFetchExceptions({
    scopeMrn: assetMrn,
    types: [ExceptionType.Security],
  });

  const {
    isRemovingException,
    isSettingException,
    handleSetExceptionModalOpen,
    handleSetExceptionModalClose,
    handleRemoveExceptionModalOpen,
    handleRemoveExceptionModalClose,
    handleRemoveException,
    handleSetException,
    loading,
  } = useExceptions({
    onSetException: () => {
      setSelectedChecks([]);
    },
    onRemoveException: () => {
      setSelectedChecks([]);
    },
    scopeMrns: [assetMrn],
    queryMrns: mapSelectedEntitiesToString(selectedChecks),
    refetchQueries: [GetChecksDocument, GetMetricsDocument],
  });

  if (error && !findingsUnion) {
    return (
      <Box sx={{ display: "flex", placeContent: "center", p: 3 }}>
        <LoadingFailed what="checks" />
      </Box>
    );
  }

  const handlePolicyFilterDelete = () => {
    searchParams.delete("encodedPolicyMrn");
    setSearchParams(searchParams);
  };

  return (
    <Fragment>
      <Grid container mt={3} rowGap={3}>
        {hasChecks && (
          <Grid item xs={12}>
            <AssetRecommendedActions {...{ asset, assetPath, isCicd }} />
          </Grid>
        )}

        {hasChecks && (
          <Grid item xs={12}>
            <FilterBar
              searchId="inventory-asset-checks"
              placeholder="inventory_asset_checks"
              searchFilters={searchFilters}
              handleFilterQuery={handleFilterQuery}
              mb={0}
            />
            {encodedPolicyMrn && (
              <KeyValueChip
                keyValue={{ key: "Policy", value: encodedPolicyMrn }}
                onDelete={handlePolicyFilterDelete}
              />
            )}
          </Grid>
        )}

        {checksLoading ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              placeContent: "center",
              py: 10,
            }}
          >
            <Loading what="checks" />
          </Box>
        ) : (
          <Fragment>
            {hasChecks && (
              <Grid item xs={12}>
                <FindingsTable
                  onSort={handleSortClick}
                  orderBy={orderBy}
                  findings={checkFindings}
                  fetchMore={fetchMore}
                  totalCount={findingsUnion?.totalCount ?? 0}
                  pageInfo={findingsUnion?.pageInfo}
                  assetMrn={assetMrn}
                  availablePermissions={space.iamActions}
                  context="check"
                />
                <Pagination
                  fetchMore={fetchMore}
                  pageInfo={findingsUnion?.pageInfo}
                  totalCount={checksTotalCount}
                  setPageItems={setPageItems}
                />
                {selectedChecks.length > 0 && (
                  <ExceptionsToolbar
                    target="check"
                    onCancel={checkHandle.cancelClick}
                    onRemoveExceptionClick={handleRemoveExceptionModalOpen}
                    onSetExceptionClick={handleSetExceptionModalOpen}
                    selectedEntities={selectedChecks}
                    totalCount={checksTotalCount}
                  />
                )}
              </Grid>
            )}
            {!hasChecks && (
              <Grid item xs={12}>
                <EmptyState contentType="asset-checks-tab" space={space} />
              </Grid>
            )}

            <ExceptionsModals
              isSetExceptionModalOpen={isSettingException}
              isRemoveExceptionModalOpen={isRemovingException}
              onRemoveExceptionModalClose={handleRemoveExceptionModalClose}
              onSetExceptionModalClose={handleSetExceptionModalClose}
              onSetExceptionModalSave={handleSetException}
              onRemoveExceptionModalSave={handleRemoveException}
              loading={loading}
              target="check"
              role="security"
              exceptionGroups={exceptionGroups}
              selectedEntities={selectedChecks}
            />
          </Fragment>
        )}
      </Grid>
    </Fragment>
  );
}
