import { Fragment, useEffect, useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Box, Breadcrumbs, Grid, Link, Typography } from "@mui/material";
import { TestIamActionsQuery } from "~/operations";

import { HomeIcon } from "~/components/icons";
import { TabPanel } from "~/components/tab-panel";

import { AppDetail } from "./integration-setup-detail";
import appsData from "./integration-setup.json";

import { IamActions } from "~/lib/iam";
import { Space } from "~/lib/types";
import {
  isChatIntegrationName,
  SpaceIntegrationSection,
} from "./settings-integrations";
import { ScrollToTop } from "~/lib/scroll-to-top";
import { Workstation } from "./integrations/workstation";
import { CiCdIntegrationAdd } from "./integrations/cicd-integrations";
import { OperatorConfiguration } from "./integrations/kubernetes/operator-configuration/operator-configuration";
import { AzureIntegrationForm } from "./integrations/hosted-integrations/forms/azure";
import { AzureBlobIntegrationForm } from "./integrations/hosted-integrations/forms/azure-blob";
import { GcpIntegrationForm } from "./integrations/hosted-integrations/forms/gcp";
import { Ms365IntegrationForm } from "./integrations/hosted-integrations/forms/ms365";
import { OktaIntegrationForm } from "./integrations/hosted-integrations/forms/okta";
import { GoogleWorkspaceIntegrationForm } from "./integrations/hosted-integrations/forms/google-workspace";
import { BigQueryIntegrationForm } from "./integrations/hosted-integrations/forms/bigquery";
import { SnowflakeIntegrationForm } from "./integrations/hosted-integrations/forms/snowflake";
import { S3IntegrationForm } from "./integrations/hosted-integrations/forms/s3";
import { S3CompatibleIntegrationForm } from "./integrations/hosted-integrations/forms/s3-compatible";
import { GitlabIntegrationForm } from "./integrations/hosted-integrations/forms/gitlab";
import { GithubIntegrationForm } from "./integrations/hosted-integrations/forms/github";
import { SlackIntegrationForm } from "./integrations/hosted-integrations/forms/slack";
import { GcsBucketIntegrationForm } from "./integrations/hosted-integrations/forms/gcs-bucket";
import { PostgreSQLIntegrationForm } from "./integrations/hosted-integrations/forms/postgresql";
import { JiraIntegrationForm } from "./integrations/hosted-integrations/forms/jira/jira";
import { OciIntegrationForm } from "./integrations/hosted-integrations/forms/oci";
import { DomainIntegrationForm } from "./integrations/hosted-integrations/forms/domain";
import { AwsIntegrationForm } from "./integrations/hosted-integrations/forms/aws";
import { EmailIntegrationForm } from "~/pages/integrations/hosted-integrations/forms/email/email";
import { ZendeskIntegrationForm } from "./integrations/hosted-integrations/forms/zendesk/zendesk";
import { MsftDefenderIntegrationForm } from "./integrations/hosted-integrations/forms/msft-defender";
import { GithubIssuesIntegrationForm } from "./integrations/hosted-integrations/forms/github-issues/github-issues";
import { GitlabIssuesIntegrationForm } from "./integrations/hosted-integrations/forms/gitlab-issues/gitlab-issues";
import { AzureDevOpsIntegrationForm } from "./integrations/hosted-integrations/forms/azure-devops/azure-devops";
import { ShodanIntegrationForm } from "./integrations/hosted-integrations/forms/shodan";
import { SentinelOneIntegrationForm } from "./integrations/hosted-integrations/forms/SentinelOne";
import { CrowdStrikeIntegrationForm } from "./integrations/hosted-integrations/forms/CrowdStrike";
import { setDocumentTitle } from "~/utils/commonUtils";

export type AppsData = typeof appsData;
export type AppDataKey = keyof AppsData;
export type App = AppsData[AppDataKey];

function isAppDataKey(value: any): value is AppDataKey {
  return Object.keys(appsData).includes(value);
}

interface IntegrationSetupPageProps {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
}

export function IntegrationSetupPage({
  space,
  availablePermissions,
}: IntegrationSetupPageProps) {
  const { appName, integrationTypeId } = useParams();
  const [selectedTab, setSelectedTab] = useState<string>(
    integrationTypeId ? `${appName}/${integrationTypeId}` : "mondoo/setup",
  );
  const label = selectedTab.split("/").pop() || "Setup";
  const integrationLabel = transformIntegrationLabel(selectedTab);

  setDocumentTitle([integrationLabel, "Integrations Setup"]);

  useEffect(() => {
    // Hack to allow us to route from nested integration pages.
    if (integrationTypeId && appName) {
      setSelectedTab(`${appName}/${integrationTypeId}`);
    }
  }, [appName, integrationTypeId]);

  const tabGroups = {
    Workstation: ["mondoo/setup"],
    "Server & Endpoint Security": [
      "mondoo/redhat",
      "mondoo/windows",
      "mondoo/ubuntu",
      "mondoo/amazonlinux",
      "mondoo/suse",
      "mondoo/macos",
      "mondoo/host",
    ],
    "Cloud Security": [
      "mondoo/aws",
      "mondoo/azure",
      "mondoo/gcp",
      "mondoo/kubernetes",
      "mondoo/vmware",
      "mondoo/oci",
    ],
    "Third Party": [
      "mondoo/defender",
      "mondoo/sentinelone",
      "mondoo/crowdstrike",
    ],
    Exports: [
      "mondoo/bigquery",
      "mondoo/gcs_bucket",
      "mondoo/snowflake",
      "mondoo/amazons3",
      "mondoo/amazon-s3-compatible",
      "mondoo/postgres",
      "mondoo/azure_blob",
    ],
    SaaS: [
      "mondoo/ms365",
      "mondoo/okta",
      "mondoo/google_workspace",
      "mondoo/slack",
      "mondoo/github",
      "mondoo/gitlab",
      "mondoo/ticket_system_jira",
      "mondoo/ticket_system_email",
      "mondoo/ticket_system_zendesk",
      "mondoo/ticket_system_github",
      "mondoo/ticket_system_gitlab",
      "mondoo/ticket_system_azure_devops",
    ],
    "Network Security": ["mondoo/shodan"],
    "Software Supply Chain": [
      "hashicorp/packer",
      "hashicorp/terraform",
      "mondoo/credentials",
    ],
    "CI/CD": [
      "cicd/gitlab",
      "cicd/github",
      "cicd/kubernetes",
      "cicd/circleci",
      "cicd/azure",
      "cicd/jenkins",
    ],
    "Chat Ops": [
      "chat/msteams",
      "chat/slack",
      "chat/telegram",
      "chat/httppost",
    ],
  };

  const hasIntegrationCreatePermission = availablePermissions?.includes(
    IamActions.INTEGRATIONS_INTEGRATIONSMANAGER_CREATE,
  );
  // If not permitted to create an AWS integration
  if (!hasIntegrationCreatePermission) {
    const cloudTabs = tabGroups["Cloud Security"];
    // Remove AWS from available options
    cloudTabs.splice(cloudTabs.indexOf("mondoo/aws"), 1);
  }

  const renderContentTabSwitch = (selectedTab: string) => {
    let type = selectedTab.split("/").pop();
    const app = isAppDataKey(selectedTab) ? appsData[selectedTab] : null;

    switch (selectedTab) {
      case "mondoo/setup":
        return <Workstation product="mondoo" />;
      case "mondoo/aws":
      case "mondoo/aws_hosted":
        return <AwsIntegrationForm space={space} />;
      case "mondoo/kubernetes":
        return (
          <OperatorConfiguration {...{ space }} setup={appsData[selectedTab]} />
        );
      case "cicd/gitlab":
      case "cicd/github":
      case "cicd/kubernetes":
      case "cicd/circleci":
      case "cicd/azure":
      case "cicd/jenkins":
        return <CiCdIntegrationAdd {...{ space, type: type! }} />;
      case "chat/msteams":
      case "chat/slack":
      case "chat/telegram":
      case "chat/httppost":
        return isChatIntegrationName(label) ? (
          <SpaceIntegrationSection
            {...{ space, availablePermissions }}
            view={label}
          />
        ) : (
          <Fragment />
        );

      case "mondoo/azure":
        return (
          <AzureIntegrationForm
            {...{ space, type: type!, availablePermissions }}
          />
        );

      case "mondoo/azure_blob":
        return <AzureBlobIntegrationForm {...{ space, type: type! }} />;

      case "mondoo/bigquery":
        return <BigQueryIntegrationForm {...{ space, type: type! }} />;

      case "mondoo/gcp":
        return <GcpIntegrationForm {...{ space, type: type! }} />;

      case "mondoo/ms365":
        return <Ms365IntegrationForm {...{ space, type: type! }} />;

      case "mondoo/okta":
        return <OktaIntegrationForm {...{ space, type: type! }} />;

      case "mondoo/google_workspace":
        return <GoogleWorkspaceIntegrationForm {...{ space, type: type! }} />;

      case "mondoo/snowflake":
        return <SnowflakeIntegrationForm {...{ space, type: type! }} />;

      case "mondoo/amazons3":
        return <S3IntegrationForm {...{ space, type: type! }} />;

      case "mondoo/amazon-s3-compatible":
        return <S3CompatibleIntegrationForm {...{ space, type: type! }} />;

      case "mondoo/defender":
        return <MsftDefenderIntegrationForm {...{ space, type: type! }} />;

      case "mondoo/sentinelone":
        return <SentinelOneIntegrationForm {...{ space, type: type! }} />;

      case "mondoo/crowdstrike":
        return <CrowdStrikeIntegrationForm {...{ space, type: type! }} />;

      case "mondoo/shodan":
        return <ShodanIntegrationForm {...{ space, type: type! }} />;

      case "mondoo/github":
        return <GithubIntegrationForm {...{ space, type: type! }} />;

      case "mondoo/gitlab":
        return <GitlabIntegrationForm {...{ space, type: type! }} />;

      case "mondoo/slack":
        return <SlackIntegrationForm {...{ space, type: type! }} />;

      case "mondoo/gcs_bucket":
        return <GcsBucketIntegrationForm {...{ space, type: type! }} />;

      case "mondoo/postgres":
        return <PostgreSQLIntegrationForm {...{ space, type: type! }} />;

      case "mondoo/oci":
        return <OciIntegrationForm {...{ space, type: type! }} />;

      case "mondoo/host":
        return <DomainIntegrationForm {...{ space, type: type! }} />;

      case "mondoo/ticket_system_jira":
        return <JiraIntegrationForm {...{ space, type: type! }} />;

      case "mondoo/ticket_system_email":
        return <EmailIntegrationForm {...{ space, type: type! }} />;

      case "mondoo/ticket_system_zendesk":
        return <ZendeskIntegrationForm {...{ space, type: type! }} />;

      case "mondoo/ticket_system_github":
        return <GithubIssuesIntegrationForm {...{ space, type: type! }} />;

      case "mondoo/ticket_system_gitlab":
        return <GitlabIssuesIntegrationForm {...{ space, type: type! }} />;

      case "mondoo/ticket_system_azure_devops":
        return <AzureDevOpsIntegrationForm {...{ space, type: type! }} />;

      default:
        return (
          <AppDetail
            item={app}
            space={space}
            availablePermissions={availablePermissions || []}
          />
        );
    }
  };

  const tabPanels = Object.values(tabGroups)
    .flat()
    .map((id, _index) => (
      <TabPanel value={selectedTab} index={id} key={id}>
        {renderContentTabSwitch(id)}
      </TabPanel>
    ));

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?spaceId=${space.id}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Link
      key="/space/integrations"
      component={RouterLink}
      to={`/space/integrations?spaceId=${space.id}`}
    >
      Integrations
    </Link>,
    <Link
      key="/space/integrations"
      component={RouterLink}
      to={`/space/integrations/add?spaceId=${space.id}`}
    >
      Add
    </Link>,
    <Typography key={1}>{transformIntegrationLabel(selectedTab)}</Typography>,
  ];

  return (
    <Fragment>
      <ScrollToTop />
      <Box>
        <Breadcrumbs sx={{ mb: 3 }} separator="›">
          {breadcrumbs}
        </Breadcrumbs>
        <Grid container>
          <Grid item xs>
            {tabPanels}
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
}

export const stackUrl = (
  cloudFormationTemplateUrl: string,
  originAWSAccountID: string,
  region: string,
  mrn: string,
  token?: string | null,
  snsEndpoint?: string | null,
  isOrgInstall?: boolean | null,
) => {
  const stack = {
    name: "mondoo",
    region,
    templateURL: cloudFormationTemplateUrl,
    stackType: isOrgInstall ? "stacksets" : "stacks",
  };
  if (isOrgInstall) {
    return `https://${region}.console.aws.amazon.com/cloudformation/home?region=${region}#/${stack.stackType}/create`;
  }
  return `https://${region}.console.aws.amazon.com/cloudformation/home?region=${region}#/${stack.stackType}/create/review?templateURL=${stack.templateURL}&stackName=${stack.name}&param_OriginAwsAccount=${originAWSAccountID}&param_MondooIntegrationMrn=${mrn}&param_MondooToken=${token}&param_MondooSnsHandler=${snsEndpoint}`;
};

export const stacksUrl = (region: string) => {
  return `https://${region}.console.aws.amazon.com/cloudformation/home?region=${region}#/stacks?filteringText=mondoo`;
};

export const AWS_REGIONS = [
  "ap-northeast-1",
  "ap-northeast-2",
  "ap-northeast-3",
  "ap-south-1",
  "ap-southeast-1",
  "ap-southeast-2",
  "ca-central-1",
  "eu-central-1",
  "eu-north-1",
  "eu-west-1",
  "eu-west-2",
  "eu-west-3",
  "ap-south-1",
  "sa-east-1",
  "us-east-1",
  "us-east-2",
  "us-west-1",
  "us-west-2",
];

// transform url parameter based labels to human friendly labels
const transformIntegrationLabel = (selectedTab: string): string => {
  switch (selectedTab.toLowerCase()) {
    case "chat/httppost":
      return "Outbound Webhook";
    case "cicd/azure":
      return "Azure Pipelines";
    case "mondoo/azure_blob":
      return "Azure Blob Storage";
    case "cicd/circleci":
      return "CircleCI";
    case "cicd/gcp":
      return "GCP";
    case "cicd/github":
      return "GitHub Actions";
    case "cicd/gitlab":
      return "GitLab CI/CD";
    case "mondoo/gitlab":
      return "GitLab";
    case "mondoo/aws":
      return "AWS";
    case "mondoo/amazonlinux":
      return "Amazon Linux";
    case "mondoo/amazons3":
      return "Amazon S3";
    case "mondoo/amazon-s3-compatible":
      return "S3 Compatible Service";
    case "mondoo/bigquery":
      return "BigQuery";
    case "mondoo/defender":
      return "Microsoft Defender for Cloud";
    case "mondoo/host":
      return "Domain/IP Address";
    case "mondoo/gcp":
      return "Google Cloud Platform";
    case "mondoo/gcs_bucket":
      return "GCP Cloud Storage Bucket";
    case "mondoo/github":
      return "GitHub";
    case "mondoo/ticket_system_jira":
      return "Atlassian Jira";
    case "mondoo/ticket_system_email":
      return "Ticketing via email";
    case "mondoo/ticket_system_zendesk":
      return "Zendesk";
    case "mondoo/ticket_system_github":
      return "GitHub Issues";
    case "mondoo/ticket_system_gitlab":
      return "GitLab Issues";
    case "mondoo/ticket_system_azure_devops":
      return "Azure DevOps";
    case "mondoo/redhat":
      return "Red Hat/Fedora";
    case "mondoo/macos":
      return "macOS";
    case "mondoo/ms365":
      return "MS 365";
    case "mondoo/okta":
      return "Okta";
    case "mondoo/oci":
      return "Oracle Cloud Infrastructure";
    case "mondoo/google_workspace":
      return "Google Workspace";
    case "chat/msteams":
      return "Microsoft Teams";
    case "mondoo/postgres":
      return "PostgreSQL";
    case "mondoo/setup":
      return "Local Installation";
    case "mondoo/sentinelone":
      return "SentinelOne";
    case "mondoo/suse":
      return "SUSE/openSUSE";
    case "mondoo/ubuntu":
      return "Ubuntu/Debian";
    case "mondoo/vmware":
      return "VMware";
    default:
      const label = selectedTab.split("/").pop() || "";
      return label[0].toUpperCase() + label.slice(1);
  }
};
