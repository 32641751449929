import React from "react";
import {
  Box,
  Button,
  Link,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Command } from "~/components/guides/components";
import { Control, Controller } from "react-hook-form";
import {
  helperTextStyles,
  ValidationMessage,
} from "~/pages/integrations/validations/helpers";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { UseFormGetFieldState } from "react-hook-form/dist/types/form";
import { ChevronRightIcon } from "~/components/icons";
import { Flex } from "~/components/Flex";
import { AzureDevOpsFormInput } from "~/pages/integrations/hosted-integrations/forms/azure-devops/types";

type AzureFormStep1Props = {
  control: Control<AzureDevOpsFormInput>;
  errors: FieldErrors<AzureDevOpsFormInput>;
  isUpdateFlow: boolean;
  isValid: boolean;
  getFieldState: UseFormGetFieldState<AzureDevOpsFormInput>;
  disabled: boolean;
};

export const AzureDevOpsFormStep1 = ({
  control,
  errors,
  isUpdateFlow,
  getFieldState,
  isValid,
}: AzureFormStep1Props) => {
  const theme = useTheme();

  return (
    <>
      {/* Step 1 */}
      <Box pb={4}>
        <Command
          number={1}
          options={{
            fontSize: { xs: 16 },
            dotColor: theme.palette.background.lightest,
          }}
        >
          Choose an integration name
        </Command>

        <Box>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ mb: 2, mt: 2 }}
          >
            Please choose a descriptive name that lets you easily identify your
            integration.
          </Typography>
          <Controller
            name="integrationName"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                sx={{
                  background: theme.palette.code.background,
                  borderRadius: 1,
                  color: "text.primary",
                  ...helperTextStyles,
                }}
                placeholder="Your integration name..."
                error={Boolean(errors.integrationName)}
                helperText={
                  Boolean(errors.integrationName) && (
                    <ValidationMessage error={errors.integrationName} />
                  )
                }
              />
            )}
          />
        </Box>
      </Box>

      {/* Step 2 */}
      <Box pb={4}>
        <Command
          number={2}
          options={{
            fontSize: { xs: 16 },
            dotColor: theme.palette.background.lightest,
          }}
        >
          Provide the Azure DevOps organization URL
        </Command>

        <Box>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ mb: 2, mt: 2 }}
          >
            This is also referred to as the “OrgURL” in Azure DevOps. To learn
            more, read the{" "}
            <Link
              href="https://mondoo.com/docs/platform/ticketing/setup-azuredevops/"
              target="_blank"
              rel="noopener"
              sx={{ color: "inherit", textDecoration: "underline" }}
            >
              Mondoo documentation
            </Link>
            .
          </Typography>
          <Flex sx={{ position: "relative", alignItems: "center" }}>
            <Controller
              name="organizationUrl"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => {
                return (
                  <>
                    <TextField
                      {...field}
                      fullWidth
                      disabled={Boolean(isUpdateFlow)}
                      sx={{
                        background: theme.palette.code.background,
                        borderRadius: 1,
                        color: "text.primary",
                        ...helperTextStyles,
                      }}
                      placeholder="https://dev.azure.com/your-url-here"
                      error={
                        getFieldState("organizationUrl").isTouched &&
                        Boolean(errors.organizationUrl)
                      }
                      helperText={
                        Boolean(errors.organizationUrl) &&
                        getFieldState("organizationUrl").isTouched && (
                          <ValidationMessage error={errors.organizationUrl} />
                        )
                      }
                    />
                  </>
                );
              }}
            />
          </Flex>
        </Box>
      </Box>

      {/* Step 3 */}
      <Box pb={4}>
        <Command
          number={3}
          options={{
            fontSize: { xs: 16 },
            dotColor: theme.palette.background.lightest,
          }}
        >
          Enter the directory ID
        </Command>

        <Box>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ mb: 2, mt: 2 }}
          >
            This is also referred to as the “tenant ID” in Azure DevOps. To
            learn more, read the{" "}
            <Link
              href="https://mondoo.com/docs/platform/ticketing/setup-azuredevops/"
              target="_blank"
              rel="noopener"
              sx={{ color: "inherit", textDecoration: "underline" }}
            >
              Mondoo documentation
            </Link>
            .
          </Typography>
          <Flex sx={{ position: "relative", alignItems: "center" }}>
            <Controller
              name="tenantId"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => {
                return (
                  <>
                    <TextField
                      {...field}
                      fullWidth
                      sx={{
                        background: theme.palette.code.background,
                        borderRadius: 1,
                        color: "text.primary",
                        ...helperTextStyles,
                      }}
                      placeholder="XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX"
                      error={
                        getFieldState("tenantId").isTouched &&
                        Boolean(errors.tenantId)
                      }
                      helperText={
                        Boolean(errors.tenantId) &&
                        getFieldState("tenantId").isTouched && (
                          <ValidationMessage error={errors.tenantId} />
                        )
                      }
                    />
                  </>
                );
              }}
            />
          </Flex>
        </Box>
      </Box>

      {/* Step 4 */}
      <Box pb={4}>
        <Command
          number={4}
          options={{
            fontSize: { xs: 16 },
            dotColor: theme.palette.background.lightest,
          }}
        >
          Provide the service principal ID
        </Command>

        <Box>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ mb: 2, mt: 2 }}
          >
            This is also referred to as the “client ID” in Azure DevOps. To
            learn more, read the{" "}
            <Link
              href="https://mondoo.com/docs/platform/ticketing/setup-azuredevops/"
              target="_blank"
              rel="noopener"
              sx={{ color: "inherit", textDecoration: "underline" }}
            >
              Mondoo documentation
            </Link>
            .
          </Typography>

          <Flex sx={{ position: "relative", alignItems: "center" }}>
            <Controller
              name="servicePrincipalId"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => {
                return (
                  <>
                    <TextField
                      {...field}
                      fullWidth
                      sx={{
                        background: theme.palette.code.background,
                        borderRadius: 1,
                        color: "text.primary",
                        ...helperTextStyles,
                      }}
                      placeholder="e.g. ch5zp4anmh16mcydy]2p3tkq116bfto66udnchvsqvsysqoccnba"
                      error={
                        getFieldState("servicePrincipalId").isTouched &&
                        Boolean(errors.servicePrincipalId)
                      }
                      helperText={
                        Boolean(errors.servicePrincipalId) &&
                        getFieldState("servicePrincipalId").isTouched && (
                          <ValidationMessage
                            error={errors.servicePrincipalId}
                          />
                        )
                      }
                    />
                  </>
                );
              }}
            />
          </Flex>
        </Box>
      </Box>

      {/* Step 5 */}
      <Box pb={4}>
        <Command
          number={5}
          options={{
            fontSize: { xs: 16 },
            dotColor: theme.palette.background.lightest,
          }}
        >
          Provide the client secret
        </Command>

        <Box mt={3}>
          <Flex sx={{ position: "relative", alignItems: "center" }}>
            <Controller
              name="secret"
              control={control}
              render={({ field }) => {
                return (
                  <>
                    <TextField
                      {...field}
                      fullWidth
                      type="password"
                      sx={{
                        background: theme.palette.code.background,
                        borderRadius: 1,
                        color: "text.primary",
                        ...helperTextStyles,
                      }}
                      placeholder="e.g. n1m8Q~sckjSDiTF¡~3rctGUYhI2XzjAOZ~gjnduN"
                    />
                  </>
                );
              }}
            />
          </Flex>
        </Box>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "end", mt: 5 }}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          endIcon={<ChevronRightIcon />}
          disabled={!isValid}
        >
          {isUpdateFlow ? "update configuration" : "create integration"}
        </Button>
      </Box>
    </>
  );
};
