import { createSvgIcon } from "@mui/material";

export const IncompletePackagesIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.7676 16.8592L12.6506 21.4202V12.3422L14.3516 11.3742L20.7676 7.76916V16.8592ZM3.23359 7.77816L11.3506 12.4142V21.4202L3.23359 16.8592V7.77816ZM11.9436 2.44916L14.9626 4.00516L7.01559 8.40216L3.94259 6.67516L11.9436 2.44916ZM13.7006 10.2482L12.0006 11.2032L8.34759 9.15116L16.3526 4.72216L20.0546 6.62916L13.7006 10.2482ZM22.0676 6.58716C22.0626 6.34816 21.9276 6.13116 21.7156 6.02316L12.2376 1.13916C12.0476 1.04116 11.8236 1.04216 11.6356 1.14216L2.27959 6.08316C2.27559 6.08516 2.27359 6.09116 2.26959 6.09316C2.26459 6.09516 2.25959 6.09416 2.25559 6.09716C2.20059 6.12916 2.16859 6.18216 2.12559 6.22716C2.09359 6.26216 2.05059 6.28716 2.02659 6.32716C1.99059 6.38916 1.98159 6.46116 1.96659 6.53216C1.95859 6.57216 1.93459 6.60616 1.93359 6.64716C1.93359 6.64916 1.93459 6.65116 1.93459 6.65316C1.93459 6.65316 1.93359 6.65616 1.93359 6.65816V17.2392C1.93359 17.4742 2.05959 17.6912 2.26559 17.8062L11.6826 23.0972C11.7816 23.1522 11.8906 23.1802 12.0006 23.1802C12.1106 23.1802 12.2196 23.1522 12.3186 23.0972L21.7356 17.8062C21.9406 17.6912 22.0676 17.4742 22.0676 17.2392V6.65816C22.0676 6.64516 22.0586 6.63516 22.0586 6.62316C22.0586 6.61016 22.0676 6.60016 22.0676 6.58716Z"
      fill="currentColor"
    />
  </svg>,
  "Incompletely Removed Packages",
);
