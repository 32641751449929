import { ReactNode } from "react";
import { Box, Typography } from "@mui/material";
import { Code } from "~/components/code";
import {
  RemediationScriptFieldsFragment,
  RemediationScriptType,
} from "~/operations";

type RemediationTabContentProps = {
  children?: ReactNode;
  script: RemediationScriptFieldsFragment;
  index: number;
  value: number;
};

export const RemediationTabContent = (props: RemediationTabContentProps) => {
  const { children, value, index, script, ...other } = props;

  const generateTagline = (scriptType: RemediationScriptType) => {
    switch (scriptType) {
      case RemediationScriptType.Ansible:
        return "Run the following Ansible playbook to update your packages:";
      case RemediationScriptType.Shell:
        return "Run the following Shell script to update your packages:";
      case RemediationScriptType.Powershell:
        return "Run the following PowerShell script to update your packages:";
      default:
        return "Run the following script to update your packages:";
    }
  };

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`remediation-tabpanel-${index}`}
      aria-labelledby={`remediation-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography fontWeight={400} my={2} color="text.secondary">
            {generateTagline(script.type)}
          </Typography>
          <Code
            className="bash"
            copyButton
            style={{ margin: 0 }}
            data-testid="script-code"
          >
            {script.script}
          </Code>
        </Box>
      )}
    </div>
  );
};
