import { createSvgIcon } from "@mui/material";

export const BracketsIcon = createSvgIcon(
  <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 14V18C8 19.1 7.1 20 6 20H4V28H6C7.1 28 8 28.9 8 30V34C8 37.3 10.7 40 14 40H20V36H14C12.9 36 12 35.1 12 34V30C12 27.4 10.32 25.16 8 24.34V23.66C10.32 22.84 12 20.6 12 18V14C12 12.9 12.9 12 14 12H20V8H14C10.7 8 8 10.7 8 14Z"
      fill="#9147FF"
    />
    <path
      d="M42 20C40.9 20 40 19.1 40 18V14C40 10.7 37.3 8 34 8H28V12H34C35.1 12 36 12.9 36 14V18C36 20.6 37.68 22.84 40 23.66V24.34C37.68 25.16 36 27.38 36 30V34C36 35.1 35.1 36 34 36H28V40H34C37.3 40 40 37.3 40 34V30C40 28.9 40.9 28 42 28H44V20H42Z"
      fill="#9147FF"
    />
  </svg>,
  "Brackets",
);
