import { Box, Grid, Grid2 } from "@mui/material";
import { CvssRadar } from "./cvss-radar";
import { CvssMetricBox } from "./cvss-metric-box";
import { useCvssRisk } from "./useCvssRisk";

export type CvssRiskContentProps = {
  id?: string;
  isPrintView?: boolean;
  flipContent?: boolean;
};

export function CvssRiskContent({
  id,
  isPrintView,
  flipContent,
}: CvssRiskContentProps) {
  const risk = useCvssRisk();
  return (
    <Box id={id}>
      <Grid2 container spacing={2} justifyContent={{ lg: "space-between" }}>
        <Grid2
          container
          size={{ xs: 12, md: 6, lg: 8.5 }}
          spacing={3}
          {...(flipContent
            ? { order: { xs: 2, md: 2 } }
            : { order: { xs: 2, md: 1 } })}
          sx={{ mb: "auto" }}
        >
          {risk.metrics.map((metric, i) => (
            <Grid2
              key={metric.field.acronym}
              size={{ xs: 12, sm: 6, md: 6, lg: 4 }}
              display="flex"
            >
              <CvssMetricBox metric={metric} isPrintView={risk.isPrintView} />
            </Grid2>
          ))}
        </Grid2>
        <Grid2
          size={{ xs: 12, md: 6, lg: 3.5 }}
          {...(flipContent
            ? { order: { xs: 1, md: 1 } }
            : { order: { xs: 1, md: 2 } })}
        >
          <CvssRadar />
        </Grid2>
      </Grid2>
    </Box>
  );
}
