import { Box, TableCell, Typography } from "@mui/material";
import { Clamp } from "~/components/clamp/clamp";
import { DynamicIcon } from "~/components/icons/DynamicIcon";
import { PackageIcon } from "~/components/icons/mondoo/package";
import { CommonCellProps } from "~/pages/inventory/components/AssetSoftware/types";
import { FirewatchChip } from "~/components/FirewatchRow/ChipText";

export const TitleCell = ({ pckg, ...props }: CommonCellProps) => {
  return (
    <TableCell data-testid={props["data-testid"]}>
      <Box display="flex" alignItems="center" gap={1}>
        <DynamicIcon iconId={pckg.iconId} defaultIcon={PackageIcon} />
        <Clamp>
          <Typography
            fontSize="14px"
            fontWeight="bold"
            data-testid="software-name"
          >
            {pckg.packageName}
          </Typography>
        </Clamp>
      </Box>
      <Box display="flex" gap={1} mt={1}>
        {pckg?.tags?.map((tag) => <FirewatchChip key={tag.key} tag={tag} />)}
      </Box>
    </TableCell>
  );
};
