import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Grid2,
  Typography,
} from "@mui/material";
import {
  useGetActivePoliciesCountQuery,
  useGetWorkspaceSelectionsQuery,
} from "~/operations";
import { FormProvider, useForm } from "react-hook-form";
import { CreateWorkspaceFormInput } from "~/pages/space/Workspaces/components/CreateWorkspace/CreateWorkspacePage";
import React, { useEffect } from "react";
import { mapQueryBuilderAPIToFormValues } from "~/pages/space/Workspaces/utils";
import { Flex } from "~/components/Flex";
import {
  QueryBuilder,
  SelectionAssetsCount,
  WorkspaceEmpty,
} from "~/pages/space/Workspaces/components";
import { DashboardHeader } from "~/pages/space/Dashboards/components/DashboardHeader";
import { InventoryOverviewCardSunburst } from "./InventoryOverviewCard";
import { Space } from "~/lib/types";
import { ScopeType, SpaceOrWorkspaceScope } from "~/hooks/useScope";
import { Link } from "react-router-dom";
import { ArrowDownwardIcon } from "~/components/icons";
import { LoadingPage } from "~/components/loading";
import { PriorityFindingsDashboardCard } from "./PriorityFindingsDashboardCard";
import {
  AdvisoriesDonutChart,
  CvesDonutChart,
  VulnerabilitiesDonutChart,
} from "~/pages/space/vulnerabilities/components";
import { PolicyOverviewCard } from "../SecurityDashboard/PolicyOverviewCard";
import { PolicyDistributionCard } from "../SecurityDashboard/PolicyDistributionCard";
import { TopSecurityFindingsCard } from "./TopSecurityFindingsCard";
import { useInventory } from "~/pages/inventory/hooks";
import { MTTRSlaCard } from "../SecurityDashboard/MTTRSlaCard";

export type HomeDashboardProps = {
  space: Space;
  scope: SpaceOrWorkspaceScope;
};

export function HomeDashboard({ space, scope }: HomeDashboardProps) {
  const {
    assetsTotalCount,
    workspaceAssetsTotalCount,
    spaceAssetsTotalCount,
    loading: inventoryLoading,
    refetch,
  } = useInventory({
    scope,
  });
  const { data: workspaceSelectionsData } = useGetWorkspaceSelectionsQuery({
    variables: {
      mrn: scope.mrn,
    },
    skip: scope.type !== ScopeType.Workspace,
  });

  const workspaceSelections =
    workspaceSelectionsData?.workspace?.__typename === "Workspace"
      ? workspaceSelectionsData?.workspace?.selections?.selections
      : [];

  const formMethods = useForm<CreateWorkspaceFormInput>({
    mode: "onBlur",
    defaultValues: {
      name: "",
      description: "",
      selections: [],
    },
  });

  useEffect(() => {
    if (scope.type !== ScopeType.Workspace) return;

    formMethods.reset({
      selections: mapQueryBuilderAPIToFormValues(workspaceSelections),
    });
  }, [workspaceSelections]);

  const { data, loading: loadingActivePolicies } =
    useGetActivePoliciesCountQuery({
      variables: {
        input: {
          scopeMrn: scope?.mrn || "",
        },
      },
      skip: !scope?.mrn,
    });

  return (
    <Box>
      {scope.type === "workspace" ? (
        <DashboardHeader
          title={scope.name}
          description={scope.description}
          additionalContent={
            <FormProvider {...formMethods}>
              <Grid2 size={12}>
                <Flex
                  sx={{ color: "text.primary", my: 3 }}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" fontWeight={700}>
                    Workspace Asset Selections
                  </Typography>
                  <Flex alignItems="center" gap={2}>
                    <SelectionAssetsCount
                      selectionCount={workspaceAssetsTotalCount}
                      totalCount={spaceAssetsTotalCount}
                    />
                    <Button
                      component={Link}
                      color="primary"
                      endIcon={<ArrowDownwardIcon sx={{ rotate: "-90deg" }} />}
                      to={`/space/settings?${scope.params}`}
                    >
                      Edit
                    </Button>
                  </Flex>
                </Flex>
                <QueryBuilder isReadonly />
              </Grid2>
            </FormProvider>
          }
        />
      ) : (
        <DashboardHeader
          title={scope.name}
          description={scope.description}
          space={space}
        />
      )}

      {!inventoryLoading &&
        !loadingActivePolicies &&
        assetsTotalCount > 0 &&
        !data?.activePolicies?.totalCount && (
          <Alert
            severity="info"
            sx={{
              mb: 2,
              mt: -1,
              backgroundColor: "#EBF1FF",
              color: "#3B5BDB",
              "& .MuiAlert-action": {
                pt: 0,
              },
              "& .MuiAlert-message": {
                color: "#3B5BDB",
              },
              "& .MuiAlertTitle-root": {
                color: "#3B5BDB",
              },
              "& .MuiAlert-icon": {
                color: "#3B5BDB",
              },
            }}
            action={
              <Button
                component={Link}
                to="/space/integrations"
                sx={{
                  color: "#3B5BDB",
                  "&:hover": {
                    backgroundColor: "rgba(59, 91, 219, 0.04)",
                  },
                }}
              >
                Add integration
              </Button>
            }
          >
            <AlertTitle mb="0">
              Add your first integration to get started with Mondoo, and unlock
              powerful scanning and inventory capabilities!
            </AlertTitle>
          </Alert>
        )}

      {inventoryLoading && <LoadingPage what={`${scope.type} data`} />}

      {!inventoryLoading && (
        <Box>
          {assetsTotalCount === 0 && scope.type === ScopeType.Workspace && (
            <WorkspaceEmpty
              space={space}
              scope={scope}
              onRefreshComplete={() => {
                refetch();
              }}
            />
          )}
          {assetsTotalCount > 0 && (
            <Grid2 container spacing={3}>
              <Grid2 container spacing={3} size={12}>
                <Grid2 size={{ xs: 12, lg: 6 }}>
                  <InventoryOverviewCardSunburst
                    scope={scope}
                    spaceScope={space}
                  />
                </Grid2>
                <Grid2 container size={{ xs: 12, lg: 6 }}>
                  <Grid2 size={12} height={152}>
                    <PriorityFindingsDashboardCard />
                  </Grid2>
                  {/* 100% height - 152height of Priority Findings Card - 24padding */}
                  <Grid2 size={12} height={`calc(100% - 152px - 24px)`}>
                    <TopSecurityFindingsCard
                      scope={scope}
                      spaceScope={space}
                      assetsTotalCount={assetsTotalCount}
                      activePoliciesCount={data?.activePolicies?.totalCount}
                    />
                  </Grid2>
                </Grid2>
              </Grid2>
              <Grid2 container size={12}>
                <Grid2 size={{ xs: 12, md: 6, lg: 4 }}>
                  <VulnerabilitiesDonutChart scope={scope} />
                </Grid2>
                <Grid2 size={{ xs: 12, md: 6, lg: 4 }}>
                  <AdvisoriesDonutChart scope={scope} />
                </Grid2>
                <Grid2 size={{ xs: 12, md: 6, lg: 4 }}>
                  <CvesDonutChart scope={scope} />
                </Grid2>
                <Grid2 size={{ xs: 12, md: 6, lg: 4 }}>
                  <PolicyOverviewCard />
                </Grid2>
                <Grid2 size={{ xs: 12, lg: 8 }}>
                  <PolicyDistributionCard />
                </Grid2>
                <Grid2 size={12}>
                  <MTTRSlaCard />
                </Grid2>
              </Grid2>
            </Grid2>
          )}
        </Box>
      )}
    </Box>
  );
}
