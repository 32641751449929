import { alpha, Button, Stack } from "@mui/material";
import {
  Link as RouterLink,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { SectionHeading } from "~/components/DetailsPage/components";
import {
  ArrowForwardIcon,
  DnsIcon,
  NavDrawerVulnerabilitiesIcon,
  ShieldFilledIcon,
} from "~/components/icons";
import { useFindingOnAsset } from "../../hooks";
import { ReactElement } from "react";
import { isCheckFinding } from "~/pages/findings/FindingOnAsset/utils";
import { truncateText } from "~/lib/truncateText";

export interface ExploreButton {
  name: string;
  icon: ReactElement;
  url: string;
}

export function ExploreWorkspace() {
  const { finding, linkToAsset, asset } = useFindingOnAsset();
  const [searchParams, _setSearchParams] = useSearchParams();
  const spaceId = searchParams.get("spaceId");
  const { vulnId = "" } = useParams();

  const cveUrl = `/space/vulns/cve/${finding?.id}?spaceId=${spaceId}`;
  const advisoryUrl = `/space/vulns/advisory/${finding?.id}?spaceId=${spaceId}`;
  const checkUrl = `/space/findings/check?findingMrn=${encodeURIComponent(finding?.mrn || "")}`;

  const buttons: ExploreButton[] = [
    isCheckFinding(finding)
      ? {
          name: truncateText(finding?.title, 40) || "",
          icon: <ShieldFilledIcon sx={{ color: "text.primary" }} />,
          url: checkUrl,
        }
      : {
          name: finding?.id || "",
          icon: <NavDrawerVulnerabilitiesIcon sx={{ color: "text.primary" }} />,
          url: vulnId ? cveUrl : advisoryUrl,
        },
    ...(linkToAsset && asset?.name
      ? [
          {
            name: asset.name,
            icon: <DnsIcon sx={{ color: "text.primary" }} />,
            url: linkToAsset,
          },
        ]
      : []),
  ];

  return (
    <>
      <SectionHeading heading="Explore Your Workspace" />

      <Stack direction="row" columnGap={3} rowGap={2} flexWrap="wrap">
        {buttons.map((button) => {
          return (
            <Button
              key={button.name}
              component={RouterLink}
              to={button.url}
              variant="contained"
              size="large"
              sx={{
                color: "secondary.light",
                background: (theme) => alpha(theme.palette.secondary.main, 0.2),
                fontWeight: 600,
                lineHeight: "32px",
                px: 2,
                textTransform: "none",
              }}
              startIcon={button.icon}
              endIcon={<ArrowForwardIcon sx={{ color: "secondary.light" }} />}
            >
              {button.name}
            </Button>
          );
        })}
      </Stack>
    </>
  );
}
