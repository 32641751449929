import { Link } from "react-router-dom";
import { FirewatchList } from "~/components/FirewatchList";
import { HubCard } from "~/components/hub-card";
import { ChecklistIcon } from "~/components/icons";
import { SpaceOrWorkspaceScope, SpaceScope } from "~/hooks/useScope";
import {
  AggregateScoreOrder,
  AggregateScoreOrderField,
  OrderDirection,
  useGetAggregateScoresQuery,
  AggregateScoreType,
} from "~/operations";
import { useSort } from "~/pages/inventory/hooks/useSort";
import { Box, Typography } from "@mui/material";
import DashboardNoFindingSvg from "~/components/icons/mondoo/dashboard/DashboardNoFindingSvg";

const getNoFindingsMessage = ({
  assetsTotalCount,
  activePoliciesCount,
  totalFindings,
}: {
  assetsTotalCount: number;
  activePoliciesCount: number | undefined;
  totalFindings: number;
}): string => {
  if (!assetsTotalCount) {
    return "Because this space contains no assets, there are no security findings.";
  }

  if (assetsTotalCount > 0 && !activePoliciesCount) {
    return "Because there are no security policies enabled in this space, there are no security findings.";
  }

  if (assetsTotalCount > 0 && activePoliciesCount && activePoliciesCount > 0) {
    return `Mondoo scanned your inventory for ${totalFindings} known security issues and misconfigurations. It found none. Woohoo!`;
  }

  return "";
};

export type TopSecurityFindingsCardProps = {
  scope: SpaceOrWorkspaceScope;
  spaceScope: SpaceScope;
  assetsTotalCount: number;
  activePoliciesCount: number | undefined;
};

export function TopSecurityFindingsCard({
  scope,
  spaceScope,
  assetsTotalCount,
  activePoliciesCount,
}: TopSecurityFindingsCardProps) {
  const { orderBy } = useSort<AggregateScoreOrder>({
    defaultSort: {
      field: AggregateScoreOrderField.Rank,
      direction: OrderDirection.Asc,
    },
  });

  const { data, loading } = useGetAggregateScoresQuery({
    variables: {
      entityMrn: scope.mrn,
      first: 10,
      after: null,
      filter: {
        minRiskValue: 1,
        scoreTypes: [
          AggregateScoreType.Check,
          AggregateScoreType.Vulnerability,
        ],
      },
      orderBy,
    },
  });

  const scoresUnion = data?.aggregateScores;
  const scores =
    scoresUnion?.__typename === "AggregateScoresConnection"
      ? scoresUnion
      : undefined;
  const findings = scores?.edges || [];

  const hasFindings = Boolean(findings.length);

  const totalFindings =
    scoresUnion?.__typename === "AggregateScoresConnection"
      ? scoresUnion.totalCount
      : 0;

  return (
    <HubCard
      loading={loading}
      title={
        <Link to={`/space/findings/all?${scope.params}`}>
          Top Security Findings
        </Link>
      }
      icon={<ChecklistIcon fontSize="small" />}
      sx={{
        height: "100%",
        minHeight: { xs: 300, sm: 346 },
        "& .MuiCardContent-root": {
          height: "100%",
          p: { xs: 2, sm: 3 },
        },
      }}
      data-testid="top-security-findings-card"
    >
      {!loading && !hasFindings && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "calc(100% - 48px)",
            gap: { xs: 2, sm: 3 },
            p: { xs: 2, sm: 3 },
            textAlign: "center",
          }}
        >
          {assetsTotalCount > 0 &&
            activePoliciesCount &&
            activePoliciesCount > 0 && (
              <Box
                sx={{
                  marginTop: 5,
                  "& svg": {
                    width: 48,
                    height: 48,
                    color: "text.secondary",
                  },
                }}
              >
                <DashboardNoFindingSvg />
              </Box>
            )}

          <Box sx={{ maxWidth: { xs: "100%", sm: 400 } }}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "700",
                fontSize: { xs: "1.125rem", sm: "1.5rem" },
                mb: { xs: 1, sm: 1.5 },
                lineHeight: 1.2,
              }}
            >
              No security findings found
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontWeight: "medium",
                color: "text.secondary",
                textAlign: "center",
                fontSize: { xs: "0.875rem", sm: "1rem" },
                px: { xs: 1, sm: 0 },
                lineHeight: { xs: 1.4, sm: 1.5 },
              }}
            >
              {getNoFindingsMessage({
                assetsTotalCount,
                activePoliciesCount,
                totalFindings,
              })}
            </Typography>
          </Box>
        </Box>
      )}

      {!loading && hasFindings && assetsTotalCount && (
        <FirewatchList
          size="small"
          field={orderBy.field}
          direction={orderBy.direction}
          {...{
            space: spaceScope,
            scope,
            pageType: "TopSecurityFindings",
            data: findings,
            handleSortClick: () => {},
            loading,
            orderBy,
            disableSorting: true,
          }}
        />
      )}
    </HubCard>
  );
}
