import { useScope } from "~/hooks/useScope";
import { useGetWorkspaceQuery, useLoadSpaceQuery } from "~/operations";
import { Metrics, parseAssetRiskRatings } from "~/utils/arrow";

export function useLoadScope() {
  const { activeScope } = useScope();

  const {
    data: spaceData,
    error: spaceError,
    loading: spaceLoading,
  } = useLoadSpaceQuery({
    variables: {
      spaceMrn: activeScope?.mrn || "",
      metricMrns: [Metrics.AssetsRiskRatingDistribution],
    },
    skip: activeScope?.type !== "space",
  });

  const {
    data: workspaceData,
    error: workspaceError,
    loading: workspaceLoading,
  } = useGetWorkspaceQuery({
    variables: {
      mrn: activeScope?.mrn || "",
      metricMrns: [Metrics.AssetsRiskRatingDistribution],
    },
    skip: activeScope?.type !== "workspace",
  });

  const getFindingsCount = () => {
    if (activeScope?.type === "space") {
      return spaceData?.space?.priorityFindings || 0;
    }
    if (activeScope?.type === "workspace") {
      return workspaceData?.workspace?.__typename === "Workspace"
        ? workspaceData.workspace.priorityFindings
        : 0;
    }
    return 0;
  };

  const getAssetCount = () => {
    const metricsEntries =
      (workspaceData?.workspace?.__typename === "Workspace"
        ? workspaceData.workspace.metrics?.entries
        : spaceData?.space?.metrics?.entries) || [];

    const assetRiskRatings = parseAssetRiskRatings(metricsEntries);

    return assetRiskRatings.total;
  };

  const loading = spaceLoading || workspaceLoading;
  const error = spaceError || workspaceError;
  const data = spaceData || workspaceData;

  const findingsCount = getFindingsCount();
  const assetCount = getAssetCount();

  return { loading, error, data, findingsCount, assetCount };
}
