import { useFindingOnAsset } from "~/pages/findings/FindingOnAsset/hooks";
import { Box, Grid2 } from "@mui/material";
import { SectionHeading } from "~/components/DetailsPage/components";
import { Markdown } from "~/components/markdown";

export const CheckRemediation = () => {
  const { checkRemediation } = useFindingOnAsset();

  const hasRemediations = checkRemediation.entries.length > 0;

  if (!hasRemediations) return;

  return (
    <Grid2 size={12}>
      <SectionHeading heading="Remediation" />
      <Box>
        {checkRemediation.entries.map((remediation) => (
          <Markdown
            key={remediation.id}
            source={remediation.desc}
            externalLinksInNew
          />
        ))}
      </Box>
    </Grid2>
  );
};
