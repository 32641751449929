import { Box, TableCell, Typography } from "@mui/material";
import { Clamp } from "../clamp/clamp";
import { AggregateScoresNode } from "../FirewatchPage";
import { FirewatchChip } from "./ChipText";
import { MoonIcon } from "../ui-library";
import { Chip } from "~/components/chip";
import { BlockIcon } from "../icons";
import { NavigateToExceptionButton } from "../exceptions/navigate-to-exception-button";
import { useNavigate } from "react-router-dom";
import {
  AggregateScoreState,
  ExceptionMutationAction,
  ReviewStatus,
} from "~/operations";
import { ExceptionData } from "../policy-gql/PolicyChecks";

export type CheckCellProps = {
  row: AggregateScoresNode;
  size?: string;
  noTags?: boolean;
  exceptionData?: ExceptionData;
};

export const createExceptionsUrl = (
  row: AggregateScoresNode,
  exceptionData?: ExceptionData,
) => {
  if (!exceptionData) {
    return "";
  }
  return `/space/findings/policies/${encodeURIComponent(
    exceptionData.policy?.mrn.split("/").pop() as string,
  )}/exceptions/?spaceId=${row.spaceId}`;
};

export function CheckCell({
  row,
  size,
  noTags,
  exceptionData,
}: CheckCellProps) {
  let navigate = useNavigate();
  const { tags } = row || {};
  const isDashboardCard = size === "small";

  const exceptionsUrl = createExceptionsUrl(row, exceptionData);

  const pendingExceptionGroups = exceptionData?.exceptionGroups?.filter(
    (group) => {
      return group.reviewStatus === ReviewStatus.NotReviewed;
    },
  );

  const pendingExceptionMrns = pendingExceptionGroups
    ? pendingExceptionGroups
        .map((group) => {
          return group.exceptions
            .map((exception) =>
              exception?.__typename !== "ControlsEdge" ? exception?.mrn : null,
            )
            .filter((mrn): mrn is string => mrn !== null && mrn !== undefined);
        })
        .flat()
    : [];

  const snoozedExceptionGroups = exceptionData?.exceptionGroups?.filter(
    (group) => {
      return (
        group.reviewStatus === ReviewStatus.Approved &&
        group.action === ExceptionMutationAction.Snooze
      );
    },
  );

  const snoozedExceptionMrns = snoozedExceptionGroups
    ? snoozedExceptionGroups
        .map((group) => {
          return group.exceptions
            .map((exception) =>
              exception?.__typename !== "ControlsEdge" ? exception?.mrn : null,
            )
            .filter((mrn): mrn is string => mrn !== null && mrn !== undefined);
        })
        .flat()
    : [];

  const disabledExceptionGroups = exceptionData?.exceptionGroups?.filter(
    (group) => {
      return (
        group.reviewStatus === ReviewStatus.Approved &&
        group.action === ExceptionMutationAction.Disable
      );
    },
  );
  const disabledExceptionMrns = disabledExceptionGroups
    ? disabledExceptionGroups
        .map((group) => {
          return group.exceptions
            .map((exception) =>
              exception?.__typename !== "ControlsEdge" ? exception?.mrn : null,
            )
            .filter((mrn): mrn is string => mrn !== null && mrn !== undefined);
        })
        .flat()
    : [];

  const hasPendingException = pendingExceptionMrns.includes(row.findingMrn);
  const hasSnoozedException = snoozedExceptionMrns.includes(row.findingMrn);
  const hasDisabledException = disabledExceptionMrns.includes(row.findingMrn);

  const isSnoozed =
    row.state === AggregateScoreState.Snoozed || hasSnoozedException;
  const isDisabled =
    row.state === AggregateScoreState.Disabled || hasDisabledException;

  return (
    <TableCell>
      <Clamp lines={size === "small" ? 2 : 1}>
        <Typography
          sx={{
            fontSize: isDashboardCard ? 12 : 14,
            fontWeight: isDashboardCard ? 400 : 700,
            lineHeight: "16px",
          }}
        >
          {hasPendingException && (
            <NavigateToExceptionButton
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                navigate(exceptionsUrl);
              }}
            />
          )}{" "}
          {row?.title || row?.nodeTitle}
        </Typography>
      </Clamp>
      {!isDashboardCard && !noTags && (
        <Box display="flex" gap={1} mt={1}>
          {tags?.map((tag) => <FirewatchChip key={tag.key} tag={tag} />)}
        </Box>
      )}

      {!isDashboardCard && (
        <Box display="flex" gap={1} mt={1}>
          {isSnoozed && (
            <Chip
              label="Snoozed"
              icon={<MoonIcon />}
              size="small"
              data-test-id="chip-snoozed"
            />
          )}
          {isDisabled && (
            <Chip
              label="Disabled"
              icon={<BlockIcon />}
              size="small"
              data-test-id="chip-disabled"
            />
          )}
        </Box>
      )}
    </TableCell>
  );
}
