import {
  Button,
  ButtonProps,
  CircularProgress,
  CircularProgressProps,
} from "@mui/material";

type LoadingButtonProps = {
  loading: boolean;
  buttonText: string;
  circleColor?: "inherit" | "primary" | "secondary";
  target?: string;
  LoaderSxProps?: CircularProgressProps["sx"];
} & ButtonProps;

export function LoadingButton({
  buttonText,
  loading,
  circleColor,
  disabled,
  sx,
  ...rest
}: LoadingButtonProps) {
  return (
    <Button
      {...rest}
      disabled={loading || disabled}
      sx={{
        "&.Mui-disabled": { cursor: "not-allowed", pointerEvents: "auto" },
        ...sx,
      }}
    >
      {buttonText}
      {loading && (
        <CircularProgress
          size={18}
          color={circleColor || "secondary"}
          sx={{ position: "absolute", ...rest.LoaderSxProps }}
        />
      )}
    </Button>
  );
}
