import {
  CentosIcon,
  FedoraIconBW,
  GitHubIcon,
  LinuxIcon,
  MacOsIcon,
  OCIColorIcon,
} from "~/components/icons";
import { AwsIcon } from "~/components/icons/mondoo/integrations/aws";
import { AzureIcon } from "~/components/icons/mondoo/integrations/azure";
import { GCPIcon } from "~/components/icons/mondoo/integrations/gcp";
import { GoogleWorkspaceIcon } from "~/components/icons/mondoo/integrations/google-workspace";
import { KubernetesIcon } from "~/components/icons/mondoo/integrations/kubernetes";
import { OktaIcon } from "~/components/icons/mondoo/integrations/okta";
import { RedHatIcon } from "~/components/icons/mondoo/integrations/redhat";
import { SlackIcon } from "~/components/icons/mondoo/integrations/slack";
import { SuseIcon } from "~/components/icons/mondoo/integrations/suse";
import { TerraformIcon } from "~/components/icons/mondoo/integrations/terraform";
import { UbuntuIcon } from "~/components/icons/mondoo/integrations/ubuntu";
import { VMwareIcon } from "~/components/icons/mondoo/integrations/vmware";
import { DebianIcon } from "~/components/icons/mondoo/integrations/debian";
import { DNSIcon } from "~/components/icons/mondoo/dns";
import { GitLabColorIcon } from "~/components/ui-library";
import { ScoreCard } from "../components/PolicyScoreCard";
import { WindowsIcon } from "~/components/icons/mondoo/integrations/windows";
import { AixIcon } from "~/components/icons/mondoo/integrations/aix";
import { AlmaLinuxIcon } from "~/components/icons/mondoo/integrations/alma-linux";
import { AristaIcon } from "~/components/icons/mondoo/integrations/arista";
import { BSIIcon } from "~/components/icons/mondoo/integrations/bsi";
import { GenericPolicyIcon } from "~/components/icons/mondoo/genericPolicy";
import { MS365Icon } from "~/components/icons/mondoo/integrations/ms365";
import { OracleLinuxIcon } from "~/components/icons/mondoo/oracleLinux";
import { RockyLinuxIcon } from "~/components/icons/mondoo/integrations/rocky-linux";
import { ShodanIcon } from "~/components/icons/mondoo/integrations";
import { NginxIcon } from "~/components/icons/mondoo/nginx";

export function policyIcon(
  title: ScoreCard["title"],
  fontSize: "small" | "medium" | "large" = "small",
) {
  let lower = title.toLowerCase();

  if (lower.includes("redhat") || lower.includes("red hat")) {
    return <RedHatIcon {...{ fontSize }} />;
  } else if (lower.includes("aix")) {
    return <AixIcon {...{ fontSize }} />;
  } else if (lower.includes("almalinux")) {
    return <AlmaLinuxIcon {...{ fontSize }} />;
  } else if (lower.includes("arista")) {
    return <AristaIcon {...{ fontSize }} />;
  } else if (lower.includes("bsi")) {
    return <BSIIcon {...{ fontSize }} />;
  } else if (lower.includes("kubernetes")) {
    if (lower.includes("(eks)")) {
      return <AwsIcon {...{ fontSize }} />;
    } else if (lower.includes("(aks)")) {
      return <AzureIcon {...{ fontSize }} />;
    } else if (lower.includes("(gke)")) {
      return <GCPIcon {...{ fontSize }} />;
    }
    return <KubernetesIcon {...{ fontSize }} />;
  } else if (lower.includes("fedora")) {
    return <FedoraIconBW {...{ fontSize }} />;
  } else if (lower.includes("centos")) {
    return <CentosIcon {...{ fontSize }} />;
  } else if (lower.includes("debian")) {
    return <DebianIcon {...{ fontSize }} />;
  } else if (lower.includes("ubuntu")) {
    return <UbuntuIcon {...{ fontSize }} />;
  } else if (lower.includes("nginx")) {
    return <NginxIcon {...{ fontSize }} />;
  } else if (lower.includes("suse")) {
    return <SuseIcon {...{ fontSize }} />;
  } else if (lower.includes("terraform")) {
    return <TerraformIcon {...{ fontSize }} />;
  } else if (lower.includes("amazon") || lower.includes("aws")) {
    return <AwsIcon {...{ fontSize }} />;
  } else if (lower.includes("apple") || lower.includes("macos")) {
    return <MacOsIcon {...{ fontSize }} />;
  } else if (lower.includes("google workspace")) {
    return <GoogleWorkspaceIcon {...{ fontSize }} />;
  } else if (lower.includes("rocky linux")) {
    return <RockyLinuxIcon {...{ fontSize }} />;
  } else if (lower.includes("slack")) {
    return <SlackIcon {...{ fontSize }} />;
  } else if (lower.includes("oracle cloud")) {
    return <OCIColorIcon {...{ fontSize }} />;
  } else if (lower.includes("oracle linux")) {
    return <OracleLinuxIcon {...{ fontSize }} />;
  } else if (lower.includes("okta")) {
    return <OktaIcon {...{ fontSize }} />;
  } else if (
    lower.includes("google cloud") ||
    lower.includes("gcp") ||
    lower.includes("google container-optimized")
  ) {
    return <GCPIcon {...{ fontSize }} />;
  } else if (lower.includes("vmware")) {
    // vmware vsphere, photon
    return <VMwareIcon {...{ fontSize }} />;
  } else if (lower.includes("github")) {
    return <GitHubIcon {...{ fontSize }} />;
  } else if (lower.includes("gitlab")) {
    return <GitLabColorIcon {...{ fontSize }} />;
  } else if (lower.includes("linux")) {
    return <LinuxIcon {...{ fontSize }} />;
    // Check Azure before MS365 check so we don't put the MS logo on Azure policies
  } else if (lower.includes("azure")) {
    return <AzureIcon {...{ fontSize }} />;
    // Check Windows before Microsoft so we don't put the MS logo on Windows policies
  } else if (lower.includes("windows")) {
    return <WindowsIcon {...{ fontSize }} />;
  } else if (lower.includes("microsoft")) {
    if (lower.includes("365")) {
      return <MS365Icon {...{ fontSize }} />;
    }
    return <WindowsIcon {...{ fontSize }} />;
  } else if (lower.includes("dns")) {
    return <DNSIcon {...{ fontSize }} />;
  } else if (lower.includes("shodan")) {
    return <ShodanIcon {...{ fontSize }} />;
  } else {
    return <GenericPolicyIcon {...{ fontSize }} />;
  }
}
