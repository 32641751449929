import { motion } from "framer-motion";
import { Box, Skeleton } from "@mui/material";
import React from "react";
import { Flex } from "~/components/Flex";

export const RemediationRadioSkeleton = () => {
  return (
    <Flex gap={3} flexDirection="column" mb={3}>
      <Box
        component={motion.div}
        width="100%"
        key="skeleton"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        sx={{
          height: 24,
          p: 0,
          m: 0,
          bgcolor: "code.background",
          borderRadius: 1,
          overflow: "hidden",
        }}
      >
        <Skeleton animation="wave" variant="rectangular" height={24} />
      </Box>
      <Box
        component={motion.div}
        width="100%"
        key="skeleton"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        sx={{
          height: 24,
          p: 0,
          m: 0,
          bgcolor: "code.background",
          borderRadius: 1,
          overflow: "hidden",
        }}
      >
        <Skeleton animation="wave" variant="rectangular" height={24} />
      </Box>
    </Flex>
  );
};
