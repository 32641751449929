import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { TestIamActionsQuery } from "~/operations";
import { setDocumentTitle } from "~/utils/commonUtils";

export type FindingsPageProps = {
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function FindingsPage({}: FindingsPageProps) {
  setDocumentTitle("Findings");

  return (
    <Box>
      <Outlet />
    </Box>
  );
}
