import { SpaceScope } from "~/hooks/useScope";
import { TestIamActionsQuery } from "~/operations";
import { WorkloadIdentityBindingsEmptyState } from "~/pages/space-settings/WorkloadIdentity/components/WorkloadIdentityBindingsEmptyState";
import { WorkloadIdentityBindingsTable } from "./components/WorkloadIdentityBindingsTable";
import { Box } from "@mui/material";
import { Flex } from "~/components/Flex";
import { AddButton } from "~/components/add-button";
import { useWorkloadIdentity } from "~/pages/space-settings/WorkloadIdentity/hooks/useWorkloadIdentity";
import { FilterBar } from "~/pages/compliance/filter-bar";
import { NoPermissionsIcon } from "~/components/icons";
import { EmptyState } from "~/pages/inventory/components/EmptyState";

type WorkloadIdentityProps = {
  space: SpaceScope;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export const WorkloadIdentity = ({
  space,
  availablePermissions,
}: WorkloadIdentityProps) => {
  const {
    bindings,
    isLoading,
    searchFilters,
    handleWIFAuthBindingRemove,
    handleFilterQuery,
    orderBy,
    handleSortClick,
    hasWIFCreatePermissions,
    hasWIFSearchPermissions,
    hasWIFRemovePermissions,
    hasWIFListPermissions,
    showEmptyState,
  } = useWorkloadIdentity({
    availablePermissions,
    scope: space,
  });

  if (showEmptyState) {
    return (
      <WorkloadIdentityBindingsEmptyState
        scope={space}
        hasCreateWIFBindingPermission={hasWIFCreatePermissions}
      />
    );
  }

  return (
    <>
      <Flex gap={4} width="100%" justifyContent="flex-end">
        {/* currently backend does not support search */}
        {hasWIFSearchPermissions && false && (
          <Box sx={{ flex: "1 0 auto" }}>
            <FilterBar
              searchId="bindings-search"
              placeholder="bindings"
              searchFilters={searchFilters}
              handleFilterQuery={handleFilterQuery}
            />
          </Box>
        )}
        {hasWIFCreatePermissions && (
          <Box sx={{ mt: 1, mb: 4 }}>
            <AddButton
              id="providers-add"
              href={`/space/settings/workload-identity/providers?${space.params}`}
              aria-label="Add New Provider"
            />
          </Box>
        )}
      </Flex>
      {hasWIFListPermissions ? (
        <WorkloadIdentityBindingsTable
          bindings={bindings}
          onBindingRemove={handleWIFAuthBindingRemove}
          onSort={handleSortClick}
          orderBy={orderBy}
          isRemoving={isLoading}
          canRemove={hasWIFRemovePermissions}
        />
      ) : (
        <EmptyState
          headline="Additional privileges required"
          icon={<NoPermissionsIcon sx={{ fontSize: 48 }} />}
          textContent="Please contact an administrator."
        />
      )}
    </>
  );
};
