import {
  RiskFactorConditionKeyValueOperator,
  RiskFactorConditionOperator,
} from "../../../RiskFactorsConfigurationForm";
import { OperatorType } from "../../types";

type UseOperatorDropdownProps = {
  type: OperatorType;
};

export function useOperatorDropdown({ type }: UseOperatorDropdownProps) {
  const conditionsOperators: RiskFactorConditionOperator[] = [
    RiskFactorConditionOperator.Or,
  ];

  const conditionsOperatorLabelMap: Record<
    RiskFactorConditionOperator,
    string
  > = {
    [RiskFactorConditionOperator.Or]: "OR",
    [RiskFactorConditionOperator.Unknown]: "",
  };

  const keyValueOperators: RiskFactorConditionKeyValueOperator[] = [
    RiskFactorConditionKeyValueOperator.Contains,
  ];

  const keyValueOperatorsLabelMap: Record<
    RiskFactorConditionKeyValueOperator,
    string
  > = {
    [RiskFactorConditionKeyValueOperator.Contains]: "CONTAIN",
    [RiskFactorConditionKeyValueOperator.Unknown]: "",
  };

  switch (type) {
    case "condition":
      return {
        operators: conditionsOperators as string[],
        operatorsLabelMap: conditionsOperatorLabelMap as Record<string, string>,
      };
    case "keyValue":
      return {
        operators: keyValueOperators as string[],
        operatorsLabelMap: keyValueOperatorsLabelMap as Record<string, string>,
      };
  }
}
