import { Fragment, useEffect } from "react";
import {
  createPath,
  Link as RouterLink,
  Navigate,
  Outlet,
  parsePath,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { Box, Link, Typography } from "@mui/material";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import { CicdBreadcrumbs } from "~/components/cicd/cicd-breadcrumbs";
import { Space } from "~/lib/types";
import {
  CaseStatus,
  ExceptionGroup,
  ExceptionType,
  ReportViewedPage,
  useGetAssetLinkInfoQuery,
  useGetCasesCountQuery,
  useGetClientIntegrationQuery,
  useLoadAssetQuery,
  useLoadOrganizationForSpaceQuery,
  useReportViewedMutation,
} from "~/operations";
import {
  TabListItem,
  TabNavigation,
  useRouteMatch,
} from "~/components/tab-nav";
import { useFetchExceptions } from "~/components/exceptions/use-fetch-exceptions";
import { PendingExceptionsTooltip } from "~/components/exceptions/pending-exceptions-tooltip";
import { AssetOverviewHeader } from "./components/PolicyScoreCard/asset/assetOverviewHeader/assetOverviewHeader";
import { Asset, Project } from "./asset/types";
import { usePlatformVulnerabilityConfiguration } from "~/hooks";
import { IamActions } from "~/lib/iam";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";
import { integrationDetailHref } from "~/lib/integrations";
import { isFeatureEnabled } from "~/login/features";
import { getAssetMrn } from "~/utils/urlUtils";
import { setDocumentTitle } from "~/utils/commonUtils";
import { MONDOO_PLATFORM_VULN_POLICY_PREFIX } from "../../../constants";

type Props = {
  space: Space;
  scope: SpaceOrWorkspaceScope;
  isCicd?: boolean;
  project?: Project | null;
  assetMrn?: string;
};

export type AssetIntegration = {
  mrn: string;
  name: string;
  href: string;
  status: string;
};

export type AssetOutletContextType = {
  loading: boolean;
  asset: Asset;
  assetId: string;
  assetMrn: string;
  assetPath: string;
  assetIntegration?: AssetIntegration;
  isCicd?: boolean;
  space: Space;
  scope: SpaceOrWorkspaceScope;
  hasMondooPlatformVulnPolicy: boolean;
  exceptionGroups: ExceptionGroup[];
  isVulnerabilityScanningEnabled: boolean;
};

export function AssetPage({
  space,
  project,
  isCicd = false,
  scope,
  ...props
}: Props) {
  const { id } = useParams();

  const assetId = props.assetMrn?.split("/").pop() || id;

  if (!assetId) {
    return <LoadingFailedPage what="asset" />;
  }

  const assetMrn = props.assetMrn || getAssetMrn(space.id, assetId);

  const { exceptionGroups, pendingExceptionsGroups } = useFetchExceptions({
    scopeMrn: assetMrn,
    types: [ExceptionType.Security, ExceptionType.Advisory, ExceptionType.Cve],
    skip: !assetMrn,
  });

  const { exceptionsCount: securityExceptionsCount } = useFetchExceptions({
    scopeMrn: assetMrn,
    types: [ExceptionType.Security],
    skip: !assetMrn,
  });

  const { exceptionsCount: vulnerabilityExceptionsCount } = useFetchExceptions({
    scopeMrn: assetMrn,
    types: [ExceptionType.Advisory, ExceptionType.Cve],
    skip: !assetMrn,
  });

  const [reportViewed] = useReportViewedMutation();

  const { pvcIsEnabled } = usePlatformVulnerabilityConfiguration({ space });

  const { data: orgDataForSpace, loading: orgDataLoading } =
    useLoadOrganizationForSpaceQuery({
      variables: { spaceMrn: space.mrn },
    });

  const hasListCasePermissions = space.iamActions.includes(
    IamActions.ACTION_MONDOO_POLICY_EXTENDEDHUB_LISTCASES,
  );

  // Send report viewed mutation on mount and when the tab changes
  useEffect(() => {
    if (orgDataLoading) return;
    let currentTab = window.location.pathname.split("/").pop() || "unknown";
    const org = orgDataForSpace?.space?.organization;
    let orgMrn = org?.mrn ? org.mrn : "unknown";

    const page = isCicd
      ? ReportViewedPage.CicdJob
      : ReportViewedPage.AssetOverview;

    const info = isCicd
      ? {
          cicdJob: {
            assetMrn: assetMrn,
            orgMrn: orgMrn,
            spaceMrn: space.mrn,
            tab: currentTab,
          },
        }
      : {
          assetOverview: {
            assetMrn: assetMrn,
            orgMrn: orgMrn,
            spaceMrn: space.mrn,
            tab: currentTab,
          },
        };

    reportViewed({
      variables: {
        input: {
          page: page,
          info: info,
        },
      },
    });

    return () => {};
  }, [window.location.pathname, orgDataForSpace]);

  const {
    data: assetData,
    error,
    loading,
  } = useLoadAssetQuery({
    variables: {
      mrn: assetMrn,
    },
  });

  const { data: assetLinkData } = useGetAssetLinkInfoQuery({
    variables: {
      input: {
        spaceID: space.id,
        assetId,
      },
    },
  });

  const { data: casesData } = useGetCasesCountQuery({
    variables: {
      input: {
        scopeMrn: assetMrn,
        findingMrns: [],
        statuses: [CaseStatus.Open],
        contextMrn: scope.mrn,
      },
    },
    fetchPolicy: "cache-and-network",
    skip: !hasListCasePermissions,
  });

  const integrationMrn = assetData?.asset?.labels.find(
    ({ key }) => key === "mondoo.com/integration-mrn",
  )?.value;

  const { data: integrationData } = useGetClientIntegrationQuery({
    variables: { mrn: integrationMrn || "skip" },
    skip: !integrationMrn,
  });

  const { clientIntegration } = integrationData || {};
  const { integration } = clientIntegration || {};
  const assetIntegration: AssetIntegration | undefined =
    integrationMrn && integration
      ? {
          name: integration.name,
          mrn: integration.mrn,
          href: integrationDetailHref(integration.type, integration.mrn, space),
          status: integration.status,
        }
      : integrationMrn && !integration
        ? {
            name: "Integration removed",
            mrn: integrationMrn,
            href: "",
            status: "removed",
          }
        : undefined;

  const assetLinkInfo = assetLinkData?.getAssetLinkInfo;
  const asset = assetData?.asset;

  if (loading) {
    return <LoadingPage what="asset" />;
  }

  if (error || !asset) {
    return <LoadingFailedPage what="asset" />;
  }

  let assetPath = `/space/inventory/${assetId}?${scope.params}`;

  if (assetLinkInfo?.__typename === "CiAssetLinkInfo") {
    const projectId = assetLinkInfo.projectID.split("/").pop();
    assetPath = `/space/cicd/jobs/${projectId}?${scope.params}&projectId=${projectId}&jobId=${assetMrn}`;

    if (!isCicd) {
      return <Navigate to={assetPath} replace />;
    }
  }

  const generateHref = (tab: string): string => {
    const parsedPath = parsePath(assetPath);
    const pathname = `${parsedPath.pathname}/${tab}`;
    const search = parsedPath.search;
    return createPath({ pathname, search });
  };

  // We only show the Asset Explorer Tab if an Asset has children
  let totalRelatedAssets = 0;
  if (asset.relatedAssets?.count) {
    totalRelatedAssets = asset.relatedAssets.count
      .filter((x: { isParent: boolean }) => !x.isParent)
      .reduce((agg: number, x: { count: number }) => agg + x.count, 0);
  }

  const hasRelatedAssets = totalRelatedAssets > 0;

  const tabList: TabListItem[] = [
    { label: "Overview", to: generateHref("overview"), route: "/overview" },
    {
      label: "Policies",
      to: generateHref("policies"),
      route: "/policies",
    },
    {
      label: "Checks",
      to: generateHref("checks"),
      route: "/checks",
    },
    {
      label: "Data queries",
      to: generateHref("data-queries"),
      route: "/data-queries",
    },
    // display the software tab if the asset
    // has Platform Vulnerability Policy applied
    pvcIsEnabled
      ? {
          label: "Software",
          to: generateHref("software"),
          route: "/software",
        }
      : undefined,
    // display the vulnerabilities tab if the asset
    // has Platform Vulnerability Policy applied
    pvcIsEnabled
      ? {
          label: "Vulnerabilities",
          to: generateHref("vulnerabilities"),
          route: "/vulnerabilities",
        }
      : undefined,
    // display the advisories tab if the asset
    // has Platform Vulnerability Policy applied
    pvcIsEnabled
      ? {
          label: "Advisories",
          to: generateHref("advisories"),
          route: "/advisories",
        }
      : undefined,
    // display the asset explorer tab if this asset has children
    isFeatureEnabled("Asset Explorer") && hasRelatedAssets
      ? {
          label: "Explorer",
          to: generateHref("explorer"),
          route: "/explorer",
        }
      : undefined,
    isFeatureEnabled("Asset Resources")
      ? {
          label: "Resources",
          to: generateHref("resources"),
          route: "/resources",
        }
      : undefined,
    !isCicd
      ? {
          label: (
            <Box sx={{ display: "flex", alignItems: "center" }} gap={1}>
              Exceptions
              {pendingExceptionsGroups.length > 0 && (
                <PendingExceptionsTooltip />
              )}
            </Box>
          ),
          to: generateHref("exceptions"),
          route: "/exceptions",
        }
      : undefined,
  ].flatMap((tab) => (tab ? [tab] : []));

  const currentTab = useRouteMatch(
    tabList.map((x) => x.route),
    "overview",
  );
  const trail = isCicd
    ? [
        <Link key="1" component={RouterLink} to={`/space/cicd?${scope.params}`}>
          CI/CD
        </Link>,
        <Link
          key="2"
          component={RouterLink}
          to={`/space/cicd/jobs?${scope.params}&projectId=${project?.id}`}
        >
          {project?.name}
        </Link>,
        <Typography key="3">{asset.name}</Typography>,
      ]
    : [
        <Link
          key="1"
          component={RouterLink}
          to={`/space/inventory?${scope.params}`}
        >
          Inventory
        </Link>,
        <Typography key="2">{asset.name}</Typography>,
      ];

  // TODO: check settings for platform-vulnerability enabled instead
  const hasMondooPlatformVulnPolicy = Boolean(
    asset?.listPolicies?.edges.some(
      (policy) => policy.node.mrn === MONDOO_PLATFORM_VULN_POLICY_PREFIX,
    ),
  );

  setDocumentTitle([asset.name, "Assets"]);

  return (
    <Fragment>
      <CicdBreadcrumbs {...{ scope, trail }} />

      <AssetOverviewHeader
        asset={asset}
        space={space}
        scope={scope}
        project={project}
        isCicd={isCicd}
        securityExceptionsCount={securityExceptionsCount}
        vulnerabilityExceptionsCount={vulnerabilityExceptionsCount}
        casesCount={casesData?.cases?.totalCount || 0}
      />

      {asset && (
        <Fragment>
          {/* Navigation Tabs List */}
          <TabNavigation
            {...{ id: "asset-tabs", tabList, currentTab, marginBottom: 0 }}
          />

          <Outlet
            context={
              {
                loading,
                asset,
                assetMrn,
                assetPath,
                assetId,
                exceptionGroups,
                space,
                scope,
                hasMondooPlatformVulnPolicy,
                isCicd,
                isVulnerabilityScanningEnabled: pvcIsEnabled,
                assetIntegration,
              } satisfies AssetOutletContextType
            }
          />
        </Fragment>
      )}
    </Fragment>
  );
}

export function useAssetOutlet() {
  return useOutletContext<AssetOutletContextType>();
}
