import React, { Fragment, useState } from "react";
import {
  INITIAL_PAGE_RANGE,
  Pagination,
  PaginationRange,
} from "~/components/pagination";
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { DataTable } from "~/components/data-table";
import { OrderDirection } from "~/operations";
import { useAssetContextualLink } from "~/pages/space/vulnerabilities/components/AffectedAssets/hooks";
import { Link as RouterLink } from "react-router-dom";
import { AssetCell } from "~/components/FirewatchRow/AssetCell";
import { DateCell } from "~/components/FirewatchRow/DateCell";
import { Header } from "~/components/FirewatchPage/FirewatchPageColumns";
import { AssetsProps } from "~/components/cases/components/CaseDetailsPage/components/AssetsSection/types";

type CaseAssetsTableProps = AssetsProps;

export const CaseAssetsTable = ({
  orderBy,
  pageInfo,
  fetchMore,
  filteredTotalCount,
  handleSortClick,
  assets,
  urlContextType,
  contextId,
  scope,
}: CaseAssetsTableProps) => {
  const [pageItems, setPageItems] =
    useState<PaginationRange>(INITIAL_PAGE_RANGE);

  const TableColumns = {
    Selection: {
      id: "SELECTION",
      field: "SELECTION",
      label: " ",
      sortable: false,
      options: {
        width: 16,
      },
    },
    Asset: {
      id: "ASSET",
      field: "TITLE",
      label: "Assets / Vulnerable Packages",
      sortable: false,
    },
    LastUpdated: {
      id: "LAST_UPDATED",
      field: "LAST_UPDATED",
      label: "Last Updated",
      sortable: false,
      options: { width: 180 },
    },
  };

  const tableHeaders: Header[] =
    [
      TableColumns.Selection,
      TableColumns.Asset,
      TableColumns.LastUpdated,
    ].flatMap((e) => e ?? []) || [];

  return (
    <Fragment>
      <DataTable id="affected-assets-list">
        <TableHead>
          <TableRow>
            {tableHeaders.map((header) => {
              return (
                <TableCell
                  key={header.field}
                  align={header.options?.textAlign}
                  width={header.options?.width}
                  sx={header.options?.sx}
                >
                  {header.sortable ? (
                    <TableSortLabel
                      onClick={() => handleSortClick(header.field)}
                      direction={
                        orderBy.direction === OrderDirection.Asc
                          ? "asc"
                          : "desc"
                      }
                      active={header.field === orderBy.field}
                    >
                      {header.label}
                    </TableSortLabel>
                  ) : (
                    header.label
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {assets?.slice(pageItems.from, pageItems.to).map((asset) => {
            const { contextUrl } = useAssetContextualLink({
              assetId: asset.id,
              scopeParams: scope.params,
              type: urlContextType,
              contextId: contextId,
            });

            return (
              <TableRow key={asset.mrn} component={RouterLink} to={contextUrl}>
                <TableCell></TableCell>
                {/* Asset */}
                <AssetCell
                  title={asset.name}
                  iconId={asset.icon}
                  tags={asset.tags}
                />

                {/* Last Updated */}
                <DateCell date={asset.lastUpdated} />
              </TableRow>
            );
          })}
        </TableBody>
      </DataTable>
      <Pagination
        fetchMore={fetchMore}
        totalCount={filteredTotalCount || 0}
        setPageItems={setPageItems}
        pageInfo={pageInfo}
      />
    </Fragment>
  );
};
