import React from "react";
import { SectionHeading } from "~/components/DetailsPage/components";
import { Box, Grid2 } from "@mui/material";
import { CveRiskFactors } from "~/components/vulnerabilities";
import { RiskFactorStats } from "~/operations";

type SoftwareRiskFactorsProps = {
  riskFactorsWithDocs: (RiskFactorStats & {
    description: { active: string; inactive: string };
  })[];
};

export const SoftwareRiskFactors = ({
  riskFactorsWithDocs,
}: SoftwareRiskFactorsProps) => {
  return (
    <Grid2 id="risk-factors" size={{ xs: 12 }}>
      <SectionHeading heading="Vulnerability insights" />
      <Box className="section-content">
        <CveRiskFactors
          id="cve-risk-factors"
          riskFactors={riskFactorsWithDocs}
        />
      </Box>
    </Grid2>
  );
};
