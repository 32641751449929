import { Fragment, useState } from "react";
import {
  Box,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ChevronLeftIcon } from "~/components/icons";
import { EditableProps } from "./editable-props";
import { MProperty } from "~/operations";
import { Space } from "~/lib/types";
import { useColorMode } from "~/providers/color-mode";

export type PropsTableProps = {
  data: CollapsablePropRowProps["prop"][];
  space: Space;
  onChange: () => void;
};

export function PropsTable({ data, space, onChange }: PropsTableProps) {
  return (
    <Box
      sx={{
        overflow: "hidden",
        borderRadius: 1,
        boxShadow: (theme) => theme.shadows[1],
      }}
    >
      <Paper sx={{ width: "100%", boxShadow: "none" }}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                {tableHeaders.map((header) => (
                  <TableCell key={header.id}>{header.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((prop) => {
                return (
                  <CollapsablePropRow
                    key={prop.uid}
                    {...{ prop, space, onChange }}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

//////// Table Configuration
type Header = {
  id: string;
  label: string;
  colSpan?: number;
  options?: {
    textAlign?: "inherit" | "left" | "center" | "right" | "justify";
    width?: number;
  };
};

const tableHeaders: Header[] = [
  {
    id: "NAME",
    label: "Property name",
    options: { textAlign: "left" },
  },
  { id: "VALUE", label: "Value", options: { textAlign: "left" } },
];

type CollapsablePropRowProps = {
  prop: Pick<MProperty, "mql" | "mrn" | "title" | "uid">;
  space: Space;
  onChange: () => void;
};

const CollapsablePropRow = ({
  prop,
  space,
  onChange,
}: CollapsablePropRowProps) => {
  const [open, setOpen] = useState(false);
  const { mode } = useColorMode();

  return (
    <Fragment>
      <TableRow
        className="info-row"
        onClick={() => setOpen(!open)}
        sx={[{ td: { ...(!open && { borderBottom: "none" }) } }]}
      >
        <TableCell sx={{ width: "20%", minWidth: 250 }}>
          <Box sx={{ display: "flex" }}>
            <Box
              component="button"
              sx={{
                background: "transparent",
                border: "none",
                transform: "rotate(270deg)",
              }}
            >
              <ChevronLeftIcon sx={{ fontSize: 16, color: "text.primary" }} />
            </Box>
            <Typography>{prop.uid}</Typography>
          </Box>
        </TableCell>
        <TableCell sx={{ position: "relative", width: "80%" }}>
          <Typography
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: { xs: 200, sm: 350, lg: 800 },
              color: "text.primary",
              background: `-webkit-linear-gradient(left, ${
                mode === "light" ? "#000" : "#FFF"
              }, transparent)`,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            {prop.mql}
          </Typography>
        </TableCell>
      </TableRow>
      {/* Accordion Content */}
      <TableRow className="expand-row">
        <TableCell sx={{ py: 0, maxWidth: 0 }} colSpan={2}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ px: 2, py: 2 }}>
              <Typography color="text.secondary">{prop.title}</Typography>
              <EditableProps {...{ space, propMrn: prop.mrn, onChange }}>
                {prop.mql}
              </EditableProps>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};
