import { Box, Breadcrumbs, Divider, Link, Typography } from "@mui/material";
import {
  Link as RouterLink,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Code } from "~/components/code";
import { HomeIcon } from "~/components/icons";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import { Markdown } from "~/components/markdown";
import { Flex } from "~/components/Flex";
import { TabsVariant, TextTitle, VariantIcon } from "~/components/ui-library";
import { PolicyIdToMrn, PolicyMrnToURIEncodedId } from "~/lib/mrn";
import { Space } from "~/lib/types";
import {
  TestIamActionsQuery,
  useGetQueryPackQuery,
  useLoadMqueryQuery,
} from "~/operations";
import { setDocumentTitle } from "~/utils/commonUtils";

const getQueryMrn = (policyId: string, queryId: string) => {
  let mrn = "//policy.api.mondoo.app";
  if (policyId.includes("/")) {
    const namespace = policyId.split("/")[0];
    mrn = `${mrn}/spaces/${namespace}`;
  }
  return `${mrn}/queries/${queryId}`;
};

export type QueryPacksAddQueryPageProps = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function QueryPacksAddQueryPage({ space }: QueryPacksAddQueryPageProps) {
  const { policyId = "", queryId = "" } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const policyMrn = PolicyIdToMrn(policyId);
  const queryMrn = getQueryMrn(policyId, queryId);
  const selectedVariantId = searchParams.get("variantQuery");

  const { data, loading, error } = useGetQueryPackQuery({
    variables: { input: { mrn: policyMrn, spaceMrn: space.mrn } },
    skip: !policyId,
  });

  const { data: mqueryData } = useLoadMqueryQuery({
    variables: { input: { mrn: queryMrn } },
    skip: !policyId || !queryId,
  });

  const loadingFailed = !policyId || error || !data?.policy;
  const queryPack = data?.policy;
  const mquery = mqueryData?.mquery;

  const variants = [
    ...(
      mquery?.variants?.map((variant) => ({
        label: variant?.title || "",
        value: variant?.id || "",
        icon: <VariantIcon type={variant?.icon || ""} />,
      })) || []
    ).sort((a, b) => (a.label > b.label ? 1 : -1)),
  ];

  const selectedVariant =
    variants.find((v) => v.value === selectedVariantId) || variants[0];

  const variantQueries = mquery?.variantQueries || [];

  const visibleQueries =
    variantQueries.length > 0
      ? variantQueries.filter((vq) => {
          if (!selectedVariant) return vq;
          return vq?.filterID === selectedVariant.value;
        })
      : [mquery];

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?spaceId=${space.id}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Link
      key="/space/inventory/query-packs"
      component={RouterLink}
      to={`/space/inventory/query-packs?spaceId=${space.id}`}
    >
      Query Packs
    </Link>,
    <Link
      key="/space/inventory/query-packs/add"
      component={RouterLink}
      to={`/space/inventory/query-packs/add?spaceId=${space.id}`}
    >
      Add Query Packs
    </Link>,
    <Link
      key="/space/inventory/query-packs/add/policyId"
      component={RouterLink}
      to={`/space/inventory/query-packs/add/${PolicyMrnToURIEncodedId(queryPack?.mrn || "")}?spaceId=${space.id}`}
    >
      {queryPack?.name}
    </Link>,
    <Typography key="/space/inventory/query-packs/add/policyId/queries/queryId">
      {mquery?.title}
    </Typography>,
  ];

  setDocumentTitle([
    mquery?.title,
    queryPack?.name,
    "Query Packs",
    "Inventory",
  ]);

  return (
    <Box>
      <Breadcrumbs sx={{ mb: 3, overflowWrap: "anywhere" }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>
      {mquery && (
        <>
          <Box
            id="mquery-header"
            mb={3}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Typography
              variant="h4"
              fontWeight={700}
              textTransform="uppercase"
              sx={{ mb: 3 }}
            >
              {mquery.title}
            </Typography>
            <Flex id="mquery-actions" alignItems="center" gap={4}></Flex>
          </Box>
          {variants.length > 0 && (
            <TabsVariant
              options={variants}
              selectedTab={selectedVariant.value}
              onTabChange={(variantId) => {
                variantId.length > 0
                  ? searchParams.set("variantQuery", variantId)
                  : searchParams.delete("variantQuery");
                setSearchParams(searchParams);
              }}
              mb={3}
            />
          )}
        </>
      )}

      {loading && <LoadingPage what="Query" />}
      {!loading && loadingFailed && <LoadingFailedPage what="Query" />}
      {!loading && mquery && (
        <Box>
          <Box className="section">
            <Flex center gap={3} my={4} className="section-header">
              <TextTitle bold sx={{ whiteSpace: "nowrap", flexShrink: 0 }}>
                Queries
              </TextTitle>
              <Box width={1}>
                <Divider />
              </Box>
            </Flex>
            <Box className="section-content">
              <Markdown source={mquery?.docs?.desc || ""} />
              {visibleQueries.map((q) => {
                return (
                  <Box key={q?.mrn}>
                    <Typography component="h4" fontWeight={700}>
                      MQL
                    </Typography>
                    <Code className="plaintext">{q?.mql}</Code>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
