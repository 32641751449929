import { Flex } from "~/components/Flex";
import { Button, Typography } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { SpaceScope } from "~/hooks/useScope";
import { WorkloadIdentityBindingsEmptyStateStepOne } from "./WorkloadIdentityBindingsEmptyStateStepOne";
import { WorkloadIdentityBindingsEmptyStateStepTwo } from "./WorkloadIdentityBindingsEmptyStateStepTwo";
import { WorkloadIdentityBindingsEmptyStateStepThree } from "./WorkloadIdentityBindingsEmptyStateStepThree";

type WorkloadIdentityEmptyStateProps = {
  hasCreateWIFBindingPermission: boolean;
  scope: SpaceScope;
};

export const WorkloadIdentityBindingsEmptyState = ({
  hasCreateWIFBindingPermission,
  scope,
}: WorkloadIdentityEmptyStateProps) => {
  return (
    <Flex flexDirection="column">
      <Flex justifyContent="space-around" sx={{ gap: 2 }}>
        <Flex flexDirection="column" maxWidth={232}>
          <WorkloadIdentityBindingsEmptyStateStepOne
            sx={{ width: "100%", height: 144 }}
          />
          <Typography
            sx={{
              color: (theme) => theme.palette.text.secondary,
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            Establish a trust relationship with your workload identity provider.
          </Typography>
        </Flex>
        <Flex flexDirection="column" maxWidth={232}>
          <WorkloadIdentityBindingsEmptyStateStepTwo
            sx={{ width: "100%", height: 144 }}
          />
          <Typography
            sx={{
              color: (theme) => theme.palette.text.secondary,
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            Workloads exchange external identity tokens for secure access to
            Mondoo.
          </Typography>
        </Flex>
        <Flex flexDirection="column" maxWidth={232}>
          <WorkloadIdentityBindingsEmptyStateStepThree
            sx={{ width: "100%", height: 144 }}
          />
          <Typography
            sx={{
              color: (theme) => theme.palette.text.secondary,
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            Workloads gain keyless authentication with Mondoo.
          </Typography>
        </Flex>
      </Flex>
      <Flex alignItems="center" sx={{ mt: 10 }} flexDirection="column">
        <Flex>
          <Button
            disabled={!hasCreateWIFBindingPermission}
            component={RouterLink}
            to={`/space/settings/workload-identity/providers?${scope.params}`}
            variant="contained"
            color="primary"
            sx={{
              background: (theme) => theme.palette.primary.gradient,
              color: "common.white",
            }}
          >
            Add Provider
          </Button>
        </Flex>
        {!hasCreateWIFBindingPermission && (
          <Typography component="span" sx={{ fontSize: 10 }} color="error">
            You don't have permission to create a new provider.
          </Typography>
        )}
      </Flex>
    </Flex>
  );
};
