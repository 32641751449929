import {
  Box,
  Grid2,
  List,
  ListItem,
  Stack,
  TableContainer,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import {
  ConfigurationList,
  ConfigurationPaper,
} from "~/components/configuration-items";
import { ImpactUpdated } from "~/components/impact/Updated/impact-updated";
import { UseSlaConfiguration } from "./useSlaConfiguration";
import { ValidationMessage } from "~/pages/integrations/validations/helpers";
import { SlaRatings } from "./types";

export function SlaConfiguration() {
  const { form, slaFindings, onSubmit } = UseSlaConfiguration();

  return (
    <ConfigurationPaper className="sla-config">
      <ConfigurationList title="Service level agreement (SLA) configuration">
        <Grid2 container spacing={3} sx={{ p: 3 }}>
          <Grid2
            size="grow"
            className="sla-config-description"
            sx={{
              pb: 2,
              borderBottom: (theme) =>
                `1px solid ${theme.palette.background.lighter}`,
            }}
          >
            <Typography sx={{ fontWeight: 700, mb: 4 }}>
              Mondoo dashboards and reports can track your team's performance
              against your service level agreement (SLA) by flagging security
              findings that are nearing or past their SLA date.
            </Typography>
            <Typography>
              Define your SLA for findings based on their risk levels: Once
              Mondoo first discovers a finding, in how many days does your team
              commit to resolving the finding? And in how many days do you want
              a warning that the SLA is nearing?
            </Typography>
          </Grid2>
        </Grid2>
        <TableContainer sx={{ px: 1 }}>
          <Box
            component="form"
            className="sla-config-form"
            sx={{ pb: 3 }}
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <List
              sx={(theme) => ({
                ".sla-input": {
                  background: theme.palette.code.background,
                  borderRadius: 1,
                },
              })}
            >
              <ListItem>
                <Stack direction="row" sx={{ gap: 2, "& > *": { width: 176 } }}>
                  <Typography>Finding risk score</Typography>
                  <Typography>Days to resolve (SLA)</Typography>
                  <Typography>Days before warning</Typography>
                </Stack>
              </ListItem>
              {slaFindings?.map((sla) => {
                const rating = sla.rating as SlaRatings;
                const errors = form.formState.errors[rating];

                return (
                  <ListItem key={rating}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ gap: 2, "& > *": { width: 176 } }}
                    >
                      <ImpactUpdated
                        impact={{
                          rating: sla.rating,
                        }}
                        options={{ fontSize: 12, fontWeight: 700 }}
                      />
                      <TextField
                        {...form.register(`${rating}.daysToResolve` as const, {
                          required: true,
                          valueAsNumber: true,
                        })}
                        type="number"
                        slotProps={{ htmlInput: { min: 0 } }}
                        className="sla-input"
                        variant="outlined"
                        sx={{ ...helperTextStyles }}
                        helperText={
                          errors?.daysToResolve && (
                            <ValidationMessage
                              error={errors.daysBeforeWarning}
                            />
                          )
                        }
                      />
                      <TextField
                        {...form.register(
                          `${rating}.daysBeforeWarning` as const,
                          {
                            required: true,
                            valueAsNumber: true,
                          },
                        )}
                        type="number"
                        slotProps={{ htmlInput: { min: 0 } }}
                        className="sla-input"
                        variant="outlined"
                        sx={{ ...helperTextStyles }}
                        helperText={
                          errors?.daysBeforeWarning && (
                            <ValidationMessage
                              error={errors.daysBeforeWarning}
                            />
                          )
                        }
                      />
                    </Stack>
                  </ListItem>
                );
              })}
            </List>
            <Box
              className="sla-config-form-actions"
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: 2,
                pt: 4,
                pb: 0,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!form.formState.isDirty}
                data-testid="apply-sla-changes"
              >
                Save Changes
              </Button>
            </Box>
          </Box>
        </TableContainer>
      </ConfigurationList>
    </ConfigurationPaper>
  );
}

export const helperTextStyles = {
  ".MuiFormHelperText-root": {
    position: "absolute",
    top: "100%",
    fontSize: 8,
    m: 0,
    pt: 0,
    px: 1,
    backgroundColor: "background.paper",
  },
  ".MuiFormHelperText-root span": {
    fontSize: 12,
  },
};
