import React from "react";
import { SvgIcon, useTheme } from "@mui/material";
import { useColorMode } from "~/providers/color-mode";

export const CreateTicketingIntegrationThirdStep = ({ ...props }) => {
  const theme = useTheme();
  const { mode } = useColorMode();

  return (
    <SvgIcon {...props}>
      <svg
        width="210"
        height="144"
        viewBox="0 0 210 145"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1.25"
          y="0.5"
          width="191"
          height="127"
          rx="7.5"
          fill={theme.palette.background.light}
          stroke={theme.palette.background.lightest}
          strokeWidth="2"
        />
        <path
          d="M1.75 8C1.75 3.85786 5.10786 0.5 9.25 0.5H185.25C189.392 0.5 192.75 3.85786 192.75 8V15.5H1.75V8Z"
          fill={theme.palette.background.lighter}
          stroke={theme.palette.background.lightest}
          strokeWidth={2}
        />
        <g opacity="0.5">
          <circle
            cx="20.75"
            cy="84"
            r="8"
            fill="#2FC089"
            fillOpacity={mode === "light" ? "0.3" : "0.1"}
          />
          <path
            d="M19.1295 86.0105L17.0223 83.9033L16.3047 84.6158L19.1295 87.4406L25.1936 81.3766L24.4811 80.6641L19.1295 86.0105Z"
            fill="#2FC089"
          />
          <path
            d="M36.75 84C36.75 81.7909 38.5409 80 40.75 80H176.75C178.959 80 180.75 81.7909 180.75 84C180.75 86.2091 178.959 88 176.75 88H40.75C38.5409 88 36.75 86.2091 36.75 84Z"
            fill="#2FC089"
            fillOpacity={mode === "light" ? "0.3" : "0.1"}
          />
        </g>
        <circle
          cx="20.75"
          cy="36"
          r="7"
          fill={theme.palette.background.light}
          stroke="#9147FF"
          strokeWidth="2"
        />
        <path
          d="M36.75 36C36.75 33.7909 38.5409 32 40.75 32H176.75C178.959 32 180.75 33.7909 180.75 36C180.75 38.2091 178.959 40 176.75 40H40.75C38.5409 40 36.75 38.2091 36.75 36Z"
          fill={theme.palette.background.lightest}
        />
        <g opacity="0.5">
          <circle
            cx="20.75"
            cy="108"
            r="8"
            fill="#2FC089"
            fillOpacity={mode === "light" ? "0.3" : "0.1"}
          />
          <path
            d="M19.1295 110.011L17.0223 107.903L16.3047 108.616L19.1295 111.441L25.1936 105.377L24.4811 104.664L19.1295 110.011Z"
            fill="#2FC089"
          />
          <path
            d="M36.75 108C36.75 105.791 38.5409 104 40.75 104H176.75C178.959 104 180.75 105.791 180.75 108C180.75 110.209 178.959 112 176.75 112H40.75C38.5409 112 36.75 110.209 36.75 108Z"
            fill="#2FC089"
            fillOpacity={mode === "light" ? "0.3" : "0.1"}
          />
        </g>
        <circle
          cx="193.25"
          cy="128"
          r="16"
          fill="url(#paint0_linear_3126_107764)"
        />
        <path
          d="M191.336 127.336H192.539C192.924 127.336 193.242 127.271 193.492 127.141C193.742 127.005 193.927 126.818 194.047 126.578C194.172 126.333 194.234 126.049 194.234 125.727C194.234 125.435 194.177 125.177 194.062 124.953C193.953 124.724 193.784 124.547 193.555 124.422C193.326 124.292 193.036 124.227 192.688 124.227C192.411 124.227 192.156 124.281 191.922 124.391C191.688 124.5 191.5 124.654 191.359 124.852C191.219 125.049 191.148 125.289 191.148 125.57H188.891C188.891 124.945 189.057 124.401 189.391 123.938C189.729 123.474 190.182 123.112 190.75 122.852C191.318 122.591 191.943 122.461 192.625 122.461C193.396 122.461 194.07 122.586 194.648 122.836C195.227 123.081 195.677 123.443 196 123.922C196.323 124.401 196.484 124.995 196.484 125.703C196.484 126.062 196.401 126.411 196.234 126.75C196.068 127.083 195.828 127.385 195.516 127.656C195.208 127.922 194.833 128.135 194.391 128.297C193.948 128.453 193.451 128.531 192.898 128.531H191.336V127.336ZM191.336 129.047V127.883H192.898C193.518 127.883 194.062 127.953 194.531 128.094C195 128.234 195.393 128.438 195.711 128.703C196.029 128.964 196.268 129.273 196.43 129.633C196.591 129.987 196.672 130.38 196.672 130.812C196.672 131.344 196.57 131.818 196.367 132.234C196.164 132.646 195.878 132.995 195.508 133.281C195.143 133.568 194.716 133.786 194.227 133.938C193.737 134.083 193.203 134.156 192.625 134.156C192.146 134.156 191.674 134.091 191.211 133.961C190.753 133.826 190.336 133.625 189.961 133.359C189.591 133.089 189.294 132.75 189.07 132.344C188.852 131.932 188.742 131.445 188.742 130.883H191C191 131.174 191.073 131.435 191.219 131.664C191.365 131.893 191.565 132.073 191.82 132.203C192.081 132.333 192.37 132.398 192.688 132.398C193.047 132.398 193.354 132.333 193.609 132.203C193.87 132.068 194.068 131.88 194.203 131.641C194.344 131.396 194.414 131.112 194.414 130.789C194.414 130.372 194.339 130.039 194.188 129.789C194.036 129.534 193.82 129.346 193.539 129.227C193.258 129.107 192.924 129.047 192.539 129.047H191.336Z"
          fill="white"
        />
        <line
          x1="9.25"
          y1="59.5"
          x2="184.25"
          y2="59.5"
          stroke={theme.palette.background.lightest}
          strokeLinecap="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_3126_107764"
            x1="177.25"
            y1="112"
            x2="210.039"
            y2="143.17"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#9147FF" />
            <stop offset="1" stopColor="#4C35E8" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
};
