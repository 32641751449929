import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { Command } from "~/components/guides/components";
import { Control, Controller } from "react-hook-form";
import {
  EMAIL_PATTERN,
  helperTextStyles,
  JIRA_HOST_PATTERN,
  ValidationMessage,
} from "~/pages/integrations/validations/helpers";
import { JiraFormInput } from "~/pages/integrations/hosted-integrations/forms/jira/types";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { UseFormGetFieldState } from "react-hook-form/dist/types/form";
import { ChevronRightIcon } from "~/components/icons";
import { JiraProject } from "~/operations";
import { PasswordField } from "~/components/Form/components/PasswordField";

type JiraFormStep1Props = {
  control: Control<JiraFormInput>;
  errors: FieldErrors<JiraFormInput>;
  isUpdateFlow: boolean;
  isValid: boolean;
  getFieldState: UseFormGetFieldState<JiraFormInput>;
  disabled: boolean;
  isProjectsEmpty: boolean;
  projects: JiraProject[] | null | undefined;
};

export const JiraFormStep1 = ({
  control,
  errors,
  isUpdateFlow,
  getFieldState,
  isValid,
}: JiraFormStep1Props) => {
  const theme = useTheme();

  return (
    <>
      {/* Step 1 */}
      <Box pb={4}>
        <Command
          number={1}
          options={{
            fontSize: { xs: 16 },
            dotColor: theme.palette.background.lightest,
          }}
        >
          Choose an integration name
        </Command>

        <Box>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ mb: 2, mt: 2 }}
          >
            Please choose a descriptive name that lets you easily identify your
            integration.
          </Typography>
          <Controller
            name="integrationName"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                sx={{
                  background: theme.palette.code.background,
                  borderRadius: 1,
                  color: "text.primary",
                  ...helperTextStyles,
                }}
                placeholder="Your integration name..."
                error={Boolean(errors.integrationName)}
                helperText={
                  Boolean(errors.integrationName) && (
                    <ValidationMessage error={errors.integrationName} />
                  )
                }
              />
            )}
          />
        </Box>
      </Box>

      {/* Step 2 */}
      <Box pb={4}>
        <Command
          number={2}
          options={{
            fontSize: { xs: 16 },
            dotColor: theme.palette.background.lightest,
          }}
        >
          Provide your Jira credentials
        </Command>

        <Box>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ mb: 2, mt: 2 }}
          >
            Please provide the credentials for the Jira installation you want to
            integrate with. You may need to contact your project owner to access
            this information.
            {/* For more help, check the{" "}
                <Link href="#" target="_blank" rel="noopener">
                  Atlassian documentation
                </Link>
                . */}
          </Typography>
          <Typography fontWeight={700} sx={{ mb: 1 }}>
            Jira host
          </Typography>
          <Controller
            name="host"
            control={control}
            rules={{
              required: true,
              validate: {
                isDomain: (value) =>
                  Boolean(value.match(JIRA_HOST_PATTERN)) ||
                  "Not a valid domain",
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                disabled={Boolean(isUpdateFlow)}
                sx={{
                  background: theme.palette.code.background,
                  borderRadius: 1,
                  color: "text.primary",
                  ...helperTextStyles,
                }}
                placeholder={"https://your-instance.atlassian.net/"}
                error={getFieldState("host").isTouched && Boolean(errors.host)}
                helperText={
                  Boolean(errors.host) &&
                  getFieldState("host").isTouched && (
                    <ValidationMessage error={errors.host} />
                  )
                }
              />
            )}
          />
          <Typography fontWeight={700} sx={{ mb: 1, mt: 3 }}>
            Email address
          </Typography>
          <Controller
            name="email"
            control={control}
            rules={{
              required: true,
              validate: {
                isEmail: (value) =>
                  Boolean(value.match(EMAIL_PATTERN)) || "Not a valid email",
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                sx={{
                  background: theme.palette.code.background,
                  borderRadius: 1,
                  color: "text.primary",
                  ...helperTextStyles,
                }}
                placeholder={"jira-owner@email.com"}
                error={
                  getFieldState("email").isTouched && Boolean(errors.email)
                }
                helperText={
                  Boolean(errors.email) &&
                  getFieldState("email").isTouched && (
                    <ValidationMessage error={errors.email} />
                  )
                }
              />
            )}
          />
          <Typography fontWeight={700} sx={{ mb: 1, mt: 3 }}>
            {isUpdateFlow ? "Update " : ""}API token
          </Typography>
          <Controller
            name="apiToken"
            control={control}
            rules={{
              required: !isUpdateFlow,
            }}
            render={({ field }) => (
              <PasswordField
                {...field}
                fullWidth
                sx={{
                  background: theme.palette.code.background,
                  borderRadius: 1,
                  color: "text.primary",
                  ...helperTextStyles,
                }}
                placeholder={
                  isUpdateFlow ? "●●●●●●●●●●●●●●●●" : "Your API key ..."
                }
                error={
                  getFieldState("apiToken").isTouched &&
                  Boolean(errors.apiToken)
                }
                helperText={
                  Boolean(errors.apiToken) &&
                  getFieldState("apiToken").isTouched && (
                    <ValidationMessage error={errors.apiToken} />
                  )
                }
              />
            )}
          />
        </Box>
      </Box>
      {/*      <Box sx={{ display: "flex", justifyContent: "center", mt: 10 }}>
        <Button
          sx={{ textTransform: "uppercase" }}
          type="submit"
          variant="contained"
          color="primary"
          endIcon={<ChevronRightIcon />}
          disabled={!isValid}
        >
          Finalize setup
        </Button>
      </Box>*/}
      <Box sx={{ display: "flex", justifyContent: "end", mt: 5 }}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          endIcon={<ChevronRightIcon />}
          // setValue doesn't trigger the required validation so we're doing it manually here
          disabled={!isValid}
        >
          {isUpdateFlow ? "update configuration" : "create integration"}
        </Button>
      </Box>
    </>
  );
};
