import { Fragment } from "react/jsx-runtime";
import { SectionHeading } from "../../components";
import { ScoreRating } from "~/operations";
import { Grid2, TableCell, TableRow } from "@mui/material";
import {
  ImpactCell,
  TitleCell,
  RiskFactorsCell,
  PublishedCell,
  RankCell,
} from "~/components/Cells";
import { GenericTable } from "~/components/GenericTable/GenericTable";
import { NoItemsSection } from "../components";
import { UseCveOrAdvisoryReportReturnObject } from "./hooks/useCveOrAdvisoryReport";

type VulnerabilitiesProps = {
  vulnerabilities: UseCveOrAdvisoryReportReturnObject["data"]["vulnerabilities"];
};

export function Vulnerabilities({ vulnerabilities }: VulnerabilitiesProps) {
  const hasVulnerabilities = Boolean(vulnerabilities?.length);

  return (
    <Fragment>
      <SectionHeading heading="Vulnerabilities" disableUpperPadding />
      <Grid2 size={12} sx={{ my: 3 }}>
        {!hasVulnerabilities ? (
          <NoItemsSection target="vulnerabilities" />
        ) : (
          <GenericTable
            items={0}
            tableName="ASSET_REPORT_VULNERABILITIES"
            validSortFields={[]}
            printView
          >
            {vulnerabilities?.map((node) => {
              return (
                <TableRow key={node?.id}>
                  <ImpactCell
                    impact={node?.riskValue || 0}
                    rating={node?.rating || ScoreRating.Critical}
                    isActive
                  />
                  <TitleCell
                    title={node?.id || ""}
                    options={{ fontSize: 14 }}
                  />
                  <TableCell></TableCell>
                  {/* <RiskFactorsCell
                    riskFactors={node?.vulnerabilityRiskFactors}
                    printView
                  /> */}
                  <PublishedCell
                    date={node?.publishedAt || "Never"}
                    formatting="absolute"
                  />
                </TableRow>
              );
            })}
          </GenericTable>
        )}
      </Grid2>
    </Fragment>
  );
}
