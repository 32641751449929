import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { TableHead } from "~/pages/inventory/components/TableHead";
import { Header } from "~/types/table";

import {
  INITIAL_PAGE_RANGE,
  Pagination,
  PaginationRange,
} from "~/components/pagination";
import React, { useState } from "react";
import { CaseTitleCell } from "~/components/cases/components/CasesTable/CaseTitleCell";
import { PageInfo, useGetCasesQuery } from "~/operations";
import { ListCaseEdges } from "~/components/cases/types";
import { CloseCaseButtonCell } from "~/components/cases/components/CasesTable/CloseCaseButtonCell";
import { Link } from "react-router-dom";
import { ClosedCaseCell } from "~/components/cases/components/CasesTable/ClosedCaseCell";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";

type VulnerabilitiesTableProps = {
  fetchMore: ReturnType<typeof useGetCasesQuery>["fetchMore"];
  cases: ListCaseEdges | null | undefined;
  pageInfo: PageInfo | undefined;
  totalCount: number;
  isLoading: boolean;
  onClose?: (mrn: string) => void;
  isOpen?: boolean;
  scope: SpaceOrWorkspaceScope;
};

export const CasesTable = ({
  fetchMore,
  cases,
  pageInfo,
  totalCount,
  isLoading,
  onClose,
  isOpen = false,
  scope,
}: VulnerabilitiesTableProps) => {
  const [pageItems, setPageItems] =
    useState<PaginationRange>(INITIAL_PAGE_RANGE);

  const paginatedCases =
    cases?.slice(pageItems.from, pageItems.to)?.flatMap((e) => e.node ?? []) ||
    [];

  const tableHeaders: Header[] = [
    {
      id: "EMPTY_CELL",
      label: " ",
      options: {
        width: "16px",
      },
      sortable: false,
    },
    {
      id: "CASE",
      label: "Ticket",
      sortable: false,
    },
    {
      id: "CLOSE_CASE",
      label: " ",
      sortable: false,
    },
  ];

  return (
    <Paper sx={{ my: 3 }}>
      <TableContainer>
        <Table>
          <TableHead tableHeaders={tableHeaders} />
          <TableBody>
            {paginatedCases.map((c) => {
              const percentage = Math.floor(
                (c.closedRefsCount / c.totalRefsCount) * 100,
              );

              return (
                <TableRow
                  key={c.mrn}
                  component={Link}
                  to={`/space/case?${scope.params}&caseMrn=${c.mrn}`}
                  sx={{
                    "&.MuiTableRow-root:hover .firewatch-chip": {
                      backgroundColor: "background.lighter",
                    },
                    "&:hover .closeCase": {
                      display: "flex",
                    },
                    "&:hover .progressBar": {
                      display: "none",
                    },
                  }}
                  data-testid={`${isOpen ? "open" : "closed"}-case-table-row`}
                >
                  <TableCell></TableCell>
                  <CaseTitleCell
                    key={c.mrn}
                    isOpen
                    title={c.title}
                    assets={c.assetsCount}
                    createdAt={c.createdAt}
                    tags={c.tags || []}
                  />
                  {isOpen ? (
                    <CloseCaseButtonCell
                      percentage={percentage}
                      isLoading={isLoading}
                      isOpen={isOpen}
                      onClick={async (e) => {
                        e.preventDefault();
                        onClose?.(c.mrn);
                      }}
                    />
                  ) : (
                    <ClosedCaseCell />
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        fetchMore={fetchMore}
        pageInfo={pageInfo}
        totalCount={totalCount}
        setPageItems={setPageItems}
      />
    </Paper>
  );
};
