import { Box, Skeleton } from "@mui/material";

const DashboardSkeleton = () => {
  return (
    <Box
      sx={{
        height: "100%",
      }}
    >
      <Box
        sx={{
          height: "100%",
          bgcolor: "background.paper",
        }}
      >
        <Skeleton
          variant="rectangular"
          width="100%"
          height="100%"
          sx={{ borderRadius: 1 }}
        />
      </Box>
    </Box>
  );
};

export default DashboardSkeleton;
