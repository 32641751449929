import { useFindingOnAsset } from "~/pages/findings/FindingOnAsset/hooks";
import { CheckQueries } from "~/pages/space/security/components/Check/CheckQueries";
import { isCheckFinding } from "~/pages/findings/FindingOnAsset/utils";
import React from "react";

export const Queries = () => {
  const { checkData, finding } = useFindingOnAsset();

  if (
    (!checkData?.mql && checkData?.properties?.length === 0) ||
    !isCheckFinding(finding)
  )
    return;

  return (
    <CheckQueries
      properties={checkData?.properties || []}
      mql={checkData?.mql}
    />
  );
};
