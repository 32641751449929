import { Link as RouterLink } from "react-router-dom";
import { alpha, Box, Grid, Paper, Typography, useTheme } from "@mui/material";
import { ContentFlag } from "~/components/content-flag";
import { GradientArrow } from "~/images/icons/gradient-arrow";
import { Space } from "~/lib/types";
import { useColorMode } from "~/providers/color-mode";
import { DynamicIntegrationIcon } from "~/pages/integrations/components/dynamic-integration-icon";
import { IntegrationType } from "~/operations";

export type AvailableIntegration = {
  id: string;
  title: string;
  description: string;
  flags?: string[] | null;
};

type Props = {
  space: Space;
  integration: AvailableIntegration;
};

const TRANSITION_SPEED = "transform 325ms";

export const AvailableIntegrationCard = ({ space, integration }: Props) => {
  const href = `/space/integrations/add/${integration.id}?spaceId=${space.id}`;

  return (
    <Box component={RouterLink} to={href}>
      <Paper
        sx={{
          height: 1,
          p: 3,
          minHeight: 216,
          "&:hover": {
            transform: "scale(1.05)",
            transition: TRANSITION_SPEED,
          },
          transition: TRANSITION_SPEED,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            mb: 3,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography fontSize={24} fontWeight="fontWeightBold">
              {integration.title}
            </Typography>
            {integration.flags?.map((flag) => (
              <ContentFlag
                key={flag}
                flag={flag}
                color="warning"
                layout={{ ml: 2 }}
              />
            ))}
          </Box>

          <GradientArrow />
        </Box>
        <Typography color="text.secondary">
          {integration.description}
        </Typography>
      </Paper>
    </Box>
  );
};

export const RecommendedAvailableIntegrationCard = ({
  space,
  integration,
}: {
  space: Space;
  integration: AvailableIntegration;
}) => {
  const href = `/space/integrations/add/${integration.id}?spaceId=${space.id}`;
  const theme = useTheme();
  const { mode } = useColorMode();

  const mapIdToIntegrationType = (title: string) => {
    switch (title.toLowerCase()) {
      case "mondoo/aws":
      case "amazon aws":
      case "aws":
        return IntegrationType.Aws;
      case "mondoo/azure":
      case "azure":
        return IntegrationType.Azure;
      case "mondoo/kubernetes":
      case "kubernetes":
      case "k8s":
        return IntegrationType.K8S;
      case "mondoo/local":
      case "managed":
      case "managed_client":
        return IntegrationType.ManagedClient;
      default:
        return IntegrationType.ManagedClient;
    }
  };
  return (
    <Box
      component={RouterLink}
      to={href}
      data-name={`add-recommended-integration-${integration.title.replaceAll(
        " ",
        "-",
      )}`}
    >
      <Paper
        sx={{
          height: 1,
          p: 3,
          backgroundColor: mode === "light" ? "" : "background.light",
          transition: TRANSITION_SPEED,
          "&:hover": {
            transform: "scale(1.05)",
            transition: TRANSITION_SPEED,
          },
        }}
      >
        <Grid container columnGap={3}>
          <Grid item sx={{ minWidth: 50 }}>
            <DynamicIntegrationIcon
              {...{
                type: mapIdToIntegrationType(integration.title),
                sx: { fontSize: 48 },
              }}
            />
          </Grid>
          <Grid item xs>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                mb: 3,
              }}
            >
              <Box>
                <Typography
                  fontSize={24}
                  fontWeight="fontWeightBold"
                  lineHeight={"24px"}
                >
                  {integration.title}
                </Typography>
                <ContentFlag
                  flag="recommended"
                  color="recommended"
                  layout={{
                    mt: 1,
                    ...(mode === "light"
                      ? {
                          color: "warning.dark",
                          background: alpha(theme.palette.warning.main, 0.5),
                        }
                      : {}),
                  }}
                />
              </Box>

              <GradientArrow />
            </Box>
          </Grid>
        </Grid>
        <Typography color="text.secondary">
          {integration.description}
        </Typography>
      </Paper>
    </Box>
  );
};
