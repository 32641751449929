import SvgIcon from "@mui/material/SvgIcon";
import { useTheme } from "@mui/material";

export const WorkloadIdentityBindingsEmptyStateStepTwo = ({ ...props }) => {
  const theme = useTheme();

  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 208 144" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
          x="1"
          y="1"
          width="190"
          height="126"
          rx="7"
          fill={theme.palette.background.default}
          stroke={theme.palette.background.lightest}
          stroke-width="2"
        />
        <path
          d="M1 8C1 4.13401 4.13401 1 8 1H184C187.866 1 191 4.13401 191 8V15H1V8Z"
          fill={theme.palette.background.light}
          stroke={theme.palette.background.lightest}
          stroke-width="2"
        />
        <circle
          cx="192"
          cy="128"
          r="16"
          fill="url(#paint0_linear_2651_29118)"
        />
        <path
          d="M195.562 132.242V134H187.781V132.5L191.461 128.555C191.831 128.143 192.122 127.781 192.336 127.469C192.549 127.151 192.703 126.867 192.797 126.617C192.896 126.362 192.945 126.12 192.945 125.891C192.945 125.547 192.888 125.253 192.773 125.008C192.659 124.758 192.49 124.565 192.266 124.43C192.047 124.294 191.776 124.227 191.453 124.227C191.109 124.227 190.812 124.31 190.562 124.477C190.318 124.643 190.13 124.875 190 125.172C189.875 125.469 189.812 125.805 189.812 126.18H187.555C187.555 125.503 187.716 124.883 188.039 124.32C188.362 123.753 188.818 123.302 189.406 122.969C189.995 122.63 190.693 122.461 191.5 122.461C192.297 122.461 192.969 122.591 193.516 122.852C194.068 123.107 194.484 123.477 194.766 123.961C195.052 124.44 195.195 125.013 195.195 125.68C195.195 126.055 195.135 126.422 195.016 126.781C194.896 127.135 194.724 127.49 194.5 127.844C194.281 128.193 194.016 128.547 193.703 128.906C193.391 129.266 193.044 129.638 192.664 130.023L190.688 132.242H195.562Z"
          fill="white"
        />
        <path
          d="M50.5781 64.8073L54.0424 61.343L54.2733 59.8843C47.9604 54.144 37.9253 54.7949 32.2345 61.2424C30.6538 63.0332 29.4811 65.2657 28.8557 67.5712L30.0965 67.3963L37.0249 66.2538L37.5598 65.7068C40.6417 62.322 45.8527 61.8667 49.4112 64.7466L50.5781 64.8073Z"
          fill="#EA4335"
        />
        <path
          d="M58.9775 67.4687C58.1812 64.5364 56.5464 61.9003 54.2734 59.8838L49.4113 64.7459C51.4643 66.4235 52.6339 68.9507 52.5838 71.6015V72.4645C54.9736 72.4645 56.9112 74.4019 56.9112 76.7917C56.9112 79.1817 54.9736 81.0705 52.5838 81.0705H43.9171L43.0662 81.9943V87.1846L43.9171 87.999H52.5838C58.8 88.0474 63.8785 83.0961 63.9269 76.8799C63.9562 73.112 62.0985 69.5797 58.9775 67.4687Z"
          fill="#4285F4"
        />
        <path
          d="M35.2621 88.0001H43.9167V81.0715H35.2621C34.6455 81.0713 34.0483 80.9389 33.4876 80.6826L32.2597 81.0594L28.7712 84.5236L28.4673 85.7027C30.4237 87.18 32.8107 88.0107 35.2621 88.0001Z"
          fill="#34A853"
        />
        <path
          d="M35.2621 65.5245C29.0457 65.5615 24.0366 70.631 24.0737 76.8472C24.0944 80.3185 25.7156 83.5862 28.4672 85.7023L33.4875 80.6822C31.3094 79.6981 30.3416 77.1349 31.3256 74.957C32.3095 72.779 34.8729 71.8112 37.0506 72.7951C38.0104 73.2286 38.779 73.9974 39.2125 74.957L44.2328 69.9368C42.0968 67.1445 38.7777 65.5119 35.2621 65.5245Z"
          fill="#FBBC05"
        />
        <path
          d="M84 64.5C83.4477 64.5 83 64.9477 83 65.5C83 66.0523 83.4477 66.5 84 66.5V64.5ZM108.707 66.2071C109.098 65.8166 109.098 65.1834 108.707 64.7929L102.343 58.4289C101.953 58.0384 101.319 58.0384 100.929 58.4289C100.538 58.8195 100.538 59.4526 100.929 59.8431L106.586 65.5L100.929 71.1569C100.538 71.5474 100.538 72.1805 100.929 72.5711C101.319 72.9616 101.953 72.9616 102.343 72.5711L108.707 66.2071ZM84 66.5H108V64.5H84V66.5Z"
          fill="#A07AFF"
        />
        <path
          d="M83.2929 77.7929C82.9024 78.1834 82.9024 78.8166 83.2929 79.2071L89.6569 85.5711C90.0474 85.9616 90.6805 85.9616 91.0711 85.5711C91.4616 85.1805 91.4616 84.5474 91.0711 84.1569L85.4142 78.5L91.0711 72.8431C91.4616 72.4526 91.4616 71.8195 91.0711 71.4289C90.6805 71.0384 90.0474 71.0384 89.6569 71.4289L83.2929 77.7929ZM108 79.5C108.552 79.5 109 79.0523 109 78.5C109 77.9477 108.552 77.5 108 77.5V79.5ZM84 79.5H108V77.5H84V79.5Z"
          fill="#A07AFF"
        />
        <path
          d="M160 60V83.9993C166.627 83.9993 172 78.6273 172 71.999C172 65.3708 166.627 60 160 60Z"
          fill="currentColor"
        />
        <path
          d="M148.001 84.0005C154.628 84.0005 160.001 78.6278 160.001 72.0003C160.001 65.3727 154.628 60 148.001 60C141.373 60 136 65.3727 136 72.0003C136 78.6278 141.373 84.0005 148.001 84.0005Z"
          fill="currentColor"
        />
        <path
          d="M124 72.0003C124 78.6273 129.372 84.0005 136 84.0005V60C129.372 60 124 65.372 124 72.0003Z"
          fill="currentColor"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2651_29118"
            x1="176"
            y1="112"
            x2="208.789"
            y2="143.17"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#9147FF" />
            <stop offset="1" stop-color="#4C35E8" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
};
