import { Fragment, useState } from "react";
import {
  alpha,
  Box,
  Grid,
  IconButton,
  IconButtonProps,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { LastOperations } from "~/components/asset-last-operations";
import {
  DeleteIcon,
  FileCopyIcon,
  InfoOutlinedIcon,
  MoreHorizIcon,
  PrintIcon,
} from "~/components/icons";
import {
  AssetForwardPaginationDocument,
  DocumentType,
  IntegrationStatistics,
  LoadSpaceStatsDocument,
  useDeleteAssetsMutation,
} from "~/operations";
import { Asset, Project } from "~/pages/inventory/asset/types";
import { Space } from "~/lib/types";
import { IamActions } from "~/lib/iam";
import { copyToClipboard } from "~/lib/clipboard";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useViewer } from "~/providers/viewer";
import { DeleteConfirmDialog } from "~/components/delete-confirm-dialog";
import { useWarnings } from "~/pages/inventory/hooks";
import { AssetInsights } from "./assetInsights";
import { ImpactData } from "~/components/impact/types";
import { ImpactUpdated } from "~/components/impact/Updated/impact-updated";
import { useImpactUpdated } from "~/components/impact/Updated/useImpactUpdated";
import { WarningMessage } from "~/components/user-warning";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";
import { isFeatureEnabled } from "~/login/features";
import { GenerateReportButton } from "~/pages/compliance/components/generate-report";
import { CasesSummary } from "~/components/cases/components/CasesSummary";
import { RiskFactorsIcons } from "~/pages/space/security/components/RiskFactors/RiskFactorsIcons";
import { MONDOO_ASSET_SCORE_DOC_URL } from "../../../../../../../constants";
import { getAssetMrn } from "~/utils/urlUtils";

type AssetOverviewHeaderProps = {
  asset: Asset;
  activeIntegrations?: IntegrationStatistics[];
  space: Space;
  scope: SpaceOrWorkspaceScope;
  vulnerabilityExceptionsCount: number;
  securityExceptionsCount: number;
  project?: Project | null;
  isCicd?: boolean | null;
  casesCount: number;
};

export function AssetOverviewHeader({
  asset,
  space,
  scope,
  securityExceptionsCount,
  vulnerabilityExceptionsCount,
  project,
  isCicd = false,
  casesCount,
}: AssetOverviewHeaderProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { viewerSettings } = useViewer();
  const { warnings } = useWarnings({ asset });
  const [moreActionsAnchorEl, setMoreActionsAnchorEl] =
    useState<HTMLElement | null>(null);
  const moreActionsOpen = Boolean(moreActionsAnchorEl);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [deleteConfirmIsOpen, setDeleteConfirmIsOpen] =
    useState<boolean>(false);

  const assetId = asset.mrn?.split("/").pop() || id;

  const assetMrn = asset.mrn || getAssetMrn(space.id, assetId);

  const [deleteAssets] = useDeleteAssetsMutation({
    refetchQueries: [AssetForwardPaginationDocument, LoadSpaceStatsDocument], // another refetch query for fleet
  });

  const hasDeleteAssetPermission = space.iamActions.includes(
    IamActions.ASSETS_DELETEASSET,
  );

  const handleDeleteAsset = async () => {
    setIsDeleting(true);

    try {
      await deleteAssets({
        variables: { input: { spaceMrn: space.mrn, assetMrns: [assetMrn] } },
        update: (cache) =>
          cache.evict({ id: "ROOT_QUERY", fieldName: "assets" }),
      });
      enqueueSnackbar("Successfully deleted asset", { variant: "success" });
      isCicd && project
        ? navigate(
            `/space/cicd/jobs?spaceId=${space.id}&projectId=${project.id}`,
          )
        : isCicd
          ? navigate(`/space/cicd?spaceId=${space.id}`)
          : navigate(`/space/inventory?spaceId=${space.id}`);
    } catch (error) {
      setIsDeleting(false);
      enqueueSnackbar("Failed to delete asset", { variant: "error" });
    }
  };

  const handleCancelDelete = () => {
    setDeleteConfirmIsOpen(false);
    setIsDeleting(false);
  };

  const handleDeleteIconClick = () => {
    if (viewerSettings?.assetDoNotAskToDelete) {
      handleDeleteAsset();
    } else {
      setDeleteConfirmIsOpen(true);
    }
  };

  const handlePopoverOpen: IconButtonProps["onClick"] = (event) => {
    setMoreActionsAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setMoreActionsAnchorEl(null);
  };

  const handleCopyClick = () => {
    copyToClipboard(assetMrn);
    setMoreActionsAnchorEl(null);
  };

  const titleCharacterCount = asset.name?.length || 0;

  const lastScan = asset.updatedAt;
  const { color } = useImpactUpdated({
    impact: { value: asset.score.riskValue, rating: asset.score.riskRating },
    isActive: true,
  });

  const tooltipContent = (
    <>
      An asset's risk score reflects how much the asset exposes your
      infrastructure to attack. 0 is low risk and 100 is high risk. The risk
      score is an average of the security score and the vulnerability score. To
      learn more, read the{" "}
      <Link href={MONDOO_ASSET_SCORE_DOC_URL} target="_blank">
        Mondoo documentation
      </Link>
      .
    </>
  );

  return (
    <Fragment>
      <DeleteConfirmDialog
        title="Are you Sure?"
        content="If you delete an asset from Mondoo, you delete all data related to the asset. You can re-add the asset, but doing so does not recover historical data."
        dividers={false}
        open={deleteConfirmIsOpen}
        onConfirm={handleDeleteAsset}
        onClose={handleCancelDelete}
        isDeleting={isDeleting}
        includeDoNotAskCheck={true}
      />
      <Grid container className="asset-overview-header">
        <Grid container item>
          <Grid
            item
            xs={12}
            md={titleCharacterCount > 45 ? 9 : "auto"}
            sx={{ display: "flex" }}
          >
            <Typography
              variant="h4"
              fontWeight={700}
              sx={{
                wordBreak: "break-word",
                overflowWrap: "anywhere",
              }}
            >
              {asset.name}{" "}
              <RiskFactorsIcons
                riskFactors={asset.riskFactors}
                shouldLimit={false}
                showBackgroundColor
              />
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          justifyContent="space-between"
          sx={{
            color: "text.secondary",
            mb: 1.625,
          }}
        >
          <Grid item xs={12} sm="auto">
            <Typography display="inline" fontSize={12} lineHeight={"31.92px"}>
              {asset.platform?.title}
            </Typography>
          </Grid>
          <Grid item xs={9} sm="auto">
            <LastOperations lastScan={lastScan} updatedAt={asset.updatedAt} />
          </Grid>

          <Grid item xs display="flex" justifyContent="end">
            <Fragment />
            {isFeatureEnabled("Reporting") ? (
              <GenerateReportButton
                documentType={DocumentType.AssetReport}
                title={asset.name || "Generated Asset Report"}
                space={space}
                assetMrn={assetMrn}
                size="large"
                IconProps={{
                  fontSize: undefined,
                }}
              />
            ) : (
              <Tooltip title="Print asset report" arrow placement="top">
                <IconButton
                  size="large"
                  href={`/print?what=asset&assetMrn=${asset.mrn}`}
                  target="_blank"
                >
                  <PrintIcon />
                </IconButton>
              </Tooltip>
            )}

            <Tooltip title="More" arrow placement="top">
              <IconButton
                size="large"
                aria-owns={moreActionsOpen ? "more-actions-menu" : undefined}
                aria-haspopup="true"
                onClick={handlePopoverOpen}
              >
                <MoreHorizIcon />
              </IconButton>
            </Tooltip>
            <Menu
              id="more-actions-menu"
              open={moreActionsOpen}
              onClose={handlePopoverClose}
              anchorEl={moreActionsAnchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem onClick={handleCopyClick}>
                <ListItemIcon>
                  <FileCopyIcon />
                </ListItemIcon>
                <ListItemText>Copy MRN</ListItemText>
              </MenuItem>
              {hasDeleteAssetPermission && (
                <MenuItem onClick={handleDeleteIconClick}>
                  <ListItemIcon>
                    <DeleteIcon
                      sx={{
                        color: "error.main",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText>Delete Asset</ListItemText>
                </MenuItem>
              )}
            </Menu>
          </Grid>

          {casesCount > 0 && (
            <Grid item xs={12} mt={2}>
              <CasesSummary
                count={casesCount}
                scope={scope}
                assetId={assetId}
              />
            </Grid>
          )}

          {warnings && (
            <Grid item xs={12} mb={3}>
              <Grid container>
                {warnings.map((warning) => {
                  const { severity, msg } = warning;
                  return (
                    <Grid item xs={12} key={msg}>
                      <WarningMessage {...{ severity, msg }} />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          )}

          {asset && (
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={12} sm="auto">
                <Box
                  sx={{
                    minHeight: "100%",
                    p: 3,
                    background: alpha(color, 0.1),
                    borderRadius: 1,
                    boxShadow: `inset 0 0 0 2px ${color}`,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Box
                    sx={{ display: "flex", justifyContent: "center", gap: 1 }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        fontWeight: 700,
                        textTransform: "uppercase",
                        color,
                      }}
                    >
                      Asset Risk Score
                    </Typography>
                    <Tooltip title={tooltipContent} arrow placement="top">
                      <IconButton
                        size="small"
                        href={MONDOO_ASSET_SCORE_DOC_URL}
                        target="_blank"
                        sx={{ fontSize: "inherit", p: 0, px: "1px" }}
                      >
                        <InfoOutlinedIcon fontSize="inherit" sx={{ color }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 3,
                      flexWrap: "wrap",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontSize: 72,
                          fontWeight: 700,
                          color: color,
                          lineHeight: "100%",
                        }}
                      >
                        {asset.score.riskValue}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        gap: 1,
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          textTransform: "uppercase",
                          fontWeight: 700,
                          color: "text.primary",
                        }}
                      >
                        {asset.score.riskRating}
                      </Typography>
                      <ImpactUpdated
                        impact={{
                          value: asset.score.riskValue,
                          rating: asset.score.riskRating,
                        }}
                        showText={false}
                        isRiskScore
                        hideTooltip
                        options={{
                          size: 16,
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm>
                <AssetInsights
                  assetMrn={asset.mrn}
                  insights={asset.insights}
                  vulnerabilityExceptionsCount={vulnerabilityExceptionsCount}
                  securityExceptionsCount={securityExceptionsCount}
                  space={space}
                  scope={scope}
                  isCicd={Boolean(isCicd)}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
}

export const getRating = (value: number): ImpactData["rating"] => {
  if (value >= 1 && value <= 100) {
    const step = 100 / 5; // Divide the range into 5 equal steps
    let rating = Math.ceil(value / step); // Round up to the nearest whole number
    return rating as ImpactData["rating"];
  }
  return 1;
};
