import { Flex } from "~/components/Flex";
import React from "react";
import { SpaceScope } from "~/hooks/useScope";
import { WorkloadIdentityProviderConfiguration } from "~/pages/space-settings/WorkloadIdentity/components/WorkloadIdentityProviderForm/WorkloadIdentityProviderConfiguration";
import { WifExternalAuthConfig } from "~/operations";
import { Box, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from "~/components/icons";

type WorkloadIdentityProviderFormFinalizeProps = {
  config: WifExternalAuthConfig | null;
  scope: SpaceScope;
};

export const WorkloadIdentityProviderFormFinalize = ({
  scope,
  config,
}: WorkloadIdentityProviderFormFinalizeProps) => {
  return (
    <Flex mt={5} flexDirection="column">
      <WorkloadIdentityProviderConfiguration config={config} />
      <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
        <Button
          component={Link}
          to={`/space/settings/workload-identity?${scope.params}`}
          variant="contained"
          color="primary"
          endIcon={<ChevronRightIcon />}
        >
          Finalize setup
        </Button>
      </Box>
    </Flex>
  );
};
