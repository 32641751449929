/**
 * Sets the document segments with consistent formatting.
 * Joins the provided segments with " · " and appends " · Mondoo" at the end.
 * @param segments - String or array of strings to be joined
 */
export const setDocumentTitle = (
  segments: string | (string | null | undefined)[],
) => {
  if (!segments?.length) {
    document.title = "Mondoo";
    return;
  }

  if (typeof segments === "string") {
    document.title = `${segments} · Mondoo`;
    return;
  }

  const title = segments.filter(Boolean).join(" · ");
  document.title = title ? `${title} · Mondoo` : "Mondoo";
};
