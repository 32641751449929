import { Fragment, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { AwsGuide } from "./aws-guide";
import { VastEmptyness } from "./vast-emptyness/vast-emptyness";
import { HomeDashboard } from "~/pages/space/Dashboards/components/HomeDashboard";
import { ScopeType, SpaceOrWorkspaceScope, SpaceScope } from "~/hooks/useScope";
import { useInventory } from "./inventory/hooks";
import { setDocumentTitle } from "~/utils/commonUtils";

export type DashboardContentProps = {
  scope: SpaceOrWorkspaceScope;
  space: SpaceScope;
};

export function DashboardContent({ scope, space }: DashboardContentProps) {
  const { assetsTotalCount, loading } = useInventory({ scope });
  const noData = assetsTotalCount === 0;

  if (noData && !loading && scope.type === ScopeType.Space) {
    const VastEmptynessOptions = {
      id: "overview",
      headline: "WELCOME TO MONDOO",
      tagline:
        "To begin scanning for vulnerabilities, let's integrate your infrastructure with Mondoo.",
      buttonText: "Start Scanning",
      href: `/space/integrations/add?${scope.params}#serverendpointsecurity`,
      buttonText2: "Browse Integrations",
      hrefButton2: `/space/integrations/add?${scope.params}`,
    };

    return <VastEmptyness options={VastEmptynessOptions} />;
  }

  return <HomeDashboard scope={scope} space={space} />;
}

type SpaceDashboardProps = {
  space: SpaceScope;
  scope: SpaceOrWorkspaceScope;
};

export function SpaceDashboard({ space, scope }: SpaceDashboardProps) {
  let [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setOpen(Boolean(searchParams.get("guide") === "aws"));
  }, []);

  const handleClose = () => {
    // remove the guide=aws search param upon closing
    searchParams.delete("guide");
    setSearchParams(searchParams);
    setOpen(false);
  };

  setDocumentTitle(scope.name);

  return (
    <Fragment>
      <DashboardContent scope={scope} space={space} />
      <AwsGuide scope={scope} open={open} onClose={handleClose} />
    </Fragment>
  );
}
