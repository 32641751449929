import { Fragment } from "react/jsx-runtime";
import { Stack, Typography } from "@mui/material";
import { MttrSla } from "~/utils/arrow";

export const MTTRDate = ({
  mttr,
  color,
}: {
  mttr: MttrSla["mttr"];
  color: string;
}) => {
  const noMttrSet = mttr.days === 0 && mttr.hours === 0 && mttr.minutes === 0;
  return (
    <Stack
      direction="row"
      alignItems="baseline"
      sx={{
        "& > p": { fontWeight: 700 },
        "& .character": { fontSize: 12, color },
        "& .character:not(:last-child)": {
          marginRight: 0.5,
        },
        "& .number": {
          fontSize: 16,
          color,
        },
        "& .no-mttr": {
          fontSize: 12,
          color: "text.secondary",
        },
      }}
    >
      {noMttrSet ? (
        <Typography className="no-mttr" sx={{ color: "text.secondary" }}>
          NO MTTR YET
        </Typography>
      ) : (
        <Fragment>
          <Typography className="character">MTTR</Typography>
          <Typography className="number">{mttr.days}</Typography>
          <Typography className="character">d</Typography>
          <Typography className="number">{mttr.hours}</Typography>
          <Typography className="character">h</Typography>
          <Typography className="number">{mttr.minutes}</Typography>
          <Typography className="character">m</Typography>
        </Fragment>
      )}
    </Stack>
  );
};
