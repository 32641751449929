import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { useState } from "react";
import { VisibilityIcon, VisibilityOffIcon } from "../../../icons";

export type PasswordFieldProps = TextFieldProps & {};

export function PasswordField(props: PasswordFieldProps) {
  const [showPassword, setShowPassword] = useState(false);
  const color = "text.secondary";
  const type = showPassword ? "text" : "password";
  const icon = showPassword ? (
    <VisibilityOffIcon sx={{ color }} />
  ) : (
    <VisibilityIcon sx={{ color }} />
  );

  return (
    <TextField
      type={type}
      slotProps={{
        input: {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword((prev) => !prev)}
                edge="end"
              >
                {icon}
              </IconButton>
            </InputAdornment>
          ),
        },
      }}
      {...props}
    />
  );
}
