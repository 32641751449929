import { Box, Grid2 } from "@mui/material";
import { useEpssRisk } from "./useEpssRisk";
import { EpssChart } from "./epss-chart";
import { EpssMetricBox } from "./epss-metric-box";

export type EpssRiskContentProps = {
  id?: string;
  flipContent?: boolean;
};

export function EpssRiskContent({ id, flipContent }: EpssRiskContentProps) {
  const risk = useEpssRisk();
  return (
    <Box id={id}>
      <Grid2 container spacing={2}>
        <Grid2
          container
          size={{ xs: 12, md: 6, lg: 8.5 }}
          spacing={3}
          sx={{ mb: "auto" }}
          {...(flipContent
            ? { order: { xs: 2, md: 2 } }
            : { order: { xs: 2, md: 1 } })}
        >
          {Object.values(risk.metrics).map((metric, i) => (
            <Grid2
              key={metric.label}
              size={{ xs: 12, sm: 6, md: 6, lg: 4 }}
              display="flex"
            >
              <EpssMetricBox metric={metric} />
            </Grid2>
          ))}
        </Grid2>
        <Grid2
          size={{ xs: 12, md: 6, lg: 3.5 }}
          {...(flipContent
            ? { order: { xs: 1, md: 1 } }
            : { order: { xs: 1, md: 2 } })}
        >
          <EpssChart />
        </Grid2>
      </Grid2>
    </Box>
  );
}
