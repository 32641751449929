import { motion } from "framer-motion";
import { Box, Skeleton } from "@mui/material";
import React from "react";

export const RemediationScriptsSkeleton = () => {
  return (
    <Box
      data-testid="scripts-skeleton"
      component={motion.div}
      width="100%"
      key="skeleton"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      sx={{
        height: 500,
        p: 0,
        m: 0,
        bgcolor: "code.background",
        borderRadius: 1,
        overflow: "hidden",
      }}
    >
      <Skeleton animation="wave" variant="rectangular" height={500} />
    </Box>
  );
};
