import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Link,
  List,
  ListItem,
  Paper,
  Typography,
} from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { FileUploadIcon, HomeIcon } from "~/components/icons";
import { Space } from "~/lib/types";
import { TestIamActionsQuery } from "~/operations";
import { Flex } from "~/components/Flex";
import { AddButton } from "~/components/add-button";
import { Upload } from "~/components/upload";
import { IamActions } from "~/lib/iam";
import { LoadingPage } from "~/components/loading";
import useRegistries from "../security/policies/hooks/useRegistries";
import { policyIcon } from "./utils/policyIcon";
import { PolicyMrnToURIEncodedId } from "~/lib/mrn";
import { Caption } from "../security/policies/components/Caption";
import { Pagination } from "../security/policies/components/Pagination";
import { PolicyAssignButton } from "../security/policies/components/PolicyAssignButton";
import { usePolicyPermissions } from "../security/policies/hooks/usePolicyPermissions";
import { setDocumentTitle } from "~/utils/commonUtils";

export type QueryPacksAddPageProps = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function QueryPacksAddPage({
  space,
  availablePermissions,
}: QueryPacksAddPageProps) {
  const {
    onAddQueryPackMutation,
    onChangeAssignHandler,
    onPaginationChange,
    paginatedRegistries,
    registries,
    state,
  } = useRegistries({ space, isQuerypack: true });
  const { queryPackUploadPermission, queryPackAssignPermission } =
    usePolicyPermissions({
      availablePermissions,
    });

  const navigate = useNavigate();

  const handleQueryPackAssignChange = async () => {
    try {
      await onChangeAssignHandler();
    } catch (error) {
      console.error("Failed to update query pack assignment");
    }
  };

  const queryPackAddDetailHref = (mrn: string) => {
    return `/space/inventory/query-packs/add/${PolicyMrnToURIEncodedId(mrn)}?spaceId=${space.id}`;
  };

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?spaceId=${space.id}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Link
      key="/space/inventory/query-packs"
      component={RouterLink}
      to={`/space/inventory/query-packs?spaceId=${space.id}`}
    >
      Query Packs
    </Link>,
    <Typography key="/space/inventory/query-packs/add">
      Add Query Packs
    </Typography>,
  ];

  setDocumentTitle(["Add Query Packs", "Query Packs", "Inventory"]);

  return (
    <Box>
      <Breadcrumbs sx={{ mb: 3, overflowWrap: "anywhere" }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>
      <Box
        id="add-query-packs-header"
        mb={3}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Typography
          variant="h4"
          fontWeight={700}
          textTransform="uppercase"
          sx={{ mb: 3 }}
        >
          Add Query Packs
        </Typography>
        <Flex id="add-query-packs-actions" alignItems="center" gap={4}>
          {queryPackUploadPermission && (
            <Upload
              title="Upload Query Pack"
              placeholder="Drag and drop a query pack or click"
              successMessage="Successfully added query pack"
              errorMessage="Failed to upload query pack"
              spaceMrn={space.mrn}
              addFileMutation={onAddQueryPackMutation}
              button={
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<FileUploadIcon />}
                >
                  Upload Custom
                </Button>
              }
            />
          )}
          <AddButton
            id="cancel-add-query-pack-button"
            href={`/space/inventory/query-packs?spaceId=${space.id}`}
            aria-label="Cancel Add Query Pack"
            close
          />
        </Flex>
      </Box>

      {state.isReady ? (
        paginatedRegistries.length > 0 && (
          <Box component={Paper}>
            <List className="available-query-packs-list" disablePadding>
              {paginatedRegistries.map((queryPack) => {
                return (
                  <ListItem
                    key={queryPack.mrn}
                    className="available-query-packs-list-item"
                    sx={{
                      p: 3,
                      cursor: "pointer",
                      "&:not(:last-child)": {
                        borderBottom: (theme) =>
                          `1px solid ${theme.palette.background.lighter}`,
                      },
                      "&:hover": {
                        backgroundColor: (theme) =>
                          theme.palette.background.light,
                      },
                    }}
                    onClick={() =>
                      navigate(queryPackAddDetailHref(queryPack.mrn))
                    }
                  >
                    <Grid container spacing={0}>
                      <Grid
                        item
                        xs="auto"
                        sx={{
                          display: "flex",
                          alignItems: "baseline",
                          justifyContent: "left",
                        }}
                      >
                        {policyIcon(queryPack.name, "large")}
                      </Grid>
                      <Grid item xs={10} sm container>
                        <Grid item xs={10} sm>
                          <Typography
                            sx={{ pl: 3, fontWeight: 700 }}
                            className="query-pack-name"
                          >
                            {queryPack.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} container spacing={0} pl={3} mt={1}>
                          <Caption
                            trustLevel={queryPack.trustLevel}
                            version={queryPack.version}
                            authors={queryPack.authors}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        sm={2}
                        sx={{
                          display: { xs: "none", sm: "flex" },
                          alignItems: "center",
                          justifyContent: "end",
                        }}
                      >
                        {queryPackAssignPermission && (
                          <PolicyAssignButton
                            spaceMrn={space.mrn}
                            policyMrn={queryPack.mrn}
                            assigned={queryPack.assigned}
                            onChange={handleQueryPackAssignChange}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </ListItem>
                );
              })}
            </List>
            <Pagination
              page={state.page}
              count={registries.length}
              rowsPerPage={state.rowsPerPage}
              onPaginationChange={onPaginationChange}
              sx={{ borderBottomLeftRadius: 4, borderBottomRightRadius: 4 }}
            />
          </Box>
        )
      ) : (
        <LoadingPage what="Available Query Packs" />
      )}
    </Box>
  );
}
