import { useDetectedBy } from "~/components/vulnerabilities/DetectedBy/hooks/useDetectedBy";
import { useFindingOnAsset } from "./useFindingOnAsset";
import { mapDetectedByVendor } from "~/components/vulnerabilities/DetectedBy/lib";
import {
  createAssetOverviewStringValue,
  createAssetOverviewTimeValue,
} from "~/utils/assetUtils";
import { isAdvisoryOrVulnerabilityFinding } from "~/pages/findings/FindingOnAsset/utils";

export function useCveOnAssetInsights() {
  const { finding, asset, activeScope } = useFindingOnAsset();
  const { overviewGroups } = asset || {};
  const { firstFound } = useDetectedBy({
    detectedBy: isAdvisoryOrVulnerabilityFinding(finding)
      ? finding?.detectionSources || []
      : [],
  });

  const linkToAsset = `/space/inventory/${asset?.id}/overview?spaceId=${activeScope?.id}`;

  const platformGroup = overviewGroups?.find(
    (g) => g.title === "Platform overview",
  );

  const evidenceGroup = overviewGroups?.find(
    (g) => g.title === "Scan overview",
  ) || { items: [] };

  //copy the evidence group to an object that is modifiable
  const evidenceGroupCopy = JSON.parse(JSON.stringify(evidenceGroup));

  const evidenceToAdd = [
    {
      key: "first-detected-at",
      title: "First detected at",
      value: firstFound?.firstDetectedAt,
      type: "time",
    },
    {
      key: "last-updated-at",
      title: "Last updated at",
      value: firstFound?.lastUpdatedAt,
      type: "time",
    },
    firstFound?.vendor
      ? {
          key: "vendor",
          title: "Vendor",
          value: firstFound?.vendor,
          type: "string",
        }
      : null,
  ].flatMap((x) => x ?? []);

  evidenceToAdd.forEach((evidence) => {
    evidenceGroupCopy.items.push(
      evidence.type === "time"
        ? createAssetOverviewTimeValue(
            evidence.key,
            evidence.title,
            evidence.value,
          )
        : createAssetOverviewStringValue(
            evidence.key,
            evidence.title,
            mapDetectedByVendor(evidence.value),
          ),
    );
  });

  return {
    evidenceGroup: evidenceGroupCopy,
    platformGroup,
    linkToAsset,
    finding,
  };
}
