import { Dispatch, memo, SetStateAction, useEffect } from "react";
import {
  Grid,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Button,
  SxProps,
  Theme,
} from "@mui/material";
import { ChevronLeftIcon, ChevronRightIcon } from "~/components/icons";
import {
  DEFAULT_PAGE_SIZE,
  PaginationRange,
  UsePaginationProps,
  usePagination,
} from "./usePagination";

export type PaginationProps = {
  totalCount: number;
  fetchMore?: UsePaginationProps["fetchMore"];
  pageInfo?: UsePaginationProps["pageInfo"];
  setPageItems: Dispatch<SetStateAction<PaginationRange>>;
  customPageSizes?: number[];
  isFullTextSearch?: boolean;
  sx?: SxProps<Theme>;
  goToFirstPage?: boolean;
};

export const INITIAL_PAGE_RANGE = {
  from: 0,
  to: DEFAULT_PAGE_SIZE,
};

export const Pagination = memo(function Pagination({
  totalCount,
  fetchMore,
  pageInfo,
  setPageItems,
  customPageSizes,
  isFullTextSearch,
  sx,
  goToFirstPage,
}: PaginationProps) {
  let pageSizes = customPageSizes ?? [10, 25, 50, 100];
  const { handle, page, disabled } = usePagination({
    pageSizes,
    fetchMore,
    pageInfo,
    totalCount,
    isFullTextSearch,
    goToFirstPage,
  });

  useEffect(() => {
    setPageItems(page.data);
  }, [page.data]);

  const shouldDisplay = totalCount > pageSizes[0] ? "flex" : "none";

  return (
    <Grid
      container
      sx={[
        {
          py: 2,
          px: 4,
          display: shouldDisplay,
          alignItems: "center",
          justifyContent: "end",
          backgroundColor: "background.light",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Grid
        item
        xs={12}
        sm="auto"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          pb: { xs: 1, sm: 0 },
        }}
      >
        <InputLabel id="page-size-select-label">Rows Per Page</InputLabel>
        <FormControl sx={{ m: 1 }} size="small">
          <Select
            labelId="page-size-select-label"
            id="page-size-select"
            variant="outlined"
            value={page.size.toString()}
            onChange={handle.onChange}
            autoWidth
            sx={{
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
            }}
          >
            {pageSizes.map((size) => (
              <MenuItem key={size} value={size}>
                {size}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        sm="auto"
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Button
          onClick={handle.prev}
          startIcon={<ChevronLeftIcon fontSize="small" />}
          disabled={disabled.prev}
        >
          Prev
        </Button>
        <Button
          onClick={handle.next}
          endIcon={<ChevronRightIcon fontSize="small" />}
          disabled={disabled.next}
        >
          Next
        </Button>
      </Grid>
    </Grid>
  );
});
