import { FlagOutlined, Radar } from "@mui/icons-material";
import { StatsProps } from "~/components/DetailsPage/components/Stats/Stats";
import { HealingIcon } from "~/components/icons";
import {
  AggregateScoreOrderField,
  AggregateScoreType,
  OrderDirection,
  useGetAggregateScoresQuery,
  useLoadSpaceQuery,
} from "~/operations";
import { AggregateScoresEdge } from "../../lib/SecurityReport/types";
import { getColor } from "~/lib/colors";
import { useTheme } from "@mui/material";
import { useFindingRiskFactors } from "~/pages/space/security/components/RiskFactors/hooks/useFindingRiskFactors";
import { ReportType } from "~/reportApp/reportingApp";
import { useReportingPageLoaded } from "../../hooks/useReportingPageLoaded";
import { useFetchAllItems } from "./useFetchAllItems";
import { pluralize } from "~/lib/pluralize";

export function useChecksOrVulnerabilitiesReport({
  reportType,
}: {
  reportType: ReportType;
}) {
  let theme = useTheme();
  const { searchParams } = new URL(window.location.href);
  //   scope may be either a "space" or "workspace"
  const scopeMrn = searchParams.get("scopeMrn") || "";

  const { data: spaceData, networkStatus: spaceNetworkStatus } =
    useLoadSpaceQuery({
      variables: {
        spaceMrn: scopeMrn,
      },
    });

  // Gather all of the checks in the space
  const { allItems: allChecks, fetchAllNetworkStatus } = useFetchAllItems({
    scopeMrn,
  });

  const {
    data: aggScoresVulnsData,
    networkStatus: aggScoresVulnsNetworkStatus,
  } = useGetAggregateScoresQuery({
    variables: {
      first: 100,
      entityMrn: scopeMrn || "",
      filter: {
        scoreType: AggregateScoreType.Vulnerability,
      },
    },
    skip: !scopeMrn,
  });

  const {
    data: aggScoresAdvisoriessData,
    networkStatus: aggScoresAdvisoriessNetworkStatus,
  } = useGetAggregateScoresQuery({
    variables: {
      first: 100,
      entityMrn: scopeMrn || "",
      filter: {
        scoreType: AggregateScoreType.Advisory,
      },
    },
    skip: !scopeMrn,
  });

  const {
    data: aggScoresAssetData,
    networkStatus: aggScoresAssetNetworkStatus,
  } = useGetAggregateScoresQuery({
    variables: {
      entityMrn: scopeMrn || "",
      filter: {
        findingMrn: "//security.api.mondoo.app/findings/all",
        scoreType: AggregateScoreType.Asset,
      },
    },
    skip: !scopeMrn,
  });

  const {
    data: aggScoresSecurityData,
    networkStatus: aggScoresSecurityNetworkStatus,
  } = useGetAggregateScoresQuery({
    variables: {
      entityMrn: scopeMrn || "",
      filter: {
        findingMrn: "//security.api.mondoo.app/findings/security",
      },
    },
    skip: !scopeMrn,
  });

  const {
    data: aggScoresSoftwareData,
    networkStatus: aggScoresSoftwareNetworkStatus,
  } = useGetAggregateScoresQuery({
    variables: {
      entityMrn: scopeMrn || "",
      orderBy: {
        field: AggregateScoreOrderField.Rank,
        direction: OrderDirection.Asc,
      },
      filter: {
        findingMrn: null,
        scoreType: AggregateScoreType.Software,
      },
    },
    skip: !scopeMrn,
  });

  const {
    riskFactorsWithDocs,
    networkStatuses: riskFactorsNetworkStatuses, // this is an array of network statuses
  } = useFindingRiskFactors({
    spaceMrn: scopeMrn,
    findingMrn: "//security.api.mondoo.app/findings/security",
  });

  const networkStatuses = [
    spaceNetworkStatus,
    aggScoresVulnsNetworkStatus,
    aggScoresAdvisoriessNetworkStatus,
    aggScoresAssetNetworkStatus,
    aggScoresSecurityNetworkStatus,
    fetchAllNetworkStatus,
    aggScoresSoftwareNetworkStatus,
    ...riskFactorsNetworkStatuses,
  ];

  // Determine if all network requests are ready
  useReportingPageLoaded(networkStatuses);

  const aggScoresAssets =
    aggScoresAssetData?.aggregateScores?.__typename ===
    "AggregateScoresConnection"
      ? aggScoresAssetData.aggregateScores
      : { totalCount: 0 };

  const aggScoresSecurityNodeData =
    aggScoresSecurityData?.aggregateScores?.__typename ===
    "AggregateScoresConnection"
      ? aggScoresSecurityData.aggregateScores.edges?.at(0)?.node
      : undefined;

  const aggScoresChecks = allChecks || [];

  const aggScoresSoftware =
    aggScoresSoftwareData?.aggregateScores?.__typename ===
    "AggregateScoresConnection"
      ? aggScoresSoftwareData.aggregateScores.edges
      : [];

  const aggScoresVulns =
    aggScoresVulnsData?.aggregateScores?.__typename ===
    "AggregateScoresConnection"
      ? aggScoresVulnsData.aggregateScores.edges
      : [];

  const aggScoresAdvisories =
    aggScoresAdvisoriessData?.aggregateScores?.__typename ===
    "AggregateScoresConnection"
      ? aggScoresAdvisoriessData.aggregateScores.edges
      : [];

  //   munged stats data
  const totalScanned = aggScoresAssets.totalCount;
  const priorityFindings = spaceData?.space?.priorityFindings || 0;
  const totalAffected = aggScoresSecurityNodeData?.blastRadius?.affected || 0;
  const totalRemediated =
    aggScoresSecurityNodeData?.blastRadius?.assets &&
    aggScoresSecurityNodeData?.blastRadius?.affected
      ? (
          aggScoresSecurityNodeData?.blastRadius?.assets -
          aggScoresSecurityNodeData.blastRadius?.affected
        ).toString()
      : "---";

  const totalExceptions =
    (aggScoresSecurityNodeData?.blastRadius?.snoozed || 0) +
    (aggScoresSecurityNodeData?.blastRadius?.disabled || 0);

  const stats: StatsProps["stats"] = [
    {
      label: "Scanned",
      value: totalScanned < 0 ? "---" : totalScanned.toString(),
      icon: <Radar fontSize="inherit" />,
    },
    {
      label: "Findings",
      value: priorityFindings < 0 ? "---" : priorityFindings.toString(),
      icon: <FlagOutlined fontSize="inherit" />,
    },
    {
      label: "Remediated",
      value: totalRemediated,
      icon: <HealingIcon fontSize="inherit" />,
    },
    {
      label: "Exceptions",
      value: totalExceptions.toString(),
      icon: <HealingIcon fontSize="inherit" />,
    },
  ].flatMap((stat) => (stat ? stat : []));

  const groupAggScoreEdgesByRating = (aggScoresAssetsEdges: any) => {
    const ratings = {
      CRITICAL: 0,
      HIGH: 0,
      MEDIUM: 0,
      LOW: 0,
      NONE: 0,
    };

    aggScoresAssetsEdges.forEach((edge: AggregateScoresEdge) => {
      const rating = edge.node?.rating;

      if (rating && ratings.hasOwnProperty(rating)) {
        ratings[rating as keyof typeof ratings]++;
      }
    });

    return Object.entries(ratings).map(([key, value], index) => {
      return {
        id: key,
        value,
        color: getColor(theme, key),
      };
    });
  };

  const checksRatings = groupAggScoreEdgesByRating(aggScoresChecks);
  const vulnsRatings = groupAggScoreEdgesByRating(aggScoresVulns);

  const failingChecks =
    aggScoresChecks?.filter((check) => (check.node?.riskValue ?? 100) > 0) ||
    [];

  const totalAssetsScanned = totalScanned;
  const totalFailingChecks = failingChecks.length;

  const checksGroupSummary = [
    {
      name: "Checks",
      description: `In the ${spaceData?.space?.name} space, Mondoo discovered ${totalFailingChecks} failed security policy checks across ${totalAssetsScanned} scanned assets. ${priorityFindings} ${pluralize("finding", priorityFindings)} have a critical or high risk rating. The team has remediated ${totalRemediated} findings in the space since its creation. Mondoo skipped ${totalExceptions} findings due to set exceptions. This report provides details about the risk factors exposed in the space and the individual results of policy checks.`,
      data: checksRatings,
    },
  ];

  const totalVulns = aggScoresVulns?.length || 0;
  const priorityVulns =
    aggScoresVulns?.filter(
      (vuln) =>
        vuln.node?.rating === "CRITICAL" || vuln.node?.rating === "HIGH",
    ).length || 0;

  const vulnerabilitiesGroupSummary = [
    {
      name: "Vulnerabilities",
      description: `In the ${spaceData?.space?.name} space, Mondoo discovered ${totalVulns} ${pluralize("vulnerabilities", totalVulns)} across ${totalAssetsScanned} scanned assets. ${priorityVulns} ${pluralize("vulnerabilities", priorityVulns)} have a critical or high risk rating. The team has remediated ${totalRemediated} vulnerabilities in the space since its creation. Mondoo skipped ${totalExceptions} findings due to set exceptions. This report provides details about the team's performance against SLAs, the risk factors exposed in the space, and the individual vulnerabilities found.`,
      data: vulnsRatings,
    },
  ];

  const pageTitle = spaceData?.space?.name
    ? `${spaceData.space.name} - Space ${reportType} Report`
    : "";

  return {
    scopeMrn,
    title: pageTitle,
    stats,
    groupSummary:
      reportType === ReportType.Checks
        ? checksGroupSummary
        : vulnerabilitiesGroupSummary,
    riskFactorsWithDocs,
    totalScanned,
    inventoryBreakdown: aggScoresSoftware,
    ...(reportType === ReportType.Checks && {
      failingChecks: {
        total: failingChecks?.length || 0,
        items: failingChecks,
        heading: "Failing checks",
      },
    }),
    ...(reportType === ReportType.Vulnerabilities && {
      foundVulns: {
        total: aggScoresVulns?.length || 0,
        items: aggScoresVulns,
        heading: "Found vulnerabilities",
      },
      foundAdvisories: {
        total: aggScoresAdvisories?.length || 0,
        items: aggScoresAdvisories,
        heading: "Found advisories",
      },
    }),
  };
}
