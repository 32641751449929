import { ChangeEvent, MouseEventHandler, ReactNode } from "react";
import { GetAffectedAssetsQuery } from "~/operations";

export type AffectedAssetsFindings = Extract<
  GetAffectedAssetsQuery["findings"],
  { __typename: "FindingsConnection" }
>;
export type AffectedAssetsEdges = NonNullable<AffectedAssetsFindings["edges"]>;
export type AffectedAssetsEdge = AffectedAssetsEdges[0];
export type AffectedAssetsNode = NonNullable<AffectedAssetsEdge["node"]>;
export type AffectedAssetsCheckNode = Extract<
  AffectedAssetsNode,
  { __typename: "CheckFinding" }
>;

export type SelectionProps = {
  selectedEntities: Array<unknown>;
  hasSelectPermissions: boolean;
  onCheckboxClick: MouseEventHandler;
  onCheckboxChange: (
    e: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    data: { mrn: string } | null | undefined,
  ) => {};
  onCheckAll: (
    checked: boolean,
    edges: Array<{ mrn: string }> | null | undefined,
  ) => void;
  getIsRowSelected: (identifier: string) => boolean;
  renderToolbar?: (totalCount: number) => ReactNode;
};

export type VulnerabilityScoreType = "CVE" | "Advisory" | "Policy";

export const AssetContextualLinkType = {
  Check: "Check",
  Cve: "Cve",
  Advisory: "Advisory",
  Software: "Software",
  Asset: "Asset",
};
