import { Markdown } from "~/components/markdown";
import { ShowMore } from "./ShowMore";
import { SxProps, Theme } from "@mui/material";

export type ShowMoreSummaryProps = {
  text: string;
  maxHeight?: number;
  id?: string;
  TextProps?: SxProps<Theme>;
};

export function ShowMoreSummary({
  text,
  maxHeight,
  id,
  ...props
}: ShowMoreSummaryProps) {
  return (
    <ShowMore id={id} maxHeight={maxHeight}>
      <Markdown
        source={text}
        sx={{ color: "text.secondary", ...props.TextProps }}
      />
    </ShowMore>
  );
}
