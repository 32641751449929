import { Box, Grid2 } from "@mui/material";
import { SectionHeading } from "~/components/DetailsPage/components";
import { Flex } from "~/components/Flex";
import { Text } from "~/components/ui-library";
import { PropertyItem } from "~/components/PropertyItem";
import { Code } from "~/components/code";
import { Mquery, MqueryVariant } from "~/operations";

type CheckQueriesProps = {
  properties?: Mquery["properties"] | MqueryVariant["properties"] | null;
  mql?: Mquery["mql"] | MqueryVariant["mql"] | null;
};

export const CheckQueries = ({ properties, mql }: CheckQueriesProps) => {
  return (
    <Grid2 container size={12}>
      <Grid2 size={12}>
        <SectionHeading heading="Queries" />
      </Grid2>
      {(properties?.length || 0) > 0 && (
        <Grid2 size={12}>
          <Flex alignItems="center" justifyContent="space-between" my={2}>
            <Text bold>PROPERTIES</Text>
          </Flex>
          {properties?.map((property, key) => {
            if (!property) return;

            const { uid, mql } = property;

            return <PropertyItem key={key} index={key} uid={uid} mql={mql} />;
          })}
        </Grid2>
      )}
      {mql && (
        <Grid2 size={12}>
          <Flex alignItems="center" justifyContent="space-between" my={2}>
            <Text bold>MQL</Text>
          </Flex>
          <Box>
            <Code copyButton className="coffeescript">
              {mql}
            </Code>
          </Box>
        </Grid2>
      )}
    </Grid2>
  );
};
