import { FindingRemediation } from "~/components/vulnerabilities/finding-remediation";
import { useParams } from "react-router-dom";
import { useFindingOnAsset } from "~/pages/findings/FindingOnAsset/hooks";
import { isFeatureEnabled } from "~/login/features";
import { isCheckFinding } from "~/pages/findings/FindingOnAsset/utils";
import { CheckRemediation } from "~/pages/findings/FindingOnAsset/components/Remediation/CheckRemediation";
import { FindingRemediationV2 } from "~/components/vulnerabilities/finding-remediationV2";

export function Remediation() {
  const { vulnId = "", advisoryId = "" } = useParams();
  const { finding } = useFindingOnAsset();

  if (vulnId && !isFeatureEnabled("CVE Remediation")) return;

  return (
    <>
      {isCheckFinding(finding) ? (
        <CheckRemediation />
      ) : isFeatureEnabled("Remediation V2") ? (
        <FindingRemediationV2
          findingId={vulnId || advisoryId}
          scopeMrn={finding?.asset?.mrn}
        />
      ) : (
        <FindingRemediation
          findingId={vulnId || advisoryId}
          scopeMrn={finding?.asset?.mrn}
        />
      )}
    </>
  );
}
