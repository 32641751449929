import { Breadcrumbs, Grid, Link, Typography } from "@mui/material";
import { AttachIcon, HomeIcon } from "~/components/icons";
import { Link as RouterLink } from "react-router-dom";
import { Flex } from "~/components/Flex";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";

type CaseDetailsBreadcrumbsProps = {
  scope: SpaceOrWorkspaceScope;
  title: string;
};

export const CaseDetailsBreadcrumbs = ({
  scope,
  title,
}: CaseDetailsBreadcrumbsProps) => {
  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?${scope.params}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Flex gap={1} key="/space/cases">
      <AttachIcon sx={{ color: (theme) => theme.palette.primary.light }} />
      <Link
        component={RouterLink}
        to={`/space/cases?${scope.params}`}
        display="flex"
      >
        Ticketing
      </Link>
    </Flex>,
    <Typography key="/space/cases/:mrn">{title}</Typography>,
  ];
  return (
    <Grid item xs={12}>
      <Breadcrumbs sx={{ mb: 3, overflowWrap: "anywhere" }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>
    </Grid>
  );
};
