import { MONDOO_ASSET_API_URL } from "../../constants";

export const ASSET_ID_PARAM = "assetId";

export const getAssetMrn = (
  spaceId: string,
  assetId: string | null | undefined,
): string => {
  if (!assetId) return "";

  return `${MONDOO_ASSET_API_URL}/spaces/${spaceId}/assets/${assetId}`;
};
