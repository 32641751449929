import { Link as RouterLink } from "react-router-dom";
import { Box, Paper, Typography } from "@mui/material";
import { GradientArrow } from "~/images/icons/gradient-arrow";
import SvgIcon from "@mui/material/SvgIcon";
import { Flex } from "~/components/Flex";
import { ProviderTypes } from "~/pages/space-settings/WorkloadIdentity/constants";

const TRANSITION_SPEED = "transform 325ms";

type IdentityProviderCardProps = {
  href: string;
  title: string;
  description: string;
  IconComponent: typeof SvgIcon;
  type: ProviderTypes;
};

export const WorkloadIdentityProviderCard = ({
  href,
  title,
  description,
  IconComponent,
  type,
}: IdentityProviderCardProps) => {
  return (
    <Box component={RouterLink} to={href} data-testid={`${type}-card`}>
      <Paper
        sx={{
          height: 1,
          p: 3,
          minHeight: 216,
          "&:hover": {
            transform: "scale(1.05)",
            transition: TRANSITION_SPEED,
          },
          transition: TRANSITION_SPEED,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            mb: 3,
          }}
        >
          <Flex gap={3}>
            <IconComponent {...{ sx: { fontSize: 48 } }} />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography fontSize={24} fontWeight="fontWeightBold">
                {title}
              </Typography>
            </Box>
          </Flex>

          <GradientArrow />
        </Box>
        <Typography color="text.secondary">{description}</Typography>
      </Paper>
    </Box>
  );
};
