import { Box, Grid, Typography } from "@mui/material";
import { AddButton } from "~/components/add-button";
import { AzureColorIcon } from "~/components/icons/mondoo/azure-color";
import { DataBox } from "~/components/integrations/databox";
import { IntegrationType } from "~/operations";
import { GCPColorIcon } from "~/components/icons/mondoo/gcp-color";
import { MS365Icon } from "~/components/icons/mondoo/ms365-color";
import { BigqueryIcon } from "~/components/icons/mondoo/integrations/bigquery";
import { SnowflakeIcon } from "~/components/icons/mondoo/integrations/snowflake";
import { AmazonS3Icon } from "~/components/icons/mondoo/integrations/amazon-s3";
import { GitLabColorIcon } from "~/components/ui-library";
import { OktaIcon } from "~/components/icons/mondoo/okta";
import {
  FlyingEnvelopeIcon,
  GithubIcon,
  GitHubIcon,
  GoogleWSColorIcon,
  OCIColorIcon,
  SlackColorIcon,
  WorkstationIcon,
} from "~/components/icons";
import { GcsBucketIcon } from "~/components/icons/mondoo/integrations/gcs-bucket";
import { PostgreSQLIcon } from "~/components/icons/mondoo/integrations/postgresql";
import { JiraIcon } from "../../../components/icons/mondoo/integrations/jira-icon";
import { Space } from "~/lib/types";
import { KubernetesIcon } from "~/components/icons/mondoo/integrations/kubernetes";
import { DomainIcon } from "~/components/icons/mondoo/integrations/domain";
import { ZendeskIcon } from "~/components/icons/mondoo/integrations/zendesk";
import {
  AwsIcon,
  AzureDevOpsIcon,
  CrowdStrikeFalconIcon,
  MsftDefenderIcon,
  SentinelOneIcon,
  ShodanIcon,
} from "~/components/icons/mondoo/integrations";

type Props = {
  space: Space;
  type: IntegrationType;
  hasIntegrations: boolean;
};

export function IntegrationsListHeader({
  space,
  type,
  hasIntegrations,
}: Props) {
  const getContent = () => {
    let title: string;
    let tagline: string;
    let icon: JSX.Element;
    let href: string;

    switch (type) {
      case IntegrationType.Aws:
        title = "AWS";
        tagline = "Cloud Hosting";
        icon = <AwsIcon sx={{ fontSize: 48 }} />;
        href = `/space/integrations/add/mondoo/aws?spaceId=${space.id}`;
        break;

      case IntegrationType.Azure:
        title = "Azure";
        tagline = "Cloud Hosting";
        icon = <AzureColorIcon sx={{ fontSize: 48 }} />;
        href = `/space/integrations/add/mondoo/azure?spaceId=${space.id}`;
        break;

      case IntegrationType.MicrosoftDefender:
        title = "Microsoft Defender for Cloud";
        tagline = "Cloud Hosting";
        icon = <MsftDefenderIcon sx={{ fontSize: 48 }} />;
        href = `/space/integrations/add/mondoo/defender?spaceId=${space.id}`;
        break;

      case IntegrationType.CrowdstrikeFalcon:
        title = "CrowdStrike";
        tagline = "Cloud Hosting";
        icon = <CrowdStrikeFalconIcon sx={{ fontSize: 48 }} />;
        href = `/space/integrations/add/mondoo/crowdstrike?spaceId=${space.id}`;
        break;

      case IntegrationType.SentinelOne:
        title = "SentinelOne";
        tagline = "Cloud Hosting";
        icon = <SentinelOneIcon sx={{ fontSize: 48 }} />;
        href = `/space/integrations/add/mondoo/sentinelone?spaceId=${space.id}`;
        break;

      case IntegrationType.AzureBlob:
        title = "Azure Blob Storage";
        tagline = "Storage";
        icon = <AzureColorIcon sx={{ fontSize: 48 }} />;
        href = `/space/integrations/add/mondoo/azure_blob?spaceId=${space.id}`;
        break;

      case IntegrationType.MicrosoftDefender:
        title = "Microsoft Defender for Cloud";
        tagline = "Cloud Hosting";
        icon = <MsftDefenderIcon sx={{ fontSize: 48 }} />;
        href = `/space/integrations/add/mondoo/defender?spaceId=${space.id}`;
        break;

      case IntegrationType.Bigquery:
        title = "BigQuery";
        tagline = "Storage";
        icon = <BigqueryIcon sx={{ fontSize: 48 }} />;
        href = `/space/integrations/add/mondoo/bigquery?spaceId=${space.id}`;
        break;

      case IntegrationType.Shodan:
        title = "Shodan";
        tagline = "Networking";
        icon = <ShodanIcon sx={{ fontSize: 48 }} />;
        href = `/space/integrations/add/mondoo/shodan?spaceId=${space.id}`;
        break;

      case IntegrationType.Snowflake:
        title = "Snowflake";
        tagline = "Storage";
        icon = <SnowflakeIcon sx={{ fontSize: 48 }} />;
        href = `/space/integrations/add/mondoo/snowflake?spaceId=${space.id}`;
        break;

      case IntegrationType.K8S:
        title = "Kubernetes";
        tagline = "Cloud Hosting";
        icon = <KubernetesIcon sx={{ fontSize: 48 }} />;
        href = `/space/integrations/add/mondoo/kubernetes?spaceId=${space.id}`;
        break;

      case IntegrationType.Host:
        title = "Domain/IP Address";
        tagline = "";
        icon = <DomainIcon sx={{ fontSize: 48 }} />;
        href = `/space/integrations/add/mondoo/host?spaceId=${space.id}`;
        break;

      case IntegrationType.Postgres:
        title = "PostgreSQL";
        tagline = "Storage";
        icon = <PostgreSQLIcon sx={{ fontSize: 48 }} />;
        href = `/space/integrations/add/mondoo/postgres?spaceId=${space.id}`;
        break;

      case IntegrationType.AwsS3:
        title = "Amazon S3";
        tagline = "Storage";
        icon = <AmazonS3Icon sx={{ fontSize: 48 }} />;
        href = `/space/integrations/add/mondoo/amazons3?spaceId=${space.id}`;
        break;

      case IntegrationType.S3:
        title = "S3 Compatible Services";
        tagline = "Storage";
        icon = <AmazonS3Icon sx={{ fontSize: 48 }} />;
        href = `/space/integrations/add/mondoo/amazon-s3-compatible?spaceId=${space.id}`;
        break;

      case IntegrationType.Github:
        title = "GitHub";
        tagline = "SaaS Platform";
        icon = <GitHubIcon sx={{ fontSize: 48 }} />;
        href = `/space/integrations/add/mondoo/github?spaceId=${space.id}`;
        break;

      case IntegrationType.HostedSlack:
        title = "Slack";
        tagline = "SaaS Platform";
        icon = <SlackColorIcon sx={{ fontSize: 48 }} />;
        href = `/space/integrations/add/mondoo/slack?spaceId=${space.id}`;
        break;

      case IntegrationType.Gcp:
        title = "Google Cloud Platform";
        tagline = "Cloud Hosting";
        icon = <GCPColorIcon sx={{ fontSize: 48 }} />;
        href = `/space/integrations/add/mondoo/gcp?spaceId=${space.id}`;
        break;

      case IntegrationType.GcsBucket:
        title = "Google Cloud Storage Bucket";
        tagline = "Storage";
        icon = <GcsBucketIcon sx={{ fontSize: 48 }} />;
        href = `/space/integrations/add/mondoo/gcs_bucket?spaceId=${space.id}`;
        break;

      case IntegrationType.GoogleWorkspace:
        title = "Google Workspace";
        tagline = "SaaS Platform";
        icon = <GoogleWSColorIcon sx={{ fontSize: 48 }} />;
        href = `/space/integrations/add/mondoo/google_workspace?spaceId=${space.id}`;
        break;

      case IntegrationType.Gitlab:
        title = "GitLab";
        tagline = "SaaS Platform";
        icon = <GitLabColorIcon sx={{ fontSize: 48 }} />;
        href = `/space/integrations/add/mondoo/gitlab?spaceId=${space.id}`;
        break;

      case IntegrationType.TicketSystemJira:
        title = "Atlassian Jira";
        tagline = "Ticket System";
        icon = <JiraIcon sx={{ fontSize: 48 }} />;
        href = `/space/integrations/add/mondoo/ticket_system_jira?spaceId=${space.id}`;
        break;

      case IntegrationType.TicketSystemEmail:
        title = "Ticketing via email";
        tagline = "Ticket System";
        icon = <FlyingEnvelopeIcon sx={{ fontSize: 48 }} />;
        href = `/space/integrations/add/mondoo/ticket_system_email?spaceId=${space.id}`;
        break;

      case IntegrationType.TicketSystemZendesk:
        title = "Zendesk";
        tagline = "Ticket System";
        icon = <ZendeskIcon sx={{ fontSize: 48 }} />;
        href = `/space/integrations/add/mondoo/ticket_system_zendesk?spaceId=${space.id}`;
        break;

      case IntegrationType.TicketSystemGithub:
        title = "GitHub Issues";
        tagline = "Ticket System";
        icon = <GithubIcon sx={{ fontSize: 48 }} />;
        href = `/space/integrations/add/mondoo/ticket_system_github?spaceId=${space.id}`;
        break;

      case IntegrationType.TicketSystemGitlab:
        title = "GitLab Issues";
        tagline = "Ticket System";
        icon = <GitLabColorIcon sx={{ fontSize: 48 }} />;
        href = `/space/integrations/add/mondoo/ticket_system_gitlab?spaceId=${space.id}`;
        break;
      case IntegrationType.TicketSystemAzureDevops:
        title = "Azure DevOps";
        tagline = "Ticket System";
        icon = <AzureDevOpsIcon sx={{ fontSize: 48 }} />;
        href = `/space/integrations/add/mondoo/ticket_system_azure_devops?spaceId=${space.id}`;
        break;

      case IntegrationType.Oci:
        title = "Oracle Cloud Infrastructure";
        tagline = "Cloud Hosting";
        icon = <OCIColorIcon sx={{ fontSize: 48 }} />;
        href = `/space/integrations/add/mondoo/oci?spaceId=${space.id}`;
        break;

      case IntegrationType.Ms365:
        title = "Microsoft 365";
        tagline = "SaaS Platform";
        icon = <MS365Icon sx={{ fontSize: 48 }} />;
        href = `/space/integrations/add/mondoo/ms365?spaceId=${space.id}`;
        break;

      case IntegrationType.Okta:
        title = "Okta";
        tagline = "SaaS Platform";
        icon = <OktaIcon sx={{ fontSize: 48 }} />;
        href = `/space/integrations/add/mondoo/okta?spaceId=${space.id}`;
        break;

      case IntegrationType.ManagedClient:
        title = "Managed Clients";
        tagline = "";
        icon = <WorkstationIcon sx={{ fontSize: 48 }} />;
        href = `/space/integrations/add?spaceId=${space.id}#serverendpointsecurity`;
        break;

      default:
        title = type;
        tagline = "";
        icon = <WorkstationIcon sx={{ fontSize: 48 }} />;
        href = `/space/integrations/add?spaceId=${space.id}`;
    }

    return { title, tagline, icon, href };
  };

  const types = [type];
  if (type === IntegrationType.Aws) {
    types.push(IntegrationType.AwsHosted);
  }

  const content = getContent();

  return (
    <Grid
      container
      sx={{
        display: "flex",
        justifyContent: "space-between",
        mb: 1,
      }}
    >
      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          display: "flex",
          mb: {
            xs: 2,
          },
        }}
      >
        {content.icon}
        <Box ml={2}>
          <Typography
            variant="h4"
            fontWeight={700}
            lineHeight=".9"
            sx={{ textTransform: "uppercase" }}
          >
            {content.title}
          </Typography>
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{ textTransform: "uppercase" }}
          >
            {content.tagline}
          </Typography>
        </Box>
      </Grid>
      <Box
        sx={{
          display: "flex",
          placeContent: "center",
        }}
      >
        {type !== IntegrationType.Bigquery && hasIntegrations && (
          <DataBox {...{ space, types }} />
        )}
        <AddButton
          id={`${type}-list`}
          href={content.href}
          layout={{ ml: 6, mt: 0.8 }}
        />
      </Box>
    </Grid>
  );
}
