import { Grid } from "@mui/material";
import { CardSizes, HubCard } from "~/components/hub-card";
import { ResourcesIcon } from "~/components/icons/mondoo/resources";
import { useMetrics } from "~/hooks";
import { GridSegment } from "~/pages/space/Dashboards/components/GridSegment";
import { Metrics } from "~/utils/arrow";

type Props = {
  sizes: CardSizes;
  assetMrn: string;
  handleCategoryFilterClick: (category: string) => void;
  selectedCategories?: string[];
};

export function AssetPolicyDistributionCard({
  sizes,
  assetMrn,
  handleCategoryFilterClick,
  selectedCategories,
}: Props) {
  const { policyCategories, isLoading } = useMetrics({
    entityMrn: assetMrn,
    metricMrns: [Metrics.PolicyCategoryDistribution],
  });

  const policyCategoriesToDisplay = [
    "security",
    "compliance",
    "best-practices",
    "total",
  ];

  return (
    <HubCard
      {...{
        id: "asset-policy-distribution-card",
        loading: isLoading,
        sizes,
        title: "Policy Distribution",
        icon: <ResourcesIcon fontSize="small" />,
        sx: {
          minHeight: "100%",
        },
      }}
    >
      <Grid container spacing={3} sx={{ pt: 2, justifyContent: "flex-end" }}>
        {policyCategories
          ?.filter((x) =>
            policyCategoriesToDisplay.includes(x.title.toLowerCase()),
          )
          ?.sort((a, b) => {
            return (
              policyCategoriesToDisplay.indexOf(a.title) -
              policyCategoriesToDisplay.indexOf(b.title)
            );
          })
          .map((data) => (
            <GridSegment
              key={data.title}
              data={data}
              showTotal={false}
              handleCategoryFilterClick={handleCategoryFilterClick}
              isSelected={selectedCategories?.includes(data.title)}
            />
          ))}
      </Grid>
    </HubCard>
  );
}
