import { Fragment, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { ChevronRightIcon, OpenInNewIcon } from "~/components/icons";
import { Command } from "~/components/guides/components";
import { Space } from "~/lib/types";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  ActionType,
  BucketOutputType,
  ClientIntegrationType,
  GetClientIntegrationDocument,
  GetIntegrationsSummaryDocument,
  IntegrationType,
  useCreateClientIntegrationMutation,
  useTriggerActionLazyQuery,
  useUpdateClientIntegrationConfigurationMutation,
} from "~/operations";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { getError } from "~/lib/handle-error";
import { IntegrationAddHeader } from "../../headers/integration-add-header";
import { helperTextStyles, ValidationMessage } from "../../validations/helpers";
import { AnimatePresence, motion } from "framer-motion";
import { Dropzone, DropzoneProps, DropzoneText } from "~/components/dropzone";
import { fadeInOut } from "~/lib/animations";
import { GreenCheckCircleIcon } from "~/pages/invitation";
import useGenerateIntegrationName from "../../utils/useGenerateIntegrationName";
import { UpdateFlowData } from "../../types";
import { setDocumentTitle } from "~/utils/commonUtils";

type GcsBucketFormInput = {
  integrationName: string;
  bucket: string;
  output: BucketOutputType;
  serviceAccount: string;
};

const defaultValues: GcsBucketFormInput = {
  integrationName: "",
  bucket: "",
  output: BucketOutputType.Jsonl,
  serviceAccount: "",
};

export function GcsBucketIntegrationForm({
  space,
  updateFlow,
}: {
  space: Space;
  updateFlow?: UpdateFlowData;
}) {
  let navigate = useNavigate();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const defaultIntegrationName = useGenerateIntegrationName({ space });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    getFieldState,
    formState: { errors, isValid, isSubmitSuccessful },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      integrationName: defaultIntegrationName,
    },
  });

  const [serviceAccount] = watch(["serviceAccount"]);

  const [createIntegration] = useCreateClientIntegrationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      const integrationMrn = data.createClientIntegration.integration.mrn;
      triggerClientIntegrationScan({
        variables: {
          input: { mrn: integrationMrn, type: ActionType.RunExport },
        },
      });
    },
    refetchQueries: [
      {
        query: GetIntegrationsSummaryDocument,
        variables: { input: { spaceMrn: space.mrn } },
      },
    ],
  });

  const [updateIntegration] = useUpdateClientIntegrationConfigurationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    refetchQueries: [
      {
        query: GetClientIntegrationDocument,
        variables: {
          mrn: `//integration.api.mondoo.app/spaces/${
            space.id
          }/integrations/${updateFlow?.integration.mrn.split("/").pop()}`,
        },
      },
    ],
  });

  const [triggerClientIntegrationScan] = useTriggerActionLazyQuery({
    onError(error) {
      console.log("%c Error Scheduling scan on creation", "color: tomato");
      console.log(error.message);
    },
  });

  useEffect(() => {
    if (updateFlow) {
      if (
        updateFlow.integration.configurationOptions?.__typename ===
        "GcsBucketConfigurationOptions"
      ) {
        const { bucket, output } = updateFlow.integration.configurationOptions;
        reset({
          integrationName: updateFlow.integration.name,
          bucket: bucket,
          output:
            output === "JSONL" ? BucketOutputType.Jsonl : BucketOutputType.Csv,
        });
      }
    }
  }, []);

  useEffect(() => {
    if (isSubmitSuccessful && !updateFlow) {
      reset(defaultValues);
    }
  }, [isSubmitSuccessful]);

  const onSubmit: SubmitHandler<GcsBucketFormInput> = async (data) => {
    const gcsBucketConfigurationOptions = {
      bucket: data.bucket.trim(),
      output: data.output,
      serviceAccount: data.serviceAccount.trim(),
    };

    try {
      if (updateFlow) {
        const integrationId = updateFlow.integration.mrn.split("/").pop();
        const mrn = `//integration.api.mondoo.app/spaces/${space.id}/integrations/${integrationId}`;
        await updateIntegration({
          variables: {
            input: {
              mrn,
              type: ClientIntegrationType.GcsBucket,
              configurationOptions: {
                gcsBucketConfigurationOptions,
              },
            },
          },
        });

        enqueueSnackbar("Successfully updated configuration", {
          variant: "success",
        });
        navigate(
          `/space/integrations/gcs_bucket/${integrationId}/?spaceId=${space.id}`,
        );
      } else {
        await createIntegration({
          variables: {
            input: {
              spaceMrn: space.mrn,
              name: data.integrationName.trim(),
              type: ClientIntegrationType.GcsBucket,
              longLivedToken: false,
              configurationOptions: {
                gcsBucketConfigurationOptions,
              },
            },
          },
        });
        navigate(`/space/integrations/gcs_bucket?spaceId=${space.id}`);
      }
    } catch (e) {
      const msg = getError(e);
      enqueueSnackbar(msg, { variant: "error" });
    }
  };

  const onDropAccepted: DropzoneProps["onDropAccepted"] = async (files) => {
    setValue("serviceAccount", await files[0].text());
  };

  setDocumentTitle(["GCP Cloud Storage Bucket", "Integrations Setup"]);

  return (
    <Fragment>
      <IntegrationAddHeader {...{ type: IntegrationType.GcsBucket }} />
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Step 1 */}
          <Box pb={4}>
            <Command
              number={1}
              options={{
                fontSize: { xs: 16 },
                dotColor: theme.palette.background.lightest,
              }}
            >
              Choose an integration name
            </Command>

            <Box>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mb: 2, mt: 2 }}
              >
                Please choose a descriptive name that lets you easily identify
                your integration.
              </Typography>
              <Controller
                name="integrationName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    disabled={updateFlow ? true : false}
                    sx={{
                      background: theme.palette.code.background,
                      borderRadius: 1,
                      color: "text.primary",
                      ...helperTextStyles,
                    }}
                    placeholder="Your integration name..."
                    error={Boolean(errors.integrationName)}
                    helperText={
                      Boolean(errors.integrationName) && (
                        <ValidationMessage error={errors.integrationName} />
                      )
                    }
                  />
                )}
              />
            </Box>
          </Box>

          {/* Step 2 */}
          <Box pb={4}>
            <Command
              number={2}
              options={{
                fontSize: { xs: 16 },
                dotColor: theme.palette.background.lightest,
              }}
            >
              Define your export settings
            </Command>

            <Box>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mb: 2, mt: 2 }}
              >
                Configure Mondoo data exports to Google Cloud Storage. To learn
                more, read the{" "}
                <Link
                  href="https://mondoo.com/docs/platform/maintain/export/gcs-bucket/"
                  target="_blank"
                  rel="noopener"
                >
                  Mondoo documentation
                </Link>
                .
              </Typography>
              <Typography fontWeight={700} sx={{ mb: 1 }}>
                Bucket name
              </Typography>
              <Controller
                name="bucket"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    sx={{
                      mb: 3,
                      background: theme.palette.code.background,
                      borderRadius: 1,
                      color: "text.primary",
                      ...helperTextStyles,
                    }}
                    placeholder="Name of target bucket ..."
                    error={Boolean(errors.bucket)}
                    helperText={
                      getFieldState("bucket").isTouched &&
                      Boolean(errors.bucket) && (
                        <ValidationMessage
                          error={errors.bucket}
                          integrationTypeId="gcs_bucket"
                        />
                      )
                    }
                  />
                )}
              />

              <Typography fontWeight={700} sx={{ mb: 1 }}>
                Export as
              </Typography>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="filetype-select-label">
                  Select a filetype...
                </InputLabel>
                <Controller
                  name="output"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Fragment>
                      <Select
                        labelId="filetype-select-label"
                        id="filetype-select"
                        label="filetype"
                        error={Boolean(errors.output)}
                        sx={{
                          "& .MuiInputBase-input": {
                            background: theme.palette.code.background,
                          },
                          "& .MuiSelect-icon": {
                            color: "text.secondary",
                          },
                        }}
                        {...field}
                      >
                        <MenuItem value={BucketOutputType.Jsonl}>
                          .jsonl
                        </MenuItem>
                        <MenuItem value={BucketOutputType.Csv}>.csv</MenuItem>
                      </Select>
                      {getFieldState("output").isTouched &&
                        Boolean(errors.output) && (
                          <FormHelperText>
                            <ValidationMessage
                              error={errors.output}
                              integrationTypeId="gcs_bucket"
                            />
                          </FormHelperText>
                        )}
                    </Fragment>
                  )}
                />
              </FormControl>
            </Box>
          </Box>
          {/* step 3 */}
          <Box pb={4}>
            <Command
              number={3}
              options={{
                fontSize: { xs: 16 },
                dotColor: theme.palette.background.lightest,
              }}
            >
              Create your Google service account
            </Command>

            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mb: 3, mt: 2 }}
            >
              Before you can integrate your Google Cloud Platform project with
              any third party service, you must create a Google service account.
              You can do this in the Google Cloud console. To learn more, read
              the Mondoo documentation and the Google documentation.
            </Typography>
            <Box>
              <Button
                variant="outlined"
                endIcon={<OpenInNewIcon />}
                sx={{ color: "#8797FF" }} //<==== new color?
              >
                Go to Google Cloud console
              </Button>
            </Box>
          </Box>

          {/* step 4 */}
          <Box pb={4}>
            <Command
              number={4}
              options={{
                fontSize: { xs: 16 },
                dotColor: theme.palette.background.lightest,
              }}
            >
              Provide your Google service account config
            </Command>

            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mb: 3, mt: 2 }}
            >
              Upload the .json file generated in the previous step.
            </Typography>
            <AnimatePresence mode="wait">
              {!serviceAccount ? (
                <Box key="dropzone" component={motion.div} {...fadeInOut}>
                  <Dropzone
                    className="mondoo-dropzone-area"
                    accept={{ "application/json": [] }}
                    multiple={false}
                    showAlerts={["error"]}
                    onDropAccepted={onDropAccepted}
                  >
                    <DropzoneText>
                      Drag and drop your .json file here.
                    </DropzoneText>
                  </Dropzone>
                </Box>
              ) : (
                <Box
                  key="success"
                  component={motion.div}
                  {...fadeInOut}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    py: 5,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <Typography variant="h6">
                      Successfully loaded JSON file
                    </Typography>
                    <GreenCheckCircleIcon fontSize="large" sx={{ ml: 2 }} />
                  </Box>

                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => setValue("serviceAccount", "")}
                  >
                    Remove JSON file
                  </Button>
                </Box>
              )}
            </AnimatePresence>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              endIcon={<ChevronRightIcon />}
              disabled={!isValid || !serviceAccount}
            >
              {updateFlow ? "update configuration" : "create export"}
            </Button>
          </Box>
        </form>
      </Box>
    </Fragment>
  );
}
