import { capitalizeFirstLetter } from "~/lib/helpers";

export const mapIntegrationRouteToTitle = (
  type: string | undefined,
): string => {
  if (!type) return "";
  switch (type.toLowerCase()) {
    case "amazon-s3-compatible":
      return "Amazon S3 compatible service";
    case "amazons3":
      return "Amazon S3";
    case "aws":
    case "aws_hosted":
      return "AWS";
    case "azure_blob":
      return "Azure Blob Storage";
    case "bigquery":
      return "BigQuery";
    case "crowdstrike":
      return "CrowdStrike";
    case "defender":
      return "Microsoft Defender for Cloud";
    case "sentinelone":
      return "SentinelOne";
    case "gcp":
      return "Google Cloud Platform";
    case "gcs_bucket":
      return "Google Cloud Storage Bucket";
    case "google_workspace":
      return "Google Workspace";
    case "github":
      return "GitHub";
    case "gitlab":
      return "GitLab";
    case "hosted_slack":
      return "Slack";
    case "host":
      return "Domain/IP Address";
    case "kubernetes":
      return "Kubernetes";
    case "managed":
      return "Managed Client";
    case "ms365":
      return "Microsoft 365";
    case "microsoft_defender":
      return "Microsoft Defender for Cloud";
    case "oci":
      return "Oracle Cloud Infrastructure";
    case "postgres":
      return "PostgreSQL";
    case "ticket_system_jira":
      return "Atlassian Jira";
    case "ticket_system_email":
      return "Ticketing via email";
    case "ticket_system_zendesk":
      return "Zendesk";
    case "ticket_system_github":
      return "GitHub Issues";
    case "ticket_system_gitlab":
      return "GitLab Issues";
    case "ticket_system_azure_devops":
      return "Azure DevOps";
    default:
      // Azure
      // Okta
      // Snowflake
      return capitalizeFirstLetter(type);
  }
};
