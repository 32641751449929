import { Typography, Grid, List, ListItem, ListItemIcon } from "@mui/material";
import { Asset } from "~/pages/inventory/asset/types";
import { ImpactUpdated } from "../impact/Updated/impact-updated";

type PrintPoliciesListProps = {
  asset: Asset;
};

export function PrintPoliciesList({ asset }: PrintPoliciesListProps) {
  const policies = asset.listPolicies?.edges || [];

  return (
    <Grid
      item
      xs={12}
      sx={{
        pb: 4,
        px: 4,
        borderBottom: "4px solid #DBDADD",
      }}
    >
      <Typography variant="h4" component="h2" mb={-1}>
        Policies
      </Typography>
      <List>
        {policies.map((edge) => {
          const score = edge.node.score;
          const policyName = edge.node.name;
          return (
            <ListItem disableGutters key={edge.node.mrn}>
              <ListItemIcon sx={{ mr: 3 }}>
                <ImpactUpdated
                  impact={{
                    value: score.riskValue,
                    rating: score.riskRating,
                  }}
                  isRiskScore
                  hideTooltip
                  contained
                  sx={{
                    width: "75px",
                  }}
                />
              </ListItemIcon>
              <Typography>{policyName}</Typography>
            </ListItem>
          );
        })}
      </List>
    </Grid>
  );
}
