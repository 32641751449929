import {
  ExceptionGroup,
  ExceptionMutationAction,
  ExceptionType,
  ReviewStatus,
  useListExceptionGroupsQuery,
} from "~/operations";

type UseGetExceptionsParams = {
  mrn?: string;
  scopeMrn: string;
  types: ExceptionType[];
  skip?: boolean;
};

type UseGetExceptionsReturn = {
  exceptionGroups: ExceptionGroup[];
  pendingExceptionsGroups: ExceptionGroup[];
  pendingAndReviewedExceptionGroups: ExceptionGroup[];
  exceptionsCount: number;
};

export function useFetchExceptions({
  scopeMrn,
  types,
  mrn,
  skip = false,
}: UseGetExceptionsParams): UseGetExceptionsReturn {
  const exceptionGroupsResult = useListExceptionGroupsQuery({
    variables: {
      input: {
        mrn,
        scopeMrn,
        types,
      },
    },
    skip,
    fetchPolicy: "cache-and-network",
  });

  const exceptionGroups = (
    exceptionGroupsResult.data?.exceptionGroups.flatMap((e) => e ?? []) || []
  ).filter(
    (group) => group.action !== ExceptionMutationAction.OutOfScope,
  ) as ExceptionGroup[];

  const pendingExceptionsGroups = exceptionGroups.filter(
    (e) => e.reviewStatus === ReviewStatus.NotReviewed,
  );
  const pendingAndReviewedExceptionGroups = exceptionGroups.filter(
    (e) => e.reviewStatus !== ReviewStatus.Rejected,
  );

  // Count the total number of exceptions
  const exceptionsCount = pendingAndReviewedExceptionGroups.length;

  return {
    pendingExceptionsGroups,
    pendingAndReviewedExceptionGroups,
    exceptionGroups,
    exceptionsCount,
  };
}
