import { Fragment, useEffect } from "react";
import { Box, Button, Grid, Link, Typography } from "@mui/material";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { useJobs } from "~/providers/jobs";
import { Space } from "~/lib/types";
import {
  LoadCicdProjectJobsQuery,
  ReportViewedPage,
  TestIamActionsQuery,
  useLoadOrganizationForSpaceQuery,
  useReportViewedMutation,
} from "~/operations";
import { Loading, LoadingPage } from "~/components/loading";
import { OpenInNewIcon } from "~/components/icons";
import { CicdBreadcrumbs } from "~/components/cicd/cicd-breadcrumbs";
import ProviderGithubLogo from "~/images/icons/provider-github.svg";
import ProviderGitlabLogo from "~/images/icons/provider-gitlab.svg";
import ProviderKubernetesLogo from "~/images/icons/provider-kubernetes.svg";
import ProviderCircleCILogo from "~/images/icons/provider-circle-ci.svg";
import ProviderAzureDevOpsLogo from "~/images/icons/provider-azure-devops.svg";
import ProviderJenkinsLogo from "~/images/icons/provider-jenkins.svg";
import { LoadMore } from "~/components/load-more";
import { CiCdJobsTable } from "~/components/cicd/cicd-jobs-table";
import { IamActions } from "~/lib/iam";
import { setDocumentTitle } from "~/utils/commonUtils";

type CicdProjectJobs = NonNullable<LoadCicdProjectJobsQuery["cicdProjectJobs"]>;

export enum JobsTypes {
  CircleCI = "circleci.com",
  Kubernetes = "k8s.mondoo.com",
  Github = "actions.github.com",
  Gitlab = "gitlab.com",
  Jenkins = "jenkins.io",
  Azure = "devops.azure.com",
}

export type Header = {
  id: string;
  label: string;
  options?: {
    align?: "inherit" | "left" | "center" | "right" | "justify";
    colSpan?: number;
  };
};

// circleCI, gitlab, azure
const defaultTableHeaders: Header[] = [
  { id: "RISK", label: "Risk", options: { align: "left" } },
  { id: "NAME", label: "Name", options: { align: "left" } },
  { id: "TYPE", label: "Type" },
  { id: "JOBID", label: "Job ID", options: { align: "left" } },
  { id: "AUTHOR", label: "Author", options: { align: "left" } },
];

const jenkinsTableHeaders: Header[] = [
  { id: "RISK", label: "Risk", options: { align: "left" } },
  { id: "NAME", label: "Name", options: { align: "left" } },
  { id: "TYPE", label: "Type" },
  { id: "JOBID", label: "Job ID", options: { align: "left" } },
];

const k8sTableHeaders: Header[] = [
  { id: "RISK", label: "Risk", options: { align: "left" } },
  { id: "NAME", label: "Namespace / Name", options: { align: "left" } },
  { id: "OPERATION", label: "Operation" },
  { id: "KIND", label: "Kind" },
  { id: "AUTHOR", label: "Author", options: { align: "left" } },
];

const githubTableHeaders: Header[] = [
  { id: "RISK", label: "Risk", options: { align: "left" } },
  { id: "NAME", label: "Name", options: { align: "left" } },
  { id: "TYPE", label: "Type" },
  { id: "RUN", label: "Run", options: { align: "left" } },
  { id: "AUTHOR", label: "Author", options: { align: "left" } },
];

const possibleJobsHeadersMap = new Map<JobsTypes, Header[]>();
possibleJobsHeadersMap.set(JobsTypes.CircleCI, defaultTableHeaders);
possibleJobsHeadersMap.set(JobsTypes.Github, githubTableHeaders);
possibleJobsHeadersMap.set(JobsTypes.Gitlab, defaultTableHeaders);
possibleJobsHeadersMap.set(JobsTypes.Kubernetes, k8sTableHeaders);
possibleJobsHeadersMap.set(JobsTypes.Azure, defaultTableHeaders);
possibleJobsHeadersMap.set(JobsTypes.Jenkins, jenkinsTableHeaders);

type Props = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function Jobs({ space, availablePermissions }: Props) {
  const { cicdProjectJobs, loading, fetchMore } = useJobs();
  const [searchParams] = useSearchParams();
  const [reportViewed] = useReportViewedMutation();

  const { data: orgDataForSpace, loading: orgDataLoading } =
    useLoadOrganizationForSpaceQuery({
      variables: { spaceMrn: space.mrn },
    });

  // Send report viewed mutation on mount and when the tab changes
  useEffect(() => {
    if (!cicdProjectJobs) return;
    if (orgDataLoading) return;
    const org = orgDataForSpace?.space?.organization;
    let orgMrn = org?.mrn ? org.mrn : "unknown";

    reportViewed({
      variables: {
        input: {
          page: ReportViewedPage.CicdProject,
          info: {
            cicdProject: {
              orgMrn: orgMrn,
              spaceMrn: space.mrn,
              projectMrn: cicdProjectJobs.project.mrn,
            },
          },
        },
      },
    });

    return () => {};
  }, [cicdProjectJobs?.project, orgDataForSpace]);

  if (!cicdProjectJobs) return <LoadingPage what="Project Jobs" />;
  const { project, jobs } = cicdProjectJobs;

  const hasDeleteAssetsPermissions = availablePermissions.includes(
    IamActions.ASSETS_DELETEASSETS,
  );

  const getProjectLogo = () => {
    switch (project.type) {
      case JobsTypes.Gitlab:
        return ProviderGitlabLogo;
      case JobsTypes.Github:
        return ProviderGithubLogo;
      case JobsTypes.Kubernetes:
        return ProviderKubernetesLogo;
      case JobsTypes.CircleCI:
        return ProviderCircleCILogo;
      case JobsTypes.Azure:
        return ProviderAzureDevOpsLogo;
      case JobsTypes.Jenkins:
        return ProviderJenkinsLogo;
      default:
        return ProviderGithubLogo;
    }
  };

  const getProjectUrlFromJobs = (jobs: CicdProjectJobs["jobs"]) => {
    const firstJob = jobs?.edges?.at(0)?.node;
    switch (firstJob?.__typename) {
      case "GitlabJob":
        return firstJob.projectUrl;
      case "CircleCIJob":
        return firstJob.projectUrl;
      case "AzureDevopsJob":
        return firstJob.projectUrl;
      case "GithubJob":
        return `${firstJob.serverUrl}/${firstJob.repository}`;
      case "KubernetesJob":
      default:
        return "";
    }
  };

  const handleLoadMore = () => {
    fetchMore({ variables: { after: jobs?.pageInfo.endCursor } });
  };

  const trail = [
    <Link
      key="1"
      component={RouterLink}
      to={`/space/cicd?spaceId=${searchParams.get("spaceId")}`}
    >
      CI/CD
    </Link>,
    <Typography key="2">{project.name}</Typography>,
  ];

  const projectUrl = getProjectUrlFromJobs(jobs);

  setDocumentTitle([project.name, "CI/CD"]);

  return (
    <Fragment>
      <CicdBreadcrumbs scope={space} trail={trail} />
      <Grid container mb={3} alignItems="center" justifyContent="space-between">
        <Grid item display="flex" alignItems="center">
          <Box sx={{ width: 32, height: 32, mr: 2 }}>
            <img
              src={getProjectLogo()}
              style={{ display: "block", width: "100%" }}
            />
          </Box>
          <Box>
            <Typography
              variant="h2"
              fontWeight="bold"
              sx={{ fontSize: 32, lineHeight: "48px" }}
            >
              {project.name}
            </Typography>
          </Box>
        </Grid>
        {projectUrl && (
          <Grid item>
            <OpenInLocationButton type={project.type} href={projectUrl} />
          </Grid>
        )}
      </Grid>
      <Box mt={4}>
        {loading && <Loading what={"jobs"} />}
        <CiCdJobsTable
          jobs={jobs}
          space={space}
          type={project.type as JobsTypes}
          headers={possibleJobsHeadersMap.get(project.type as JobsTypes)!}
          project={cicdProjectJobs?.project}
          selectable={hasDeleteAssetsPermissions}
        />
      </Box>
      <LoadMore
        loading={loading}
        handleLoadMore={handleLoadMore}
        hasNextPage={jobs?.pageInfo?.hasNextPage}
        sx={{ pt: 3 }}
      />
    </Fragment>
  );
}

//////// Open project in new window button
const OpenInLocationButton = ({
  type,
  href,
}: {
  type: string;
  href: string;
}) => {
  const getLocation = (type: string) => {
    switch (type) {
      case JobsTypes.Github:
        return "GitHub";
      case JobsTypes.Gitlab:
        return "GitLab";
      case JobsTypes.Kubernetes:
        return "Kubernetes";
      case JobsTypes.Azure:
        return "Azure";
      default:
        return "GitHub";
    }
  };

  return (
    <Button
      id="open-in-provider-button"
      component={Link}
      target="_blank"
      href={href}
      rel="noopener"
      variant="outlined"
      endIcon={<OpenInNewIcon />}
      sx={{ color: (theme) => theme.palette.secondary.light }}
    >
      Open in {getLocation(type)}
    </Button>
  );
};
