import { ReactNode } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export type TabListItem = {
  label: ReactNode;
  to?: string;
  route: string;
};

type Props = {
  id: string; //html ID for the Tabs Component
  tabList: TabListItem[];
  currentTab: string; //this is how react-router tells which tab is active
  onTabChange?: (event: React.ChangeEvent<{}>, newValue: string) => void;
  defaultTab?: string | number;
  marginBottom?: number;
  fullWidth?: boolean;
};

export function useRouteMatch(patterns: readonly string[], base: string) {
  // usematch doesn't seem to be working?
  // return patterns.find((pattern) => {
  //   return useMatch(pattern);
  // });
  const match = patterns.find((pattern) => {
    return location.pathname.includes(pattern);
  });

  return match ? match : `/${base}`;
}

export function TabNavigation({
  id,
  tabList,
  currentTab,
  onTabChange,
  defaultTab,
  marginBottom = 5,
  fullWidth = true,
}: Props) {
  return (
    <Box
      sx={{
        position: "relative",
        ...(!fullWidth && { display: "inline-block" }),
        mb: marginBottom,
        "&:after": {
          content: "''",
          position: "absolute",
          bottom: 0,
          left: 0,
          width: 1,
          height: 4,
          backgroundColor: "background.light",
        },
      }}
    >
      <Tabs
        id={id}
        variant="scrollable"
        scrollButtons="auto"
        value={currentTab}
        {...(onTabChange && { onChange: onTabChange })}
        {...(defaultTab && { defaultTab })}
        sx={[
          {
            ".MuiTabs-indicator": {
              height: 4,
              zIndex: 1,
            },
          },
        ]}
      >
        {tabList.map((tab) => {
          return (
            <Tab
              key={tab.route}
              label={tab.label}
              value={tab.route}
              {...(tab.to && { component: RouterLink, to: tab.to })}
              sx={{
                fontSize: 14,
                fontWeight: 400,
                color: "text.secondary",
                textTransform: "capitalize",
              }}
            />
          );
        })}
      </Tabs>
    </Box>
  );
}
