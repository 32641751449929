import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { SelectedEntity } from "~/components/exceptions/types";
import { Space } from "~/lib/types";
import { Asset, CheckFindingsCheckNode } from "../../asset/types";
import {
  FindingException,
  FindingType,
  MqueryDocs,
  RenderedAssetQueryData,
  ReviewStatus,
  ScoreRating,
  ScoreResultType,
  ScoreState,
  useGetCheckRowDataLazyQuery,
} from "~/operations";
import { PolicyQueryAssessmentResults } from "~/components/policy-query-assessment-results";
import { Code } from "~/components/code";
import { Markdown } from "~/components/markdown";
import {
  Box,
  Checkbox,
  Chip,
  Grid2,
  IconButton,
  List,
  styled,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { DetailRow } from "~/components/data-table";
import { NavigateToExceptionButton } from "~/components/exceptions/navigate-to-exception-button";
import { BlockIcon, ExpandLessIcon, ExpandMoreIcon } from "~/components/icons";
import { MoonIcon } from "~/components/ui-library";
import { ErrorBoundary } from "~/components/error-boundary";
import { Loading, LoadingFailed } from "~/components/loading";
import { CheckResultByText } from "~/components/impact/ByText/ByText";
import { RiskFactorsCell } from "~/components/Cells";
import { Flex } from "~/components/Flex";
import { ChecksRowDivider } from "./ChecksRowDivider";

type AssetReportQueryRowProps = {
  space: Space;
  asset: Asset;
  queryEdge: CheckFindingsCheckNode;
  isCicd?: boolean;
  isFocused?: boolean;
  isSelectable?: boolean;
  isChecked?: boolean;
  handleOpen?: (queryEdge: CheckFindingsCheckNode) => void;
  handleClose?: (queryEdge: CheckFindingsCheckNode) => void;
  handleCheck?: (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    exception: SelectedEntity,
  ) => void;
};

const Headline = styled(Typography)`
  padding-bottom: 0.3em;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

export function ChecksTableRow({
  space,
  asset,
  queryEdge,
  isCicd,
  isFocused = false,
  isSelectable = false,
  isChecked = false,
  handleOpen,
  handleClose,
  handleCheck,
}: AssetReportQueryRowProps) {
  const [open, setOpen] = React.useState<boolean>(isFocused);
  const navigate = useNavigate();

  const [getData, { data, error, loading }] = useGetCheckRowDataLazyQuery({
    variables: {
      scopeMrn: asset.mrn,
      first: 1,
      filter: {
        types: [FindingType.Check],
        mrn: queryEdge?.mrn || "",
        includeCicd: isCicd,
      },
      renderedAssetQueryInput: {
        assetMrn: asset.mrn,
        queryMrn: queryEdge?.mrn || "",
      },
    },
  });

  const getCheckRowData = async () => {
    try {
      await getData();
    } catch (error) {
      console.error(error);
    }
  };

  const notFoundErrorMessage =
    data?.renderedAssetQueryData?.__typename === "NotFoundError"
      ? data.renderedAssetQueryData.message
      : null;

  const renderedData =
    data?.renderedAssetQueryData?.__typename === "RenderedAssetQueryData"
      ? data.renderedAssetQueryData
      : null;

  const findingsUnion =
    data?.findings?.__typename === "FindingsConnection"
      ? data.findings
      : undefined;
  const checkEdges = findingsUnion?.edges || [];
  const checkFindings = checkEdges.flatMap((e) =>
    e?.node?.__typename === "CheckFinding" ? e.node : [],
  );

  const checkData = checkFindings?.[0];

  const { results } = useFormattedResults(renderedData, queryEdge?.state);

  React.useEffect(() => {
    if (isFocused) {
      setOpen(isFocused);
      getCheckRowData();
    }
  }, [isFocused]);

  const handleClick = async () => {
    if (open) {
      setOpen(false);
      handleClose?.(queryEdge);
    } else {
      setOpen(true);
      handleOpen?.(queryEdge);
      getCheckRowData();
    }
  };

  const handlePendingReviewClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    exception: FindingException,
  ) => {
    e.stopPropagation();
    navigate(
      `/space/inventory/${asset.id}/exceptions?spaceId=${space.id}&exceptionId=${exception.id}`,
    );
  };

  const {
    rating,
    state,
    resultType,
    exception,
    mrn,
    title,
    tags,
    riskFactors,
  } = queryEdge || {};

  const { mquery } = checkData || {};

  const isModified = state !== ScoreState.Open;
  const isSnoozed = state === ScoreState.Snoozed;
  const isDisabled = state === ScoreState.Disabled;
  const isPendingException =
    exception?.reviewStatus === ReviewStatus.NotReviewed;

  const descText = mquery?.docs?.desc ? (
    <Markdown source={mquery?.docs?.desc} sx={{ "& p": { m: 0 } }} />
  ) : null;
  const auditText = mquery?.docs?.audit ? (
    <Markdown source={mquery?.docs.audit} sx={{ "& p": { m: 0 } }} />
  ) : null;
  const policies = tags?.map((tag) => tag.value).join(", ");

  // extract remediation text from nested docs structure
  const getRemediationText = (remediations: MqueryDocs["remediations"]) => {
    if (
      remediations &&
      "entries" in remediations &&
      remediations.entries.length > 0
    ) {
      return remediations.entries.map((remedation) => (
        <Markdown
          key={remedation.id}
          source={remedation.desc}
          externalLinksInNew
          sx={{ "& p": { m: 0 } }}
        />
      ));
    }
    return null;
  };

  const remediationText = getRemediationText(mquery?.docs?.remediations);
  const className = [isChecked ? "selected" : ""].join(" ");

  return (
    <React.Fragment key={mrn}>
      {/* Accordion Title Row */}
      <TableRow id={mrn} onClick={handleClick} className={className}>
        {isSelectable && (
          <TableCell>
            <Checkbox
              onChange={(e, checked) =>
                handleCheck?.(e, checked, {
                  mrn: mrn || "",
                  exception,
                  groupId: exception?.id,
                })
              }
              checked={isChecked}
              onClick={(e) => e.stopPropagation()}
            />
          </TableCell>
        )}

        <TableCell className="risk-cell">
          <Box sx={{ display: "flex", gap: 1 }}>
            <CheckResultByText
              scoreResult={resultType || ScoreResultType.Unknown}
              scoreState={state || ScoreState.Open}
              rating={rating || ScoreRating.Critical}
            />
          </Box>
        </TableCell>

        <TableCell
          sx={{ opacity: isModified ? 0.5 : 1, overflowWrap: "anywhere" }}
        >
          <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="center" gap={1}>
              {isSnoozed && (
                <Chip label="Snoozed" icon={<MoonIcon />} size="small" />
              )}
              {isDisabled && (
                <Chip label="Disabled" icon={<BlockIcon />} size="small" />
              )}
              {isPendingException && (
                <NavigateToExceptionButton
                  onClick={(e) => {
                    handlePendingReviewClick(e, exception);
                  }}
                />
              )}
              <Typography
                className="mquery-title"
                fontSize={14}
                display="inline-block"
              >
                {title}
              </Typography>
            </Box>
            <List sx={{ p: 0 }}>
              <Typography variant="caption" color="text.secondary">
                {policies}
              </Typography>
            </List>
          </Box>
        </TableCell>

        <RiskFactorsCell riskFactors={riskFactors} />

        <TableCell align="right">
          <IconButton aria-label="expand query" size="small">
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      {/* Accordion Content Row */}
      <DetailRow
        id={`${mrn}-expand`}
        colSpan={isSelectable ? 5 : 4}
        open={open}
      >
        {error && (
          <Box sx={{ pt: 2, pb: 5 }}>
            <LoadingFailed what="result data" />
          </Box>
        )}
        {loading && (
          <Box sx={{ pt: 2, pb: 5 }}>
            <Loading what="result data" />
          </Box>
        )}
        {mquery && (
          <Grid2 container spacing={4} sx={{ p: 3 }}>
            <Grid2 size={12}>
              <Flex sx={{ mb: 2 }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography
                    component={RouterLink}
                    to={`/space/findings/check?spaceId=${space.id}&findingMrn=${mquery.mrn}`}
                    variant="h5"
                    fontWeight={700}
                    sx={{ display: "block", lineHeight: "40px" }}
                  >
                    {title}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    Policies: {policies}
                  </Typography>
                </Box>
                <Box>
                  {/* TODO - make more flexible or make new? */}
                  {/* <SetExceptionButton /> */}
                </Box>
              </Flex>
              <Grid2 size={12} container spacing={3}>
                <Grid2 size="grow">
                  {mquery.docs?.desc && (
                    <Markdown
                      source={mquery.docs?.desc}
                      sx={{
                        fontSize: 16,
                        lineHeight: 1.5,
                        "& > p": { my: 0 },
                      }}
                    />
                  )}
                </Grid2>
                <Grid2 size="auto">
                  <CheckResultByText
                    scoreResult={resultType || ScoreResultType.Unknown}
                    scoreState={state || ScoreState.Open}
                    rating={rating || ScoreRating.Critical}
                  />
                </Grid2>
              </Grid2>
            </Grid2>

            {remediationText && (
              <ErrorBoundary
                key={`${mrn}-remediation`}
                FallbackComponent={RowErrorFallback}
              >
                <Grid2 size={12}>
                  <ChecksRowDivider title="Remediation" />
                  {/* remediation text is in a Markdown component */}
                  {remediationText}
                </Grid2>
              </ErrorBoundary>
            )}

            {(results || notFoundErrorMessage) && (
              <Grid2 size={12}>
                <ChecksRowDivider title="Result" />
                {notFoundErrorMessage && (
                  <Code
                    copyButton
                    className="ini"
                    ContainerProps={{ sx: { m: 0 } }}
                  >
                    {"[Query Error]\n" + notFoundErrorMessage}
                  </Code>
                )}
                {/* results are either in a Code or Markdown component */}
                {results}
              </Grid2>
            )}

            <Grid2 size={12}>
              <ChecksRowDivider title="Query" />
              <Code
                copyButton
                className="javascript"
                ContainerProps={{ sx: { m: 0 } }}
              >
                {mquery.mql.trim()}
              </Code>
            </Grid2>

            {auditText && (
              <Grid2 size={12}>
                <ChecksRowDivider title="Audit" />
                {auditText}
              </Grid2>
            )}
          </Grid2>
        )}
      </DetailRow>
    </React.Fragment>
  );
}

const RowErrorFallback = ({ error }: { error: Error }) => {
  console.error(error.message);
  return (
    <TableRow>
      <TableCell colSpan={5} sx={{ border: "none" }}></TableCell>
    </TableRow>
  );
};

export const useFormattedResults = (
  renderedData: RenderedAssetQueryData | null,
  scoreState?: ScoreState,
) => {
  const [results, setResults] = React.useState<React.ReactNode | undefined>(
    undefined,
  );
  const { data, assessment } = renderedData || {};

  const getStatusfromScoreState = () => {
    switch (scoreState) {
      case ScoreState.Open:
        return "fail";
      case ScoreState.Closed:
        return "excellent";
      default:
        return "fail";
    }
  };

  useEffect(() => {
    getResults();
  }, [renderedData]);

  const getResults = () => {
    let results = undefined;
    if (assessment) {
      results = (
        <PolicyQueryAssessmentResults
          assessment={assessment}
          status={getStatusfromScoreState()}
        />
      );
    } else if (data) {
      try {
        results = (
          <Code className="plaintext" ContainerProps={{ sx: { p: 0 } }}>
            {JSON.stringify(data, null, 2)}
          </Code>
        );
      } catch {
        results = (
          <Code className="plaintext" ContainerProps={{ sx: { p: 0 } }}>
            An error occurred while processing results
          </Code>
        );
      }
    }

    setResults(results);
  };

  return { results };
};
