import { Box, Container, Grid2, Typography } from "@mui/material";
import { spacing } from "../lib/SharedVariables";
import { useCveOrAdvisoryReport } from "./hooks/useCveOrAdvisoryReport";
import { SectionHeading } from "../../components";
import { Stats } from "~/components/DetailsPage/components";
import {
  CveSources,
  CvssRiskContent,
  CvssRiskProvider,
  CvssVector,
  EpssRiskContent,
  EpssRiskProvider,
  Sources,
} from "~/components/vulnerabilities";
import { PrintScoreBlock } from "../components";
import { DetectedBy } from "~/components/vulnerabilities/DetectedBy/DetectedBy";
import { RiskFactors } from "../AssetReport/RiskFactors";
import { AffectedAssets } from "./AffectedAssets";
import { Vulnerabilities } from "./Vulnerabilities";
import { Markdown } from "~/components/markdown";

type CveOrAdvisoryReportProps = {};

export function CveOrAdvisoryReport({}: CveOrAdvisoryReportProps) {
  const { data, aggregateNodeData } = useCveOrAdvisoryReport();

  const hasAdvisorySources =
    Boolean(data.sourceLinks.length > 0) && data.type === "advisory";
  const hasCveSources = data.cveSources && data.type === "cve";
  const hasSources = hasAdvisorySources || hasCveSources;

  return (
    <Container sx={{ p: 4 }}>
      <Grid2 container>
        <Grid2 size={12} sx={{ mb: spacing[50] }}>
          <Typography variant="h4" fontWeight={700}>
            {data.title}
          </Typography>
        </Grid2>
        <Grid2 size={12}>
          <SectionHeading heading="Summary" disableUpperPadding />
        </Grid2>

        <Stats id="detail-content-stats" stats={data.stats} />
        <Box sx={{ position: "relative" }}>
          {aggregateNodeData?.riskValue != null &&
            aggregateNodeData?.rating && (
              <Box
                sx={{
                  float: "right",
                  ml: 3,
                  mb: 1,
                  mt: 3.75,
                }}
              >
                <PrintScoreBlock
                  mappings={data.mappings}
                  ratingValues={{
                    value: aggregateNodeData.riskValue,
                    rating: aggregateNodeData.rating,
                  }}
                />
              </Box>
            )}

          {data.summary && (
            <Markdown
              source={data.summary}
              externalLinksInNew
              copyButton={false}
              sx={{
                my: spacing[25],
                "& p": {
                  fontSize: 14,
                  fontFamily: "Roboto",
                  lineHeight: "1.43",
                  textAlign: "justify",
                },
              }}
            />
          )}
        </Box>

        <Grid2 size={12} sx={{ mb: spacing[25] }}>
          {data.detectedBy && (
            <DetectedBy
              detectedBy={data.detectedBy}
              widthOverride={{ xs: 12 }}
            />
          )}
        </Grid2>
        {hasSources && (
          <Grid2 size={12}>
            {hasAdvisorySources && (
              <Sources id="advisory-sources" links={data.sourceLinks} />
            )}
            {data.cveSources && (
              <CveSources id="cve-sources" {...{ ...data.cveSources }} />
            )}
          </Grid2>
        )}
        <Grid2 size={12} sx={{ mb: spacing[50] }}>
          <RiskFactors
            dataType={data.type}
            riskFactors={data.riskFactorsWithDocs}
          />
        </Grid2>

        {data.cvssScore?.value && (
          <Grid2 size={12} sx={{ mb: spacing[50] }}>
            <CvssRiskProvider vector={data.cvssScore.vector} isPrintView>
              <SectionHeading heading="CVSS v3 score" sx={{ pb: 0 }} />
              <Box sx={{ pt: 1, pb: spacing[25] }}>
                <CvssVector />
              </Box>
              <CvssRiskContent />
            </CvssRiskProvider>
          </Grid2>
        )}

        {data.epssScore && data.cvssScore && (
          <Grid2 size={12} sx={{ mb: spacing[50] }}>
            <SectionHeading heading="EPSS score" />
            <EpssRiskProvider
              probability={data.epssScore.probability}
              percentile={data.epssScore.percentile}
              cvssScore={data.cvssScore?.value}
              printView
            >
              <Box sx={{ pt: 3 }}>
                <EpssRiskContent />
              </Box>
            </EpssRiskProvider>
          </Grid2>
        )}

        {data.type === "advisory" && (
          <Grid2 size={12} sx={{ mb: spacing[50] }}>
            <Vulnerabilities vulnerabilities={data.vulnerabilities} />
          </Grid2>
        )}

        {/* Where do I source these */}
        {/* {data.type === "cve" && (
          <Grid2 size={12} sx={{ mb: spacing[50] }}>
            <RelatedAdvisories relatedAdvisories={data.relatedAdvisories} />
          </Grid2>
        )} */}

        <Grid2 size={12} sx={{ mb: spacing[50] }}>
          <AffectedAssets affectedAssets={data.affectedAssets} />
        </Grid2>
      </Grid2>
    </Container>
  );
}
