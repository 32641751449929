import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ClickableTableCell, DashboardTable } from "./dashboard-table";
import {
  GetOrganizationOverviewQuery,
  useGetOrganizationOverviewQuery,
} from "~/operations";
import { RiskDistribution } from "~/components/risk-distribution";
import DashboardSkeleton from "~/pages/organization/dashboard/DashboardSkeleton";

type SpacesOverview = NonNullable<
  GetOrganizationOverviewQuery["organizationOverview"]
>["spacesOverview"];

export type SpacesGlanceProps = {
  organizationMrn: string;
};

export function SpacesGlance({ organizationMrn }: SpacesGlanceProps) {
  const result = useGetOrganizationOverviewQuery({
    variables: { input: { organizationMrn } },
  });

  if (result.loading) {
    return <DashboardSkeleton />;
  }

  const spaces: SpacesOverview =
    result.data?.organizationOverview?.spacesOverview;

  if (!spaces?.length) {
    return (
      <Typography
        sx={{
          fontWeight: "bold",
          color: "text.secondary",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          height: "100%",
        }}
      >
        Create spaces in your organization, and a summary will be shown here
      </Typography>
    );
  }

  return (
    <DashboardTable>
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: "40%" }}>Name</TableCell>
          <TableCell sx={{ width: "40%", p: 0 }}>Scores</TableCell>
          <TableCell sx={{ width: "20%" }}>Assets</TableCell>
        </TableRow>
      </TableHead>
      <TableBody sx={[{ ".MuiTableCell-body": { fontSize: 12 } }]}>
        {spaces?.map((item) => {
          const spaceId = item.spaceMrn.split("/").pop();
          const spaceHref = `/space/overview?spaceId=${spaceId}`;
          return (
            <TableRow key={item.spaceMrn}>
              <ClickableTableCell
                sx={{ width: "40%", maxWidth: "160px" }}
                href={spaceHref}
              >
                {item.spaceName}
              </ClickableTableCell>
              <ClickableTableCell sx={{ width: "40%", p: 0 }} href={spaceHref}>
                <RiskDistribution data={item.scores} variant="bar" />
              </ClickableTableCell>
              <ClickableTableCell sx={{ width: "20%" }} href={spaceHref}>
                {item.scores.total}
              </ClickableTableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </DashboardTable>
  );
}
