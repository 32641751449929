import React, { ReactNode, useEffect, useState } from "react";
import { Box, Grid, MenuItem, Paper, Select } from "@mui/material";
import { AutoConfigurationSection } from "~/pages/integrations/hosted-integrations/forms/jira/AutoConfigurationSection";
import {
  ClientIntegrationType,
  GetClientIntegrationDocument,
  useUpdateClientIntegrationConfigurationMutation,
} from "~/operations";
import { Controller, useForm } from "react-hook-form";
import { Space } from "~/lib/types";
import { TicketIntegration } from "~/components/cases/components/CaseSettings/types";
import { motion } from "framer-motion";
import { SubField } from "~/components/Form/components";
import { FormattedDestination } from "~/pages/integrations/hosted-integrations/forms/azure-devops/FormattedDestination";
import { AzureDevOpsFormInput } from "~/pages/integrations/hosted-integrations/forms/azure-devops/types";
import { useAzureTicketContext } from "~/hooks/useAzureTicketContext";

type CaseSettingsIntegrationProps = {
  integration: TicketIntegration;
  space: Space;
  showAutoCreateSection: boolean;
};

export const CaseSettingsAzureIntegration = ({
  integration,
  space,
  showAutoCreateSection,
}: CaseSettingsIntegrationProps) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const { control, handleSubmit, reset, watch } = useForm<AzureDevOpsFormInput>(
    {
      defaultValues: {
        defaultProject: "",
        autoCloseTickets: false,
        autoCreateCases: false,
      },
      mode: "onChange",
    },
  );
  const { projects, isProjectsEmpty } = useAzureTicketContext({
    scopeMrn: integration?.mrn || "",
    skip: !integration?.mrn,
  });

  const [updateIntegration] = useUpdateClientIntegrationConfigurationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    refetchQueries: [
      {
        query: GetClientIntegrationDocument,
        variables: {
          mrn: `//integration.api.mondoo.app/spaces/${
            space.id
          }/integrations/${integration?.mrn.split("/").pop()}`,
        },
      },
    ],
  });

  const onSubmit = async (data: AzureDevOpsFormInput) => {
    const azureDevopsConfigurationOptions = {
      defaultProjectName: data.defaultProject || "",
      organizationUrl: data.organizationUrl,
      autoCloseTickets: data.autoCloseTickets,
      autoCreateTickets: data.autoCreateCases,
      tenantId: data.tenantId,
      servicePrincipalId: data.servicePrincipalId,
      clientSecret: "",
    };

    await updateIntegration({
      variables: {
        input: {
          mrn: `//integration.api.mondoo.app/spaces/${
            space.id
          }/integrations/${integration?.mrn.split("/").pop()}`,
          type: ClientIntegrationType.TicketSystemAzureDevops,
          configurationOptions: {
            azureDevopsConfigurationOptions,
          },
        },
      },
    });
  };

  useEffect(() => {
    // TypeScript users
    const subscription = watch(() => {
      isInitialized && handleSubmit(onSubmit)();
    });
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, isInitialized]);

  const [watchAutoCreate] = watch(["autoCreateCases"]);

  useEffect(() => {
    const configOptions = integration?.configurationOptions;
    if (configOptions?.__typename !== "AzureDevopsConfigurationOptions") return;
    reset({
      defaultProject: configOptions.defaultProjectName || "",
      organizationUrl: configOptions.organizationUrl,
      integrationName: integration?.name || "",
      autoCloseTickets: configOptions.autoCloseTickets,
      autoCreateCases: configOptions.autoCreateTickets,
      tenantId: configOptions.tenantId,
      servicePrincipalId: configOptions.servicePrincipalId,
    });
    setIsInitialized(true);
  }, []);

  return (
    <Box>
      <Paper sx={{ p: 3 }}>
        <AutoConfigurationSection
          control={control}
          showAutoCreateSection={showAutoCreateSection}
          autoCreateSubField={
            <Box
              component={motion.div}
              initial={false}
              transition={{
                duration: 0.35,
                staggerChildren: 0.25,
              }}
              sx={{ overflow: "hidden" }}
            >
              <Controller
                name="defaultProject"
                {...{ control }}
                render={({ field }) => (
                  <SubField
                    configuration="Select a drift destination"
                    caption="Select where to create an issue for each new drift ticket."
                    disabled={!watchAutoCreate}
                    field={
                      <Grid item xs={12} md={6} sx={{ alignSelf: "flex-end" }}>
                        <Select
                          {...field}
                          fullWidth
                          disabled={!watchAutoCreate}
                          sx={{
                            "&.MuiInputBase-root": {
                              bgcolor: "code.background",
                            },
                          }}
                          displayEmpty
                          renderValue={(selected: ReactNode) => {
                            const selectedProject = projects?.find(
                              (project) => project.name === selected,
                            );

                            const selectedProjectTitle = (
                              <FormattedDestination
                                projectTitle={selectedProject?.name || ""}
                              />
                            );

                            if (isProjectsEmpty) {
                              return (
                                <>
                                  No projects available – create one in Azure
                                  DevOps first
                                </>
                              );
                            }

                            if (!selected || !selectedProjectTitle) {
                              return <>Select project</>;
                            }

                            return <>{selectedProjectTitle}</>;
                          }}
                        >
                          {(projects || []).map((project) => (
                            <MenuItem key={project.name} value={project.name}>
                              {project.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    }
                  />
                )}
              />
            </Box>
          }
        />
      </Paper>
    </Box>
  );
};
