import { differenceInDays } from "date-fns";
import { AssetOverviewItem } from "~/components/asset-configuration-section";
import { Format } from "~/lib/date";
import { AssetOverviewReferenceTypeEnum } from "~/operations";

export const formatGroupType = (type: string) => {
  const regex = new RegExp("(^k8s.)|(^aws.)");
  return type.replace(regex, "") + "s";
};

export const isLastScanOlderThanSevenDays = (dateString: string): boolean => {
  try {
    return differenceInDays(new Date(), dateString) >= 7;
  } catch {
    return false;
  }
};

export const createAssetOverviewUrlValue = (
  key: string,
  name: string,
  url: string,
  displayValue: string,
) => {
  return {
    __typename: "AssetOverviewItem" as const,
    key,
    name,
    values: [
      { __typename: "AssetOverviewURLValue" as const, url, displayValue },
    ],
  };
};

export const createAssetOverviewStringValue = (
  key: string,
  name: string,
  stringValue: string,
) => {
  return {
    __typename: "AssetOverviewItem" as const,
    key,
    name,
    values: [{ __typename: "AssetOverviewStringValue" as const, stringValue }],
  };
};

export const createAssetOverviewTimeValue = (
  key: string,
  name: string,
  timeValue: string,
) => {
  return {
    __typename: "AssetOverviewItem" as const,
    key,
    name,
    values: [{ __typename: "AssetOverviewTimeValue" as const, timeValue }],
  };
};

export const extractAssetKey = (item: AssetOverviewItem) => {
  const val = item.values[0];
  switch (val.__typename) {
    case "AssetOverviewDictValue":
      return val.dictValue?.at(0)?.key || item.name;
    default:
      return item.name;
  }
};

export const extractAssetValue = (item: AssetOverviewItem): string => {
  const val = item.values[0];

  switch (val.__typename) {
    case "AssetOverviewNullValue":
      return "no data";
    case "AssetOverviewBoolValue":
      return val.boolValue ? "Yes" : "No";
    case "AssetOverviewArrayValue":
      return (
        val.arrayValue
          ?.map((v) => extractAssetValue({ ...item, values: [v] }))
          .join(", ") || ""
      );
    case "AssetOverviewDictValue":
      return val.dictValue?.at(0)?.value || "";
    case "AssetOverviewStringValue":
      return val.stringValue;
    case "AssetOverviewTimeValue":
      return Format(val.timeValue);
    case "AssetOverviewReferenceValue":
      return val.displayValue;
    case "AssetOverviewError":
      // if the value starts with "could not retrieve integration", then we want to clean up the error message
      if (val.value.msg.startsWith("could not retrieve integration")) {
        return "integration not found";
      }
      return val.value.msg;
    case "AssetOverviewURLValue":
      return val.displayValue;
  }
};

export const extractAssetLink = (
  item: AssetOverviewItem,
  spaceParams: string,
  hasIntegrationUpdatePermission: boolean,
) => {
  const value = item.values[0];

  switch (value.__typename) {
    case "AssetOverviewURLValue":
      return value.url;
    case "AssetOverviewReferenceValue":
      if (
        value.referenceType === AssetOverviewReferenceTypeEnum.Integration &&
        hasIntegrationUpdatePermission
      ) {
        return `/space/integrations/mrn?${spaceParams}&integrationMrn=${value.mrn}`;
      }
    default:
      return undefined;
  }
};
