import { Stack, Typography } from "@mui/material";
import { HubCard } from "~/components/hub-card";
import { NavDrawerFleetIcon, PriorityFindingsIcon } from "~/components/icons";
import { useLoadScope } from "./hooks/useLoadScope";

type PriorityFindingsDashboardCardProps = {};

export function PriorityFindingsDashboardCard({}: PriorityFindingsDashboardCardProps) {
  const { loading, findingsCount, assetCount } = useLoadScope();

  return (
    <HubCard
      loading={loading}
      title="Priority Findings"
      tagline="critical & high risk findings"
      icon={<PriorityFindingsIcon />}
      data-testid="priority-findings-card"
    >
      <Stack direction="row" gap={3}>
        <Typography
          fontSize={32}
          fontWeight={700}
          data-testid="priority-findings-count"
        >
          {findingsCount}
        </Typography>
        <Stack direction="row" alignItems="center" gap={0.5}>
          <NavDrawerFleetIcon />
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ lineHeight: "40px" }}
            data-testid="priority-findings-assets-count"
          >
            {assetCount} Assets
          </Typography>
        </Stack>
      </Stack>
    </HubCard>
  );
}
