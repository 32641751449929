import { FC, ReactElement } from "react";
import { Button, ButtonProps, Grid, Typography, SxProps } from "@mui/material";
import { DisableIcon, SnoozeIcon } from "~/components/ui-library";
import {
  ComplianceFrameworkState,
  ControlState,
  CheckState,
} from "~/operations";
import { EditIcon, VisibilityOffIcon } from "~/components/icons";

type ExceptionFlagProps = {
  state: ControlState | ComplianceFrameworkState;
  onClick: ButtonProps["onClick"];
  buttonProps?: {
    sx: SxProps;
  };
};

export function ExceptionFlag({
  state,
  onClick,
  buttonProps = { sx: {} },
}: ExceptionFlagProps) {
  const getStatus = (
    state: ControlState | ComplianceFrameworkState | CheckState,
  ) => {
    if (
      state === ControlState.Active ||
      state === ComplianceFrameworkState.Active ||
      state === CheckState.Active
    ) {
      return "active";
    } else if (
      state === ControlState.Snoozed ||
      state === ComplianceFrameworkState.Preview
    ) {
      return "snoozed";
    } else if (state === ControlState.OutOfScope) {
      return "out of scope";
    } else {
      return "disabled";
    }
  };

  if (getStatus(state) === "active") {
    return <SetExceptionButton onClick={onClick} {...buttonProps} />;
  }

  function getFlagData(state: ControlState | ComplianceFrameworkState): {
    label: string;
    icon: ReactElement;
    tagline: string;
  } {
    const status = getStatus(state);

    switch (status) {
      case "snoozed":
        return {
          label: "SNOOZED",
          tagline: "Run, do not score - optional time limit",
          icon: <SnoozeIcon />,
        };
      case "out of scope":
        return {
          label: "OUT OF SCOPE",
          tagline: "Out Of Scope",
          icon: <VisibilityOffIcon />,
        };
      case "disabled":
      default:
        return {
          label: "DISABLED",
          tagline: "Do not run or score",
          icon: <DisableIcon />,
        };
    }
  }

  const flagData = getFlagData(state);

  return (
    <Grid
      container
      sx={{
        padding: 1,
        minWidth: 211,
        maxWidth: 211,
        columnGap: 1,
        border: "1px solid",
        borderColor: "text.secondary",
        borderRadius: 1,
        color: "text.secondary",
        alignSelf: "flex-start",
      }}
      data-test-id="exception-flag"
    >
      <Grid item xs="auto">
        {flagData.icon}
      </Grid>
      <Grid item container xs sx={{ flexDirection: "column" }}>
        <Grid>
          <Typography
            sx={{
              fontSize: (theme) => theme.spacing(1.75),
              fontWeight: 600,
              textTransform: "uppercase",
              lineHeight: "normal",
            }}
            data-test-id="exception-flag-status"
          >
            {flagData.label}
          </Typography>
        </Grid>
        <Grid>
          <Typography
            sx={{
              fontSize: (theme) => theme.spacing(1.25),
            }}
          >
            {flagData.tagline}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export const SetExceptionButton: FC<ButtonProps> = (props) => {
  return (
    <Button
      {...props}
      variant="outlined"
      startIcon={<EditIcon sx={{ fontSize: (theme) => theme.spacing(2.25) }} />}
      sx={{
        color: "text.secondary",
        fontSize: (theme) => theme.spacing(1.625),
        ...props.sx,
      }}
      data-test-id="set-exception-button"
    >
      Set Exception
    </Button>
  );
};
