import { Button, ButtonProps } from "@mui/material";
import { Flex } from "~/components/Flex";
import { AddIcon, IndentIcon } from "~/components/icons";

type AddConditionRowProps = {
  onClick: ButtonProps["onClick"];
  disabled: boolean;
};

export const AddConditionRow = ({
  onClick,
  disabled,
}: AddConditionRowProps) => {
  return (
    <Flex gap={1} alignItems="center" ml={6}>
      <IndentIcon sx={{ color: (theme) => theme.palette.text.disabled }} />
      <Button
        variant="text"
        disabled={disabled}
        sx={{
          color: (theme) => theme.palette.secondary.light,
        }}
        onClick={onClick}
        startIcon={<AddIcon />}
        data-testid="add-condition-button"
      >
        Add Condition
      </Button>
    </Flex>
  );
};
