import { SelectionProps } from "~/pages/space/vulnerabilities/components/AffectedAssets/types";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";
import { Loading, LoadingFailed } from "~/components/loading";
import { Grid } from "@mui/material";
import { AffectedAssetsTable } from "./AffectedAssetsTable";
import { EmptySection } from "~/components/vulnerabilities";
import { usePolicyAssets } from "./hooks/usePolicyAssets";

type SecurityPolicyAffectedAssetsAdaptorProps = {
  scope: SpaceOrWorkspaceScope;
  selectionProps?: SelectionProps | undefined;
};

export const SecurityPolicyAssetsTab = ({
  scope,
  selectionProps,
}: SecurityPolicyAffectedAssetsAdaptorProps) => {
  const {
    assets,
    orderBy,
    handleSortClick,
    filteredTotalCount,
    pageInfo,
    fetchMore,
    error,
    loading,
  } = usePolicyAssets({
    scope,
  });

  if (error) {
    return <LoadingFailed what="assets" />;
  }

  if (loading) {
    return <Loading what="assets" />;
  }

  return (
    <Grid id="exposed-assets" item xs={12}>
      {(assets?.length || 0) > 0 && !error ? (
        <AffectedAssetsTable
          scopeParams={scope.params}
          assets={assets}
          orderBy={orderBy}
          handleSortClick={handleSortClick}
          filteredTotalCount={filteredTotalCount || 0}
          pageInfo={pageInfo}
          fetchMore={fetchMore}
          selectionProps={selectionProps}
          urlContextType={"ASSET"}
          contextId={""}
        />
      ) : (
        <EmptySection
          id="affected-assets-list-empty"
          text={`There are currently no exposed assets for this policy`}
        />
      )}
    </Grid>
  );
};
