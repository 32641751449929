import { Fragment, ReactNode, useLayoutEffect, useState } from "react";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  DataQueryOrder,
  DataQueryOrderField,
  GetDataQueryQuery,
  OrderDirection,
  useGetDataQueriesListQuery,
  useGetDataQueryLazyQuery,
} from "~/operations";
import { DataTable, DetailRow } from "~/components/data-table";
import { useAssetOutlet } from "../asset";
import { Code } from "~/components/code";
import { Flex } from "~/components/Flex";
import { Loading, LoadingFailed } from "~/components/loading";
import { Markdown } from "~/components/markdown";
import { FilterBar } from "../../compliance/filter-bar";
import { useSearch } from "~/components/search/useSearch";
import { useSearchParams } from "react-router-dom";
import { ExpandLessIcon, ExpandMoreIcon } from "~/components/icons";
import { EmptySection } from "~/components/vulnerabilities";
import { useSort } from "../hooks/useSort";
import {
  INITIAL_PAGE_RANGE,
  Pagination,
  PaginationRange,
} from "~/components/pagination";

export type DataQueries = Extract<
  NonNullable<GetDataQueryQuery["dataQueries"]>,
  { __typename: "DataQueriesConnection" }
>;

type DataQueryEdges = NonNullable<DataQueries["edges"]>;
type DataQueriesEdge = NonNullable<DataQueryEdges>[0];

type AssetDataQueriesProps = {};

export function AssetDataQueries({}: AssetDataQueriesProps) {
  const [searchParams] = useSearchParams();
  const { assetMrn, isCicd } = useAssetOutlet();
  const { handleFilterQuery, searchFilters } = useSearch();
  const [pageItems, setPageItems] =
    useState<PaginationRange>(INITIAL_PAGE_RANGE);
  const queryMrn = searchParams.get("queryMrn");

  const { orderBy } = useSort<DataQueryOrder>({
    defaultSort: {
      field: DataQueryOrderField.LastUpdated,
      direction: OrderDirection.Desc,
    },
  });

  const { data, error, loading, fetchMore } = useGetDataQueriesListQuery({
    variables: {
      entityMrn: assetMrn,
      first: 10,
      orderBy,
      filter: {
        queryTerms: searchFilters,
      },
    },
  });

  if (loading || !data) {
    return (
      <Flex center sx={{ py: 10 }}>
        <Loading what="Data queries" />
      </Flex>
    );
  }

  if (error || !assetMrn) {
    return (
      <Flex center sx={{ py: 10 }}>
        <LoadingFailed what="Data queries" />
      </Flex>
    );
  }

  if (data.dataQueries?.__typename === "NotFoundError") {
    return (
      <Flex center sx={{ py: 10 }}>
        <Typography>
          We were unable to locate the data queries associated with this asset.
        </Typography>
      </Flex>
    );
  }

  if (data.dataQueries?.__typename === "RequestError") {
    return (
      <Flex center sx={{ py: 10 }}>
        <Typography>
          There was an error fetching the data queries for this asset.
        </Typography>
      </Flex>
    );
  }

  const dataQueries =
    data.dataQueries?.__typename === "DataQueriesConnection"
      ? data.dataQueries.edges
      : [];

  // Focus on query if searchParams contains queryMrn
  const focusedQueryEdge = dataQueries?.find((edge) => {
    return edge.node?.mquery?.mrn === queryMrn;
  });

  if (focusedQueryEdge) {
    const mrn = focusedQueryEdge.node?.mquery?.mrn;
    if (mrn) {
      document.getElementById(mrn)?.scrollIntoView({ behavior: "smooth" });
    }
  }

  return (
    <Grid container mt={3}>
      <Grid item xs={12}>
        <FilterBar
          searchId="asset-data-queries-search-bar"
          placeholder="asset_data_queries"
          searchFilters={searchFilters}
          handleFilterQuery={handleFilterQuery}
        />
      </Grid>
      <DataTable>
        <TableHead>
          <TableRow>
            <TableCell
              sortDirection={orderBy.direction === "DESC" ? "desc" : "asc"}
            >
              Query
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {dataQueries?.slice(pageItems.from, pageItems.to).map((queryEdge) => {
            return (
              <DataQueryRow
                assetMrn={assetMrn}
                queryEdge={queryEdge}
                isCicd={isCicd}
                key={queryEdge.node?.mquery?.mrn}
                shouldOpen={
                  queryEdge.node?.mquery?.mrn ===
                  focusedQueryEdge?.node?.mquery?.mrn
                }
              />
            );
          })}
        </TableBody>
      </DataTable>
      <Pagination
        totalCount={data.dataQueries?.filteredTotalCount || 0}
        pageInfo={data.dataQueries?.pageInfo}
        setPageItems={setPageItems}
        fetchMore={fetchMore}
      />
    </Grid>
  );
}

function DataQueryRow({
  assetMrn,
  queryEdge,
  shouldOpen,
  isCicd,
}: {
  assetMrn: string;
  queryEdge: DataQueriesEdge;
  shouldOpen: boolean;
  isCicd?: boolean;
}) {
  const [open, setOpen] = useState<boolean>(shouldOpen);
  const { node } = queryEdge;
  const [getDataQuery, { data, error, loading }] = useGetDataQueryLazyQuery({
    variables: {
      entityMrn: assetMrn,
      orderBy: {
        field: DataQueryOrderField.LastUpdated,
        direction: OrderDirection.Desc,
      },
      filter: {
        queryMrn: node?.mrn,
        includeCicd: isCicd,
      },
    },
  });

  useLayoutEffect(() => {
    if (open) {
      getDataQuery();
    }
  }, [open]);

  const handleClick = () => {
    setOpen(!open);
  };

  const notFoundErrorMessage =
    data?.dataQueries?.__typename === "NotFoundError"
      ? data.dataQueries.message
      : null;
  const renderedDataEdges =
    data?.dataQueries?.__typename === "DataQueriesConnection"
      ? data.dataQueries.edges
      : null;
  const queryData = renderedDataEdges?.[0];
  const description = queryData?.node?.mquery?.docs?.desc;
  const mql = queryData?.node?.mquery?.mql;
  const value = queryData?.node?.value;

  return (
    <Fragment>
      <TableRow onClick={handleClick} id={node?.mquery?.mrn}>
        <TableCell>
          <Typography>{node?.mquery?.title}</Typography>
          <List sx={{ p: 0 }}>
            <Typography variant="caption" color="text.secondary">
              {node?.tags?.map((tag) => tag.value).join(", ")}
            </Typography>
          </List>
          <Typography variant="caption" color="text.secondary"></Typography>
        </TableCell>
        <TableCell align="right">
          <IconButton aria-label="expand query" size="small">
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <DetailRow colSpan={2} open={open}>
        <ListItem key="result" disableGutters sx={{ px: 2 }}>
          <ListItemText
            primary={
              <>
                {loading && (
                  <Box sx={{ pt: 2, pb: 5 }}>
                    <Loading />
                  </Box>
                )}
                {error && (
                  <Box sx={{ pt: 2, pb: 5 }}>
                    <LoadingFailed what="result data" />
                  </Box>
                )}
                {notFoundErrorMessage && (
                  <Code copyButton className="ini">
                    {"[Query Error]\n" + notFoundErrorMessage}
                  </Code>
                )}

                {description && (
                  <DetailSection
                    heading="Description"
                    child={<Markdown source={description} />}
                  />
                )}
                {mql && <DetailSection heading="Query" code={mql} />}
                {value && (
                  <DetailSection
                    heading="Result"
                    code={JSON.stringify(value, null, 2)}
                  />
                )}
                {!value && !loading && (
                  <EmptySection
                    id={`data-query-${node?.mrn}-results-empty`}
                    text="When last run, this query returned no results."
                  />
                )}
              </>
            }
          />
        </ListItem>
      </DetailRow>
    </Fragment>
  );
}

type DetailSectionProps = {
  heading: string;
  code?: string;
  content?: string;
  child?: ReactNode;
};

const DetailSection = ({
  heading,
  code,
  content,
  child,
}: DetailSectionProps) => {
  return (
    <Grid container alignItems="center">
      <Grid item sx={{ display: "flex", alignItems: "center" }}>
        <Box>
          <Typography
            sx={{
              display: "inline-block",
              pr: 3,
              pb: 1,
              fontWeight: 700,
            }}
          >
            {heading}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs>
        <Divider />
      </Grid>
      <Grid item xs={12} sx={{ mb: 2 }}>
        {content && <Typography>{content}</Typography>}
        {code && (
          <Code className="plaintext" copyButton>
            {code}
          </Code>
        )}
        {child && child}
      </Grid>
    </Grid>
  );
};
