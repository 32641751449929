export const MONDOO_ASSET_API_URL = `//assets.api.mondoo.app`;
export const MONDOO_VULN_MRN_PREFIX = `//vadvisor.api.mondoo.app`;
export const MONDOO_ASSET_SCORE_DOC_URL =
  "https://mondoo.com/docs/platform/security/posture/asset-score/";
export const MONDOO_POLICY_API_URL = `//policy.api.mondoo.app`;
export const MONDOO_PLATFORM_VULN_POLICY_PREFIX = `${MONDOO_POLICY_API_URL}/policies/platform-vulnerability`;
export const IGNORE_LABELS = [
  "mondoo.com/integration-mrn",
  "mondoo.com/agent-mrn",
];
