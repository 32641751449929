import { NavDrawerSpacesIcon } from "~/components/icons";
import { Typography } from "@mui/material";
import { useViewer } from "~/providers/viewer";
import { Org } from "~/lib/types";

export const organizationSpacesEmptyStateContent = () => {
  const { currentOrg } = useViewer();
  const org: Org | undefined | null = currentOrg();

  return {
    icon: <NavDrawerSpacesIcon sx={{ fontSize: 38, color: "primary.main" }} />,
    headline: "There are no spaces in this organization",
    textContent: (
      <Typography color="text.secondary" sx={{ textAlign: "center" }}>
        Create one or more spaces to hold and organize your assets, manage
        policies, and control access.
      </Typography>
    ),
    actionButtons: [
      {
        text: "Create a new space",
        to: `/organization/spaces/new?organizationId=${org?.id}`,
      },
    ],
  };
};
