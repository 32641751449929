import {
  AssetSoftwarePackagesType,
  SoftwareEmptyStateVariants,
} from "~/pages/inventory/components/AssetSoftware/types";

type UseEmptyStateParams = {
  packagesConnection: AssetSoftwarePackagesType | null | undefined;
  isFilterApplied: boolean;
  isPlatformSupported: boolean;
  isVulnerabilityScanningEnabled: boolean;
  isNotFound: boolean;
};

type UseEmptyStateReturn = {
  isEmptyState: boolean;
  emptyStateVariant: SoftwareEmptyStateVariants | null;
};

export function useSoftwareEmptyState({
  packagesConnection,
  isFilterApplied,
  isPlatformSupported,
  isVulnerabilityScanningEnabled,
  isNotFound,
}: UseEmptyStateParams): UseEmptyStateReturn {
  if (isNotFound) {
    return {
      isEmptyState: true,
      emptyStateVariant: "notFoundError",
    };
  }

  if (!isPlatformSupported) {
    return {
      isEmptyState: true,
      emptyStateVariant: "platformNotSupported",
    };
  }

  if (!isVulnerabilityScanningEnabled) {
    return {
      isEmptyState: true,
      emptyStateVariant: "scanningDisabled",
    };
  }

  if (isFilterApplied && packagesConnection?.edges?.length === 0) {
    return {
      isEmptyState: true,
      emptyStateVariant: "noVulnerablePackagesWithAppliedFilters",
    };
  }

  if ((packagesConnection?.totalCount || 0) === 0)
    return {
      isEmptyState: true,
      emptyStateVariant: "noPackagesInstalled",
    };

  //TODO - Revisit this logic, not as easy to facilitate with findings api
  // if ((packagesConnection?.stats?.vulnerablePackages || 0) === 0)
  //   return {
  //     isEmptyState: true,
  //     emptyStateVariant: "noVulnerablePackages",
  //   };

  return {
    emptyStateVariant: null,
    isEmptyState: false,
  };
}
