import { Controller } from "react-hook-form";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { CloseIcon, CheckIcon } from "~/components/icons";
import { LoadingButton } from "~/components/loading-button";
import { GenerateReportFormProps } from "~/pages/compliance/components/generate-report/useGenerateReportButton";

interface LineItemProps {
  text: string;
  caption?: string;
}

const LineItem = ({ text, caption }: LineItemProps) => {
  return (
    <Grid container item xs={12}>
      <Grid
        item
        xs="auto"
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ px: 1.125 }}
      >
        <CheckIcon sx={{ color: "text.secondary" }} />
      </Grid>
      <Grid
        item
        xs
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography sx={{ mb: -0.5 }}>{text}</Typography>
        {caption && (
          <Typography variant="caption" color="text.secondary">
            {caption}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

interface ExportDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  isComplianceReport?: boolean;
  form: GenerateReportFormProps;
}

export function GenerateReportDialog({
  open,
  onClose,
  title,
  isComplianceReport,
  form,
}: ExportDialogProps) {
  const theme = useTheme();

  const handleClose = () => {
    form.reset();
    onClose();
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth
      PaperProps={{
        component: "form",
        onSubmit: form.handleSubmit(form.onSubmit),
      }}
      onClose={handleClose}
    >
      <DialogTitle
        component="div"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            textTransform: "uppercase",
            fontWeight: "700",
            display: "flex",
            alignItems: "center",
          }}
        >
          {title}
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container rowGap={3}>
          <Grid item xs={12}>
            <Typography sx={{ fontWeight: "700", mb: 1 }}>
              Report name
            </Typography>
            <Controller
              name="reportName"
              control={form.control}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={form.formState.isSubmitting}
                  fullWidth
                  sx={{
                    background: theme.palette.code.background,
                    borderRadius: 1,
                    color: "text.primary",
                  }}
                />
              )}
            />
          </Grid>

          {isComplianceReport && (
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: "700", mb: 1 }}>
                REPORT AUTOMATICALLY INCLUDES
              </Typography>
              <Grid container rowGap={2}>
                <LineItem
                  text="Metadata"
                  caption="Control names, report date, compiled by name, Mondoo organization and space ID"
                />
                <LineItem text="Check summaries" />
                <LineItem text="Data query evidence" />
                <LineItem text="Exceptions" />
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "center", pb: 5 }}>
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          disabled={
            form.formState.isSubmitting ||
            !form.formState.isValid ||
            !form.watch("reportName")
          }
          loading={form.formState.isSubmitting}
          buttonText="Generate report"
        />
      </DialogActions>
    </Dialog>
  );
}
