import { RiskFactorsConfiguration } from "./RiskFactorsConfiguration";
import { SlaConfiguration } from "./SlaConfiguration";

export function SecurityModel() {
  return (
    <>
      <SlaConfiguration />
      <RiskFactorsConfiguration />
    </>
  );
}
