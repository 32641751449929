import { Typography } from "@mui/material";
import { Fragment } from "react/jsx-runtime";
import RiskFactorsContent, {
  RiskFactorsContentProps,
} from "~/components/vulnerabilities/risk-factors-content";
import { SectionHeading } from "../../components";
import { NoItemsSection } from "../components";

type RiskFactorsProps = {
  dataType: "cve" | "advisory" | "check" | "checks" | "security";
  riskFactors: RiskFactorsContentProps["riskFactors"];
  hideValues?: boolean;
};

export function RiskFactors({
  dataType,
  riskFactors,
  hideValues,
}: RiskFactorsProps) {
  const defaultContent =
    "When Mondoo evaluates the security of an asset, it considers context: Factors like defensive measures or running database servers can lower or raise the risk to your business if the asset were compromised. The list below shows the risk factors for this asset. Red indicates factors that raise the asset's risk rating. Blue indicates factors that lower the asset's risk rating.";

  const advisoryContent =
    "When Mondoo evaluates advisories, it considers context: Factors like running services or defensive measures on the asset can raise or lower the actual risk that an advisory poses. The list below shows the risk factors for this advisory. Red indicates factors that raise the asset's risk rating. Blue indicates factors that lower the asset's risk rating.";

  const checkContent =
    "When Mondoo runs checks against security policies, it considers context: Factors like running services or defensive measures on the asset can raise or lower the actual risk that a failed check poses. The list below shows the risk factors for the assets that failed this check. Red indicates factors that raise the asset's risk rating. Blue indicates factors that lower the asset's risk rating.";
  const securityContent =
    "When Mondoo evaluates security, it considers context: Factors like defensive measures or exposed credentials can lower or raise the actual risk that a misconfiguration poses. The list below shows the factors Mondoo found in this space that affect risk. Red indicates factors that raise the asset's risk rating. Blue indicates factors that lower the asset's risk rating.";

  const checksContent =
    "When Mondoo evaluates security, it considers context: Factors like defensive measures or exposed credentials can lower or raise the actual risk that a misconfiguration poses. The list below shows the factors Mondoo found in this space that affect risk. Red indicates factors that raise the asset's risk rating. Blue indicates factors that lower the asset's risk rating.";

  const getContent = () => {
    if (dataType === "advisory") {
      return advisoryContent;
    }
    if (dataType === "security") {
      return securityContent;
    }

    if (dataType === "check") {
      return checkContent;
    }

    if (dataType === "checks") {
      return checksContent;
    }

    return defaultContent;
  };

  const content = getContent();
  return (
    <Fragment>
      <SectionHeading heading="Risk factors" />
      {!riskFactors ? (
        <NoItemsSection target="Risk Factors" />
      ) : (
        <Fragment>
          <Typography sx={{ mb: 2 }}>{content}</Typography>
          <RiskFactorsContent
            riskFactors={riskFactors}
            hideValues={hideValues}
            twoColumn
          />
        </Fragment>
      )}
    </Fragment>
  );
}
