import { Dispatch, Fragment, SetStateAction, useState } from "react";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { EmptyState } from "~/components/empty-state/empty-state";
import {
  Box,
  Grid,
  Paper,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { FilterBar } from "~/pages/compliance/filter-bar";
import { ComplianceTable, Header } from "~/pages/compliance/compliance-table";
import { INITIAL_PAGE_RANGE, PaginationRange } from "~/components/pagination";
import { QueryAssetScore } from "~/operations";
import { Space } from "~/lib/types";
import { ControlResultIcon } from "~/components/ui-library";
import { ViewSwitcher, ViewType } from "~/components/view-switcher";

type Props = {
  space: Space;
};

export function Assets({ space }: Props) {
  const [pageItems, setPageItems] = useState(INITIAL_PAGE_RANGE);
  const [searchParams] = useSearchParams();
  const selectedView = searchParams.get("view") || "list";
  let assets = demoAssets.map((edge) => edge.node) as QueryAssetScore[];

  return (
    <Box>
      <Grid container rowGap={3}>
        {!assets || assets?.length === 0 ? (
          <Grid item xs={12}>
            <EmptyState contentType="control-assets" />
          </Grid>
        ) : (
          <Fragment>
            <Grid item xs={12}>
              <FilterBar
                searchId={""}
                placeholder={""}
                searchFilters={[]}
                handleFilterQuery={() => {}}
                mb={0}
              />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "right" }}>
              <ViewSwitcher
                views={[ViewType.List, ViewType.Keyboard]}
                defaultView={ViewType.List}
              />
            </Grid>
            {selectedView === "list" && (
              <Grid item xs={12}>
                <DataQueryListView
                  assets={assets}
                  pageItems={pageItems}
                  setPageItems={setPageItems}
                />
              </Grid>
            )}
            {selectedView === "keyboard" && (
              <DataQueryGridView assets={assets} space={space} />
            )}
          </Fragment>
        )}
      </Grid>
    </Box>
  );
}

const tableHeaders: Header[] = [
  {
    id: "NAME",
    label: "Name",
  },
  {
    id: "PLATFORM",
    label: "Platform",
  },
];

// TODO: switch these to the new shared components built in Asset -> Data Queries

type DataQueryListViewProps = {
  assets: QueryAssetScore[];
  pageItems: PaginationRange;
  setPageItems: Dispatch<SetStateAction<PaginationRange>>;
};

function DataQueryListView({
  assets,
  pageItems,
  setPageItems,
}: DataQueryListViewProps) {
  return (
    <ComplianceTable
      tableHeaders={tableHeaders}
      defaultActiveColumn={"QUERY"}
      paginationProps={{
        setPageItems,
        totalCount: assets.length,
      }}
    >
      {assets.slice(pageItems.from, pageItems.to).map((asset, i) => {
        return (
          <TableRow
            key={`${asset?.assetMrn}-${i}`}
            component={RouterLink}
            to={"#"}
          >
            <TableCell>{asset?.assetName}</TableCell>
            <TableCell>{asset?.assetPlatform?.name}</TableCell>
          </TableRow>
        );
      })}
    </ComplianceTable>
  );
}

type DataQueryGridViewProps = { assets: QueryAssetScore[]; space: Space };

function DataQueryGridView({ assets, space }: DataQueryGridViewProps) {
  return (
    <Grid container item spacing={3}>
      {assets.map((asset) => {
        const assetId = asset?.assetMrn?.split("/").pop();
        return (
          <Grid item xs={12} md={6} lg={4} key={asset?.assetMrn}>
            <Paper
              component={RouterLink}
              to={`#`}
              //   to={`/space/inventory/${assetId}/overview?spaceId=${space.id}`}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 2,
              }}
            >
              <Box sx={{ width: 1 }}>
                <Typography
                  sx={{
                    display: "block",
                    fontWeight: 700,
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    width: { xs: 200, md: 300, lg: 240 },
                  }}
                >
                  {asset?.assetName}
                </Typography>
                <Typography sx={{ fontSize: 12, color: "text.secondary" }}>
                  {asset?.assetPlatform?.name} {asset?.assetPlatform?.version}
                </Typography>
              </Box>
              <Box>
                <ControlResultIcon status={"data"} size="lg" />
              </Box>
            </Paper>
          </Grid>
        );
      })}
    </Grid>
  );
}

const demoAssets = [
  {
    cursor:
      "v0:Ly9hc3NldHMuYXBpLm1vbmRvby5hcHAvc3BhY2VzL3Rlc3QtaW5mYWxsaWJsZS10YXVzc2lnLTc5NjU5Ni9hc3NldHMvMlZaeUFpS0ZhemdkSFF2bDFNcW5aNUhLTXJO:Ly9hc3NldHMuYXBpLm1vbmRvby5hcHAvc3BhY2VzL3Rlc3QtaW5mYWxsaWJsZS10YXVzc2lnLTc5NjU5Ni9hc3NldHMvMlZaeUFpS0ZhemdkSFF2bDFNcW5aNUhLTXJO",
    node: {
      assetMrn:
        "//assets.api.mondoo.app/spaces/test-infallible-taussig-796596/assets/2VZyAiKFazgdHQvl1MqnZ5HKMrN",
      assetName: "mondoohq/cnquery-packs",
      assetPlatform: {
        name: "github-repo",
        title: "GitHub Repository",
        arch: "",
        version: "",
        kind: "API",
        runtime: "github",
        __typename: "Platform",
      },
      score: {
        id: "//policy.api.mondoo.app/queries/github-benchmark--code-owners-set-extra-sensitive-code-configuration",
        value: 0,
        type: 2,
        completion: 100,
        weight: 1,
        message: "",
        grade: "F",
        __typename: "Score",
      },
      __typename: "QueryAssetScore",
    },
    __typename: "MqueryAssetScoresEdge",
  },
  {
    cursor:
      "v0:Ly9hc3NldHMuYXBpLm1vbmRvby5hcHAvc3BhY2VzL3Rlc3QtaW5mYWxsaWJsZS10YXVzc2lnLTc5NjU5Ni9hc3NldHMvMlZaeUFpS0ZhemdkSFF2bDFNcW5aNUhLTXJO:Ly9hc3NldHMuYXBpLm1vbmRvby5hcHAvc3BhY2VzL3Rlc3QtaW5mYWxsaWJsZS10YXVzc2lnLTc5NjU5Ni9hc3NldHMvMlZaeUFpS0ZhemdkSFF2bDFNcW5aNUhLTXJN",
    node: {
      assetMrn:
        "//assets.api.mondoo.app/spaces/test-infallible-taussig-796596/assets/2VZyAiKFazgdHQvl1MqnZ5HKMnN",
      assetName: "mondoohq/cnquery-packs",
      assetPlatform: {
        name: "github-repo",
        title: "GitHub Repository",
        arch: "",
        version: "",
        kind: "API",
        runtime: "github",
        __typename: "Platform",
      },
      score: {
        id: "//policy.api.mondoo.app/queries/github-benchmark--code-owners-set-extra-sensitive-code-configuration",
        value: 0,
        type: 2,
        completion: 100,
        weight: 1,
        message: "",
        grade: "F",
        __typename: "Score",
      },
      __typename: "QueryAssetScore",
    },
    __typename: "MqueryAssetScoresEdge",
  },
  {
    cursor:
      "v0:Ly9hc3NldHMuYXBpLm1vbmRvby5hcHAvc3BhY2VzL3Rlc3QtaW5mYWxsaWJsZS10YXVzc2lnLTc5NjU5Ni9hc3NldHMvMlZaeUFpS0ZhemdkSFF2bDFNcW5aNUhLTXJQ",
    node: {
      assetMrn:
        "//assets.api.mondoo.app/spaces/test-infallible-taussig-796596/assets/2VZyAiKFazgdHQvl1MqnZ5HKMnN",
      assetName: "mondoohq/cnquery-packs",
      assetPlatform: {
        name: "github-repo",
        title: "GitHub Repository",
        arch: "",
        version: "",
        kind: "API",
        runtime: "github",
        __typename: "Platform",
      },
      score: {
        id: "//policy.api.mondoo.app/queries/github-benchmark--code-owners-set-extra-sensitive-code-configuration",
        value: 0,
        type: 2,
        completion: 100,
        weight: 1,
        message: "",
        grade: "F",
        __typename: "Score",
      },
      __typename: "QueryAssetScore",
    },
    __typename: "MqueryAssetScoresEdge",
  },
  {
    cursor:
      "v0:Ly9hc3NldHMuYXBpLm1vbmRvby5hcHAvc3BhY2VzL3Rlc3QtaW5mYWxsaWJsZS10YXVzc2lnLTc5NjU5Ni9hc3NldHMvMlZaeUFpS0ZhemdkSFF2bDFNcW5aNUhLTXJQ",
    node: {
      assetMrn:
        "//assets.api.mondoo.app/spaces/test-infallible-taussig-796596/assets/2VZyAiKFazgdHQvl1MqnZ5HKMnN",
      assetName: "mondoohq/cnquery-packs",
      assetPlatform: {
        name: "github-repo",
        title: "GitHub Repository",
        arch: "",
        version: "",
        kind: "API",
        runtime: "github",
        __typename: "Platform",
      },
      score: {
        id: "//policy.api.mondoo.app/queries/github-benchmark--code-owners-set-extra-sensitive-code-configuration",
        value: 0,
        type: 2,
        completion: 100,
        weight: 1,
        message: "",
        grade: "F",
        __typename: "Score",
      },
      __typename: "QueryAssetScore",
    },
    __typename: "MqueryAssetScoresEdge",
  },
  {
    cursor:
      "v0:Ly9hc3NldHMuYXBpLm1vbmRvby5hcHAvc3BhY2VzL3Rlc3QtaW5mYWxsaWJsZS10YXVzc2lnLTc5NjU5Ni9hc3NldHMvMlZaeUFpS0ZhemdkSFF2bDFNcW5aNUhLTXJR",
    node: {
      assetMrn:
        "//assets.api.mondoo.app/spaces/test-infallible-taussig-796596/assets/2VZyAiKFazgdHQvl1MqnZ5HKMnN",
      assetName: "mondoohq/cnquery-packs",
      assetPlatform: {
        name: "github-repo",
        title: "GitHub Repository",
        arch: "",
        version: "",
        kind: "API",
        runtime: "github",
        __typename: "Platform",
      },
      score: {
        id: "//policy.api.mondoo.app/queries/github-benchmark--code-owners-set-extra-sensitive-code-configuration",
        value: 0,
        type: 2,
        completion: 100,
        weight: 1,
        message: "",
        grade: "F",
        __typename: "Score",
      },
      __typename: "QueryAssetScore",
    },
    __typename: "MqueryAssetScoresEdge",
  },
  {
    cursor:
      "v0:Ly9hc3NldHMuYXBpLm1vbmRvby5hcHAvc3BhY2VzL3Rlc3QtaW5mYWxsaWJsZS10YXVzc2lnLTc5NjU5Ni9hc3NldHMvMlZaeUFpS0ZhemdkSFF2bDFNcW5aNUhLTXJS",
    node: {
      assetMrn:
        "//assets.api.mondoo.app/spaces/test-infallible-taussig-796596/assets/2VZyAiKFazgdHQvl1MqnZ5HKMnN",
      assetName: "mondoohq/cnquery-packs",
      assetPlatform: {
        name: "github-repo",
        title: "GitHub Repository",
        arch: "",
        version: "",
        kind: "API",
        runtime: "github",
        __typename: "Platform",
      },
      score: {
        id: "//policy.api.mondoo.app/queries/github-benchmark--code-owners-set-extra-sensitive-code-configuration",
        value: 0,
        type: 2,
        completion: 100,
        weight: 1,
        message: "",
        grade: "F",
        __typename: "Score",
      },
      __typename: "QueryAssetScore",
    },
    __typename: "MqueryAssetScoresEdge",
  },
  {
    cursor:
      "v0:Ly9hc3NldHMuYXBpLm1vbmRvby5hcHAvc3BhY2VzL3Rlc3QtaW5mYWxsaWJsZS10YXVzc2lnLTc5NjU5Ni9hc3NldHMvMlZaeUFpS0ZhemdkSFF2bDFNcW5aNUhLTXJT",
    node: {
      assetMrn:
        "//assets.api.mondoo.app/spaces/test-infallible-taussig-796596/assets/2VZyAiKFazgdHQvl1MqnZ5HKMnN",
      assetName: "mondoohq/cnquery-packs",
      assetPlatform: {
        name: "github-repo",
        title: "GitHub Repository",
        arch: "",
        version: "",
        kind: "API",
        runtime: "github",
        __typename: "Platform",
      },
      score: {
        id: "//policy.api.mondoo.app/queries/github-benchmark--code-owners-set-extra-sensitive-code-configuration",
        value: 0,
        type: 2,
        completion: 100,
        weight: 1,
        message: "",
        grade: "F",
        __typename: "Score",
      },
      __typename: "QueryAssetScore",
    },
    __typename: "MqueryAssetScoresEdge",
  },
  {
    cursor:
      "v0:Ly9hc3NldHMuYXBpLm1vbmRvby5hcHAvc3BhY2VzL3Rlc3QtaW5mYWxsaWJsZS10YXVzc2lnLTc5NjU5Ni9hc3NldHMvMlZaeUFpS0ZhemdkSFF2bDFNcW5aNUhLTXJU",
    node: {
      assetMrn:
        "//assets.api.mondoo.app/spaces/test-infallible-taussig-796596/assets/2VZyAiKFazgdHQvl1MqnZ5HKMnN",
      assetName: "mondoohq/cnquery-packs",
      assetPlatform: {
        name: "github-repo",
        title: "GitHub Repository",
        arch: "",
        version: "",
        kind: "API",
        runtime: "github",
        __typename: "Platform",
      },
      score: {
        id: "//policy.api.mondoo.app/queries/github-benchmark--code-owners-set-extra-sensitive-code-configuration",
        value: 0,
        type: 2,
        completion: 100,
        weight: 1,
        message: "",
        grade: "F",
        __typename: "Score",
      },
      __typename: "QueryAssetScore",
    },
    __typename: "MqueryAssetScoresEdge",
  },
  {
    cursor:
      "v0:Ly9hc3NldHMuYXBpLm1vbmRvby5hcHAvc3BhY2VzL3Rlc3QtaW5mYWxsaWJsZS10YXVzc2lnLTc5NjU5Ni9hc3NldHMvMlZaeUFpS0ZhemdkSFF2bDFNcW5aNUhLTXJV",
    node: {
      assetMrn:
        "//assets.api.mondoo.app/spaces/test-infallible-taussig-796596/assets/2VZyAiKFazgdHQvl1MqnZ5HKMnN",
      assetName: "mondoohq/cnquery-packs",
      assetPlatform: {
        name: "github-repo",
        title: "GitHub Repository",
        arch: "",
        version: "",
        kind: "API",
        runtime: "github",
        __typename: "Platform",
      },
      score: {
        id: "//policy.api.mondoo.app/queries/github-benchmark--code-owners-set-extra-sensitive-code-configuration",
        value: 0,
        type: 2,
        completion: 100,
        weight: 1,
        message: "",
        grade: "F",
        __typename: "Score",
      },
      __typename: "QueryAssetScore",
    },
    __typename: "MqueryAssetScoresEdge",
  },
  {
    cursor:
      "v0:Ly9hc3NldHMuYXBpLm1vbmRvby5hcHAvc3BhY2VzL3Rlc3QtaW5mYWxsaWJsZS10YXVzc2lnLTc5NjU5Ni9hc3NldHMvMlZaeUFpS0ZhemdkSFF2bDFNcW5aNUhLTXJW",
    node: {
      assetMrn:
        "//assets.api.mondoo.app/spaces/test-infallible-taussig-796596/assets/2VZyAiKFazgdHQvl1MqnZ5HKMnN",
      assetName: "mondoohq/cnquery-packs",
      assetPlatform: {
        name: "github-repo",
        title: "GitHub Repository",
        arch: "",
        version: "",
        kind: "API",
        runtime: "github",
        __typename: "Platform",
      },
      score: {
        id: "//policy.api.mondoo.app/queries/github-benchmark--code-owners-set-extra-sensitive-code-configuration",
        value: 0,
        type: 2,
        completion: 100,
        weight: 1,
        message: "",
        grade: "F",
        __typename: "Score",
      },
      __typename: "QueryAssetScore",
    },
    __typename: "MqueryAssetScoresEdge",
  },
  {
    cursor:
      "v0:Ly9hc3NldHMuYXBpLm1vbmRvby5hcHAvc3BhY2VzL3Rlc3QtaW5mYWxsaWJsZS10YXVzc2lnLTc5NjU5Ni9hc3NldHMvMlZaeUFpS0ZhemdkSFF2bDFNcW5aNUhLTXJX",
    node: {
      assetMrn:
        "//assets.api.mondoo.app/spaces/test-infallible-taussig-796596/assets/2VZyAiKFazgdHQvl1MqnZ5HKMnN",
      assetName: "mondoohq/cnquery-packs",
      assetPlatform: {
        name: "github-repo",
        title: "GitHub Repository",
        arch: "",
        version: "",
        kind: "API",
        runtime: "github",
        __typename: "Platform",
      },
      score: {
        id: "//policy.api.mondoo.app/queries/github-benchmark--code-owners-set-extra-sensitive-code-configuration",
        value: 0,
        type: 2,
        completion: 100,
        weight: 1,
        message: "",
        grade: "F",
        __typename: "Score",
      },
      __typename: "QueryAssetScore",
    },
    __typename: "MqueryAssetScoresEdge",
  },
  {
    cursor:
      "v0:Ly9hc3NldHMuYXBpLm1vbmRvby5hcHAvc3BhY2VzL3Rlc3QtaW5mYWxsaWJsZS10YXVzc2lnLTc5NjU5Ni9hc3NldHMvMlZaeUFpS0ZhemdkSFF2bDFNcW5aNUhLTXJY",
    node: {
      assetMrn:
        "//assets.api.mondoo.app/spaces/test-infallible-taussig-796596/assets/2VZyAiKFazgdHQvl1MqnZ5HKMnN",
      assetName: "mondoohq/cnquery-packs",
      assetPlatform: {
        name: "github-repo",
        title: "GitHub Repository",
        arch: "",
        version: "",
        kind: "API",
        runtime: "github",
        __typename: "Platform",
      },
      score: {
        id: "//policy.api.mondoo.app/queries/github-benchmark--code-owners-set-extra-sensitive-code-configuration",
        value: 0,
        type: 2,
        completion: 100,
        weight: 1,
        message: "",
        grade: "F",
        __typename: "Score",
      },
      __typename: "QueryAssetScore",
    },
    __typename: "MqueryAssetScoresEdge",
  },
];
