import { useFindingOnAsset } from "../../hooks";
import { ExceptionsModals } from "~/components/exceptions/exceptions-modals";
import { TriageBar } from "~/components/TriageBar/TriageBar";
import { CreateCasesIntegrationModal } from "~/components/cases/components/CreateCasesIntegrationModal";
import { CreateCaseModal } from "~/components/cases/components/CreateCaseModal";
import { ScopeType } from "~/hooks/useScope";
import {
  getFindingExceptionTarget,
  getFindingSubheaders,
  getFindingTitle,
} from "~/pages/findings/FindingOnAsset/utils";

export function InfoBar() {
  const {
    finding,
    exceptionActions,
    ticketActions,
    linkBack,
    linkToException,
    hasTicketingIntegration,
    activeScope,
    spaceScope,
    searchParams,
  } = useFindingOnAsset();

  if (
    (activeScope?.type !== ScopeType.Workspace &&
      activeScope?.type !== ScopeType.Space) ||
    !spaceScope
  ) {
    // We only want to render this component for workspace or space scopes.
    return null;
  }

  return (
    <>
      <TriageBar
        {...{
          title: getFindingTitle({ finding }),
          linkBack,
          subheaders: getFindingSubheaders({ finding }),
          triage: true,
          isLoading: exceptionActions.loading,
          availableRemediations: {
            exception: {
              active: true,
              hasException: !!finding?.exception,
              linkToException,
              onClick: !!finding?.exception
                ? exceptionActions.handleRemoveException
                : exceptionActions.handleSetExceptionModalOpen,
              disabled: exceptionActions.loading,
            },
            ticket: {
              active: true,
              onClick: hasTicketingIntegration
                ? ticketActions.handleCreateCaseModalOpen
                : ticketActions.handleCreateCaseIntegrationModalOpen,
            },
            snooze: { active: false },
          },
        }}
      />
      <ExceptionsModals
        isSetExceptionModalOpen={exceptionActions.isSettingException}
        isRemoveExceptionModalOpen={exceptionActions.isRemovingException}
        onRemoveExceptionModalClose={
          exceptionActions.handleRemoveExceptionModalClose
        }
        onSetExceptionModalClose={exceptionActions.handleSetExceptionModalClose}
        onSetExceptionModalSave={exceptionActions.handleSetException}
        onRemoveExceptionModalSave={exceptionActions.handleRemoveException}
        loading={exceptionActions.loading}
        target={getFindingExceptionTarget(finding)}
        role="asset"
        exceptionGroups={exceptionActions.exceptionGroups}
        selectedEntities={[
          {
            mrn: finding?.mrn || "",
            groupId: "",
            exception: finding?.exception,
          },
        ]}
      />
      <CreateCasesIntegrationModal
        open={ticketActions.isCreateCaseIntegrationModalOpen}
        onClose={ticketActions.handleCreateCaseIntegrationModalClose}
        space={spaceScope}
        redirectTo={`${location.pathname}?${searchParams}`}
        hasIntegrationCreatePermission={
          ticketActions.hasIntegrationCreatePermission
        }
      />
      <CreateCaseModal
        open={ticketActions.isCreateCaseModalOpen}
        target="asset"
        loading={ticketActions.loading}
        onClose={() => {
          ticketActions.handleCreateCaseModalClose();
        }}
        onSave={ticketActions.handleCreateCase}
        selectedAssets={[
          {
            mrn: finding?.mrn || "",
            scopeMrn: spaceScope?.mrn,
            groupId: "",
          },
        ]}
        space={spaceScope}
        scope={activeScope}
        hasCreateCasePermissions={ticketActions.hasCreateCasePermissions}
      />
    </>
  );
}
