import React from "react";
import { AutocompleteProps, Box, Grid } from "@mui/material";
import { SoftwareTypeGroup } from "~/pages/inventory/components/AssetSoftware/SoftwareTypeGroup/AssetSoftwareTypeGroup";
import { FilterBar } from "~/pages/compliance/filter-bar";
import {
  CopyTableToClipboard,
  CopyTableToClipboardProps,
} from "~/components/copy-table-to-clipboard";
import { PackageTypes } from "~/pages/inventory/components/AssetSoftware/types";
import { FindingType, PackageType } from "~/operations";

type AssetSoftwareFilterSection = {
  displayedFilterTypes: Array<{
    id: FindingType;
    title: string;
    count: string;
  }>;
  filter: FindingType;
  handleFilter: (type: FindingType) => void;
  searchFilters: string[];
  handleFilterQuery: AutocompleteProps<string, true, false, true>["onChange"];
  showCopyToClipboard: boolean;
  tableData: CopyTableToClipboardProps["tableData"];
};

export const AssetSoftwareFilterSection = ({
  displayedFilterTypes,
  filter,
  handleFilter,
  searchFilters,
  handleFilterQuery,
  showCopyToClipboard,
  tableData,
}: AssetSoftwareFilterSection) => {
  return (
    <>
      <Grid container spacing={2} mt={5} mb={5}>
        {displayedFilterTypes.map((filterType) => (
          <Grid key={filterType.id} item xs={12} sm={6} md={4}>
            <SoftwareTypeGroup
              active={filter === filterType.id}
              id={filterType.id}
              title={filterType.title}
              count={filterType.count}
              onClick={handleFilter}
            />
          </Grid>
        ))}
      </Grid>
      <Box display="flex" gap={2} mt={3}>
        <Box flex="1">
          <FilterBar
            mb={0}
            searchId="software"
            placeholder="vm_software"
            searchFilters={searchFilters}
            handleFilterQuery={handleFilterQuery}
          />
        </Box>
        {showCopyToClipboard && (
          <CopyTableToClipboard tableData={tableData} sx={{ mt: "10px" }} />
        )}
      </Box>
    </>
  );
};
