import { Fragment } from "react/jsx-runtime";
import { SectionHeading } from "../../components";
import { Grid2, TableRow } from "@mui/material";
import { NoItemsSection } from "../components";
import { GenericTable } from "~/components/GenericTable/GenericTable";
import {
  ImpactCell,
  PublishedCell,
  RiskFactorsCell,
  TitleCell,
} from "~/components/Cells";
import { UseCveOrAdvisoryReportReturnObject } from "./hooks/useCveOrAdvisoryReport";
import { ScoreRating } from "~/operations";
import { DynamicIcon } from "~/components/icons/DynamicIcon";

type AffectedAssetsProps = {
  affectedAssets: UseCveOrAdvisoryReportReturnObject["data"]["affectedAssets"];
};

export function AffectedAssets({ affectedAssets }: AffectedAssetsProps) {
  return (
    <Fragment>
      <SectionHeading heading="Exposed assets" />
      <Grid2 size={12} sx={{ my: 3 }}>
        {!affectedAssets ? (
          <NoItemsSection target="assets" />
        ) : (
          <GenericTable
            items={0}
            tableName="ADVISORY_REPORT_AFFECTED_ASSETS"
            validSortFields={[]}
            printView
          >
            {affectedAssets?.map((asset) => {
              return (
                <TableRow key={asset?.id}>
                  <ImpactCell
                    impact={asset?.score || 0}
                    rating={asset?.rating || ScoreRating.Critical}
                    isActive
                    hideTooltip
                  />
                  <TitleCell
                    title={asset?.title || ""}
                    icon={<DynamicIcon iconId={asset?.iconId} />}
                    tags={asset?.tags}
                    options={{ fontSize: 14 }}
                  />
                  <RiskFactorsCell riskFactors={asset?.riskFactors} printView />
                  <PublishedCell
                    date={asset?.lastUpdated || "Never"}
                    formatting="absolute"
                  />
                </TableRow>
              );
            })}
          </GenericTable>
        )}
      </Grid2>
    </Fragment>
  );
}
