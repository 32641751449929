import { Fragment, useState } from "react";
import { useColorMode } from "~/providers/color-mode";
import { useViewer } from "~/providers/viewer";
import { LoadViewerDocument, useUpdateUserNameMutation } from "~/operations";
import {
  ConfigurationPaper,
  ConfigurationList,
  ConfigurationItemText,
  ConfigurationItemSwitch,
  ConfigurationItemTextProps,
  ConfigurationItemSwitchProps,
  ConfigurationITemButtonSwitch,
} from "./configuration-items";
import { PaletteMode } from "@mui/material";
import { enqueueSnackbar } from "notistack";

export function UserNameSection() {
  const { viewer, updateViewerSettings } = useViewer();
  const { colorBlind, mode } = useColorMode();
  const [name, setName] = useState<string>(viewer?.name || "");
  const [storeNameError, setStoreNameError] = useState<string>("");
  const [isUsernameDirty, setIsUsernameDirty] = useState(false);
  const [updateUserName] = useUpdateUserNameMutation();

  const handleChangeName: ConfigurationItemTextProps["onChange"] = (event) => {
    event.preventDefault();
    const newValue = event.target.value;
    setName(newValue);
    setIsUsernameDirty(newValue !== viewer?.name);
  };

  const onUsernameBlur = () => {
    if (isUsernameDirty) {
      saveUsername().then();
    }
  };

  const saveUsername = async () => {
    try {
      await updateUserName({
        variables: { input: { name } },
        refetchQueries: [LoadViewerDocument],
      });

      setStoreNameError("");
      setIsUsernameDirty(false);
      enqueueSnackbar("Username saved successfully!", { variant: "success" });
    } catch (error) {
      const errorMsg = "Failed to save user name, please try again!";
      enqueueSnackbar(errorMsg, { variant: "error" });
      console.error(error);
      setStoreNameError(errorMsg);
    }
  };

  const handleSetColorBlind: ConfigurationItemSwitchProps["onChange"] = (
    _event,
    value,
  ) => {
    try {
      updateViewerSettings({
        variables: { key: "colorblind", value: value.toString() },
        refetchQueries: [LoadViewerDocument],
      }).then();
    } catch (error) {
      enqueueSnackbar("Failed to save color blind setting, please try again!", {
        variant: "error",
      });
      console.error(error);
    }
  };

  const handleColorModeChange = (_: unknown, newMode: PaletteMode) => {
    try {
      updateViewerSettings({
        variables: { key: "colormode", value: newMode },
        refetchQueries: [LoadViewerDocument],
      }).then();
    } catch (error) {
      enqueueSnackbar("Failed to save color mode setting, please try again!", {
        variant: "error",
      });
      console.error(error);
    }
  };

  return (
    <Fragment>
      <ConfigurationPaper>
        <ConfigurationList title="General">
          <ConfigurationItemText
            onBlur={onUsernameBlur}
            primary="Name"
            secondary="Your full name"
            label="Name"
            value={name}
            onChange={handleChangeName}
            error={storeNameError.length !== 0}
            helperText={storeNameError}
          />

          <ConfigurationItemText
            disabled={true}
            primary="Email"
            secondary="Email used for login and notifications"
            label="Email"
            value={viewer?.email || ""}
          />
        </ConfigurationList>
      </ConfigurationPaper>

      <ConfigurationPaper>
        <ConfigurationList title="Display">
          <ConfigurationITemButtonSwitch
            mode={mode}
            primary="Color mode"
            secondary="Select light or dark mode"
            onChange={handleColorModeChange}
          />

          <ConfigurationItemSwitch
            primary="Colorblind Mode"
            secondary="Modifies colors for easy viewing for colorblind users."
            checked={Boolean(colorBlind === "true")}
            action={colorBlind ? "On" : "Off"}
            onChange={handleSetColorBlind}
          />
        </ConfigurationList>
      </ConfigurationPaper>
    </Fragment>
  );
}
