import {
  FindingsOrderField,
  FindingType,
  OrderDirection,
  ScoreState,
  useGetChecksQuery,
} from "~/operations";
import { Asset } from "../types";

type UsePoliciesProps = { asset: Asset; policyMrn: string };

export function usePolicies({ asset, policyMrn }: UsePoliciesProps) {
  const { data, error } = useGetChecksQuery({
    variables: {
      scopeMrn: asset.mrn,
      after: null,
      orderBy: {
        field: FindingsOrderField.RiskValue,
        direction: OrderDirection.Desc,
      },
      filter: {
        types: [FindingType.Check],
        policyMrn,
      },
    },
  });

  const findingsUnion =
    data?.findings?.__typename === "FindingsConnection"
      ? data.findings
      : undefined;
  const checkEdges = findingsUnion?.edges || [];
  const checkFindings = checkEdges.flatMap((e) =>
    e?.node?.__typename === "CheckFinding" ? e.node : [],
  );

  //split the edges into two groups, those that are passing and those that are failing
  const { passingChecks, failingChecks } = checkFindings?.reduce<{
    passingChecks: typeof checkFindings;
    failingChecks: typeof checkFindings;
  }>(
    (acc, edge) => {
      if (edge?.state === ScoreState.Open) {
        acc.failingChecks.push(edge);
      } else if (edge?.state === ScoreState.Closed) {
        acc.passingChecks.push(edge);
      }
      return acc;
    },
    { passingChecks: [], failingChecks: [] },
  ) || { passingChecks: [], failingChecks: [] };

  return { passingChecks, failingChecks };
}
