import { Link, Route, Routes } from "react-router-dom";
import { AggregateScoreType, TestIamActionsQuery } from "~/operations";
import { FindingsPage } from "~/pages/findings";
import { FirewatchPage } from "~/components/FirewatchPage";
import { Check } from "~/pages/space/security/components/Check";
import { PolicyChecks } from "~/components/policy-gql/PolicyChecks";
import { PolicyPage } from "~/pages/policy";
import { EnablePolicies } from "~/pages/security/policies/enable-policies";
import { PoliciesAddDetailPage } from "~/pages/security/policies/policies-add-detail";
import { PoliciesAddQueryPage } from "~/pages/security/policies/policies-add-query";
import { PolicyAddDetailChecksPage } from "~/pages/security/policies/policy-add-detail-checks";
import { PolicyAddDetailPropertiesPage } from "~/pages/security/policies/policy-add-detail-properties";
import { PolicyDetailPropertiesPage } from "~/pages/security/policies/policy-detail-properties";
import { SpaceOrWorkspaceScope, SpaceScope } from "~/hooks/useScope";
import { SecurityPolicyAssetsTab } from "~/pages/space/vulnerabilities/components/AffectedAssets/SecurityPolicyAssetsTab";

import { Policies } from "~/pages/security/policies/policies";
import { ExceptionsWrapper } from "~/components/policy-gql/exceptions";
import { PageLayout } from "../page-layout";

export type VulnerabilitiesRoutesProps = {
  scope: SpaceOrWorkspaceScope;
  spaceScope: SpaceScope;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function FindingsRoutes({
  scope,
  spaceScope,
  availablePermissions,
}: VulnerabilitiesRoutesProps) {
  return (
    <Routes>
      <Route
        element={<FindingsPage availablePermissions={availablePermissions} />}
      >
        <Route element={<PageLayout />}>
          <Route
            path="all"
            element={
              <FirewatchPage
                {...{
                  space: spaceScope,
                  scope,
                  pageType: "AllSecurityFindings",
                }}
              />
            }
          />

          <Route
            path="cves"
            element={
              <FirewatchPage
                {...{
                  space: spaceScope,
                  scope,
                  pageType: AggregateScoreType.Vulnerability,
                }}
              />
            }
          />

          <Route
            path="advisories"
            element={
              <FirewatchPage
                {...{
                  space: spaceScope,
                  scope,
                  pageType: AggregateScoreType.Advisory,
                }}
              />
            }
          />
          <Route path="policies">
            <Route
              index
              element={
                <Policies
                  {...{ space: spaceScope, scope, availablePermissions }}
                />
              }
            />
            <Route path="add">
              <Route
                index
                element={
                  <EnablePolicies
                    {...{ space: spaceScope, availablePermissions }}
                  />
                }
              />
              <Route
                path=":policyId"
                element={
                  <PoliciesAddDetailPage
                    {...{ space: spaceScope, availablePermissions }}
                  />
                }
              >
                <Route index element={<PolicyAddDetailChecksPage />} />
                <Route
                  path={"checks"}
                  element={<PolicyAddDetailChecksPage />}
                />
                <Route
                  path={"properties"}
                  element={<PolicyAddDetailPropertiesPage />}
                />
              </Route>
              <Route
                path=":policyId/queries/:queryId"
                element={
                  <PoliciesAddQueryPage
                    {...{ space: spaceScope, availablePermissions }}
                  />
                }
              />
            </Route>
            <Route
              path=":policyId"
              element={
                <PolicyPage
                  space={spaceScope}
                  scope={scope}
                  query={window.location.search}
                  availablePermissions={availablePermissions}
                />
              }
            >
              <Route index element={<PolicyChecks />} />
              <Route path="checks" element={<PolicyChecks />} />
              <Route
                path="assets"
                element={<SecurityPolicyAssetsTab scope={scope} />}
              />
              <Route path="exceptions" element={<ExceptionsWrapper />} />
              <Route
                path="properties"
                element={<PolicyDetailPropertiesPage />}
              />
            </Route>
          </Route>
          <Route path="checks">
            <Route
              index
              element={
                <FirewatchPage
                  {...{
                    space: spaceScope,
                    scope,
                    pageType: AggregateScoreType.Check,
                  }}
                />
              }
            />
          </Route>
        </Route>
        <Route
          path="check"
          element={
            <Check
              availablePermissions={availablePermissions}
              {...{ space: spaceScope, scope }}
            />
          }
          handle={{
            crumb: () => <Link to="/Check">Check</Link>,
          }}
        />
      </Route>
    </Routes>
  );
}
