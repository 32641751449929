import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { Space } from "~/lib/types";
import { TestIamActionsQuery } from "~/operations";
import {
  CasesBreadcrumbs,
  ClosedCases,
  CreateFirstCase,
  OpenCases,
  Title,
} from "./components";
import { CreateTicketingIntegration } from "~/components/cases/components/CasesPage/components/CreateTicketingIntegration";
import { useCasesPage } from "~/components/cases/hooks/useCasesPage";
import { ScrollToTop } from "~/lib/scroll-to-top";
import { CasesTabs } from "~/components/cases/components/CasesPage/components/CasesTabs/CasesTabs";
import { CASES_TABS } from "~/components/cases/constants";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";
import { useSearchParams } from "react-router-dom";
import { ASSET_ID_PARAM, getAssetMrn } from "~/utils/urlUtils";
import { KeyValueChip } from "~/components/asset-search";
import { useLoadAssetQuery } from "~/operations";

type CasesPageProps = {
  space: Space;
  scope: SpaceOrWorkspaceScope;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export const CasesPage = ({
  space,
  scope,
  availablePermissions,
}: CasesPageProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const assetId = searchParams.get(ASSET_ID_PARAM);
  const assetMrn = assetId ? getAssetMrn(space.id, assetId) : undefined;

  const { data: assetData } = useLoadAssetQuery({
    variables: { mrn: assetMrn || "skip" },
    skip: !assetMrn,
  });

  const handleClearAssetFilter = () => {
    const params = new URLSearchParams(searchParams);
    params.delete(ASSET_ID_PARAM);
    setSearchParams(params);
  };

  const {
    hasIntegrationCreatePermission,
    hasCloseCasePermissions,
    openCases,
    openCasesDataLoading,
    closedCases,
    closedCasesDataLoading,
    showCreateFirstCaseScreen,
    showCreateTicketingIntegrationScreen,
    hasCases,
    tabList,
    currentTab,
    fetchMoreOpenCases,
    fetchMoreClosedCases,
    openCasesPageInfo,
    openCasesTotalCount,
    closedCasesPageInfo,
    closedCasesTotalCount,
    onTabChange,
  } = useCasesPage({
    space,
    scope,
    availablePermissions,
    assetId,
  });

  return (
    <Box>
      <ScrollToTop />
      <Grid container>
        <CasesBreadcrumbs space={space} />
        <Title />
        {/* No API Support and no needed for first iteration */}
        {/*<FilterSection />*/}

        {!showCreateFirstCaseScreen &&
          !showCreateTicketingIntegrationScreen && (
            <Grid item xs={12}>
              <CasesTabs
                tabs={tabList}
                onTabChange={onTabChange}
                currentTab={currentTab}
              />
            </Grid>
          )}
        {showCreateFirstCaseScreen && <CreateFirstCase />}

        {hasCases && (
          <>
            {assetId && (
              <Grid
                item
                xs={12}
                sx={{ mt: 2, display: "flex", alignItems: "center", gap: 1 }}
              >
                <Typography
                  variant="body2"
                  color="text.secondary"
                  fontWeight={600}
                  sx={{ lineHeight: 1, marginTop: 1 }}
                >
                  Filtered By:
                </Typography>
                <KeyValueChip
                  keyValue={{
                    key: "Asset",
                    value: assetData?.asset?.name || assetId,
                  }}
                  onDelete={handleClearAssetFilter}
                />
              </Grid>
            )}
            {currentTab === CASES_TABS.OPEN ? (
              <OpenCases
                openCases={openCases}
                hasCloseCasePermissions={hasCloseCasePermissions}
                isLoading={openCasesDataLoading}
                fetchMoreOpenCases={fetchMoreOpenCases}
                openCasesPageInfo={openCasesPageInfo}
                openCasesTotalCount={openCasesTotalCount}
                scope={scope}
              />
            ) : (
              <ClosedCases
                closedCases={closedCases}
                hasCloseCasePermissions={hasCloseCasePermissions}
                isLoading={closedCasesDataLoading}
                closedCasesPageInfo={closedCasesPageInfo}
                closedCasesTotalCount={closedCasesTotalCount}
                fetchMoreClosedCases={fetchMoreClosedCases}
                scope={scope}
              />
            )}
          </>
        )}

        {showCreateTicketingIntegrationScreen && (
          <CreateTicketingIntegration
            scope={scope}
            hasIntegrationCreatePermission={hasIntegrationCreatePermission}
          />
        )}
      </Grid>
    </Box>
  );
};
