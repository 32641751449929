import { NavDrawerPolicyIcon } from "~/components/icons";
import { useMetrics } from "~/hooks";
import { Metrics } from "~/utils/arrow";
import { SmallDonutCard, SmallDonutCardProps } from "~/components/SmallDonut";

export type AssetPolicyOverviewCardProps = {
  onRatingFilterClick?: SmallDonutCardProps["onDatumClick"];
  selectedRatings?: string[];
  assetMrn: string;
};

export function AssetPolicyOverviewCard({
  onRatingFilterClick,
  selectedRatings,
  assetMrn,
}: AssetPolicyOverviewCardProps) {
  const { policyRiskRatings, isLoading } = useMetrics({
    entityMrn: assetMrn,
    metricMrns: [Metrics.PolicyRiskRatingDistribution],
  });

  return (
    <SmallDonutCard
      data-testid="asset-policy-overview-card"
      loading={isLoading}
      data={policyRiskRatings}
      title="Policy Overview"
      icon={<NavDrawerPolicyIcon />}
      onDatumClick={onRatingFilterClick}
      selected={selectedRatings}
    />
  );
}
