import React, { ReactNode, useEffect, useState } from "react";
import { Box, Grid, MenuItem, Paper, Select } from "@mui/material";
import { AutoConfigurationSection } from "~/pages/integrations/hosted-integrations/forms/jira/AutoConfigurationSection";
import { JiraFormInput } from "~/pages/integrations/hosted-integrations/forms/jira/types";
import {
  ClientIntegrationType,
  GetClientIntegrationDocument,
  useUpdateClientIntegrationConfigurationMutation,
} from "~/operations";
import { Controller, useForm } from "react-hook-form";
import { Space } from "~/lib/types";
import { TicketIntegration } from "~/components/cases/components/CaseSettings/types";
import { useJiraTicketContext } from "~/hooks/useJiraTicketContext";
import { motion } from "framer-motion";
import { SubField } from "~/components/Form/components";
import { FormattedDestination } from "~/pages/integrations/hosted-integrations/forms/jira/FormattedDestination";

type CaseSettingsIntegrationProps = {
  integration: TicketIntegration;
  space: Space;
  showAutoCreateSection: boolean;
};

export const CaseSettingsJiraIntegration = ({
  integration,
  space,
  showAutoCreateSection,
}: CaseSettingsIntegrationProps) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const { control, handleSubmit, reset, watch } = useForm<JiraFormInput>({
    defaultValues: {
      defaultProject: "",
      autoCloseTickets: false,
      autoCreateCases: false,
    },
    mode: "onChange",
  });
  const { projects, isProjectsEmpty } = useJiraTicketContext({
    scopeMrn: integration?.mrn || "",
    skip: !integration?.mrn,
  });

  const [updateIntegration] = useUpdateClientIntegrationConfigurationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    refetchQueries: [
      {
        query: GetClientIntegrationDocument,
        variables: {
          mrn: `//integration.api.mondoo.app/spaces/${
            space.id
          }/integrations/${integration?.mrn.split("/").pop()}`,
        },
      },
    ],
  });

  const onSubmit = async (data: JiraFormInput) => {
    const jiraConfigurationOptions = {
      host: data.host.trim(),
      email: data.email.trim(),
      apiToken: "",
      autoCloseTickets: data.autoCloseTickets,
      autoCreateCases: data.autoCreateCases,
      defaultProject: data.defaultProject,
    };

    await updateIntegration({
      variables: {
        input: {
          mrn: `//integration.api.mondoo.app/spaces/${
            space.id
          }/integrations/${integration?.mrn.split("/").pop()}`,
          type: ClientIntegrationType.TicketSystemJira,
          configurationOptions: {
            jiraConfigurationOptions,
          },
        },
      },
    });
  };

  useEffect(() => {
    // TypeScript users
    const subscription = watch(() => {
      isInitialized && handleSubmit(onSubmit)();
    });
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, isInitialized]);

  const [watchAutoCreate] = watch(["autoCreateCases"]);

  useEffect(() => {
    const configOptions = integration?.configurationOptions;
    if (configOptions?.__typename !== "JiraConfigurationOptions") return;
    reset({
      host: configOptions.host,
      email: configOptions.email,
      autoCloseTickets: configOptions.autoCloseTickets,
      autoCreateCases: configOptions.autoCreateCases,
      defaultProject: configOptions.defaultProject,
    });
    setIsInitialized(true);
  }, []);

  return (
    <Box>
      <Paper sx={{ p: 3 }}>
        <AutoConfigurationSection
          control={control}
          showAutoCreateSection={showAutoCreateSection}
          autoCreateSubField={
            <Box
              component={motion.div}
              initial={false}
              transition={{
                duration: 0.35,
                staggerChildren: 0.25,
              }}
              sx={{ overflow: "hidden" }}
            >
              <Controller
                name="defaultProject"
                {...{ control }}
                render={({ field }) => (
                  <SubField
                    configuration="Select a drift destination"
                    caption="Select where to create an issue for each new drift ticket."
                    disabled={!watchAutoCreate}
                    field={
                      <Grid item xs={12} md={6} sx={{ alignSelf: "flex-end" }}>
                        <Select
                          {...field}
                          fullWidth
                          disabled={!watchAutoCreate}
                          sx={{
                            "&.MuiInputBase-root": {
                              bgcolor: "code.background",
                            },
                          }}
                          displayEmpty
                          renderValue={(selected: ReactNode) => {
                            const selectedProject = projects?.find(
                              (project) => project.key === selected,
                            );

                            const selectedProjectTitle = (
                              <FormattedDestination
                                projectKey={selectedProject?.key || ""}
                                projectTitle={selectedProject?.name || ""}
                              />
                            );

                            if (isProjectsEmpty) {
                              return (
                                <>
                                  No projects available – create one in Jira
                                  first
                                </>
                              );
                            }

                            if (!selected || !selectedProjectTitle) {
                              return <>Select project</>;
                            }

                            return <>{selectedProjectTitle}</>;
                          }}
                        >
                          {(projects || []).map((project) => (
                            <MenuItem key={project.key} value={project.key}>
                              ({project.key}) {project.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    }
                  />
                )}
              />
            </Box>
          }
        />
      </Paper>
    </Box>
  );
};
