import { Controller } from "react-hook-form";
import { OperatorType } from "../types";
import { useOperator } from "./hooks/useOperator";
import { OperatorDropdown } from "./OperatorDropdown";

type OperatorProps = {
  fieldId: string;
  selectionIndex: number;
  conditionIndex: number;
  isReadonly: boolean;
  type?: OperatorType;
};

export const Operator = ({
  fieldId,
  isReadonly,
  conditionIndex,
  selectionIndex,
  type: typeFromProps,
}: OperatorProps) => {
  const { name, defaultValue, control, type } = useOperator({
    fieldId,
    selectionIndex,
    conditionIndex,
    type: typeFromProps,
  });

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <OperatorDropdown
          type={typeFromProps || type}
          isReadonly={isReadonly}
          defaultValue={defaultValue}
          {...field}
        />
      )}
    />
  );
};
