import { Grid2, Stack, Skeleton, Divider } from "@mui/material";
import { ChevronLeftIcon } from "~/components/icons";

export const TriageBarSkeleton = () => {
  return (
    <Grid2
      container
      rowSpacing={2}
      columnSpacing={2}
      sx={{
        position: "sticky",
        top: 64,
        background: (theme) => theme.palette.background.paper,
        zIndex: 99,
      }}
    >
      <Grid2 container size={12} sx={{ width: 1, mx: "auto", py: 3, px: 5 }}>
        <Grid2
          container
          rowSpacing={1}
          flexDirection="column"
          size={{ xs: 12, md: "grow" }}
        >
          <Grid2>
            <Stack direction="row" alignItems="center" spacing={1}>
              <ChevronLeftIcon
                fontSize="large"
                sx={{
                  color: "primary.main",
                }}
              />
              <Skeleton variant="rounded" width="100%" height={48} />
            </Stack>
          </Grid2>
          <Grid2>
            <Skeleton variant="rounded" width="100%" height={24} />
          </Grid2>
        </Grid2>
        <Grid2
          container
          rowSpacing={1}
          flexDirection="column"
          size={{ xs: 12, md: "auto" }}
        >
          <Grid2 container>
            <Grid2 size={{ xs: 12, md: "grow" }}>
              <Stack
                direction="row"
                gap={1}
                alignItems="center"
                justifyContent={{ xs: "flex-end" }}
              >
                <Skeleton variant="rounded" width={48} height={48} />
                <Skeleton variant="rounded" width={48} height={48} />
              </Stack>
            </Grid2>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ display: { xs: "none", md: "flex" } }}
            />
            <Grid2
              size={{ xs: 12, md: "auto" }}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Stack
                direction="column"
                gap={1}
                alignItems="center"
                justifyContent={{ xs: "flex-end" }}
              >
                <Skeleton
                  variant="rounded"
                  width={160}
                  height={34}
                  sx={{
                    maxWidth: { xs: "100%", sm: 256 },
                    minWidth: { xs: "100%", sm: 256 },
                    mb: 0.8,
                    mt: 0.8,
                    bgcolor: "primary.main",
                  }}
                />
                <Skeleton
                  variant="rounded"
                  width={160}
                  height={24}
                  sx={{
                    maxWidth: { xs: "100%", sm: 256 },
                    minWidth: { xs: "100%", sm: 256 },
                  }}
                />
              </Stack>
            </Grid2>
          </Grid2>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};
