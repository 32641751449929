import { Box, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { ReportTypes } from "./ReportTypes";
import { Space } from "~/lib/types";

const DESCRIPTION_TEXT = `Select a report to run below. Check back soon for more reporting capabilities!`;

interface Props {
  space: Space;
  onSuccess: () => void;
}

const ReportTypesPicker = ({ space, onSuccess }: Props) => {
  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      sx={{ width: 1 }}
    >
      <Box sx={{ display: "flex", alignItems: "start", gap: 1, mb: 5 }}>
        <Typography color="text.secondary">{DESCRIPTION_TEXT}</Typography>
      </Box>

      <Box sx={{ position: "relative", mb: 3 }}>
        <Typography variant="h6" sx={{ mb: 3 }}>
          Space-wide Reports
        </Typography>
        <Box
          sx={{
            position: "absolute",
            width: "calc(100% - 200px)",
            top: "50%",
            right: 0,
            height: 2,
            bgcolor: "divider",
          }}
        />
      </Box>

      <ReportTypes space={space} onSuccess={onSuccess} />
    </Box>
  );
};

export default ReportTypesPicker;
