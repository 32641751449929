import { NavDrawerSpacesIcon } from "~/components/icons";
import { Typography } from "@mui/material";
import { useViewer } from "~/providers/viewer";
import { Org } from "~/lib/types";

export const organizationSpacesFilteredEmptyStateContent = () => {
  const { currentOrg } = useViewer();
  const org: Org | undefined | null = currentOrg();

  return {
    icon: <NavDrawerSpacesIcon sx={{ fontSize: 38, color: "primary.main" }} />,
    headline: "No spaces match your filter criteria",
    textContent: (
      <Typography color="text.secondary" sx={{ textAlign: "center" }}>
        Mondoo searched {org?.spacesCount} spaces but none of them match what
        you are looking for. There are {org?.spacesCount} results hidden by
        filters.
      </Typography>
    ),
  };
};
