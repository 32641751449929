import {
  ConfigurationList,
  ConfigurationPaper,
} from "~/components/configuration-items";
import { LoadingPage } from "~/components/loading";
import { RiskFactorAction, useGetSpaceRiskFactorsQuery } from "~/operations";
import { useSpaceSettingsOutletContext } from "../../space-settings";
import {
  fieldId,
  formatMagnitude,
  getInitialCondition,
  RiskFactorConditionKeyValueField,
  RiskFactorConditionKeyValueOperator,
  RiskFactorConditionOperator,
  RiskFactorsConfigurationForm,
  RiskFactorsConfigurationInput,
} from "./RiskFactorsConfigurationForm";

export function RiskFactorsConfiguration() {
  const { space } = useSpaceSettingsOutletContext();
  const spaceMrn = space.mrn;

  const { data, loading } = useGetSpaceRiskFactorsQuery({
    variables: { spaceMrn },
    fetchPolicy: "network-only",
  });

  const riskFactors = data?.riskFactors.edges || [];
  // sort the riskFactors so that tagged risk factors are first in the list, High Prio then Low Prio.
  const highPrio = riskFactors.find(
    (riskFactor) => riskFactor.title === "High-priority asset",
  );
  const lowPrio = riskFactors.find(
    (riskFactor) => riskFactor.title === "Low-priority asset",
  );
  if (lowPrio) {
    riskFactors.splice(riskFactors.indexOf(lowPrio), 1);
    //place low priority asset after high priority asset
    riskFactors.unshift(lowPrio);
  }
  if (highPrio) {
    riskFactors.splice(riskFactors.indexOf(highPrio), 1);
    riskFactors.unshift(highPrio);
  }

  const defaultValues =
    riskFactors.reduce<RiskFactorsConfigurationInput>(
      (acc, { mrn, action, magnitude, assetFieldFilters }) => {
        const { value, isToxic } = magnitude;
        const rawMagnitude = formatMagnitude(value);

        const labelConditions =
          assetFieldFilters?.labelBasedFilters?.flatMap((f) => {
            return {
              formKey: RiskFactorConditionKeyValueField.Labels,
              operator: RiskFactorConditionOperator.Or,
              keyValueCondition: {
                field: RiskFactorConditionKeyValueField.Unknown,
                operator: RiskFactorConditionKeyValueOperator.Contains,
                values: [
                  {
                    key: f.key,
                    value: f.value,
                  },
                ],
              },
            };
          }) || [];

        const annotationConditions =
          assetFieldFilters?.annotationBasedFilters?.flatMap((f) => {
            return {
              formKey: RiskFactorConditionKeyValueField.Annotations,
              operator: RiskFactorConditionOperator.Or,
              keyValueCondition: {
                field: RiskFactorConditionKeyValueField.Unknown,
                operator: RiskFactorConditionKeyValueOperator.Contains,
                values: [
                  {
                    key: f.key,
                    value: f.value,
                  },
                ],
              },
            };
          }) || [];

        const conditions = [...labelConditions, ...annotationConditions];

        const isTaggable = assetFieldFilters !== null;

        acc[fieldId(mrn)] = {
          enabled: action === RiskFactorAction.Enable,
          magnitude: value,
          rawMagnitude,
          isToxic,
          selections: isTaggable
            ? [
                {
                  conditions: [
                    ...(conditions.length > 0
                      ? conditions
                      : [getInitialCondition()]),
                  ],
                },
              ]
            : undefined,
        };
        return acc;
      },
      {},
    ) || {};

  return (
    <ConfigurationPaper className="risk-factors-config">
      <ConfigurationList title="Risk configuration">
        {loading && <LoadingPage what="Risk Factors" />}
        {!loading && riskFactors.length > 0 && (
          <RiskFactorsConfigurationForm
            riskFactors={riskFactors}
            defaultValues={defaultValues}
          />
        )}
      </ConfigurationList>
    </ConfigurationPaper>
  );
}
