import { Fragment, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {
  SubscriptionItem,
  useChangeSubscriptionPlanMutation,
  useCreateBillingSessionMutation,
  useSubscriptionInfoQuery,
} from "~/operations";
import { Org } from "~/lib/types";
import { AvailableSubscriptions } from "~/components/billing/available-subscriptions";
import { ActiveSubscriptions } from "~/components/billing/active-subscriptions";
import { UpgradePlan } from "~/components/billing/upgrade-plan";
import { RequestQuoteDialog } from "~/components/billing/request-quote";
import { Loading, LoadingFailed } from "~/components/loading";
import { setDocumentTitle } from "~/utils/commonUtils";

type SettingsBillingProps = {
  org: Org;
};

export function Billing({ org }: SettingsBillingProps) {
  const { enqueueSnackbar } = useSnackbar();

  const [showQuoteRequestDialog, setShowRequestQuoteDialog] = useState(false);

  const subscriptionInfoResult = useSubscriptionInfoQuery({
    variables: {
      orgMrn: org.mrn,
    },
  });

  const subscriptionInfo = subscriptionInfoResult.data?.subscriptionInfo;
  const basePlan = subscriptionInfo?.basePlan;
  const isCommunityPlan = basePlan?.key === "plan_community";
  const isTeamPlan = basePlan?.key === "plan_team";

  const [createBillingSession, billingSessionResult] =
    useCreateBillingSessionMutation({
      variables: {
        input: {
          returnURL: window.location.href,
          orgMrn: org.mrn,
        },
      },
    });

  const billingSessionUrl =
    billingSessionResult.data?.createBillingSession.url || "";

  useEffect(() => {
    if (subscriptionInfo?.isSelfService) {
      createBillingSession();
    }
  }, [subscriptionInfo]);

  const subscriptionPlans = subscriptionInfoResult.data?.subscriptionPlans;
  const enterprisePlan = subscriptionPlans?.find(
    (p) => p.basePlan.key === "plan_enterprise",
  );
  const enterprisePlanPriceID = enterprisePlan?.basePlan.price.id || "";

  const [changeSubscriptionPlan, changeSubscriptionPlanResult] =
    useChangeSubscriptionPlanMutation();

  const changePlanSession =
    changeSubscriptionPlanResult.data?.changeSubscriptionPlan;
  const changePlanError = changeSubscriptionPlanResult.error;

  useEffect(() => {
    if (changePlanSession?.__typename === "BillingSession") {
      window.open(changePlanSession.url, "_self");
    }
  }, [changePlanSession]);

  useEffect(() => {
    if (changePlanError) {
      enqueueSnackbar(`${changePlanError}`, { variant: "error" });
    }
  }, [changePlanError]);

  const onPlanSelect = (items: SubscriptionItem[]) => {
    const selectedEnterprise = items.some(
      (i) => i.priceID === enterprisePlanPriceID,
    );
    if (selectedEnterprise) {
      quotePlan(items);
    } else {
      setPlan(items);
    }
  };

  const onQuoteRequest = () => {
    quotePlan([{ priceID: enterprisePlanPriceID, quantity: 1 }]);
  };

  const onQuotaRequest = () => {
    quotePlan([]);
  };

  const setPlan = (items: SubscriptionItem[]) => {
    return changeSubscriptionPlan({
      variables: {
        input: {
          confirm: true,
          returnURL: window.location.href,
          orgMrn: org.mrn,
          items,
        },
      },
    });
  };

  const quotePlan = (items: SubscriptionItem[]) => {
    setShowRequestQuoteDialog(true);
  };

  const onQuoteRequestClose = () => {
    setShowRequestQuoteDialog(false);
  };

  setDocumentTitle(["Billing", "Organization Settings"]);

  return (
    <Fragment>
      {subscriptionInfoResult.loading || billingSessionResult.loading ? (
        <Loading what="Plan" />
      ) : (
        <Fragment>
          {subscriptionInfoResult.error ? (
            <LoadingFailed what="Plan" />
          ) : (
            <Fragment>
              {subscriptionInfo && (
                <ActiveSubscriptions
                  billingSessionUrl={billingSessionUrl}
                  subscriptionInfo={subscriptionInfo}
                  onQuotaRequest={onQuotaRequest}
                />
              )}
              {isCommunityPlan && subscriptionPlans && (
                <AvailableSubscriptions
                  subscriptionPlans={subscriptionPlans}
                  onPlanSelect={onPlanSelect}
                />
              )}
              {isTeamPlan && <UpgradePlan onQuoteRequest={onQuoteRequest} />}
              <RequestQuoteDialog
                open={showQuoteRequestDialog}
                onClose={onQuoteRequestClose}
              />
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
