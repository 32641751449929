import { ReactElement } from "react";
import { alpha, Box, SxProps, Theme, Typography } from "@mui/material";
import { ImpactRating } from "../impact-rating";
import { Impact as ImpactType } from "~/operations";
import { ImpactTooltipUpdated } from "./impact-tooltip-updated";
import { useImpactUpdated } from "./useImpactUpdated";

export type ImpactRating = Omit<ImpactType, "__typename" | "value"> &
  Partial<ImpactType>;

export type ImpactRatioProps = {
  impact: ImpactRating;
  adjustedColor?: string;
  hideTooltip?: boolean;
  showText?: boolean;
  isActive?: boolean;
  children?: ReactElement;
  isRiskScore?: boolean;
  isCVSS?: boolean;
  centerDots?: boolean;
  contained?: boolean;
  sx?: SxProps<Theme>;
  options?: ImpactRatingOptions;
};

export type ImpactRatingOptions = {
  size?: number;
  border?: number;
  fontSize?: number;
  fontWeight?: number;
};

export const ImpactUpdated = ({
  impact,
  adjustedColor,
  hideTooltip = false,
  showText = true, // show the text label i.e "high", "medium", "low" etc.
  isActive = true,
  isRiskScore = false,
  isCVSS = false,
  centerDots = false,
  contained = false,
  sx = [],
  options,
}: ImpactRatioProps) => {
  const { impactData, color, label } = useImpactUpdated({
    impact,
    adjustedColor,
    isActive,
  });

  const fontSize = options?.fontSize || 12;
  const fontWeight = options?.fontWeight || 400;

  const TooltipWrapper = ({ children }: { children: ReactElement }) =>
    hideTooltip ? (
      children
    ) : (
      <ImpactTooltipUpdated
        color={color}
        impact={impact}
        isRiskScore={isRiskScore}
        isCVSS={isCVSS}
      >
        {children}
      </ImpactTooltipUpdated>
    );

  return (
    <TooltipWrapper>
      <Box
        className="impact"
        display="inline-flex"
        flexDirection="column"
        sx={[
          {
            gap: 0.5,
            ...(contained
              ? {
                  backgroundColor: alpha(color, 0.2),
                  borderRadius: 0.5,
                  p: 1.5,
                  justifyContent: "center",
                }
              : {}),
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        {showText && (
          <Box className="impact-label">
            <Typography
              sx={{ fontWeight, fontSize: `${fontSize}px`, lineHeight: 1 }}
            >
              {label}
            </Typography>
          </Box>
        )}
        <Box className="impact-rating">
          <ImpactRating
            impactData={impactData}
            color={color}
            options={options}
            centerDots={centerDots}
          />
        </Box>
      </Box>
    </TooltipWrapper>
  );
};
