import { PrintPolicyReport } from "./print-policy-report";
import { PrintPolicyVulnReport } from "./print-policy-vuln-report";
import { Asset, ListPolicyEdge } from "~/pages/inventory/asset/types";
import { MONDOO_PLATFORM_VULN_POLICY_PREFIX } from "../../../constants";

type PrintPoliciesProps = {
  asset: Asset;
};

export function PrintPolicies({ asset }: PrintPoliciesProps) {
  const policyEdges = asset.listPolicies?.edges || [];
  const assetMrn = asset.mrn;

  const isPlatVulnPolicy = (edge: ListPolicyEdge) =>
    edge.node.mrn.startsWith(MONDOO_PLATFORM_VULN_POLICY_PREFIX);

  return (
    <>
      {policyEdges.map((edge) => {
        if (isPlatVulnPolicy(edge)) {
          return (
            <PrintPolicyVulnReport
              edge={edge}
              assetMrn={assetMrn}
              key={edge.node.mrn}
            />
          );
        } else {
          return (
            <PrintPolicyReport
              listPolicyEdge={edge}
              assetMrn={assetMrn}
              key={edge.node.mrn}
            />
          );
        }
      })}
    </>
  );
}
