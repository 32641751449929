import { TableCell, Typography } from "@mui/material";
import { AggregateScoresRiskValue } from "../FirewatchPage";

export type RiskValueCellProps = {
  riskValue: AggregateScoresRiskValue;
};

export const RiskValueCell = ({ riskValue }: RiskValueCellProps) => {
  return (
    <TableCell>
      <Typography fontSize={12}>{riskValue}</Typography>
    </TableCell>
  );
};
