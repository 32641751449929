import { Clamp } from "~/components/clamp/clamp";
import { Box, Chip, TableCell, Typography } from "@mui/material";
import { advisoryTitle } from "~/lib/types";
import { Score2CvssScore } from "~/lib/score";
import { SoftwareDetailsAdvisoryCellProps } from "./types";
import { AdvisoryIcon } from "~/components/icons";
import { DynamicIcon } from "~/components/icons/DynamicIcon";

export const AdvisoryCell = ({
  advisory,
  ...props
}: SoftwareDetailsAdvisoryCellProps) => {
  return (
    <TableCell data-testid={props["data-testid"]}>
      <Box display="flex" gap={1} alignItems="center">
        <DynamicIcon iconId={advisory.iconIDEnum} defaultIcon={AdvisoryIcon} />
        <Clamp>
          <Typography fontSize="14px" data-testid="advisory-id">
            {advisory.id} {advisoryTitle(advisory)}
          </Typography>
        </Clamp>
      </Box>
      <Box display="flex" gap={1} mt={1}>
        <Chip
          label={`CVSS v3 ${Score2CvssScore(advisory.cvssScore.value)}`}
          variant="filled"
          size="small"
          sx={{ borderRadius: "4px" }}
          data-testid="advisory-score"
        />
      </Box>
    </TableCell>
  );
};
