import {
  AzureColorIcon,
  BracketsIcon,
  GCPColorIcon,
  GithubIcon,
} from "~/components/icons";
import SvgIcon from "@mui/material/SvgIcon";

export type ProviderTypes = "azure" | "google" | "github" | "custom";

type AvailableProvidersType = {
  issuerUrl: string;
  type: ProviderTypes;
  title: string;
  description: string;
  icon: typeof SvgIcon;
};

export const customProvider: AvailableProvidersType = {
  issuerUrl: "",
  type: "custom",
  title: "Custom",
  description:
    "Add your own, custom method for providing keyless workload authentication using OpenID Connect.",
  icon: BracketsIcon,
};

export const availableProviders: Array<AvailableProvidersType> = [
  {
    issuerUrl: "https://sts.windows.net/",
    type: "azure",
    title: "Azure",
    description:
      "Use Microsoft Entra Workload ID for keyless authentication with Mondoo.",
    icon: AzureColorIcon,
  },
  {
    issuerUrl: "https://accounts.google.com",
    type: "google",
    title: "Google",
    description:
      "Use Google Cloud Workload Identity Federation for keyless authentication with Mondoo.",
    icon: GCPColorIcon,
  },
  {
    issuerUrl: "https://token.actions.githubusercontent.com",
    type: "github",
    title: "GitHub",
    description:
      "Use GitHub's OpenID Connect provider for keyless authentication with Mondoo.",
    icon: GithubIcon,
  },
  customProvider,
];
