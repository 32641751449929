import { useState } from "react";
import {
  Link as RouterLink,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import { ArrowForwardIcon, HomeIcon } from "~/components/icons";
import { useSnackbar } from "notistack";

import {
  ActionType,
  ClientIntegration,
  ClientIntegrationType,
  GetIntegrationsSummaryDocument,
  TestIamActionsQuery,
  useDeleteClientIntegrationMutation,
  useGetClientIntegrationQuery,
} from "~/operations";
import { IntegrationHeader } from "../headers/integration-header";
import { ConfigurationsAndMessages } from "../components/configurations-and-messages";
import { ConfirmDeleteDialog } from "../components/confirm-delete-dialogue";
import { mapIntegrationRouteToTitle } from "./helpers";
import { Space } from "~/lib/types";
import { setDocumentTitle } from "~/utils/commonUtils";

type Props = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

const EXPORT_INTEGRATIONS = [
  ClientIntegrationType.AwsS3,
  ClientIntegrationType.Bigquery,
  ClientIntegrationType.GcsBucket,
  ClientIntegrationType.Postgres,
  ClientIntegrationType.Snowflake,
  ClientIntegrationType.S3,
  ClientIntegrationType.AzureBlob,
];

// these are also export integrations
const TICKET_SYSTEM_INTEGRATIONS = [
  ClientIntegrationType.TicketSystemJira,
  ClientIntegrationType.TicketSystemEmail,
];

const IMPORT_INTEGRATIONS = [
  ClientIntegrationType.MicrosoftDefender,
  ClientIntegrationType.SentinelOne,
  ClientIntegrationType.CrowdstrikeFalcon,
];

export function HostedIntegrationPage({ space, availablePermissions }: Props) {
  const { integrationTypeId, integrationId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  let navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [searchParams, _setSearchParams] = useSearchParams();
  const integrationTitle = mapIntegrationRouteToTitle(integrationTypeId);
  const mrn: string = `//integration.api.mondoo.app/spaces/${space.id}/integrations/${integrationId}`;

  const { data, error, loading } = useGetClientIntegrationQuery({
    variables: { mrn },
  });

  const [deleteIntegration] = useDeleteClientIntegrationMutation({
    refetchQueries: [
      {
        query: GetIntegrationsSummaryDocument,
        variables: { input: { spaceMrn: space.mrn } },
      },
    ],
  });

  if (error) {
    return <LoadingFailedPage what="Integration" />;
  }

  if (loading || !data?.clientIntegration.integration) {
    return <LoadingPage what="Integration" />;
  }

  const { integration } = data.clientIntegration;

  // integration
  let integrationStatus: ClientIntegration["status"] = integration.status;
  if (integration.status.toLowerCase() === "not_ready") {
    integrationStatus = "pending";
  }

  const actions = {
    deleteIntegration: () => setDialogOpen(true),
    editIntegration: () => navigate(`edit?spaceId=${space.id}`),
  };

  const isExportIntegration = [
    ...EXPORT_INTEGRATIONS,
    ...TICKET_SYSTEM_INTEGRATIONS,
  ].includes(integration.type);

  const isImportIntegration = IMPORT_INTEGRATIONS.includes(integration.type);

  const actionType = isExportIntegration
    ? "export"
    : isImportIntegration
      ? "import"
      : "scan";

  const getAssetsUrl = (integrationMrn: string) => {
    const queryterms = JSON.stringify({
      "mondoo.com/integration-mrn": integrationMrn,
    });
    searchParams.set("queryTerms", queryterms);
    return `/space/inventory?${searchParams}`;
  };

  const handleDelete = async () => {
    try {
      await deleteIntegration({
        variables: { input: { mrn: integration.mrn } },
      });
      enqueueSnackbar(
        `Successfully removed ${integrationTitle} integration from Mondoo`,
        {
          variant: "success",
        },
      );
      navigate(`/space/integrations/${integrationTypeId}?spaceId=${space.id}`);
    } catch {
      enqueueSnackbar(
        `Could not remove ${integrationTitle} integration from Mondoo`,
        {
          variant: "error",
        },
      );
    }
  };

  // we have a unique situation for aws is split into two routes for viewing, hosted and serverless
  // when viewing the integrations as a list, they should all show up under the same aws route.
  const modifyIntegrationLink = () => {
    if (integrationTypeId === "aws_hosted") {
      return "aws";
    }
    return integrationTypeId;
  };

  const linkToIntegrationsList = `/space/integrations/${modifyIntegrationLink()}?spaceId=${space.id}`;

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?spaceId=${space.id}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Link
      key="/space/integrations"
      component={RouterLink}
      to={`/space/integrations?spaceId=${space.id}`}
    >
      Integrations
    </Link>,
    <Link
      key={`/space/integrations/${integrationTypeId}`}
      component={RouterLink}
      to={linkToIntegrationsList}
    >
      {integrationTitle}
    </Link>,
    <Typography key={1} color="text.primary">
      {integration.name}
    </Typography>,
  ];

  // Sum up the total of all asset types
  const assetCounts =
    integration.assetCounts?.reduce((acc, count) => acc + count.total, 0) || 0;

  setDocumentTitle([integration.name, integrationTitle, "Integrations"]);

  return (
    <Box>
      <Breadcrumbs sx={{ mb: 5 }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>
      {/* Header Block */}
      <Box sx={{ mb: 6.5 }}>
        <IntegrationHeader
          {...{
            space,
            integration,
            availablePermissions,
            actions,
            integrationTypeId,
            ...(isExportIntegration && {
              scanAction: ActionType.RunExport,
            }),
            ...(isImportIntegration && {
              scanAction: ActionType.RunImport,
            }),
          }}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          alignItems: { xs: "flex-start", sm: "center" },
          justifyContent: "space-between",
          mb: 4,
        }}
      >
        <Typography variant="h5" component="h2" fontWeight={700}>
          Integration Details
        </Typography>
        {/* If the integration status is pending, we don't want to show a link to assets */}
        {/* If the integration is an export, there are no assets to link to */}
        {![...EXPORT_INTEGRATIONS, ...TICKET_SYSTEM_INTEGRATIONS].includes(
          integration.type,
        ) &&
          assetCounts > 0 &&
          integrationStatus !== "pending" && (
            <Button
              color="secondary"
              component={RouterLink}
              to={getAssetsUrl(integration.mrn)}
              endIcon={<ArrowForwardIcon />}
            >
              Show Assets
            </Button>
          )}
      </Box>
      {/* Configuration and Overview Information */}
      <Paper sx={{ p: 3, mb: 3 }}>
        <ConfigurationsAndMessages {...{ integration, actionType }} />
      </Paper>
      {/* Delete Confirmation Dialog */}
      <ConfirmDeleteDialog
        id={`confirm-delete-single-${integrationTypeId}-item`}
        value={"hello"}
        open={dialogOpen}
        onCancel={() => setDialogOpen(false)}
        onConfirm={handleDelete}
        title={`Remove ${integrationTitle} Integration`}
        content={
          <Typography>
            Are you absolutely sure you want to remove this integration? This
            operation cannot be undone.
          </Typography>
        }
      />
    </Box>
  );
}
