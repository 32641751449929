import { FC, ReactNode } from "react";
import { styled } from "@mui/material";
import { Label } from "./label";

export const scoreDotColor = (props: any | ScoreDotProps) => {
  const palette = props.theme.palette[props.status];
  if (palette == null) {
    console.error(`score-card> no score dot color for status==${props.status}`);
    return "black";
  }
  return palette.main;
};

type ScoreDotProps = {
  status: string;
};

const ScoreDot = styled("div")<ScoreDotProps>`
  height: 12px;
  width: 12px;
  border-radius: 6px;
  background-color: ${scoreDotColor};
  margin-right: 10px;
  display: inline-block;
`;

const ScoreLabel = styled(Label)`
  margin: 5px;
`;

const LgScoreDot = styled("div")<ScoreDotProps>`
  height: 24px;
  width: 24px;
  border-radius: 12px;
  background-color: ${scoreDotColor};
  margin-right: ${(props) => props.theme.spacing(1)};
  display: inline-block;
`;

const LgScoreLabel = styled(Label)`
  margin: ${(props) => props.theme.spacing(1)} 0;
  padding: ${(props) => props.theme.spacing(1)};
  border-radius: 20px;
  justify-content: flex-start;
  font-size: 12px;
`;

export type StatusTagProps = {
  status: string;
  text: ReactNode;
  displaydot: boolean;
};

export const LargeStatusTag: FC<StatusTagProps> = ({
  status,
  text,
  displaydot = true,
}) => {
  return (
    <LgScoreLabel>
      {displaydot == true && <LgScoreDot status={status} />}
      <span>{text}</span>
    </LgScoreLabel>
  );
};

export const StatusTag: FC<StatusTagProps> = ({
  status,
  text,
  displaydot = true,
}) => {
  return (
    <ScoreLabel>
      {displaydot == true && <ScoreDot status={status} />}
      <span>{text}</span>
    </ScoreLabel>
  );
};

export const AgentStatus = {
  ACTIVE: "excellent",
  MISSING: "critical",
  TERMINATED: "unknown",
  NOT_READY: "unknown",
  WAITING_FOR_SETUP: "low",
  SETUP_IN_PROGRESS: "low",
  ERROR: "critical",
  REGISTERING: "low",
  CONFIGURING: "low",
  DELETED: "critical",
  WARNING: "low",
} as const;

const AgentStatusText = {
  ACTIVE: "active",
  MISSING: "missing",
  TERMINATED: "terminated",
  NOT_READY: "not ready",
  WAITING_FOR_SETUP: "waiting for setup",
  SETUP_IN_PROGRESS: "setup in progress",
  REGISTERING: "registering",
  CONFIGURING: "configuring",
  ERROR: "error",
  DELETED: "deleted",
  WARNING: "active",
} as const;

export const AgentStatusTag = ({
  status,
}: {
  status: keyof typeof AgentStatus;
}) => {
  const s = AgentStatus[status] || AgentStatus.NOT_READY;
  const text = AgentStatusText[status] || AgentStatusText.NOT_READY;
  return <StatusTag status={s} text={text} displaydot />;
};

export const LargeAgentStatusTag = ({
  status,
}: {
  status: keyof typeof AgentStatus;
}) => {
  const s = AgentStatus[status] || AgentStatus.NOT_READY;
  const text = AgentStatusText[status] || AgentStatusText.NOT_READY;
  return <LargeStatusTag status={s} text={text} displaydot />;
};
