import { ReactNode } from "react";
import { alpha, Paper, Table, TableContainer, TableProps } from "@mui/material";

export type DataTableProps = {
  children: ReactNode;
  id?: string;
  selectable?: boolean;
  selection?: unknown[];
  /***
   * This size specifically comes from the FirewatchList component
   */
  firewatchSize?: string;
  printView?: boolean;
  "data-testid"?: string;
};

export function DataTable({
  children,
  id,
  selectable = false,
  selection = [],
  firewatchSize,
  printView,
  ...props
}: DataTableProps) {
  const isFirewatchDashboard = firewatchSize === "small";

  return (
    <TableContainer
      id={id}
      component={Paper}
      elevation={isFirewatchDashboard ? 0 : 1}
      data-testid={props["data-testid"]}
      sx={{
        // remove border radius from the bottom of the table container only
        ...(!printView && {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }),
        ...(printView && {
          boxShadow: "none",
          border: "1px solid",
          borderColor: "background.light",
          thead: {
            boxShadow: "none",
          },
        }),

        ".MuiTableRow-root:not(.detail-row):not(.loading-row):not(.empty-row)":
          {
            backgroundColor: "transparent",
            "&.excepted .MuiTableCell-root": {
              color: "text.disabled",
            },

            "&.selected": {
              backgroundColor: (theme) =>
                alpha(theme.palette.primary.main, 0.2),
            },

            "&:hover": {
              backgroundColor: printView ? "transparent" : "background.light",

              "&.selected": {
                backgroundColor: (theme) =>
                  alpha(theme.palette.primary.main, 0.4),
              },

              ".MuiTableCell-root:first-of-type": {
                opacity: 1,
              },
            },

            "&.inactive": {
              "& .MuiTableCell-root": {
                color: "text.secondary",
              },
              "&:hover .MuiTableCell-root::before": {
                background: "transparent",
              },
            },
          },
        ".MuiTableRow-root:not(.detail-row):not(.loading-row):not(.empty-row) .MuiTableCell-root":
          {
            py: isFirewatchDashboard ? 0.5 : 2,
            color: "text.primary",
            fontWeight: 400,

            "&.MuiTableCell-head": {
              py: 1,
              color: "text.secondary",
              fontWeight: 700,
            },

            ...(selectable && {
              "&:first-of-type": {
                px: 1,
                py: 0,
                width: 0,

                "@media (hover: hover)": {
                  opacity: selection.length === 0 ? 0 : 1,
                  transition: (theme) =>
                    theme.transitions.create("opacity", {
                      duration: theme.transitions.duration.shortest,
                    }),
                },

                "& + .MuiTableCell-root": {
                  pl: 0,
                },
              },
            }),
          },

        // Remove the bottom border from only the bottom of the table header
        ".MuiTableRow-root:not(.detail-row):not(.loading-row):not(.empty-row):last-of-type .MuiTableCell-root":
          {
            borderBottom: "none",
          },
        ...(isFirewatchDashboard && {
          td: {
            borderBottom: "none",
          },
        }),
        // Remove the bottom border from the last detail row
        ".MuiTableRow-root:not(.loading-row):not(.empty-row):last-of-type .MuiTableCell-root":
          {
            borderBottom: "none",
          },
      }}
    >
      <Table {...(isFirewatchDashboard && { size: "small" })}>{children}</Table>
    </TableContainer>
  );
}
