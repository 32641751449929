import { useParams } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import { SectionHeading } from "~/components/DetailsPage/components";
import { ScopeType, useScope } from "~/hooks/useScope";
import { AdvisoriesTable } from "~/pages/space/vulnerabilities/components/Vulnerability/AdvisoriesTable";
import { AdvisoryContentDetails } from "~/components/vulnerabilities";
import { useFindingOnAsset } from "~/pages/findings/FindingOnAsset/hooks";
import { isAdvisoryOrVulnerabilityFinding } from "~/pages/findings/FindingOnAsset/utils";
import { ScrollToTop } from "~/lib/scroll-to-top";

export function RelatedFindings() {
  const { activeScope, spaceScope } = useScope();
  const { vulnId = "", advisoryId = "" } = useParams();
  const { finding } = useFindingOnAsset();

  const noSpaceAndSpace = !spaceScope || !activeScope;

  if (noSpaceAndSpace) {
    // This should never happen, but if it does, we don't want to render anything.
    return null;
  }

  const isWorkspace = activeScope.type === ScopeType.Workspace;
  const isSpace = activeScope.type === ScopeType.Space;

  if (
    (!isWorkspace && !isSpace) ||
    !isAdvisoryOrVulnerabilityFinding(finding)
  ) {
    // We only want to render this component for workspace or space scopes.
    return null;
  }

  return vulnId ? (
    <Fragment>
      <ScrollToTop />
      <SectionHeading heading="Related advisories" />
      <AdvisoriesTable space={spaceScope} scope={activeScope} vulnId={vulnId} />
    </Fragment>
  ) : (
    <AdvisoryContentDetails advisoryId={advisoryId} scope={activeScope} />
  );
}
