import { MenuList, MenuListProps } from "@mui/material";

type BaseMenuListProps = MenuListProps & {};

export const BaseMenuList = ({
  children,
  sx = [],
  ...rest
}: BaseMenuListProps) => {
  return (
    <MenuList
      disablePadding
      sx={[
        {
          maxHeight: "calc(100vh - 152px)",
          overflow: "auto",
          pt: 0,
          mb: 2,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      {children}
    </MenuList>
  );
};
