import { Grid2 } from "@mui/material";
import { Code } from "~/components/code";
import React from "react";
import { useFindingOnAsset } from "~/pages/findings/FindingOnAsset/hooks";
import { Flex } from "~/components/Flex";
import { Text } from "~/components/ui-library";

export const Results = () => {
  const { queryResultsNotFoundErrorMessage, queryResults } =
    useFindingOnAsset();

  if (!queryResults && !queryResultsNotFoundErrorMessage) return null;

  return (
    <Grid2 size={12}>
      <Flex alignItems="center" justifyContent="space-between" my={2}>
        <Text bold>Result</Text>
      </Flex>
      {queryResultsNotFoundErrorMessage && (
        <Code copyButton className="ini" ContainerProps={{ sx: { m: 0 } }}>
          {"[Query Error]\n" + queryResultsNotFoundErrorMessage}
        </Code>
      )}
      {/* results are either in a Code or Markdown component */}
      {queryResults}
    </Grid2>
  );
};
