import { useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import { Role, ServiceAccount } from "~/lib/types";
import {
  Controller,
  ControllerRenderProps,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { CloseIcon } from "./icons";
import { ApiToken } from "~/operations";

export type EditRoleDialogProps = {
  open: boolean;
  roles: ServiceAccount["roles"] | ApiToken["roles"] | undefined;
  onClose: () => void;
  onConfirm: SubmitHandler<PermissionFormInput>;
  scope: "space" | "organization"; //the level at which these roles are being modified
};

export interface PermissionFormInput {
  viewer: boolean;
  editor: boolean;
  owner: boolean;
  agent: boolean;
}

const defaultValues = {
  viewer: false,
  editor: false,
  owner: false,
  agent: false,
};

export const formatRoleName = (role: keyof PermissionFormInput) => {
  switch (role) {
    default:
      return role;
  }
};

const parseRoleName = (role: string | undefined) => {
  if (!role) return;
  switch (role) {
    case "gateway-agent":
      return "gatewayAgent";
    default:
      return role;
  }
};

const parseExistingRoles = (roles: Role[] | undefined): PermissionFormInput => {
  if (!roles) return defaultValues;
  const existingRoles = roles.map((x) => parseRoleName(x.mrn.split("/").pop()));
  const currentValues = { ...defaultValues };
  Object.keys(defaultValues).map((key) => {
    existingRoles.forEach((existingRole) => {
      if (existingRole === key) {
        currentValues[existingRole as keyof PermissionFormInput] = true;
      }
    });
  });
  return currentValues;
};

export function EditRoleDialog({
  roles,
  onClose,
  onConfirm,
  scope,
  ...rest
}: EditRoleDialogProps) {
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful, isDirty },
  } = useForm<PermissionFormInput>({
    defaultValues,
  });

  // populate the form with the already assigned roles
  useEffect(() => {
    if (roles) {
      reset(parseExistingRoles(roles));
    }
  }, [roles]);

  // after successfully submitting the form, reset the form
  // to its defaults
  useEffect(() => {
    reset(defaultValues);
  }, [isSubmitSuccessful]);

  return (
    <Dialog
      id="edit-role-dialog"
      fullWidth
      maxWidth="md"
      {...{ ...rest, onClose }}
    >
      <Box id="edit-agent-role" sx={{ p: 5 }}>
        <Typography
          variant="h5"
          fontWeight={700}
          sx={{ textTransform: "uppercase", mb: 3 }}
        >
          Permissions
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Set the permissions for this service account according to your needs.
          Remember that removing permissions can result in features not working
          anymore.
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 16,
            top: 24,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent sx={{ pt: 0, px: 5, pb: 5 }}>
        <form onSubmit={handleSubmit(onConfirm)}>
          <Grid container spacing={2}>
            <Controller
              name="viewer"
              control={control}
              render={({ field }) => (
                <FormCheckbox
                  {...{ field }}
                  label="Viewer"
                  description={`View most content in ${scope}.`}
                />
              )}
            />
            <Controller
              name="editor"
              control={control}
              render={({ field }) => (
                <FormCheckbox
                  {...{ field }}
                  label="Editor"
                  description={`View, create, update, and delete assets, reports and members. Cannot delete the ${scope}.`}
                />
              )}
            />
            <Controller
              name="owner"
              control={control}
              render={({ field }) => (
                <FormCheckbox
                  {...{ field }}
                  label="Owner"
                  description={`Full access to all data in ${scope}.`}
                />
              )}
            />
            <Controller
              name="agent"
              control={control}
              render={({ field }) => (
                <FormCheckbox
                  {...{ field }}
                  label="Agent"
                  description="Ability to fetch and run polices and query packs and report its results."
                />
              )}
            />
          </Grid>
          <Box sx={{ display: "flex", placeContent: "center", mt: 4 }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isDirty}
            >
              Set Permissions
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
}

const FormCheckbox = ({
  field,
  label,
  description,
}: {
  field: ControllerRenderProps<PermissionFormInput, any>;
  label: string;
  description: string;
}) => {
  return (
    <Grid item xs={12} sm={6}>
      <FormControlLabel
        control={<Checkbox {...field} checked={field.value} />}
        {...{ label }}
      />
      <Typography variant="body2" color="text.secondary" sx={{ mt: -1, ml: 4 }}>
        {description}
      </Typography>
    </Grid>
  );
};
