import { NetworkStatus } from "@apollo/client";
import { useEffect } from "react";

export function useReportingPageLoaded(networkStatuses: NetworkStatus[]) {
  function isNetworkRequestReady(networkStatuses: NetworkStatus[]): boolean {
    return networkStatuses.every((status) => status === NetworkStatus.ready);
  }

  const allLoaded = isNetworkRequestReady(networkStatuses);

  useEffect(() => {
    window["reportingPageLoaded"] = false;
    if (allLoaded) {
      //wait 2 seconds
      setTimeout(() => {
        //set global variable
        window["reportingPageLoaded"] = allLoaded;
      }, 2000);
    }
  }, [allLoaded]);

  return { allLoaded };
}
