import { Fragment, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useAssetOutlet } from "~/pages/inventory/asset";
import { FilterBar } from "~/pages/compliance/filter-bar";
import { useSearch } from "~/components/search/useSearch";
import { AssetPolicyDistributionCard } from "./assetPolicyDistributionCard/assetPolicyDistributionCard";
import {
  AssetPolicyOverviewCard,
  AssetPolicyOverviewCardProps,
} from "./assetPolicyOverviewCard/assetPolicyOverviewCard";
import { PolicyCardGrid } from "../components/Policies";
import {
  INITIAL_PAGE_RANGE,
  Pagination,
  PaginationRange,
} from "~/components/pagination";
import { Loading, LoadingFailed } from "~/components/loading";
import { useSearchParams } from "react-router-dom";
import { EmptyState } from "~/components/empty-state/empty-state";
import { useLoadAssetListPoliciesQuery } from "~/operations";

export function AssetPoliciesTab() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { assetMrn, isCicd, space, scope } = useAssetOutlet();
  const { handleFilterQuery, searchFilters } = useSearch();
  const [pageItems, setPageItems] =
    useState<PaginationRange>(INITIAL_PAGE_RANGE);
  // gathering the current filters from the URL
  let url = new URL(window.location.href);
  let ratingFilters = url.searchParams.get("ratings")?.split(",") || [];
  let categoryFilter = url.searchParams.get("categories")?.split(",") || [];

  const hasFilters =
    ratingFilters.length > 0 ||
    categoryFilter.length > 0 ||
    searchFilters.length > 0;

  const { data, error, networkStatus } = useLoadAssetListPoliciesQuery({
    skip: !assetMrn,
    variables: {
      mrn: assetMrn,
      first: 50,
      after: null,
      filter: {
        categories: categoryFilter,
        ratings: ratingFilters,
        query: searchFilters.join(),
      },
    },
  });

  const policiesFetching = networkStatus === 1;
  const policiesRefetching = networkStatus === 2;
  const policiesLoading = policiesFetching || policiesRefetching;
  const policies = data?.asset?.listPolicies;
  const policyEdges = policies?.edges ?? [];
  const policyTotalCount = policies?.totalCount ?? 0;
  const hasPolicies = !policiesFetching && (hasFilters || policyTotalCount > 0);

  if (error && !policies) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          py: 12,
        }}
      >
        <LoadingFailed what="Asset Policies" />
      </Box>
    );
  }

  // set search params for the page size but do not overwrite the other search params
  const updateSearchParams = (param: string, value: string) => {
    let updatedSearchParams = new URL(window.location.href).searchParams;
    if (value === "") {
      updatedSearchParams.delete(param);
      setSearchParams(updatedSearchParams.toString());
    } else {
      updatedSearchParams.set(param, value.toString());
    }
    setSearchParams(updatedSearchParams.toString());
  };

  const handleCategoryFilterClick = (category: string) => {
    // If the category is "total", clear all filters
    // "total" is the same as "all categories"
    if (category === "total") {
      return updateSearchParams("categories", "");
    }
    if (categoryFilter.includes(category)) {
      updateSearchParams(
        "categories",
        categoryFilter.filter((c) => c !== category).join(","),
      );
    } else {
      updateSearchParams("categories", [...categoryFilter, category].join(","));
    }
  };

  const handleRatingFilterClick: AssetPolicyOverviewCardProps["onRatingFilterClick"] =
    (data) => {
      const url = new URL(window.location.href);
      const filters = url.searchParams.get("ratings")?.split(",") || [];

      let rating = data.rating;

      if (filters.includes(rating)) {
        updateSearchParams(
          "ratings",
          filters.filter((c) => c !== rating).join(","),
        );
      } else {
        updateSearchParams("ratings", [...filters, rating].join(","));
      }
    };

  return (
    <Fragment>
      {hasPolicies && (
        <Grid container item xs={12} columnSpacing={3} rowGap={3} my={3}>
          {/* Policy Overview */}
          <Grid item xs={12} md={4}>
            {policies && (
              <AssetPolicyOverviewCard
                onRatingFilterClick={handleRatingFilterClick}
                selectedRatings={searchParams.get("ratings")?.split(",") || []}
                assetMrn={assetMrn}
              />
            )}
          </Grid>
          {/* Policy Distribution */}
          <Grid item xs={12} md>
            <AssetPolicyDistributionCard
              assetMrn={assetMrn}
              sizes={{ xs: 12 }}
              handleCategoryFilterClick={handleCategoryFilterClick}
              selectedCategories={categoryFilter}
            />
          </Grid>
        </Grid>
      )}
      {/* Filter Search Bar */}
      {hasPolicies && (
        <Grid item xs={12}>
          <FilterBar
            searchId="inventory-asset-policies"
            placeholder="inventory_asset_policies"
            searchFilters={searchFilters}
            handleFilterQuery={handleFilterQuery}
            mb={0}
          />
        </Grid>
      )}

      {policiesLoading ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            placeContent: "center",
            py: 10,
          }}
        >
          <Loading what="Asset Policies" />
        </Box>
      ) : (
        <Fragment>
          {!hasPolicies && (
            <Box my={3}>
              <EmptyState contentType="asset-policies-tab" space={space} />
            </Box>
          )}

          {/* Policy Cards */}
          {hasPolicies && (
            <Fragment>
              <PolicyCardGrid
                space={space}
                scope={scope}
                policies={policyEdges.slice(pageItems.from, pageItems.to)}
                isCicd={isCicd}
              />
              <Pagination
                totalCount={policyTotalCount}
                setPageItems={setPageItems}
                customPageSizes={[6, 18, 24]}
              />
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
