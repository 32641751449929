import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  SxProps,
  Theme,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormatRelativeDate, FormatTime } from "~/lib/date";
import { Space } from "~/lib/types";
import { AccessTimeIcon } from "../icons";
import { AssetsEdge, AssetsPageInfo } from "./asset-explorer-tab";
import {
  INITIAL_PAGE_RANGE,
  Pagination,
  UsePaginationProps,
} from "../pagination";
import { ImpactCell } from "../Cells";

type Header = {
  id: string;
  label: string;
  options?: SxProps<Theme>;
};

type Props = {
  items: AssetsEdge[];
  pageInfo?: AssetsPageInfo;
  space: Space;
  fetchMore: UsePaginationProps["fetchMore"];
  totalCount: number;
};

export function ListView({
  items,
  pageInfo,
  space,
  fetchMore,
  totalCount,
}: Props) {
  const [pageItems, setPageItems] = useState(INITIAL_PAGE_RANGE);
  let navigate = useNavigate();

  const handleAssetClick = (id: string) => {
    navigate(`/space/inventory/${id}/overview?spaceId=${space.id}`);
  };

  const tableHeaders: Header[] = [
    {
      id: "ASSET_NAME",
      label: "Asset name",
      options: { textAlign: "left", pl: 4, width: "40%" },
    },
    {
      id: "PLATFORM",
      label: "Platform",
      options: { textAlign: "left", width: "20%" },
    },
    {
      id: "RISK",
      label: "Risk",
      options: { textAlign: "left" },
    },
    {
      id: "LAST_UPDATED",
      label: "Last updated",
      options: { textAlign: "left" },
    },
    {
      id: "CHILD_ASSETS_COUNT",
      label: "# Child Assets",
      options: { textAlign: "right", pr: 4 },
    },
  ];

  return (
    <Box className="explorer-list">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header) => (
                <TableCell key={header.id} sx={{ ...header.options }}>
                  {header.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.slice(pageItems.from, pageItems.to).map(({ node }) => {
              if (!node) return <></>;
              const formattedDate = node.updatedAt
                ? `${FormatRelativeDate(
                    node.updatedAt,
                  )} - ${FormatTime(node.updatedAt)}`
                : "Never";

              return (
                <TableRow
                  key={node.id}
                  onClick={() => handleAssetClick(node.id)}
                >
                  <TableCell sx={{ pl: 4 }}>{node.name}</TableCell>
                  <TableCell>{node.platform?.title}</TableCell>
                  <ImpactCell
                    rating={node.score.riskRating}
                    impact={node.score.riskValue}
                    isActive
                  />
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <AccessTimeIcon fontSize="small" sx={{ mr: 1.25 }} />
                      {formattedDate}
                    </Box>
                  </TableCell>
                  <TableCell align="right" sx={{ pr: 4 }}>
                    n/a
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        fetchMore={fetchMore}
        pageInfo={pageInfo}
        setPageItems={setPageItems}
        totalCount={totalCount}
      />
    </Box>
  );
}
