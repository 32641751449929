import { createSvgIcon } from "@mui/material";

export const ElbowIcon = createSvgIcon(
  <svg viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.666667 0C0.298477 0 0 0.298476 0 0.666666V6.66667V7C0 7.55228 0.447716 8 1 8H1.33333H7.33333C7.70152 8 8 7.70152 8 7.33333C8 6.96514 7.70152 6.66667 7.33333 6.66667L2.33333 6.66667C1.78105 6.66667 1.33333 6.21895 1.33333 5.66667L1.33333 0.666667C1.33333 0.298477 1.03486 0 0.666667 0Z"
      fill="#454545"
    />
  </svg>,
  "",
);
