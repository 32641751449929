import { IosShare } from "@mui/icons-material";
import {
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButtonProps,
} from "@mui/material";
import { useState } from "react";
import { EmailIcon } from "~/components/icons";
import { CopyLinkIcon } from "~/components/ui-library";
import { copyToClipboard } from "~/lib/clipboard";

type ShareFindingProps = { IconButtonProps?: IconButtonProps };

export function ShareFinding({ IconButtonProps }: ShareFindingProps) {
  const [shareAnchorEl, setShareAnchorEl] = useState<null | HTMLElement>(null);
  const shareOpen = Boolean(shareAnchorEl);

  const shareLink = window.location.href;
  const shareEmailLink = `mailto:hello@mondoo.com?subject=${encodeURIComponent("Take a look at this finding")}&body=${encodeURIComponent(shareLink)}`;

  return (
    <>
      <Tooltip title="Share" placement="top" arrow>
        <IconButton
          onClick={(e) => setShareAnchorEl(e.currentTarget)}
          {...IconButtonProps}
        >
          <IosShare />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={shareAnchorEl}
        open={shareOpen}
        onClose={() => setShareAnchorEl(null)}
        onClick={() => setShareAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem onClick={() => copyToClipboard(shareLink)}>
          <ListItemIcon>
            <CopyLinkIcon fontSize="inherit" />
          </ListItemIcon>
          <ListItemText>Copy link</ListItemText>
        </MenuItem>
        <MenuItem component="a" href={shareEmailLink}>
          <ListItemIcon>
            <EmailIcon fontSize="inherit" />
          </ListItemIcon>
          <ListItemText>Email</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
