import { ChangeEvent, Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  CheckboxProps,
  IconButton,
  InputAdornment,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { ProjectsSplash } from "~/components/projects-splash-screen";
import { Space } from "~/lib/types";
import {
  LoadCicdProjectsDocument,
  TestIamActionsQuery,
  useDeleteCicdProjectsMutation,
  useLoadCicdProjectsQuery,
} from "~/operations";
import { AddButton } from "~/components/add-button";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import { CicdBreadcrumbs } from "~/components/cicd/cicd-breadcrumbs";
import { DataTable, SelectionToolbar } from "~/components/data-table";
import ProviderGithubLogo from "~/images/icons/provider-github.svg";
import ProviderGitlabLogo from "~/images/icons/provider-gitlab.svg";
import ProviderKubernetesLogo from "~/images/icons/provider-kubernetes.svg";
import ProviderAzureDevOpsLogo from "~/images/icons/provider-azure-devops.svg";
import ProviderJenkinsLogo from "~/images/icons/provider-jenkins.svg";
import { SearchIcon } from "~/components/icons";
import { useSnackbar } from "notistack";
import { pluralize } from "~/lib/pluralize";
import { IamActions } from "~/lib/iam";
import ProviderCircleCILogo from "~/images/icons/provider-circle-ci.svg";
import { setDocumentTitle } from "~/utils/commonUtils";

type Props = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function Projects({ space, availablePermissions }: Props) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [searchText, setSearchText] = useState("");
  const [batchSelections, setBatchSelections] = useState<string[]>([]);

  const hasDeleteProjectPermissions = availablePermissions?.includes(
    IamActions.ASSETS_DELETECICDPROJECT,
  );

  const { data, error, loading } = useLoadCicdProjectsQuery({
    variables: { input: { spaceMrn: space.mrn } },
  });

  const [deleteProjects] = useDeleteCicdProjectsMutation({
    variables: { input: { mrns: batchSelections } },
    refetchQueries: [LoadCicdProjectsDocument],
  });

  const logoForType = (type: string) => {
    switch (type) {
      case "actions.github.com":
        return ProviderGithubLogo;
      case "gitlab.com":
        return ProviderGitlabLogo;
      case "circleci.com":
        return ProviderCircleCILogo;
      case "devops.azure.com":
        return ProviderAzureDevOpsLogo;
      case "k8s.mondoo.com":
        return ProviderKubernetesLogo;
      case "jenkins.io":
        return ProviderJenkinsLogo;
      default:
        return "shouldn't happen but should probably have a generic fallback";
    }
  };

  const navToJobs = (projectId: string) => {
    navigate(`/space/cicd/jobs?spaceId=${space.id}&projectId=${projectId}`);
  };

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleDeleteClick = async () => {
    const total = batchSelections.length;
    try {
      await deleteProjects();
      resetEditing();
      enqueueSnackbar(
        `Successfully removed ${total} ${pluralize("project", total)}`,
        { variant: "success" },
      );
    } catch (error) {
      enqueueSnackbar(`Failed to remove ${pluralize("project", total)}`, {
        variant: "error",
      });
    }
  };

  const handleCancelClick = () => {
    resetEditing();
  };

  const resetEditing = () => {
    setBatchSelections([]);
  };

  const handleCheck = (
    _event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    mrn: string,
  ) => {
    if (checked) {
      return setBatchSelections((prev) => [...prev, mrn]);
    }
    const removed = batchSelections.filter((selection) => mrn !== selection);
    setBatchSelections(removed);
  };

  const handleAllNodeChange: CheckboxProps["onChange"] = (e, checked) => {
    if (batchSelections.length === 0) {
      setBatchSelections(filteredProjects.map((p) => p.node.mrn));
    } else {
      setBatchSelections([]);
    }
  };

  const breadcrumbs = [<Typography key="1">CI/CD</Typography>];

  if (loading) return <LoadingPage what="CI/CD Projects" />;

  if (error || !data) return <LoadingFailedPage what="CI/CD Projects" />;

  // If a user does not have any projects yet, we will show them a splash screen
  // with instructions on how to integrate a new project
  const noProjects = data.cicdProjects?.projects?.totalCount! < 1;

  const allProjects = data.cicdProjects?.projects.edges || [];

  const filteredProjects = allProjects.filter((edge) => {
    const text = `${edge.node.name}`;
    return text.includes(searchText);
  });

  const totalProjects = data.cicdProjects?.projects.totalCount;

  setDocumentTitle("CI/CD");

  return (
    <Box id="projects-view">
      <CicdBreadcrumbs scope={space} trail={breadcrumbs} />
      <Box
        id="projects-header"
        mb={3}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Typography
          variant="h2"
          fontWeight="bold"
          sx={{ fontSize: 32, lineHeight: "48px" }}
        >
          CI/CD
        </Typography>
        <AddButton
          id="cicd-project"
          href={`/space/integrations/add?spaceId=${space.id}#cicd`}
          aria-label="Add Project"
        />
      </Box>

      {noProjects ? (
        <ProjectsSplash spaceId={space.id} />
      ) : (
        <Fragment>
          <Box
            id="projects-search"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 4,
            }}
          >
            <TextField
              placeholder="Search for repos, clusters, ..."
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon sx={{ color: "#AAAABF" }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={onSearchChange}
            />
          </Box>

          <DataTable
            id="projects-list"
            selectable={hasDeleteProjectPermissions}
            selection={batchSelections}
          >
            <TableHead>
              <TableRow>
                {hasDeleteProjectPermissions && (
                  <TableCell>
                    <Checkbox
                      onChange={handleAllNodeChange}
                      checked={
                        batchSelections.length > 0 &&
                        batchSelections.length === totalProjects
                      }
                      indeterminate={
                        batchSelections.length > 0 &&
                        batchSelections.length !== totalProjects
                      }
                    />
                  </TableCell>
                )}
                <TableCell colSpan={2}>Project</TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                ".project-logo-cell": {
                  width: (theme) => parseInt(theme.spacing(5)),
                  pr: 0,

                  img: { display: "block", width: "100%" },
                },
              }}
            >
              {filteredProjects?.map((edge) => {
                const isSelected = batchSelections.includes(edge.node.mrn);
                const className = isSelected ? "selected" : "";
                return (
                  <TableRow
                    className={"projects-list-row " + className}
                    key={edge.node.mrn}
                    onClick={() => navToJobs(edge.node.id)}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    {hasDeleteProjectPermissions && (
                      <TableCell>
                        <Checkbox
                          onChange={(e, checked) =>
                            handleCheck(e, checked, edge.node.mrn)
                          }
                          onClick={(e) => e.stopPropagation()}
                          checked={isSelected}
                        />
                      </TableCell>
                    )}
                    <TableCell className="project-logo-cell">
                      <img src={logoForType(edge.node.type)} />
                    </TableCell>
                    <TableCell className="project-name-cell">
                      {edge.node.name}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </DataTable>
          {batchSelections.length > 0 && (
            <SelectionToolbar>
              <Typography>
                Selected {batchSelections.length} of {totalProjects} projects
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handleDeleteClick}
              >
                Delete
              </Button>
              <Button onClick={handleCancelClick}>Cancel</Button>
            </SelectionToolbar>
          )}
        </Fragment>
      )}
    </Box>
  );
}
