import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  FindingType,
  ScoreStateFilter,
  useGetAssetPackagesStatsQuery,
} from "~/operations";

type UseFilterPackagesParams = {
  assetMrn?: string;
};

export function useFilterPackages({ assetMrn }: UseFilterPackagesParams) {
  const navigate = useNavigate();
  const [searchParams, _setSearchParams] = useSearchParams();

  const [filter, setFilter] = React.useState<
    keyof typeof FindingType | string | null
  >(() => searchParams.get("packageType"));

  const {
    data: { assetPackagesStats } = {
      assetPackagesStats: { osPackagesCount: 0, appsCount: 0 },
    },
    error,
    loading,
  } = useGetAssetPackagesStatsQuery({
    variables: {
      input: {
        mrn: assetMrn || "",
        state: ScoreStateFilter.Open,
      },
    },
    skip: !assetMrn,
  });

  React.useEffect(() => {
    const nextSearchFilters = searchParams.get("packageType");
    if (nextSearchFilters) {
      setFilter(nextSearchFilters);
    } else {
      setFilter(null);
    }
  }, [searchParams]);

  const displayedFilterTypes = [
    {
      id: FindingType.OsPackage,
      title: "Operating System Packages",
      count: `${assetPackagesStats?.osPackagesCount} Packages`,
    },
    {
      id: FindingType.AppPackage,
      title: "Applications",
      count: `${assetPackagesStats?.appsCount} Applications`,
    },
  ];

  const handleFilter = (packageType: FindingType) => {
    if (filter === packageType) {
      searchParams.delete("packageType");
    } else {
      searchParams.set("packageType", packageType);
    }

    navigate(`${location.pathname}?${searchParams}`);
  };

  return {
    displayedFilterTypes,
    filter: filter as FindingType,
    handleFilter,
  };
}
