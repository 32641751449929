import { ReactNode } from "react";
import { Grid2 } from "@mui/material";
import { StatItem } from "./StatItem";

export type Stat = {
  label: string;
  value: string;
  icon: ReactNode;
  onClick?: () => void;
};

export type StatsProps = {
  stats: Stat[];
  id?: string;
};

export function Stats({ stats, id }: StatsProps) {
  return (
    <Grid2
      container
      id={id}
      className="asset-stats"
      columnSpacing={2}
      rowSpacing={1}
    >
      {stats.map((stat) => {
        return (
          <Grid2 key={stat.label}>
            <StatItem
              icon={stat.icon}
              value={stat.value}
              label={stat.label}
              onClick={stat.onClick}
            />
          </Grid2>
        );
      })}
    </Grid2>
  );
}
