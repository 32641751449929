import { Box, ButtonProps, IconButton, MenuProps } from "@mui/material";
import { Fragment, useState } from "react";
import { CheckIcon, MoreVertIcon } from "~/components/icons";
import { LoadingButton } from "~/components/loading-button";
import {
  PolicyAction,
  useAssignPolicyMutation,
  useUnassignPolicyMutation,
} from "~/operations";
import {
  AssignmentOption,
  options,
  PolicyAssignMenu,
} from "./PolicyAssignMenu";

export type PolicyAssignButtonProps = {
  spaceMrn: string;
  policyMrn: string;
  assigned: boolean;
  action?: PolicyAction | null;
  onChange?: (action?: PolicyAction) => void;
  advanced?: boolean;
};

export function PolicyAssignButton({
  spaceMrn,
  policyMrn,
  assigned,
  action,
  onChange,
  advanced,
}: PolicyAssignButtonProps) {
  const [assignPolicy, assignResult] = useAssignPolicyMutation();
  const [unassignPolicy, unassignResult] = useUnassignPolicyMutation();
  const toggleAssign = assigned ? unassignPolicy : assignPolicy;
  const loading = unassignResult.loading || assignResult.loading;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const selectedOption = options.find((o) => o.action === action);

  const handleToggleClick: ButtonProps["onClick"] = async (e) => {
    await toggleAssign({
      variables: {
        input: {
          assetMrn: spaceMrn,
          policyMrn,
          action,
        },
      },
    });

    onChange?.();
  };

  const handleMenuOpen: ButtonProps["onClick"] = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuItemClick = async (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    option: AssignmentOption,
  ) => {
    setAnchorEl(null);

    if (option.action !== selectedOption?.action) {
      await assignPolicy({
        variables: {
          input: {
            assetMrn: spaceMrn,
            policyMrn,
            action: option.action,
          },
        },
      });

      onChange?.();
    }
  };

  const handleMenuClose: MenuProps["onClose"] = (e) => {
    setAnchorEl(null);
  };

  return (
    <Box onClick={(e) => e.stopPropagation()} sx={{ whiteSpace: "nowrap" }}>
      <LoadingButton
        variant="outlined"
        color={assigned ? "excellent" : "primary"}
        size="small"
        sx={{
          whiteSpace: "nowrap",
        }}
        startIcon={assigned ? <CheckIcon fontSize="inherit" /> : undefined}
        onClick={handleToggleClick}
        buttonText={assigned ? "Added" : "Add to space"}
        loading={loading}
      />
      {advanced && (
        <Fragment>
          <IconButton
            onClick={handleMenuOpen}
            sx={{ p: 0.25, ml: 2 }}
            color="primary"
          >
            <MoreVertIcon />
          </IconButton>
          <PolicyAssignMenu
            {...{
              advanced,
              anchorEl,
              open,
              handleMenuClose,
              selectedOption,
              handleMenuItemClick,
            }}
          />
        </Fragment>
      )}
    </Box>
  );
}
