import { Fragment, useEffect, useState } from "react";
import {
  Box,
  Button,
  Link,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Dropzone, DropzoneProps, DropzoneText } from "~/components/dropzone";
import { ChevronRightIcon, OpenInNewIcon } from "~/components/icons";
import { Command } from "~/components/guides/components";
import { Space } from "~/lib/types";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  ActionType,
  ClientIntegrationType,
  GetClientIntegrationDocument,
  GetIntegrationsSummaryDocument,
  IntegrationType,
  useCreateClientIntegrationMutation,
  useTriggerActionLazyQuery,
  useUpdateClientIntegrationConfigurationMutation,
} from "~/operations";
import { useSnackbar } from "notistack";
import { RecommendedPolicies } from "../../components/recommended-policies";
import { GreenCheckCircleIcon } from "~/pages/invitation";
import { AnimatePresence, motion } from "framer-motion";
import { fadeInOut } from "~/lib/animations";
import { useNavigate } from "react-router-dom";

import { IntegrationAddHeader } from "../../headers/integration-add-header";
import { getError } from "~/lib/handle-error";
import {
  GUID_PATTERN,
  helperTextStyles,
  ID_PLACEHOLDER,
  MATCHING_ID_ERROR_MESSAGE,
  ValidationMessage,
} from "../../validations/helpers";
import useGenerateIntegrationName from "../../utils/useGenerateIntegrationName";
import { UpdateFlowData } from "../../types";
import { setDocumentTitle } from "~/utils/commonUtils";

type CloudFormInput = {
  integrationName: string;
  tenantId: string;
  clientId: string;
  pemKey: string | null;
};

const defaultValues: CloudFormInput = {
  integrationName: "",
  tenantId: "",
  clientId: "",
  pemKey: null,
};

export function Ms365IntegrationForm({
  space,
  updateFlow,
}: {
  space: Space;
  updateFlow?: UpdateFlowData;
}) {
  let navigate = useNavigate();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [view, setView] = useState<"setup" | "add policies">("setup");
  const defaultIntegrationName = useGenerateIntegrationName({ space });

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    trigger,
    getFieldState,
    formState: { errors, isValid, isSubmitSuccessful },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      integrationName: defaultIntegrationName,
    },
  });

  const [tenantId, clientId, pemKey] = watch([
    "tenantId",
    "clientId",
    "pemKey",
  ]);

  const [createIntegration] = useCreateClientIntegrationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      const integrationMrn = data.createClientIntegration.integration.mrn;
      triggerClientIntegrationScan({
        variables: { input: { mrn: integrationMrn, type: ActionType.RunScan } },
      });
    },
    refetchQueries: [
      {
        query: GetIntegrationsSummaryDocument,
        variables: { input: { spaceMrn: space.mrn } },
      },
    ],
  });

  const [updateIntegration] = useUpdateClientIntegrationConfigurationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    refetchQueries: [
      {
        query: GetClientIntegrationDocument,
        variables: {
          mrn: `//integration.api.mondoo.app/spaces/${
            space.id
          }/integrations/${updateFlow?.integration.mrn.split("/").pop()}`,
        },
      },
    ],
  });

  const [triggerClientIntegrationScan] = useTriggerActionLazyQuery({
    onError(error) {
      console.log("%c Error Scheduling scan on creation", "color: tomato");
      console.log(error.message);
    },
  });

  useEffect(() => {
    if (updateFlow) {
      if (
        updateFlow.integration.configurationOptions?.__typename !==
        "Ms365ConfigurationOptions"
      )
        return;
      reset({
        integrationName: updateFlow.integration.name,
        tenantId: updateFlow.integration.configurationOptions?.tenantId,
        clientId: updateFlow.integration.configurationOptions?.clientId,
      });
    }
  }, []);

  useEffect(() => {
    if (isSubmitSuccessful && !updateFlow) {
      reset(defaultValues);
    }
  }, [isSubmitSuccessful]);

  useEffect(() => {
    trigger("clientId");
  }, [tenantId]);

  useEffect(() => {
    trigger("tenantId");
  }, [clientId]);

  const onSubmit: SubmitHandler<CloudFormInput> = async (data) => {
    if (!updateFlow && !data.pemKey) {
      throw new Error("You must supply a valid .pem certificate");
    }

    const ms365ConfigurationOptions = {
      tenantId: data.tenantId,
      clientId: data.clientId,
      ...(updateFlow ? {} : { certificate: data.pemKey }),
    };

    try {
      if (updateFlow) {
        await updateIntegration({
          variables: {
            input: {
              name: data.integrationName.trim(),
              mrn: `//integration.api.mondoo.app/spaces/${
                space.id
              }/integrations/${updateFlow?.integration.mrn.split("/").pop()}`,
              type: ClientIntegrationType.Ms365,
              configurationOptions: {
                ms365ConfigurationOptions,
              },
            },
          },
        });
        const integrationId = updateFlow.integration.mrn.split("/").pop();
        enqueueSnackbar("Successfully updated configuration", {
          variant: "success",
        });
        navigate(
          `/space/integrations/ms365/${integrationId}/?spaceId=${space.id}`,
        );
      } else {
        await createIntegration({
          variables: {
            input: {
              spaceMrn: space.mrn,
              name: data.integrationName,
              type: ClientIntegrationType.Ms365,
              longLivedToken: false,
              configurationOptions: {
                ms365ConfigurationOptions,
              },
            },
          },
        });
        setView("add policies");
      }
    } catch (e) {
      const msg = getError(e);
      enqueueSnackbar(msg, { variant: "error" });
    }
  };

  const onDropAccepted: DropzoneProps["onDropAccepted"] = async (files) => {
    setValue("pemKey", await files[0].text());
  };

  setDocumentTitle(["MS365", "Integrations Setup"]);

  return (
    <Fragment>
      {view === "setup" ? (
        <Fragment>
          <IntegrationAddHeader {...{ type: IntegrationType.Ms365 }} />
          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* Step 1 */}
              <Box pb={4}>
                <Command
                  number={1}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  Create your Microsoft app registration
                </Command>

                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2, mt: 2 }}
                  >
                    Before you can integrate your Microsoft account with any
                    third party service,must create an app registration with the
                    proper permissions. You can do this in the Microsoft Entra
                    admin center. To learn more, read the{" "}
                    <Link
                      href="https://learn.microsoft.com/en-us/security/zero-trust/develop/app-registration"
                      target="_blank"
                      rel="noopener"
                    >
                      Microsoft documentation
                    </Link>
                    .
                  </Typography>

                  <Button
                    href="https://aad.portal.azure.com"
                    target="_blank"
                    variant="outlined"
                    color="secondary"
                    endIcon={<OpenInNewIcon />}
                  >
                    Go to Entra admin center
                  </Button>
                </Box>
              </Box>
              {/* Step 2 */}
              <Box pb={4}>
                <Command
                  number={2}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  Choose an integration name
                </Command>

                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2, mt: 2 }}
                  >
                    Please choose a descriptive name that lets you easily
                    identify your integration.
                  </Typography>
                  <Controller
                    name="integrationName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        sx={{
                          background: theme.palette.code.background,
                          borderRadius: 1,
                          color: "text.primary",
                          ...helperTextStyles,
                        }}
                        placeholder="Your integration name..."
                        error={Boolean(errors.integrationName)}
                        helperText={
                          Boolean(errors.integrationName) && (
                            <ValidationMessage error={errors.integrationName} />
                          )
                        }
                      />
                    )}
                  />
                </Box>
              </Box>

              {/* step 3 */}
              <Box pb={4}>
                <Command
                  number={3}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  Enter the Tenant (Directory) ID
                </Command>
                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2, mt: 2 }}
                  >
                    Find it on the{" "}
                    <Link
                      href="https://aad.portal.azure.com"
                      target="_blank"
                      rel="noopener"
                    >
                      Microsoft Entra admin center
                    </Link>
                    's Tenant page: On the Overview tab, it's under Basic
                    information.
                  </Typography>
                  <Controller
                    name="tenantId"
                    control={control}
                    rules={{
                      required: true,
                      pattern: GUID_PATTERN,
                      validate: {
                        matchingIds: (value: CloudFormInput["tenantId"]) =>
                          value !== clientId || MATCHING_ID_ERROR_MESSAGE,
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        sx={{
                          background: theme.palette.code.background,
                          borderRadius: 1,
                          color: "text.primary",
                          ...helperTextStyles,
                        }}
                        placeholder={ID_PLACEHOLDER}
                        error={
                          getFieldState("tenantId").isTouched &&
                          Boolean(errors.tenantId)
                        }
                        helperText={
                          Boolean(errors.tenantId) &&
                          getFieldState("tenantId").isTouched && (
                            <ValidationMessage error={errors.tenantId} />
                          )
                        }
                      />
                    )}
                  />
                </Box>
              </Box>
              {/* step 4 */}
              <Box pb={4}>
                <Command
                  number={4}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  Enter the application (client) ID
                </Command>
                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2, mt: 2 }}
                  >
                    Find it in the Application (client) ID box on the “Overview”
                    page of your app registration. To learn more, read the{" "}
                    <Link
                      href="https://learn.microsoft.com/en-us/azure/healthcare-apis/register-application?wt.mc_id=searchAPI_azureportal_inproduct_rmskilling&sessionId=43aa24014475423fb9fdd085ba970015#application-id-client-id"
                      target="_blank"
                      rel="noopener"
                    >
                      Microsoft documentation
                    </Link>
                    .
                  </Typography>
                  <Controller
                    name="clientId"
                    control={control}
                    rules={{
                      required: true,
                      pattern: GUID_PATTERN,
                      validate: {
                        matchingIds: (value: CloudFormInput["clientId"]) =>
                          value !== tenantId || MATCHING_ID_ERROR_MESSAGE,
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        sx={{
                          background: theme.palette.code.background,
                          borderRadius: 1,
                          color: "text.primary",
                          ...helperTextStyles,
                        }}
                        placeholder={ID_PLACEHOLDER}
                        error={
                          getFieldState("clientId").isTouched &&
                          Boolean(errors.clientId)
                        }
                        helperText={
                          Boolean(errors.clientId) &&
                          getFieldState("clientId").isTouched && (
                            <ValidationMessage error={errors.clientId} />
                          )
                        }
                      />
                    )}
                  />
                </Box>
              </Box>
              {/* When in the updateFlow, we do not want to update the certificate */}
              {/* step 5 */}
              {!updateFlow && (
                <Box pb={4}>
                  <Command
                    number={5}
                    options={{
                      fontSize: { xs: 16 },
                      dotColor: theme.palette.background.lightest,
                    }}
                  >
                    Provide your certificate and private key
                  </Command>
                  <Box>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mb: 2, mt: 2 }}
                    >
                      Upload a valid PEM file containing your certificate and
                      private key. To learn more, read the{" "}
                      <Link
                        href="https://mondoo.com/docs/platform/infra/saas/ms365/ms365-auto/"
                        target="_blank"
                        rel="noopener"
                      >
                        Mondoo documentation
                      </Link>
                      .
                    </Typography>
                    <AnimatePresence mode="wait">
                      {!pemKey ? (
                        <Box
                          key="dropzone"
                          component={motion.div}
                          {...fadeInOut}
                        >
                          <Dropzone
                            className="mondoo-dropzone-area"
                            accept={{
                              "application/x-x509-ca-cert": [".pem"],
                              "application/x-x509-user-cert": [".pem"],
                              "application/x-pkcs12": [".pem"],
                              "application/x-pem-file": [".pem"],
                            }}
                            multiple={false}
                            showAlerts={["error"]}
                            onDropAccepted={onDropAccepted}
                          >
                            <DropzoneText>
                              Drag and drop your .pem file here.
                            </DropzoneText>
                          </Dropzone>
                        </Box>
                      ) : (
                        <Box
                          key="success"
                          component={motion.div}
                          {...fadeInOut}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            py: 5,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 2,
                            }}
                          >
                            <Typography variant="h6">
                              Successfully loaded certificate
                            </Typography>
                            <GreenCheckCircleIcon
                              fontSize="large"
                              sx={{ ml: 2 }}
                            />
                          </Box>

                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => setValue("pemKey", "")}
                          >
                            Remove certificate
                          </Button>
                        </Box>
                      )}
                    </AnimatePresence>
                  </Box>
                </Box>
              )}

              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  endIcon={<ChevronRightIcon />}
                  // setValue doesn't trigger the required validation so we're doing it manually here
                  disabled={updateFlow ? !isValid : !isValid || !pemKey}
                >
                  {updateFlow ? "update configuration" : "start scanning"}
                </Button>
              </Box>
            </form>
          </Box>
        </Fragment>
      ) : (
        <RecommendedPolicies {...{ space }} filterTypes={["microsoft365"]} />
      )}
    </Fragment>
  );
}
