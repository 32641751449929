import {
  CaseStatus,
  PageInfo,
  TestIamActionsQuery,
  useGetCasesQuery,
  useGetTicketingIntegrationsQuery,
} from "~/operations";
import { Space } from "~/lib/types";
import { IamActions } from "~/lib/iam";
import { ListCaseEdges } from "~/components/cases/types";
import { TabOption } from "~/components/ui-library";
import React, { useCallback } from "react";
import { CASES_TABS } from "~/components/cases/constants";
import { getAssetMrn } from "~/utils/urlUtils";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";

type UseCasesPageProps = {
  space: Space;
  scope: SpaceOrWorkspaceScope;
  availablePermissions: TestIamActionsQuery["testIamActions"];
  assetId: string | null;
};

type UseCasesPageReturn = {
  closedCases: ListCaseEdges;
  openCases: ListCaseEdges;
  hasTicketingIntegration: boolean;
  hasCloseCasePermissions: boolean;
  hasCreateCasePermissions: boolean;
  hasIntegrationCreatePermission: boolean;
  closedCasesDataLoading: boolean;
  openCasesDataLoading: boolean;
  showCreateFirstCaseScreen: boolean;
  showCreateTicketingIntegrationScreen: boolean;
  hasCases: boolean;
  tabList: TabOption[];
  currentTab: number;
  fetchMoreClosedCases: ReturnType<typeof useGetCasesQuery>["fetchMore"];
  fetchMoreOpenCases: ReturnType<typeof useGetCasesQuery>["fetchMore"];
  openCasesTotalCount: number;
  closedCasesTotalCount: number;
  openCasesPageInfo: PageInfo | undefined;
  closedCasesPageInfo: PageInfo | undefined;
  onTabChange: (_event: React.SyntheticEvent, newValue: number) => void;
};

export function useCasesPage({
  space,
  scope,
  availablePermissions,
  assetId,
}: UseCasesPageProps): UseCasesPageReturn {
  const [currentTab, setCurrentTab] = React.useState(CASES_TABS.OPEN);

  const hasCloseCasePermissions = availablePermissions.includes(
    IamActions.ACTION_MONDOO_POLICY_EXTENDEDHUB_UPDATECASE,
  );

  const hasCreateCasePermissions = availablePermissions.includes(
    IamActions.ACTION_MONDOO_POLICY_EXTENDEDHUB_CREATECASE,
  );

  const hasIntegrationCreatePermission = availablePermissions?.includes(
    IamActions.INTEGRATIONS_INTEGRATIONSMANAGER_CREATE,
  );

  const handleChangeTab = useCallback(
    (_event: React.SyntheticEvent, newValue: number) => {
      setCurrentTab(newValue);
    },
    [],
  );

  const assetMrn = getAssetMrn(space.id, assetId);

  const {
    data: closedCasesData,
    loading: closedCasesDataLoading,
    fetchMore: fetchMoreClosedCases,
  } = useGetCasesQuery({
    variables: {
      first: 10,
      input: {
        scopeMrn: scope.mrn,
        statuses: [CaseStatus.Closed],
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const {
    data: openCasesData,
    loading: openCasesDataLoading,
    fetchMore: fetchMoreOpenCases,
  } = useGetCasesQuery({
    variables: {
      first: 10,
      input: {
        contextMrn: scope.mrn,
        scopeMrn: assetMrn || scope.mrn,
        statuses: [CaseStatus.Open],
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const { data: ticketIntegrationsData } = useGetTicketingIntegrationsQuery({
    variables: {
      input: {
        spaceMrn: space.mrn,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const hasTicketingIntegration =
    (ticketIntegrationsData?.ticketingIntegrations?.integrations.length || 0) >
    0;

  const closedCases = closedCasesData?.cases?.edges || [];
  const closedCasesPageInfo = closedCasesData?.cases?.pageInfo;
  const closedCasesTotalCount = closedCasesData?.cases?.totalCount || 0;
  const openCases = openCasesData?.cases?.edges || [];
  const openCasesPageInfo = openCasesData?.cases?.pageInfo;
  const openCasesTotalCount = openCasesData?.cases?.totalCount || 0;

  const showCreateFirstCaseScreen =
    closedCases.length === 0 &&
    openCases.length === 0 &&
    hasTicketingIntegration &&
    !openCasesDataLoading &&
    !closedCasesDataLoading;

  const showCreateTicketingIntegrationScreen =
    !hasTicketingIntegration &&
    closedCases.length === 0 &&
    openCases.length === 0 &&
    !closedCasesDataLoading &&
    !openCasesDataLoading;

  const hasCases = closedCases.length > 0 || openCases.length > 0;

  const tabList: TabOption[] = [
    { label: `Open Tickets (${openCasesTotalCount})`, value: "open" },
    { label: `Closed Tickets (${closedCasesTotalCount})`, value: "closed" },
  ];

  return {
    closedCases,
    openCases,
    hasTicketingIntegration,
    hasCloseCasePermissions,
    hasCreateCasePermissions,
    hasIntegrationCreatePermission,
    openCasesDataLoading,
    closedCasesDataLoading,
    showCreateFirstCaseScreen,
    showCreateTicketingIntegrationScreen,
    hasCases,
    tabList,
    currentTab,
    onTabChange: handleChangeTab,
    fetchMoreClosedCases,
    closedCasesTotalCount,
    closedCasesPageInfo,
    fetchMoreOpenCases,
    openCasesPageInfo,
    openCasesTotalCount,
  };
}
