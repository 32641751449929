import { useFieldArray, useFormContext } from "react-hook-form";
import {
  getInitialCondition,
  RiskFactorsConfigurationInput,
} from "../../RiskFactorsConfigurationForm";
import { isConditionComplete } from "../Condition/hooks/useConditionRow";

export function useQueryBuilder(fieldId: string) {
  const { control, watch } = useFormContext<RiskFactorsConfigurationInput>();

  const { fields, append, remove } =
    useFieldArray<RiskFactorsConfigurationInput>({
      control,
      name: `${fieldId}.selections`,
    });

  const handleAddNewSelection = () => {
    append({
      conditions: [getInitialCondition()],
    });
  };

  const selections = watch(`${fieldId}.selections`) || [];

  const handleRemoveSelection = (conditionIndex: number) =>
    remove(conditionIndex);

  return {
    canAddSelection: selections.every((selection) =>
      selection.conditions.every(isConditionComplete),
    ),
    handleAddNewSelection,
    handleRemoveSelection,
    fields,
  };
}
