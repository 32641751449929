import { IosShare } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs,
  Divider,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Fragment, useState } from "react";
import {
  Link as RouterLink,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { DataTable } from "~/components/data-table";
import { ExpandMoreIcon, HomeIcon } from "~/components/icons";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import { PolicyDownloadButton } from "~/components/policy-download-button";
import { Flex } from "~/components/Flex";
import {
  CopyLinkIcon,
  EmailIcon,
  TabsVariant,
  VariantIcon,
} from "~/components/ui-library";
import { copyToClipboard } from "~/lib/clipboard";
import { PolicyIdToMrn } from "~/lib/mrn";
import { Space } from "~/lib/types";
import {
  TestIamActionsQuery,
  TrustLevel,
  useGetQueryPackQueriesQuery,
  useGetQueryPackQuery,
} from "~/operations";
import { Caption } from "../security/policies/components/Caption";
import { PolicyAssignButton } from "../security/policies/components/PolicyAssignButton";
import { Markdown } from "~/components/markdown";
import { LayoutSwitch } from "~/components/LayoutSwitch/LayoutSwitch";
import { ViewType } from "~/components/view-switcher";
import { pluralize } from "~/lib/pluralize";
import { ShowMoreSummary } from "~/components/ShowMore/ShowMoreSummary";
import { PolicyDeleteButton } from "~/components/policy-gql/PolicyDeleteButton";
import { usePolicyPermissions } from "../security/policies/hooks/usePolicyPermissions";
import { ScopeType, SpaceOrWorkspaceScope } from "~/hooks/useScope";
import { setDocumentTitle } from "~/utils/commonUtils";

export type QueryPackPageProps = {
  space: Space;
  scope: SpaceOrWorkspaceScope;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function QueryPackPage({
  space,
  scope,
  availablePermissions,
}: QueryPackPageProps) {
  const { policyId = "" } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { queryPackAssignPermission } = usePolicyPermissions({
    availablePermissions,
  });
  const [shareAnchorEl, setShareAnchorEl] = useState<null | HTMLElement>(null);
  const shareOpen = Boolean(shareAnchorEl);

  const mrn = PolicyIdToMrn(policyId);
  const selectedVariantId = searchParams.get("variant");

  const { data, loading, error, refetch } = useGetQueryPackQuery({
    variables: { input: { mrn, spaceMrn: space.mrn } },
    skip: !policyId,
  });

  const loadingFailed = !policyId || error || !data?.policy;
  const queryPack = data?.policy;

  const variants = [
    {
      label: "Overall",
      value: "",
      icon: "",
    },
    ...(
      queryPack?.variantPlatformFilters?.map((variant) => ({
        label: variant?.title || "",
        value: variant?.id || "",
        icon: <VariantIcon type={variant?.icon || ""} />,
      })) || []
    ).sort((a, b) => (a.label > b.label ? 1 : -1)),
  ];

  const shareLink = window.location.href;
  const shareEmailLink = `mailto:hello@mondoo.com?subject=${encodeURIComponent("Take a look at this query pack")}&body=${encodeURIComponent(shareLink)}`;
  const isCustomQueryPack = queryPack?.trustLevel === TrustLevel.Private;

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?${scope.params}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Link
      key="/space/inventory/query-packs"
      component={RouterLink}
      to={`/space/inventory/query-packs?${scope.params}`}
    >
      Query Packs
    </Link>,
    <Typography key="/space/inventory/query-packs/policyId">
      {queryPack?.name}
    </Typography>,
  ];

  setDocumentTitle([queryPack?.name, "Query Packs", "Inventory"]);

  return (
    <Box>
      <Breadcrumbs sx={{ mb: 3, overflowWrap: "anywhere" }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>
      {queryPack && (
        <>
          <Box
            id="query-pack-header"
            mb={3}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Flex flexDirection="column">
              <Typography
                variant="h4"
                fontWeight={700}
                textTransform="uppercase"
                sx={{ mb: 1 }}
              >
                {queryPack.name}
              </Typography>
              <Caption
                trustLevel={queryPack.trustLevel}
                version={queryPack.version}
                authors={queryPack.authors}
              />
            </Flex>

            <Flex id="query-pack-actions" alignItems="center" gap={4}>
              <Tooltip title="Share" placement="top" arrow>
                <IconButton onClick={(e) => setShareAnchorEl(e.currentTarget)}>
                  <IosShare />
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={shareAnchorEl}
                open={shareOpen}
                onClose={() => setShareAnchorEl(null)}
                onClick={() => setShareAnchorEl(null)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <MenuItem onClick={() => copyToClipboard(shareLink)}>
                  <ListItemIcon>
                    <CopyLinkIcon fontSize="inherit" />
                  </ListItemIcon>
                  <ListItemText>Copy link</ListItemText>
                </MenuItem>
                <MenuItem component="a" href={shareEmailLink}>
                  <ListItemIcon>
                    <EmailIcon fontSize="inherit" />
                  </ListItemIcon>
                  <ListItemText>Email</ListItemText>
                </MenuItem>
              </Menu>
              <PolicyDownloadButton
                space={space}
                policy={queryPack}
                size="small"
                iconOnly
              />
              {scope.type === ScopeType.Space && queryPackAssignPermission && (
                <Fragment>
                  <Divider orientation="vertical" flexItem />
                  {queryPack.assigned ? (
                    <PolicyDeleteButton
                      space={space}
                      queryPackMrn={queryPack.mrn}
                      isCustom={isCustomQueryPack}
                      availablePermissions={availablePermissions}
                    />
                  ) : (
                    <PolicyAssignButton
                      spaceMrn={space.mrn}
                      policyMrn={queryPack.mrn}
                      assigned={queryPack.assigned}
                      onChange={() => {
                        refetch();
                      }}
                    />
                  )}
                </Fragment>
              )}
            </Flex>
          </Box>
          {variants.length > 1 && (
            <TabsVariant
              options={variants}
              selectedTab={selectedVariantId || ""}
              onTabChange={(variantId) => {
                variantId.length > 0
                  ? searchParams.set("variant", variantId)
                  : searchParams.delete("variant");
                setSearchParams(searchParams);
              }}
              mb={3}
            />
          )}
          {queryPack.docs && (
            <ShowMoreSummary maxHeight={72} text={queryPack.docs} />
          )}
        </>
      )}

      {loading && <LoadingPage what="Query Pack" />}
      {!loading && loadingFailed && <LoadingFailedPage what="Query Pack" />}
      {!loading && queryPack && (
        <Box>
          <QueryPackQueries
            scope={scope}
            policyId={policyId}
            space={space}
            variantPlatformFilter={selectedVariantId}
          />
        </Box>
      )}
    </Box>
  );
}

type QueryPackQueriesProps = {
  space: Space;
  scope: SpaceOrWorkspaceScope;
  policyId: string;
  variantPlatformFilter: string | null;
};

function QueryPackQueries({
  space,
  scope,
  policyId,
  variantPlatformFilter,
}: QueryPackQueriesProps) {
  const mrn = PolicyIdToMrn(policyId);
  const { data, loading, error } = useGetQueryPackQueriesQuery({
    variables: { input: { mrn, spaceMrn: space.mrn, variantPlatformFilter } },
    // network-only until we can figure out how to cache Policy.queries
    // for different variants of a Policy
    fetchPolicy: "network-only",
  });

  const loadingFailed = error || !data?.policy;
  const queryPack = data?.policy;
  const groups = (queryPack?.groups || []).sort((a, b) =>
    a.title.localeCompare(b.title),
  );
  const queries = (queryPack?.queries || []).sort((a, b) =>
    a.title.localeCompare(b.title),
  );

  const groupedQueries = groups.map((group) => {
    return {
      title: group.title,
      summary: group.summary,
      queries:
        group.queries?.flatMap(
          (gq) => queries?.find((q) => q.mrn === gq.mrn) ?? [],
        ) || [],
    };
  });

  const ungroupedQueries = {
    title: "Other",
    summary: "",
    queries: queries.filter(
      (q) =>
        !groups.some((g) => g.queries?.some((gq) => q.mrn === gq.mrn) || false),
    ),
  };

  const groupedView = [...groupedQueries, ungroupedQueries].map(
    (group) =>
      group.queries.length > 0 && (
        <Box key={group.title} mb={3}>
          <Accordion defaultExpanded>
            <AccordionSummary
              sx={{
                backgroundColor: "background.light",
                borderTopLeftRadius: 4,
                borderTopRightRadius: 4,
                flexDirection: "row-reverse",
                "& .Mui-expanded .expand-more-icon": {
                  transform: "rotate(-180deg)",
                },
                "&.Mui-expanded": {
                  borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                },
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  fontWeight={700}
                  sx={{ mr: 0.5, textTransform: "uppercase" }}
                >
                  {group.title}
                </Typography>
                <ExpandMoreIcon
                  className="expand-more-icon"
                  sx={{
                    transform: "rotate(0deg)",
                    transition: "transform .25s",
                  }}
                />
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ px: 0, py: 0 }}>
              {/* I've yet to see a group that doesn't have a blank string summary */}
              {group.summary && (
                <Box
                  sx={{
                    bgcolor: "background.light",
                    px: 2,
                    py: 0,
                    color: "text.secondary",
                    overflow: "auto",
                  }}
                >
                  <Markdown source={group.summary} />
                </Box>
              )}
              <DataTable>
                <TableHead>
                  <TableRow>
                    <TableCell>Query</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="queries-list">
                  {group.queries.map((query) => {
                    const queryId = query.mrn.split("/").pop();
                    return (
                      <TableRow
                        key={query.mrn}
                        component={RouterLink}
                        to={`/space/inventory/query-packs/${encodeURIComponent(policyId)}/queries/${queryId}?${scope.params}`}
                        className="queries-list-item"
                      >
                        <TableCell>{query.title}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </DataTable>
            </AccordionDetails>
          </Accordion>
        </Box>
      ),
  );

  const listView = (
    <DataTable>
      <TableHead>
        <TableRow>
          <TableCell>Query</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {queries.map((query) => {
          const queryId = query.mrn.split("/").pop();
          return (
            <TableRow
              key={query.mrn}
              component={RouterLink}
              to={`/space/inventory/query-packs/${encodeURIComponent(policyId)}/queries/${queryId}?${scope.params}`}
            >
              <TableCell>{query.title}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </DataTable>
  );

  return (
    <Box>
      {loading && <LoadingPage what="Queries" />}
      {!loading && loadingFailed && <LoadingFailedPage what="Queries" />}
      {!loading && queryPack && (
        <Box>
          <LayoutSwitch
            views={{
              grouped: groupedView,
              list: listView,
            }}
            defaultView={ViewType.Grouped}
            countOfCountCaption={`Showing ${queries.length} of ${queries.length} ${pluralize("query", queries.length)}:`}
          />
        </Box>
      )}
    </Box>
  );
}
