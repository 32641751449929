import React from "react";
import { Space } from "~/lib/types";
import { TestIamActionsQuery } from "~/operations";
import { CaseSettings } from "~/components/cases/components/CaseSettings";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";

type CasesProps = {
  space: Space;
  scope: SpaceOrWorkspaceScope;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export const Cases = ({ space, scope, availablePermissions }: CasesProps) => {
  return (
    <CaseSettings
      space={space}
      availablePermissions={availablePermissions}
      scope={scope}
    />
  );
};
