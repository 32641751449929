import React from "react";
import { SectionHeading } from "~/components/DetailsPage/components";
import { Box } from "@mui/material";
import { CveRiskFactors, EmptySection } from "~/components/vulnerabilities";
import { AssetPackageDetailsFieldsFragment } from "~/operations";

type SoftwareRiskFactorsProps = {
  software: AssetPackageDetailsFieldsFragment;
};

export const SoftwareRiskFactors = ({ software }: SoftwareRiskFactorsProps) => {
  return (
    <Box id="risk-factors" className="section">
      <SectionHeading heading="Vulnerability insights" />
      {software.cvssScore && software.epssScore ? (
        <Box className="section-content">
          <CveRiskFactors
            id="cve-risk-factors"
            cvssScore={software.cvssScore}
            epssScore={software.epssScore}
          />
        </Box>
      ) : (
        <EmptySection
          id="software-risk-assessment-empty"
          text="There are no vulnerabilities to assess"
        />
      )}
    </Box>
  );
};
