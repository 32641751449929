import { useSearchParams } from "react-router-dom";
import { useAssetOutlet } from "~/pages/inventory/asset";
import { AssetSoftware } from "./AssetSoftware";
import { clipboardDetails } from "~/pages/inventory/components/AssetSoftware/utils";

export function AssetSoftwareTab() {
  const [searchParams, _setSearchParams] = useSearchParams();
  const { isCicd, scope, space, asset, isVulnerabilityScanningEnabled } =
    useAssetOutlet();
  const assetMrn = isCicd ? (searchParams.get("jobId") as string) : asset?.mrn;

  if (!space || !asset || !assetMrn) return <></>;

  return (
    <AssetSoftware
      assetMrn={assetMrn}
      clipboardDetails={clipboardDetails(asset)}
      scope={scope}
      space={space}
      isVulnerabilityScanningEnabled={isVulnerabilityScanningEnabled}
    />
  );
}
