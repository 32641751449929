import { useColorMode } from "~/providers/color-mode";

export const TailscaleIcon = ({ ...props }) => {
  const { mode } = useColorMode();

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.75015 14.7501C5.26902 14.7501 6.5003 13.5188 6.5003 12C6.5003 10.4811 5.26902 9.24988 3.75015 9.24988C2.23128 9.24988 1 10.4811 1 12C1 13.5188 2.23128 14.7501 3.75015 14.7501Z"
        fill={mode === "light" ? "#141414" : "#FEFDF9"}
      />
      <path
        d="M12.0001 14.7501C13.519 14.7501 14.7503 13.5188 14.7503 12C14.7503 10.4811 13.519 9.24988 12.0001 9.24988C10.4813 9.24988 9.25 10.4811 9.25 12C9.25 13.5188 10.4813 14.7501 12.0001 14.7501Z"
        fill={mode === "light" ? "#141414" : "#FEFDF9"}
      />
      <path
        d="M3.75015 23C5.26902 23 6.5003 21.7688 6.5003 20.2499C6.5003 18.7311 5.26902 17.4998 3.75015 17.4998C2.23128 17.4998 1 18.7311 1 20.2499C1 21.7688 2.23128 23 3.75015 23Z"
        fill={mode === "light" ? "#D0D0D0" : "#696564"}
      />
      <path
        d="M20.2501 23C21.769 23 23.0003 21.7688 23.0003 20.2499C23.0003 18.7311 21.769 17.4998 20.2501 17.4998C18.7313 17.4998 17.5 18.7311 17.5 20.2499C17.5 21.7688 18.7313 23 20.2501 23Z"
        fill={mode === "light" ? "#D0D0D0" : "#696564"}
      />
      <path
        d="M12.0001 23C13.519 23 14.7503 21.7688 14.7503 20.2499C14.7503 18.7311 13.519 17.4998 12.0001 17.4998C10.4813 17.4998 9.25 18.7311 9.25 20.2499C9.25 21.7688 10.4813 23 12.0001 23Z"
        fill={mode === "light" ? "#141414" : "#FEFDF9"}
      />
      <path
        d="M20.2501 14.7501C21.769 14.7501 23.0003 13.5188 23.0003 12C23.0003 10.4811 21.769 9.24988 20.2501 9.24988C18.7313 9.24988 17.5 10.4811 17.5 12C17.5 13.5188 18.7313 14.7501 20.2501 14.7501Z"
        fill={mode === "light" ? "#141414" : "#FEFDF9"}
      />
      <path
        d="M3.75015 6.5002C5.26902 6.5002 6.5003 5.26894 6.5003 3.7501C6.5003 2.23126 5.26902 1 3.75015 1C2.23128 1 1 2.23126 1 3.7501C1 5.26894 2.23128 6.5002 3.75015 6.5002Z"
        fill={mode === "light" ? "#D0D0D0" : "#696564"}
      />
      <path
        d="M12.0001 6.5002C13.519 6.5002 14.7503 5.26894 14.7503 3.7501C14.7503 2.23126 13.519 1 12.0001 1C10.4813 1 9.25 2.23126 9.25 3.7501C9.25 5.26894 10.4813 6.5002 12.0001 6.5002Z"
        fill={mode === "light" ? "#D0D0D0" : "#696564"}
      />
      <path
        d="M20.2501 6.5002C21.769 6.5002 23.0003 5.26894 23.0003 3.7501C23.0003 2.23126 21.769 1 20.2501 1C18.7313 1 17.5 2.23126 17.5 3.7501C17.5 5.26894 18.7313 6.5002 20.2501 6.5002Z"
        fill={mode === "light" ? "#D0D0D0" : "#696564"}
      />
    </svg>
  );
};

TailscaleIcon.displayName = "Tailscale";
