import { useSort } from "~/pages/inventory/hooks/useSort";
import {
  FindingsFilter,
  FindingsOrder,
  FindingsOrderField,
  OrderDirection,
  useGetAffectedAssetsQuery,
} from "~/operations";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";

type UseAffectedAssetsParams = {
  scope: SpaceOrWorkspaceScope;
  filter: FindingsFilter;
};

export function useAffectedAssets({ scope, filter }: UseAffectedAssetsParams) {
  const { handleSortClick, orderBy } = useSort<FindingsOrder>({
    defaultSort: {
      field: FindingsOrderField.RiskValue,
      direction: OrderDirection.Desc,
    },
    validFields: [
      FindingsOrderField.AssetName,
      FindingsOrderField.LastUpdated,
      FindingsOrderField.RiskValue,
    ],
  });

  const { data, error, fetchMore, loading } = useGetAffectedAssetsQuery({
    variables: {
      scopeMrn: scope.mrn,
      first: 10,
      orderBy,
      filter,
    },
  });

  const findingsUnion = data?.findings;
  const findings =
    findingsUnion?.__typename === "FindingsConnection"
      ? findingsUnion
      : undefined;

  const assets = findings?.edges?.flatMap(({ node }) => {
    if (!node) return [];

    const asset = {
      id: node.asset?.id || "",
      mrn: node.asset?.mrn || "",
      score: node.riskValue,
      lastUpdated: node.lastUpdated,
      riskFactors: node.riskFactors,
      title: node.asset?.name || "",
      iconId: node.asset?.icon,
      tags: node.asset?.tags,
      rating: node.rating,
    };

    return [asset];
  });

  return {
    assets,
    orderBy,
    handleSortClick,
    // Backend is currently returning a 0 for filteredTotalCount, so we're using totalCount for now
    // but this will not be accurate when filtering is implemented
    filteredTotalCount: findings?.totalCount || 0,
    fetchMore,
    pageInfo: findings?.pageInfo,
    error,
    loading,
  };
}
