import { Fragment } from "react/jsx-runtime";
import { Grid2, Typography } from "@mui/material";
import { SectionHeading } from "../../components";
import { spacing } from "../lib/SharedVariables";

type SimpleReportHeaderProps = {
  title: string;
};

export function SimpleReportHeader({ title }: SimpleReportHeaderProps) {
  return (
    <Fragment>
      <Grid2 size={12}>
        <Typography variant="h4" fontWeight={700} textTransform="uppercase">
          {title}
        </Typography>
      </Grid2>
      <Grid2 size={12}>
        <SectionHeading heading="Summary" />
      </Grid2>
    </Fragment>
  );
}
