import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { AttachIcon, CloseIcon } from "~/components/icons";
import { Flex } from "~/components/Flex";
import { Link as RouterLink } from "react-router-dom";
import { Space } from "~/lib/types";
import { CreateTicketingIntegrationContent } from "~/components/cases/components/CasesPage/components/CreateTicketingIntegration/CreateTicketingIntegrationContent";

type CreateCasesIntegrationModalProps = {
  open: boolean;
  onClose: () => void;
  space: Space;
  redirectTo: string;
  hasIntegrationCreatePermission: boolean;
};

export const CreateCasesIntegrationModal = ({
  open,
  onClose,
  space,
  redirectTo,
  hasIntegrationCreatePermission,
}: CreateCasesIntegrationModalProps) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle
        component="div"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 5,
        }}
      >
        <Typography sx={{ display: "flex", alignItems: "center" }} gap={2}>
          <AttachIcon />
          <Typography
            variant="h5"
            sx={{
              textTransform: "uppercase",
              fontWeight: "700",
            }}
          >
            GET STARTED USING TICKETS
          </Typography>
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ mt: 4, px: 5 }}>
        <Flex justifyContent="space-around" sx={{ gap: 2 }}>
          <CreateTicketingIntegrationContent />
        </Flex>
      </DialogContent>
      <DialogActions sx={{ px: 5, py: 5, justifyContent: "center" }}>
        <Flex flexDirection="column" gap={1}>
          <Button
            disabled={!hasIntegrationCreatePermission}
            component={RouterLink}
            to={`/space/integrations/add?spaceId=${space.id}&redirectTo=${encodeURIComponent(redirectTo)}#ticketsystems`}
            variant="contained"
            color="primary"
          >
            Add Integration
          </Button>
          {!hasIntegrationCreatePermission && (
            <Typography component="span" sx={{ fontSize: 10 }} color="error">
              You don't have permission to create an integration.
            </Typography>
          )}
        </Flex>
      </DialogActions>
    </Dialog>
  );
};
