import { Fragment } from "react/jsx-runtime";
import { SectionHeading } from "../../components";
import { ScoreRating } from "~/operations";
import { Grid2, TableRow } from "@mui/material";
import {
  ImpactCell,
  PublishedCell,
  RiskFactorsCell,
  TitleCell,
} from "~/components/Cells";
import { GenericTable } from "~/components/GenericTable/GenericTable";
import { NoItemsSection } from "../components";
import { useAssetReport } from "./hooks/useAssetReport";

export function Vulnerabilities() {
  const { vulnerabilities } = useAssetReport();
  const hasVulnerabilities = Boolean(vulnerabilities?.length);

  return (
    <Fragment>
      <SectionHeading heading="Vulnerabilities" disableUpperPadding />
      <Grid2 size={12} sx={{ my: 3 }}>
        {!hasVulnerabilities ? (
          <NoItemsSection target="vulnerabilities" />
        ) : (
          <GenericTable
            space={undefined}
            items={0}
            tableName="ASSET_REPORT_VULNERABILITIES"
            validSortFields={[]}
            printView
          >
            {vulnerabilities?.map((vulnerability) => {
              return (
                <TableRow key={vulnerability?.id}>
                  {/* <RankCell id={node.rank} /> CHECKSCORES API DOES NOT HAVE RANK */}
                  <ImpactCell
                    impact={vulnerability?.riskValue || 0}
                    rating={vulnerability?.rating || ScoreRating.Critical}
                    isActive
                  />
                  <TitleCell
                    title={vulnerability?.id || ""}
                    tags={vulnerability?.tags}
                    options={{ fontSize: 14 }}
                  />
                  <RiskFactorsCell
                    riskFactors={vulnerability?.riskFactors}
                    printView
                  />
                  <PublishedCell
                    date={vulnerability?.lastUpdated || "Never"}
                    formatting="absolute"
                  />
                </TableRow>
              );
            })}
          </GenericTable>
        )}
      </Grid2>
    </Fragment>
  );
}
