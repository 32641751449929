import {
  alpha,
  Box,
  Grid2,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { InfoOutlinedIcon } from "~/components/icons";
import { RiskFactorsChip } from "~/components/RiskFactorsChip/RiskFactorsChip";
import { useFindingOnAsset } from "../../hooks";
import { getColor } from "~/lib/colors";
import { ImpactUpdated } from "~/components/impact/Updated/impact-updated";
import { ShowMoreSummary } from "~/components/ShowMore/ShowMoreSummary";
import { TagButtonRow } from "../Tags/TagButtonRow";
import { ScoreRating } from "~/operations";
import { CasesSummary } from "~/components/cases/components/CasesSummary";
import { ScopeType } from "~/hooks/useScope";
import { isCheckFinding } from "~/pages/findings/FindingOnAsset/utils";

export function SummaryData() {
  let theme = useTheme();
  const { finding, labelTags, ticketActions, casesCount, activeScope } =
    useFindingOnAsset();
  const { riskFactors, asset } = finding || {};

  const riskFactorsIndicators =
    riskFactors?.flatMap((riskFactor) => riskFactor?.indicator ?? []) || [];

  if (
    activeScope?.type !== ScopeType.Workspace &&
    activeScope?.type !== ScopeType.Space
  ) {
    // We only want to render this component for workspace or space scopes.
    return null;
  }

  // map the scorebox scores to a more readable format
  /*const mappedScores = [
    {
      id: 1,
      type: "Final score",
      score: String(finding?.riskValue),
      rating: String(finding?.rating),
    },
    {
      id: 2,
      type: "Base score",
      score: String(cvss?.value),
      rating: String(cvss?.rating),
      subType: "CVSS v3",
    },
  ];*/

  const tooltipMap: Record<ScoreRating, string> = {
    CRITICAL:
      "Presents extreme risk to your organization (risk score between 90 and 100)",
    HIGH: "Presents significant risk to your organization (risk score between 89 and 70)",
    MEDIUM:
      "Presents moderate risk to your organization (risk score between 69 and 40)",
    LOW: "Presents some risk to your organization (risk score between 39 and 1)",
    NONE: "Presents no risk to your organization (risk score of 0)",
  };

  const primaryScoreColor = getColor(theme, String(finding?.rating));
  const ratingTooltip = finding?.rating ? tooltipMap[finding.rating] : "";

  return (
    <Grid2 container columnSpacing={4} rowSpacing={3}>
      <Grid2 size={12}>
        {ticketActions.hasListCasePermissions && casesCount > 0 && (
          <CasesSummary
            count={casesCount}
            scope={activeScope}
            assetId={asset?.id}
          />
        )}
      </Grid2>
      <Grid2
        container
        spacing={3}
        size={{ xs: 12, md: "grow" }}
        order={{ xs: 2, md: 1 }}
      >
        {/* Risk Factors Chips */}
        {!!riskFactorsIndicators.length && (
          <Grid2 size={12}>
            <Stack direction="row" flexWrap="wrap" gap={1}>
              {riskFactorsIndicators.map((indicator) => (
                <RiskFactorsChip riskFactor={indicator} key={indicator} />
              ))}
            </Stack>
          </Grid2>
        )}
        {/* Patch Available */}
        {/* <Grid2 size={12}>
          <Stack direction="row" flexWrap="wrap" gap={1}>
            {Array.from({ length: 1 }).map((_, i) => (
              <RiskFactorsChip riskFactor="patch-available" key={i} />
            ))}
          </Stack>
        </Grid2> */}
        {/* Summary */}
        <Grid2 size={12}>
          <TagButtonRow items={labelTags} />
          {finding?.title && (
            <Grid2 size={12}>
              <ShowMoreSummary
                maxHeight={80}
                text={
                  isCheckFinding(finding)
                    ? finding?.mquery?.docs?.desc || ""
                    : finding.title
                }
                TextProps={{ fontWeight: 500 }}
              />
            </Grid2>
          )}
        </Grid2>
      </Grid2>
      {/* Scores Box */}
      <Grid2 size={{ xs: 12, md: "auto" }} order={{ xs: 1, md: 2 }}>
        <Box
          sx={{
            p: 3,
            width: { xs: "100%", md: 320 },
            maxWidth: { xs: "100%" },
            background: alpha(primaryScoreColor, 0.1),
            borderRadius: 1,
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="column" alignItems={{ xs: "left" }} gap={2}>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Typography
                  sx={{
                    fontSize: 24,
                    fontWeight: 700,
                    lineHeight: "24px",
                    letterSpacing: ".96px",
                  }}
                >
                  {finding?.rating}
                </Typography>
                <Tooltip title={ratingTooltip} placement="top" arrow>
                  <InfoOutlinedIcon
                    sx={{ fontSize: 12, color: "text.secondary" }}
                  />
                </Tooltip>
              </Stack>
              <ImpactUpdated
                impact={{
                  rating: String(finding?.rating),
                  value: finding?.riskValue || 0,
                }}
                options={{
                  size: 16,
                }}
                showText={false}
                isRiskScore
                hideTooltip
              />
            </Stack>
            <Typography
              sx={{
                color: primaryScoreColor,
                fontSize: 48,
                fontWeight: 700,
                lineHeight: "48px",
              }}
            >
              {finding?.riskValue}
            </Typography>
          </Stack>

          {/* <Divider
            sx={{
              my: 3,
              borderBottom: `2px solid  ${alpha(getColor(theme, String(cveFinding?.rating)), 0.1)}`,
            }}
          />
          <Box>
            <Stack
              direction="column"
              spacing={3}
              sx={{
                "& .rating-tag:after": {
                  content: '""',
                  position: "absolute",
                  display: "block",
                  top: "100%",
                  left: "calc(50% - 1px)",
                  transform: "translateX(-50%)",
                  height: 25,
                  borderLeft: `2px solid rgba(180, 180, 180, 0.50)`,
                },
              }}
            >
              {mappedScores.map((score, i) => {
                const isLast = i === mappedScores.length - 1;
                const color = getColor(theme, score.rating);
                return (
                  <Stack
                    key={score.id}
                    direction="row"
                    spacing={1.75}
                    sx={{
                      "& .MuiTypography-root.info": {
                        fontWeight: 700,
                        lineHeight: "24px",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        minWidth: 68,
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        className={!isLast ? "rating-tag" : ""}
                        sx={{
                          position: "relative",
                          display: "inline-block",
                          backgroundColor: alpha(color, 0.4),
                          fontSize: 12,
                          fontWeight: 700,
                          lineHeight: "16px",
                          textTransform: "uppercase",
                          padding: 0.5,
                          borderRadius: 0.5,
                        }}
                      >
                        {score.rating}
                      </Typography>
                    </Box>
                    <Typography className="info" sx={{ minWidth: 36 }}>
                      {score.score}
                    </Typography>
                    <Typography className="info">
                      {score.type}
                      <Fragment>
                        {score.subType && (
                          <Typography
                            component="span"
                            sx={{ fontSize: 12, fontWeight: 700 }}
                          >
                            {" "}
                            ({score.subType})
                          </Typography>
                        )}
                      </Fragment>
                    </Typography>
                  </Stack>
                );
              })}
            </Stack>
          </Box> */}
        </Box>
      </Grid2>
    </Grid2>
  );
}
