import * as d3 from "d3";
import {
  alpha,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormGroup,
  Paper,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { AggregateScoreType, ScoreRating, Severity } from "~/operations";
import { SoftwareVersionTooltipData } from "~/pages/space/software/Software/components/SoftwareVersions/SoftwareVersionTooltipData";
import { useColorMode } from "~/providers/color-mode";
import { useState } from "react";
import { EpssMetric, EpssMetrics } from "~/components/vulnerabilities";
import { SpaceSoftwareAggregateScoresEdges } from "~/pages/space/software/Software/types";
import { ordinalize } from "~/lib/ordinalize";
import { get } from "lodash";
import { blastValueMap } from "~/components/blast/const";
import { getLinkByType } from "~/components/FirewatchPage/lib";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";
import { useGetImpactColorUpdated } from "~/components/impact/Updated/impact-result-updated";
import { getImpactLabelUpdated } from "~/components/impact/Updated/useImpactUpdated";
import { RiskFactorsIconsProps } from "~/pages/space/security/components/RiskFactors/RiskFactorsIcons";

type SoftwareVersionsChartProps = {
  edges: SpaceSoftwareAggregateScoresEdges;
  riskFactors: RiskFactorsIconsProps["riskFactors"];
  scope: SpaceOrWorkspaceScope;
};

export function SoftwareVersionsChart({
  edges,
  riskFactors,
  scope,
}: SoftwareVersionsChartProps) {
  const theme = useTheme();
  const { mode } = useColorMode();

  const [focusedMetric, setFocusedMetric] = useState<EpssMetric | undefined>(
    undefined,
  );
  const [severityFilters, setSeverityFilters] = useState<Severity[]>([
    Severity.Critical,
    Severity.High,
    Severity.Medium,
    Severity.Low,
  ]);

  const isFocused = (metric: EpssMetric) => {
    return focusedMetric?.id === metric.id;
  };

  const onMetricMouseEnter = (metric: EpssMetric) => {
    setFocusedMetric(metric);
  };

  const onMetricMouseLeave = (_metric: EpssMetric) => {
    setFocusedMetric(undefined);
  };

  const plotWidth = 1072;
  const plotHeight = 240;

  const paddingTop = 0;
  const paddingBottom = 56;
  const paddingLeft = 56;
  const paddingRight = 56;

  const viewboxWidth = plotWidth + paddingLeft + paddingRight;
  const viewboxHeight = plotHeight + paddingTop + paddingBottom;
  const viewBox = `0 0 ${viewboxWidth} ${viewboxHeight}`;

  const xScale = d3.scaleLinear([0, plotWidth]);
  const yScale = d3.scaleLinear([plotHeight, 0]);
  const ringThresholds = [0, 0.05, 0.2];

  const handleSeverityFilterChange =
    (name: Severity): FormControlLabelProps["onChange"] =>
    (_event, checked) => {
      const nextSeverity = severityFilters.filter((s) => s !== name);
      if (checked) {
        nextSeverity.push(name);
      }
      setSeverityFilters(nextSeverity);
    };

  const filteredEdges = (edges || []).filter((edge) =>
    severityFilters.includes(
      getImpactLabelUpdated(
        edge?.node?.cvss?.rating || ScoreRating.None,
      ) as unknown as Severity,
    ),
  );

  // if we have multiple points that have same coordinates, we wanna gather them
  const edgesByPoint = filteredEdges?.reduce<{
    [key: string]: SpaceSoftwareAggregateScoresEdges;
  }>((acc, edge) => {
    const point = `${edge?.node?.cvss?.value}x${edge?.node?.epss?.percentile}`;
    const archPkgs = acc[point] || [];
    archPkgs.push(edge);
    acc[point] = archPkgs;
    return acc;
  }, {});

  // after we've gathered all the points with the same coordinated, we want to display the one with the lowest rank
  const distinctEdges = Object.entries(
    edgesByPoint,
  ).reduce<SpaceSoftwareAggregateScoresEdges>((acc, [_, edges]) => {
    return [
      ...(acc || []),
      (edges || [])?.reduce((prev, curr) => {
        return (prev?.node?.rank || 0) < (curr?.node?.rank || 0) ? prev : curr;
      }),
    ];
  }, []);

  return (
    <Paper>
      <FormControl
        component="fieldset"
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 3,
          py: 2,
          px: 7,
        }}
      >
        <Typography>Include</Typography>
        <FormGroup
          aria-label="severity"
          row={true}
          sx={{ display: "flex", gap: 3 }}
        >
          <FormControlLabel
            value="critical"
            onChange={handleSeverityFilterChange(Severity.Critical)}
            control={
              <Checkbox
                checked={severityFilters.includes(Severity.Critical)}
                color="critical"
              />
            }
            label="Critical"
          />
          <FormControlLabel
            value="high"
            onChange={handleSeverityFilterChange(Severity.High)}
            control={
              <Checkbox
                checked={severityFilters.includes(Severity.High)}
                color="high"
              />
            }
            label="High"
          />
          <FormControlLabel
            value="medium"
            onChange={handleSeverityFilterChange(Severity.Medium)}
            control={
              <Checkbox
                checked={severityFilters.includes(Severity.Medium)}
                color="medium"
              />
            }
            label="Medium"
          />
          <FormControlLabel
            value="low"
            onChange={handleSeverityFilterChange(Severity.Low)}
            control={
              <Checkbox
                checked={severityFilters.includes(Severity.Low)}
                color="low"
              />
            }
            label="Low"
          />
        </FormGroup>
      </FormControl>
      <Box
        component="svg"
        viewBox={viewBox}
        sx={{
          margin: "0 auto",
          display: "flex",
          width: "100%",
          overflow: "visible",
          ".point": {
            transform: `translateX(calc(100% - 56px))`,
          },
        }}
      >
        {/* x-axis status gradient background */}
        <g className="x-axis-background">
          <foreignObject
            x={paddingLeft}
            y={paddingTop}
            width={plotWidth}
            height={plotHeight}
          >
            <Box
              sx={{
                backgroundColor: "background.default",
                backgroundImage: `radial-gradient(${[
                  "170.1% 128% at 1.09% 113.04%",
                  "rgba(44, 191, 201, 0.16) 5.75%",
                  "rgba(3, 156, 216, 0.16) 25.31%",
                  "rgba(207, 37, 132, 0.16) 68.84%",
                  "rgba(252, 55, 121, 0.16) 100%",
                ].join(", ")})`,
                height: "100%",
                width: "100%",
                borderRadius: "4px 4px 4px 0px",
              }}
            />
          </foreignObject>
        </g>
        {/* x-axis label and arrow line */}
        <g className="x-axis">
          <g
            style={{
              transform: `translate(calc(100% - ${paddingLeft}), 0)`,
            }}
          >
            <text
              fontSize={12}
              fill={alpha(theme.palette.text.secondary, 0.33)}
              x={paddingLeft - 20}
              y={plotHeight + 24}
              textAnchor="middle"
              dominantBaseline="text-top"
            >
              0
            </text>
          </g>
          <g
            style={{
              transform: `translate(calc(100% - ${paddingLeft}), 0)`,
            }}
          >
            <text
              fontSize={12}
              fill={alpha(theme.palette.text.secondary, 0.33)}
              x={paddingLeft + 64}
              y={plotHeight + 24}
              textAnchor="middle"
              dominantBaseline="text-top"
            >
              CVSS v3 score (Severity)
            </text>
          </g>
          {[0.2, 0.4, 0.6, 0.8, 1].map((x) => (
            <text
              fontSize={12}
              fill={alpha(theme.palette.text.secondary, 0.33)}
              x={xScale(x) + paddingLeft}
              y={plotHeight + 24}
              textAnchor="middle"
              dominantBaseline="text-top"
            >
              {x * 10}
            </text>
          ))}
        </g>
        {/* y-axis label and arrow line */}
        <g className="y-axis">
          <text
            fontSize={12}
            fill={alpha(theme.palette.text.secondary, 0.33)}
            transform="rotate(-90)"
            x={-plotHeight + 48}
            y={paddingLeft - 24}
            textAnchor="middle"
            dominantBaseline="hanging"
          >
            EPSS percentile
          </text>
          <text
            fontSize={12}
            fill={alpha(theme.palette.text.secondary, 0.33)}
            transform="rotate(-90)"
            x={-plotHeight / 2}
            y={paddingLeft - 16}
            textAnchor="middle"
            dominantBaseline="text-top"
          >
            0.5
          </text>
          <text
            fontSize={12}
            fill={alpha(theme.palette.text.secondary, 0.33)}
            transform="rotate(-90)"
            x={0}
            y={paddingLeft - 16}
            textAnchor="middle"
            dominantBaseline="text-top"
          >
            1.0
          </text>
        </g>
        {/* data plot */}
        <g
          className="data-plot"
          transform={`translate(${paddingLeft} ${paddingTop})`}
        >
          {/* xy point */}
          {(distinctEdges || []).map((edge) => {
            const probability = edge?.node?.epss?.probability || 0;
            const percentile = edge?.node?.epss?.percentile || 0;
            const cvss = edge?.node?.cvss?.value || 0;
            const epss = edge?.node?.epss;
            const color = useGetImpactColorUpdated(
              edge?.node?.cvss?.rating || ScoreRating.None,
            );
            const fillColor = get(theme.palette, color, color);
            const blastRadius = edge?.node?.blastRadius;

            const percentileValue = Math.round(percentile * 100);
            const formattedPercentile = ordinalize(percentileValue);

            const formattedProbability = `${(probability * 100).toFixed(
              probability % 1 === 0 ? 0 : 2,
            )}%`;

            const metrics: EpssMetrics = {
              probability: {
                id: edge?.node?.id,
                label: "Probability",
                description:
                  "The FIRST Exploit Prediction Scoring System (EPSS) forecasts the probability that this CVE will be exploited in the next 30 days. It is a measure of overall risk.",
                value: probability,
                formattedValue: formattedProbability,
              },
              percentile: {
                label: "Percentile",
                description: `This CVE has a higher EPSS score than ${percentileValue}% of all EPSS-scored CVEs. The percentile is a measure of relative risk.`,
                value: percentile,
                formattedValue: formattedPercentile,
              },
              cvssScore: {
                label: "CVSS v3 score",
                description:
                  "The Common Vulnerability Scoring System (CVSS) is a method used to supply a qualitative measure of CVE severity.",
                value: cvss,
                formattedValue: `${(cvss / 10).toFixed(1)}`,
              },
            };

            const percentileY = yScale(metrics.percentile.value) || 0;
            const cvssScoreX = xScale(metrics.cvssScore.value / 100) || 0;
            const probabilityX = cvssScoreX;
            const probabilityY = percentileY;

            let hasActiveCircle = false;

            return (
              <>
                <g
                  className="xy-point"
                  onMouseEnter={() => onMetricMouseEnter(metrics.probability)}
                  onMouseLeave={() => onMetricMouseLeave(metrics.probability)}
                >
                  <Tooltip
                    className="xy-point-label-tooltip"
                    title={
                      <SoftwareVersionTooltipData
                        blastRadius={edge?.node?.blastRadius}
                        riskValue={edge?.node?.riskValue || 0}
                        rating={edge?.node?.rating || ScoreRating.None}
                        epssScore={epss}
                        cvss={cvss}
                        title={String(edge?.node?.title)}
                        riskFactors={riskFactors}
                        href={getLinkByType({
                          type: AggregateScoreType.VersionedSoftware,
                          queryParams: scope.params,
                          findingMrn: String(edge?.node?.findingMrn),
                          scoreType: edge.node?.scoreType,
                        })}
                      />
                    }
                    placement="top"
                    arrow
                    componentsProps={{
                      tooltip: {
                        sx: {
                          maxWidth: "none",
                          p: 1,
                          ...(mode === "dark"
                            ? {
                                boxShadow: 2,
                                bgcolor: "background.light",
                                "& .MuiTooltip-arrow": {
                                  color: "background.light",
                                },
                              }
                            : {}),
                        },
                      },
                    }}
                    open={isFocused(metrics.probability)}
                  >
                    <circle
                      className="xy-point-dot"
                      r={3}
                      cx={probabilityX}
                      cy={probabilityY}
                      stroke={
                        isFocused(metrics.probability)
                          ? "transperent"
                          : fillColor
                      }
                      strokeWidth={2}
                      fill="transparent"
                    />
                  </Tooltip>
                  {isFocused(metrics.probability) &&
                    ringThresholds.map((threshold, i) => {
                      const fillCircle = !hasActiveCircle;
                      const isActive =
                        blastValueMap[
                          (blastRadius?.indicator ||
                            "none") as keyof typeof blastValueMap
                        ] > threshold;

                      hasActiveCircle = isActive;

                      return (
                        <circle
                          cursor="pointer"
                          className="xy-point-dot"
                          r={4 * i + 4}
                          cx={probabilityX}
                          cy={probabilityY}
                          stroke={
                            isActive
                              ? fillColor
                              : theme.palette.background.lightest
                          }
                          fill={
                            isActive && fillCircle
                              ? alpha(fillColor, 0.5)
                              : "transparent"
                          }
                        />
                      );
                    })}
                </g>
              </>
            );
          })}
        </g>
      </Box>
    </Paper>
  );
}
