import { createSvgIcon } from "@mui/material";

export const MTTRSlaIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 9.14062C8.84664 9.14062 6.28125 11.706 6.28125 14.8594C6.28125 18.0127 8.84664 20.5781 12 20.5781C15.1533 20.5781 17.7187 18.0127 17.7187 14.8594C17.7187 11.706 15.1533 9.14062 12 9.14062ZM12 19.1484C9.6352 19.1484 7.71094 17.2242 7.71094 14.8594C7.71094 12.4946 9.6352 10.5703 12 10.5703C14.3648 10.5703 16.289 12.4946 16.289 14.8594C16.289 17.2242 14.3648 19.1484 12 19.1484Z"
      fill="currentColor"
    />
    <path
      d="M14.1445 14.1445H12.7148V12C12.7148 11.605 12.3949 11.2852 12 11.2852C11.605 11.2852 11.2852 11.605 11.2852 12V14.8594C11.2852 15.2543 11.605 15.5742 12 15.5742H14.1445C14.5395 15.5742 14.8594 15.2543 14.8594 14.8594C14.8594 14.4644 14.5395 14.1445 14.1445 14.1445Z"
      fill="currentColor"
    />
    <path
      d="M16.0951 6.97868C14.1986 5.33722 12.5602 3.92004 12.7135 1.31936C12.7296 1.04861 12.5906 0.792161 12.3556 0.657235C12.1202 0.522308 11.8289 0.532137 11.6032 0.682701C9.91533 1.80813 8.1939 3.39285 7.79626 6.61366L6.73874 5.73217C6.52563 5.5548 6.22897 5.51638 5.97788 5.63388C5.7268 5.75138 5.5664 6.00381 5.5664 6.28126C5.5664 8.2569 5.0821 9.22552 4.56964 10.2504C4.00536 11.379 3.42188 12.546 3.42188 14.8594C3.42188 19.5894 7.26996 23.4375 12 23.4375C16.73 23.4375 20.5781 19.5894 20.5781 14.8594C20.5781 10.8576 18.1968 8.79661 16.0956 6.97868H16.0951ZM11.9995 22.0078C8.05807 22.0078 4.85111 18.8008 4.85111 14.8594C4.85111 12.8837 5.33542 11.9151 5.84787 10.8902C6.2799 10.0261 6.72311 9.13973 6.90718 7.73373L7.96783 8.61745C8.18094 8.79482 8.4776 8.83325 8.72869 8.71574C8.97977 8.59824 9.14017 8.34581 9.14017 8.06836C9.14017 5.36805 9.99038 3.83605 11.3486 2.65969C11.727 5.08926 13.4676 6.59579 15.1591 8.05943C17.2103 9.83447 19.1475 11.5108 19.1475 14.8594C19.1475 18.8008 15.9406 22.0078 11.9991 22.0078H11.9995Z"
      fill="currentColor"
    />
  </svg>,
  "MTTR & SLA PERFORMANCE BY SEVERITY",
);
