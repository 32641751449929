import { ImpactData } from "../types";
import { ImpactLabels } from "../constants";
import { useTheme } from "@mui/material";
import { useGetImpactColorUpdated } from "./impact-result-updated";
import { ImpactRating } from "./impact-updated";
import { ScoreRating } from "~/operations";

type UseImpactRatioParamsUpdated = {
  impact: ImpactRating;
  adjustedColor?: string;
  isActive: boolean;
};

type UseImpactRatioReturnUpdated = {
  impactData: ImpactData;
  color: string;
  label: (typeof ImpactLabels)[keyof typeof ImpactLabels];
};

export function useImpactUpdated({
  impact,
  adjustedColor,
  isActive,
}: UseImpactRatioParamsUpdated): UseImpactRatioReturnUpdated {
  const theme = useTheme();

  const color = !isActive
    ? theme.palette.unknown.main
    : useGetImpactColorUpdated(impact.rating);

  const label = getImpactLabelUpdated(impact.rating);

  // It can be adjusted by success status, skipped/disabled/errored states
  const checkColor = adjustedColor || color;

  function getImpactData(rating: ImpactRating["rating"]): ImpactData {
    let normalizedRating = rating?.toLowerCase();

    if (normalizedRating === "none") {
      return {
        rating: 1,
      };
    } else if (normalizedRating === "low") {
      return {
        rating: 2,
      };
    } else if (normalizedRating === "medium") {
      return {
        rating: 3,
      };
    } else if (normalizedRating === "high") {
      return {
        rating: 4,
      };
    } else {
      return {
        rating: 5,
      };
    }
  }

  return {
    impactData: getImpactData(impact.rating),
    color: checkColor,
    label,
  };
}

export function getImpactLabelUpdated(
  rating: ImpactRating["rating"],
): ScoreRating {
  let normalizedRating = rating?.toLowerCase();

  if (normalizedRating === "none") {
    return ScoreRating.None;
  } else if (normalizedRating === "low") {
    return ScoreRating.Low;
  } else if (normalizedRating === "medium") {
    return ScoreRating.Medium;
  } else if (normalizedRating === "high") {
    return ScoreRating.High;
  } else {
    return ScoreRating.Critical;
  }
}
