import { Checkbox } from "@mui/material";
import { useExceptions } from "~/components/exceptions/use-exceptions";
import { useExceptionsSelection } from "~/components/exceptions/use-exceptions-selection";
import { useFetchExceptions } from "~/components/exceptions/use-fetch-exceptions";
import { mapSelectedEntitiesToString } from "~/components/exceptions/utils";
import { IamActions } from "~/lib/iam";
import {
  ExceptionMutationAction,
  ExceptionType,
  GetAdvisoriesDocument,
  GetChecksDocument,
  GetCvEsDocument,
  LoadAssetDocument,
  ScoreState,
  TestIamActionsQuery,
} from "~/operations";
import { Header } from "~/types/table";
import { AdvisoryFindingsNode } from "~/pages/inventory/components/Advisories/types";
import { CVEFindingsNode } from "~/pages/inventory/components/Vulnerabilities/types";
import { useAssetOutlet } from "~/pages/inventory/asset";
import { ExceptionTarget } from "~/components/exceptions/types";
import { CheckFindingsCheckNode } from "~/pages/inventory/asset/types";

type Context = "advisory" | "cve" | "check";

type UseAssetVulnerabilitiesTableProps = {
  availablePermissions: TestIamActionsQuery["testIamActions"];
  context: Context;
  entityMrn: string;
  items: {
    mrn: string;
    state: ScoreState;
    exception?: {
      id: string;
      action: ExceptionMutationAction;
    } | null;
  }[];
};

export function useFindingsTable({
  availablePermissions,
  context,
  entityMrn,
  items,
}: UseAssetVulnerabilitiesTableProps) {
  const { scope, asset } = useAssetOutlet();

  const {
    isGroupChecked,
    isGroupIndeterminate,
    onGroupCheckChange,
    selectedEntities,
    setSelectedEntities,
    handleNodeClick,
    handleNodeChange,
    handleCancelClick,
  } = useExceptionsSelection();

  const {
    isRemovingException,
    isSettingException,
    handleSetExceptionModalOpen,
    handleSetExceptionModalClose,
    handleRemoveExceptionModalOpen,
    handleRemoveExceptionModalClose,
    handleRemoveException,
    handleSetException,
    loading: exceptionsLoading,
  } = useExceptions({
    onSetException: () => {
      setSelectedEntities([]);
    },
    onRemoveException: () => {
      setSelectedEntities([]);
    },
    scopeMrns: [entityMrn],
    advisoryMrns:
      context === "advisory"
        ? mapSelectedEntitiesToString(selectedEntities)
        : [],
    cveMrns:
      context === "cve" ? mapSelectedEntitiesToString(selectedEntities) : [],
    queryMrns:
      context === "check" ? mapSelectedEntitiesToString(selectedEntities) : [],
    applyToCves: context === "advisory",
    refetchQueries: [
      context === "cve" ? GetCvEsDocument : GetAdvisoriesDocument,
      ...(context === "check" ? [GetChecksDocument] : []),
      LoadAssetDocument,
    ],
  });

  const { exceptionGroups } = useFetchExceptions({
    scopeMrn: entityMrn,
    types:
      context === "advisory" ? [ExceptionType.Advisory] : [ExceptionType.Cve],
  });

  const targetPaginatedGroup = items.map((item) => {
    return {
      mrn: item.mrn as string,
      exception: item.exception,
      groupId: item.exception?.id,
    };
  });

  const hasApplyExceptionPermission = availablePermissions.includes(
    IamActions.ACTION_MONDOO_POLICY_EXTENDEDHUB_APPLYEXCEPTIONMUTATION,
  );

  const AssetVulnerabilitiesTableHeaders = {
    SELECT: {
      id: "SELECT",
      label: (
        <Checkbox
          checked={isGroupChecked(targetPaginatedGroup)}
          indeterminate={isGroupIndeterminate(targetPaginatedGroup)}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onGroupCheckChange(event, targetPaginatedGroup)
          }
        />
      ),
      sortable: false,
    },
    RISK_VALUE: {
      id: "RISK_VALUE",
      label: "Risk",
      options: { width: "10%" },
    },
    ID: {
      id: "ID",
      label: "Advisory",
      options: { width: "50%" },
      sortable: false,
    },
    VULNERABILITY_ID: {
      id: "VULNERABILITY_ID",
      label: "Vulnerability",
      options: { width: "50%" },
      sortable: false,
    },
    CHECK_ID: {
      id: "CHECK_ID",
      label: "Check",
      options: { width: "50%" },
      sortable: false,
    },
    RISKFACTORS: {
      id: "RISKFACTORS",
      label: "Risk factors",
      sortable: false,
    },
    PUBLISHED_DATE: {
      id: "PUBLISHED_DATE",
      label: "Published",
      // Backend does not support sorting by this column yet
      sortable: false,
    },
  };

  const getTableHeaders = (context: Context, hasPermission: boolean) => {
    switch (context) {
      case "advisory":
        return AdvisoryTableHeaders(hasPermission);
      case "cve":
        return VulnerabilitiesTableHeaders(hasPermission);
      case "check":
        return ChecksTableHeaders(hasPermission);
      default:
        return [];
    }
  };

  const getTableRowBuilder = (context: Context) => {
    switch (context) {
      case "advisory":
        return (
          finding:
            | AdvisoryFindingsNode
            | CVEFindingsNode
            | CheckFindingsCheckNode,
        ) =>
          `/space/inventory/${asset.id}/advisories/${finding.id}?${scope.params}`;
      case "cve":
        return (
          finding:
            | CVEFindingsNode
            | AdvisoryFindingsNode
            | CheckFindingsCheckNode,
        ) =>
          `/space/inventory/${asset.id}/vulnerabilities/${finding.id}?${scope.params}`;
      case "check":
        return (
          finding:
            | CVEFindingsNode
            | AdvisoryFindingsNode
            | CheckFindingsCheckNode,
        ) =>
          `/space/inventory/${asset.id}/check?checkMrn=${encodeURIComponent(finding.mrn)}&${scope.params}`;
      default:
        return () => "#";
    }
  };

  const getExceptionHref = (exceptionId: string) => {
    return `/space/inventory/${asset.id}/exceptions?spaceId=${scope.id}&exceptionId=${exceptionId}`;
  };

  const AdvisoryTableHeaders = (hasPermission: boolean): Header[] => {
    return [
      ...(hasPermission ? [AssetVulnerabilitiesTableHeaders.SELECT] : []),
      AssetVulnerabilitiesTableHeaders.RISK_VALUE,
      AssetVulnerabilitiesTableHeaders.ID,
      AssetVulnerabilitiesTableHeaders.RISKFACTORS,
      AssetVulnerabilitiesTableHeaders.PUBLISHED_DATE,
    ].flatMap((h) => h ?? []);
  };

  const VulnerabilitiesTableHeaders = (hasPermission: boolean): Header[] => {
    return [
      ...(hasPermission ? [AssetVulnerabilitiesTableHeaders.SELECT] : []),
      AssetVulnerabilitiesTableHeaders.RISK_VALUE,
      AssetVulnerabilitiesTableHeaders.VULNERABILITY_ID,
      AssetVulnerabilitiesTableHeaders.RISKFACTORS,
      AssetVulnerabilitiesTableHeaders.PUBLISHED_DATE,
    ].flatMap((h) => h ?? []);
  };

  const ChecksTableHeaders = (hasPermission: boolean): Header[] => {
    return [
      ...(hasPermission ? [AssetVulnerabilitiesTableHeaders.SELECT] : []),
      AssetVulnerabilitiesTableHeaders.RISK_VALUE,
      AssetVulnerabilitiesTableHeaders.CHECK_ID,
      AssetVulnerabilitiesTableHeaders.RISKFACTORS,
    ].flatMap((h) => h ?? []);
  };

  // get the appropriate table headers
  const tableHeaders = getTableHeaders(context, hasApplyExceptionPermission);
  const buildTableRowHref = getTableRowBuilder(context);
  function getFindingExceptionTarget(): ExceptionTarget {
    switch (context) {
      case "advisory":
        return "advisory";
      case "cve":
        return "cve";
      case "check":
        return "check";
      default:
        return "finding";
    }
  }

  return {
    hasApplyExceptionPermission,
    findingExceptionTarget: getFindingExceptionTarget(),
    tableHeaders,
    buildTableRowHref,
    getExceptionHref,
    exceptionGroups,
    targetPaginatedGroup,
    exception: {
      isRemovingException,
      isSettingException,
      handleSetExceptionModalOpen,
      handleSetExceptionModalClose,
      handleRemoveExceptionModalOpen,
      handleRemoveExceptionModalClose,
      handleRemoveException,
      handleSetException,
      loading: exceptionsLoading,
    },
    exceptionsSelection: {
      isGroupChecked,
      isGroupIndeterminate,
      onGroupCheckChange,
      selectedEntities,
      setSelectedEntities,
      handleNodeClick,
      handleNodeChange,
      handleCancelClick,
    },
  };
}
