import { SoftwareVersionTooltipData } from "~/pages/space/software/Software/components/SoftwareVersions/SoftwareVersionTooltipData";
import { Flex } from "~/components/Flex";
import { SoftwareVersionLabel } from "~/pages/space/software/Software/components/SoftwareVersionDistribution/SoftwareVersionLabel";
import { SoftwareVersionTooltip } from "~/pages/space/software/Software/components/SoftwareVersionDistribution/SoftwareVersionTooltip";
import { SoftwareVersionUnitBase } from "~/pages/space/software/Software/components/SoftwareVersionDistribution/SoftwareVersionUnitBase";
import {
  AggregateScoreType,
  BlastRadiusFieldsFragment,
  EpssScore,
  ScoreRating,
} from "~/operations";
import { Link } from "react-router-dom";
import { getLinkByType } from "~/components/FirewatchPage/lib";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";
import { RiskFactorsIconsProps } from "~/pages/space/security/components/RiskFactors/RiskFactorsIcons";

type SoftwareVersionUnitProps = {
  color: string;
  version: string;
  riskFactors: RiskFactorsIconsProps["riskFactors"];
  blastRadius: BlastRadiusFieldsFragment | null | undefined;
  epss: EpssScore | null | undefined;
  cvss: number;
  riskValue: number;
  rating: ScoreRating;
  title: string;
  findingMrn: string;
  scope: SpaceOrWorkspaceScope;
};

export const SoftwareVersionUnit = ({
  color,
  version,
  riskFactors,
  blastRadius,
  epss,
  cvss,
  riskValue,
  rating,
  title,
  findingMrn,
  scope,
}: SoftwareVersionUnitProps) => {
  return (
    <Flex alignSelf="flex-end" flexDirection="column">
      <SoftwareVersionLabel color={color} version={version} />
      <SoftwareVersionTooltip
        content={
          <SoftwareVersionTooltipData
            blastRadius={blastRadius}
            riskValue={riskValue}
            rating={rating}
            epssScore={epss}
            cvss={cvss}
            title={title}
            riskFactors={riskFactors}
            href={getLinkByType({
              type: AggregateScoreType.VersionedSoftware,
              findingMrn: findingMrn + "/" + version,
              queryParams: scope.params,
            })}
          />
        }
      >
        <SoftwareVersionUnitBase
          color={color}
          {...{
            component: Link,
            to: getLinkByType({
              type: AggregateScoreType.VersionedSoftware,
              findingMrn: findingMrn + "/" + version,
              queryParams: scope.params,
            }),
          }}
        />
      </SoftwareVersionTooltip>
    </Flex>
  );
};
