import {
  alpha,
  Avatar,
  Box,
  List,
  ListItem,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import {
  ArrowDownwardIcon,
  ArrowUpwardIcon,
  DefaultRiskAction,
  RemoveIcon,
} from "~/components/icons";
import { getColor } from "~/lib/colors";
import { useAssetOutlet } from "../../asset";
import { getRiskFactorIcon } from "~/pages/space/security/components/RiskFactors/utils";
import { AssetRiskFactor, RiskMagnitudeExplaination } from "~/operations";
import { Flex } from "~/components/Flex";

export function RiskFactors() {
  const { asset, loading } = useAssetOutlet();
  const { riskFactors } = asset;

  const affectedRiskFactors = riskFactors?.filter(
    (riskFactor) => riskFactor.isDetected,
  );

  return (
    <Box component={Paper} sx={{ p: 3, minHeight: 298 }}>
      <Stack direction="row" justifyContent="space-between">
        <Typography
          variant="h5"
          component="h3"
          fontWeight={700}
          sx={{ color: "text.primary", lineHeight: "24px" }}
        >
          RISK FACTORS
        </Typography>
        <DefaultRiskAction />
      </Stack>
      <List disablePadding sx={{ pt: 3, "& > li:first-of-type": { pt: 0 } }}>
        {loading ? (
          <RiskFactorsLoading />
        ) : affectedRiskFactors?.length ? (
          <RiskFactorsList affectedRiskFactors={affectedRiskFactors} />
        ) : (
          <Flex
            alignItems="center"
            justifyContent="center"
            sx={{ height: 150 }}
          >
            <Typography sx={{ fontWeight: 500, color: "text.secondary" }}>
              NO RISK FACTORS
            </Typography>
          </Flex>
        )}
      </List>
    </Box>
  );
}

const RiskFactorsList = ({
  affectedRiskFactors,
}: {
  affectedRiskFactors: AssetRiskFactor[];
}) => {
  const arrowDirection = (
    magnitudeExplanation: RiskMagnitudeExplaination | undefined,
  ) => {
    if (!magnitudeExplanation) return null;

    if (magnitudeExplanation === RiskMagnitudeExplaination.IncreasesRisk) {
      return <ArrowUpwardIcon sx={{ fontSize: 12 }} />;
    }
    if (magnitudeExplanation === RiskMagnitudeExplaination.DecreasesRisk) {
      return <ArrowDownwardIcon sx={{ fontSize: 12 }} />;
    }
    if (magnitudeExplanation === RiskMagnitudeExplaination.NoEffect) {
      return <RemoveIcon sx={{ fontSize: 12 }} />;
    }
    return null;
  };

  const getRiskColor = (
    magnitudeExplanation: RiskMagnitudeExplaination | undefined,
  ) => {
    if (!magnitudeExplanation) return "unknown";

    if (magnitudeExplanation === RiskMagnitudeExplaination.IncreasesRisk) {
      return "critical";
    }
    if (magnitudeExplanation === RiskMagnitudeExplaination.DecreasesRisk) {
      return "none";
    }

    return "unknown";
  };
  return (
    <>
      {affectedRiskFactors?.map((riskFactor) => {
        const riskDirection = riskFactor.explanation?.magnitudeExplanation;
        const arrow = arrowDirection(riskDirection);
        const colorType = getRiskColor(riskDirection);

        return (
          <ListItem
            key={riskFactor.mrn}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              px: 0,
            }}
          >
            <Stack direction="row" gap={1}>
              {getRiskFactorIcon({ indicator: riskFactor.indicator })}
              <Typography sx={{ fontWeight: 500 }}>
                {riskFactor.title}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              gap={0.5}
              sx={{
                p: 0.5,
                pr: 1,
                width: 104,
                minWidth: 104,
                background: (theme) => alpha(getColor(theme, colorType), 0.1),
                color: (theme) => getColor(theme, colorType),
                borderRadius: 1,
              }}
            >
              {arrow}
              <Typography
                variant="body2"
                component="span"
                sx={{ fontWeight: 600, lineHeight: "normal" }}
              >
                RISK
              </Typography>
            </Stack>
          </ListItem>
        );
      })}
    </>
  );
};

const RiskFactorsSkeleton = () => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent={"space-between"}
      sx={{ width: 1 }}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{ width: "100%", gap: 0.5 }}
      >
        <Skeleton variant="circular" width={24} height={24} animation="wave">
          <Avatar />
        </Skeleton>
        <Skeleton variant="text" animation="wave" width={"50%"} />
      </Stack>
      <Skeleton variant="rounded" animation="wave" width={138} height={24} />
    </Stack>
  );
};

const RiskFactorsLoading = () => {
  // Simulate loading state with skeletons
  return (
    <>
      {[...Array(5)].map((_, index) => (
        <ListItem key={index} sx={{ px: 0, width: "100%" }}>
          <RiskFactorsSkeleton />
        </ListItem>
      ))}
    </>
  );
};
