import { TableBody } from "@mui/material";
import { DataTable } from "~/components/data-table";
import React from "react";
import { Header } from "~/types/table";
import { WIFAuthBindings } from "~/pages/space-settings/WorkloadIdentity/types";
import { WorkloadIdentityBindingsTableRow } from "~/pages/space-settings/WorkloadIdentity/components/WorkloadIdentityBindingsTable/WorkloadIdentityBindingsTableRow";
import { AuthBindingOrderField, WifAuthBindingOrder } from "~/operations";
import { TableHead } from "~/pages/inventory/components/TableHead";

type WorkloadIdentityBindingsTableProps = {
  bindings: WIFAuthBindings;
  onBindingRemove: (mrn: string) => void;
  canRemove: boolean;
  isRemoving: boolean;
  onSort: (id: string) => void;
  orderBy: WifAuthBindingOrder;
};

export const WorkloadIdentityBindingsTable = ({
  bindings,
  onBindingRemove,
  onSort,
  orderBy,
  canRemove,
  isRemoving,
}: WorkloadIdentityBindingsTableProps) => {
  const tableHeaders: Header[] = [
    {
      id: "ICON",
      label: " ",
      sortable: false,
      options: { width: "40px" },
    },
    {
      id: AuthBindingOrderField.Name,
      label: "Workload identity provider",
      sortable: true,
    },
    {
      id: "EXPIRATION",
      label: "Expiration",
      sortable: false,
    },
    {
      id: AuthBindingOrderField.CreateTime,
      label: "Created",
      sortable: true,
    },
    {
      id: AuthBindingOrderField.LastUsed,
      label: "Last used",
      sortable: true,
    },
    {
      id: "EXPAND_COLLAPSE",
      label: " ",
      sortable: false,
    },
  ];

  return (
    <DataTable data-testid="wif-table">
      <TableHead
        tableHeaders={tableHeaders}
        onSort={onSort}
        orderBy={orderBy}
      />
      <TableBody>
        {bindings.map((binding) => (
          <WorkloadIdentityBindingsTableRow
            key={binding?.mrn}
            binding={binding}
            canRemove={canRemove}
            onBindingRemove={onBindingRemove}
            isRemoving={isRemoving}
          />
        ))}
      </TableBody>
    </DataTable>
  );
};
