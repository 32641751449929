import React from "react";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { HomeIcon } from "~/components/icons";
import { LoadMqueryQuery, LoadPolicyQuery } from "~/operations";
import { ComplianceControl } from "~/pages/compliance/control/control";

type CheckBreadcrumbsParams = {
  policyId: string | undefined;
  controlId: string | undefined;
  frameworkId: string | undefined;
  frameworkMrn: string | undefined;
  policyData: Pick<LoadPolicyQuery, "policy"> | undefined;
  control: ComplianceControl | undefined;
  check: Pick<LoadMqueryQuery, "mquery"> | undefined;
  scopeParams: URLSearchParams;
};

export const CheckBreadcrumbs = ({
  policyId,
  policyData,
  control,
  controlId,
  frameworkId,
  frameworkMrn,
  check,
  scopeParams,
}: CheckBreadcrumbsParams) => {
  const homeCrumb = (
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?${scopeParams}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>
  );

  const checkCrumb = (
    <Typography key={"/space/findings/checks/:check"}>
      {check?.mquery?.title}
    </Typography>
  );

  const complianceBreadcrumbs = [
    homeCrumb,
    <Link
      key="framework-crumb"
      component={RouterLink}
      to={`/space/compliance/${frameworkId}/controls/${controlId}/checks?${scopeParams}&frameworkMrn=${frameworkMrn}&controlMrn=${control?.mrn}`}
    >
      {control?.title}
    </Link>,
    checkCrumb,
  ];

  const securityBreadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?${scopeParams}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    policyId ? (
      <Link
        key="/space/findings/policies/:policyId/queries"
        component={RouterLink}
        to={`/space/findings/policies/${encodeURIComponent(
          policyId,
        )}/queries?${scopeParams}`}
        display="flex"
      >
        {policyData?.policy?.name}
      </Link>
    ) : (
      <Link
        key="/space/findings/checks"
        component={RouterLink}
        to={`/space/findings/checks?${scopeParams}`}
        display="flex"
      >
        Checks
      </Link>
    ),
    checkCrumb,
  ];

  return (
    <Breadcrumbs sx={{ mb: 3, overflowWrap: "anywhere" }} separator="›">
      {control ? complianceBreadcrumbs : securityBreadcrumbs}
    </Breadcrumbs>
  );
};
