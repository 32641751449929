import { createSvgIcon } from "@mui/material";

export const LowPriorityIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.091 17.874H9.938C6.109 17.874 3 14.766 3 10.937C3 7.108 6.109 4 9.938 4H11.696C12.054 4 12.346 4.291 12.346 4.65C12.346 5.008 12.054 5.3 11.696 5.3H9.938C6.826 5.3 4.3 7.826 4.3 10.937C4.3 14.048 6.826 16.574 9.938 16.574H10.179L8.984 15.379C8.73 15.125 8.73 14.713 8.984 14.459C9.111 14.332 9.278 14.269 9.444 14.269C9.61 14.269 9.777 14.332 9.904 14.459L12.165 16.72C12.418 16.974 12.418 17.386 12.165 17.64L9.904 19.9C9.777 20.027 9.611 20.091 9.444 20.091C9.278 20.091 9.111 20.027 8.985 19.9C8.731 19.647 8.731 19.235 8.985 18.981L10.091 17.874ZM15.465 5.3C15.107 5.3 14.815 5.008 14.815 4.65C14.815 4.291 15.107 4 15.465 4H19.537C19.896 4 20.187 4.291 20.187 4.65C20.187 5.008 19.896 5.3 19.537 5.3H15.465ZM15.465 8.443C15.107 8.443 14.815 8.152 14.815 7.793C14.815 7.435 15.107 7.143 15.465 7.143H19.537C19.896 7.143 20.187 7.435 20.187 7.793C20.187 8.152 19.896 8.443 19.537 8.443H15.465ZM15.465 11.587C15.107 11.587 14.815 11.296 14.815 10.937C14.815 10.578 15.107 10.287 15.465 10.287H19.537C19.896 10.287 20.187 10.578 20.187 10.937C20.187 11.296 19.896 11.587 19.537 11.587H15.465ZM15.465 14.731C15.107 14.731 14.815 14.44 14.815 14.081C14.815 13.722 15.107 13.431 15.465 13.431H19.537C19.896 13.431 20.187 13.722 20.187 14.081C20.187 14.44 19.896 14.731 19.537 14.731H15.465ZM15.465 17.874C15.107 17.874 14.815 17.583 14.815 17.224C14.815 16.866 15.107 16.574 15.465 16.574H19.537C19.896 16.574 20.187 16.866 20.187 17.224C20.187 17.583 19.896 17.874 19.537 17.874H15.465Z"
      fill="currentColor"
    />
  </svg>,
  "Low Priority",
);
