import { Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import {
  availableProviders,
  customProvider,
} from "~/pages/space-settings/WorkloadIdentity/constants";
import { Flex } from "~/components/Flex";
import { AddButton } from "~/components/add-button";
import { SpaceScope } from "~/hooks/useScope";

type WorkloadIdentityProviderFormHeaderProps = {
  scope: SpaceScope;
};

export const WorkloadIdentityProviderFormHeader = ({
  scope,
}: WorkloadIdentityProviderFormHeaderProps) => {
  const { type } = useParams();
  const provider =
    availableProviders.find((provider) => provider.type === type) ||
    customProvider;

  const Icon = provider.icon;

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Flex alignItems="center" gap={1}>
        <Icon />
        <Typography
          component="h6"
          fontWeight={700}
          sx={{ textTransform: "uppercase", fontSize: 24 }}
        >
          ADD {provider.title} workload identity provider
        </Typography>
      </Flex>
      <AddButton
        id="providers-close"
        href={`/space/settings/workload-identity/providers?${scope.params}`}
        aria-label="Go to providers list"
        close
      />
    </Flex>
  );
};
