import {
  Box,
  BoxProps,
  SxProps,
  Theme,
  Typography,
  alpha,
} from "@mui/material";

export type MetricBoxProps = {
  label: string;
  value: string;
  description: string;
  color: string;
  highlighted: boolean;
  onMouseEnter: BoxProps["onMouseEnter"];
  onMouseLeave: BoxProps["onMouseLeave"];
  onFocus: BoxProps["onFocus"];
  onBlur: BoxProps["onBlur"];
  sx?: SxProps<Theme>;
  printView?: boolean;
};

export function MetricBox({
  label,
  value,
  description,
  color,
  highlighted,
  onMouseEnter,
  onMouseLeave,
  onFocus,
  onBlur,
  sx,
  printView,
}: MetricBoxProps) {
  return (
    <Box
      className={`metric-box ${highlighted ? "highlighted" : ""} ${printView ? "print-view" : ""}`}
      tabIndex={0}
      sx={[
        {
          borderRadius: 2,
          p: 1.5,
          m: -1.5,
          border: "1px solid",
          borderColor: "transparent",
          cursor: "default",
          position: "relative",

          ".metric-header": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            gap: 1,
            mb: 1,
          },
          ".metric-label": {
            fontWeight: 500,
          },
          ".metric-value": {
            fontWeight: 600,
            fontSize: 14,
            textTransform: "uppercase",
            color: "text.disabled",
            backgroundColor: (theme) => alpha(theme.palette.text.disabled, 0.1),
            borderRadius: 1,
            px: 1,
            py: 0.5,
            my: -0.25,
            flexBasis: "40%",
            flexShrink: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          ".metric-description": {
            fontSize: 12,
            color: "text.secondary",
          },
          "&.highlighted": {
            backgroundColor: "background.paper",
            boxShadow: (theme) =>
              `0 0 ${theme.spacing(3)} 0 ${alpha(
                theme.palette.common.black,
                0.33,
              )}`,
            outline: "none",
            zIndex: 1000,

            ".metric-value": {
              backgroundColor: alpha(color, 0.2),
              color: color,
            },
          },
          "&.print-view": {
            backgroundColor: "inherit",
            boxShadow: "unset",
            outline: "none",
            zIndex: 1000,
            ".metric-value": {
              backgroundColor: alpha(color, 0.2),
              color: color,
            },
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...(!printView && { onMouseEnter, onMouseLeave, onFocus, onBlur })}
    >
      <Box className="metric-header">
        <Typography className="metric-label">{label}</Typography>
        <Box className="metric-value">{value}</Box>
      </Box>
      <Box className="metric-body">
        <Typography className="metric-description">{description}</Typography>
      </Box>
    </Box>
  );
}
