import {
  ExceptionGroup,
  ExceptionType,
  ListExceptionGroupsDocument,
  SpaceCheckException,
  TestIamActionsQuery,
} from "~/operations";
import { useControlContext } from "../control";
import { Exceptions } from "~/components/exceptions/exceptions";
import {
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Link as RouterLink, useParams } from "react-router-dom";
import { useFetchExceptions } from "~/components/exceptions/use-fetch-exceptions";
import { formatPolicyName } from "~/pages/inventory/asset/asset-checks-tab";
import { ImpactUpdated } from "~/components/impact/Updated/impact-updated";

export type ExceptionsProps = {
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function ExceptionsWrapper({ availablePermissions }: ExceptionsProps) {
  const { space, controlMrn } = useControlContext();
  const { frameworkId, controlId } = useParams();

  const { exceptionGroups } = useFetchExceptions({
    mrn: controlMrn,
    scopeMrn: space.mrn,
    types: [ExceptionType.Security],
  });

  const buildUrl = (check: SpaceCheckException) => {
    return `/space/compliance/${frameworkId}/controls/${controlId}/check?spaceId=${space.id}&findingMrn=${check.mrn}`;
  };

  return (
    <Exceptions
      availablePermissions={availablePermissions}
      emptyStateType="control-exceptions"
      exceptionGroups={exceptionGroups}
      refetchQueries={[ListExceptionGroupsDocument]}
      renderTable={function (exceptionGroup: ExceptionGroup) {
        const { exceptions } = exceptionGroup;
        return (
          <Table sx={{ backgroundColor: "background.lighter" }}>
            <TableHead>
              <TableRow>
                <TableCell>Result</TableCell>
                <TableCell>Check</TableCell>
                <TableCell>Assets</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(exceptions as SpaceCheckException[]).map((check) => {
                return (
                  <TableRow
                    sx={{ cursor: "pointer" }}
                    component={RouterLink}
                    to={buildUrl(check)}
                  >
                    <TableCell>
                      {!!check.riskValue && (
                        <ImpactUpdated
                          isRiskScore
                          impact={{
                            value: check.riskValue,
                            rating: check.rating,
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <Typography
                        className="mquery-title"
                        fontSize={14}
                        display="inline-block"
                      >
                        {check.title}
                      </Typography>
                      <List sx={{ p: 0 }}>
                        <ListItem
                          sx={{ p: 0 }}
                          key={formatPolicyName(check.policyMrn, space.id)}
                        >
                          <Typography
                            variant="caption"
                            color="text.secondary"
                            fontSize={10}
                          >
                            {formatPolicyName(check.policyMrn, space.id)}
                          </Typography>
                        </ListItem>
                      </List>
                    </TableCell>
                    <TableCell>{check.assetsStats.total}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        );
      }}
    />
  );
}
