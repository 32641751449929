import { Box, Grid } from "@mui/material";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import { CopyTableToClipboardProps } from "~/components/copy-table-to-clipboard";
import { Space } from "~/lib/types";
import { AssetSoftwareScanningEmptyState } from "./AssetSoftwareScanningEmptyState";
import { useAssetSoftware } from "./hooks/useAssetSoftware";
import { AssetSoftwareTableEmptyState } from "~/pages/inventory/components/AssetSoftware/AssetSoftwareTableEmptyState";
import { AssetSoftwareData } from "~/pages/inventory/components/AssetSoftware/AssetSoftwareData";
import { AssetSoftwareFilterSection } from "~/pages/inventory/components/AssetSoftware/AssetSoftwareFilterSection/AssetSoftwareFilterSection";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";

type SoftwareProps = {
  assetMrn: string;
  clipboardDetails: CopyTableToClipboardProps["tableData"]["details"];
  scope: SpaceOrWorkspaceScope;
  space: Space;
  isCicd?: boolean;
  isVulnerabilityScanningEnabled: boolean;
};

export const AssetSoftware = ({
  assetMrn,
  clipboardDetails,
  scope,
  space,
  isVulnerabilityScanningEnabled,
}: SoftwareProps) => {
  const {
    isLoading,
    isLoadingFailed,
    isScanningEmptyState,
    packageScores,
    emptyStateVariant,
    showCopyToClipboard,
    tableData,
    displayedFilterTypes,
    filter,
    handleFilter,
    searchFilters,
    handleClearSearch,
    handleFilterQuery,
    hasData,
    handleSortClick,
    orderBy,
    fetchMore,
  } = useAssetSoftware({
    assetMrn,
    clipboardDetails,
    isVulnerabilityScanningEnabled,
  });

  if (isLoading) {
    return <LoadingPage what="packages" />;
  }

  if (isLoadingFailed) {
    return <LoadingFailedPage what="packages" />;
  }

  if (isScanningEmptyState) {
    return (
      <AssetSoftwareScanningEmptyState
        space={space}
        packageScores={packageScores}
        variant={emptyStateVariant}
      />
    );
  }

  return (
    <Box>
      <AssetSoftwareFilterSection
        displayedFilterTypes={displayedFilterTypes}
        filter={filter}
        handleFilter={handleFilter}
        searchFilters={searchFilters}
        handleFilterQuery={handleFilterQuery}
        showCopyToClipboard={showCopyToClipboard}
        tableData={tableData}
      />
      {hasData ? (
        <AssetSoftwareData
          scope={scope}
          packageScores={packageScores}
          handleSortClick={handleSortClick}
          orderBy={orderBy}
          assetMrn={assetMrn}
          fetchMore={fetchMore}
        />
      ) : (
        <Grid item xs={12} mt={3}>
          <AssetSoftwareTableEmptyState
            variant={emptyStateVariant}
            packageScores={packageScores}
            handleResetFilters={() =>
              handleClearSearch(["packageType", "queryTerms"])
            }
          />
        </Grid>
      )}
    </Box>
  );
};
