import { Fragment } from "react/jsx-runtime";
import { SectionHeading } from "../../components";
import { Grid2, TableRow } from "@mui/material";
import { NoItemsSection } from "../components";
import { GenericTable } from "~/components/GenericTable/GenericTable";
import { ImpactCell, RiskFactorsCell } from "~/components/Cells";
import { ScoreRating } from "~/operations";
import { AssetCell } from "~/components/FirewatchRow/AssetCell";
import { FirstFoundCell } from "~/components/FirewatchRow/FirstFoundCell";
import React from "react";
import { AffectedAsset } from "~/pages/space/vulnerabilities/components/AffectedAssets/AffectedAssetsTable";

type AffectedAssetsProps = {
  assets: Array<AffectedAsset> | undefined;
  heading: string;
};

export function AffectedAssets({ assets, heading }: AffectedAssetsProps) {
  return (
    <Fragment>
      <SectionHeading heading={heading} />

      <Grid2 size={12} sx={{ my: 3 }}>
        {!assets ? (
          <NoItemsSection target={heading} />
        ) : (
          <GenericTable
            items={0}
            tableName={`CHECK_REPORT_AFFECTED_ASSETS`}
            validSortFields={[]}
            printView
          >
            {assets?.map((node) => {
              return (
                <TableRow key={node?.id}>
                  <ImpactCell
                    impact={node?.score || 0}
                    rating={node?.rating || ScoreRating.Critical}
                    isActive
                    hideTooltip
                  />
                  {/* Asset */}
                  <AssetCell
                    title={node?.title}
                    iconId={node?.iconId}
                    tags={node?.tags}
                  />

                  {/* Risk Factors */}
                  <RiskFactorsCell riskFactors={node?.riskFactors} printView />

                  {/* Last Updated */}
                  <FirstFoundCell discoveryTime={node?.lastUpdated} />
                </TableRow>
              );
            })}
          </GenericTable>
        )}
      </Grid2>
    </Fragment>
  );
}
