import { Box, Grid, Typography } from "@mui/material";
import { DynamicIntegrationIcon } from "~/pages/integrations/components/dynamic-integration-icon";
import { formatIntegrationName } from "~/lib/integrations";
import {
  GetOrganizationOverviewQuery,
  IntegrationType,
  useGetOrganizationOverviewQuery,
} from "~/operations";
import DashboardSkeleton from "~/pages/organization/dashboard/DashboardSkeleton";

type RawData = NonNullable<
  GetOrganizationOverviewQuery["organizationOverview"]
>["integrationSummaries"];
type Datum = NonNullable<RawData>[0];
type Data = Datum[];

export type IntegrationsOverviewProps = {
  organizationMrn: string;
};

export function IntegrationsOverview({
  organizationMrn,
}: IntegrationsOverviewProps) {
  const result = useGetOrganizationOverviewQuery({
    variables: { input: { organizationMrn } },
  });

  if (result.loading) {
    return <DashboardSkeleton />;
  }

  const rawData = result.data?.organizationOverview?.integrationSummaries || [];
  const data = parseData(rawData);

  if (data.length === 0) {
    return (
      <Typography
        sx={{
          fontWeight: "bold",
          color: "text.secondary",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          height: "100%",
        }}
      >
        Enable integrations to determine their vulnerabilities
      </Typography>
    );
  }

  return (
    <Grid container>
      {data.map((item) => (
        <Grid item lg={3} xs={6} key={item.type}>
          <Box sx={{ display: "flex" }}>
            <DynamicIntegrationIcon
              type={item.type}
              sx={{ fontSize: 40, mr: 2 }}
            />
            <Box>
              <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
                {/* 
                    todo: may need to update `formatIntegrationName` to accept options/variants:

                      formatIntegrationName(IntegrationType.Gcp) => "Google Cloud Platform";
                      formatIntegrationName(IntegrationType.Gcp, "full") => "Google Cloud Platform";
                      formatIntegrationName(IntegrationType.Gcp, "short") => "GCP";

                    Even so, we'll want a component mockup to use a particular variant consistently,
                    otherwise we'll still need to sprinkle in special overrides like below:

                    item.type === IntegrationType.Gcp
                      ? formatIntegrationName(item.type, "short")
                      : formatIntegrationName(item.type)}
                  */}
                {item.type === IntegrationType.Gcp
                  ? "GCP"
                  : formatIntegrationName(item.type)}
              </Typography>
              <Typography sx={{ fontSize: 12 }}>{item.totalCount}</Typography>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}

function parseData(rawData: RawData): Data {
  return rawData?.filter((d) => d.type !== IntegrationType.Unknown) || [];
}
