import { Fragment, useEffect, useState } from "react";
import {
  Box,
  Button,
  Link,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Dropzone, DropzoneProps, DropzoneText } from "~/components/dropzone";
import { ChevronRightIcon, OpenInNewIcon } from "~/components/icons";
import { Command } from "~/components/guides/components";
import { Space } from "~/lib/types";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  ActionType,
  ClientIntegrationType,
  GetClientIntegrationDocument,
  GetIntegrationsSummaryDocument,
  IntegrationType,
  useCreateClientIntegrationMutation,
  useTriggerActionLazyQuery,
  useUpdateClientIntegrationConfigurationMutation,
} from "~/operations";
import { useSnackbar } from "notistack";
import { GreenCheckCircleIcon } from "~/pages/invitation";
import { AnimatePresence, motion } from "framer-motion";
import { fadeInOut } from "~/lib/animations";
import { useNavigate } from "react-router-dom";
import { RecommendedPolicies } from "../../components/recommended-policies";
import { IntegrationAddHeader } from "../../headers/integration-add-header";
import { getError } from "~/lib/handle-error";
import {
  EMAIL_PATTERN,
  helperTextStyles,
  ValidationMessage,
} from "../../validations/helpers";
import useGenerateIntegrationName from "../../utils/useGenerateIntegrationName";
import { UpdateFlowData } from "../../types";
import { setDocumentTitle } from "~/utils/commonUtils";

type GoogleWorkspaceFormInput = {
  integrationName: string;
  customerId: string;
  impersonatedUserEmail: string;
  serviceAccount: string | null;
};

const defaultValues: GoogleWorkspaceFormInput = {
  integrationName: "",
  customerId: "",
  impersonatedUserEmail: "",
  serviceAccount: null,
};

export function GoogleWorkspaceIntegrationForm({
  space,
  updateFlow,
}: {
  space: Space;
  updateFlow?: UpdateFlowData;
}) {
  let navigate = useNavigate();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [view, setView] = useState<"setup" | "add policies">("setup");
  const defaultIntegrationName = useGenerateIntegrationName({ space });

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    getFieldState,
    formState: { errors, isValid, isSubmitSuccessful },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      integrationName: defaultIntegrationName,
    },
  });

  const [createIntegration] = useCreateClientIntegrationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      const integrationMrn = data.createClientIntegration.integration.mrn;
      triggerClientIntegrationScan({
        variables: { input: { mrn: integrationMrn, type: ActionType.RunScan } },
      });
    },
    refetchQueries: [
      {
        query: GetIntegrationsSummaryDocument,
        variables: { input: { spaceMrn: space.mrn } },
      },
    ],
  });

  const [updateIntegration] = useUpdateClientIntegrationConfigurationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    refetchQueries: [
      {
        query: GetClientIntegrationDocument,
        variables: {
          mrn: `//integration.api.mondoo.app/spaces/${
            space.id
          }/integrations/${updateFlow?.integration.mrn.split("/").pop()}`,
        },
      },
    ],
  });

  const [triggerClientIntegrationScan] = useTriggerActionLazyQuery({
    onError(error) {
      console.log("%c Error Scheduling scan on creation", "color: tomato");
      console.log(error.message);
    },
  });

  useEffect(() => {
    if (updateFlow) {
      if (
        updateFlow.integration.configurationOptions?.__typename ===
        "GoogleWorkspaceConfigurationOptions"
      ) {
        reset({
          integrationName: updateFlow.integration.name,
          customerId: updateFlow.integration.configurationOptions.customerId,
          impersonatedUserEmail:
            updateFlow.integration.configurationOptions.impersonatedUserEmail,
        });
      }
    }
  }, []);

  useEffect(() => {
    if (isSubmitSuccessful && !updateFlow) {
      reset(defaultValues);
    }
  }, [isSubmitSuccessful]);

  const [serviceAccount] = watch(["serviceAccount"]);

  const onSubmit: SubmitHandler<GoogleWorkspaceFormInput> = async (data) => {
    const googleWorkspaceConfigurationOptions = {
      customerId: data.customerId,
      impersonatedUserEmail: data.impersonatedUserEmail,
      serviceAccount: data.serviceAccount,
    };

    try {
      if (updateFlow) {
        const integrationId = updateFlow.integration.mrn.split("/").pop();
        const mrn = `//integration.api.mondoo.app/spaces/${space.id}/integrations/${integrationId}`;
        await updateIntegration({
          variables: {
            input: {
              mrn,
              name: data.integrationName.trim(),
              type: ClientIntegrationType.GoogleWorkspace,
              configurationOptions: {
                googleWorkspaceConfigurationOptions,
              },
            },
          },
        });

        enqueueSnackbar("Successfully updated configuration", {
          variant: "success",
        });
        navigate(
          `/space/integrations/google_workspace/${integrationId}/?spaceId=${space.id}`,
        );
      } else {
        await createIntegration({
          variables: {
            input: {
              spaceMrn: space.mrn,
              name: data.integrationName.trim(),
              type: ClientIntegrationType.GoogleWorkspace,
              longLivedToken: false,
              configurationOptions: {
                googleWorkspaceConfigurationOptions,
              },
            },
          },
        });
        setView("add policies");
      }
    } catch (e) {
      const msg = getError(e);
      enqueueSnackbar(msg, { variant: "error" });
    }
  };

  const onDropAccepted: DropzoneProps["onDropAccepted"] = async (files) => {
    setValue("serviceAccount", await files[0].text());
  };

  setDocumentTitle(["GoogleWorkspace", "Integrations Setup"]);

  return (
    <Fragment>
      {view === "setup" ? (
        <Fragment>
          <IntegrationAddHeader
            {...{ type: IntegrationType.GoogleWorkspace }}
          />
          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* Step 1 */}
              <Box pb={4}>
                <Command
                  number={1}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  Choose an integration name
                </Command>

                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2, mt: 2 }}
                  >
                    Please choose a descriptive name that lets you easily
                    identify your integration.
                  </Typography>
                  <Controller
                    name="integrationName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        sx={{
                          background: theme.palette.code.background,
                          borderRadius: 1,
                          color: "text.primary",
                          ...helperTextStyles,
                        }}
                        placeholder="Your integration name..."
                        error={Boolean(errors.integrationName)}
                        helperText={
                          Boolean(errors.integrationName) && (
                            <ValidationMessage error={errors.integrationName} />
                          )
                        }
                      />
                    )}
                  />
                </Box>
              </Box>

              {/* Step 2 */}
              <Box pb={4}>
                <Command
                  number={2}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  Enter your customer ID
                </Command>

                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2, mt: 2 }}
                  >
                    Find your customer ID in your Google Workspace account
                    settings. To learn more, read the{" "}
                    <Link
                      href="https://support.google.com/a/answer/10070793"
                      target="_blank"
                      rel="noopener"
                    >
                      Google documentation
                    </Link>
                    .
                  </Typography>
                  <Controller
                    name="customerId"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        sx={{
                          background: theme.palette.code.background,
                          borderRadius: 1,
                          color: "text.primary",
                          ...helperTextStyles,
                        }}
                        placeholder="XXXXXXXXX"
                        error={Boolean(errors.customerId)}
                        helperText={
                          getFieldState("customerId").isTouched &&
                          Boolean(errors.customerId) && (
                            <ValidationMessage
                              error={errors.customerId}
                              integrationTypeId="google_workspace"
                            />
                          )
                        }
                      />
                    )}
                  />
                </Box>
              </Box>
              {/* Step 3 */}
              <Box pb={4}>
                <Command
                  number={3}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  Mandated user's email
                </Command>

                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2, mt: 2 }}
                  >
                    Specify on whose behalf the integration should act. You can
                    choose any user that is part of your workspace, as long as
                    they have Super Admin permissions. To learn more, read the{" "}
                    <Link
                      href="https://cloud.google.com/resource-manager/docs/super-admin-best-practices"
                      target="_blank"
                      rel="noopener"
                    >
                      Google documentation
                    </Link>
                    .
                  </Typography>
                  <Controller
                    name="impersonatedUserEmail"
                    control={control}
                    rules={{
                      required: true,
                      validate: {
                        isEmail: (value) =>
                          Boolean(value.match(EMAIL_PATTERN)) ||
                          "Not a valid email",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        sx={{
                          background: theme.palette.code.background,
                          borderRadius: 1,
                          color: "text.primary",
                          ...helperTextStyles,
                        }}
                        placeholder="yourworkspacemember@googlemail.com"
                        error={Boolean(errors.impersonatedUserEmail)}
                        helperText={
                          getFieldState("impersonatedUserEmail").isTouched &&
                          Boolean(errors.impersonatedUserEmail) && (
                            <ValidationMessage
                              error={errors.impersonatedUserEmail}
                              integrationTypeId="google_workspace"
                            />
                          )
                        }
                      />
                    )}
                  />
                </Box>
              </Box>
              {/* step 4 */}
              <Box pb={4}>
                <Command
                  number={4}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  Create your Google service account
                </Command>

                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 3, mt: 2 }}
                  >
                    Before you can integrate your Google Cloud Platform
                    integration or project with any third party service, you
                    must create a service account. You can do this in the Google
                    Cloud console. To learn how, read the{" "}
                    <Link
                      href="https://cloud.google.com/iam/docs/creating-managing-service-accounts"
                      target="_blank"
                      rel="noopener"
                    >
                      Google documentation
                    </Link>
                    .
                  </Typography>
                  <Button
                    href="https://console.cloud.google.com/"
                    target="_blank"
                    variant="outlined"
                    color="secondary"
                    endIcon={<OpenInNewIcon />}
                  >
                    Go to Google Cloud console
                  </Button>
                </Box>
              </Box>
              {/* step 5 */}
              <Box pb={4}>
                <Command
                  number={5}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  Link your service account to your workspace
                </Command>

                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 3, mt: 2 }}
                  >
                    Your service account needs permissions to access your
                    workspace. Grant these in the Workspace API controls in the
                    Google Cloud console. To learn how, read the{" "}
                    <Link
                      href="https://mondoo.com/docs/platform/infra/saas/google_workspace/"
                      target="_blank"
                      rel="noopener"
                    >
                      Mondoo documentation
                    </Link>
                    .
                  </Typography>
                  <Button
                    href="https://console.cloud.google.com/"
                    target="_blank"
                    variant="outlined"
                    color="secondary"
                    endIcon={<OpenInNewIcon />}
                  >
                    Go to Google Cloud console
                  </Button>
                </Box>
              </Box>
              {/* step 6 */}
              {/* When in the updateFlow, we do not want to update the certificate */}
              {!updateFlow && (
                <Box pb={4}>
                  <Command
                    number={6}
                    options={{
                      fontSize: { xs: 16 },
                      dotColor: theme.palette.background.lightest,
                    }}
                  >
                    Provide your Google service account config
                  </Command>
                  <Box>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mb: 2, mt: 2 }}
                    >
                      Upload the .json file generated in the previous step. To
                      learn more, read the{" "}
                      <Link
                        href="https://mondoo.com/docs/platform/infra/saas/google_workspace/"
                        target="_blank"
                        rel="noopener"
                      >
                        Mondoo documentation
                      </Link>
                      .
                    </Typography>
                    <AnimatePresence mode="wait">
                      {!serviceAccount ? (
                        <Box
                          key="dropzone"
                          component={motion.div}
                          {...fadeInOut}
                        >
                          <Dropzone
                            className="mondoo-dropzone-area"
                            accept={{ "application/json": [] }}
                            multiple={false}
                            showAlerts={["error"]}
                            onDropAccepted={onDropAccepted}
                          >
                            <DropzoneText>
                              Drag and drop your .json file here.
                            </DropzoneText>
                          </Dropzone>
                        </Box>
                      ) : (
                        <Box
                          key="success"
                          component={motion.div}
                          {...fadeInOut}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            py: 5,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 2,
                            }}
                          >
                            <Typography variant="h6">
                              Successfully loaded JSON file
                            </Typography>
                            <GreenCheckCircleIcon
                              fontSize="large"
                              sx={{ ml: 2 }}
                            />
                          </Box>

                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => setValue("serviceAccount", "")}
                          >
                            Remove JSON file
                          </Button>
                        </Box>
                      )}
                    </AnimatePresence>
                  </Box>
                </Box>
              )}

              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  endIcon={<ChevronRightIcon />}
                  // setValue doesn't trigger the required validation so we're doing it manually here
                  disabled={updateFlow ? !isValid : !isValid || !serviceAccount}
                >
                  {updateFlow ? "update configuration" : "start scanning"}
                </Button>
              </Box>
            </form>
          </Box>
        </Fragment>
      ) : (
        <RecommendedPolicies
          {...{ space }}
          filterTypes={["google-workspace"]}
        />
      )}
    </Fragment>
  );
}
