import { Fragment } from "react/jsx-runtime";
import { SectionHeading } from "~/components/DetailsPage/components/SectionHeading";
import { Link as RouterLink } from "react-router-dom";
import {
  alpha,
  Button,
  Divider,
  Grid2,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ArrowForwardIcon,
  FileCopyIcon,
  InfoOutlinedIcon,
} from "~/components/icons";
import { useCveOnAssetInsights } from "../../hooks";
import { AssetOverviewGroup } from "~/components/asset-configuration-section";

import { copyToClipboard } from "~/lib/clipboard";
import { extractAssetKey, extractAssetValue } from "~/utils/assetUtils";
import { isCheckFinding } from "~/pages/findings/FindingOnAsset/utils";

export function AssetInsights() {
  const { evidenceGroup, platformGroup, linkToAsset, finding } =
    useCveOnAssetInsights();

  return (
    <Fragment>
      <SectionHeading
        heading="Asset insights"
        {...(linkToAsset && {
          dividerContent: (
            <Button
              component={RouterLink}
              to={linkToAsset}
              endIcon={<ArrowForwardIcon />}
              sx={{ color: "secondary.light" }}
            >
              Go to asset
            </Button>
          ),
        })}
      />
      <Grid2
        container
        size={12}
        rowSpacing={4}
        columnSpacing={10}
        justifyContent="space-between"
      >
        {!isCheckFinding(finding) && (
          <Grid2 size={{ xs: 12, md: 6 }}>
            <AssetInsightsSectionHeading
              title="Evidence"
              tooltip="Evidence gives substance to a security finding. It can include file versions, exploit paths, or other supporting details."
            />
            {evidenceGroup && <AssetInsightsTable group={evidenceGroup} />}
            {/* {evidenceGroup && <AssetInsightsTable group={evidenceGroup} />} */}
          </Grid2>
        )}
        <Grid2 size={{ xs: 12, md: 6 }}>
          <AssetInsightsSectionHeading title="Asset Details" />
          {platformGroup && <AssetInsightsTable group={platformGroup} />}
        </Grid2>
        {/* <Grid2 size={{ xs: 12, md: "auto" }}>
          <AssetInsightsSectionHeading title="Users & Sessions" />
          <AssetInsightsTable />
        </Grid2> */}
        {/* <Grid2 size={{ xs: 12, md: "auto" }}>
          <AssetInsightsSectionHeading title="Listening Services" />
          <AssetInsightsTable />
        </Grid2> */}
        {/* <Grid2 size={12}>
          <AssetInsightsSectionHeading title="Lateral Movement Risk" />
          <Stack
            direction="row"
            alignItems="center"
            mt={3}
            columnGap={4}
            rowGap={2}
            flexWrap="wrap"
          >
            {Array.from({ length: 3 }).map((_, i) => {
              return (
                <Stack direction="row" gap={2} key={i} sx={{ width: 264 }}>
                  <EKSClustersIcon sx={{ fontSize: 40, borderRadius: 1 }} />
                  <Stack direction="column">
                    <Typography sx={{ fontWeight: 700 }}>
                      EKS Account
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 12,
                        color: "text.secondary",
                        textTransform: "uppercase",
                        lineHeight: "16px",
                      }}
                    >
                      1 discovered
                    </Typography>
                  </Stack>
                </Stack>
              );
            })}
          </Stack>
        </Grid2> */}
      </Grid2>
    </Fragment>
  );
}

export function AssetInsightsSectionHeading({
  title,
  tooltip,
}: {
  title: string;
  tooltip?: string | JSX.Element;
}) {
  return (
    <Fragment>
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography
          variant="body2"
          sx={{ textTransform: "uppercase", fontWeight: 500 }}
        >
          {title}
        </Typography>
        {tooltip && (
          <Tooltip title={tooltip || ""}>
            <InfoOutlinedIcon
              sx={{
                fontSize: 12,
                color: (theme) => alpha(theme.palette.text.primary, 0.8),
              }}
            />
          </Tooltip>
        )}
      </Stack>
      <Divider sx={{ my: 1 }} />
    </Fragment>
  );
}

export function AssetInsightsTable({ group }: { group: AssetOverviewGroup }) {
  const copyValue = (value: string) => {
    if (!value) return;
    copyToClipboard(value);
    // show success state for 1.5s
    const button = document.querySelector(`button[data-key="${value}"]`);
    if (button) {
      button.classList.add("success");
      setTimeout(() => {
        button.classList.remove("success");
      }, 1500);
    }
  };

  return (
    <Table
      size="small"
      padding="normal"
      sx={{
        "& .MuiTableRow-root:hover": {
          background: "transparent",
        },
        "& .MuiTableCell-root": {
          px: 0,
          border: "none",
          fontSize: 14,
        },
        // only show the copy icon on hover
        "& button": { visibility: "hidden" },
        "& button.success": { visibility: "visible" },
        "& button.success .copy-icon": {
          color: "success.main",
        },
        "& .MuiTableCell-root:hover button": {
          visibility: "visible",
        },
      }}
    >
      <TableBody>
        {group?.items.map((item) => {
          const key = extractAssetKey(item);
          const value = extractAssetValue(item);

          return (
            <TableRow key={item.key}>
              <TableCell width="50%" sx={{ verticalAlign: "text-top" }}>
                <Typography sx={{ color: "text.secondary" }}>{key}</Typography>
              </TableCell>
              <TableCell width="50%" sx={{ verticalAlign: "text-top" }}>
                <Stack direction="row" alignItems="flex-start" gap={1}>
                  <Typography>{value}</Typography>

                  <button
                    onClick={() => copyValue(value)}
                    data-key={value}
                    style={{
                      background: "none",
                      border: "none",
                      transform: "translateY(2px)",
                      cursor: "pointer",
                    }}
                  >
                    <FileCopyIcon
                      className="copy-icon"
                      color="primary"
                      sx={{ fontSize: 16 }}
                    />
                  </button>
                </Stack>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
