import { ExceptionTarget } from "~/components/exceptions/types";

export const targetLabelMap: Record<ExceptionTarget, string> = {
  check: "check",
  control: "control",
  asset: "asset",
  advisory: "advisory",
  cve: "vulnerability",
  finding: "finding",
};
