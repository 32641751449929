import { ReactNode, useLayoutEffect, useRef, useState } from "react";
import { alpha, Box, Button, Collapse } from "@mui/material";
import { useColorMode } from "~/providers/color-mode";

export type ShowMoreProps = {
  children: ReactNode;
  maxHeight?: number;
  id?: string;
};

export function ShowMore({ children, maxHeight = 280, id }: ShowMoreProps) {
  const [moreOpen, setMoreOpen] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>();
  const { mode } = useColorMode();
  useLayoutEffect(() => {
    const handleResize = () => {
      setContentHeight(contentRef.current?.clientHeight || 0);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [contentRef.current]);

  const handleShowClick = () => {
    setMoreOpen(!moreOpen);
  };

  const isClipped = contentHeight > maxHeight;

  return (
    <Box id={id} className="show-more" data-testid="show-more">
      <Collapse
        in={moreOpen}
        collapsedSize={isClipped ? maxHeight : contentHeight}
        className="show-more-visible"
      >
        <Box ref={contentRef} display="flex" className="show-more-content">
          {children}
        </Box>
      </Collapse>
      {isClipped && (
        <Box
          className="show-more-actions"
          sx={{
            position: "relative",
            "&::after": {
              content: '""',
              position: "absolute",
              background: (theme) =>
                `linear-gradient(${alpha(
                  theme.palette.background.default,
                  0,
                )}, ${theme.palette.background.default})`,
              height: (theme) => theme.spacing(5),
              top: (theme) => theme.spacing(-5),
              left: 0,
              right: 0,
              opacity: moreOpen ? 0 : 1,
              transition: (theme) => theme.transitions.create("opacity"),
            },
          }}
        >
          <Button
            className="show-more-toggle"
            variant="text"
            color="secondary"
            onClick={handleShowClick}
            sx={{
              px: 0,
              background: "transparent",
              textTransform: "none",
              color: mode === "light" ? "secondary.main" : "secondary.light",
              fontWeight: 600,
              "&:hover": { background: "transparent" },
            }}
          >
            Show {moreOpen ? "less" : "more"}
          </Button>
        </Box>
      )}
    </Box>
  );
}
