import { TableCell } from "@mui/material";
import { CommonCellProps } from "~/pages/inventory/components/AssetSoftware/types";
import { ImpactUpdated } from "~/components/impact/Updated/impact-updated";

type ImpactCellProps = {
  hideTooltip?: boolean;
};

export const ImpactCell = ({
  pckg,
  hideTooltip,
  ...props
}: CommonCellProps & ImpactCellProps) => {
  return (
    <TableCell data-testid={props["data-testid"]}>
      <ImpactUpdated
        hideTooltip={hideTooltip}
        impact={{
          rating: pckg.rating,
          value: pckg.riskValue,
        }}
        isRiskScore
      />
    </TableCell>
  );
};
