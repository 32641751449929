import { Fragment } from "react/jsx-runtime";
import { SectionHeading } from "~/components/DetailsPage/components";
import { CveSources } from "~/components/vulnerabilities/cve-sources";
import { useFindingOnAsset } from "../../hooks";

export function References() {
  const { finding, cveData } = useFindingOnAsset();
  // SOURCES DO NOT EXIST ON THE FINDING
  if (!cveData?.source) {
    return null;
  }

  return (
    <Fragment>
      <SectionHeading heading="References" />
      {/* TODO - MODIFY TO HOLD ICONS */}
      <CveSources
        id="asset-on-cve-sources"
        url={cveData?.url}
        source={cveData?.source}
        references={cveData?.references}
        showTitle={false}
      />
    </Fragment>
  );
}
