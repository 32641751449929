import { Fragment, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { ChevronRightIcon } from "~/components/icons";
import { Command } from "~/components/guides/components";
import { Space } from "~/lib/types";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  ActionType,
  BucketOutputType,
  ClientIntegrationType,
  GetClientIntegrationDocument,
  GetIntegrationsSummaryDocument,
  IntegrationType,
  useCreateClientIntegrationMutation,
  useTriggerActionLazyQuery,
  useUpdateClientIntegrationConfigurationMutation,
} from "~/operations";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

import { getError } from "~/lib/handle-error";
import { IntegrationAddHeader } from "../../headers/integration-add-header";
import { helperTextStyles, ValidationMessage } from "../../validations/helpers";
import useGenerateIntegrationName from "../../utils/useGenerateIntegrationName";
import { UpdateFlowData } from "../../types";
import { PasswordField } from "~/components/Form/components/PasswordField";
import { setDocumentTitle } from "~/utils/commonUtils";

type S3CompatibleFormInput = {
  integrationName: string;
  endpoint: string;
  bucket: string;
  output: BucketOutputType;
  useLegacyList: boolean;
  usePathStyle: boolean;
  accessKey: string;
  secretAccessKey: string;
};

const defaultValues: S3CompatibleFormInput = {
  integrationName: "",
  endpoint: "",
  output: BucketOutputType.Jsonl,
  bucket: "",
  useLegacyList: false,
  usePathStyle: false,
  accessKey: "",
  secretAccessKey: "",
};

export function S3CompatibleIntegrationForm({
  space,

  updateFlow,
}: {
  space: Space;
  updateFlow?: UpdateFlowData;
}) {
  let navigate = useNavigate();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const defaultIntegrationName = useGenerateIntegrationName({ space });

  const {
    control,
    handleSubmit,
    reset,
    getFieldState,
    formState: { errors, isValid, isSubmitSuccessful },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      integrationName: defaultIntegrationName,
    },
  });

  const [createIntegration] = useCreateClientIntegrationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      const integrationMrn = data.createClientIntegration.integration.mrn;
      triggerClientIntegrationScan({
        variables: {
          input: { mrn: integrationMrn, type: ActionType.RunExport },
        },
      });
    },
    refetchQueries: [
      {
        query: GetIntegrationsSummaryDocument,
        variables: { input: { spaceMrn: space.mrn } },
      },
    ],
  });

  const [updateIntegration] = useUpdateClientIntegrationConfigurationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    refetchQueries: [
      {
        query: GetClientIntegrationDocument,
        variables: {
          mrn: `//integration.api.mondoo.app/spaces/${
            space.id
          }/integrations/${updateFlow?.integration.mrn.split("/").pop()}`,
        },
      },
    ],
  });

  const [triggerClientIntegrationScan] = useTriggerActionLazyQuery({
    onError(error) {
      console.log("%c Error Scheduling scan on creation", "color: tomato");
      console.log(error.message);
    },
  });

  useEffect(() => {
    if (updateFlow) {
      if (
        updateFlow.integration.configurationOptions?.__typename ===
        "S3ConfigurationOptions"
      ) {
        const {
          endpoint,
          bucket,
          output,
          useLegacyList,
          usePathStyle,
          accessKey,
        } = updateFlow.integration.configurationOptions;

        reset({
          integrationName: updateFlow.integration.name,
          endpoint,
          bucket,
          useLegacyList,
          usePathStyle,
          accessKey,
          output:
            output === "JSONL"
              ? BucketOutputType.Jsonl
              : output === "CSV"
                ? BucketOutputType.Csv
                : BucketOutputType.Unknown,
        });
      }
    }
  }, []);

  useEffect(() => {
    if (isSubmitSuccessful && !updateFlow) {
      reset(defaultValues);
    }
  }, [isSubmitSuccessful]);

  const onSubmit: SubmitHandler<S3CompatibleFormInput> = async (data) => {
    const s3ConfigurationOptions = {
      endpoint: data.endpoint.trim(),
      output: data.output,
      bucket: data.bucket.trim(),
      useLegacyList: data.useLegacyList,
      usePathStyle: data.usePathStyle,
      accessKey: data.accessKey.trim(),
      secretAccessKey: data.secretAccessKey.trim(),
    };

    try {
      if (updateFlow) {
        const integrationId = updateFlow.integration.mrn.split("/").pop();
        const mrn = `//integration.api.mondoo.app/spaces/${space.id}/integrations/${integrationId}`;
        await updateIntegration({
          variables: {
            input: {
              mrn,
              name: data.integrationName.trim(),
              type: ClientIntegrationType.S3,
              configurationOptions: {
                s3ConfigurationOptions,
              },
            },
          },
        });

        enqueueSnackbar("Successfully updated configuration", {
          variant: "success",
        });
        navigate(
          `/space/integrations/amazon-s3-compatible/${integrationId}/?spaceId=${space.id}`,
        );
      } else {
        await createIntegration({
          variables: {
            input: {
              spaceMrn: space.mrn,
              name: data.integrationName.trim(),
              type: ClientIntegrationType.S3,
              longLivedToken: false,
              configurationOptions: {
                s3ConfigurationOptions,
              },
            },
          },
        });
        navigate(
          `/space/integrations/amazon-s3-compatible?spaceId=${space.id}`,
        );
      }
    } catch (e) {
      const msg = getError(e);
      enqueueSnackbar(msg, { variant: "error" });
    }
  };

  setDocumentTitle(["S3 Compatible Service", "Integrations Setup"]);

  return (
    <Fragment>
      <IntegrationAddHeader {...{ type: IntegrationType.S3 }} />
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Step 1 */}
          <Box pb={4}>
            <Command
              number={1}
              options={{
                fontSize: { xs: 16 },
                dotColor: theme.palette.background.lightest,
              }}
            >
              Choose an integration name
            </Command>

            <Box>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mb: 2, mt: 2 }}
              >
                Please choose a descriptive name that lets you easily identify
                your integration.
              </Typography>
              <Controller
                name="integrationName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    sx={{
                      background: theme.palette.code.background,
                      borderRadius: 1,
                      color: "text.primary",
                      ...helperTextStyles,
                    }}
                    placeholder="Your integration name..."
                    error={Boolean(errors.integrationName)}
                    helperText={
                      Boolean(errors.integrationName) && (
                        <ValidationMessage error={errors.integrationName} />
                      )
                    }
                  />
                )}
              />
            </Box>
          </Box>

          {/* Step 2 */}
          <Box pb={4}>
            <Command
              number={2}
              options={{
                fontSize: { xs: 16 },
                dotColor: theme.palette.background.lightest,
              }}
            >
              Define the export destination
            </Command>

            <Box>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mb: 2, mt: 2 }}
              >
                Enter your storage setting details. Consult your vendor for more
                information on these settings.
              </Typography>
              <Typography fontWeight={700} sx={{ mb: 1 }}>
                S3 compatible endpoint URL
              </Typography>
              <Controller
                name="endpoint"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    sx={{
                      mb: 3,
                      background: theme.palette.code.background,
                      borderRadius: 1,
                      color: "text.primary",
                      ...helperTextStyles,
                    }}
                    placeholder="https://s3compat.mycloudservice.com"
                    error={Boolean(errors.endpoint)}
                    helperText={
                      getFieldState("endpoint").isTouched &&
                      Boolean(errors.endpoint) && (
                        <ValidationMessage
                          error={errors.endpoint}
                          integrationTypeId="s3"
                        />
                      )
                    }
                  />
                )}
              />

              <Typography fontWeight={700} sx={{ mb: 1 }}>
                Bucket name
              </Typography>
              <Controller
                name="bucket"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    sx={{
                      mb: 3,
                      background: theme.palette.code.background,
                      borderRadius: 1,
                      color: "text.primary",
                      ...helperTextStyles,
                    }}
                    placeholder="Name of target bucket ..."
                    error={Boolean(errors.bucket)}
                    helperText={
                      getFieldState("bucket").isTouched &&
                      Boolean(errors.bucket) && (
                        <ValidationMessage
                          error={errors.bucket}
                          integrationTypeId="s3"
                        />
                      )
                    }
                  />
                )}
              />

              <Typography fontWeight={700} sx={{ mb: 1 }}>
                Export as
              </Typography>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="filetype-select-label">
                  Select a filetype...
                </InputLabel>
                <Controller
                  name="output"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Fragment>
                      <Select
                        labelId="filetype-select-label"
                        id="filetype-select"
                        label="filetype"
                        error={Boolean(errors.output)}
                        sx={{
                          "& .MuiInputBase-input": {
                            backgroundColor: theme.palette.code.background,
                          },
                          "& .MuiSelect-icon": {
                            color: "text.secondary",
                          },
                        }}
                        {...field}
                      >
                        <MenuItem value={BucketOutputType.Jsonl}>
                          .jsonl
                        </MenuItem>
                        <MenuItem value={BucketOutputType.Csv}>.csv</MenuItem>
                      </Select>
                      {getFieldState("output").isTouched &&
                        Boolean(errors.output) && (
                          <FormHelperText>
                            <ValidationMessage
                              error={errors.output}
                              integrationTypeId="s3"
                            />
                          </FormHelperText>
                        )}
                    </Fragment>
                  )}
                />
              </FormControl>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mt: 3,
                  mb: 3,
                }}
              >
                <Box>
                  <Typography fontWeight={700} sx={{ mb: 1 }}>
                    Use legacy lists{" "}
                    <Typography
                      component="span"
                      sx={{ color: "text.secondary", fontStyle: "italic" }}
                    >
                      (if applicable)
                    </Typography>
                  </Typography>
                  <Typography color="text.secondary">
                    Use the legacy list objects API. Consult your vendor
                    documentation to see if this setting is required.
                  </Typography>
                </Box>
                <Box>
                  <Controller
                    name="useLegacyList"
                    control={control}
                    render={({ field }) => (
                      <Switch checked={field.value} {...field} size="small" />
                    )}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography fontWeight={700} sx={{ mb: 1 }}>
                    Use path style{" "}
                    <Typography
                      component="span"
                      sx={{ color: "text.secondary", fontStyle: "italic" }}
                    >
                      (if applicable)
                    </Typography>
                  </Typography>
                  <Typography color="text.secondary">
                    Use path style bucket addressing instead of virtual hosted
                    addressing. Consult your vendor documentation to see if this
                    setting is required.
                  </Typography>
                </Box>
                <Box>
                  <Controller
                    name="usePathStyle"
                    control={control}
                    render={({ field }) => (
                      <Switch checked={field.value} {...field} size="small" />
                    )}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          {/* step 3 */}
          <Box pb={4}>
            <Command
              number={3}
              options={{
                fontSize: { xs: 16 },
                dotColor: theme.palette.background.lightest,
              }}
            >
              Enter your credentials
            </Command>

            <Typography fontWeight={700} sx={{ mt: 3, mb: 1 }}>
              Access key ID
            </Typography>
            <Controller
              name="accessKey"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  sx={{
                    mb: 2,
                    background: theme.palette.code.background,
                    borderRadius: 1,
                    color: "text.primary",
                    ...helperTextStyles,
                  }}
                  placeholder="Your access key ..."
                  error={Boolean(errors.accessKey)}
                  helperText={
                    getFieldState("accessKey").isTouched &&
                    Boolean(errors.accessKey) && (
                      <ValidationMessage
                        error={errors.accessKey}
                        integrationTypeId="s3"
                      />
                    )
                  }
                />
              )}
            />

            <Typography fontWeight={700} sx={{ mb: 1 }}>
              Secret access key
            </Typography>
            <Controller
              name="secretAccessKey"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <PasswordField
                  {...field}
                  fullWidth
                  sx={{
                    background: theme.palette.code.background,
                    borderRadius: 1,
                    color: "text.primary",
                    ...helperTextStyles,
                  }}
                  placeholder="Your secret access key ..."
                  error={Boolean(errors.secretAccessKey)}
                  helperText={
                    getFieldState("secretAccessKey").isTouched &&
                    Boolean(errors.secretAccessKey) && (
                      <ValidationMessage
                        error={errors.secretAccessKey}
                        integrationTypeId="s3"
                      />
                    )
                  }
                />
              )}
            />
          </Box>

          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              endIcon={<ChevronRightIcon />}
              disabled={updateFlow ? !isValid : !isValid}
            >
              {updateFlow ? "update configuration" : "create export"}
            </Button>
          </Box>
        </form>
      </Box>
    </Fragment>
  );
}
