import React from "react";
import { SectionHeading } from "~/components/DetailsPage/components";
import { Box, Grid } from "@mui/material";
import { AggregateScoresEdges } from "~/components/FirewatchPage";
import { Space } from "~/lib/types";
import { AggregateScoreType, OrderDirection } from "~/operations";
import { FirewatchList } from "~/components/FirewatchList";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";

type ContainedFindingsSectionProps = {
  space: Space;
  scope: SpaceOrWorkspaceScope;
  pageType: AggregateScoreType;
  finding: NonNullable<AggregateScoresEdges>[0];
  loading: boolean;
};

export const ContainedFindingsSection = ({
  space,
  scope,
  pageType,
  finding,
  loading,
}: ContainedFindingsSectionProps) => {
  const getLabelByPageType = () => {
    switch (pageType) {
      case AggregateScoreType.Advisory:
        return "Advisory";
      case AggregateScoreType.Check:
        return "Check";
      case AggregateScoreType.Vulnerability:
        return "CVE";
      default:
        return "Finding";
    }
  };

  return (
    <Grid item xs={12}>
      <SectionHeading
        heading={`Contained ${getLabelByPageType() || "Finding"}`}
      />
      <Box className="section-content">
        <FirewatchList
          {...{
            space,
            scope,
            pageType,
            data: [finding],
            loading: loading,
            disableSorting: true,
            field: "",
            direction: OrderDirection.Asc,
            handleSortClick: () => {},
          }}
        />
      </Box>
    </Grid>
  );
};
