import { SvgIcon } from "@mui/material";

export const MacOsIcon = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <svg
        enableBackground="new 0 0 34 34"
        x="0px"
        y="0px"
        version="1.1"
        viewBox="0 0 34 34"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="MacOSIcon"
      >
        <path
          id="path2"
          fill="currentColor"
          d="M15.8,20.6c0,2.9-1.5,4.7-3.9,4.7s-3.9-1.8-3.9-4.7c0-2.9,1.5-4.7,3.9-4.7S15.8,17.8,15.8,20.6z
             M19.1,10.5l-1,0.1c-0.6,0-0.9,0.2-0.9,0.6c0,0.4,0.3,0.6,0.8,0.6c0.6,0,1.1-0.4,1.1-1L19.1,10.5z M34,17c0,9.5-7.5,17-17,17
            S0,26.5,0,17S7.5,0,17,0S34,7.5,34,17z M20.9,10.3c0,1.4,0.8,2.2,2,2.2c1,0,1.7-0.6,1.8-1.4h-0.8c-0.1,0.5-0.5,0.7-1,0.7
            c-0.7,0-1.1-0.6-1.1-1.5c0-0.9,0.4-1.5,1.1-1.5c0.6,0,0.9,0.3,1,0.7h0.8c-0.1-0.8-0.8-1.4-1.8-1.4C21.7,8.1,20.9,9,20.9,10.3z
             M9.5,8.2v4.2h0.8V9.9c0-0.6,0.4-1,0.9-1c0.5,0,0.8,0.3,0.8,0.8v2.8h0.8V9.8c0-0.5,0.4-0.9,0.9-0.9c0.6,0,0.8,0.3,0.8,0.9v2.7h0.8
            V9.6c0-0.9-0.5-1.4-1.4-1.4c-0.6,0-1.1,0.3-1.3,0.8h-0.1c-0.2-0.5-0.6-0.8-1.2-0.8c-0.6,0-1,0.3-1.2,0.8h-0.1V8.2L9.5,8.2z
             M17.3,20.6c0-3.7-2.1-6-5.4-6s-5.4,2.3-5.4,6s2.1,6,5.4,6S17.3,24.3,17.3,20.6z M17.8,12.5c0.6,0,1-0.2,1.3-0.7h0.1v0.6H20V9.6
            c0-0.9-0.6-1.4-1.7-1.4c-1,0-1.6,0.5-1.7,1.2h0.8c0.1-0.3,0.4-0.5,0.9-0.5c0.6,0,0.9,0.3,0.9,0.7V10L18,10c-1,0.1-1.6,0.5-1.6,1.3
            C16.4,12.1,17,12.5,17.8,12.5z M27,23.1c0-1.6-1-2.6-3.4-3.1l-1.3-0.3c-1.6-0.4-2.2-1-2.2-1.9c0-1.2,1.1-1.9,2.6-1.9
            c1.5,0,2.5,0.8,2.6,2.1h1.5c-0.1-2-1.7-3.4-4.1-3.4c-2.4,0-4.1,1.4-4.1,3.3c0,1.6,1,2.7,3.3,3.2l1.3,0.3c1.6,0.4,2.3,1,2.3,2
            c0,1.1-1.1,2-2.7,2c-1.7,0-2.8-0.8-3-2h-1.5c0.1,2,1.8,3.3,4.4,3.3C25.3,26.6,27,25.3,27,23.1z"
        />
      </svg>
    </SvgIcon>
  );
};

MacOsIcon.displayName = "MacOS";
