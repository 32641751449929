import { createSvgIcon } from "@mui/material";

export const WifiIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.8906 5.19531C14.9718 5.19423 17.9565 6.27014 20.3283 8.23691C21.0568 8.83048 21.1971 9.48521 20.7312 10.0482C20.2653 10.6112 19.652 10.5716 18.9325 10.0068C14.5778 6.52095 9.41558 6.52995 5.05373 10.0338C4.38102 10.5734 3.70291 10.5734 3.26583 10.014C2.82874 9.45463 2.94746 8.88265 3.61837 8.31965C5.93437 6.35779 8.85591 5.25435 11.8906 5.19531Z"
      fill="currentColor"
    />
    <path
      d="M12.2473 8.85557C14.3485 8.9104 16.3625 9.70685 17.9329 11.1039C18.5967 11.6885 18.7028 12.3504 18.2351 12.8757C17.7926 13.3793 17.1361 13.3523 16.5048 12.8253C13.6664 10.4708 10.4504 10.469 7.57244 12.8253C6.88174 13.3793 6.23241 13.3793 5.77914 12.8271C5.35285 12.3001 5.46077 11.6957 6.09391 11.1183C7.64799 9.70276 9.90536 8.84478 12.2473 8.85557Z"
      fill="currentColor"
    />
    <path
      d="M12.0229 12.4904C13.3437 12.5323 14.6092 13.0307 15.6041 13.9006C16.1707 14.4042 16.2408 15.0697 15.8109 15.5608C15.408 16.0212 14.7569 16.0554 14.1921 15.6471C12.4887 14.4294 11.5696 14.4276 9.85544 15.6381C9.26547 16.0554 8.64312 16.023 8.23661 15.5572C7.80492 15.0553 7.88586 14.3916 8.43986 13.8898C9.31943 13.0875 10.8681 12.4796 12.0229 12.4904Z"
      fill="currentColor"
    />
    <path
      d="M11.9977 16.3848C12.3551 16.3833 12.6988 16.5224 12.9546 16.7721C13.2103 17.0218 13.3576 17.362 13.3647 17.7194C13.3509 18.063 13.2094 18.3891 12.9679 18.634C12.7264 18.8788 12.4023 19.0247 12.0589 19.0433C11.882 19.0442 11.7067 19.01 11.5432 18.9425C11.3798 18.8751 11.2313 18.7758 11.1066 18.6504C10.9819 18.525 10.8834 18.376 10.8168 18.2122C10.7502 18.0483 10.7169 17.8729 10.7188 17.696C10.7155 17.3528 10.8481 17.0223 11.0877 16.7765C11.3274 16.5308 11.6545 16.39 11.9977 16.3848Z"
      fill="currentColor"
    />
  </svg>,
  "Network Devices",
);
