import { Box, TableCell, Typography } from "@mui/material";
import { AggregateScoresNode } from "../FirewatchPage";
import { Clamp } from "~/components/clamp/clamp";
import { DynamicIcon } from "~/components/icons/DynamicIcon";
import { FirewatchChip } from "~/components/FirewatchRow/ChipText";

export const NameCell = ({ row }: { row: AggregateScoresNode }) => {
  return (
    <TableCell>
      <Clamp>
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: 14,
            lineHeight: "16px",
            gap: 1,
            fontWeight: 700,
          }}
          data-testid="title"
        >
          <DynamicIcon iconId={row.iconId} size="16" />
          {row.title || row.nodeTitle}
        </Typography>
      </Clamp>
      <Box display="flex" gap={1} mt={1}>
        {row.tags?.map((tag) => <FirewatchChip key={tag.key} tag={tag} />)}
      </Box>
    </TableCell>
  );
};
