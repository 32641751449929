import { SxProps, Theme } from "@mui/material";
import { MetricBox } from "./metric-box";
import { CvssMetric } from "../cvss";
import { useCvssRisk } from "./useCvssRisk";

export type CvssMetricBoxProps = {
  metric: CvssMetric;
  sx?: SxProps<Theme>;
  isPrintView?: boolean;
};

export function CvssMetricBox({ sx, metric, isPrintView }: CvssMetricBoxProps) {
  const risk = useCvssRisk();
  const label = metric.field.title;
  const value = metric.value.title;
  const color = metric.value.color;
  const description = metric.field.description;
  const highlighted = risk.isFocused(metric);

  return (
    <MetricBox
      label={label}
      value={value}
      description={description}
      color={color}
      highlighted={highlighted}
      onMouseEnter={() => risk.onMetricMouseEnter(metric)}
      onMouseLeave={() => risk.onMetricMouseLeave(metric)}
      onFocus={() => risk.onMetricFocus(metric)}
      onBlur={() => risk.onMetricBlur(metric)}
      sx={sx}
      printView={isPrintView}
    />
  );
}
