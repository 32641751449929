import { BarChartIcon } from "~/components/ui-library";
import {
  Button,
  Checkbox,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { Fragment } from "react";
import { DataTable, SelectionToolbar } from "~/components/data-table";
import { usePolicies } from "./hooks/usePolicies";
import { ActivePolicyEdge, SelectedPolicies } from "./types";
import { OrderDirection, PageInfo } from "~/operations";
import { PreviewIcon } from "~/components/icons/mondoo/preview";
import { pluralize } from "~/lib/pluralize";
import { BlockOutlined } from "@mui/icons-material";
import { Pagination, PaginationRange } from "~/components/pagination";
import { ObservableQuery } from "@apollo/client";
import { isEmpty } from "lodash";
import { EmptyState } from "~/components/empty-state/empty-state";
import { PolicyMrnToURIEncodedId } from "~/lib/mrn";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";
import { PolicyListRow } from "~/pages/security/policies/components/PolicyListRow";

type ListProps = {
  scope: SpaceOrWorkspaceScope;
  selectedItems: SelectedPolicies;
  handleCheckAll: (edges: ActivePolicyEdge[]) => void;
  activePolicies: ActivePolicyEdge[];
  totalPolicies: number;
  permissions: ReturnType<typeof usePolicies>["permissions"];
  handle: ReturnType<typeof usePolicies>["handle"];
  state: ReturnType<typeof usePolicies>["state"];
  orderBy: ReturnType<typeof usePolicies>["orderBy"];
  PaginationProps: {
    fetchMore: ObservableQuery["fetchMore"];
    pageInfo?: PageInfo;
    setPageItems: React.Dispatch<React.SetStateAction<PaginationRange>>;
    totalCount: number;
  };
};

type Header = {
  id: string;
  label: string;
  sortable?: boolean;
  options?: {
    minWidth?: number;
    align?: "inherit" | "left" | "center" | "right" | "justify";
    colSpan?: number;
    pl?: number;
  };
};
const tableHeaders: Header[] = [
  {
    id: "NAME",
    label: "Policy",
    sortable: true,
    options: { colSpan: 2, pl: 1.5 },
  },
  {
    id: "RISK",
    label: "Risk",
    sortable: false,
  },
  {
    id: "AFFECTED_ASSETS",
    sortable: true,
    label: "Asset results",
    options: { minWidth: 300 },
  },
];

export function List({
  scope,
  permissions,
  selectedItems,
  handle,
  state,
  orderBy,
  handleCheckAll,
  activePolicies,
  totalPolicies,
  PaginationProps,
}: ListProps) {
  if (isEmpty(activePolicies)) {
    return <EmptyState contentType="policies" />;
  }

  return (
    <Fragment>
      <DataTable
        selectable={permissions.edit}
        selection={selectedItems}
        id="enabled-policies-list"
      >
        <TableHead>
          <TableRow>
            {permissions.edit && (
              <TableCell>
                <Checkbox
                  checked={selectedItems.length === activePolicies?.length}
                  indeterminate={
                    selectedItems.length > 0 &&
                    selectedItems.length < activePolicies.length
                  }
                  onChange={() => handleCheckAll(activePolicies)}
                />
              </TableCell>
            )}
            {tableHeaders.map((header: Header) => (
              <TableCell
                key={header.id}
                align={header.options?.align}
                colSpan={header.options?.colSpan}
                sx={{ minWidth: header.options?.minWidth }}
              >
                {header.sortable ? (
                  <TableSortLabel
                    onClick={() => handle.sortClick(header.id)}
                    direction={
                      orderBy.direction === OrderDirection.Asc ? "asc" : "desc"
                    }
                    active={orderBy.field === header.id}
                    sx={{ pl: header.options?.pl }}
                  >
                    {header.label}
                  </TableSortLabel>
                ) : (
                  header.label
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {/* Enabled Policies List */}
        <TableBody>
          {activePolicies.map((edge) => {
            if (!edge.node) return undefined;
            const { action, mrn } = edge.node;

            const href = `/space/findings/policies/${PolicyMrnToURIEncodedId(
              mrn,
            )}?${scope.params}`;

            return (
              <PolicyListRow
                key={mrn}
                onClick={() => handle.navigate(href)}
                policy={edge.node}
                canEdit={permissions.edit}
                onCheckboxSelect={(e) =>
                  handle.check(e, {
                    policyMrn: mrn,
                    action,
                  })
                }
                isSelected={Boolean(
                  selectedItems.find((i) => i.policyMrn === mrn),
                )}
              />
            );
          })}
        </TableBody>
      </DataTable>
      {PaginationProps.pageInfo && <Pagination {...PaginationProps} />}

      {selectedItems.length > 0 && (
        <SelectionToolbar>
          <Typography>
            Selected {selectedItems.length} of {totalPolicies} policies
          </Typography>
          {(state.hasSelectedBothEnabledAndPreviewedPolicies ||
            state.hasSelectedPreviewedPolicy) && (
            <Button
              variant="contained"
              color="primary"
              onClick={handle.enablePolicies}
              startIcon={<BarChartIcon />}
            >
              Enable {pluralize("Policy", selectedItems.length)}
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handle.disablePolicies}
            startIcon={<BlockOutlined />}
          >
            Remove {pluralize("Policy", selectedItems.length)}
          </Button>
          {(state.hasSelectedBothEnabledAndPreviewedPolicies ||
            state.hasSelectedEnabledPolicy) && (
            <Button
              variant="contained"
              color="primary"
              onClick={handle.previewPolicies}
              startIcon={<PreviewIcon />}
            >
              Preview {pluralize("Policy", selectedItems.length)}
            </Button>
          )}
          <Button onClick={handle.unCheckAll}>Cancel</Button>
        </SelectionToolbar>
      )}
    </Fragment>
  );
}
