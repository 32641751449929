import { useParams, useSearchParams } from "react-router-dom";
import { useScope } from "~/hooks/useScope";
import {
  CaseStatus,
  GetCasesCountDocument,
  GetFindingOnAssetDocument,
  useGetCasesCountQuery,
  useGetFindingOnAssetQuery,
  useGetRenderedAssetDataQueryQuery,
  useGetTicketingIntegrationsQuery,
  useLoadAdvisoryQuery,
  useLoadAssetQuery,
  useLoadCveQuery,
} from "~/operations";
import { useFindingRiskFactors } from "~/pages/space/security/components/RiskFactors/hooks/useFindingRiskFactors";
import { useExceptions } from "~/components/exceptions/use-exceptions";
import { useFetchExceptions } from "~/components/exceptions/use-fetch-exceptions";
import { useCases } from "~/components/cases/hooks";
import {
  getCheckFindingRemediation,
  getFindingAggregateScoreType,
  getFindingExceptionMrns,
  getFindingExceptionTypes,
  getFindingLinkBack,
  getFindingMrn,
  isAdvisoryFinding,
  isCheckFinding,
  isVulnerabilityFinding,
} from "../utils";
import { useFormattedResults } from "~/pages/inventory/components/Checks";

export function useFindingOnAsset() {
  const { activeScope, spaceScope } = useScope();
  const [searchParams] = useSearchParams();
  const { id, vulnId = "", advisoryId = "" } = useParams();
  const checkMrn = searchParams.get("checkMrn") || "";
  const ASSET_MRN = `//assets.api.mondoo.app/spaces/${spaceScope?.id}/assets/${id}`;

  const findingMrn = getFindingMrn({
    vulnId,
    advisoryId,
    checkMrn,
  });

  const scoreType = getFindingAggregateScoreType({
    vulnId,
    advisoryId,
    checkMrn,
  });

  const exceptionTypes = getFindingExceptionTypes({
    vulnId,
    advisoryId,
    checkMrn,
  });

  const { data: findingData } = useGetFindingOnAssetQuery({
    variables: {
      scopeMrn: ASSET_MRN,
      first: 1,
      filter: {
        mrn: findingMrn,
      },
    },
    skip: !activeScope || !findingMrn,
  });

  const { data: cveData } = useLoadCveQuery({
    variables: { id: vulnId || "" },
    skip: !vulnId,
  });

  const { data: advisoryData } = useLoadAdvisoryQuery({
    variables: { id: advisoryId || "" },
    skip: !advisoryId,
  });

  const { riskFactorsWithDocs } = useFindingRiskFactors({
    spaceMrn: spaceScope?.mrn || "",
    findingMrn,
    ...(scoreType && { scoreType }),
  });

  const { data: assetData } = useLoadAssetQuery({
    variables: {
      mrn: ASSET_MRN,
    },
    skip: !activeScope,
  });

  const { exceptionGroups } = useFetchExceptions({
    scopeMrn: ASSET_MRN,
    types: exceptionTypes,
    skip: exceptionTypes.length === 0,
  });

  const {
    handleCreateCaseIntegrationModalOpen,
    handleCreateCaseIntegrationModalClose,
    handleCreateCaseModalOpen,
    handleCreateCaseModalClose,
    isCreateCaseModalOpen,
    isCreateCaseIntegrationModalOpen,
    handleCreateCase,
    hasCreateCasePermissions,
    hasIntegrationCreatePermission,
    hasListCasePermissions,
    loading: createCaseLoading,
  } = useCases({
    createCaseRefetchQueries: [GetCasesCountDocument],
    availablePermissions: spaceScope?.iamActions || [],
  });

  const { data: ticketIntegrationsData } = useGetTicketingIntegrationsQuery({
    variables: {
      input: {
        spaceMrn: spaceScope?.mrn || "",
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const findings =
    findingData?.findings?.__typename === "FindingsConnection"
      ? findingData.findings
      : undefined;

  const findingsNode = findings?.edges?.[0]?.node;

  const finding =
    isCheckFinding(findingsNode) ||
    isAdvisoryFinding(findingsNode) ||
    isVulnerabilityFinding(findingsNode)
      ? findingsNode
      : undefined;

  const asset = assetData?.asset;

  //chop and sort the labels the way we want to display them
  const labels = finding?.asset?.platform?.labels || [];
  const labelsCopy = [...labels];
  // sort the labels so that any values that contain sha256 are at the end
  const mungedLabels = labelsCopy
    .sort((a, b) => {
      if (a.value?.substring(0, 6) === "sha256") {
        return 1;
      }
      if (b.value?.substring(0, 6) === "sha256") {
        return -1;
      }
      return 0;
    })
    // repackage the labels with an ellipse if they are sha256
    .map((label) => {
      const isLong = label.value && label.value.length > 20;
      const shouldEllipse = isLong;

      return {
        key: label.key,
        value: label.value,
        displayValue: shouldEllipse
          ? "..." + label.value?.substring(label.value.length - 7)
          : undefined,
      };
    });

  const {
    isRemovingException,
    isSettingException,
    handleSetExceptionModalOpen,
    handleSetExceptionModalClose,
    handleRemoveExceptionModalOpen,
    handleRemoveExceptionModalClose,
    handleRemoveException,
    handleSetException,
    loading: exceptionsLoading,
  } = useExceptions({
    scopeMrns: [ASSET_MRN],
    ...getFindingExceptionMrns({ vulnId, advisoryId, checkMrn }),
    refetchQueries: [GetFindingOnAssetDocument],
  });

  const { data: casesData } = useGetCasesCountQuery({
    variables: {
      input: {
        scopeMrn: asset?.mrn || "",
        findingMrns: [finding?.mrn || ""],
        statuses: [CaseStatus.Open],
      },
    },
    fetchPolicy: "cache-and-network",
    skip: !asset?.mrn || !finding?.mrn || !hasListCasePermissions,
  });

  const { data: queryResultData } = useGetRenderedAssetDataQueryQuery({
    variables: {
      input: {
        assetMrn: ASSET_MRN,
        queryMrn: findingMrn,
      },
    },
    skip: !isCheckFinding(finding),
  });

  const queryResultsNotFoundErrorMessage =
    queryResultData?.renderedAssetQueryData?.__typename === "NotFoundError"
      ? queryResultData.renderedAssetQueryData.message
      : null;

  const queryResultsRenderedData =
    queryResultData?.renderedAssetQueryData?.__typename ===
    "RenderedAssetQueryData"
      ? queryResultData.renderedAssetQueryData
      : null;

  const { results: queryResults } = useFormattedResults(
    queryResultsRenderedData,
    finding?.state,
  );

  const linkToAsset = `/space/inventory/${asset?.id}/overview?spaceId=${activeScope?.id}`;
  const linkToException = `/space/inventory/${asset?.id}/exceptions?spaceId=${activeScope?.id}`;

  const linkBack = getFindingLinkBack({
    vulnId,
    advisoryId,
    checkMrn,
    assetId: asset?.id || "",
    scopeParams: activeScope?.params || new URLSearchParams(),
  });

  const hasTicketingIntegration =
    (ticketIntegrationsData?.ticketingIntegrations?.integrations.length || 0) >
    0;

  const casesCount = casesData?.cases.totalCount || 0;

  const checkData = isCheckFinding(finding) ? finding.mquery : undefined;

  const checkRemediation = getCheckFindingRemediation({
    finding,
    checkData,
  });

  return {
    asset,
    linkToAsset,
    linkToException,
    linkBack,
    finding,
    riskFactorsWithDocs,
    cveData: cveData?.cve,
    advisoryData: advisoryData?.advisory,
    checkData: checkData,
    checkRemediation,
    labelTags: mungedLabels,
    activeScope,
    spaceScope,
    hasTicketingIntegration,
    searchParams,
    casesCount,
    queryResults,
    queryResultsNotFoundErrorMessage,
    exceptionActions: {
      isRemovingException,
      isSettingException,
      handleSetExceptionModalOpen,
      handleSetExceptionModalClose,
      handleRemoveExceptionModalOpen,
      handleRemoveExceptionModalClose,
      handleRemoveException,
      handleSetException,
      loading: exceptionsLoading,
      exceptionGroups,
    },
    ticketActions: {
      handleCreateCaseIntegrationModalOpen,
      handleCreateCaseIntegrationModalClose,
      handleCreateCaseModalOpen,
      handleCreateCaseModalClose,
      isCreateCaseModalOpen,
      isCreateCaseIntegrationModalOpen,
      handleCreateCase,
      hasCreateCasePermissions,
      hasIntegrationCreatePermission,
      hasListCasePermissions,
      loading: createCaseLoading,
    },
  };
}
