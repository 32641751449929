import {
  Box,
  Button,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { formatLastUsed } from "~/lib/date";
import {
  BracketsIcon,
  DeleteIcon,
  ExpandLessIcon,
  ExpandMoreIcon,
} from "~/components/icons";
import { DetailRow } from "~/components/data-table";
import { Fragment } from "react/jsx-runtime";
import React, { useState } from "react";
import { useGetWifAuthBindingConfigLazyQuery } from "~/operations";
import { Flex } from "~/components/Flex";
import { ConfirmationDialog } from "~/components/ConfirmationDialog";
import { WIFAuthBinding } from "~/pages/space-settings/WorkloadIdentity/types";
import { availableProviders } from "~/pages/space-settings/WorkloadIdentity/constants";
import { WorkloadIdentityProviderConfiguration } from "~/pages/space-settings/WorkloadIdentity/components/WorkloadIdentityProviderForm/WorkloadIdentityProviderConfiguration";

type WorkloadIdentityBindingsTableRowProps = {
  binding: WIFAuthBinding | null;
  canRemove?: boolean;
  onBindingRemove: (mrn: string) => void;
  isRemoving: boolean;
};

export const WorkloadIdentityBindingsTableRow = ({
  binding,
  canRemove,
  onBindingRemove,
  isRemoving,
}: WorkloadIdentityBindingsTableRowProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

  const [getWIFAuthBindingConfig, { data: bindingConfigData }] =
    useGetWifAuthBindingConfigLazyQuery();

  const handleOpen = async () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      await getWIFAuthBindingConfig({
        variables: { bindingMrn: binding?.mrn || "" },
      });
      setIsOpen(true);
    }
  };

  const Icon =
    availableProviders.find((provider) =>
      binding?.issuerUri.includes(provider.issuerUrl),
    )?.icon || BracketsIcon;

  return (
    <Fragment>
      <TableRow
        onClick={handleOpen}
        sx={{
          background: (theme) =>
            isOpen ? theme.palette.background.lighter : "unset",
        }}
      >
        <TableCell width="40px" data-testid="wif-icon-cell">
          <Icon />
        </TableCell>
        <TableCell data-testid="wif-name-cell">{binding?.name}</TableCell>
        <TableCell data-testid="wif-expiry-cell">
          {Math.floor((binding?.expiration || 0) / 60)} min
        </TableCell>
        <TableCell data-testid="wif-created-at-cell">
          {formatLastUsed(binding?.createdAt)}
        </TableCell>
        <TableCell data-testid="wif-last-used-cell">
          {formatLastUsed(binding?.lastUsed)}
        </TableCell>
        <TableCell align="right">
          <IconButton aria-label="expand query" size="small">
            {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </TableCell>
      </TableRow>

      {/* Accordion Content Row */}
      <DetailRow
        colSpan={6}
        open={isOpen}
        tdSx={isOpen ? { px: 9, py: 2, pr: 4 } : {}}
      >
        <Flex justifyContent="space-between" gap={5}>
          <Flex flexDirection="column" width="100%">
            {binding?.description && (
              <>
                <Typography sx={{ m: 0 }}>Description</Typography>
                <Typography
                  color="text.secondary"
                  sx={{ mb: 5, mt: 1 }}
                  data-testid="binding-description"
                >
                  {binding?.description}
                </Typography>
              </>
            )}
            <WorkloadIdentityProviderConfiguration
              config={bindingConfigData?.getWIFAuthBinding?.config || null}
            />
          </Flex>
          <Box>
            {canRemove && (
              <Button
                size="small"
                color="error"
                variant="outlined"
                endIcon={<DeleteIcon />}
                onClick={() => {
                  setDeleteDialogOpen(true);
                }}
              >
                Delete
              </Button>
            )}
          </Box>
        </Flex>
      </DetailRow>
      <ConfirmationDialog
        title="Delete workload identity provider?"
        isOpen={deleteDialogOpen}
        confirmButtonText="DELETE"
        content={
          <>
            <Typography>
              Are you sure you want to delete this workload identity provider?
              Any external workloads using this provider will not be able to
              authenticate with Mondoo. This can't be undone.
            </Typography>
          </>
        }
        onConfirm={() => {
          onBindingRemove(binding?.mrn || "");
          setDeleteDialogOpen(false);
        }}
        onClose={() => {
          setDeleteDialogOpen(false);
        }}
        loading={isRemoving}
      />
    </Fragment>
  );
};
