import { Box, Card, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ArrowForwardIcon } from "~/components/icons";
import {
  buildReportObject,
  getReportTitle,
  REPORT_TYPES,
  SelectedReport,
} from "~/utils/generateReportUtils";
import { DocumentType } from "~/operations";
import {
  useGenerateReportButton,
  getGenerateReportProps,
} from "~/pages/compliance/components/generate-report/useGenerateReportButton";
import { Space } from "~/lib/types";
import { GenerateReportDialog } from "~/pages/compliance/components/generate-report/GenerateReportDialog";
import { useState } from "react";

const StyledReportCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.light,
  padding: theme.spacing(3),
  position: "relative",
  cursor: "pointer",
  transition: "background-color 0.2s",
  "&:hover": {
    backgroundColor: theme.palette.background.lighter,
  },
}));

interface ReportCardProps {
  space: Space;
  onSuccess: () => void;
}

export const ReportTypes = ({ space, onSuccess }: ReportCardProps) => {
  const [selectedReport, setSelectedReport] = useState<SelectedReport | null>(
    null,
  );

  const reportItemObject = buildReportObject(selectedReport, space);

  const { exportDialog, form } = useGenerateReportButton({
    ...getGenerateReportProps(reportItemObject),
    onGenerateReportFinishedCallback: onSuccess,
  });

  const handleReportClick = (title: string, type: DocumentType) => {
    form.setValue("reportName", getReportTitle(title));
    setSelectedReport({ title, type });
    exportDialog.handleOpen();
  };

  const handleDialogClose = () => {
    setSelectedReport(null);
    exportDialog.handleClose();
  };

  return (
    <>
      <Grid container spacing={3}>
        {REPORT_TYPES.map((reportType, index) => (
          <Grid item xs={12} md={6} lg={4} key={index}>
            <StyledReportCard
              onClick={() =>
                handleReportClick(reportType.title, reportType.type)
              }
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "start",
                  height: 160,
                  position: "relative",
                }}
              >
                <Box sx={{ width: "calc(100% - 32px)" }}>
                  <Typography variant="h5" fontWeight={700} sx={{ mb: 3 }}>
                    {reportType.title}
                  </Typography>
                  <Typography
                    color="text.secondary"
                    sx={{
                      display: "-webkit-box",
                      WebkitLineClamp: 4,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {reportType.description}
                  </Typography>
                </Box>

                <ArrowForwardIcon
                  sx={{
                    color: "primary.main",
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                />
              </Box>
            </StyledReportCard>
          </Grid>
        ))}
      </Grid>

      {selectedReport && (
        <GenerateReportDialog
          open={exportDialog.exportDialogOpen}
          onClose={handleDialogClose}
          title={exportDialog.title}
          isComplianceReport={exportDialog.isComplianceReport}
          form={form}
        />
      )}
    </>
  );
};
