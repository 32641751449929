import { FlagOutlined, Radar } from "@mui/icons-material";
import { StatsProps } from "~/components/DetailsPage/components/Stats/Stats";
import { CveSourcesProps, SourcesProps } from "~/components/vulnerabilities";
import {
  AggregateScoreType,
  Cve,
  CvssScore,
  FindingsOrderField,
  FindingType,
  LoadAdvisoryCvesQuery,
  OrderDirection,
  ScoreRating,
  ScoreStateFilter,
  Source,
  useGetAffectedAssetsQuery,
  useGetAggregateScoresQuery,
  useGetTotalAssetsQuery,
  useLoadAdvisoryCvesQuery,
  useLoadAdvisoryQuery,
  useLoadCveQuery,
  VulnerabilityScoresConnection,
} from "~/operations";
import { useFindingRiskFactors } from "~/pages/space/security/components/RiskFactors/hooks/useFindingRiskFactors";
import { ImpactUpdated } from "~/components/impact/Updated/impact-updated";
import { ScoreBlastRadius } from "~/pages/space/security/components/Check/ScoreBlock/ScoreBlastRadius";
import { useParams } from "react-router-dom";
import { useReportingPageLoaded } from "../../hooks/useReportingPageLoaded";
import { formatEPSSnoRank } from "~/utils/formatter";
import { HealingIcon } from "~/components/icons";
import { AffectedAssetsEdge } from "~/pages/space/vulnerabilities/components/AffectedAssets";
import { AffectedAsset } from "~/pages/space/vulnerabilities/components/AffectedAssets/AffectedAssetsTable";

export function useCveOrAdvisoryReport(): UseCveOrAdvisoryReportReturnObject {
  const { searchParams } = new URL(window.location.href);
  //   scope may be either a "space" or "workspace"
  const scopeMrn = searchParams.get("scopeMrn") || "";
  const id = searchParams.get("id") || "";
  const { advisory: advisoryParam } = useParams();
  const isAdvisory = Boolean(advisoryParam === "advisory");
  const isVulnerability = Boolean(advisoryParam === "vulnerability");

  const advisoryId = isAdvisory ? id : "";
  const cveId = !isAdvisory ? id : "";

  const dataType = advisoryId ? "advisory" : "cve";

  const {
    data: advisoryData,
    loading: advisoryLoading,
    error: advisoryError,
  } = useLoadAdvisoryQuery({
    variables: { id: advisoryId },
    skip: !advisoryId,
  });

  const {
    data: cveData,
    loading: cveLoading,
    error: cveError,
  } = useLoadCveQuery({
    variables: { id: cveId },
    skip: !cveId,
  });

  const findingMrn =
    advisoryData?.advisory?.__typename === "Advisory"
      ? advisoryData.advisory.mrn
      : cveData?.cve?.__typename === "Cve"
        ? cveData.cve.mrn
        : undefined;

  const {
    data: aggScoreData,
    loading: aggScoreLoading,
    error: aggScoreError,
  } = useGetAggregateScoresQuery({
    variables: {
      entityMrn: scopeMrn || "",
      filter: {
        findingMrn,
      },
    },
    skip: (!advisoryId && !cveId) || !scopeMrn,
  });

  const {
    data: totalAssetCountData,
    loading: totalAssetCountLoading,
    error: totalAssetCountError,
    networkStatus: totalAssetCountNetworkStatus,
  } = useGetTotalAssetsQuery({
    variables: {
      scopeMrn: scopeMrn || "",
    },
    skip: !scopeMrn,
  });

  const {
    riskFactorsWithDocs,
    riskFactors,
    loading: riskFactorsLoading,
    networkStatuses: riskFactorsNetworkStatuses, // this is an array of network statuses
  } = useFindingRiskFactors({
    spaceMrn: scopeMrn,
    findingMrn: findingMrn || "",
    scoreType:
      dataType === "advisory"
        ? AggregateScoreType.Advisory
        : AggregateScoreType.Vulnerability,
  });

  const {
    data: failedAffectedAssetsData,
    loading: failedAffectedAssetsLoading,
    error: failedAffectedAssetsError,
    networkStatus: failedAffectedAssetsNetworkStatus,
  } = useGetAffectedAssetsQuery({
    variables: {
      scopeMrn,
      orderBy: {
        field: FindingsOrderField.CvssScore,
        direction: OrderDirection.Desc,
      },
      filter: {
        mrn: findingMrn,
        types: [
          dataType === "advisory" ? FindingType.Advisory : FindingType.Cve,
        ],
        state: ScoreStateFilter.Open,
      },
    },
    skip: dataType === "advisory" ? Boolean(!advisoryId) : Boolean(!cveId),
  });

  const { data: advisoryCvesData, networkStatus: advisoryCveNetworkStatus } =
    useLoadAdvisoryCvesQuery({
      variables: { advisoryId, scopeMrn: scopeMrn },
      skip: !scopeMrn || !advisoryId,
    });

  const networkStatuses = [
    totalAssetCountNetworkStatus,
    failedAffectedAssetsNetworkStatus,
    advisoryCveNetworkStatus,
    ...riskFactorsNetworkStatuses,
  ];

  // Determine if all network requests are ready
  useReportingPageLoaded(networkStatuses);

  const aggregateNodeData =
    aggScoreData?.aggregateScores?.__typename === "AggregateScoresConnection"
      ? aggScoreData.aggregateScores.edges?.at(0)?.node
      : undefined;

  const advisory =
    advisoryData?.advisory?.__typename === "Advisory"
      ? advisoryData.advisory
      : undefined;

  const cve = cveData?.cve?.__typename === "Cve" ? cveData.cve : undefined;

  const mappings = [
    ...(aggregateNodeData?.cvss
      ? [
          {
            group: "CVSS score",
            chart: (
              <ImpactUpdated
                showText={false}
                impact={{
                  rating: aggregateNodeData?.cvss?.rating,
                  value: aggregateNodeData?.cvss?.value,
                }}
                isRiskScore
              />
            ),
            value: aggregateNodeData?.cvss?.value || 0,
          },
        ]
      : []),
    ,
    ...(aggregateNodeData?.epss
      ? [
          {
            group: "EPSS Score",
            chart: (
              <ImpactUpdated
                showText={false}
                impact={{
                  rating: ScoreRating.Critical,
                  value: aggregateNodeData?.epss?.percentile,
                }}
                isRiskScore
              />
            ),
            value: formatEPSSnoRank(aggregateNodeData?.epss),
          },
        ]
      : []),
    { group: "Risk Factors", value: riskFactors?.length || 0 },
    ...(aggregateNodeData?.blastRadius && aggregateNodeData.rating
      ? [
          {
            group: "Blast Radius",
            chart: (
              <ScoreBlastRadius
                blastRadius={aggregateNodeData?.blastRadius}
                rating={aggregateNodeData?.rating}
              />
            ),
            value: aggregateNodeData.blastRadius.affected || 0,
          },
        ]
      : []),
  ].flatMap((mapping) => (mapping ? mapping : []));

  //   munged stats data
  const totalScanned = totalAssetCountData?.assets?.totalCount || 0;
  const totalAffected = aggregateNodeData?.blastRadius?.affected || 0;
  const totalRemediated =
    aggregateNodeData?.blastRadius?.assets &&
    aggregateNodeData?.blastRadius?.affected
      ? (
          aggregateNodeData?.blastRadius?.assets -
          aggregateNodeData.blastRadius?.affected
        ).toString()
      : "---";

  const stats: StatsProps["stats"] = [
    {
      label: "Scanned",
      value: totalScanned < 0 ? "---" : totalScanned.toString(),
      icon: <Radar fontSize="inherit" />,
    },
    {
      label: "Exposed",
      value: totalAffected < 0 ? "---" : totalAffected.toString(),
      icon: <FlagOutlined fontSize="inherit" />,
    },
    ...(isVulnerability
      ? [
          {
            label: "Remediated",
            value: totalRemediated,
            icon: <HealingIcon fontSize="inherit" />,
          },
        ]
      : []),
  ].flatMap((stat) => (stat ? stat : []));

  const sourceLinks: SourcesProps["links"] = (advisory?.externalUrls || [])
    .flatMap((link) => link ?? [])
    .map((reference) => ({
      href: reference.url,
      text: reference.title || "Advisory Source Link",
      icon: reference.iconIDEnum,
    }));

  const failedFindingsUnion =
    failedAffectedAssetsData?.findings?.__typename === "FindingsConnection"
      ? failedAffectedAssetsData.findings
      : undefined;
  const failedCheckEdges = failedFindingsUnion?.edges || [];

  function mapCheckScoresToAffectedAssets(checkScores: AffectedAssetsEdge[]) {
    return checkScores?.flatMap(({ node }) => {
      if (!node || node.__typename === "PackageFinding") return [];

      const asset = {
        id: node?.asset?.id || "",
        mrn: node?.asset?.mrn || "",
        score: node.riskValue || 0,
        lastUpdated: node.lastUpdated,
        riskFactors: node.riskFactors,
        title: node?.asset?.name || "",
        iconId: node?.asset?.icon,
        tags: node?.asset?.tags,
        rating: node.rating,
      };

      return [asset];
    });
  }

  const affectedAssets = mapCheckScoresToAffectedAssets(failedCheckEdges || []);

  const rawVulnerabilities =
    advisoryCvesData?.advisory?.__typename === "Advisory"
      ? (advisoryCvesData.advisory.cves ?? [])
      : [];

  const vulnerabilities = [...rawVulnerabilities].sort((a, b) => {
    if (
      b.vulnerabilityRiskFactors.knownExploited !==
      a.vulnerabilityRiskFactors.knownExploited
    ) {
      return b.vulnerabilityRiskFactors.knownExploited ? 1 : 0;
    }
    return (b.riskValue || 0) - (a.riskValue || 0);
  });

  return {
    loading: advisoryLoading || cveLoading || aggScoreLoading,
    error: advisoryError || cveError || aggScoreError,
    advisory,
    aggregateNodeData,
    cve,
    data: {
      type: dataType,
      title: dataType === "advisory" ? `${advisory?.id} ADVISORY` : cve?.id,
      summary: advisory?.description || cve?.description,
      mappings,
      stats,
      sourceLinks,
      ...(dataType === "cve" &&
        cve?.url &&
        cve?.source &&
        cve?.references && {
          cveSources: {
            url: cve?.url,
            source: cve?.source,
            references: cve?.references,
          },
        }),
      detectedBy: aggregateNodeData?.detectionSources,
      cvssScore: aggregateNodeData?.cvss,
      riskFactorsWithDocs,
      epssScore: aggregateNodeData?.epss,
      affectedAssets,
      vulnerabilities,
      relatedAdvisories: null,
    },
  };
}

type AffectedAssetsEdges = NonNullable<VulnerabilityScoresConnection>["edges"];
type AffectedAssetsNode = NonNullable<AffectedAssetsEdges>[0]["node"];

type AdvisoryCvesQuery = Extract<
  NonNullable<LoadAdvisoryCvesQuery>,
  { __typename: "Query" }
>;
type AdvisoryCves = NonNullable<AdvisoryCvesQuery["advisory"]>["cves"];

export type UseCveOrAdvisoryReportReturnObject = {
  loading: boolean;
  error: Error | undefined;
  advisory?: any;
  cve?: any;
  aggregateNodeData?: any;
  data: {
    type: "advisory" | "cve" | "check";
    title: string | null | undefined;
    summary: string | null | undefined;
    mappings: any[];
    stats: StatsProps["stats"];
    sourceLinks: SourcesProps["links"];
    cveSources?: {
      url: CveSourcesProps["url"];
      source: Pick<Source, "name" | "url">;
      references: Cve["references"];
    };
    detectedBy: any;
    cvssScore: CvssScore | undefined | null;
    epssScore: any;
    riskFactorsWithDocs: any;
    affectedAssets: Array<AffectedAsset> | undefined;
    vulnerabilities: AdvisoryCves;
    relatedAdvisories: any;
  };
};
