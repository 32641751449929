import { Box, Select, SelectProps, Skeleton, Typography } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { Fragment, ReactNode } from "react";

type SelectFieldProps = {
  title: string;
  children: ReactNode;
  registration: object;
  errorMessage: string | undefined;
  loading?: boolean;
} & SelectProps;

export const SelectField = ({
  loading,
  registration,
  children,
  ...props
}: SelectFieldProps) => {
  return (
    <Fragment>
      <Typography variant="body1" gutterBottom sx={{ mb: 1, fontWeight: 700 }}>
        {props.title}
      </Typography>
      <AnimatePresence mode="wait" initial={false}>
        {loading ? (
          <Box
            component={motion.div}
            key="skeleton"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            sx={{
              height: 56,
              p: 0,
              m: 0,
              bgcolor: "code.background",
              borderRadius: 1,
              overflow: "hidden",
            }}
          >
            <Skeleton animation="wave" variant="rectangular" height={56} />
          </Box>
        ) : (
          <Box
            component={motion.div}
            key="select"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Select
              data-test-id="case-select-input"
              fullWidth
              error={Boolean(props.errorMessage)}
              sx={{
                "&.MuiInputBase-root": {
                  bgcolor: "code.background",
                },
              }}
              {...props}
              {...registration}
            >
              {children}
            </Select>
          </Box>
        )}
      </AnimatePresence>
    </Fragment>
  );
};
