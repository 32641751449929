import { OpenDropdownButton } from "../OpenDropdownButton";
import { Box, TextFieldProps } from "@mui/material";
import { KeyboardEvent, useEffect, useRef, useState } from "react";
import { Path, useFormContext } from "react-hook-form";
import { debounce, get, last } from "lodash";
import {
  RiskFactorConditionKeyValueField,
  RiskFactorItemInput,
  RiskFactorsConfigurationInput,
} from "../../RiskFactorsConfigurationForm";
import { MenuItemsGroup, MenuItemsGroupProps } from "./MenuItemsGroup";
import { BaseAutocomplete } from "./BaseAutocomplete";
import { BaseMenuList } from "./BaseMenuList";

type GroupItemValue = {
  label: string;
  value: string;
};

export type KeysDropdownAutocomplete = {
  name: `${string}.selections.${number}.conditions.${number}.formKey`;
  groups: Array<{ label: string; values: Array<GroupItemValue> }>;
  onChange: (
    name: `${string}.selections.${number}.conditions.${number}.formKey`,
    value: "" | RiskFactorConditionKeyValueField,
  ) => void;
};

export const KeysDropdownAutocomplete = ({
  name,
  groups,
  onChange,
}: KeysDropdownAutocomplete) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState("");
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { register, trigger } = useFormContext<RiskFactorsConfigurationInput>();
  const filteredGroups = groups
    .map((group) => ({
      ...group,
      values: group.values.filter((v) =>
        v.label.toLowerCase().includes(searchQuery.toLowerCase()),
      ),
    }))
    .filter((g) => g.values.length > 0);

  const lastItemLabel = [...get(last([...filteredGroups]), "values", [])].pop()
    ?.value;

  const handleKeyDown = (
    event: KeyboardEvent<HTMLLIElement>,
    label: string,
  ): void => {
    // Check if `Tab` is pressed on the last item
    if (event.key === "Tab") {
      if (label === lastItemLabel && !event.shiftKey) {
        // Close dropdown if Tab is pressed on the last item
        setIsOpen(false);
      }
    }
  };

  const handleDropdownSearchFieldChange: TextFieldProps["onChange"] = debounce(
    (e) => {
      setSearchQuery(e.target.value);
    },
    400,
  );

  useEffect(() => {
    register(name, {
      // required: "This condition is incomplete. Please add a selection.",
    });
    trigger(name);
  }, [register]);

  const onItemClick: MenuItemsGroupProps["onClick"] = (value) => {
    onChange(name, value as RiskFactorConditionKeyValueField);
    setIsOpen(false);
  };

  useEffect(() => {
    if (!isOpen) {
      setSearchQuery("");
    }
  }, [isOpen]);

  return (
    <Box>
      <OpenDropdownButton
        isOpen={isOpen}
        anchorRef={anchorRef}
        onClick={() => setIsOpen((open) => !open)}
        data-testid={`${isOpen ? "close" : "open"}-keys-dropdown`}
      />
      <Box sx={{ maxWidth: "400px", position: "relative" }}>
        <BaseAutocomplete
          onSearchInputChange={handleDropdownSearchFieldChange}
          showKeyboardHint
          open={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
          anchorRef={anchorRef}
          renderContent={() => (
            <BaseMenuList>
              {filteredGroups.map((group) => (
                <MenuItemsGroup
                  groupTitle={group.label}
                  onClick={onItemClick}
                  items={group.values}
                  key={group.label}
                  onKeyDown={handleKeyDown}
                />
              ))}
            </BaseMenuList>
          )}
        />
      </Box>
    </Box>
  );
};
