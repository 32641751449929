import { motion } from "framer-motion";
import { Box, Grid2, Skeleton } from "@mui/material";
import React from "react";

export const RemediationCheckboxSkeleton = () => {
  return (
    <Grid2 container spacing={3}>
      {new Array(8).fill("checkbox-mock").map((_, index) => (
        <Grid2 key={index} size={{ xs: 12, md: 6, lg: 3 }}>
          <Box
            component={motion.div}
            key="skeleton"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            sx={{
              height: 24,
              p: 0,
              m: 0,
              bgcolor: "code.background",
              borderRadius: 1,
              overflow: "hidden",
            }}
          >
            <Skeleton animation="wave" variant="rectangular" height={24} />
          </Box>
        </Grid2>
      ))}
    </Grid2>
  );
};
