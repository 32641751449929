import * as mondooIcons from "../../components/icons/mondoo";
import * as integrationsIcons from "../../components/icons/mondoo/integrations";
import * as dashboardIcons from "../../components/icons/mondoo/dashboard";
import * as resourceExplorerIcons from "../../components/icons/mondoo/resources-explorer";
import * as uiLibraryIcons from "~/components/ui-library/icons";
import * as awsResourcesIcons from "~/components/icons/mondoo/aws-resources";
import { Box } from "@mui/material";
import { IconsSection } from "~/pages/icons/IconsSection";

export const IconsDiscovery = () => {
  return (
    <Box>
      <IconsSection title="Mondoo" list={mondooIcons} />
      <IconsSection title="UI Library" list={uiLibraryIcons} />
      <IconsSection title="Dashboard" list={dashboardIcons} />
      <IconsSection title="Integrations" list={integrationsIcons} />
      <IconsSection title="Resource Explorer" list={resourceExplorerIcons} />
      <IconsSection title="AWS Resources" list={awsResourcesIcons} />
    </Box>
  );
};
