import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  IconButtonProps,
  Tooltip,
  Typography,
} from "@mui/material";
import { CloseIcon, GenerateReportIcon } from "~/components/icons";
import {
  getGenerateReportProps,
  useGenerateReportButton,
  UseGenerateReportButtonProps,
} from "./useGenerateReportButton";
import { Space } from "~/lib/types";
import { GenerateReportDialog } from "./GenerateReportDialog";

type GenerateReportButtonProps = UseGenerateReportButtonProps & IconButtonProps;

export function GenerateReportButton({
  space,
  documentType,
  title,
  frameworkMrn,
  controlMrn,
  assetMrn,
  IconProps = { fontSize: "inherit" },
  advisoryId,
  vulnerabilityId,
  checkId,
  ...props
}: GenerateReportButtonProps) {
  const { exportDialog, finishedDialog, form } = useGenerateReportButton(
    getGenerateReportProps({
      documentType,
      space,
      title,
      controlMrn,
      frameworkMrn,
      assetMrn,
      advisoryId,
      vulnerabilityId,
      checkId,
    }),
  );

  return (
    <>
      <Tooltip title="Generate report" placement="top" arrow>
        <IconButton onClick={exportDialog.handleOpen} {...props}>
          <GenerateReportIcon {...IconProps} />
        </IconButton>
      </Tooltip>

      <GenerateReportDialog
        open={exportDialog.exportDialogOpen}
        onClose={exportDialog.handleClose}
        title={exportDialog.title}
        isComplianceReport={exportDialog.isComplianceReport}
        form={form}
      />

      <FinishedDialog
        space={space}
        open={finishedDialog.finishedDialogOpen}
        handleClose={finishedDialog.handleFinishedClose}
      />
    </>
  );
}

type FinishedDialogProps = {
  space: Space;
  open: boolean;
  handleClose: () => void;
};

function FinishedDialog({ space, open, handleClose }: FinishedDialogProps) {
  return (
    <Dialog open={open} maxWidth="md" onClose={handleClose}>
      <DialogTitle
        component="div"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pt: 5,
          px: 5,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            textTransform: "uppercase",
            fontWeight: "700",
            display: "flex",
            alignItems: "center",
          }}
        >
          You're all set!
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ pb: 5, px: 5 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography sx={{ my: 3 }}>
            When your report is ready, you can download it from the Reporting
            page.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            component={RouterLink}
            to={`/space/compliance/reports?spaceId=${space.id}`}
          >
            View Reports
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
