import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { Box, Grid, Tooltip, Typography, useTheme } from "@mui/material";
import { getColor } from "~/lib/colors";
import { FormatDate } from "~/lib/date";
import { Space } from "~/lib/types";
import { AccessTimeIcon } from "~/components/icons";
import {
  INITIAL_PAGE_RANGE,
  Pagination,
  UsePaginationProps,
} from "~/components/pagination";
import { AssetsEdge, AssetsPageInfo } from "./asset-explorer-tab";
import { ImpactUpdated } from "../impact/Updated/impact-updated";

type Props = {
  items: AssetsEdge[];
  pageInfo?: AssetsPageInfo;
  space: Space;
  fetchMore: UsePaginationProps["fetchMore"];
  totalCount: number;
};

export function KeyboardView({
  items,
  pageInfo,
  space,
  fetchMore,
  totalCount,
}: Props) {
  const [pageItems, setPageItems] = useState(INITIAL_PAGE_RANGE);
  const theme = useTheme();

  const getOffset = (index: number, size: string) => {
    let offset = theme.spacing(2);
    if (size === "xs") {
      offset = theme.spacing(0.5);
    } else if (size === "sm") {
      offset = theme.spacing(1);
    }

    if ((index / 20) % 2 < 1.0) {
      return `translateX(${-offset})`;
    } else {
      return `translateX(${offset})`;
    }
  };

  return (
    <Box className="explorer-keyboard" px={2} py={1}>
      <Grid
        container
        columns={20}
        columnSpacing={{ xs: 0.5, sm: 0 }}
        rowSpacing={{ xs: 1, sm: 2 }}
        sx={{ py: 3 }}
      >
        <AnimatePresence>
          {items.slice(pageItems.from, pageItems.to).map(({ node }, index) => {
            if (!node) return <></>;
            const { score } = node;
            return (
              <Grid
                item
                key={index}
                xs={1}
                component={motion.div}
                layout
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <Tooltip
                  placement="top"
                  arrow
                  componentsProps={{
                    tooltip: {
                      sx: {
                        maxWidth: "none",
                        padding: 0,
                      },
                    },
                  }}
                  title={
                    <Box
                      sx={{
                        display: "flex",
                        px: 2,
                        py: 1.75,
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          mr: 5,
                        }}
                      >
                        <Box>
                          <Typography variant="body2">{node.name}</Typography>
                          <Typography variant="caption" color="text.secondary">
                            {node.platform?.title}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <AccessTimeIcon
                            sx={{
                              mr: 1,
                              fontSize: 14,
                              color: "text.secondary",
                            }}
                          />
                          <Typography variant="caption" color="text.secondary">
                            {FormatDate(node.updatedAt)}
                          </Typography>
                        </Box>
                      </Box>
                      <ImpactUpdated
                        impact={{
                          value: score.riskValue,
                          rating: score.riskRating,
                        }}
                        isRiskScore
                        hideTooltip
                      />
                    </Box>
                  }
                >
                  <Box
                    component={RouterLink}
                    className="key-cap"
                    to={`/space/inventory/${node.id}/overview?spaceId=${space.id}`}
                    sx={{
                      display: "block",
                      maxWidth: { xs: 24, sm: 36 },
                      height: { xs: 24, sm: 36 },
                      borderRadius: { xs: 0.5, sm: 2 },
                      background: getColor(theme, score.riskRating, true),
                      transform: {
                        xs: getOffset(index, "xs"),
                        sm: getOffset(index, "sm"),
                        md: getOffset(index, "md"),
                      },
                    }}
                  />
                </Tooltip>
              </Grid>
            );
          })}
        </AnimatePresence>
        <Pagination
          fetchMore={fetchMore}
          pageInfo={pageInfo}
          setPageItems={setPageItems}
          totalCount={totalCount}
        />
      </Grid>
    </Box>
  );
}
