import { Grid2 } from "@mui/material";
import { useAssetOutlet } from "~/pages/inventory/asset";
import { AssetConfigurationSection } from "./asset-configuration-section";
import { Loading } from "./loading";
import { RiskFactors } from "~/pages/inventory/components/RiskFactors/RiskFactors";
import { AssetTopFindings } from "~/pages/inventory/components/AssetTopFindings/AssetTopFindings";

export function OverviewTab() {
  const { asset, assetPath, hasMondooPlatformVulnPolicy, space } =
    useAssetOutlet();

  if (!assetPath) {
    return <Loading what="Overview Tab" />;
  }

  // until backend removes the platform vuln policy from the count, we are managing it here
  let policyCount = asset?.listPolicies?.totalCount || 0;
  // if there is a platform vulnerability policy, remove it from the count
  if (hasMondooPlatformVulnPolicy && policyCount > 0) {
    policyCount--;
  }

  const hasRiskFactors =
    asset?.riskFactors?.some((factor) => factor.isDetected) ?? false;

  return (
    <>
      <Grid2 container spacing={3} mt={3}>
        {hasRiskFactors && (
          <Grid2 size={{ xs: 12, md: 4.25 }}>
            <RiskFactors />
          </Grid2>
        )}
        <Grid2 size={{ xs: 12, md: hasRiskFactors ? 7.75 : 12 }}>
          <AssetTopFindings />
        </Grid2>
      </Grid2>
      <AssetConfigurationSection
        {...{ space, availablePermissions: space.iamActions }}
      />
    </>
  );
}
