import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { LoadingButton } from "~/components/loading-button";
import { ExceptionTarget, SelectedEntity } from "~/components/exceptions/types";
import { RemoveExceptionText } from "~/components/exceptions/RemoveExceptionText";
import { ExceptionGroup } from "~/operations";
import { targetLabelMap } from "~/components/exceptions/constants";
import { CloseIcon } from "~/components/icons";
import { pluralize } from "~/lib/pluralize";

type RemoveExceptionDialogProps = {
  open: boolean;
  onClose: () => void;
  onRemove: () => void;
  loading: boolean;
  target: ExceptionTarget;
  exceptionGroups: ExceptionGroup[];
  selectedEntities: SelectedEntity[];
};

export const RemoveExceptionDialog = ({
  open,
  onClose,
  onRemove,
  loading,
  target,
  exceptionGroups,
  selectedEntities,
}: RemoveExceptionDialogProps) => {
  const checksContainingExceptions = selectedEntities.filter(
    (check) => check?.exception,
  );

  return (
    <Dialog onClose={onClose} open={open} maxWidth="sm">
      <DialogTitle
        component="div"
        data-test-id="confirm-dialog-title"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 3,
          mx: 2,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            textTransform: "uppercase",
            fontWeight: "700",
            display: "flex",
            alignItems: "center",
          }}
        >
          ARE YOU SURE?
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ mb: 2, mx: 2.5 }}>
        <Grid
          container
          rowGap={3}
          sx={{
            "& .MuiTypography-root": {
              color: "text.secondary",
            },
          }}
        >
          <RemoveExceptionText
            exceptionGroups={exceptionGroups}
            selectedEntities={selectedEntities}
            target={target}
          />
        </Grid>
      </DialogContent>

      <DialogActions
        sx={{
          flexDirection: "column",
          justifyContent: "center",
          pb: 5,
          rowGap: 2,
        }}
      >
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          disabled={loading}
          loading={loading}
          buttonText={`Yes, Enable the ${pluralize(
            targetLabelMap[target],
            checksContainingExceptions.length,
          )}`}
          onClick={onRemove}
          data-test-id="exception-delete-confirm-button"
        />

        <Button
          variant="text"
          sx={{ display: "inline-block" }}
          onClick={onClose}
          disabled={loading}
        >
          no, keep the{" "}
          {pluralize("exception", checksContainingExceptions.length)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
