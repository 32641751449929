import { AggregateScoreType } from "~/operations";
import { AdjustedAggregateScoreType } from "..";

export const isAggregateScoreType = (type: any): type is AggregateScoreType => {
  return Object.values(AggregateScoreType).includes(type);
};

export const scoreTypesByType = (type: AdjustedAggregateScoreType) => {
  if (isAggregateScoreType(type)) {
    return [type];
  }
  switch (type) {
    case "AllSecurityFindings":
      return [AggregateScoreType.Check, AggregateScoreType.Vulnerability];
    case "ALL":
    default:
      return [];
  }
};
