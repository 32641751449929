import { useFieldArray, useFormContext } from "react-hook-form";
import {
  getInitialCondition,
  RiskFactorsConfigurationInput,
} from "../../../RiskFactorsConfigurationForm";
import { isConditionComplete } from "../../Condition/hooks/useConditionRow";

type UseSelectionRowProps = {
  fieldId: string;
  selectionIndex: number;
};

export function useSelectionRow({
  fieldId,
  selectionIndex,
}: UseSelectionRowProps) {
  const {
    watch,
    control,
    formState: { errors, touchedFields },
    trigger,
  } = useFormContext<RiskFactorsConfigurationInput>();

  const { fields, append, remove } =
    useFieldArray<RiskFactorsConfigurationInput>({
      control,
      name: `${fieldId}.selections.${selectionIndex}.conditions`,
    });

  const conditions = watch(
    `${fieldId}.selections.${selectionIndex}.conditions`,
  );

  const handleAddCondition = () => {
    append(getInitialCondition());
    trigger();
  };

  const handleRemoveCondition = (conditionIndex: number) => {
    remove(conditionIndex);
    if (conditionIndex === 0) {
      handleAddCondition();
    }
  };

  const getConditionError = (conditionIndex: number) => {
    return errors?.[fieldId]?.selections?.[selectionIndex]?.conditions?.[
      conditionIndex
    ]?.keyValueCondition?.values?.message;
  };

  const getIsConditionTouched = (conditionIndex: number) => {
    return (
      touchedFields?.[fieldId]?.selections?.[selectionIndex]?.conditions?.[
        conditionIndex
      ]?.keyValueCondition?.values &&
      touchedFields?.[fieldId]?.selections?.[selectionIndex]?.conditions?.[
        conditionIndex
      ].formKey
    );
  };

  return {
    canAddCondition: conditions.every(isConditionComplete),
    handleAddCondition,
    handleRemoveCondition,
    fields,
    getConditionError,
    getIsConditionTouched,
  };
}
