import { useTheme } from "@mui/material";
import { RiskFactorsTitle } from "./risk-factors-title";
import { positiveRiskFactors } from "~/pages/space/security/components/RiskFactors/utils";

export function useRiskFactorsContentStatus() {
  const theme = useTheme();

  const getRiskStatus = (
    affected = 0,
    total = 0,
    indicator = "",
  ): { status: "active" | "inactive"; color: string } => {
    const isPositive = positiveRiskFactors.includes(indicator);
    const isAffected = affected > 0;
    const isAllAffected = affected === total;
    const isSuccess = isPositive ? isAllAffected : !isAffected;
    const status = isAffected ? "active" : "inactive";
    const color = isSuccess ? "success" : "critical";
    return { status, color: theme.palette[color].main };
  };

  const createRiskFactorSectionTitle = (
    riskFactors: RiskFactorsTitle["riskFactors"],
    { hideValues }: { hideValues?: boolean } = {},
  ) => {
    return (
      riskFactors
        ?.map((rf) =>
          !hideValues ? `${rf?.affected}/${rf?.total} ${rf?.title}` : rf?.title,
        )
        .join(", ") || ""
    );
  };

  return {
    getRiskStatus,
    createRiskFactorSectionTitle,
  };
}
