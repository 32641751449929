import { Grid2, TableRow, Typography } from "@mui/material";
import { Fragment } from "react/jsx-runtime";
import {
  ImpactCell,
  PublishedCell,
  RiskFactorsCell,
  TitleCell,
} from "~/components/Cells";
import { GenericTable } from "~/components/GenericTable/GenericTable";
import { SectionHeading } from "../../components";
import { spacing } from "../lib/SharedVariables";
import { usePolicies } from "./hooks/usePolicies";
import { AssetPolicy, Score, ScoreRating } from "~/operations";
import { NoItemsSection } from "../components";
import { useAssetReport } from "./hooks/useAssetReport";
import { Asset } from "./types";

export function Policies() {
  const { asset, policies } = useAssetReport();

  return (
    <Fragment>
      <SectionHeading heading="Policies" disableUpperPadding />
      {asset &&
        policies.map((policy) => {
          return <PolicyChecksGroup key={policy.mrn} {...{ asset, policy }} />;
        })}
    </Fragment>
  );
}

export type PolicyChecksGroupPolicyScore = Pick<
  Score,
  "riskRating" | "riskValue"
>;

export type PolicyChecksGroupPolicy = Pick<
  AssetPolicy,
  "mrn" | "name" | "scoreStats"
> & { score: PolicyChecksGroupPolicyScore };

export type PolicyChecksGroupProps = {
  asset: Asset;
  policy: PolicyChecksGroupPolicy;
};

export function PolicyChecksGroup({ asset, policy }: PolicyChecksGroupProps) {
  const { passingChecks, failingChecks } = usePolicies({
    asset,
    policyMrn: policy.mrn,
  });
  const hasPassingChecks = Boolean(passingChecks.length > 0);
  const hasFailingChecks = Boolean(failingChecks.length > 0);

  return (
    <Grid2 container sx={{ alignItems: "flex-start", columnGap: 2, mb: 3 }}>
      <Grid2
        size="grow"
        sx={{
          display: "flex",
          flexDirection: "column",
          pr: 2,
        }}
      >
        <Typography fontWeight={700}>{policy.name}</Typography>
        {/* TODO: ADD DESCRIPTION TO API */}
        {/* <Typography variant="body2" color="text.secondary">
          {policy.node.description}
        </Typography> */}
      </Grid2>
      {/* Cannot Yet do or doesn't make sense */}
      {/* <Grid2 sx={{ mr: 3 }}>
        <AssetResultsChart blastRadius={fakeData.blastRadius} />
      </Grid2> */}
      {/* <Grid2>
        <RiskBoxSmall riskLevel={"critical"} riskValue={4} />
      </Grid2> */}

      <Grid2 size={12} sx={{ my: 3 }}>
        <Typography fontWeight={700} sx={{ mb: spacing[25] }}>
          Failing checks
        </Typography>
        {!hasFailingChecks ? (
          <NoItemsSection target="failing checks" />
        ) : (
          <GenericTable
            items={0}
            tableName="ASSET_REPORT_CHECKS"
            validSortFields={[]}
            printView
          >
            {failingChecks.map((item) => {
              return (
                <TableRow key={item?.mrn}>
                  {/* <RankCell id={node.rank} /> CHECKSCORES API DOES NOT HAVE RANK */}
                  <ImpactCell
                    impact={item?.riskValue || 0}
                    rating={item?.rating || ScoreRating.Critical}
                    isActive
                  />
                  <TitleCell
                    title={item?.title || ""}
                    tags={item?.tags}
                    options={{ fontSize: 14 }}
                  />
                  <RiskFactorsCell riskFactors={item?.riskFactors} printView />
                  <PublishedCell
                    date={item?.lastUpdated || "Never"}
                    formatting="absolute"
                  />
                </TableRow>
              );
            })}
          </GenericTable>
        )}
      </Grid2>
      <Grid2 size={12} sx={{ mt: 3 }}>
        <Typography fontWeight={700} sx={{ mb: spacing[25] }}>
          Passing checks
        </Typography>
        {!hasPassingChecks ? (
          <NoItemsSection target="passing checks" />
        ) : (
          <GenericTable
            space={undefined}
            items={0}
            tableName="ASSET_REPORT_CHECKS"
            validSortFields={[]}
            printView
          >
            {passingChecks.map((item) => {
              return (
                <TableRow key={item?.mrn}>
                  {/* <RankCell id={node.rank} /> CHECKSCORES API DOES NOT HAVE RANK */}
                  <ImpactCell
                    impact={item?.riskValue || 0}
                    rating={item?.rating || ScoreRating.Critical}
                    isActive
                  />
                  <TitleCell
                    title={item?.title || ""}
                    tags={item?.tags}
                    options={{ fontSize: 14 }}
                  />
                  <RiskFactorsCell riskFactors={item?.riskFactors} printView />
                  <PublishedCell date={item?.lastUpdated || "Never"} />
                </TableRow>
              );
            })}
          </GenericTable>
        )}
      </Grid2>
    </Grid2>
  );
}
