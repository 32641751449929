import { Box, Button, Grid, Typography } from "@mui/material";
import { ContentFlag } from "~/components/content-flag";
import { OpenInNewIcon } from "~/components/icons";
import { IntegrationType } from "~/operations";
import { DynamicIntegrationIcon } from "../components/dynamic-integration-icon";

type Flag = {
  id: string;
  title: string;
};

export enum OtherIntegrations {
  REDHAT = "mondoo/redhat",
  WINDOWS = "mondoo/windows",
  UBUNTU_DEBIAN = "mondoo/ubuntu",
  AMAZON_LINUX = "mondoo/amazonlinux",
  SUSE = "mondoo/suse",
  MACOS = "mondoo/macos",
  VMWARE = "mondoo/vmware",
  PACKER = "hashicorp/packer",
  TERRAFORM = "hashicorp/terraform",
  CREDENTIALS = "mondoo/credentials",
  WORKSTATION_MONDOO = "workstation/mondoo",
  CHATOPS_MSTEAMS = "chatops/msteams",
  MSTEAMS = "MSTEAMS",
  CHATOPS_SLACK = "chatops/slack",
  SLACK = "SLACK",
  CHATOPS_TELEGRAM = "chatops/telegram",
  TELEGRAM = "TELEGRAM",
  CHATOPS_WEBHOOK = "chatops/httppost",
  WEBHOOK = "HTTPPOST",
}

type Props = {
  type: IntegrationType | OtherIntegrations;
};

export function IntegrationAddHeader({ type }: Props) {
  const getContent = (
    type: IntegrationType | OtherIntegrations,
  ): IntegrationAddHeaderData => {
    switch (type) {
      case IntegrationType.AwsS3:
        return AmazonS3HeaderData;
      case IntegrationType.S3:
        return AmazonS3CompatibleHeaderData;
      case IntegrationType.Azure:
        return AzureHeaderData;
      case IntegrationType.AzureBlob:
        return AzureBlobHeaderData;
      case IntegrationType.Aws:
        return AwsHeaderData;
      case IntegrationType.CrowdstrikeFalcon:
        return CrowdStrikeHeaderData;
      case IntegrationType.Oci:
        return OciHeaderData;
      case IntegrationType.Bigquery:
        return BigQueryHeaderData;
      case IntegrationType.MicrosoftDefender:
        return DefenderHeaderData;
      case IntegrationType.Host:
        return HostHeaderData;
      case IntegrationType.Ms365:
        return MS365HeaderData;
      case IntegrationType.Gcp:
        return GcpHeaderData;
      case IntegrationType.GcsBucket:
        return GcsBucketHeaderData;
      case IntegrationType.Gitlab:
        return GitlabHeaderData;
      case IntegrationType.Github:
        return GithubHeaderData;
      case IntegrationType.TicketSystemJira:
        return JiraHeaderData;
      case IntegrationType.TicketSystemEmail:
        return EmailHeaderData;
      case IntegrationType.TicketSystemZendesk:
        return ZendeskHeaderData;
      case IntegrationType.TicketSystemGithub:
        return GithubIssuesHeaderData;
      case IntegrationType.TicketSystemGitlab:
        return GitlabIssuesHeaderData;
      case IntegrationType.TicketSystemAzureDevops:
        return AzureDevOpsHeaderData;
      case IntegrationType.Okta:
        return OktaHeaderData;
      case IntegrationType.HostedSlack:
        return SlackHeaderData;
      case IntegrationType.Snowflake:
        return SnowflakeHeaderData;
      case IntegrationType.SentinelOne:
        return SentinelOneHeaderData;
      case IntegrationType.Postgres:
        return PostgreSQLHeaderData;
      case IntegrationType.GoogleWorkspace:
        return GoogleWorkspaceHeaderData;
      case IntegrationType.K8S:
        return KubernetesHeaderData;
      case IntegrationType.Shodan:
        return ShodanHeaderData;
      case OtherIntegrations.REDHAT:
        return RedhatHeaderData;
      case OtherIntegrations.WINDOWS:
        return WindowsHeaderData;
      case OtherIntegrations.UBUNTU_DEBIAN:
        return UbuntuDebianHeaderData;
      case OtherIntegrations.AMAZON_LINUX:
        return AmazonLinuxHeaderData;
      case OtherIntegrations.SUSE:
        return SuseHeaderData;
      case OtherIntegrations.MACOS:
        return MacOsHeaderData;
      case OtherIntegrations.VMWARE:
        return VMwareHeaderData;
      case OtherIntegrations.PACKER:
        return PackerHeaderData;
      case OtherIntegrations.TERRAFORM:
        return TerraformHeaderData;
      case OtherIntegrations.CREDENTIALS:
        return CredentialsHeaderData;
      case OtherIntegrations.WORKSTATION_MONDOO:
        return WorkstationMondooHeaderData;
      case OtherIntegrations.CHATOPS_MSTEAMS:
        return ChatopsMsteamsHeaderData;
      case OtherIntegrations.CHATOPS_SLACK:
        return ChatopsSlackHeaderData;
      case OtherIntegrations.CHATOPS_TELEGRAM:
        return ChatopsTelegramHeaderData;
      case OtherIntegrations.CHATOPS_WEBHOOK:
        return ChatopsWebhookHeaderData;
      default:
        return {
          title: type,
          flags: [],
          tagline: "",
          docsLink: "https://mondoo.com/docs/platform/home/",
        };
    }
  };

  const data = getContent(type);

  return (
    <Box>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Grid
          item
          xs={12}
          sm={8}
          sx={{
            display: "flex",
            alignItems: "flex-start",
            mb: {
              xs: 2,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              placeContent: "center",
            }}
          >
            <DynamicIntegrationIcon {...{ type, sx: { fontSize: 56 } }} />
          </Box>
          <Box ml={3}>
            <Typography
              variant="h4"
              fontWeight={700}
              lineHeight="34px" //swap to theme
              sx={{ textTransform: "uppercase" }}
            >
              {data.title}
            </Typography>
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ mt: -2, textTransform: "uppercase" }}
            >
              {data.tagline}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          sx={{
            textAlign: { xs: "left", sm: "right" },
          }}
        >
          <Button
            href={data.docsLink}
            target="_blank"
            variant="outlined"
            color="secondary"
            endIcon={<OpenInNewIcon />}
            sx={{
              py: 0.5,
              px: 1,
            }}
          >
            Documentation
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ mb: 5, mt: { xs: 2, sm: 0 } }} mb={5}>
        {data.flags.map((flag) => (
          <ContentFlag
            flag={flag.title}
            key={flag.id}
            layout={{ mr: 2, mb: { xs: 1, sm: 0 } }}
          />
        ))}
      </Box>
    </Box>
  );
}

// HEADER DATA

type IntegrationAddHeaderData = {
  title: string;
  tagline: string;
  flags: Flag[];
  docsLink: string;
};

const AwsHeaderData: IntegrationAddHeaderData = {
  title: "AWS",
  tagline: "Integration for AWS organizations and accounts",
  flags: [
    { id: "continuous_scanning", title: "Continuous Scanning" },
    { id: "cloud", title: "Cloud" },
  ],
  docsLink:
    "https://mondoo.com/docs/platform/infra/cloud/aws/aws-integration-scan/",
};
const OciHeaderData: IntegrationAddHeaderData = {
  title: "Oracle Cloud Infrastructure",
  tagline:
    "INTEGRATION WITH OCI AND ITS EMBEDDED FRAMEWORKS, CLOUDS, AND SERVICES",
  flags: [
    { id: "continuous_scanning", title: "Continuous Scanning" },
    { id: "cloud", title: "Cloud" },
  ],
  docsLink: "https://mondoo.com/docs/platform/infra/cloud/oci/",
};
const AzureHeaderData: IntegrationAddHeaderData = {
  title: "Microsoft Azure",
  tagline: "Integration for Microsoft Azure Subscriptions",
  flags: [
    { id: "continuous_scanning", title: "Continuous Scanning" },
    { id: "cloud", title: "Cloud" },
  ],
  docsLink: "https://mondoo.com/docs/platform/infra/cloud/azure/overview/",
};
const DefenderHeaderData: IntegrationAddHeaderData = {
  title: "Microsoft Defender for Cloud",
  tagline: "Integration for Microsoft Defender for Cloud",
  flags: [
    { id: "continuous_import", title: "Continuous Import" },
    { id: "third_party_data", title: "3rd Party Data" },
  ],
  docsLink: "https://mondoo.com/docs/platform/infra/imports/defender/",
};

const SentinelOneHeaderData: IntegrationAddHeaderData = {
  title: "SentinelOne",
  tagline: "Integration for SentinelOne Subscriptions",
  flags: [
    { id: "continuous_import", title: "Continuous Import" },
    { id: "third_party_data", title: "3rd Party Data" },
  ],
  docsLink: "https://mondoo.com/docs/platform/infra/imports/sentinelone/",
};

const CrowdStrikeHeaderData: IntegrationAddHeaderData = {
  title: "CrowdStrike",
  tagline: "Integration for the CrowdStrike Falcon Platform",
  flags: [
    { id: "continuous_import", title: "Continuous Import" },
    { id: "third_party_data", title: "Third-Party Data" },
  ],
  docsLink: "https://mondoo.com/docs/platform/infra/imports/crowdstrike/",
};

const AzureBlobHeaderData: IntegrationAddHeaderData = {
  title: "Azure Blob Storage",
  tagline:
    "Continuously export data to Azure Blob Storage in JSONL or CSV format",
  flags: [
    { id: "storage", title: "Storage" },
    { id: "exports", title: "Exports" },
  ],
  docsLink: "https://mondoo.com/docs/platform/maintain/export/azure-blob/",
};
const BigQueryHeaderData: IntegrationAddHeaderData = {
  title: "BigQuery",
  tagline: "Integration for Google Cloud BigQuery",
  flags: [
    { id: "data_warehouse", title: "Data Warehouse" },
    { id: "exports", title: "Exports" },
  ],
  docsLink:
    "https://mondoo.com/docs/platform/maintain/export/bigquery-integration/",
};

const HostHeaderData: IntegrationAddHeaderData = {
  title: "Domain/IP ADDRESS",
  tagline:
    "Continuously scan endpoints to evaluate domain TLS, SSL, HTTP, and HTTPS security",
  flags: [{ id: "continuous_scanning", title: "Continuous Scanning" }],
  docsLink: "https://mondoo.com/docs/platform/infra/networking/domain-ip/",
};

const SnowflakeHeaderData: IntegrationAddHeaderData = {
  title: "Snowflake",
  tagline:
    "Continuously export scan results to Snowflake for storage, analysis, and reporting ",
  flags: [
    { id: "data_warehouse", title: "Data Warehouse" },
    { id: "exports", title: "Exports" },
  ],
  docsLink: "https://mondoo.com/docs/platform/maintain/export/snowflake/",
};
const AmazonS3HeaderData: IntegrationAddHeaderData = {
  title: "Amazon S3",
  tagline: "Continuously export data to Amazon S3 in JSONL or CSV format",
  flags: [
    { id: "storage", title: "Storage" },
    { id: "exports", title: "Exports" },
  ],
  docsLink: "https://mondoo.com/docs/platform/maintain/export/s3/",
};
const AmazonS3CompatibleHeaderData: IntegrationAddHeaderData = {
  title: "S3 Compatible Service",
  tagline:
    "Continuously export data to Amazon S3-compatible services in JSONL or CSV format",
  flags: [
    { id: "storage", title: "Storage" },
    { id: "exports", title: "Exports" },
  ],
  docsLink: "https://mondoo.com/docs/platform/maintain/export/s3-compatible/",
};
const PostgreSQLHeaderData: IntegrationAddHeaderData = {
  title: "PostgreSQL",
  tagline: "Continuously export data to PostgreSQL databases",
  flags: [
    { id: "database", title: "Database" },
    { id: "exports", title: "Exports" },
  ],
  docsLink: "https://mondoo.com/docs/platform/maintain/export/postgresql/",
};

const CloudSQLHeaderData: IntegrationAddHeaderData = {
  title: "GCP Cloud SQL for PostgreSQL",
  tagline: "Continuously export data to GCP Cloud SQL for PostgreSQL",
  flags: [
    { id: "database", title: "Database" },
    { id: "exports", title: "Exports" },
  ],
  docsLink: "https://mondoo.com/docs/platform/maintain/export/cloudsql/",
};

const GcsBucketHeaderData: IntegrationAddHeaderData = {
  title: "GCP Cloud storage bucket",
  tagline:
    "Continuously export data to GCP Cloud Storage in JSONL or CSV format",
  flags: [
    { id: "storage", title: "Storage" },
    { id: "exports", title: "Exports" },
  ],
  docsLink: "https://mondoo.com/docs/platform/maintain/export/gcs-bucket/",
};

const GcpHeaderData: IntegrationAddHeaderData = {
  title: "Google Cloud Platform",
  tagline: "Integration for GCP Organizations and Projects",
  flags: [
    { id: "continuous_scanning", title: "Continuous Scanning" },
    { id: "cloud", title: "Cloud" },
  ],
  docsLink:
    "https://mondoo.com/docs/platform/infra/cloud/gcp/gcp-integration-scan/",
};

const KubernetesHeaderData: IntegrationAddHeaderData = {
  title: "Kubernetes Operator Configuration",
  tagline: "Continuous Kubernetes cluster, workload, and container scanning",
  flags: [
    { id: "continuous_scanning", title: "Continuous Scanning" },
    { id: "cloud", title: "Cloud" },
  ],
  docsLink:
    "https://mondoo.com/docs/platform/infra/cloud/kubernetes/scan-kubernetes-with-operator/",
};

const ShodanHeaderData: IntegrationAddHeaderData = {
  title: "Shodan",
  tagline: "Continuously assess external risk for domains and IP addresses",
  flags: [{ id: "networking", title: "Networking" }],
  docsLink: "https://mondoo.com/docs/platform/infra/networking/shodan/",
};

const MS365HeaderData: IntegrationAddHeaderData = {
  title: "Microsoft 365",
  tagline: "Integration for Microsoft 365",
  flags: [
    { id: "continuous_scanning", title: "Continuous Scanning" },
    { id: "saas", title: "SaaS" },
  ],
  docsLink: "https://mondoo.com/docs/platform/infra/saas/ms365/ms365-auto/",
};

const GithubHeaderData: IntegrationAddHeaderData = {
  title: "GitHub",
  tagline:
    "Continuously scan your GitHub organizations and repositories for security misconfigurations",
  flags: [
    { id: "continuous_scanning", title: "Continuous Scanning" },
    { id: "saas", title: "SaaS" },
  ],
  docsLink: "https://mondoo.com/docs/platform/infra/saas/github/",
};

const JiraHeaderData: IntegrationAddHeaderData = {
  title: "Jira",
  tagline:
    "Integrate Mondoo with Jira to create Jira issues from the Mondoo Console",
  flags: [{ id: "ticketing", title: "Ticket Systems" }],
  docsLink: "https://mondoo.com/docs/platform/ticketing/setup/",
};

const EmailHeaderData: IntegrationAddHeaderData = {
  title: "Ticketing via email",
  tagline: "Send email to a listener or service when a new ticket is created",
  flags: [
    { id: "cases", title: "Ticketing" },
    { id: "collaboration", title: "Collaboration" },
    { id: "workflows", title: "Workflows" },
  ],
  docsLink: "https://mondoo.com/docs/platform/ticketing/setup-email",
};

const ZendeskHeaderData: IntegrationAddHeaderData = {
  title: "Zendesk",
  tagline:
    "INTEGRATE MONDOO WITH ZENDESK TO CREATE ZENDESK TICKETS FROM THE MONDOO CONSOLE",
  flags: [
    { id: "cases", title: "Ticketing" },
    { id: "collaboration", title: "Collaboration" },
    { id: "workflows", title: "Workflows" },
  ],
  docsLink: "https://mondoo.com/docs/platform/ticketing/setup-zendesk",
};

const GithubIssuesHeaderData: IntegrationAddHeaderData = {
  title: "GitHub Issues",
  tagline:
    "INTEGRATE MONDOO WITH GITHUB TO CREATE GITHUB ISSUES FROM THE MONDOO CONSOLE",
  flags: [
    { id: "cases", title: "Ticketing" },
    { id: "collaboration", title: "Collaboration" },
    { id: "workflows", title: "Workflows" },
  ],
  docsLink: "https://mondoo.com/docs/platform/ticketing/setup-github",
};

const GitlabIssuesHeaderData: IntegrationAddHeaderData = {
  title: "GitLab Issues",
  tagline:
    "INTEGRATE MONDOO WITH GITLAB TO CREATE GITLAB ISSUES FROM THE MONDOO CONSOLE",
  flags: [
    { id: "cases", title: "Ticketing" },
    { id: "collaboration", title: "Collaboration" },
    { id: "workflows", title: "Workflows" },
  ],
  docsLink: "https://mondoo.com/docs/platform/ticketing/setup-gitlab",
};

const AzureDevOpsHeaderData: IntegrationAddHeaderData = {
  title: "Azure DevOps",
  tagline:
    "INTEGRATE MONDOO WITH AZURE DEVOPS TO CREATE ISSUES FROM THE MONDOO CONSOLE",
  flags: [
    { id: "cases", title: "Ticketing" },
    { id: "collaboration", title: "Collaboration" },
    { id: "workflows", title: "Workflows" },
  ],
  docsLink: "https://mondoo.com/docs/platform/ticketing/setup-azuredevops",
};

const OktaHeaderData: IntegrationAddHeaderData = {
  title: "Okta",
  tagline: "Continuously scan your Okta domain for security misconfigurations",
  flags: [
    { id: "continuous_scanning", title: "Continuous Scanning" },
    { id: "saas", title: "SaaS" },
  ],
  docsLink: "https://mondoo.com/docs/platform/infra/saas/okta/",
};

const GitlabHeaderData: IntegrationAddHeaderData = {
  title: "GitLab",
  tagline:
    "Continuously scan your GitLab groups for security misconfigurations",
  flags: [
    { id: "continuous_scanning", title: "Continuous Scanning" },
    { id: "saas", title: "SaaS" },
  ],
  docsLink: "https://mondoo.com/docs/platform/infra/saas/gitlab/",
};

const SlackHeaderData: IntegrationAddHeaderData = {
  title: "Slack",
  tagline: "Continuously scan your Slack Teams for security misconfigurations",
  flags: [
    { id: "continuous_scanning", title: "Continuous Scanning" },
    { id: "saas", title: "SaaS" },
  ],
  docsLink: "https://mondoo.com/docs/platform/infra/saas/slack/",
};

const GoogleWorkspaceHeaderData: IntegrationAddHeaderData = {
  title: "Google Workspace",
  tagline:
    "Continuously scan your Google Workspace environment for security misconfigurations",
  flags: [
    { id: "continuous_scanning", title: "Continuous Scanning" },
    { id: "saas", title: "SaaS" },
  ],
  docsLink: "https://mondoo.com/docs/platform/infra/saas/google_workspace/",
};

const RedhatHeaderData: IntegrationAddHeaderData = {
  title: "Red Hat/Fedora",
  tagline:
    "Deploy cnspec on your Red Hat Enterprise Linux (RHEL) and Fedora Linux based hosts",
  flags: [{ id: "continuous_scanning", title: "Continuous Scanning" }],

  docsLink: "https://mondoo.com/docs/cnspec/cnspec-adv-install/linux/",
};

const WindowsHeaderData: IntegrationAddHeaderData = {
  title: "Windows",
  tagline: "Deploy cnspec on Windows hosts",
  flags: [{ id: "continuous_scanning", title: "Continuous Scanning" }],
  docsLink: "https://mondoo.com/docs/cnspec/cnspec-adv-install/windows/",
};

const UbuntuDebianHeaderData: IntegrationAddHeaderData = {
  title: "Ubuntu/Debian",
  tagline: "Deploy cnspec on your Debian and Ubuntu hosts",
  flags: [{ id: "continuous_scanning", title: "Continuous Scanning" }],
  docsLink: "https://mondoo.com/docs/cnspec/cnspec-adv-install/linux/",
};

const AmazonLinuxHeaderData: IntegrationAddHeaderData = {
  title: "Amazon Linux",
  tagline: "Deploy cnspec on Amazon Linux hosts",
  flags: [{ id: "continuous_scanning", title: "Continuous Scanning" }],
  docsLink: "https://mondoo.com/docs/cnspec/cnspec-adv-install/linux/",
};

const SuseHeaderData: IntegrationAddHeaderData = {
  title: "SUSE/openSUSE",
  tagline: "Deploy cnspec on your SUSE (SLES) or openSUSE Linux hosts",
  flags: [{ id: "continuous_scanning", title: "Continuous Scanning" }],
  docsLink: "https://mondoo.com/docs/cnspec/cnspec-adv-install/linux/",
};

const MacOsHeaderData: IntegrationAddHeaderData = {
  title: "macOS",
  tagline: "Deploy cnspec on macOS hosts",
  flags: [{ id: "continuous_scanning", title: "Continuous Scanning" }],
  docsLink: "https://mondoo.com/docs/cnspec/cnspec-adv-install/macos/",
};

const VMwareHeaderData: IntegrationAddHeaderData = {
  title: "VMware",
  tagline:
    "Deploy the Mondoo VMware Appliance to scan VMware vCenter Server and ESXi",
  flags: [{ id: "continuous_scanning", title: "Continuous Scanning" }],
  docsLink: "https://mondoo.com/docs/platform/infra/cloud/vmware/",
};

const PackerHeaderData: IntegrationAddHeaderData = {
  title: "HashiCorp Packer",
  tagline: "The HashiCorp Packer plugin by Mondoo",
  flags: [{ id: "continuous_scanning", title: "Continuous Scanning" }],
  docsLink: "https://mondoo.com/docs/cnspec/supplychain/packer/",
};

const TerraformHeaderData: IntegrationAddHeaderData = {
  title: "HashiCorp Terraform",
  tagline:
    "Use Mondoo to run a static analysis of HashiCorp Terraform (HCL) code",
  flags: [{ id: "continuous_scanning", title: "Continuous Scanning" }],
  docsLink: "https://mondoo.com/docs/cnspec/supplychain/terraform/",
};

const CredentialsHeaderData: IntegrationAddHeaderData = {
  title: "Generate Long-Lived Credentials",
  tagline: "Generate and download Mondoo Platform credentials.",
  flags: [],
  docsLink:
    "https://mondoo.com/docs/cnspec/cnspec-adv-install/registration-keys/",
};

const WorkstationMondooHeaderData: IntegrationAddHeaderData = {
  title: "Engineering Workstation",
  tagline: "LOCAL CNSPEC AND CNQUERY INSTALLATION FOR ENGINEERS",
  flags: [
    { id: "agentless_scanning", title: "Agentless Scanning" },
    { id: "local_scanning", title: "Local Scanning" },
    { id: "remote_scanning", title: "Remote Scanning" },
  ],
  docsLink: "https://mondoo.com/docs/cnspec/cnspec-adv-install/overview/",
};

// CHAT OPS DATA

const ChatopsMsteamsHeaderData: IntegrationAddHeaderData = {
  title: "Microsoft Teams",
  tagline: "Microsoft Teams is the hub for team collaboration in Microsoft 365",
  flags: [{ id: "chat_ops", title: "Chat Ops" }],
  docsLink: "https://mondoo.com/docs/platform/maintain/alerting/msteams/",
};

const ChatopsSlackHeaderData: IntegrationAddHeaderData = {
  title: "Slack Notifications",
  tagline: "Slack is a cloud-based proprietary instant messaging platform",
  flags: [{ id: "chat_ops", title: "Chat Ops" }],
  docsLink: "https://mondoo.com/docs/platform/maintain/alerting/slack/",
};
const ChatopsTelegramHeaderData: IntegrationAddHeaderData = {
  title: "Telegram",
  tagline: "Telegram is a cloud-based instant messaging service",
  flags: [{ id: "chat_ops", title: "Chat Ops" }],
  docsLink: "https://mondoo.com/docs/platform/maintain/alerting/telegram/",
};
const ChatopsWebhookHeaderData: IntegrationAddHeaderData = {
  title: "Outbound Webhook",
  tagline: "A webhook delivers event data to a HTTP endpoint",
  flags: [{ id: "chat_ops", title: "Chat Ops" }],
  docsLink: "https://mondoo.com/docs/platform/maintain/alerting/webhook/",
};
