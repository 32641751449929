import { ReactNode } from "react";
import { Box, Button, Paper, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { ComplianceFramework } from "~/operations";
import { Org, Space } from "~/lib/types";
import { useEmptyState } from "./useEmptyState";

type Props = {
  contentType: string;
  frameworkMrn?: ComplianceFramework["mrn"];
  space?: Space;
  children?: ReactNode;
};

export function EmptyState({
  contentType,
  frameworkMrn,
  space,
  children,
}: Props) {
  const { emptyStateContent } = useEmptyState({ space, frameworkMrn });

  const values = emptyStateContent(contentType);

  if (!values) return;

  return (
    <Paper
      className="empty-state"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: 5,
      }}
    >
      {values.icon}
      <Typography
        variant="h5"
        fontWeight={700}
        sx={{ mt: 2, mb: 1, textAlign: "center" }}
      >
        {values.headline}
      </Typography>
      {values?.textContent}
      {values?.actionButtons && (
        <Box textAlign="center" pt={3}>
          {values.actionButtons.map((actionButton, index) => (
            <Button
              key={index}
              variant="outlined"
              component={RouterLink}
              to={actionButton.to}
              sx={{
                mx: 1,
                borderColor: "secondary.light",
                color: "secondary.light",
              }}
            >
              {actionButton.text}
            </Button>
          ))}
        </Box>
      )}
      {children}
    </Paper>
  );
}
