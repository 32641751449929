import { useSearchParams } from "react-router-dom";

const TITLE_FILTER_KEY = "title";
const VERSION_FILTER_KEY = "version";
const PACKAGES_FILTER_KEY = "packages";

export function useRemediationFilterV2() {
  const [searchParams, setSearchParams] = useSearchParams();

  const getFilter = (filterKey: string) => {
    return searchParams.get(filterKey);
  };

  const setFilter = (filterKey: string, filterValue: string) => {
    if (searchParams.get(filterKey) === filterValue) {
      searchParams.delete(filterKey);
    } else {
      searchParams.set(filterKey, filterValue);
    }
  };

  const setPackages = (packages: string[]) => {
    if (packages.length > 0) {
      searchParams.set(PACKAGES_FILTER_KEY, packages.join(","));
    } else {
      searchParams.delete(PACKAGES_FILTER_KEY);
    }
  };

  const getPackages = () => {
    return searchParams.get(PACKAGES_FILTER_KEY)?.split(",") || [];
  };

  const applyFilter = () => {
    setSearchParams(searchParams);
  };

  const setTitle = (title: string) => {
    setFilter(TITLE_FILTER_KEY, title);
  };

  const getTitle = () => {
    return getFilter(TITLE_FILTER_KEY);
  };

  const setVersion = (version: string) => {
    setFilter(VERSION_FILTER_KEY, version);
  };

  const getVersion = () => {
    return getFilter(VERSION_FILTER_KEY);
  };

  return {
    setTitle,
    setVersion,
    setPackages,
    getTitle,
    getVersion,
    getPackages,
    applyFilter,
  };
}
