import { ChangeEvent, Fragment, SyntheticEvent, useState } from "react";
import {
  useNavigate,
  useParams,
  useSearchParams,
  Link as RouterLink,
} from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Link,
  Typography,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  Checkbox,
  Breadcrumbs,
  Button,
  Box,
} from "@mui/material";
import { ContentFlag } from "~/components/content-flag";
import { ClickableTableRow } from "~/components/report";
import { LoadingPage, LoadingFailedPage } from "~/components/loading";
import { FormatRelativeDateAbbreviated } from "~/lib/date";
import { IamActions } from "~/lib/iam";
import { Org, Space } from "~/lib/types";
import {
  ClientIntegration,
  ClientIntegrationType,
  GetIntegrationsSummaryDocument,
  IntegrationType,
  ListClientIntegrationsDocument,
  TestIamActionsQuery,
  useDeleteClientIntegrationMutation,
  useListClientIntegrationsQuery,
} from "~/operations";

import { pluralize } from "~/lib/pluralize";
import { IntegrationsListHeader } from "../headers/integrations-list-header";
import { IntegrationsTableEmptyRow } from "../components/integrations-table-empty-row";
import { ConfirmDeleteDialog } from "../components/confirm-delete-dialogue";
import {
  mapIntegrationRouteToTitle,
  mapIntegrationRouteToClientIntegrationType,
  mapIntegrationRouteToIntegrationType,
  mapintegrationRouteToTableHeaders,
} from "./helpers";
import { ArrowForwardIcon, HomeIcon } from "~/components/icons";
import {
  ChatIntegrationName,
  SpaceIntegrationSection,
} from "~/pages/settings-integrations";
import { DataTable, SelectionToolbar } from "~/components/data-table";
import { integrationDetailHref } from "~/lib/integrations";
import { setDocumentTitle } from "~/utils/commonUtils";

type Props = {
  id?: string;
  space: Space;
  org?: Org;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function HostedIntegrationList({ space, availablePermissions }: Props) {
  let navigate = useNavigate();
  const { integrationTypeId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams, _setSearchParams] = useSearchParams();
  const url = new URL(window.location.toString());
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selection, setSelection] = useState<ClientIntegration["mrn"][]>([]);

  const integrationTitle = mapIntegrationRouteToTitle(integrationTypeId);
  const clientIntegrationTypes =
    mapIntegrationRouteToClientIntegrationType(integrationTypeId);
  const integrationType =
    mapIntegrationRouteToIntegrationType(integrationTypeId);
  const tableHeaders = mapintegrationRouteToTableHeaders(integrationTypeId);

  const { data, loading, error } = useListClientIntegrationsQuery({
    variables: {
      input: {
        spaceMrn: space.mrn,
        filterQuery: {
          types: clientIntegrationTypes,
        },
      },
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "network-only",
    pollInterval: 10000,
  });

  const [deleteIntegration] = useDeleteClientIntegrationMutation({
    refetchQueries: [
      ListClientIntegrationsDocument,
      GetIntegrationsSummaryDocument,
    ],
  });

  if (error) {
    return <LoadingFailedPage what="integrations" />;
  }

  if (loading || !data?.listClientIntegrations) {
    return <LoadingPage what="integrations" />;
  }

  const { integrations } = data.listClientIntegrations;
  const hasIntegrations = Boolean(integrations?.length > 0);

  // Check if user has permission to delete integrations
  // AWS integrations cannot be deleted via multi-select
  const hasIntegrationDeletePermission =
    availablePermissions?.includes(
      IamActions.INTEGRATIONS_INTEGRATIONSMANAGER_DELETE,
    ) && integrationType !== IntegrationType.Aws;

  const getId = (mrn: string) => {
    return [...mrn.split(`/`)].pop();
  };

  const handleSelection = (
    e: ChangeEvent<HTMLInputElement>,
    mrn: ClientIntegration["mrn"],
  ) => {
    if (e.target.checked) {
      setSelection((prev) => [...prev, mrn]);
    } else {
      const updated = selection.filter((x) => x !== mrn);
      setSelection(updated);
    }
  };

  const handleSelectAll = () => {
    const updated =
      selection.length > 0 && integrations.length > 0
        ? []
        : integrations.flatMap((x) => (x?.mrn ? [x.mrn] : []));
    setSelection(updated);
  };

  const handleDeleteClick = () => {
    handleDelete();
  };

  const handleCancelClick = () => {
    setSelection([]);
  };

  //Delete functionality
  const handleDelete = async () => {
    try {
      Promise.all(
        selection.map((mrn) =>
          deleteIntegration({ variables: { input: { mrn: mrn } } }),
        ),
      );
      enqueueSnackbar(
        `Successfully removed ${
          selection.length
        } ${integrationTitle} ${pluralize(
          "integration",
          selection.length,
        )} from Mondoo`,
        {
          variant: "success",
        },
      );
      setSelection([]);
    } catch {
      enqueueSnackbar(
        `Could not remove ${selection.length} ${integrationTitle} ${pluralize(
          "integration",
          selection.length,
        )} from Mondoo`,
        {
          variant: "error",
        },
      );
    } finally {
      setIsOpen(false);
    }
  };

  const routeToServiceAccounts = (e: SyntheticEvent, mrn: string) => {
    e.stopPropagation();
    searchParams.set("queryTerms", mrn);
    navigate(`/space/settings/serviceaccounts?${searchParams}`);
  };

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?spaceId=${space.id}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Typography
      key={1}
      component={RouterLink}
      color="primary.light"
      to={`/space/integrations?spaceId=${space.id}`}
    >
      Integrations
    </Typography>,
    <Typography key={2}>{integrationTitle}</Typography>,
  ];

  setDocumentTitle([integrationTitle, "Integrations"]);

  // Show chatops view if integration type is one of chatops
  if (
    integrationTypeId &&
    ["msteams", "slack", "telegram", "httppost"].includes(integrationTypeId)
  ) {
    return (
      <Fragment>
        <Breadcrumbs sx={{ mb: 4 }} separator="›">
          {breadcrumbs}
        </Breadcrumbs>
        <SpaceIntegrationSection
          {...{ space, availablePermissions }}
          view={integrationTypeId as ChatIntegrationName}
        />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Breadcrumbs sx={{ mb: 4 }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>
      <IntegrationsListHeader
        {...{
          space,
          type: integrationType,
          hasIntegrations,
        }}
      />
      <Box sx={{ mb: 5 }} />
      <DataTable
        id={`${integrationTypeId}-integrations-table`}
        selectable={hasIntegrationDeletePermission}
        selection={selection}
      >
        {hasIntegrations && (
          <TableHead>
            <TableRow>
              {hasIntegrationDeletePermission && (
                <TableCell>
                  <Checkbox
                    checked={
                      integrations.length > 0 &&
                      selection.length === integrations.length
                    }
                    indeterminate={
                      selection.length > 0 &&
                      selection.length < integrations.length
                    }
                    onChange={handleSelectAll}
                    disabled={integrations.length === 0}
                  />
                </TableCell>
              )}
              {tableHeaders.map((header) => {
                return (
                  <TableCell
                    key={header.id}
                    align={header.options?.textAlign}
                    width={header.options?.width}
                  >
                    <TableSortLabel
                      direction="desc"
                      active={header.id === "CREATED_AT"}
                    >
                      {header.label}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {hasIntegrations ? (
            integrations
              .flatMap((integration) => integration ?? [])
              .map((integration) => {
                const { type, mrn } = integration;
                const id = getId(mrn);
                const href = integrationDetailHref(type, mrn, space);
                const tableRowsMaps =
                  mapintegrationRouteToTableHeaders(integrationTypeId);
                const integrationStatus =
                  integration?.status.toLowerCase() === "not_ready"
                    ? "pending"
                    : integration?.status.split("_").join(" ");

                const isSelected = selection.includes(integration.mrn);
                const className = isSelected ? "selected" : "";

                return (
                  <ClickableTableRow
                    key={id}
                    onClick={() => navigate(href)}
                    className={className}
                    data-name={`${integrationTypeId}-${getId(integration.mrn)}`}
                  >
                    {hasIntegrationDeletePermission && (
                      <TableCell>
                        <Checkbox
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) => handleSelection(e, integration.mrn)}
                          checked={isSelected}
                        />
                      </TableCell>
                    )}
                    {tableRowsMaps.map((header) => (
                      <TableCell
                        key={header.id}
                        align={header.options?.textAlign}
                        width={header.options?.width}
                      >
                        {header.id === "NAME" ? (
                          integration.name
                        ) : header.id === "STATUS" ? (
                          <ContentFlag
                            flag={integrationStatus}
                            color={integration.status}
                          />
                        ) : header.id === "CREATED_AT" ? (
                          FormatRelativeDateAbbreviated(integration.created)
                        ) : header.id === "LAST_SCAN" ||
                          header.id === "LAST_EXPORT" ||
                          header.id === "LAST_UPDATED" ? (
                          FormatRelativeDateAbbreviated(integration.lastCheckin)
                        ) : header.id === "SERVICE_ACCOUNT" &&
                          integrationStatus !== "pending" ? (
                          <Button
                            variant="text"
                            color="secondary"
                            onClick={(e) =>
                              routeToServiceAccounts(e, integration.mrn)
                            }
                            endIcon={<ArrowForwardIcon />}
                          >
                            Service Account
                          </Button>
                        ) : header.id === "GITHUB_TYPE" &&
                          integration.configurationOptions?.__typename ===
                            "GithubConfigurationOptions" ? (
                          integration.configurationOptions.repository ? (
                            "Repository"
                          ) : (
                            "Organization"
                          )
                        ) : header.id === "K8S_VERSION" ? (
                          integration.lastStateInfo?.k8sVersion || "unknown"
                        ) : header.id === "TYPE" ? (
                          integration.configurationOptions?.__typename ===
                          "AWSConfigurationOptions" ? (
                            `Serverless, ${
                              integration.configurationOptions.isOrganization
                                ? "organization"
                                : "single account"
                            }`
                          ) : integration.configurationOptions?.__typename ===
                            "HostedAwsConfigurationOptions" ? (
                            "Hosted, single account"
                          ) : (
                            ""
                          )
                        ) : null}
                      </TableCell>
                    ))}
                  </ClickableTableRow>
                );
              })
          ) : (
            <IntegrationsTableEmptyRow
              space={space}
              integrationTypeId={integrationTypeId}
            />
          )}
        </TableBody>
      </DataTable>
      {selection.length > 0 && (
        <SelectionToolbar>
          <Typography>
            Selected {selection.length} of {integrations.length} integrations
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDeleteClick}
          >
            Delete
          </Button>
          <Button onClick={handleCancelClick}>Cancel</Button>
        </SelectionToolbar>
      )}
      {/* Delete Integration Modal */}
      <ConfirmDeleteDialog
        id={`confirm-delete-${integrationTypeId}-item-list-page`}
        title={`Remove ${integrationTitle} Integration`}
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        onConfirm={() => handleDelete()}
        content={
          <Typography>
            Are you absolutely sure you want to remove this integration? This
            operation cannot be undone.
          </Typography>
        }
        value={""}
      />
    </Fragment>
  );
}
