import { useState } from "react";
import { SoftwareVersionTooltipData } from "~/pages/space/software/Software/components/SoftwareVersions/SoftwareVersionTooltipData";
import { Flex } from "~/components/Flex";
import { IconButton, Typography } from "@mui/material";
import { AggregateScoresEdges } from "~/components/FirewatchPage";
import { AggregateScoreType, ScoreRating } from "~/operations";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { getLinkByType } from "~/components/FirewatchPage/lib";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";
import { RiskFactorsIconsProps } from "~/pages/space/security/components/RiskFactors/RiskFactorsIcons";
import { VersionDistrEntry } from "../SoftwareVersionDistribution/hooks/useSoftwareVersionDistribution";

type MultipleSoftwareVersionsTooltipProps = {
  versions: VersionDistrEntry[];
  edges: AggregateScoresEdges;
  riskFactors: RiskFactorsIconsProps["riskFactors"];
  scope: SpaceOrWorkspaceScope;
};

export const MultipleSoftwareVersionsTooltip = ({
  versions,
  edges,
  riskFactors,
  scope,
}: MultipleSoftwareVersionsTooltipProps) => {
  const [currentVersionIndex, setCurrentVersionIndex] = useState(0);

  const currentVersion = versions?.[currentVersionIndex];

  if (!currentVersion) return;

  const edge = edges?.find((e) =>
    e?.node?.findingMrn.split("/").includes(currentVersion.version),
  );
  const cvss = edge?.node?.cvss?.value || 0;
  const epss = edge?.node?.epss;

  const isLeftArrowDisabled = currentVersionIndex === 0;
  const isRightArrowDisabled = currentVersionIndex === versions.length - 1;

  return (
    <Flex flexDirection="column">
      <Flex justifyContent="center" alignItems="center">
        <IconButton
          onClick={() => setCurrentVersionIndex((prevState) => prevState - 1)}
          disabled={isLeftArrowDisabled}
        >
          <ArrowLeft color={isLeftArrowDisabled ? "disabled" : "primary"} />
        </IconButton>
        <Typography color="text.secondary">
          {currentVersionIndex + 1}/{versions.length}
        </Typography>
        <IconButton
          onClick={() => setCurrentVersionIndex((prevState) => prevState + 1)}
          disabled={isRightArrowDisabled}
        >
          <ArrowRight color={isRightArrowDisabled ? "disabled" : "primary"} />
        </IconButton>
      </Flex>
      <SoftwareVersionTooltipData
        blastRadius={edge?.node?.blastRadius}
        riskValue={edge?.node?.riskValue || 0}
        rating={edge?.node?.rating || ScoreRating.None}
        epssScore={epss}
        cvss={cvss}
        title={String(edge?.node?.title)}
        riskFactors={riskFactors}
        href={getLinkByType({
          type: AggregateScoreType.VersionedSoftware,
          queryParams: scope.params,
          findingMrn: edge?.node?.findingMrn + "/" + currentVersion.version,
        })}
      />
    </Flex>
  );
};
