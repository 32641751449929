import { Box, Typography } from "@mui/material";
import {
  GetOrganizationOverviewQuery,
  useGetOrganizationOverviewQuery,
} from "~/operations";
import DashboardSkeleton from "~/pages/organization/dashboard/DashboardSkeleton";

type RawData =
  | NonNullable<
      GetOrganizationOverviewQuery["organizationOverview"]
    >["eolAssets"]
  | undefined;
type Data = NonNullable<RawData>;

export type EndOfLifeProps = {
  organizationMrn: string;
};
export function EndOfLife({ organizationMrn }: EndOfLifeProps) {
  const result = useGetOrganizationOverviewQuery({
    variables: { input: { organizationMrn } },
  });

  if (result.loading) {
    return <DashboardSkeleton />;
  }

  const rawData = result.data?.organizationOverview?.eolAssets;
  const data = parseData(rawData);

  return (
    <Box>
      <Typography
        component="span"
        sx={{ fontSize: 40, fontWeight: "bold", lineHeight: 1 }}
      >
        {data}
      </Typography>{" "}
      <Typography
        component="span"
        sx={{
          fontSize: 16,
          fontWeight: "bold",
          textTransform: "uppercase",
          lineHeight: 1,
        }}
      >
        Assets
      </Typography>
    </Box>
  );
}

function parseData(rawData: RawData): Data {
  return typeof rawData === "number" ? rawData : 0;
}
