import React from "react";
import { Grid } from "@mui/material";
import {
  GetCasesDocument,
  PageInfo,
  useCloseCaseMutation,
  useGetCasesQuery,
} from "~/operations";
import { EmptySection } from "~/components/vulnerabilities";
import { CasesSkeleton } from "~/components/cases/components/CasesPage/components";
import { ListCaseEdges } from "~/components/cases/types";
import { getError } from "~/lib/handle-error";
import { useSnackbar } from "notistack";
import { CasesTable } from "~/components/cases/components/CasesTable/CasesTable";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";

type OpenCasesProps = {
  hasCloseCasePermissions: boolean;
  isLoading: boolean;
  openCases: ListCaseEdges;
  fetchMoreOpenCases: ReturnType<typeof useGetCasesQuery>["fetchMore"];
  openCasesTotalCount: number;
  openCasesPageInfo: PageInfo | undefined;
  scope: SpaceOrWorkspaceScope;
};

export const OpenCases = ({
  hasCloseCasePermissions,
  openCases,
  isLoading,
  fetchMoreOpenCases,
  openCasesPageInfo,
  openCasesTotalCount,
  scope,
}: OpenCasesProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [closeCase, { loading: closeCaseLoading }] = useCloseCaseMutation({
    refetchQueries: [GetCasesDocument],
  });

  const handleCloseCase = async (caseMrn: string) => {
    if (!hasCloseCasePermissions) return;

    try {
      await closeCase({
        variables: {
          input: {
            mrn: caseMrn,
          },
        },
      });
    } catch (e) {
      const errorMessage = getError(e);
      enqueueSnackbar(
        `Something went wrong while attempting to close the case: ${errorMessage}`,
        { variant: "error" },
      );
    }
  };

  const isEmpty = openCases.length === 0;

  return (
    <Grid item xs={12}>
      {isLoading ? (
        <CasesSkeleton />
      ) : isEmpty ? (
        <EmptySection
          id="open-cases-empty"
          text="There are currently no open tickets."
        />
      ) : (
        <CasesTable
          isLoading={isLoading || closeCaseLoading}
          cases={openCases}
          fetchMore={fetchMoreOpenCases}
          pageInfo={openCasesPageInfo}
          totalCount={openCasesTotalCount}
          onClose={handleCloseCase}
          isOpen
          scope={scope}
        />
      )}
    </Grid>
  );
};
