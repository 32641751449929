import { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, Button } from "@mui/material";
import { GlobalWords } from "../lib/global.words";
import { useAuth } from "~/login/provider";
import { Config } from "~/configuration_provider";
import { Code } from "./code";

type DeveloperInfoState = {
  show: boolean;
  idToken: string;
};

export function DeveloperInfo() {
  const auth = useAuth();
  const [state, setState] = useState<DeveloperInfoState>({
    show: false,
    idToken: "",
  });

  useEffect(() => {
    GlobalWords.register("info", async () => {
      const idToken = (await auth.state.user?.getIdToken()) || "";
      setState((prevState) => {
        return {
          ...prevState,
          idToken,
          show: true,
        };
      });
    });
  }, []);

  const refreshToken = async () => {
    const idToken = (await auth.state.user?.getIdToken(true)) || "";
    setState((prevState) => {
      return {
        ...prevState,
        idToken,
      };
    });
  };

  return (
    <Dialog
      aria-labelledby="developer-info-modal-title"
      aria-describedby="developer-info-modal-desc"
      open={state.show}
      onClose={() => setState((prevState) => ({ ...prevState, show: false }))}
    >
      <DialogTitle>Developer Info</DialogTitle>
      <DialogContent>
        <span>
          Mondoo UI <small>({Config.VITE_RELEASE})</small>
        </span>
        <Code copyButton children={`Bearer ${state.idToken}`} />
        <Button onClick={refreshToken}>Refresh token</Button>
      </DialogContent>
    </Dialog>
  );
}
