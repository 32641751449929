import { alpha, Button, Stack, Tooltip, Typography } from "@mui/material";
import { copyToClipboard } from "~/lib/clipboard";

type TagButton = {
  key: string;
  value?: string | null | undefined;
  displayValue?: string;
};

export type TagButtonProps = {
  item: TagButton;
};

export function TagButton({ item }: TagButtonProps) {
  const handleCopyClick = async () => {
    if (item.value) {
      await copyToClipboard(item.value);
    }
  };

  return (
    <Tooltip title={item.displayValue ? item.value : ""} arrow>
      <Stack
        component={Button}
        onClick={handleCopyClick}
        direction="row"
        spacing={0.5}
        sx={(theme) => ({
          py: 0.5,
          px: 1,
          background: alpha(theme.palette.primary.main, 0.4),
          borderColor: theme.palette.primary.main,
          borderWidth: 1,
          borderStyle: "solid",
          borderRadius: 1,
          textTransform: "unset",
          "& .MuiTypography-root": {
            fontSize: 12,
            lineHeight: "16px",
          },
        })}
      >
        <Typography>{item.key}</Typography>
        <Typography fontWeight={700} sx={{ wordBreak: "break-all" }}>
          {item.displayValue || item.value}
        </Typography>
      </Stack>
    </Tooltip>
  );
}
