import Grid from "@mui/material/Grid2";
import { WorkloadIdentityProviderCard } from "~/pages/space-settings/WorkloadIdentity/components/WorkloadIdentityProviderCard";
import { SpaceScope } from "~/hooks/useScope";
import { availableProviders } from "../../constants";

type WorkloadIdentityProvidersListProps = {
  scope: SpaceScope;
};

export const WorkloadIdentityProvidersList = ({
  scope,
}: WorkloadIdentityProvidersListProps) => {
  return (
    <Grid container spacing={3} className="integration-cards-list">
      {availableProviders.map((provider, key) => (
        <Grid
          size={{
            xs: 12,
            sm: 6,
            md: 4,
          }}
          key={key}
        >
          <WorkloadIdentityProviderCard
            href={`/space/settings/workload-identity/providers/add/${provider.type}?${scope.params}`}
            title={provider.title}
            description={provider.description}
            IconComponent={provider.icon}
            type={provider.type}
          />
        </Grid>
      ))}
    </Grid>
  );
};
