import { TableCell } from "@mui/material";
import {
  RiskFactorsIcons,
  RiskFactorsIconsProps,
} from "~/pages/space/security/components/RiskFactors/RiskFactorsIcons";

export type RiskFactorsCellProps = {
  riskFactors: RiskFactorsIconsProps["riskFactors"];
  printView?: boolean;
  "data-testid"?: string;
};

export const RiskFactorsCell = ({
  riskFactors,
  printView = false,
  ...props
}: RiskFactorsCellProps) => {
  return (
    <TableCell data-testid={props["data-testid"]}>
      <RiskFactorsIcons riskFactors={riskFactors} printView={printView} />
    </TableCell>
  );
};
