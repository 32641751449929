import { alpha, Grid2, Stack, Typography, useTheme } from "@mui/material";
import { CardSizes, HubCard } from "~/components/hub-card";
import { WarningAmberIcon } from "~/components/icons";
import { ElbowIcon, MTTRSlaIcon } from "~/components/icons/mondoo/dashboard";
import { ImpactUpdated } from "~/components/impact/Updated/impact-updated";
import { useMetrics } from "~/hooks";
import { useScope } from "~/hooks/useScope";
import { getColor } from "~/lib/colors";
import { Metrics } from "~/utils/arrow";
import { MTTRDate } from "./MTTRDate";

type Props = {
  sizes?: CardSizes;
};

export function MTTRSlaCard({ sizes }: Props) {
  let theme = useTheme();
  const { activeScope } = useScope();

  const { mttrSla, isLoading } = useMetrics({
    entityMrn: activeScope?.mrn || "",
    metricMrns: [Metrics.MTTRSla],
  });

  return (
    <HubCard
      {...{
        loading: isLoading,
        sizes,
        title: "SLA & MTTR PERFORMANCE BY RISK",
        icon: <MTTRSlaIcon />,
      }}
      PaperProps={{ minHeight: 234 }}
      data-testid="mttr-sla-card"
    >
      <Grid2 container spacing={3} sx={{ pt: 2 }}>
        {mttrSla.map((sla, index) => {
          const { rating, mttr, nearDate, pastDate, slaDays } = sla;
          const color = getColor(theme, rating);
          const slasPastDue = Boolean(pastDate);

          return (
            <Grid2
              key={index}
              size={{ xs: 12, sm: 6, lg: 3 }}
              sx={{
                p: 2,
                background: alpha(color, 0.1),
                borderRadius: 1,
                ...(slasPastDue && {
                  boxShadow: `inset 0 0 0 2px ${theme.palette.warning.main}`,
                }),
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ mb: 1 }}
              >
                <ImpactUpdated
                  impact={{
                    rating: rating,
                  }}
                  hideTooltip
                  options={{ fontSize: 12, fontWeight: 700 }}
                />
                <MTTRDate mttr={mttr} color={color} />
              </Stack>

              <Stack
                direction="column"
                rowGap={1}
                sx={{ pt: 1, borderTop: `1px solid ${alpha(color, 0.1)}` }}
              >
                <Stack direction="row" justifyContent="space-between">
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: 12,
                      fontWeight: 700,
                      lineHeight: "12px",
                      color: "text.secondary",
                    }}
                  >
                    SLA
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: 12,
                      fontWeight: 700,
                      lineHeight: "12px",
                      color: theme.palette.text.primary,
                    }}
                  >
                    {slaDays}d
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Stack direction="row" gap={0.5} sx={{ pl: 0.5 }}>
                    <ElbowIcon sx={{ fontSize: 8 }} />
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: 12,
                        fontWeight: 700,
                        lineHeight: "12px",
                        color: "text.secondary",
                      }}
                    >
                      Findings nearing SLA date
                    </Typography>
                  </Stack>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: 12,
                      fontWeight: 700,
                      lineHeight: "12px",
                      color: theme.palette.text.primary,
                    }}
                  >
                    {nearDate}
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Stack direction="row" gap={0.5} sx={{ pl: 0.5 }}>
                    <ElbowIcon sx={{ fontSize: 8 }} />
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: 12,
                        fontWeight: 700,
                        lineHeight: "12px",
                        color: "text.secondary",
                      }}
                    >
                      Findings past SLA date
                    </Typography>
                  </Stack>
                  <Stack
                    position="relative"
                    direction="row"
                    gap={0.5}
                    alignItems="center"
                  >
                    {slasPastDue && (
                      <WarningAmberIcon
                        sx={{
                          position: "absolute",
                          left: -16,
                          top: -0.5,
                          fontSize: 14,
                          color: "warning.main",
                        }}
                      />
                    )}
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: 12,
                        fontWeight: 700,
                        lineHeight: "12px",
                        color: slasPastDue
                          ? theme.palette.text.primary
                          : theme.palette.text.disabled,
                      }}
                    >
                      {pastDate}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Grid2>
          );
        })}
      </Grid2>
    </HubCard>
  );
}
