import { Flex } from "~/components/Flex";
import { SoftwareVersionLabel } from "~/pages/space/software/Software/components/SoftwareVersionDistribution/SoftwareVersionLabel";
import { SoftwareVersionTooltip } from "~/pages/space/software/Software/components/SoftwareVersionDistribution/SoftwareVersionTooltip";
import { SoftwareVersionUnitBase } from "~/pages/space/software/Software/components/SoftwareVersionDistribution/SoftwareVersionUnitBase";
import { MultipleSoftwareVersionsTooltip } from "~/pages/space/software/Software/components/SoftwareVersions/MultipleSoftwareVersionsTooltip";
import { VersionDistrEntry } from "./hooks/useSoftwareVersionDistribution";
import { AggregateScoresEdges } from "~/components/FirewatchPage";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";
import { RiskFactorsIconsProps } from "~/pages/space/security/components/RiskFactors/RiskFactorsIcons";

type SoftwareVersionRestProps = {
  width: number;
  color: string;
  version: string;
  riskFactors: RiskFactorsIconsProps["riskFactors"];
  versions: VersionDistrEntry[];
  edges: AggregateScoresEdges;
  scope: SpaceOrWorkspaceScope;
};

export const SoftwareVersionRest = ({
  color,
  version,
  riskFactors,
  versions,
  edges,
  scope,
}: SoftwareVersionRestProps) => {
  return (
    <Flex alignSelf="flex-end" flexDirection="column">
      <SoftwareVersionLabel color={color} version={version} />
      <SoftwareVersionTooltip
        content={
          <MultipleSoftwareVersionsTooltip
            edges={edges}
            riskFactors={riskFactors}
            versions={versions}
            scope={scope}
          />
        }
      >
        <SoftwareVersionUnitBase color={color} />
      </SoftwareVersionTooltip>
    </Flex>
  );
};
