import { Advisories } from "./Advisories";
import { useAssetOutlet } from "~/pages/inventory/asset";
import { useSearchParams } from "react-router-dom";

export const AdvisoriesTab = () => {
  const [searchParams, _setSearchParams] = useSearchParams();
  const { isCicd, space, asset, isVulnerabilityScanningEnabled } =
    useAssetOutlet();
  const assetMrn = isCicd ? (searchParams.get("jobId") as string) : asset?.mrn;

  if (!space || !asset || !assetMrn) return <></>;

  return (
    <Advisories
      assetMrn={assetMrn}
      space={space}
      isVulnerabilityScanningEnabled={isVulnerabilityScanningEnabled}
      availablePermissions={space.iamActions}
    />
  );
};
