import { useState } from "react";
import { useQuery } from "@apollo/client";
import {
  AggregateScoreOrderField,
  AggregateScoreType,
  GET_AGGREGATE_SCORES,
  GetAggregateScoresQueryResult,
  OrderDirection,
} from "~/operations";
import { AggregateScoresEdges } from "~/components/FirewatchPage";

export const useFetchAllItems = ({ scopeMrn }: { scopeMrn: string }) => {
  const [allItems, setAllItems] = useState<AggregateScoresEdges>([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const pageSize = 100;

  const { fetchMore, networkStatus: fetchAllNetworkStatus } = useQuery(
    GET_AGGREGATE_SCORES,
    {
      variables: {
        entityMrn: scopeMrn || "",
        first: 100,
        orderBy: {
          field: AggregateScoreOrderField.Rank,
          direction: OrderDirection.Asc,
        },
        filter: {
          scoreType: AggregateScoreType.Check,
        },
      },
      notifyOnNetworkStatusChange: true,
      onCompleted: (data: GetAggregateScoresQueryResult["data"]) =>
        handleNewData(data),
    },
  );

  const handleNewData = async (data: GetAggregateScoresQueryResult["data"]) => {
    if (
      !data ||
      data.aggregateScores?.__typename !== "AggregateScoresConnection"
    )
      return;

    const newEdges = data.aggregateScores.edges;
    const hasNextPage = data.aggregateScores.pageInfo.hasNextPage;

    setAllItems((prev) => [...(prev ?? []), ...(newEdges ?? [])]);

    if (!hasNextPage) {
      setHasMore(false);
      setLoading(false);
    } else {
      const after = data.aggregateScores.pageInfo.endCursor;

      await fetchMore({
        variables: {
          first: pageSize,
          after,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return fetchMoreResult;
        },
      });
    }
  };

  return { allItems, loading, fetchAllNetworkStatus };
};
