import React, { Fragment, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  ActionType,
  ClientIntegrationType,
  GetClientIntegrationDocument,
  GetIntegrationsSummaryDocument,
  GetTicketContextDocument,
  IntegrationType,
  useCreateClientIntegrationMutation,
  useTriggerActionLazyQuery,
  useUpdateClientIntegrationConfigurationMutation,
} from "~/operations";
import { Space } from "~/lib/types";
import { getError } from "~/lib/handle-error";
import { IntegrationAddHeader } from "../../../headers/integration-add-header";
import { UpdateFlowData } from "../../../types";
import useGenerateIntegrationName from "../../../utils/useGenerateIntegrationName";
import { useJiraTicketContext } from "~/hooks/useJiraTicketContext";
import { JiraFormInput } from "./types";
import { JiraFormStep1 } from "./JiraFormStep1";
import { JiraFormStep2 } from "~/pages/integrations/hosted-integrations/forms/jira/JiraFormStep2";
import { setDocumentTitle } from "~/utils/commonUtils";

const defaultValues: JiraFormInput = {
  integrationName: "",
  host: "",
  email: "",
  apiToken: "",
  autoCloseTickets: false,
  autoCreateCases: false,
  defaultProject: "",
};

export function JiraIntegrationForm({
  space,
  updateFlow,
}: {
  space: Space;
  updateFlow?: UpdateFlowData;
}) {
  const [searchParams] = useSearchParams();
  const redirectToParam = searchParams.get("redirectTo");
  const stepFromParams = searchParams.get("step");
  const [step, setStep] = useState<number>(Number(stepFromParams) || 1);
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const defaultIntegrationName = useGenerateIntegrationName({ space });

  const {
    control,
    handleSubmit,
    reset,
    getFieldState,
    formState: { errors, isValid, isSubmitSuccessful },
    watch,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      integrationName: defaultIntegrationName,
    },
  });

  const { projects, isProjectsEmpty } = useJiraTicketContext({
    scopeMrn: updateFlow?.integration?.mrn || "",
    skip: !updateFlow?.integration.mrn,
  });

  const [watchAutoCreateOnDrift] = watch(["autoCreateCases"]);

  const [createIntegration] = useCreateClientIntegrationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      const integrationMrn = data.createClientIntegration.integration.mrn;
      triggerClientIntegrationScan({
        variables: { input: { mrn: integrationMrn, type: ActionType.RunScan } },
      });
    },
    refetchQueries: [
      GetTicketContextDocument,
      {
        query: GetIntegrationsSummaryDocument,
        variables: { input: { spaceMrn: space.mrn } },
      },
      {
        query: GetClientIntegrationDocument,
        variables: {
          mrn: `//integration.api.mondoo.app/spaces/${
            space.id
          }/integrations/${updateFlow?.integration.mrn.split("/").pop()}`,
        },
      },
    ],
  });

  const [updateIntegration] = useUpdateClientIntegrationConfigurationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    refetchQueries: [
      {
        query: GetClientIntegrationDocument,
        variables: {
          mrn: `//integration.api.mondoo.app/spaces/${
            space.id
          }/integrations/${updateFlow?.integration.mrn.split("/").pop()}`,
        },
      },
    ],
  });

  const [triggerClientIntegrationScan] = useTriggerActionLazyQuery({
    onError(error) {
      console.log("%c Error Scheduling scan on creation", "color: tomato");
      console.log(error.message);
    },
  });

  useEffect(() => {
    if (updateFlow) {
      const configOptions = updateFlow.integration.configurationOptions;
      if (configOptions?.__typename !== "JiraConfigurationOptions") return;
      reset({
        host: configOptions.host,
        email: configOptions.email,
        integrationName: updateFlow.integration.name,
        autoCloseTickets: configOptions.autoCloseTickets,
        autoCreateCases: configOptions.autoCreateCases,
        defaultProject: configOptions.defaultProject,
      });
    }
  }, []);

  useEffect(() => {
    if (isSubmitSuccessful && !updateFlow) {
      reset(defaultValues);
    }
  }, [isSubmitSuccessful]);

  const onSubmit: SubmitHandler<JiraFormInput> = async (data) => {
    const jiraConfigurationOptions = {
      host: data.host.trim(),
      email: data.email.trim(),
      apiToken: data.apiToken?.trim?.() || "",
      autoCloseTickets: data.autoCloseTickets,
      autoCreateCases: data.autoCreateCases,
      defaultProject: data.defaultProject,
    };

    const integrationName =
      data.integrationName?.trim() || updateFlow?.integration.name;

    try {
      if (updateFlow || step === 2) {
        await updateIntegration({
          variables: {
            input: {
              name: integrationName,
              mrn: `//integration.api.mondoo.app/spaces/${
                space.id
              }/integrations/${updateFlow?.integration.mrn.split("/").pop()}`,
              type: ClientIntegrationType.TicketSystemJira,
              configurationOptions: {
                jiraConfigurationOptions,
              },
            },
          },
        });
        const integrationId = updateFlow?.integration.mrn.split("/").pop();
        enqueueSnackbar("Successfully updated configuration", {
          variant: "success",
        });
        if (step === 1 && updateFlow) {
          setStep(2);
          return;
        } else {
          navigate(
            `/space/integrations/ticket_system_jira/${integrationId}/?spaceId=${space.id}`,
          );
        }
      } else {
        const { data: createIntegrationData } = await createIntegration({
          variables: {
            input: {
              spaceMrn: space.mrn,
              name: data.integrationName.trim(),
              type: ClientIntegrationType.TicketSystemJira,
              longLivedToken: false,
              configurationOptions: {
                jiraConfigurationOptions,
              },
            },
          },
        });
        const integrationId =
          createIntegrationData?.createClientIntegration.integration.mrn
            .split("/")
            .pop();
        if (step === 1) {
          navigate(
            `/space/integrations/ticket_system_jira/${integrationId}/edit?spaceId=${space.id}&step=2`,
          );
          return;
        }
      }
      if (redirectToParam) {
        navigate(redirectToParam);
      } else {
        navigate(`/space/integrations/ticket_system_jira?spaceId=${space.id}`);
      }
    } catch (e) {
      const msg = getError(e);
      enqueueSnackbar(msg, { variant: "error" });
    }
  };

  setDocumentTitle(["Atlassian Jira", "Integrations Setup"]);

  return (
    <Fragment>
      <IntegrationAddHeader {...{ type: IntegrationType.TicketSystemJira }} />
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          {step === 1 && (
            <JiraFormStep1
              control={control}
              isUpdateFlow={!!updateFlow}
              errors={errors}
              getFieldState={getFieldState}
              isValid={isValid}
              disabled={!watchAutoCreateOnDrift}
              isProjectsEmpty={isProjectsEmpty}
              projects={projects}
            />
          )}

          {step === 2 && (
            <JiraFormStep2
              control={control}
              isValid={isValid}
              disabled={!watchAutoCreateOnDrift}
              isProjectsEmpty={isProjectsEmpty}
              projects={projects}
              isUpdateFlow={!!updateFlow}
            />
          )}
        </form>
      </Box>
    </Fragment>
  );
}
