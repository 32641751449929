import { alpha, Box, Typography } from "@mui/material";
import { Asset, ListPolicyEdge } from "~/pages/inventory/asset/types";
import { useImpactUpdated } from "./impact/Updated/useImpactUpdated";
import { ImpactUpdated } from "./impact/Updated/impact-updated";

export type AssetScoreBoxProps = {
  score: Asset["score"] | ListPolicyEdge["node"]["score"];
  policyBox?: boolean;
};

export function AssetScoreBox({ score, policyBox }: AssetScoreBoxProps) {
  const dotSize = policyBox ? 8 : 16;
  const scoreFontSize = policyBox ? 48 : 72;
  const ratingFontSize = policyBox ? 16 : 24;

  const { color } = useImpactUpdated({
    impact: { value: score.riskValue, rating: score.riskRating },
    isActive: true,
  });

  return (
    <Box
      sx={{
        minHeight: "100%",
        p: 3,
        background: alpha(color, 0.1),
        borderRadius: 1,
        boxShadow: `inset 0 0 0 2px ${color}`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 2,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
        <Typography
          sx={{
            fontSize: 12,
            fontWeight: 700,
            textTransform: "uppercase",
            color,
          }}
        >
          Risk Score
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 3,
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: scoreFontSize,
              fontWeight: 700,
              color: color,
              lineHeight: "100%",
            }}
          >
            {score.riskValue}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 1,
          }}
        >
          <Typography
            variant="h5"
            sx={{
              textTransform: "uppercase",
              fontSize: ratingFontSize,
              fontWeight: 700,
              color: "text.primary",
            }}
          >
            {score.riskRating}
          </Typography>
          <ImpactUpdated
            impact={{
              value: score.riskValue,
              rating: score.riskRating,
            }}
            showText={false}
            isRiskScore
            hideTooltip
            options={{
              size: dotSize,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
