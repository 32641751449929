import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { CloseIcon } from "~/components/icons";
import { LoadingButton } from "~/components/loading-button";
import { ExceptionMutationAction } from "~/operations";
import { DisableIcon, SnoozeIcon } from "~/components/ui-library";
import { useColorMode } from "~/providers/color-mode";
import { ExceptionTarget } from "~/components/exceptions/types";
import { targetLabelMap } from "~/components/exceptions/constants";

export enum ExceptionFormValidUntil {
  Indefinitely = "INDEFINITELY",
  Week1 = "WEEK1",
  Month1 = "MONTH1",
  Month3 = "MONTH3",
  Month6 = "MONTH6",
  Year1 = "YEAR1",
}

export type ExceptionFormInput = {
  action: ExceptionMutationAction;
  validUntil: ExceptionFormValidUntil;
  justification: string;
};

export type SetExceptionDialogProps = {
  open: boolean;
  onClose: () => void;
  onSave: (params: ExceptionFormInput) => Promise<void>;
  loading: boolean;
  target: ExceptionTarget;
  role: "security" | "compliance" | "asset";
};

export function SetExceptionDialog({
  loading,
  open,
  onSave,
  onClose,
  target,
  role,
}: SetExceptionDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { mode } = useColorMode();

  const { register, handleSubmit, formState, reset, control, watch } =
    useForm<ExceptionFormInput>({
      mode: "onChange",
      defaultValues: {
        action: ExceptionMutationAction.Snooze,
        validUntil: ExceptionFormValidUntil.Indefinitely,
        justification: "",
      },
    });

  const actionValue = watch("action");
  const validUntilDisabled = actionValue !== ExceptionMutationAction.Snooze;

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleFormSubmit: SubmitHandler<ExceptionFormInput> = async (
    formData,
  ) => {
    try {
      await onSave(formData);
    } catch (error) {
      enqueueSnackbar(`Failed to set exception`, {
        variant: "error",
      });
    } finally {
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      PaperProps={{
        // @ts-ignore https://github.com/mui/material-ui/pull/32404#issuecomment-1105228783
        component: "form",
        onSubmit: handleSubmit(handleFormSubmit),
      }}
      onClose={handleClose}
      data-test-id="set-exception-dialog"
    >
      <DialogTitle
        component="div"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            textTransform: "uppercase",
            fontWeight: "700",
            display: "flex",
            alignItems: "center",
          }}
        >
          Set An Exception
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography color="text.secondary">
          Snooze or disable a {targetLabelMap[target]} to exclude it from your{" "}
          {role === "compliance" ? "compliance score" : "security assessment"}.
        </Typography>
        <FormControl fullWidth>
          <Typography
            variant="body2"
            sx={{
              my: 3,
              fontSize: 24,
              fontWeight: 700,
              "::before": {
                content: "'1'",
                display: "inline-flex",
                width: "32px",
                height: "32px",
                borderRadius: "50%",
                mr: 2,
                backgroundColor: "background.lightest",
                alignItems: "center",
                justifyContent: "center",
                fontSize: 16,
              },
            }}
          >
            Define the exception type
          </Typography>
          <Controller
            rules={{ required: true }}
            control={control}
            name="action"
            render={({ field }) => (
              <RadioGroup {...field} sx={{ pl: 1 }}>
                <Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <FormControlLabel
                      value={ExceptionMutationAction.Snooze}
                      control={<Radio data-test-id="snooze-control" />}
                      label="Snooze"
                      sx={{
                        mr: 1,
                        ".MuiFormControlLabel-label": { fontWeight: 700 },
                      }}
                    />
                    <SnoozeIcon />
                  </Box>
                  <Typography
                    color="text.secondary"
                    fontSize={12}
                    sx={{ ml: 4 }}
                  >
                    Until the snooze period ends, Mondoo shows the results for
                    this {target} but excludes those results from your overall{" "}
                    {role} score.
                  </Typography>
                  <Box>
                    <Controller
                      rules={{ required: !validUntilDisabled }}
                      control={control}
                      name="validUntil"
                      render={({ field }) => (
                        <RadioGroup {...field} row sx={{ my: 2, ml: 4 }}>
                          <FormControlLabel
                            value={ExceptionFormValidUntil.Indefinitely}
                            control={<Radio />}
                            label="Indefinitely"
                            disabled={validUntilDisabled}
                          />
                          <Divider
                            orientation="vertical"
                            flexItem
                            sx={{ mr: 2 }}
                          />
                          <FormControlLabel
                            value={ExceptionFormValidUntil.Week1}
                            control={<Radio />}
                            label="1 week"
                            disabled={validUntilDisabled}
                          />
                          <FormControlLabel
                            value={ExceptionFormValidUntil.Month1}
                            control={<Radio />}
                            label="1 month"
                            disabled={validUntilDisabled}
                          />
                          <FormControlLabel
                            value={ExceptionFormValidUntil.Month3}
                            control={<Radio />}
                            label="3 months"
                            disabled={validUntilDisabled}
                          />
                          <FormControlLabel
                            value={ExceptionFormValidUntil.Month6}
                            control={<Radio />}
                            label="6 months"
                            disabled={validUntilDisabled}
                          />
                          <FormControlLabel
                            value={ExceptionFormValidUntil.Year1}
                            control={<Radio />}
                            label="1 year"
                            disabled={validUntilDisabled}
                          />
                        </RadioGroup>
                      )}
                    />
                  </Box>
                </Box>
                <Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <FormControlLabel
                      value={ExceptionMutationAction.Disable}
                      control={<Radio data-test-id="disable-control" />}
                      label="Disable"
                      sx={{
                        mr: 1,
                        ".MuiFormControlLabel-label": { fontWeight: 700 },
                      }}
                    />
                    <DisableIcon />
                  </Box>
                  <Typography
                    color="text.secondary"
                    fontSize={12}
                    sx={{ ml: 4 }}
                  >
                    Mondoo completely eliminates the {target} from your {role}{" "}
                    assessment. Choose this option if the {target} is entirely
                    out of scope for your{" "}
                    {role === "compliance"
                      ? "compliance audit"
                      : "security needs"}
                    .
                  </Typography>
                </Box>
              </RadioGroup>
            )}
          />
        </FormControl>
        <FormControl fullWidth>
          <Typography
            variant="body2"
            sx={{
              my: 3,
              fontSize: 24,
              fontWeight: 700,
              "::before": {
                content: "'2'",
                display: "inline-flex",
                width: "32px",
                height: "32px",
                borderRadius: "50%",
                mr: 2,
                backgroundColor: "background.lightest",
                alignItems: "center",
                justifyContent: "center",
                fontSize: 16,
              },
            }}
          >
            Provide a justification{" "}
            <Typography variant="caption" color="text.secondary">
              (optional)
            </Typography>
          </Typography>
          <TextField
            placeholder="Provide a reason for your changes..."
            multiline
            rows={8}
            {...register("justification")}
            error={Boolean(formState.errors.justification)}
            helperText={formState.errors.justification?.message}
            InputProps={{
              sx: {
                backgroundColor:
                  mode === "light" ? "common.white" : "common.black",
                borderRadius: 1,
              },
            }}
            data-test-id="justification-input"
          />
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", pb: 5 }}>
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          disabled={!formState.isValid}
          loading={loading}
          buttonText="Save Exception"
          data-test-id="save-exception-button"
        />
      </DialogActions>
    </Dialog>
  );
}
