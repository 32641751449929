import { createSvgIcon } from "@mui/material";

export const PriorityFindingsIcon = createSvgIcon(
  <svg viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.1882 6.28025L15.9489 8.51952C16.7859 9.5235 17.2895 10.8151 17.2895 12.2241C17.2895 15.4211 14.697 18.0136 11.5 18.0136C8.30301 18.0136 5.71053 15.4211 5.71053 12.2241C5.71053 9.02713 8.30301 6.43465 11.5 6.43465C12.909 6.43465 14.2006 6.93823 15.2046 7.7752L17.4439 5.53593C15.8635 4.13047 13.7816 3.27675 11.5 3.27675C6.55804 3.27675 2.55263 7.28217 2.55263 12.2241C2.55263 17.1661 6.55804 21.1715 11.5 21.1715C16.442 21.1715 20.4474 17.1661 20.4474 12.2241C20.4474 9.94256 19.5936 7.86061 18.1882 6.28025ZM15.2008 9.26761L12.925 11.5435C13.0237 11.7496 13.0789 11.9804 13.0789 12.2241C13.0789 13.0958 12.3717 13.8031 11.5 13.8031C10.6283 13.8031 9.92105 13.0958 9.92105 12.2241C9.92105 11.3524 10.6283 10.6452 11.5 10.6452C11.7437 10.6452 11.9745 10.7004 12.1807 10.7991L14.4565 8.52328C13.6461 7.87499 12.6182 7.48728 11.5 7.48728C8.88436 7.48728 6.76316 9.60848 6.76316 12.2241C6.76316 14.8398 8.88436 16.961 11.5 16.961C14.1156 16.961 16.2368 14.8398 16.2368 12.2241C16.2368 11.1059 15.8491 10.078 15.2008 9.26761ZM11.8809 11.8611C11.8778 11.8582 11.8748 11.8553 11.8718 11.8523C11.8689 11.8493 11.8659 11.8463 11.863 11.8433C11.7685 11.7532 11.6407 11.6978 11.5 11.6978C11.2096 11.6978 10.9737 11.9337 10.9737 12.2241C10.9737 12.5145 11.2096 12.7504 11.5 12.7504C11.7904 12.7504 12.0263 12.5145 12.0263 12.2241C12.0263 12.0835 11.971 11.9556 11.8809 11.8611ZM21.5 12.2241C21.5 17.7474 17.0233 22.2241 11.5 22.2241C5.97669 22.2241 1.5 17.7474 1.5 12.2241C1.5 6.70081 5.97669 2.22412 11.5 2.22412C17.0233 2.22412 21.5 6.70081 21.5 12.2241Z"
      fill="currentColor"
    />
  </svg>,
  "Priority Findings",
);
