import {
  ConfigurationListSubheader,
  ConfigurationPaper,
} from "~/components/configuration-items";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Grid2, Typography } from "@mui/material";
import { QueryBuilder } from "~/pages/space/Workspaces/components/QueryBuilder";
import { CreateWorkspaceFormInput } from "~/pages/space/Workspaces/components/CreateWorkspace/CreateWorkspacePage";
import { useEffect } from "react";
import {
  mapFormValuesToQueryBuilderAPI,
  mapQueryBuilderAPIToFormValues,
} from "~/pages/space/Workspaces/utils";
import {
  useGetWorkspaceSelectionsQuery,
  useUpdateWorkspaceMutation,
  WorkspaceSelectionInput,
} from "~/operations";
import { ApolloError } from "@apollo/client";
import { useSnackbar } from "notistack";
import { Flex } from "~/components/Flex";
import { useScope } from "~/hooks/useScope";
import { useMetrics } from "~/hooks";
import { Metrics } from "~/utils/arrow";
import { SelectionAssetsCount } from "../space/Workspaces/components";

export const AssetSelectionSettings = () => {
  const [updateWorkspace] = useUpdateWorkspaceMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { workspaceScope, spaceScope } = useScope();
  const { data: workspaceSelectionsData } = useGetWorkspaceSelectionsQuery({
    variables: {
      mrn: workspaceScope?.mrn || "",
    },
    skip: !workspaceScope?.mrn,
  });

  const { assetRiskRatings: spaceAssetRiskRatings } = useMetrics({
    entityMrn: spaceScope?.mrn || "",
    metricMrns: [Metrics.AssetsRiskRatingDistribution],
  });

  const { assetRiskRatings: workspaceAssetRiskRatings } = useMetrics({
    entityMrn: workspaceScope?.mrn || "",
    metricMrns: [Metrics.AssetsRiskRatingDistribution],
  });

  const totalAssetsCount = spaceAssetRiskRatings.total;

  const workspaceAssetsCount = workspaceAssetRiskRatings.total;

  const workspaceSelections =
    workspaceSelectionsData?.workspace?.__typename === "Workspace"
      ? workspaceSelectionsData?.workspace?.selections?.selections
      : [];

  const formMethods = useForm<CreateWorkspaceFormInput>({
    mode: "onBlur",
    defaultValues: {
      name: "",
      description: "",
      selections: [],
    },
  });

  useEffect(() => {
    formMethods.reset({
      selections: mapQueryBuilderAPIToFormValues(workspaceSelections),
    });
  }, [workspaceSelections]);

  const handleFormSubmit: SubmitHandler<CreateWorkspaceFormInput> = async (
    values,
  ) => {
    try {
      const selections: Array<WorkspaceSelectionInput> =
        mapFormValuesToQueryBuilderAPI(values.selections);

      await updateWorkspace({
        variables: {
          input: {
            mrn: workspaceScope?.mrn || "",
            selections: {
              selections,
            },
          },
        },
      });

      enqueueSnackbar(`Successfully updated asset selection.`, {
        variant: "success",
      });
    } catch (err) {
      if (err instanceof ApolloError) {
        err.graphQLErrors.map((e) => {
          enqueueSnackbar(e.message, { variant: "error" });
        });
      } else {
        enqueueSnackbar("Failed to update asset selection.", {
          variant: "error",
        });
      }
    }
  };

  return (
    <ConfigurationPaper>
      <ConfigurationListSubheader component="div">
        <Flex
          justifyContent="space-between"
          flex="1 0 auto"
          alignItems="center"
        >
          <Typography component="span" variant="h6">
            Workspace Asset Selections
          </Typography>
          <SelectionAssetsCount
            selectionCount={workspaceAssetsCount}
            totalCount={totalAssetsCount}
          />
        </Flex>
      </ConfigurationListSubheader>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
          <Grid2 size={12} p={3}>
            <QueryBuilder showFormActions={formMethods.formState.isDirty} />
          </Grid2>
        </form>
      </FormProvider>
    </ConfigurationPaper>
  );
};
