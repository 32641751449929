import { Box, Collapse, Typography } from "@mui/material";
import { useSections } from "~/hooks/useSections";
import { CvssRiskProvider, CvssScore } from "./useCvssRisk";
import { CvssRiskContent } from "./cvss-risk-content";
import { CvssRiskTitle } from "./cvss-risk-title";
import { EpssRiskProvider, EpssScore } from "./useEpssRisk";
import { EpssRiskTitle } from "./epss-risk-title";
import { EpssRiskContent } from "./epss-risk-content";
import { RiskFactorStats } from "~/operations";
import RiskFactorsContent from "~/components/vulnerabilities/risk-factors-content";
import { RiskFactorsTitle } from "~/components/vulnerabilities/risk-factors-title";

type RiskStatsWithDescription = RiskFactorStats & {
  description: { active: string; inactive: string };
};
export type CveRiskFactorsProps = {
  cvssScore?: CvssScore | null;
  epssScore?: EpssScore | null;
  id?: string;
  riskFactors?: (RiskStatsWithDescription | null)[] | null | undefined;
  RiskFactorsProps?: {
    hideValues?: boolean;
  };
  CVSSProps?: {
    flipContent?: boolean;
  };
  EPSSProps?: {
    flipContent?: boolean;
  };
};

export function CveRiskFactors({
  cvssScore,
  epssScore,
  id,
  riskFactors,
  RiskFactorsProps,
  CVSSProps,
  EPSSProps,
}: CveRiskFactorsProps) {
  const sections = useSections({
    key: "cveRiskFactors",
    sections: ["riskFactors", "cvss", "epss"],
  });

  return (
    <Box id={id} display="flex" flexDirection="column" gap={5}>
      {riskFactors && riskFactors.length > 0 && (
        <Box>
          <RiskFactorsTitle
            open={sections.isOpen("riskFactors")}
            onClick={() => sections.toggle("riskFactors")}
            showSubtitle
            riskFactors={riskFactors}
            hideValues={RiskFactorsProps?.hideValues}
          />
          <Collapse in={sections.isOpen("riskFactors")} unmountOnExit>
            <Box>
              <RiskFactorsContent
                riskFactors={riskFactors}
                hideValues={RiskFactorsProps?.hideValues}
              />
            </Box>
          </Collapse>
        </Box>
      )}
      {cvssScore && cvssScore.vector && (
        <Box>
          <CvssRiskProvider vector={cvssScore.vector}>
            <CvssRiskTitle
              open={sections.isOpen("cvss")}
              onClick={() => sections.toggle("cvss")}
            />
            <Collapse in={sections.isOpen("cvss")} unmountOnExit>
              <Box sx={{ pt: 3 }}>
                <CvssRiskContent flipContent={CVSSProps?.flipContent} />
              </Box>
            </Collapse>
          </CvssRiskProvider>
        </Box>
      )}
      {cvssScore && epssScore && (
        <Box>
          <EpssRiskProvider
            probability={epssScore.probability}
            percentile={epssScore.percentile}
            cvssScore={cvssScore.value}
          >
            <EpssRiskTitle
              open={sections.isOpen("epss")}
              onClick={() => sections.toggle("epss")}
            />
            <Collapse in={sections.isOpen("epss")} unmountOnExit>
              <Box sx={{ pt: 3 }}>
                <EpssRiskContent flipContent={EPSSProps?.flipContent} />
              </Box>
            </Collapse>
          </EpssRiskProvider>
        </Box>
      )}
    </Box>
  );
}
