import { Exceptions } from "~/components/exceptions/exceptions";
import {
  AssetAdvisoryException,
  AssetCheckException,
  AssetCveException,
  ExceptionGroup,
  GetAdvisoriesDocument,
  ListExceptionGroupsDocument,
  LoadAssetDocument,
} from "~/operations";
import { useAssetOutlet } from "~/pages/inventory/asset";
import {
  AssetAdvisoryExceptionTable,
  AssetCheckExceptionTable,
} from "./AssetExceptionsTable";
import { AssetCveExceptionTable } from "~/components/AssetExceptionsTable/AssetCveExceptionsTable";

export const AssetExceptionsWrapper = () => {
  const { exceptionGroups, space, assetId, assetMrn } = useAssetOutlet();

  return (
    <Exceptions
      availablePermissions={space.iamActions}
      emptyStateType="check-exceptions"
      exceptionGroups={exceptionGroups}
      refetchQueries={[
        ListExceptionGroupsDocument,
        LoadAssetDocument,
        GetAdvisoriesDocument,
      ]}
      renderTable={function (exceptionGroup: ExceptionGroup) {
        const { exceptions } = exceptionGroup;

        const item = exceptions[0];
        // Determine if the exception is on an advisory or a check
        const isAdvisory = item && item.__typename === "AssetAdvisoryException";
        const isCheck = item && item.__typename === "AssetCheckException";
        const isCVE = item && item.__typename === "AssetCveException";

        if (isCheck) {
          return (
            <AssetCheckExceptionTable
              space={space}
              assetId={assetId}
              exceptions={exceptions as AssetCheckException[]}
            />
          );
        }

        if (isAdvisory) {
          return (
            <AssetAdvisoryExceptionTable
              space={space}
              exceptions={exceptions as AssetAdvisoryException[]}
            />
          );
        }

        if (isCVE) {
          return (
            <AssetCveExceptionTable
              exceptions={exceptions as AssetCveException[]}
            />
          );
        }
        return <></>;
      }}
    />
  );
};
