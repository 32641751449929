import { useState } from "react";
import { CheckboxProps } from "@mui/material";
import { useSnackbar } from "notistack";
import { Space } from "~/lib/types";
import {
  ComplianceControl,
  ComplianceFramework,
  DocumentMetadata,
  DocumentMetadataConnection,
  ListDocumentsDocument,
  useDeleteDocumentMutation,
  useListDocumentsQuery,
  useRequestDocumentDownloadUrlMutation,
} from "~/operations";
import { pluralize } from "~/lib/pluralize";
import { getError } from "~/lib/handle-error";
import { downloadFromUrl } from "~/lib/download-from-url";

export interface UseReportExports {
  selectedReports: ComplianceFramework["mrn"][] | ComplianceControl["mrn"][];
  handle: {
    selectionChange: (checked: CheckboxProps["checked"], mrn: string) => void;
    selectAll: (checked: boolean, pageSize: number, pageNumber: number) => void;
    downloadSingle: (report: DocumentMetadata) => void;
    delete: () => void;
    deleteSingle: (documentMrn: DocumentMetadata["mrn"]) => void;
    cancel: () => void;
    fetchMore: ReturnType<typeof useListDocumentsQuery>["fetchMore"];
  };
  documentList: {
    data: DocumentMetadataConnection | undefined;
    reports: DocumentMetadata[];
    error: ReturnType<typeof useListDocumentsQuery>["error"];
    loading: ReturnType<typeof useListDocumentsQuery>["loading"];
  };
}

type UseReportProps = {
  space: Space;
  onDeleteSucceed: () => void;
};

export const useReports = ({
  space,
  onDeleteSucceed,
}: UseReportProps): UseReportExports => {
  const [selectedReports, setSelectedReports] = useState<string[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  const { data, error, loading, fetchMore } = useListDocumentsQuery({
    variables: {
      scopeMrn: space.mrn,
      first: 10,
    },
    fetchPolicy: "cache-and-network",
  });

  const [deleteDocumentMutation] = useDeleteDocumentMutation({
    refetchQueries: [ListDocumentsDocument],
  });

  const [requestDocumentDownloadUrlMutation] =
    useRequestDocumentDownloadUrlMutation();

  const handleSelectionChange = (
    checked: CheckboxProps["checked"],
    mrn: string,
  ) => {
    let nextSelection: typeof selectedReports = [];
    if (checked) {
      nextSelection = [...selectedReports, mrn];
    } else {
      nextSelection = selectedReports.filter((x) => x !== mrn);
    }

    setSelectedReports(nextSelection);
  };

  const handleSelectAll = (checked: boolean, from: number, to: number) => {
    let currentReportsMrns = (data?.listDocuments?.edges || [])
      .map((edge) => edge.node.mrn)
      .slice(from, to);

    if (!checked) {
      setSelectedReports((alreadySelectedReports) =>
        alreadySelectedReports.filter(
          (mrn) => !currentReportsMrns.includes(mrn),
        ),
      );
      return;
    }

    setSelectedReports((alreadySelectedReports) => [
      ...alreadySelectedReports,
      ...currentReportsMrns,
    ]);
  };

  const handleDownloadSingle = async (report: DocumentMetadata) => {
    try {
      const request = await requestDocumentDownloadUrlMutation({
        variables: { documentMrn: report.mrn },
      });

      let url = request.data?.requestDocumentDownloadUrl?.downloadUrl;
      if (url) {
        downloadFromUrl(url, report.name);
        return;
      }

      throw new Error("No download URL was returned");
    } catch (e) {
      const errorMessage = getError(e);
      enqueueSnackbar(
        `Something went wrong while attempting to download: ${errorMessage}`,
        { variant: "error" },
      );
    }
  };

  const handleDelete = async () => {
    try {
      await Promise.all(
        selectedReports.map((documentMrn) =>
          deleteDocumentMutation({
            variables: {
              documentMrn,
            },
          }),
        ),
      );

      enqueueSnackbar(
        `Successfully deleted ${selectedReports.length} ${pluralize(
          "report",
          selectedReports.length,
        )}`,
        { variant: "success" },
      );

      setSelectedReports([]);
      onDeleteSucceed();
    } catch (e) {
      const errorMessage = getError(e);
      enqueueSnackbar(
        `Something went wrong while attempting to delete ${pluralize(
          "report",
          selectedReports.length,
        )}: ${errorMessage}`,
        { variant: "error" },
      );
    }
  };

  const handleDeleteSingle = async (documentMrn: DocumentMetadata["mrn"]) => {
    try {
      await deleteDocumentMutation({
        variables: {
          documentMrn,
        },
      });
      enqueueSnackbar(`Successfully deleted report`, { variant: "success" });
      //Remove the deleted report from the selected reports in case it
      // was selected previously
      const nextSelection = selectedReports.filter((x) => x !== documentMrn);
      setSelectedReports(nextSelection);
    } catch (e) {
      const errorMessage = getError(e);
      enqueueSnackbar(
        `Something went wrong while attempting to delete your report: ${errorMessage}`,
        { variant: "error" },
      );
    }
  };

  const handleCancel = () => {
    setSelectedReports([]);
  };

  return {
    selectedReports,
    handle: {
      selectionChange: handleSelectionChange,
      selectAll: handleSelectAll,
      downloadSingle: handleDownloadSingle,
      delete: handleDelete,
      deleteSingle: handleDeleteSingle,
      cancel: handleCancel,
      fetchMore,
    },
    documentList: {
      data: data?.listDocuments,
      reports: data?.listDocuments?.edges?.map((edge) => edge.node) || [],
      error,
      loading,
    },
  };
};
