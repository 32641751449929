import { AssetContextualLinkType } from "~/pages/space/vulnerabilities/components/AffectedAssets";

type AssetContextualLinkProps = {
  type: (typeof AssetContextualLinkType)[keyof typeof AssetContextualLinkType];
  assetId: string;
  contextId?: string;
  scopeParams: URLSearchParams;
};

export const useAssetContextualLink = ({
  type,
  assetId,
  contextId,
  scopeParams,
}: AssetContextualLinkProps) => {
  const assetPathByType = {
    [AssetContextualLinkType.Cve]: `/space/inventory/${assetId}/vulnerabilities/${contextId}?${scopeParams}`,
    [AssetContextualLinkType.Advisory]: `/space/inventory/${assetId}/advisories/${contextId}?${scopeParams}`,
    [AssetContextualLinkType.Check]: `/space/inventory/${assetId}/check?checkMrn=${contextId}`,
    [AssetContextualLinkType.Software]: `/space/inventory/${assetId}/software?${scopeParams}${contextId ? `&queryTerms=${contextId}` : ""}`,
    [AssetContextualLinkType.Asset]: `/space/inventory/${assetId}/overview?${scopeParams}`,
  };

  return {
    contextUrl:
      assetPathByType[type] || assetPathByType[AssetContextualLinkType.Asset],
  };
};
