import { Box, Button, Grid, Typography } from "@mui/material";
import { memo, useCallback } from "react";
import { AddButton } from "~/components/add-button";
import { FileUploadIcon } from "~/components/icons";
import { Upload } from "~/components/upload";
import { Space } from "~/lib/types";
import {
  GetListFrameworksDocument,
  UploadFrameworkInput,
  useUploadFrameworkMutation,
} from "~/operations";

type ComplianceHeaderProps = {
  hasFrameworks: boolean;
  hasAddFrameworkPermission: boolean;
  isAdding: boolean;
  setIsAdding: (isAdding: boolean) => void;
  space: Space;
};

const ComplianceHeader = ({
  hasFrameworks,
  hasAddFrameworkPermission,
  isAdding,
  setIsAdding,
  space,
}: ComplianceHeaderProps) => {
  const [uploadFramework] = useUploadFrameworkMutation({
    refetchQueries: [GetListFrameworksDocument],
    onCompleted: () => setIsAdding(false),
  });

  const handleUploadFramework = useCallback((input: UploadFrameworkInput) => {
    return uploadFramework({
      variables: {
        input: { dataurl: input.dataurl, spaceMrn: input.spaceMrn },
      },
    });
  }, []);

  return (
    <Grid
      container
      item
      columnGap={2}
      mt={2}
      mb={4}
      sx={{
        alignItems: {
          xs: "start",
          sm: "center",
        },
      }}
    >
      <Grid item xs>
        <Typography
          component="h1"
          fontWeight="bold"
          sx={{
            fontSize: { xs: 22, sm: 32 },
          }}
        >
          {isAdding ? "AVAILABLE FRAMEWORKS" : "COMPLIANCE"}
        </Typography>
      </Grid>

      <Box
        display="flex"
        justifyContent="end"
        sx={{
          minWidth: 150,
          mt: {
            xs: 0.75,
            sm: 0,
          },
          gap: 2,
        }}
      >
        {hasAddFrameworkPermission && (
          <>
            {isAdding && (
              <Upload
                title="Upload Custom Framework"
                placeholder={`Drag and drop a framework or click to upload a framework`}
                errorMessage={`Failed to upload framework`}
                successMessage={`Successfully uploaded framework`}
                spaceMrn={space.mrn}
                addFileMutation={handleUploadFramework}
                button={
                  <Button
                    variant="outlined"
                    color="primary"
                    data-name="upload-custom-framework"
                    startIcon={<FileUploadIcon />}
                  >
                    Add custom framework
                  </Button>
                }
                force={false}
              />
            )}

            {hasFrameworks && (
              <AddButton
                id="framework"
                aria-label="Add framework"
                close={isAdding}
                onClick={() => setIsAdding(!isAdding)}
              />
            )}
          </>
        )}
      </Box>
    </Grid>
  );
};

export default memo(ComplianceHeader);
