import { createSvgIcon } from "@mui/material";

export const HighPriorityIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.09 6.217H9.937C6.108 6.217 3 9.325 3 13.154C3 16.983 6.108 20.092 9.937 20.092H11.695C12.054 20.092 12.345 19.8 12.345 19.442C12.345 19.083 12.054 18.792 11.695 18.792H9.937C6.826 18.792 4.3 16.266 4.3 13.154C4.3 10.043 6.826 7.517 9.937 7.517H10.179L8.983 8.712C8.729 8.966 8.729 9.378 8.983 9.632C9.11 9.759 9.277 9.822 9.443 9.822C9.609 9.822 9.776 9.759 9.903 9.632L12.164 7.371C12.417 7.117 12.417 6.705 12.164 6.451L9.903 4.191C9.776 4.064 9.61 4 9.443 4C9.277 4 9.11 4.064 8.984 4.191C8.73 4.444 8.73 4.856 8.984 5.11L10.09 6.217ZM15.424 7.523C15.066 7.523 14.774 7.231 14.774 6.873C14.774 6.514 15.066 6.223 15.424 6.223H19.496C19.855 6.223 20.146 6.514 20.146 6.873C20.146 7.231 19.855 7.523 19.496 7.523H15.424ZM15.424 10.666C15.066 10.666 14.774 10.375 14.774 10.016C14.774 9.658 15.066 9.366 15.424 9.366H19.496C19.855 9.366 20.146 9.658 20.146 10.016C20.146 10.375 19.855 10.666 19.496 10.666H15.424ZM15.424 13.81C15.066 13.81 14.774 13.519 14.774 13.16C14.774 12.801 15.066 12.51 15.424 12.51H19.496C19.855 12.51 20.146 12.801 20.146 13.16C20.146 13.519 19.855 13.81 19.496 13.81H15.424ZM15.424 16.954C15.066 16.954 14.774 16.663 14.774 16.304C14.774 15.945 15.066 15.654 15.424 15.654H19.496C19.855 15.654 20.146 15.945 20.146 16.304C20.146 16.663 19.855 16.954 19.496 16.954H15.424ZM15.424 20.097C15.066 20.097 14.774 19.806 14.774 19.447C14.774 19.089 15.066 18.797 15.424 18.797H19.496C19.855 18.797 20.146 19.089 20.146 19.447C20.146 19.806 19.855 20.097 19.496 20.097H15.424Z"
      fill="currentColor"
    />
  </svg>,
  "High Priority",
);
