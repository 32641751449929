import { Fragment, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  ActionType,
  ClientIntegrationType,
  GetClientIntegrationDocument,
  GetIntegrationsSummaryDocument,
  GithubIntegrationType,
  IntegrationType,
  useCreateClientIntegrationMutation,
  useTriggerActionLazyQuery,
  useUpdateClientIntegrationConfigurationMutation,
} from "~/operations";
import { Space } from "~/lib/types";
import { ChevronRightIcon } from "~/components/icons";
import { Command } from "~/components/guides/components";
import { getError } from "~/lib/handle-error";
import { IntegrationAddHeader } from "../../headers/integration-add-header";
import { RecommendedPolicies } from "../../components/recommended-policies";
import {
  GITHUB_ISSUES_PERSONAL_ACCESS_TOKEN_ERROR_MESSAGE,
  GITHUB_ISSUES_PERSONAL_ACCESS_TOKEN_PATTERN,
  helperTextStyles,
  isNotGithubUrl,
  isUrl,
  ValidationMessage,
} from "../../validations/helpers";
import useGenerateIntegrationName from "../../utils/useGenerateIntegrationName";
import { UpdateFlowData } from "../../types";
import { ToggleOption } from "~/pages/integrations/components/ToggleOption/ToggleOption";
import { motion } from "framer-motion";
import { OperatorList } from "~/pages/integrations/kubernetes/operator-configuration/operator-list";
import { motionAccordionVariants } from "~/constants/motion";
import { PasswordField } from "~/components/Form/components/PasswordField";
import { setDocumentTitle } from "~/utils/commonUtils";

type GithubFormInput = {
  integrationName: string;
  type: GithubIntegrationType;
  repository: string | null;
  owner: string | null;
  accessToken: string;
  scanAllRepos: boolean;
  reposAllowList: string;
  reposDenyList: string;
  discoverTerraform?: boolean;
  discoverK8sManifests?: boolean;
  // discoverDockerfiles?: boolean;
  enterpriseUrl?: string;
};

const defaultValues: GithubFormInput = {
  integrationName: "",
  type: GithubIntegrationType.Org,
  repository: "",
  owner: "",
  accessToken: "",
  scanAllRepos: true,
  reposAllowList: "",
  reposDenyList: "",
  discoverTerraform: false,
  discoverK8sManifests: false,
  // discoverDockerfiles: false,
  enterpriseUrl: "",
};

export function GithubIntegrationForm({
  space,
  updateFlow,
}: {
  space: Space;
  updateFlow?: UpdateFlowData;
}) {
  let navigate = useNavigate();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [view, setView] = useState<"setup" | "add policies">("setup");
  const defaultIntegrationName = useGenerateIntegrationName({ space });

  const {
    control,
    handleSubmit,
    watch,
    reset,
    getFieldState,
    formState: { errors, isValid, isSubmitSuccessful },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      integrationName: defaultIntegrationName,
    },
  });

  const [watchType] = watch(["type"]);

  const [createIntegration] = useCreateClientIntegrationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      const integrationMrn = data.createClientIntegration.integration.mrn;
      triggerClientIntegrationScan({
        variables: { input: { mrn: integrationMrn, type: ActionType.RunScan } },
      });
    },
    refetchQueries: [
      {
        query: GetIntegrationsSummaryDocument,
        variables: { input: { spaceMrn: space.mrn } },
      },
    ],
  });

  const [updateIntegration] = useUpdateClientIntegrationConfigurationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    refetchQueries: [
      {
        query: GetClientIntegrationDocument,
        variables: {
          mrn: `//integration.api.mondoo.app/spaces/${
            space.id
          }/integrations/${updateFlow?.integration.mrn.split("/").pop()}`,
        },
      },
    ],
  });

  const [triggerClientIntegrationScan] = useTriggerActionLazyQuery({
    onError(error) {
      console.log("%c Error Scheduling scan on creation", "color: tomato");
      console.log(error.message);
    },
  });

  useEffect(() => {
    if (updateFlow) {
      if (
        updateFlow.integration.configurationOptions?.__typename !==
        "GithubConfigurationOptions"
      ) {
        return;
      }

      reset({
        integrationName: updateFlow.integration.name,
        type: updateFlow.integration.configurationOptions.githubType,
        repository:
          updateFlow.integration.configurationOptions?.repository || undefined,
        owner:
          updateFlow.integration.configurationOptions?.owner ||
          updateFlow.integration.configurationOptions?.githubOrg,
        ...((updateFlow.integration.configurationOptions?.reposAllowList
          ?.length || 0) > 0 ||
        (updateFlow.integration.configurationOptions?.reposAllowList?.length ||
          0) > 0
          ? { scanAllRepos: false }
          : { scanAllRepos: true }),
        reposAllowList: (
          updateFlow.integration.configurationOptions?.reposAllowList || []
        ).join("\n"),
        reposDenyList: (
          updateFlow.integration.configurationOptions?.reposDenyList || []
        ).join("\n"),
        discoverTerraform:
          updateFlow.integration.configurationOptions
            ?.githubDiscoverTerraform ?? false,
        discoverK8sManifests:
          updateFlow.integration.configurationOptions
            ?.githubDiscoverK8sManifests ?? false,
        // discoverDockerfiles:
        //   updateFlow.integration.configurationOptions?.discoverDockerfiles ??
        //   false,
        enterpriseUrl:
          updateFlow.integration.configurationOptions?.enterpriseUrl ?? "",
      });
    }
  }, []);

  useEffect(() => {
    if (isSubmitSuccessful && !updateFlow) {
      reset(defaultValues);
    }
  }, [isSubmitSuccessful]);

  const [watchScanAllRepos] = watch(["scanAllRepos"]);

  const onSubmit: SubmitHandler<GithubFormInput> = async (data) => {
    const splitAndTrim = (string: string): string[] => {
      const replaceNewlinesRegex = new RegExp(/\r?\n|\r/g);
      return string
        .replace(replaceNewlinesRegex, ",")
        .split(",")
        .map((x) => x.trim());
    };

    const githubConfigurationOptions = {
      type: data.type,
      ...(data.type === GithubIntegrationType.Org
        ? {
            organization: data.owner,
            reposAllowList:
              !data.scanAllRepos && data.reposAllowList.length > 0
                ? splitAndTrim(data.reposAllowList)
                : [],
            reposDenyList:
              !data.scanAllRepos && data.reposDenyList.length > 0
                ? splitAndTrim(data.reposDenyList)
                : [],
          }
        : {
            repository: data.repository,
            owner: data.owner,
          }),
      discoverTerraform: data?.discoverTerraform,
      discoverK8sManifests: data?.discoverK8sManifests,
      // discoverDockerfiles: data?.discoverDockerfiles,
      enterpriseUrl: data?.enterpriseUrl,
      token: data.accessToken,
    };

    try {
      if (updateFlow) {
        await updateIntegration({
          variables: {
            input: {
              name: data.integrationName.trim(),
              mrn: `//integration.api.mondoo.app/spaces/${
                space.id
              }/integrations/${updateFlow?.integration.mrn.split("/").pop()}`,
              type: ClientIntegrationType.Github,
              configurationOptions: {
                githubConfigurationOptions,
              },
            },
          },
        });
        const integrationId = updateFlow.integration.mrn.split("/").pop();
        enqueueSnackbar("Successfully updated configuration", {
          variant: "success",
        });
        navigate(
          `/space/integrations/github/${integrationId}/?spaceId=${space.id}`,
        );
      } else {
        await createIntegration({
          variables: {
            input: {
              spaceMrn: space.mrn,
              name: data.integrationName.trim(),
              type: ClientIntegrationType.Github,
              longLivedToken: false,
              configurationOptions: {
                githubConfigurationOptions,
              },
            },
          },
        });
        setView("add policies");
      }
    } catch (e) {
      const msg = getError(e);
      enqueueSnackbar(msg, { variant: "error" });
    }
  };

  setDocumentTitle(["GitHub", "Integrations Setup"]);

  return (
    <Fragment>
      {view === "setup" ? (
        <Fragment>
          <IntegrationAddHeader {...{ type: IntegrationType.Github }} />
          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* Step 1 */}
              <Box pb={4}>
                <Command
                  number={1}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  Choose an integration name
                </Command>

                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2, mt: 2 }}
                  >
                    Please choose a descriptive name that lets you easily
                    identify your integration.
                  </Typography>
                  <Controller
                    name="integrationName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        sx={{
                          background: theme.palette.code.background,
                          borderRadius: 1,
                          color: "text.primary",
                          ...helperTextStyles,
                        }}
                        placeholder="Your integration name..."
                        error={Boolean(errors.integrationName)}
                        helperText={
                          Boolean(errors.integrationName) && (
                            <ValidationMessage error={errors.integrationName} />
                          )
                        }
                      />
                    )}
                  />
                </Box>
              </Box>

              {/* Step 2 */}
              <Box pb={4}>
                <Command
                  number={2}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  Provide your GitHub Enterprise Server URL{" "}
                  <Typography
                    component="span"
                    sx={{ color: "text.secondary", fontStyle: "italic" }}
                  >
                    (optional)
                  </Typography>
                </Command>
                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2, mt: 2 }}
                  >
                    If you use a dedicated GitHub Enterprise server instead of
                    github.com, specify the URL. To learn more, read the{" "}
                    <Link
                      href="https://mondoo.com/docs/platform/infra/saas/github/"
                      target="_blank"
                      rel="noopener"
                    >
                      Mondoo documentation
                    </Link>
                    .
                  </Typography>
                  <Controller
                    name="enterpriseUrl"
                    control={control}
                    rules={{
                      required: false,
                      validate: {
                        isUrl: (value) =>
                          !value ||
                          isUrl(
                            value,
                            "This is not a valid URL. Please enter a valid GitHub Enterprise Server URL.",
                          ),
                        isNotGithubUrl: (value) =>
                          !value || isNotGithubUrl(value),
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        sx={{
                          background: theme.palette.code.background,
                          borderRadius: 1,
                          color: "text.primary",
                          ...helperTextStyles,
                        }}
                        placeholder="e.g., https://github.mycompany.com"
                        error={Boolean(errors.enterpriseUrl)}
                        helperText={
                          Boolean(errors.enterpriseUrl) && (
                            <ValidationMessage error={errors.enterpriseUrl} />
                          )
                        }
                      />
                    )}
                  />
                </Box>
              </Box>

              {/* Step 3 */}
              <Box pb={4}>
                <Command
                  number={3}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  Select your integration type
                </Command>
                <Box sx={{ pl: 1, pt: 2 }}>
                  <Controller
                    name="type"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <RadioGroup {...field}>
                        <Box>
                          <FormControlLabel
                            value={GithubIntegrationType.Org}
                            control={<Radio />}
                            label="Organization"
                            disabled={Boolean(updateFlow)}
                          />
                          <Typography
                            variant="caption"
                            display="block"
                            color="text.secondary"
                            sx={{ pl: 4, mt: -1 }}
                          >
                            Integrate an entire organization with Mondoo and
                            (optionally) scan all repositories in it.
                          </Typography>
                        </Box>
                        <Box>
                          <FormControlLabel
                            value={GithubIntegrationType.Repo}
                            control={<Radio />}
                            label="Repository"
                            disabled={Boolean(updateFlow)}
                          />
                          <Typography
                            variant="caption"
                            display="block"
                            color="text.secondary"
                            sx={{ pl: 4, mt: -1 }}
                          >
                            Integrate a single repository with Mondoo (with no
                            access to the organization).
                          </Typography>
                        </Box>
                      </RadioGroup>
                    )}
                  />
                </Box>
              </Box>

              {/* Step 4 */}
              <Box pb={4}>
                <Command
                  number={4}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  Define the{" "}
                  {watchType === GithubIntegrationType.Repo
                    ? "repository"
                    : "organization"}
                </Command>

                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2, mt: 2 }}
                  >
                    Enter the name of the{" "}
                    {watchType === GithubIntegrationType.Repo
                      ? "repository"
                      : "organization"}{" "}
                    you want to scan. To learn more, read the{" "}
                    <Link
                      href="https://mondoo.com/docs/platform/infra/saas/github/"
                      target="_blank"
                      rel="noopener"
                    >
                      Mondoo documentation
                    </Link>
                    .
                  </Typography>
                  <Grid container columnSpacing={3}>
                    <Grid item xs={12} sm>
                      <Typography fontWeight={700} sx={{ mb: 1 }}>
                        {watchType === GithubIntegrationType.Repo
                          ? "Owner"
                          : "Organization"}
                      </Typography>
                      <Controller
                        name="owner"
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            sx={{
                              background: theme.palette.code.background,
                              borderRadius: 1,
                              color: "text.primary",
                              ...helperTextStyles,
                            }}
                            placeholder={
                              watchType === GithubIntegrationType.Repo
                                ? "organization or user that owns the repository"
                                : "e.g., mondoohq, lunalectric, ..."
                            }
                            error={
                              getFieldState("owner").isTouched &&
                              Boolean(errors.owner)
                            }
                            helperText={
                              Boolean(errors.owner) &&
                              getFieldState("owner").isTouched && (
                                <ValidationMessage error={errors.owner} />
                              )
                            }
                          />
                        )}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm
                      {...(watchType !== GithubIntegrationType.Repo && {
                        sx: { display: "none" },
                      })}
                    >
                      <Typography fontWeight={700} sx={{ mb: 1 }}>
                        Repository
                      </Typography>
                      <Controller
                        name="repository"
                        control={control}
                        rules={{
                          required:
                            watchType === GithubIntegrationType.Repo
                              ? true
                              : false,
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            sx={{
                              background: theme.palette.code.background,
                              borderRadius: 1,
                              color: "text.primary",
                              ...helperTextStyles,
                            }}
                            placeholder={"e.g., frontend"}
                            error={
                              getFieldState("repository").isTouched &&
                              Boolean(errors.repository)
                            }
                            helperText={
                              Boolean(errors.repository) &&
                              getFieldState("repository").isTouched && (
                                <ValidationMessage error={errors.repository} />
                              )
                            }
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Box>
                {watchType === GithubIntegrationType.Org && (
                  <Grid item xs={12} p={4}>
                    <ToggleOption
                      title="Scan all repositories found within the provided organization"
                      description="Allow Mondoo to scan all contained repositories. Disable to define an allow list or deny list instead."
                      formOptions={{ name: "scanAllRepos", control }}
                    />
                    <Box
                      component={motion.div}
                      variants={motionAccordionVariants}
                      initial={false}
                      animate={!watchScanAllRepos ? "open" : "closed"}
                      transition={{
                        duration: 0.35,
                        staggerChildren: 0.25,
                      }}
                      sx={{ overflow: "hidden" }}
                    >
                      <Controller
                        name="reposAllowList"
                        {...{ control }}
                        render={({ field }) => (
                          <OperatorList
                            configuration="Allow list"
                            caption="Specify the repositories to give Mondoo access to. Type each repository name on a new line:"
                            {...{ field }}
                          />
                        )}
                      />

                      <Controller
                        name="reposDenyList"
                        {...{ control }}
                        render={({ field }) => (
                          <OperatorList
                            configuration="Deny list"
                            caption="Specify the repositories for Mondoo to skip. Type each repository name on a new line:"
                            index={1}
                            {...{ field }}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                )}
              </Box>

              {/*  Step 5 */}
              <Box pb={4}>
                <Command
                  number={5}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  Provide your personal access token
                </Command>

                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2, mt: 2 }}
                  >
                    To learn about creating a personal access token, read the{" "}
                    <Link
                      href="https://mondoo.com/docs/platform/infra/saas/github/"
                      target="_blank"
                      rel="noopener"
                    >
                      Mondoo documentation
                    </Link>
                    .
                  </Typography>
                  <Controller
                    name="accessToken"
                    control={control}
                    rules={{
                      required: true,
                      pattern: {
                        value: GITHUB_ISSUES_PERSONAL_ACCESS_TOKEN_PATTERN,
                        message:
                          GITHUB_ISSUES_PERSONAL_ACCESS_TOKEN_ERROR_MESSAGE,
                      },
                    }}
                    render={({ field }) => (
                      <PasswordField
                        {...field}
                        fullWidth
                        sx={{
                          background: theme.palette.code.background,
                          borderRadius: 1,
                          color: "text.primary",
                          ...helperTextStyles,
                        }}
                        placeholder={"xxxx-xxxxxxxxxxxx-xxxx"}
                        error={
                          getFieldState("accessToken").isTouched &&
                          Boolean(errors.accessToken)
                        }
                        helperText={
                          Boolean(errors.accessToken) &&
                          getFieldState("accessToken").isTouched && (
                            <ValidationMessage error={errors.accessToken} />
                          )
                        }
                      />
                    )}
                  />
                </Box>
              </Box>

              {/* Step 6 */}
              <Box pb={4}>
                <Command
                  number={6}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  Select discovery options
                </Command>

                <FormControl>
                  <FormGroup sx={{ mt: 1, pl: 1.5 }}>
                    <Controller
                      name="discoverTerraform"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox {...field} checked={field.value} />
                          }
                          label="Terraform files"
                        />
                      )}
                    />
                    <Controller
                      name="discoverK8sManifests"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox {...field} checked={field.value} />
                          }
                          label="Kubernetes manifests"
                        />
                      )}
                    />
                    {/* <Controller
                      name="discoverDockerfiles"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox {...field} checked={field.value} />
                          }
                          label="Dockerfiles"
                        />
                      )}
                    /> */}
                  </FormGroup>
                </FormControl>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  endIcon={<ChevronRightIcon />}
                  disabled={!isValid}
                >
                  {updateFlow ? "update configuration" : "start scanning"}
                </Button>
              </Box>
            </form>
          </Box>
        </Fragment>
      ) : (
        <RecommendedPolicies {...{ space }} filterTypes={["github"]} />
      )}
    </Fragment>
  );
}
