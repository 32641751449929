import {
  Box,
  Container,
  ContainerOwnProps,
  SxProps,
  Theme,
} from "@mui/material";
import { Outlet } from "react-router-dom";

export function PageLayout({
  maxWidth = "lg",
}: {
  maxWidth?: ContainerOwnProps["maxWidth"] | false;
}) {
  return (
    <Container sx={{ pt: 3 }} maxWidth={maxWidth}>
      <Outlet />
    </Container>
  );
}

export function InventoryPageHeader({
  children,
  sx,
  disableYPadding = false,
}: {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
  disableYPadding?: boolean;
}) {
  return (
    <Box
      width="100%"
      maxWidth={1235}
      sx={{
        display: "block",
        mx: "auto",
        p: 5,
        ...(disableYPadding && { py: 0 }),
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}

export function InventoryPageLayout({
  maxWidth = "lg",
  children,
}: {
  maxWidth?: ContainerOwnProps["maxWidth"] | false;
  children: React.ReactNode;
}) {
  return (
    <Container sx={{ pt: 3 }} maxWidth={maxWidth}>
      {children}
    </Container>
  );
}
