import { Typography, Box, Divider, SxProps } from "@mui/material";
import { Flex } from "~/components/Flex";
import { spacing } from "../reports/lib/SharedVariables";

type SectionHeadingProps = {
  heading: string;
  dividerContent?: string;
  disableUpperPadding?: boolean;
  sx?: SxProps;
};

export const SectionHeading = ({
  heading,
  disableUpperPadding,
  dividerContent,
  sx,
}: SectionHeadingProps) => {
  return (
    <Flex
      center
      gap={3}
      className="section-header"
      sx={{ pt: disableUpperPadding ? 0 : spacing[25], pb: spacing[25], ...sx }}
    >
      <Typography sx={{ whiteSpace: "nowrap", flexShrink: 0, fontWeight: 700 }}>
        {heading}
      </Typography>
      <Box width={1}>
        <Divider
          textAlign="right"
          sx={{ "&::before": { width: "100%" }, "&::after": { width: 0 } }}
        >
          {dividerContent}
        </Divider>
      </Box>
    </Flex>
  );
};
