type DaysHoursMinutes = {
  days: number;
  hours: number;
  minutes: number;
};

const parseSeconds = (seconds: number): DaysHoursMinutes => {
  const SECONDS_IN_MINUTE = 60n;
  const SECONDS_IN_HOUR = 60n * SECONDS_IN_MINUTE;
  const SECONDS_IN_DAY = 24n * SECONDS_IN_HOUR;

  // Calculate the number of days
  const days = BigInt(seconds) / SECONDS_IN_DAY;
  // Calculate the number of hours
  const hours = (BigInt(seconds) % SECONDS_IN_DAY) / SECONDS_IN_HOUR;

  // Calculate the number of minutes
  const minutes = (BigInt(seconds) % SECONDS_IN_HOUR) / SECONDS_IN_MINUTE;

  return {
    days: Number(days),
    hours: Number(hours),
    minutes: Number(minutes),
  };
};

export default parseSeconds;
