import { ActiveScope } from "~/hooks/useScope";

export const getTicketDetailsUrl = ({
  scope,
  ticketMrn,
}: {
  scope: ActiveScope | undefined;
  ticketMrn: string | null | undefined;
}) => {
  if (!scope) {
    throw new Error("scope required to generate ticket details URL");
  }

  if (!ticketMrn) {
    throw new Error("ticketId MRN required to generate ticket details URL");
  }

  return `/space/case?${scope.params}&caseMrn=${ticketMrn}`;
};
