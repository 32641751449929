import {
  useGetTicketContextQuery,
  ZendeskTicketContextFieldsPayload,
  ZendeskTicketContextType,
} from "~/operations";

type UseZendeskTicketContextParams = {
  scopeMrn: string;
  skip?: boolean;
};

export function useZendeskTicketContext({
  scopeMrn,
  skip = false,
}: UseZendeskTicketContextParams) {
  const { data, error, loading } = useGetTicketContextQuery({
    variables: {
      input: {
        scopeMrn,
        zendesk: {
          type: ZendeskTicketContextType.Fields,
        },
      },
    },
    skip: skip,
  });

  const fields = {
    priority: (
      data?.ticketContext as ZendeskTicketContextFieldsPayload | undefined
    )?.priority,
    type: (data?.ticketContext as ZendeskTicketContextFieldsPayload | undefined)
      ?.type,
  };

  return {
    zendeskLoading: loading,
    zendeskFields: fields,
    zendeskErrorMessage: error,
  };
}
