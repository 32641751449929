import { Control, Path, useFormContext } from "react-hook-form";
import { getIsKeyValueKeySelected } from "../../Condition/hooks/useConditionRow";
import {
  RiskFactorConditionKeyValueOperator,
  RiskFactorConditionOperator,
  RiskFactorsConfigurationInput,
} from "../../../RiskFactorsConfigurationForm";
import { OperatorType } from "../../types";

type UseOperatorParams = {
  fieldId: string;
  selectionIndex: number;
  conditionIndex: number;
  type?: OperatorType;
};

type OperatorEnums =
  | RiskFactorConditionOperator
  | RiskFactorConditionKeyValueOperator;

type UseOperatorParamsReturn = {
  name: Path<RiskFactorsConfigurationInput>;
  defaultValue: OperatorEnums;
  control: Control<RiskFactorsConfigurationInput>;
  type: OperatorType;
};

export function useOperator({
  fieldId,
  selectionIndex,
  conditionIndex,
  type: typeFromProps,
}: UseOperatorParams): UseOperatorParamsReturn {
  const { control, watch } = useFormContext<RiskFactorsConfigurationInput>();
  const conditionValue = watch(
    `${fieldId}.selections.${selectionIndex}.conditions.${conditionIndex}`,
  );
  const isKeyValueKeySelected = getIsKeyValueKeySelected(
    conditionValue?.formKey,
  );

  const typeNameMap: Record<
    OperatorType,
    Path<RiskFactorsConfigurationInput>
  > = {
    condition: `${fieldId}.selections.${selectionIndex}.conditions.${conditionIndex}.operator`,
    keyValue: `${fieldId}.selections.${selectionIndex}.conditions.${conditionIndex}.keyValueCondition.operator`,
  };

  const typeDefaultValueMap: Record<OperatorType, OperatorEnums> = {
    condition: RiskFactorConditionOperator.Or,
    keyValue: RiskFactorConditionKeyValueOperator.Contains,
  };

  function getOperatorType(): OperatorType {
    if (isKeyValueKeySelected) return "keyValue";

    return "condition";
  }

  const type = typeFromProps || getOperatorType();

  return {
    name: typeNameMap[type],
    defaultValue: typeDefaultValueMap[type],
    control,
    type,
  };
}
