import { Paper, Typography } from "@mui/material";
import { Flex } from "~/components/Flex";
import { SubdirectoryArrowLeft } from "@mui/icons-material";

export const KeyboardHints = () => {
  return (
    <Paper
      sx={{
        p: 2,
        color: (theme) => theme.palette.text.disabled,
        fontWeight: 600,
      }}
      component={Flex}
      gap={2}
      justifyContent="flex-start"
      alignItems="center"
    >
      <Flex gap={1} alignItems="center">
        <Flex
          sx={{
            border: (theme) => `1px solid ${theme.palette.text.disabled}`,
            borderRadius: "4px",
            px: 1,
            py: 0.5,
            fontSize: "12px",
            lineHeight: "normal",
          }}
          alignItems="center"
        >
          TAB
        </Flex>
        <Typography variant="caption" component="span" fontWeight={600}>
          Navigate
        </Typography>
      </Flex>
      <Flex gap={1} alignItems="center">
        <Flex
          sx={{
            border: (theme) => `1px solid ${theme.palette.text.disabled}`,
            borderRadius: "4px",
            px: 1,
            py: 0.5,
            fontSize: "12px",
            lineHeight: "normal",
          }}
          alignItems="center"
          gap={0.5}
        >
          <SubdirectoryArrowLeft sx={{ fontSize: "11px" }} />
          ENTER
        </Flex>
        <Typography variant="caption" component="span" fontWeight={600}>
          Select
        </Typography>
      </Flex>
      <Flex gap={1} alignItems="center">
        <Flex
          sx={{
            border: (theme) => `1px solid ${theme.palette.text.disabled}`,
            borderRadius: "4px",
            px: 1,
            py: 0.5,
            fontSize: "12px",
            lineHeight: "normal",
          }}
          alignItems="center"
        >
          ESC
        </Flex>
        <Typography variant="caption" component="span" fontWeight={600}>
          Close
        </Typography>
      </Flex>
    </Paper>
  );
};
