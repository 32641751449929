import { AggregateScoreType } from "~/operations";
import { CheckCell, CheckCellProps } from "./CheckCell";
import { VulnerabilityCell, VulnerabilityCellProps } from "./VulnerabilityCell";

export type AllSecurityFindingCellProps = {
  type: AggregateScoreType;
  CheckCellProps: CheckCellProps;
  VulnerabilityCellProps: VulnerabilityCellProps;
};

export function AllSecurityFindingCell({
  type,
  CheckCellProps,
  VulnerabilityCellProps,
}: AllSecurityFindingCellProps) {
  switch (type) {
    case AggregateScoreType.Check:
      return <CheckCell {...CheckCellProps} />;
    case AggregateScoreType.Vulnerability:
      return <VulnerabilityCell {...VulnerabilityCellProps} />;
    default:
      return <></>;
  }
}
