import { FlagOutlined, Radar } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { StatsProps } from "~/components/DetailsPage/components/Stats/Stats";
import { EmptyExceptionsIcon } from "~/components/empty-state/empty-exceptions-icon";
import { ExceptionIcon, HealingIcon } from "~/components/icons";
import { DisableIcon } from "~/components/ui-library";
import { useMetrics } from "~/hooks";
import { getColor } from "~/lib/colors";
import { pluralize } from "~/lib/pluralize";
import {
  AggregateScoreOrderField,
  AggregateScoreType,
  OrderDirection,
  useGetAggregateScoresQuery,
  useLoadSpaceQuery,
} from "~/operations";
import { useFindingRiskFactors } from "~/pages/space/security/components/RiskFactors/hooks/useFindingRiskFactors";
import { Metrics } from "~/utils/arrow";
import { AggregateScoresEdge } from "../types";
import { useReportingPageLoaded } from "../../../hooks/useReportingPageLoaded";

export function useSecurityReport() {
  let theme = useTheme();
  const { searchParams } = new URL(window.location.href);
  //   scope may be either a "space" or "workspace"
  const scopeMrn = searchParams.get("scopeMrn") || "";

  const { data: spaceData, networkStatus: spaceNetworkStatus } =
    useLoadSpaceQuery({
      variables: {
        spaceMrn: scopeMrn,
      },
    });

  const {
    data: aggScoresAssetData,
    networkStatus: aggScoresAssetNetworkStatus,
  } = useGetAggregateScoresQuery({
    variables: {
      entityMrn: scopeMrn || "",
      filter: {
        findingMrn: "//security.api.mondoo.app/findings/all",
        scoreType: AggregateScoreType.Asset,
      },
    },
    skip: !scopeMrn,
  });

  const {
    data: aggScoresSecurityData,
    networkStatus: aggScoresSecurityNetworkStatus,
  } = useGetAggregateScoresQuery({
    variables: {
      entityMrn: scopeMrn || "",
      filter: {
        findingMrn: "//security.api.mondoo.app/findings/security",
      },
    },
    skip: !scopeMrn,
  });

  const {
    data: aggScoresVulnsData,
    networkStatus: aggScoresVulnsNetworkStatus,
  } = useGetAggregateScoresQuery({
    variables: {
      entityMrn: scopeMrn || "",
      filter: {
        findingMrn: null,
        scoreType: AggregateScoreType.Vulnerability,
      },
    },
    skip: !scopeMrn,
  });

  const {
    data: aggScoresChecksData,
    networkStatus: aggScoresChecksNetworkStatus,
  } = useGetAggregateScoresQuery({
    variables: {
      entityMrn: scopeMrn || "",
      filter: {
        findingMrn: null,
        scoreType: AggregateScoreType.Check,
      },
    },
    skip: !scopeMrn,
  });

  const {
    data: aggScoresSoftwareData,
    networkStatus: aggScoresSoftwareNetworkStatus,
  } = useGetAggregateScoresQuery({
    variables: {
      entityMrn: scopeMrn || "",
      orderBy: {
        field: AggregateScoreOrderField.Rank,
        direction: OrderDirection.Asc,
      },
      filter: {
        findingMrn: null,
        scoreType: AggregateScoreType.Software,
      },
    },
    skip: !scopeMrn,
  });

  const {
    riskFactorsWithDocs,
    networkStatuses: riskFactorsNetworkStatuses, // this is an array of network statuses
  } = useFindingRiskFactors({
    spaceMrn: scopeMrn,
    findingMrn: "//security.api.mondoo.app/findings/security",
  });

  const networkStatuses = [
    spaceNetworkStatus,
    aggScoresAssetNetworkStatus,
    aggScoresSecurityNetworkStatus,
    aggScoresVulnsNetworkStatus,
    aggScoresChecksNetworkStatus,
    aggScoresSoftwareNetworkStatus,
    ...riskFactorsNetworkStatuses,
  ];

  // Determine if all network requests are ready
  useReportingPageLoaded(networkStatuses);

  const aggScoresAssets =
    aggScoresAssetData?.aggregateScores?.__typename ===
    "AggregateScoresConnection"
      ? aggScoresAssetData.aggregateScores
      : { totalCount: 0 };

  const aggScoresAssetsEdges =
    aggScoresAssetData?.aggregateScores?.__typename ===
    "AggregateScoresConnection"
      ? aggScoresAssetData.aggregateScores.edges
      : [];

  const aggScoresSecurityNodeData =
    aggScoresSecurityData?.aggregateScores?.__typename ===
    "AggregateScoresConnection"
      ? aggScoresSecurityData.aggregateScores.edges?.at(0)?.node
      : undefined;

  const aggScoresVulns =
    aggScoresVulnsData?.aggregateScores?.__typename ===
    "AggregateScoresConnection"
      ? aggScoresVulnsData.aggregateScores.edges
      : [];

  const aggScoresChecks =
    aggScoresChecksData?.aggregateScores?.__typename ===
    "AggregateScoresConnection"
      ? aggScoresChecksData.aggregateScores.edges
      : [];

  const aggScoresSoftware =
    aggScoresSoftwareData?.aggregateScores?.__typename ===
    "AggregateScoresConnection"
      ? aggScoresSoftwareData.aggregateScores.edges
      : [];

  useEffect(() => {
    console.log(aggScoresSoftwareData);
  }, [aggScoresSoftwareData]);

  //   munged stats data
  const totalScanned = aggScoresAssets.totalCount;
  const priorityFindings = spaceData?.space?.priorityFindings || 0;
  const totalAffected = aggScoresSecurityNodeData?.blastRadius?.affected || 0;
  const totalRemediated =
    aggScoresSecurityNodeData?.blastRadius?.assets &&
    aggScoresSecurityNodeData?.blastRadius?.affected
      ? (
          aggScoresSecurityNodeData?.blastRadius?.assets -
          aggScoresSecurityNodeData.blastRadius?.affected
        ).toString()
      : "---";

  const stats: StatsProps["stats"] = [
    {
      label: "Scanned",
      value: totalScanned < 0 ? "---" : totalScanned.toString(),
      icon: <Radar fontSize="inherit" />,
    },
    {
      label: "Findings",
      value: priorityFindings < 0 ? "---" : priorityFindings.toString(),
      icon: <FlagOutlined fontSize="inherit" />,
    },
    {
      label: "Remediated",
      value: totalRemediated,
      icon: <HealingIcon fontSize="inherit" />,
    },
  ].flatMap((stat) => (stat ? stat : []));

  const groupAggScoreEdgesByRating = (aggScoresAssetsEdges: any) => {
    const ratings = {
      CRITICAL: 0,
      HIGH: 0,
      MEDIUM: 0,
      LOW: 0,
      NONE: 0,
    };

    aggScoresAssetsEdges.forEach((edge: AggregateScoresEdge) => {
      const rating = edge.node?.rating;

      if (rating && ratings.hasOwnProperty(rating)) {
        ratings[rating as keyof typeof ratings]++;
      }
    });

    return Object.entries(ratings).map(([key, value], index) => {
      return {
        id: key,
        value,
        color: getColor(theme, key),
      };
    });
  };

  const assetRatings = groupAggScoreEdgesByRating(aggScoresAssetsEdges);
  const vulnRatings = groupAggScoreEdgesByRating(aggScoresVulns);
  const checkRatings = groupAggScoreEdgesByRating(aggScoresChecks);

  const pageTitle = spaceData?.space?.name
    ? `${spaceData.space.name} - Assets Report`
    : "";

  const groupSummary = [
    {
      name: "Assets",
      description: `The ${spaceData?.space?.name} space contains ${totalScanned} assets. This report lists each asset, its risk level, and its risk factors.`,
      data: assetRatings,
    },
    {
      name: "Vulnerabilities",
      description: `Mondoo found ${aggScoresVulns?.length} vulnerabilities in the Discovery One space, ${priorityFindings} of which had a critical or high risk level.`,
      data: vulnRatings,
    },
    {
      name: "Checks",
      description:
        "Mondoo ran the assets in this space against 12 security policies, each made up of multiple checks. There were 81 check failures, 0 of which had a critical or high risk level. There were 43,897 passing checks.",
      data: checkRatings,
    },
  ];

  return {
    assets: aggScoresAssetsEdges,
    title: pageTitle,
    totalScanned,
    stats,
    groupSummary,
    riskFactorsWithDocs,
    inventoryBreakdown: aggScoresSoftware,
  };
}
