import { Path, useFormContext } from "react-hook-form";
import { CreateWorkspaceFormInput } from "~/pages/space/Workspaces/components/CreateWorkspace/CreateWorkspacePage";
import {
  useGetAnnotationSuggestionsLazyQuery,
  useGetLabelSuggestionsLazyQuery,
  useGetWorkspaceConditionPossibleValuesLazyQuery,
  WorkspaceSelectionConditionIntField,
  WorkspaceSelectionConditionKeyValueField,
  WorkspaceSelectionConditionOperator,
  WorkspaceSelectionConditionRatingField,
  WorkspaceSelectionConditionStringField,
} from "~/operations";
import {
  getIsIntKeySelected,
  getIsKeyValueKeySelected,
  getIsRatingKeySelected,
  getIsStringKeySelected,
  isConditionComplete,
} from "~/pages/space/Workspaces/utils";
import { KeyboardEvent, useEffect, useState } from "react";
import { TextFieldProps } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { debounce } from "lodash";
import { ConditionValuesNameType } from "~/pages/space/Workspaces/components/QueryBuilder/types";

type ConditionKey =
  | ""
  | WorkspaceSelectionConditionStringField
  | WorkspaceSelectionConditionIntField
  | WorkspaceSelectionConditionRatingField
  | WorkspaceSelectionConditionKeyValueField;

type UseConditionRowParams = {
  selectionIndex: number;
  conditionIndex: number;
  isReadonly: boolean;
};

export function useConditionRow({
  selectionIndex,
  conditionIndex,
  isReadonly,
}: UseConditionRowParams) {
  const { watch, setValue, setError, trigger, unregister } =
    useFormContext<CreateWorkspaceFormInput>();

  const [searchParams] = useSearchParams();
  const [isValuesDropdownOpen, setIsValuesDropdownOpen] =
    useState<boolean>(false);
  // In case of condition values autocomplete we will ALWAYS need space mrn, not workspace mrn.
  const spaceId = searchParams.get("spaceId");
  const spaceMrn = spaceId
    ? `//captain.api.mondoo.app/spaces/${spaceId}`
    : undefined;
  const conditions = watch(`selections.${selectionIndex}.conditions`);
  const searchableFields: Array<
    | WorkspaceSelectionConditionStringField
    | WorkspaceSelectionConditionIntField
    | WorkspaceSelectionConditionRatingField
    | WorkspaceSelectionConditionKeyValueField
  > = [
    WorkspaceSelectionConditionStringField.Platform,
    WorkspaceSelectionConditionStringField.Technology,
    WorkspaceSelectionConditionStringField.AssetKind,
    WorkspaceSelectionConditionRatingField.Risk,
    WorkspaceSelectionConditionKeyValueField.Labels,
    WorkspaceSelectionConditionKeyValueField.Annotations,
  ];

  const [
    fetchConditionValues,
    { data: conditionValuesData, loading: isConditionValuesLoading, refetch },
  ] = useGetWorkspaceConditionPossibleValuesLazyQuery();

  const [
    fetchLabels,
    {
      data: labelsData,
      loading: isLabelsSuggestionsLoading,
      refetch: refetchLabels,
    },
  ] = useGetLabelSuggestionsLazyQuery();

  const [
    fetchAnnotation,
    {
      data: annotationsData,
      loading: isAnnotationsSuggestionsLoading,
      refetch: refetchAnnotations,
    },
  ] = useGetAnnotationSuggestionsLazyQuery();

  const conditionValue = watch(
    `selections.${selectionIndex}.conditions.${conditionIndex}`,
  );
  const conditionKey = watch(
    `selections.${selectionIndex}.conditions.${conditionIndex}.formKey`,
  );

  const conditionFormDictionaryKey = watch(
    `selections.${selectionIndex}.conditions.${conditionIndex}.keyValueCondition.values.0.key`,
  );

  const conditionKeyIsSearchable = (key: ConditionKey) => {
    return key && searchableFields.includes(key);
  };

  const fetchMoreValues = (key: ConditionKey, value: string) => {
    if (!conditionKeyIsSearchable(conditionKey)) return;

    switch (conditionKey) {
      case WorkspaceSelectionConditionKeyValueField.Labels:
        refetchLabels({
          scopeMrn: spaceMrn || "",
          key: conditionFormDictionaryKey,
          query: value,
        });
        break;
      case WorkspaceSelectionConditionKeyValueField.Annotations:
        refetchAnnotations({
          scopeMrn: spaceMrn || "",
          key: conditionFormDictionaryKey,
          query: value,
        });
        break;
      default:
        refetch({
          input: {
            scopeMrn: spaceMrn || "",
            ...(value ? { query: value } : {}),
            ...(getIsStringKeySelected(key)
              ? {
                  stringField: key,
                }
              : {}),
            ...(getIsIntKeySelected(key)
              ? {
                  intField: key,
                }
              : {}),
            ...(getIsRatingKeySelected(key)
              ? {
                  ratingField: key,
                }
              : {}),
          },
        });
    }
  };

  useEffect(() => {
    if (!conditionKeyIsSearchable(conditionKey)) return;

    switch (conditionKey) {
      case WorkspaceSelectionConditionKeyValueField.Labels:
        fetchLabels({
          variables: {
            scopeMrn: spaceMrn || "",
            key: conditionFormDictionaryKey,
          },
        });
        return;
      case WorkspaceSelectionConditionKeyValueField.Annotations:
        fetchAnnotation({
          variables: {
            scopeMrn: spaceMrn || "",
            key: conditionFormDictionaryKey,
          },
        });
        return;
      default:
        fetchConditionValues({
          variables: {
            input: {
              scopeMrn: spaceMrn || "",
              ...(getIsStringKeySelected(conditionKey)
                ? {
                    stringField: conditionKey,
                  }
                : {}),
              ...(getIsIntKeySelected(conditionKey)
                ? {
                    intField: conditionKey,
                  }
                : {}),
              ...(getIsRatingKeySelected(conditionKey)
                ? {
                    ratingField: conditionKey,
                  }
                : {}),
            },
          },
        });
    }
  }, [conditionKey, conditionFormDictionaryKey, spaceMrn]);

  const handleKeyChange = (
    name: Path<CreateWorkspaceFormInput>,
    value:
      | WorkspaceSelectionConditionStringField
      | WorkspaceSelectionConditionIntField
      | WorkspaceSelectionConditionRatingField,
  ) => {
    setValue(name, value, { shouldDirty: true, shouldTouch: true });
    trigger(name);
  };

  const conditionKeys = [
    {
      label: "INFRASTRUCTURE",
      values: [
        {
          label: "Technology",
          value: WorkspaceSelectionConditionStringField.Technology,
        },
        {
          label: "Platform",
          value: WorkspaceSelectionConditionStringField.Platform,
        },
        {
          label: "Platform version",
          value: WorkspaceSelectionConditionStringField.PlatformVersion,
        },
        {
          label: "Kind",
          value: WorkspaceSelectionConditionStringField.AssetKind,
        },
      ],
    },
    {
      label: "RISK & FINDINGS",
      values: [
        /*{
          label: "Risk",
          value: WorkspaceSelectionConditionIntField.RiskValue,
        },*/
        {
          label: "Risk rating",
          value: WorkspaceSelectionConditionRatingField.Risk,
        },
      ],
    },
    {
      label: "METADATA",
      values: [
        {
          label: "Name",
          value: WorkspaceSelectionConditionStringField.AssetName,
        },
        {
          label: "Tags/Labels",
          value: WorkspaceSelectionConditionKeyValueField.Labels,
        },
        {
          label: "Annotations",
          value: WorkspaceSelectionConditionKeyValueField.Annotations,
        },
      ],
    },
  ];

  const isKeySelected = !!conditionKey;
  const isStringKeySelected = getIsStringKeySelected(conditionKey);
  const isIntKeySelected = getIsIntKeySelected(conditionKey);
  const isRatingKeySelected = getIsRatingKeySelected(conditionKey);
  const isKeyValueKeySelected = getIsKeyValueKeySelected(conditionKey);

  const getConditionValues = () => {
    if (isKeyValueKeySelected)
      return (conditionValue?.keyValueCondition?.values || []).map((v) =>
        String(v.value),
      );

    if (isStringKeySelected)
      return (conditionValue?.stringCondition?.values || []).map(String);
    if (isIntKeySelected)
      return (conditionValue?.intCondition?.values || []).map(String);
    if (isRatingKeySelected)
      return (conditionValue?.ratingCondition?.values || []).map(String);

    return [];
  };

  const conditionValues = getConditionValues();

  function getConditionValuesFieldName(): ConditionValuesNameType {
    if (isStringKeySelected)
      return `selections.${selectionIndex}.conditions.${conditionIndex}.stringCondition.values`;
    if (isIntKeySelected)
      return `selections.${selectionIndex}.conditions.${conditionIndex}.intCondition.values`;
    if (isRatingKeySelected)
      return `selections.${selectionIndex}.conditions.${conditionIndex}.ratingCondition.values`;

    return `selections.${selectionIndex}.conditions.${conditionIndex}.keyValueCondition.values`;
  }

  const conditionValuesFieldName: ConditionValuesNameType =
    getConditionValuesFieldName();

  const isFirstCondition = conditionIndex === 0;

  const conditionOperator = WorkspaceSelectionConditionOperator.And;

  const getSelectedKeyLabel = () => {
    for (const group of conditionKeys) {
      const targetValue = group.values.find((v) => v.value === conditionKey);
      if (targetValue) {
        return targetValue.label;
      }
    }
    return "";
  };

  const selectedConditionKeyLabel = getSelectedKeyLabel();

  const handleConditionValueRemove = (value: string) => {
    if (isReadonly) return;

    const newValues = conditionValues.includes(value)
      ? conditionValues.filter((v) => v !== value)
      : [...conditionValues, value];

    setValue(conditionValuesFieldName, newValues, {
      shouldDirty: true,
      shouldTouch: true,
    });

    setValue(
      `selections.${selectionIndex}.conditions.${conditionIndex}.keyValueCondition.values`,
      [],
      { shouldDirty: true, shouldTouch: true },
    );

    const invalidConditions = conditions.filter(
      (condition) => !isConditionComplete(condition),
    );

    invalidConditions.forEach((_) => {
      setError(conditionValuesFieldName, {
        message: "This condition is incomplete. Please add a selection.",
      });
    });
  };

  const handleConditionKeyRemove = () => {
    if (isReadonly) return;

    setValue(
      `selections.${selectionIndex}.conditions.${conditionIndex}.formKey`,
      "",
      { shouldDirty: true, shouldTouch: true },
    );
    unregister(
      `selections.${selectionIndex}.conditions.${conditionIndex}.keyValueCondition.values`,
    );
    unregister(
      `selections.${selectionIndex}.conditions.${conditionIndex}.stringCondition.values`,
    );
    unregister(
      `selections.${selectionIndex}.conditions.${conditionIndex}.intCondition.values`,
    );
    unregister(
      `selections.${selectionIndex}.conditions.${conditionIndex}.ratingCondition.values`,
    );

    trigger(conditionValuesFieldName);
  };

  const handleKeyValueKeyClick = (value: string) => {
    setValue(
      conditionValuesFieldName,
      [...(value ? [{ key: String(value), value: "" }] : [])],
      {
        shouldDirty: true,
        shouldTouch: true,
      },
    );
  };

  const handleKeyValueValueClick = (value: string) => {
    setValue(
      conditionValuesFieldName,
      [{ key: conditionFormDictionaryKey, value }],
      {
        shouldDirty: true,
        shouldTouch: true,
      },
    );
    trigger(conditionValuesFieldName);
    setIsValuesDropdownOpen(false);
  };

  const handleValuesItemClick = (value: unknown) => {
    const castedCurrentValues = conditionValues.map((cv) => String(cv));

    const isValueSelected = castedCurrentValues.includes(value as string);
    if (isValueSelected) {
      setValue(
        conditionValuesFieldName,
        castedCurrentValues.filter((cv) => cv !== value),
        { shouldDirty: true, shouldTouch: true },
      );
    } else {
      setValue(
        conditionValuesFieldName,
        [...castedCurrentValues, value as string],
        { shouldDirty: true, shouldTouch: true },
      );
    }
    trigger(conditionValuesFieldName);
  };

  const handleDropdownSearchFieldChange: TextFieldProps["onChange"] = debounce(
    (e) => {
      fetchMoreValues(conditionKey, e.target.value);
    },
    400,
  );

  const handleDropdownSearchFieldKeyUp = (
    e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (e.key === "Enter") {
      if (
        e.target instanceof HTMLInputElement ||
        e.target instanceof HTMLTextAreaElement
      ) {
        if (!isKeyValueKeySelected) {
          handleValuesItemClick(e.target.value);
          e.target.value = "";
        } else {
          if (!conditionFormDictionaryKey) {
            handleKeyValueKeyClick(e.target.value);
            e.target.value = "";
          } else {
            handleKeyValueValueClick(e.target.value);
            e.target.value = "";
          }
          trigger(conditionValuesFieldName);
        }
      }
    }
  };

  const handleValuesDropdownOpen = () => {
    setIsValuesDropdownOpen(true);
  };

  const handleValuesDropdownClose = () => {
    if (!conditionValue?.keyValueCondition?.values?.[0]?.value) {
      setValue(
        `selections.${selectionIndex}.conditions.${conditionIndex}.keyValueCondition.values`,
        [],
        { shouldDirty: true, shouldTouch: true },
      );
    }
    setIsValuesDropdownOpen(false);
  };

  const conditionValuesOptions = conditionKeyIsSearchable(conditionKey)
    ? (
        conditionValuesData?.workspaceConditionPossibleValues.suggestions || []
      ).map((option) => ({
        ...(option.__typename === "WorkspaceConditionStringValueSuggestion"
          ? {
              value: option.stringValue,
              label: option.stringDisplayName,
            }
          : option.__typename === "WorkspaceConditionIntValueSuggestion"
            ? {
                value: String(option.intValue),
                label: option.intDisplayName,
              }
            : {
                value: option.ratingValue,
                label: option.ratingDisplayName,
              }),
      }))
    : [];

  const getConditionValuesOptions = () => {
    switch (conditionKey) {
      case WorkspaceSelectionConditionKeyValueField.Labels:
        return (labelsData?.labelSuggestions || []).map((option) => ({
          value: option,
          label: option,
        }));
      case WorkspaceSelectionConditionKeyValueField.Annotations:
        return (annotationsData?.annotationSuggestions || []).map((option) => ({
          value: option,
          label: option,
        }));
      default:
        return conditionValuesOptions;
    }
  };

  return {
    showIncludeLabel: isFirstCondition,
    showConditionOperator: !isFirstCondition,
    showConditionKeyLabel: isKeySelected,
    isKeySelected,
    conditionKeys,
    conditionValues,
    conditionValuesOptions: getConditionValuesOptions(),
    selectedConditionKeyLabel,
    conditionOperator,
    conditionValuesFieldName,
    conditionFormDictionaryKey,
    handleConditionValueRemove,
    handleConditionKeyRemove,
    handleKeyChange,
    handleValuesItemClick,
    handleKeyValueKeyClick,
    handleKeyValueValueClick,
    handleDropdownSearchFieldKeyUp,
    handleDropdownSearchFieldChange,
    isLoading:
      isLabelsSuggestionsLoading ||
      isAnnotationsSuggestionsLoading ||
      isConditionValuesLoading,
    isValuesDropdownOpen,
    isDictionaryKeySelected: isKeyValueKeySelected,
    handleValuesDropdownOpen,
    handleValuesDropdownClose,
  };
}
