import { Box, TableCell, Typography } from "@mui/material";
import { Clamp } from "../clamp/clamp";
import { FirewatchChip } from "./ChipText";
import { AggregateScoresNode } from "../FirewatchPage";
import { DynamicIcon } from "../icons/DynamicIcon";

type AdvisoryCellProps = {
  row: AggregateScoresNode;
};

export function AdvisoryCell({ row }: AdvisoryCellProps) {
  const { tags } = row || {};

  return (
    <TableCell>
      <Clamp>
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: 14,
            lineHeight: "16px",
            gap: 1,
            fontWeight: 700,
          }}
          data-testid="advisory-id"
        >
          <DynamicIcon iconId={row?.iconId} size="16" />
          {row?.title || row?.nodeTitle}
        </Typography>
      </Clamp>
      {(tags?.length || 0) > 0 && (
        <Box display="flex" gap={1} mt={1}>
          {tags?.map((tag) => <FirewatchChip key={tag.key} tag={tag} />)}
        </Box>
      )}
    </TableCell>
  );
}
