import { TableCell } from "@mui/material";
import { PublishedDate } from "~/pages/inventory/components/PublishedDate";

export const PublishedCell = ({
  date,
  ...props
}: {
  date: string;
  "data-testid"?: string;
  formatting?: "relative" | "absolute";
}) => {
  return (
    <TableCell data-testid={props["data-testid"]} width="160px">
      <PublishedDate dateString={date ?? null} formatting={props.formatting} />
    </TableCell>
  );
};
