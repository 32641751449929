import { useNavigate } from "react-router-dom";
import { Box, Grid, List, ListItem, Paper, Typography } from "@mui/material";
import { useFrameworkContext } from "../framework";
import { FilterBar } from "../../filter-bar";
import { GetContentQuery, useGetContentQuery } from "~/operations";
import { Space } from "~/lib/types";
import { useSearch } from "~/components/search/useSearch";
import { policyIcon } from "~/pages/inventory/utils/policyIcon";
import { PolicyMrnToURIEncodedId } from "~/lib/mrn";
import { PolicyAssignButton } from "~/pages/security/policies/components/PolicyAssignButton";
import { Caption } from "~/pages/security/policies/components/Caption";

export type Policy = NonNullable<
  GetContentQuery["content"]
>["edges"][0]["node"];

export function Policies({}) {
  const { space, framework } = useFrameworkContext();
  const { handleFilterQuery, searchFilters } = useSearch();

  const {
    data: { content } = { content: null },
    error,
    loading,
    refetch,
  } = useGetContentQuery({
    variables: {
      input: {
        scopeMrn: space.mrn,
        contentMrns: framework.policiesMrns,
        query: searchFilters.join(","),
      },
    },
    skip: framework.policiesMrns.length === 0,
  });

  const { edges } = content ?? {};
  const policies = edges?.map((edge) => edge.node) || [];
  const activePolicies = policies.filter((policy) => policy.action);
  const recommendedPolicies = policies.filter((policy) => !policy.assigned);

  const handleAssignChange: PolicyListProps["handleAssignChange"] = () => {
    refetch();
  };

  return (
    <Box>
      <FilterBar
        searchId="compliance-policies"
        placeholder="compliance_policies"
        searchFilters={searchFilters}
        handleFilterQuery={handleFilterQuery}
      />
      <PolicyList
        title="Recommended Policies"
        policies={recommendedPolicies}
        space={space}
        handleAssignChange={handleAssignChange}
      />
      <PolicyList
        title="Enabled Policies"
        policies={activePolicies}
        space={space}
        handleAssignChange={handleAssignChange}
      />
    </Box>
  );
}

type PolicyListProps = {
  title: string;
  policies: Policy[];
  space: Space;
  handleAssignChange: PolicyItemProps["handleAssignChange"];
};

const PolicyList = ({
  title,
  policies,
  space,
  handleAssignChange,
}: PolicyListProps) => {
  if (!policies.length) return <Box />;

  return (
    <Box sx={{ mb: 5 }}>
      <Typography variant="h5" sx={{ mb: 2, fontWeight: 700 }}>
        {title}
      </Typography>
      <List component={Paper} sx={{ py: 0, overflow: "hidden" }}>
        {policies.map((policy) => {
          const policyId = policy.mrn?.split("/").pop();
          return (
            <PolicyItem
              key={policyId}
              policy={policy}
              space={space}
              handleAssignChange={handleAssignChange}
            />
          );
        })}
      </List>
    </Box>
  );
};

type PolicyItemProps = {
  policy: Policy;
  space: Space;
  handleAssignChange: () => void;
};

export const PolicyItem = ({
  policy,
  space,
  handleAssignChange,
}: PolicyItemProps) => {
  const policyId = PolicyMrnToURIEncodedId(policy.mrn);
  const policyLink = `/space/findings/policies/${policyId}?spaceId=${space.id}`;
  const navigate = useNavigate();

  return (
    <ListItem
      sx={{
        p: 3,
        cursor: "pointer",
        "&:not(:last-child)": {
          borderBottom: (theme) =>
            `1px solid ${theme.palette.background.lighter}`,
        },
        "&:hover": {
          backgroundColor: (theme) => theme.palette.background.light,
        },
      }}
      onClick={() => navigate(policyLink)}
    >
      <Grid container spacing={0}>
        <Grid
          item
          xs="auto"
          sx={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "left",
          }}
        >
          {policyIcon(policy.name, "large")}
        </Grid>
        <Grid item xs={10} sm container>
          <Grid item xs={10} sm>
            <Typography variant="h6" sx={{ pl: 3 }}>
              {policy.name}
            </Typography>
          </Grid>
          <Grid item xs={12} container spacing={0}>
            <Caption
              trustLevel={policy.trustLevel}
              authors={policy.authors}
              version={policy.version}
            />
          </Grid>
        </Grid>
        <Grid
          item
          sm={2}
          sx={{
            display: { xs: "none", sm: "flex" },
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <PolicyAssignButton
            spaceMrn={space.mrn}
            policyMrn={policy.mrn}
            assigned={policy.assigned}
            action={policy.action}
            onChange={handleAssignChange}
            advanced
          />
        </Grid>
      </Grid>
    </ListItem>
  );
};
