import { RiskFactorsIndicators } from "~/pages/space/security/components/RiskFactors/types";
import React from "react";
import {
  AccessToKeys,
  AdminUser,
  ContainerEnvironmentIcon,
  DatabaseAccessIcon,
  DefaultRiskAction,
  DefensiveIcon,
  EndOfLife,
  ExploitableIcon,
  HighPriorityIcon,
  IncompletePackagesIcon,
  InternetExposed,
  LowPriorityIcon,
  PortsIcon,
  RemoteExecutionIcon,
  SensitiveDataIcon,
  VulnerableServiceIcon,
  WifiIcon,
} from "~/components/icons";
import { alpha, SxProps, Theme } from "@mui/material";

type GetRiskFactorIconParams = {
  indicator: string | null | undefined;
  sx?: SxProps;
};

export const getRiskFactorIcon = ({
  indicator,
  sx: propsSx = {},
}: GetRiskFactorIconParams) => {
  const sx = {
    cursor: "pointer",
    ...propsSx,
  };

  const riskFactorsIconMap: Record<RiskFactorsIndicators, React.ReactElement> =
    {
      "internet-exposed": <InternetExposed {...{ sx }} />,
      "internet-facing": <InternetExposed {...{ sx }} />,
      "db-access": <DatabaseAccessIcon {...{ sx }} />,
      "user-risk": <AdminUser {...{ sx }} />,
      "asset-in-use": <VulnerableServiceIcon {...{ sx }} />,
      "code-execution": <RemoteExecutionIcon {...{ sx }} />,
      "sensitive-data": <SensitiveDataIcon {...{ sx }} />,
      "patch-available": <WifiIcon {...{ sx }} />,
      credentials: <AccessToKeys {...{ sx }} />,
      containers: <ContainerEnvironmentIcon {...{ sx }} />,
      exploitable: <ExploitableIcon {...{ sx }} />,
      eol: <EndOfLife {...{ sx }} />,
      defensive: <DefensiveIcon {...{ sx }} />,
      package: <IncompletePackagesIcon {...{ sx }} />,
      port: <PortsIcon {...{ sx }} />,
      "high-priority": <HighPriorityIcon {...{ sx }} />,
      "low-priority": <LowPriorityIcon {...{ sx }} />,
      default: <DefaultRiskAction {...{ sx }} />,
    };

  return (
    riskFactorsIconMap[(indicator || "default") as RiskFactorsIndicators] || (
      <DefaultRiskAction {...{ sx }} />
    )
  );
};

// TODO: remove this array when we switch to risk factor explanations
export const positiveRiskFactors = ["defensive", "patch-available"];

export type GetRiskFactorColorsParams = {
  indicator: string;
  theme: Theme;
  light: boolean;
};

export const getRiskFactorColors = ({
  indicator,
  theme,
  light,
}: GetRiskFactorColorsParams) => {
  const positive = positiveRiskFactors.includes(indicator);
  const warnColorDark = theme.palette.warning.dark;
  const warnColorMain = theme.palette.warning.main;
  const warnTextColor = light ? warnColorDark : warnColorMain;
  const warnFillColor = light ? warnColorMain : alpha(warnColorMain, 0.1);
  const goodTextColor = theme.palette.success.main;
  const goodFillColor = alpha(goodTextColor, 0.1);
  const textColor = positive ? goodTextColor : warnTextColor;
  const fillColor = positive ? goodFillColor : warnFillColor;
  return { textColor, fillColor };
};
