import { useSearch } from "~/components/search/useSearch";
import { useSort } from "~/pages/inventory/hooks/useSort";
import {
  FindingsOrder,
  FindingsOrderField,
  FindingType,
  OrderDirection,
  ScoreStateFilter,
  useGetAssetPackagesQuery,
  useGetAssetSupportsVulnerabilitiesQuery,
} from "~/operations";
import { CopyTableToClipboardProps } from "~/components/copy-table-to-clipboard";
import { CvssScoreType, Score2CvssScore } from "~/lib/score";
import { useSoftwareEmptyState } from "~/pages/inventory/components/AssetSoftware/hooks/useSoftwareEmptyState";
import { useFilterPackages } from "~/pages/inventory/components/AssetSoftware/SoftwareTypeGroup/hooks/useFilterPackages";
import { useAssetOutlet } from "~/pages/inventory/asset";

type UseAssetSoftwareParams = {
  assetMrn: string;
  isVulnerabilityScanningEnabled: boolean;
  clipboardDetails: CopyTableToClipboardProps["tableData"]["details"];
};

export function useAssetSoftware({
  assetMrn,
  isVulnerabilityScanningEnabled,
  clipboardDetails,
}: UseAssetSoftwareParams) {
  const { isCicd } = useAssetOutlet();
  const { handleFilterQuery, handleClearSearch, searchFilters } = useSearch();
  const { handleSortClick, orderBy } = useSort<FindingsOrder>({
    defaultSort: {
      field: FindingsOrderField.RiskValue,
      direction: OrderDirection.Desc,
    },
    validFields: [
      FindingsOrderField.RiskValue,
      FindingsOrderField.PackageName,
      FindingsOrderField.RiskFactors,
      FindingsOrderField.FirstFound,
    ],
  });
  const { filter, handleFilter, displayedFilterTypes } = useFilterPackages({
    assetMrn,
  });

  const { loading, data, fetchMore, error } = useGetAssetPackagesQuery({
    variables: {
      scopeMrn: assetMrn,
      filter: {
        queryTerms: searchFilters,
        types: filter ? [filter] : [FindingType.OsPackage, FindingType.Package],
        includeCicd: isCicd,
        state: ScoreStateFilter.Open,
      },
      orderBy,
      first: 10,
    },
    errorPolicy: "all",
  });

  const { data: assetSupportsData } = useGetAssetSupportsVulnerabilitiesQuery({
    variables: {
      assetMrn,
    },
  });

  const packageScores =
    data?.findings?.__typename === "FindingsConnection"
      ? data.findings
      : undefined;

  const { isEmptyState, emptyStateVariant } = useSoftwareEmptyState({
    packagesConnection: packageScores,
    isFilterApplied: Boolean(filter || searchFilters.length > 0),
    isPlatformSupported: Boolean(
      assetSupportsData?.assetSupportVulnerabilities,
    ),
    isVulnerabilityScanningEnabled,
    isNotFound: data?.findings?.__typename === "NotFoundError",
  });

  const tableData: CopyTableToClipboardProps["tableData"] = {
    columns: ["Package Name", "Installed", "Available", "Score"],
    rows: (
      packageScores?.edges?.flatMap((e) =>
        e.node && e.node.__typename === "PackageFinding" ? e.node : [],
      ) || []
    ).map((pkg) => [
      pkg.packageName || "",
      pkg.installedVersion || "",
      pkg.availableVersion || "",
      `${CvssScoreType(pkg.cvss.value).toUpperCase()} (${Score2CvssScore(
        pkg.cvss.value,
      )})`,
    ]),
    details: clipboardDetails,
  };

  const isScanningEmptyState =
    isEmptyState &&
    emptyStateVariant &&
    ["noVulnerablePackages", "notFoundError", "platformNotSupported"].includes(
      emptyStateVariant,
    );

  return {
    isLoading: loading,
    isLoadingFailed: error,
    isScanningEmptyState,
    emptyStateVariant,
    packageScores,
    showCopyToClipboard: packageScores?.totalCount !== 0,
    tableData,
    displayedFilterTypes,
    filter,
    handleFilter,
    searchFilters,
    handleClearSearch,
    handleFilterQuery,
    hasData: (packageScores?.totalCount || 0) > 0,
    handleSortClick,
    orderBy,
    fetchMore,
  };
}
