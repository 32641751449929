import { FirewatchTooltip } from "~/components/FirewatchRow/Tooltip";
import { BlastRadius } from "~/components/blast/blast-radius";
import { BlastRadiusFieldsFragment, ScoreRating } from "~/operations";
import {
  adjustImpactColor,
  useGetImpactColorUpdated,
} from "~/components/impact/Updated/impact-result-updated";

type CheckScoreBlastRadiusProps = {
  blastRadius: BlastRadiusFieldsFragment | null | undefined;
  isActive?: boolean;
  rating: ScoreRating;
};

export const ScoreBlastRadius = ({
  blastRadius,
  isActive = true,
  rating,
}: CheckScoreBlastRadiusProps) => {
  const { __typename, indicator, assets, affected, ...ratings } =
    blastRadius || {};

  // color should be same as impact
  const color = useGetImpactColorUpdated(rating);

  return (
    <BlastRadius
      radius={indicator || "none"}
      tooltip={<FirewatchTooltip data={ratings} />}
      color={adjustImpactColor({ color, isInactive: !isActive })}
    />
  );
};
