import { Clamp } from "~/components/clamp/clamp";
import { Box, TableCell, Typography } from "@mui/material";
import { FirewatchChip } from "~/components/FirewatchRow/ChipText";
import { Tag } from "~/operations";
import { Format } from "~/lib/date";

type CaseTitleCellProps = {
  title: string;
  createdAt: string | undefined;
  assets: number | undefined;
  isOpen?: boolean;
  "data-testid"?: string;
  tags: Array<Tag>;
};

export const CaseTitleCell = ({
  createdAt,
  title,
  assets,
  tags,
  ...props
}: CaseTitleCellProps) => {
  const tagsWithCreated: Tag[] = [
    {
      __typename: "Tag",
      key: "Created",
      value: `${Format(createdAt)}`,
    },
    ...tags,
  ];

  return (
    <TableCell data-testid={props["data-testid"]}>
      <Clamp>
        <Typography>{title}</Typography>
      </Clamp>
      <Box display="flex" gap={1} mt={1} flexWrap="wrap">
        {tagsWithCreated?.map((tag) => (
          <FirewatchChip key={tag.key} tag={tag} />
        ))}
      </Box>
    </TableCell>
  );
};
