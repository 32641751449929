import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { parseJwt } from "~/lib/parseJwt";
import { useAuth } from "~/login/provider";
import { useViewer } from "~/providers/viewer";
import { DeveloperInfo } from "~/components/developer-info";
import { AppNavToolbar } from "~/components/app-nav-toolbar";
import { LoadingPage } from "~/components/loading";
import { ErrorBoundary } from "~/components/error-boundary";
import { WaitingList } from "./waitingList";
import { SetupRegion } from "./setup-region";
import { MondooLogoHorizontal } from "~/images/icons";
import { Config } from "~/configuration_provider";
import { AppNavDrawer } from "~/components/app-nav-drawer";
import { useAppNavDrawer } from "~/providers/app-nav-drawer";
import { IncidentInfo } from "~/components/incident-info";

type SetupState = "not set" | "waitlisted" | "setup-region" | "complete";

export function Root() {
  const auth = useAuth();
  const { viewer, viewerSettings, refetch } = useViewer();
  const isPrivateInstance = Config.VITE_PRIVATE_INSTANCE === "true";
  const [setupState, setSetupState] = useState<SetupState>(
    isPrivateInstance ? "complete" : "not set",
  );
  const [authProviderName] = useState<string>(Config.VITE_AUTH_PROVIDER);
  const location = useLocation();
  const { navbarHeight } = useAppNavDrawer();

  useEffect(() => {
    if (setupState === "not set" || setupState === "setup-region") {
      completeSetup();
    }
  }, [auth.state, location.pathname, viewer, setupState]);

  const reloadAndSetup = async () => {
    await refetch();
    setSetupState("complete");
  };

  // Recursive function that checks if the users account is setup and/or approved
  const completeSetup = async (timer = 0) => {
    if (!auth.state.user) return;

    // If using Single Sign On, let the user in
    if (authProviderName === "firebase") {
      const idToken = await auth.state.user.getIdToken();
      let token = parseJwt(idToken);
      let signinprovider = token?.firebase?.sign_in_provider;
      if (signinprovider.startsWith("saml")) {
        return reloadAndSetup();
      }
    }

    // If the timer has reached 15 seconds, something has gone wrong, we let
    // user in as a fallback.
    if (timer >= 15) {
      return reloadAndSetup();
    }
    timer += 3;
    const lastSpaceId = viewerSettings?.last_space_id;

    if (lastSpaceId && viewer?.state === "ENABLED") {
      // if Enabled, let the user in
      return reloadAndSetup();
    } else if (lastSpaceId && viewer?.state === "WAITLISTED") {
      // If Waitlisted, we show a waitlisting screen
      return setSetupState("waitlisted");
    } else {
      if (viewer?.state === "UNKNOWN") {
        return setSetupState("setup-region");
      }

      // If the user has no state or lastSpaceId yet, we try again after 3 seconds
      setTimeout(() => completeSetup(timer), 3000);
    }
  };

  if (setupState === "not set" && !(authProviderName === "dev")) {
    return <LoadingPage />;
  }

  let mainContent = <Outlet />;
  if (setupState === "waitlisted") {
    mainContent = <WaitingList />;
  } else if (setupState === "setup-region") {
    mainContent = <SetupRegion />;
  }

  return (
    <ErrorBoundary key="root">
      <Box component="header">
        <AppNavToolbar />
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: `calc(100% - ${navbarHeight}px)`,
        }}
      >
        <AppNavDrawer />
        <Box
          component="main"
          role="main"
          width="100%"
          sx={{
            display: "flex",
            flexDirection: "column",
            minWidth: 0,
            minHeight: `calc(100vh - ${navbarHeight}px)`,
          }}
        >
          <IncidentInfo />
          <Box>{mainContent}</Box>
          <Box component="footer" mt={"auto"} pt={3} zIndex={1}>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              height={100}
              width={1}
            >
              <MondooLogoHorizontal />
            </Grid>
          </Box>
        </Box>
      </Box>
      <DeveloperInfo />
    </ErrorBoundary>
  );
}
