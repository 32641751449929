import { alpha, Box, Grid, List, ListItem, Typography } from "@mui/material";
import { useRef } from "react";
import { DonutChart, DonutChartProps } from "~/components/SmallDonut/Chart";
import { useDimensions } from "~/components/SmallDonut/useDimensions";
import { HubCard } from "~/components/hub-card";
import { getColor } from "~/lib/colors";
import { numberKFormat } from "~/lib/number-k-formatting";

export type SmallDonutData = {
  context: Array<{
    value: number;
    rating: string;
  }>;
  total: number;
};

export type SmallDonutCardProps = {
  /**
   * data that is required for the donut chart
   */
  data: SmallDonutData;
  /**
   * title of the card
   */
  title: string;
  /**
   * if provided, the card will display an icon to the right of the title
   */
  icon?: JSX.Element;
  /**
   * if `true`, the card will display a loading spinner instead of content
   */
  loading?: boolean;
  /**
   * if a `to` prop is provided, the card will be wrapped in a `Link` component
   */
  to?: string;
  "data-testid"?: string;

  onDatumClick?: DonutChartProps["onDatumClick"];

  selected?: string[];
};

export function SmallDonutCard({
  data,
  title,
  icon,
  loading,
  to,
  onDatumClick,
  selected = [],
  ...rest
}: SmallDonutCardProps) {
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const { width, height } = useDimensions(chartContainerRef);

  return (
    <HubCard
      loading={loading ? loading : false}
      {...(to && { to })}
      title={title}
      icon={icon}
      PaperProps={{
        minHeight: 208,
        pb: 1,
      }}
      data-testid={rest["data-testid"]}
    >
      {data && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: 1,
          }}
        >
          <Grid container sx={{ width: 1, height: 1 }}>
            <Grid
              item
              xs={3.5}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  textAlign: "center",
                  color: data.total > 0 ? "text.primary" : "text.disabled",
                }}
              >
                <Typography
                  sx={{
                    fontSize: (theme) => theme.spacing(5),
                    fontWeight: 800,
                    lineHeight: "normal",
                  }}
                >
                  {numberKFormat.format(data.total || 0)}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    textTransform: "uppercase",
                    color: "text.secondary",
                  }}
                >
                  Total
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              ref={chartContainerRef}
              xs="auto"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <DonutChart
                width={width || 122}
                height={height}
                data={data}
                onDatumClick={onDatumClick}
                selected={selected}
              />
            </Grid>
            <Grid item xs>
              <List sx={{ pl: 2 }}>
                {data.context.map((d, i) => {
                  const hasSelection = selected.length > 0;
                  const hasValue = d.value > 0;
                  const isSelected = selected.includes(d.rating);
                  const isActive =
                    (hasSelection && isSelected) || (!hasSelection && hasValue);
                  const isClickable = Boolean(onDatumClick) && hasValue;
                  return (
                    <ListItem
                      key={i}
                      disablePadding
                      sx={{
                        py: 0.25,
                        cursor: isClickable ? "pointer" : undefined,
                      }}
                      onClick={
                        isClickable ? () => onDatumClick?.(d) : undefined
                      }
                    >
                      <Box
                        sx={{
                          width: 16,
                          height: 16,
                          mr: 1,
                          background: (theme) =>
                            isActive
                              ? getColor(theme, d.rating)
                              : alpha(theme.palette.text.primary, 0.3),
                          borderRadius: "50%",
                        }}
                      />
                      <Typography
                        variant="caption"
                        sx={{
                          color: (theme) =>
                            isActive
                              ? "inherit"
                              : alpha(theme.palette.text.primary, 0.3),
                          fontWeight: 700,
                          textTransform: "uppercase",
                        }}
                      >
                        {d.rating}
                        <Typography
                          variant="caption"
                          sx={{ fontWeight: 400, textTransform: "uppercase" }}
                          data-testid={`${d.rating.toLowerCase()}-value`}
                        >
                          {" "}
                          {d.value}
                        </Typography>
                      </Typography>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          </Grid>
        </Box>
      )}
    </HubCard>
  );
}
