import { EpssScore } from "~/operations";
import { ordinalize } from "~/lib/ordinalize";

export function formatEPSS(epssScore: EpssScore) {
  return `
    ${(epssScore.probability * 100).toFixed(
      epssScore.probability % 1 === 0 ? 0 : 2,
    )}% (${ordinalize(Math.round(epssScore.percentile * 100))})`;
}

export function formatEPSSnoRank(epssScore: EpssScore) {
  return `
    ${(epssScore.probability * 100).toFixed(
      epssScore.probability % 1 === 0 ? 0 : 2,
    )}%`;
}
