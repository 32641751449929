import { useEffect, useState } from "react";
import {
  Grid,
  styled,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Breadcrumbs,
  Link,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate, useParams, Link as RouterLink } from "react-router-dom";
import {
  AgentState,
  LoadAgentQuery,
  useDeleteAgentsMutation,
  useLoadAgentQuery,
} from "~/operations";
import { Space } from "~/lib/types";
import { DeleteConfirmDialog } from "~/components/delete-confirm-dialog";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import { HomeIcon } from "~/components/icons";
import { IamActions } from "~/lib/iam";
import { FormatRelativeDate, isBefore, sub } from "~/lib/date";
import { Card, CardHeader, CardDetailsGrid } from "~/components/card";
import { DeleteIcon } from "~/components/actions-row";
import { AgentStatusTag, StatusTag } from "~/components/score-card";
import { Small, B, Right, VerticalSpacer } from "~/components/typography";
import { platformTitle } from "~/lib/platform";
import { CardViewLabels } from "~/components/label";
import { Action, ActionMenu } from "~/components/action-menu";
import { setDocumentTitle } from "~/utils/commonUtils";

type Agent = NonNullable<LoadAgentQuery["agent"]>;

export type AgentPageProps = {
  space: Space;
  availablePermissions: string[];
};

type AgentPageState = {
  openEditAgentDialog: boolean;
  openDeleteConfirmDialog: boolean;
  isDeleting: boolean;
  latestAgentRelease: string;
};

export function AgentPage({ space, availablePermissions }: AgentPageProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { integrationId } = useParams();
  const [state, setState] = useState<AgentPageState>({
    openEditAgentDialog: false,
    openDeleteConfirmDialog: false,
    isDeleting: false,
    latestAgentRelease: "0.0.0",
  });

  const mergeState = (params: Partial<AgentPageState>) => {
    return setState((prevState) => ({
      ...prevState,
      ...params,
    }));
  };

  const fetchLatestRelease = async (): Promise<string> => {
    // TODO: should this move someplace more central?
    return fetch("https://releases.mondoo.com/mondoo/latest.json")
      .then((res) => res.json())
      .then((releases) => {
        return releases?.version || "0.0.0";
      });
  };

  useEffect(() => {
    (async () => {
      const latestAgentRelease = await fetchLatestRelease();
      mergeState({ latestAgentRelease });
    })();
  }, []);

  const agentMrn = `//agents.api.mondoo.app/spaces/${space.id}/agents/${integrationId}`;

  const [deleteAgents] = useDeleteAgentsMutation();

  const { loading, error, data } = useLoadAgentQuery({
    variables: { mrn: agentMrn },
    notifyOnNetworkStatusChange: true,
  });

  if (loading) return <LoadingPage what="Client" />;

  if (error || !data?.agent) return <LoadingFailedPage what="Client" />;

  const agent = data.agent;

  const handleDeleteAgent = () => {
    mergeState({ openDeleteConfirmDialog: true });
  };

  const handleDeleteAgentConfirm = () => {
    mergeState({ isDeleting: true });

    const agentMrns = [agent].map((x) => x.mrn);
    deleteAgents({
      variables: {
        input: { spaceMrn: space.mrn, agentMrns: agentMrns },
      },
      update(cache) {
        mergeState({ isDeleting: false });
        enqueueSnackbar("Successfully deleted client", {
          variant: "success",
        });
        cache.evict({ id: "ROOT_QUERY", fieldName: "agents" });
        navigate(`/space/integrations/managed?spaceId=${space?.id}`);
      },
    }).catch((error) => {
      mergeState({ isDeleting: false });
      enqueueSnackbar("Failed to delete client", {
        variant: "error",
      });
    });
  };

  const handleDeleteAgentClose = () => {
    mergeState({ openDeleteConfirmDialog: false });
  };

  const agentName = agent?.hostname || agent?.name || "Agent";

  const hasDeleteAgentsPermission = availablePermissions.includes(
    IamActions.AGENTMANAGER_DELETEAGENTS,
  );

  // Action are a list of things a user can accomplish though a dropdown menu
  const actions: Action[] = [
    hasDeleteAgentsPermission
      ? {
          label: "Delete",
          action: handleDeleteAgent,
          icon: <DeleteIcon />,
        }
      : undefined,
  ].flatMap((a) => a ?? []);

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?spaceId=${space.id}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Link
      key="/space/integrations"
      component={RouterLink}
      to={`/space/integrations?spaceId=${space.id}`}
    >
      Integrations
    </Link>,
    <Link
      key="/space/integrations/managed"
      component={RouterLink}
      to={`/space/integrations/managed?spaceId=${space.id}`}
    >
      Managed Clients
    </Link>,
    <Typography key={1} sx={{ textTransform: "capitalize" }}>
      {agentName}
    </Typography>,
  ];

  setDocumentTitle([agentName, "Managed Clients", "Integrations"]);

  return (
    <>
      <DeleteConfirmDialog
        isDeleting={state.isDeleting}
        open={state.openDeleteConfirmDialog}
        items={[agent]}
        onConfirm={handleDeleteAgentConfirm}
        onClose={handleDeleteAgentClose}
        itemName={(item: Agent): string => item.hostname}
        itemType="agent"
      />
      <Breadcrumbs sx={{ mb: 3 }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          {AgentCard(agent, actions, state.latestAgentRelease)}
        </Grid>
        {hasAgentErrors(agent) && (
          <Grid item xs={12} sm={12}>
            {AgentErrorsCard(agent)}
          </Grid>
        )}
      </Grid>
    </>
  );
}

export const DateString = (timestamp: string) => {
  if (
    timestamp == null ||
    timestamp == "" ||
    timestamp === "0001-01-01T00:00:00Z"
  ) {
    return "Never";
  }
  return FormatRelativeDate(timestamp);
};

const createdWithin24h = (timestamp: string) => {
  if (timestamp == null || timestamp == "") {
    return false;
  }

  return isBefore(sub(new Date(), { days: 1 }), new Date(timestamp));
};

const FloatRight = styled("div")`
  float: right;
`;

const AgentCard = (
  item: Agent,
  actions: Action[],
  latestAgentRelease: string,
) => {
  const id = item.id;
  const lastCheckin = item.status?.lastCheckin
    ? DateString(item.status.lastCheckin)
    : "";

  const statusState = item.status?.state || AgentState.Missing;
  const statusVersion = item.status?.version || "";

  // check if the credentials have been used (missing and no checkedin) and are not created within the last 24h
  const considerDeletion =
    lastCheckin === "" &&
    statusState === AgentState.Missing &&
    !createdWithin24h(item.createdAt);

  const { hostname, ipaddress } = item;
  const subtitle = `${hostname} / ${ipaddress}`;

  const outdated =
    statusVersion !== latestAgentRelease && statusVersion !== "unstable";

  return (
    <Card style={{ width: "100%", minHeight: "345px", cursor: "default" }}>
      <CardHeader style={{ minHeight: "100px" }}>
        <FloatRight>
          <ActionMenu id={`${item.id}-menu`} actions={actions} />
        </FloatRight>
        {item.name}
        <br />
        <Small>{subtitle}</Small>
        <VerticalSpacer space="0.7em" />
        {item.status?.state && <AgentStatusTag status={item.status.state} />}
        {considerDeletion && (
          <>
            <StatusTag status="critical" text="unused" displaydot />
          </>
        )}
        {outdated && item.status?.state === "ACTIVE" && (
          <StatusTag status="critical" text="update required" displaydot />
        )}
        {!outdated && <StatusTag status="good" text="up-to-date" displaydot />}
      </CardHeader>
      <CardDetailsGrid>
        <span>ID</span>
        <Right>
          <B>{id}</B>
        </Right>
        <span>Last Check-in</span>
        <Right>
          <B>{lastCheckin}</B>
        </Right>
        <span>Platform</span>
        <Right>
          <B>{platformTitle(item.platform)}</B>
        </Right>
        <span>Hostname</span>
        <Right>
          <B>{item.hostname}</B>
        </Right>
        <span>IP Address</span>
        <Right>
          <B>{item.ipaddress}</B>
        </Right>
        <span>Client Version</span>
        <Right>
          <B>{item.status?.version}</B>
        </Right>
        <CardViewLabels labels={item.labels} />
      </CardDetailsGrid>
    </Card>
  );
};

const AgentErrorsCard = (item: Agent) => {
  return (
    <Card style={{ width: "100%", cursor: "default" }}>
      <CardHeader>Agent Errors</CardHeader>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Message</TableCell>
            <TableCell align="right">Timestamp</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {item.errors?.map((row) => (
            <TableRow>
              <TableCell component="th" scope="row">
                {row.message}
              </TableCell>
              <TableCell align="right">{row.timestamp}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};

const hasAgentErrors = (item: Agent) => {
  return item.errors && item.errors.length > 0;
};
