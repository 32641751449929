import { Grid, Typography } from "@mui/material";
import {
  FindingsOrderField,
  FindingType,
  OrderDirection,
  ScoreStateFilter,
  useGetChecksQuery,
} from "~/operations";
import {
  CheckFindingsCheckNode,
  ListPolicyEdge,
} from "~/pages/inventory/asset/types";
import { FailedCheck } from "./FailedCheck";
import { Loading } from "../loading";

type FailedChecksProps = { listPolicyEdge: ListPolicyEdge; assetMrn: string };

export function FailedChecks({ assetMrn, listPolicyEdge }: FailedChecksProps) {
  const { data, loading } = useGetChecksQuery({
    variables: {
      scopeMrn: assetMrn,
      first: 100,
      after: null,
      orderBy: {
        field: FindingsOrderField.RiskValue,
        direction: OrderDirection.Desc,
      },
      filter: {
        policyMrn: listPolicyEdge.node.mrn,
        state: ScoreStateFilter.Open,
        types: [FindingType.Check],
      },
    },
  });

  if (loading || !data) {
    return (
      <Grid
        item
        xs={12}
        sx={{
          py: 4,
          borderBottom: "1px solid #DBDADD",
        }}
      >
        <Loading what="failed checks" />
      </Grid>
    );
  }

  const findingsUnion =
    data?.findings?.__typename === "FindingsConnection"
      ? data.findings
      : undefined;
  const checkEdges = findingsUnion?.edges || [];
  const checks: CheckFindingsCheckNode[] = checkEdges.flatMap((e) =>
    e?.node?.__typename === "CheckFinding" ? e.node : [],
  );

  return (
    <Grid
      container
      item
      xs={12}
      sx={{
        py: 4,
        borderBottom: "1px solid #DBDADD",
      }}
    >
      <Typography variant="h5" component="h4" sx={{ px: 4 }}>
        Failed Checks
      </Typography>
      <Grid
        container
        sx={{
          "& .failed-check": {
            borderBottom: "1px solid #DBDADD",
          },
          "& .failed-check:last-child": {
            borderBottom: "none",
          },
        }}
      >
        {checks?.map((edge) => {
          const check = edge;
          return (
            <FailedCheck key={check?.mrn} assetMrn={assetMrn} check={check} />
          );
        })}
      </Grid>
    </Grid>
  );
}
