import { RiskFactorStats } from "~/operations";
import { Chip } from "../vulnerabilities/chip";
import {
  getRiskFactorColors,
  getRiskFactorIcon,
} from "~/pages/space/security/components/RiskFactors/utils";
import { useTheme } from "@mui/material";
import { useColorMode } from "~/providers/color-mode";

export type RiskFActorsChipProps = {
  riskFactor: RiskFactorStats["indicator"]; // risk factors indicators?  or maybe whole risk factor
};

export function RiskFactorsChip({ riskFactor }: RiskFActorsChipProps) {
  const theme = useTheme();
  const { mode } = useColorMode();
  const icon = getRiskFactorIcon({ indicator: riskFactor });
  const { textColor, fillColor } = getRiskFactorColors({
    indicator: riskFactor,
    theme,
    light: mode === "light",
  });
  const label = riskFactor.replace(/-/g, " ");

  return (
    <Chip
      label={label}
      variant="filled"
      icon={icon}
      sx={{
        textTransform: label === "eol" ? "uppercase" : "capitalize",
        backgroundColor: fillColor,
        color: textColor,
        ".MuiChip-icon": {
          color: textColor,
        },
      }}
    />
  );
}
