import { Fragment } from "react/jsx-runtime";
import { Grid2, TableRow } from "@mui/material";

import { GenericTable } from "~/components/GenericTable/GenericTable";
import {
  ImpactCell,
  PublishedCell,
  RiskFactorsCell,
  TitleCell,
} from "~/components/Cells";
import { AggregateScoreEdge, Asset, ScoreRating } from "~/operations";
import { DynamicIcon } from "~/components/icons/DynamicIcon";
import { SectionHeading } from "~/pages/reporting/components";
import { NoItemsSection } from "../../components";
import { AggregateScoresEdges } from "./types";

type AssetsProps = {
  assets: AggregateScoresEdges;
};

export function Assets({ assets }: AssetsProps) {
  const totalAssets = assets?.length || 0;
  return (
    <Fragment>
      <SectionHeading
        heading="Assets"
        dividerContent={`${totalAssets} Total`}
      />
      <Grid2 size={12} sx={{ my: 3 }}>
        {!assets ? (
          <NoItemsSection target="Assets" />
        ) : (
          <GenericTable
            items={0}
            tableName="SECURITY_REPORT_ASSETS"
            validSortFields={[]}
            printView
          >
            {assets?.map((asset) => {
              const {
                id,
                riskValue,
                rating,
                title,
                iconId,
                tags,
                riskFactors,
                lastScannedAt,
              } = asset.node || {};
              return (
                <TableRow key={id}>
                  <ImpactCell
                    impact={riskValue || 0}
                    rating={rating || ScoreRating.Critical}
                    isActive
                    hideTooltip
                  />
                  <TitleCell
                    title={title || ""}
                    icon={<DynamicIcon iconId={iconId} />}
                    tags={tags}
                    options={{ fontSize: 14 }}
                  />
                  <RiskFactorsCell riskFactors={riskFactors} />
                  <PublishedCell date={lastScannedAt || "Never"} />
                </TableRow>
              );
            })}
          </GenericTable>
        )}
      </Grid2>
    </Fragment>
  );
}
