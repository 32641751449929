import React from "react";
import { Box } from "@mui/material";
import { RemediationScriptFieldsFragment } from "~/operations";
import { RemediationTabContent } from "~/components/DetailsPage/components/Remediation/RemediationTabContent";
import { RemediationTabs } from "~/components/DetailsPage/components/Remediation/RemediationTabs";
import { useRemediationScripts } from "~/components/DetailsPage/components/Remediation/hooks/useRemediationScripts";
import { RemediationScriptsSkeleton } from "~/components/DetailsPage/components/Remediation/RemediationFilter/RemediationScriptsSkeleton";

type SoftwareRemediationProps = {
  scripts: Array<RemediationScriptFieldsFragment>;
  loading: boolean;
};

export const RemediationScripts = ({
  scripts,
  loading,
}: SoftwareRemediationProps) => {
  const { scriptTab, handleChangeScriptTab } = useRemediationScripts();

  return (
    <>
      <Box sx={{ width: "100%" }} data-testid="remediation-scripts">
        {!loading && (
          <RemediationTabs
            onScriptTabChange={handleChangeScriptTab}
            scriptTab={scriptTab}
            scripts={scripts}
          />
        )}
        {loading ? (
          <Box mt={5}>
            <RemediationScriptsSkeleton />
          </Box>
        ) : (
          <>
            {scripts.map((script, index) => (
              <RemediationTabContent
                value={scriptTab}
                index={index}
                key={index}
                script={script}
              />
            ))}
          </>
        )}
      </Box>
    </>
  );
};
