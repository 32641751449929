import { NavDrawerPolicyIcon } from "~/components/icons";
import { useScope } from "~/hooks/useScope";
import { useMetrics } from "~/hooks";
import { Metrics } from "~/utils/arrow";
import { SmallDonutCard } from "~/components/SmallDonut";

export type PolicyOverviewCardProps = {};

export function PolicyOverviewCard({}: PolicyOverviewCardProps) {
  const { activeScope } = useScope();

  const { policyRiskRatings, isLoading } = useMetrics({
    entityMrn: activeScope?.mrn || "",
    metricMrns: [Metrics.PolicyRiskRatingDistribution],
  });

  return (
    <SmallDonutCard
      to={`/space/findings/policies?${activeScope?.params?.toString()}`}
      data-testid="policy-risk-ratings-donut-chart"
      loading={isLoading}
      data={policyRiskRatings}
      title="Policy Overview"
      icon={<NavDrawerPolicyIcon />}
    />
  );
}
