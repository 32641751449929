import { Box, Button, ButtonProps, Typography } from "@mui/material";
import { ExpandLessIcon, ExpandMoreIcon } from "../icons";
import { Fragment, ReactNode } from "react";
import { RiskFactorStats } from "~/operations";
import { useRiskFactorsContentStatus } from "./useRiskFactorsContentStatus";

export type RiskFactorsTitle = {
  id?: string;
  open: boolean;
  onClick: ButtonProps["onClick"];
  riskFactors?:
    | (
        | (RiskFactorStats & {
            description: { active: string; inactive: string };
          })
        | null
      )[]
    | null
    | undefined;
  showSubtitle?: boolean;
  hideValues?: boolean;
  suffix?: ReactNode;
};

export function RiskFactorsTitle({
  id,
  open,
  onClick,
  riskFactors,
  showSubtitle,
  suffix,
  hideValues,
}: RiskFactorsTitle) {
  const { createRiskFactorSectionTitle } = useRiskFactorsContentStatus();
  const title = createRiskFactorSectionTitle(riskFactors, { hideValues });
  const subtitleNode = showSubtitle ? (
    <Typography
      textTransform="uppercase"
      color="text.secondary"
      fontSize="12px"
    >
      {title}
    </Typography>
  ) : (
    <Fragment />
  );

  return (
    <Box id={id} display="flex" alignItems="flex-start">
      <Box>
        <Button
          className="toggle"
          variant="text"
          endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          onClick={onClick}
          sx={{
            textAlign: "left",
            ml: -0.5,
            p: 0.5,
            ".MuiButton-endIcon": { color: "primary.main" },
          }}
        >
          <Typography textTransform="uppercase" fontWeight={700}>
            Risk factors
          </Typography>
        </Button>

        {subtitleNode && <Box className="subtitle">{subtitleNode}</Box>}
      </Box>
      {suffix && (
        <Box className="suffix" ml="auto">
          {suffix}
        </Box>
      )}
    </Box>
  );
}
