import SvgIcon from "@mui/material/SvgIcon";
import { useTheme } from "@mui/material";

export const WorkloadIdentityBindingsEmptyStateStepOne = ({ ...props }) => {
  const theme = useTheme();

  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 208 144" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M64 64C64 61.7909 65.7909 60 68 60H140C142.209 60 144 61.7909 144 64V136C144 138.209 142.209 140 140 140H68C65.7909 140 64 138.209 64 136V64Z"
          fill={theme.palette.background.light}
        />
        <path
          d="M64 64C64 61.7909 65.7909 60 68 60H140C142.209 60 144 61.7909 144 64V136C144 138.209 142.209 140 140 140H68C65.7909 140 64 138.209 64 136V64Z"
          stroke={theme.palette.background.lightest}
          stroke-width="2"
        />
        <path
          d="M127 89.7817C126.482 88.7196 125.89 87.6915 125.195 86.7246L117.808 89.4141C118.666 90.2924 119.387 91.2797 119.98 92.335L127 89.7817ZM94.3585 97.9523L86.9709 100.642C87.0663 101.826 87.2705 102.998 87.5565 104.141L94.5763 101.588C94.3448 100.397 94.2631 99.1778 94.3585 97.9523Z"
          fill="#C22133"
        />
        <path
          d="M110.747 87.6917C112.286 88.4066 113.614 89.3871 114.737 90.531L122.125 87.8415C120.082 84.9682 117.29 82.5715 113.879 80.9783C103.319 76.0555 90.7294 80.6378 85.8067 91.1982C84.2134 94.6162 83.6211 98.2453 83.8934 101.759L91.2809 99.0692C91.4035 97.4691 91.7916 95.8622 92.5065 94.3303C95.7066 87.4738 103.891 84.4984 110.747 87.6917Z"
          fill="#DB212E"
        />
        <path
          d="M118.625 101.187C118.51 102.787 118.101 104.387 117.386 105.925C114.186 112.782 106.009 115.764 99.1524 112.564C97.6136 111.849 96.2723 110.875 95.1557 109.725L87.7886 112.407C89.8312 115.281 92.6092 117.677 96.0272 119.271C106.588 124.193 119.177 119.611 124.1 109.051C125.693 105.639 126.285 102.01 125.999 98.4971L118.625 101.187Z"
          fill="#DB212E"
        />
        <path
          d="M120.443 92.1719L113.423 94.7252C114.724 97.0606 115.343 99.75 115.139 102.453L122.506 99.7705C122.302 97.1287 121.594 94.5481 120.443 92.1719ZM88.013 103.971L81 106.525C81.6468 109.085 82.7635 111.516 84.3022 113.681L91.6693 110.998C89.7765 109.058 88.5169 106.6 88.013 103.971Z"
          fill="#EB2126"
        />
        <path
          d="M125.931 87.8413C125.699 87.4668 125.454 87.0923 125.202 86.7314L117.815 89.4209C118.141 89.7545 118.441 90.1086 118.727 90.4695L125.931 87.8413ZM94.3244 99.4366C94.304 98.9464 94.3176 98.4493 94.3516 97.9523L86.9641 100.642C87.005 101.112 87.0594 101.581 87.1343 102.051L94.3244 99.4366Z"
          fill="#AD213B"
        />
        <path
          d="M125.993 98.5049L118.625 101.188C118.551 102.25 118.339 103.312 118.006 104.36L126.027 101.433C126.081 100.452 126.074 99.4785 125.993 98.5049ZM87.7817 112.415C88.3469 113.212 88.9801 113.974 89.661 114.696L97.6817 111.768C96.7421 111.183 95.8978 110.495 95.1488 109.733L87.7817 112.415Z"
          fill="#BA2133"
        />
        <path
          d="M117 6C117 3.79086 118.791 2 121 2H193C195.209 2 197 3.79086 197 6V78C197 80.2091 195.209 82 193 82H121C118.791 82 117 80.2091 117 78V6Z"
          fill={theme.palette.background.light}
        />
        <path
          d="M117 6C117 3.79086 118.791 2 121 2H193C195.209 2 197 3.79086 197 6V78C197 80.2091 195.209 82 193 82H121C118.791 82 117 80.2091 117 78V6Z"
          stroke={theme.palette.background.lightest}
          stroke-width="2"
        />
        <g clip-path="url(#clip0_2647_37678)">
          <path
            d="M157 22.4954C145.951 22.4954 137 31.4541 137 42.4954C137 51.3366 142.73 58.8304 150.671 61.4741C151.675 61.6629 152.038 61.0441 152.038 60.5091C152.038 60.0341 152.026 58.7779 152.019 57.1104C146.453 58.3154 145.28 54.4216 145.28 54.4216C144.369 52.1166 143.054 51.4979 143.054 51.4979C141.244 50.2616 143.198 50.2879 143.198 50.2879C145.203 50.4241 146.258 52.3441 146.258 52.3441C148.041 55.4041 150.938 54.5191 152.084 54.0041C152.259 52.7154 152.78 51.8291 153.348 51.3354C148.908 50.8341 144.239 49.1154 144.239 41.4516C144.239 39.2629 145.014 37.4804 146.295 36.0804C146.074 35.5791 145.396 33.5416 146.471 30.7866C146.471 30.7866 148.145 30.2529 151.971 32.8379C153.573 32.3941 155.273 32.1729 156.971 32.1616C158.671 32.1729 160.37 32.3954 161.971 32.8379C165.775 30.2529 167.448 30.7866 167.448 30.7866C168.521 33.5416 167.844 35.5779 167.649 36.0804C168.918 37.4804 169.693 39.2641 169.693 41.4516C169.693 49.1329 165.018 50.8266 160.573 51.3154C161.268 51.9141 161.919 53.1454 161.919 55.0141C161.919 57.6891 161.894 59.8441 161.894 60.4954C161.894 61.0154 162.244 61.6404 163.274 61.4391C171.274 58.8229 176.998 51.3229 176.998 42.4941C176.998 31.4529 168.046 22.4941 156.998 22.4941L157 22.4954Z"
            fill="currentColor"
          />
        </g>
        <path
          d="M1 30C1 27.7909 2.79086 26 5 26H77C79.2091 26 81 27.7909 81 30V102C81 104.209 79.2091 106 77 106H5C2.79086 106 1 104.209 1 102V30Z"
          fill={theme.palette.background.light}
        />
        <path
          d="M1 30C1 27.7909 2.79086 26 5 26H77C79.2091 26 81 27.7909 81 30V102C81 104.209 79.2091 106 77 106H5C2.79086 106 1 104.209 1 102V30Z"
          stroke={theme.palette.background.lightest}
          stroke-width="2"
        />
        <path
          d="M47.5779 58.8073L51.0422 55.343L51.2731 53.8843C44.9602 48.144 34.9251 48.7949 29.2343 55.2424C27.6535 57.0332 26.4808 59.2657 25.8555 61.5712L27.0962 61.3963L34.0246 60.2538L34.5595 59.7068C37.6415 56.322 42.8525 55.8667 46.4109 58.7466L47.5779 58.8073Z"
          fill="#EA4335"
        />
        <path
          d="M55.9772 61.4687C55.1809 58.5364 53.5462 55.9003 51.2731 53.8838L46.411 58.7459C48.4641 60.4235 49.6337 62.9507 49.5836 65.6015V66.4645C51.9734 66.4645 53.9109 68.4019 53.9109 70.7917C53.9109 73.1817 51.9734 75.0705 49.5836 75.0705H40.9168L40.0659 75.9943V81.1846L40.9168 81.999H49.5836C55.7998 82.0474 60.8783 77.0961 60.9267 70.8799C60.9559 67.112 59.0982 63.5797 55.9772 61.4687Z"
          fill="#4285F4"
        />
        <path
          d="M32.2619 82.0001H40.9165V75.0715H32.2619C31.6453 75.0713 31.0481 74.9389 30.4873 74.6826L29.2595 75.0594L25.7709 78.5236L25.467 79.7027C27.4234 81.18 29.8104 82.0107 32.2619 82.0001Z"
          fill="#34A853"
        />
        <path
          d="M32.2618 59.5245C26.0455 59.5615 21.0364 64.631 21.0734 70.8472C21.0942 74.3185 22.7154 77.5862 25.467 79.7023L30.4873 74.6822C28.3092 73.6981 27.3413 71.1349 28.3254 68.957C29.3092 66.779 31.8726 65.8112 34.0504 66.7951C35.0101 67.2286 35.7787 67.9974 36.2123 68.957L41.2326 63.9368C39.0965 61.1445 35.7775 59.5119 32.2618 59.5245Z"
          fill="#FBBC05"
        />
        <circle
          cx="192"
          cy="128"
          r="16"
          fill="url(#paint0_linear_2647_37678)"
        />
        <path
          d="M193.266 121.602V133H191.016V124.195L188.312 125.055V123.281L193.023 121.602H193.266Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2647_37678"
            x1="176"
            y1="112"
            x2="208.789"
            y2="143.17"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#9147FF" />
            <stop offset="1" stop-color="#4C35E8" />
          </linearGradient>
          <clipPath id="clip0_2647_37678">
            <rect
              width="40"
              height="40"
              fill="white"
              transform="translate(137 22)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
