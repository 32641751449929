import { Box, TableCell, Typography } from "@mui/material";
import { Clamp } from "../clamp/clamp";
import { FirewatchChip } from "./ChipText";
import { AggregateScoresNode } from "../FirewatchPage";

export type VulnerabilityCellProps = {
  row: AggregateScoresNode;
  size?: string;
};

export function VulnerabilityCell({ row, size }: VulnerabilityCellProps) {
  const { tags } = row || {};
  const isDashboardCard = size === "small";

  return (
    <TableCell>
      <Clamp lines={size === "small" ? 2 : 1}>
        <Typography sx={{ fontSize: 14, fontWeight: 700, lineHeight: "16px" }}>
          {row?.title || row?.nodeTitle}{" "}
          <Typography
            component="span"
            sx={{ fontSize: isDashboardCard ? 12 : 14, fontWeight: 400 }}
          >
            {row?.description}
          </Typography>
        </Typography>
      </Clamp>
      {size !== "small" && (
        <Box display="flex" gap={1} mt={1}>
          {tags?.map((tag) => <FirewatchChip key={tag.key} tag={tag} />)}
        </Box>
      )}
    </TableCell>
  );
}
