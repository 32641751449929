import { Grid2 as Grid, Typography } from "@mui/material";
import { Code } from "~/components/code";
import React from "react";
import { WifExternalAuthConfig } from "~/operations";
import { formatBindingConfig } from "~/pages/space-settings/WorkloadIdentity/utils";

type WorkloadIdentityProviderConfigurationProps = {
  config: WifExternalAuthConfig | null;
};

export const WorkloadIdentityProviderConfiguration = ({
  config,
}: WorkloadIdentityProviderConfigurationProps) => {
  return (
    <>
      <Typography sx={{ m: 0 }}>Configuration</Typography>
      <Grid
        container
        py={1}
        width="100%"
        justifyContent="center"
        data-testid="binding-configuration"
      >
        <Code
          copyButton
          className="json"
          ContainerProps={{ sx: { m: 0, width: "100%" } }}
        >
          {formatBindingConfig(config)}
        </Code>
      </Grid>
    </>
  );
};
