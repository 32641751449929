import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { DashboardTable } from "./dashboard-table";
import { SeverityChip } from "~/components/severity-chip";
import {
  GetOrganizationOverviewQuery,
  useGetOrganizationOverviewQuery,
} from "~/operations";
import DashboardSkeleton from "~/pages/organization/dashboard/DashboardSkeleton";

type RawData = NonNullable<
  GetOrganizationOverviewQuery["organizationOverview"]
>["newVulnerabilities"];
type Datum = NonNullable<RawData>[0];
type Data = Datum[];

export type NewThisWeekProps = {
  organizationMrn: string;
};

export function NewThisWeek({ organizationMrn }: NewThisWeekProps) {
  const result = useGetOrganizationOverviewQuery({
    variables: { input: { organizationMrn } },
  });

  if (result.loading) {
    return <DashboardSkeleton />;
  }

  const rawData = result.data?.organizationOverview?.newVulnerabilities || [];
  const data = parseData(rawData);

  if (data.length === 0) {
    return (
      <Typography
        sx={{
          fontWeight: "bold",
          color: "text.secondary",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          height: "100%",
        }}
      >
        Enable vulnerability scanning to track your top new vulnerabilities
      </Typography>
    );
  }

  return (
    <DashboardTable>
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: "10%", minWidth: "100px" }}>Date</TableCell>
          <TableCell sx={{ width: "70%" }}>ID/Title</TableCell>
          <TableCell sx={{ width: "10%", minWidth: "100px", pr: 0 }}>
            CVSS score
          </TableCell>
          <TableCell sx={{ width: "10%", minWidth: "100px" }}>Assets</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((item) => (
          <Tooltip
            key={item.cve.id}
            title={item.cve.title || item.cve.description}
            arrow
            placement="top"
            disableInteractive
            enterDelay={500}
            enterNextDelay={500}
            PopperProps={{
              sx: {
                transition: (theme) => theme.transitions.create("opacity"),
                "&[data-popper-reference-hidden]": {
                  opacity: 0,
                  pointerEvents: "none",
                },
                ".MuiTooltip-tooltip": {
                  p: 1,
                  fontSize: 12,
                },
              },
            }}
          >
            <TableRow key={item.cve.id}>
              <TableCell sx={{ width: "10%", minWidth: "100px" }}>
                {item.date}
              </TableCell>
              <TableCell sx={{ width: "70%" }}>
                <Typography
                  component="span"
                  fontWeight="bold"
                  fontSize="inherit"
                >
                  {item.cve.id}:{" "}
                </Typography>
                <Typography component="span" fontSize="inherit">
                  {item.cve.title || item.cve.description}
                </Typography>
              </TableCell>
              <TableCell sx={{ width: "10%", minWidth: "100px", pr: 0 }}>
                <SeverityChip
                  severity={item.cve.cvssScore.value}
                  useCvss
                  small
                />
              </TableCell>
              <TableCell sx={{ width: "10%", minWidth: "100px" }}>
                {item.assetsAffected}
              </TableCell>
            </TableRow>
          </Tooltip>
        ))}
      </TableBody>
    </DashboardTable>
  );
}

function parseData(rawData: RawData): Data {
  return rawData || [];
}
