import { useSnackbar } from "notistack";
import { IamActions } from "~/lib/iam";
import {
  AuthBindingOrderField,
  GetWifAuthBindingsDocument,
  OrderDirection,
  TestIamActionsQuery,
  useGetWifAuthBindingsQuery,
  useRemoveWifAuthBindingMutation,
  WifAuthBindingOrder,
} from "~/operations";
import { useSearch } from "~/components/search/useSearch";
import { SpaceScope } from "~/hooks/useScope";
import { useSort } from "~/pages/inventory/hooks/useSort";

type UseWorkloadIdentityParams = {
  availablePermissions: TestIamActionsQuery["testIamActions"];
  scope: SpaceScope;
};

export function useWorkloadIdentity({
  availablePermissions,
  scope,
}: UseWorkloadIdentityParams) {
  const { enqueueSnackbar } = useSnackbar();
  const { searchFilters, handleFilterQuery } = useSearch();
  const { handleSortClick, orderBy } = useSort<WifAuthBindingOrder>({
    defaultSort: {
      field: AuthBindingOrderField.CreateTime,
      direction: OrderDirection.Desc,
    },
    validFields: [
      AuthBindingOrderField.CreateTime,
      AuthBindingOrderField.Name,
      AuthBindingOrderField.LastUsed,
    ],
  });

  const hasWIFListPermissions = availablePermissions.includes(
    IamActions.CAPTAIN_LISTWIFAUTHBINDINGS,
  );

  const hasWIFCreatePermissions = availablePermissions.includes(
    IamActions.CAPTAIN_CREATEWIFAUTHBINDING,
  );

  const hasWIFRemovePermissions = availablePermissions.includes(
    IamActions.CAPTAIN_REMOVEWIFAUTHBINDING,
  );

  const hasWIFSearchPermissions = availablePermissions.includes(
    IamActions.CAPTAIN_SEARCHWIFAUTHBINDING,
  );

  const { data, loading } = useGetWifAuthBindingsQuery({
    variables: { scopeMrn: scope.mrn, orderBy },
    skip: !hasWIFListPermissions,
    fetchPolicy: "cache-and-network",
  });

  const [removeWIFAuthBinding, { loading: isRemoving }] =
    useRemoveWifAuthBindingMutation({
      refetchQueries: [GetWifAuthBindingsDocument],
    });

  const handleWIFAuthBindingRemove = async (mrn: string) => {
    try {
      await removeWIFAuthBinding({ variables: { mrn } });
      enqueueSnackbar(`Successfully removed workload identity provider.`, {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(
        `Something went wrong while attempting to remove the binding: ${error}`,
        { variant: "error" },
      );
      console.error(error);
    }
  };

  const bindings =
    data?.listWIFAuthBindings?.__typename === "ListWIFAuthBindingsPayload"
      ? data.listWIFAuthBindings.bindings || []
      : [];

  const isLoading = isRemoving || loading;

  return {
    bindings,
    handleWIFAuthBindingRemove,
    isLoading,
    showEmptyState: bindings.length === 0 && !isLoading,
    searchFilters,
    handleFilterQuery,
    orderBy,
    handleSortClick,
    hasWIFListPermissions,
    hasWIFCreatePermissions,
    hasWIFSearchPermissions,
    hasWIFRemovePermissions,
  };
}
