import { Results } from "~/pages/findings/FindingOnAsset/components/QueryResults/Results";
import { Queries } from "~/pages/findings/FindingOnAsset/components/QueryResults/Queries";
import { useFindingOnAsset } from "~/pages/findings/FindingOnAsset/hooks";
import { isCheckFinding } from "~/pages/findings/FindingOnAsset/utils";

export const QueryResults = () => {
  const { finding } = useFindingOnAsset();

  if (!isCheckFinding(finding)) return;

  return (
    <>
      <Queries />
      <Results />
    </>
  );
};
