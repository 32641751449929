import React from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { RemediationScriptFieldsFragment } from "~/operations";

type RemediationTabsProps = {
  scripts: Array<RemediationScriptFieldsFragment>;
  scriptTab: number;
  onScriptTabChange: (_event: React.SyntheticEvent, newValue: number) => void;
};

type ScriptTypeKey = "ANSIBLE" | "SHELL" | "POWERSHELL";
type ScriptTypeValue = "Ansible" | "Shell" | "PowerShell";
type ScriptTypeMap = Record<ScriptTypeKey, ScriptTypeValue>;

const ScriptTypeMap: ScriptTypeMap = {
  ANSIBLE: "Ansible",
  SHELL: "Shell",
  POWERSHELL: "PowerShell",
};

export const RemediationTabs = ({
  scriptTab,
  onScriptTabChange,
  scripts,
}: RemediationTabsProps) => {
  const getTabLabel = (script: RemediationScriptFieldsFragment) => {
    return `${script.packageManager} (${ScriptTypeMap[script.type]})`;
  };

  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }} mt={2}>
      <Tabs
        variant="scrollable"
        value={scriptTab}
        onChange={onScriptTabChange}
        sx={{
          ".MuiTabs-scrollButtons.Mui-disabled": {
            opacity: 0.3,
          },
        }}
      >
        {(scripts || []).map((script) => (
          <Tab
            key={script.type}
            label={getTabLabel(script)}
            sx={{ textTransform: "none" }}
          />
        ))}
      </Tabs>
    </Box>
  );
};
