import { Theme, useTheme } from "@mui/material";
import {
  FindingsOrderField,
  FindingType,
  OrderDirection,
  ScoreRating,
  useGetAdvisoriesQuery,
  useGetCvEsQuery,
  useLoadAssetListPoliciesQuery,
  useLoadAssetQuery,
} from "~/operations";
import { ReactNode } from "react";
import { ApolloError } from "@apollo/client";
import { Asset } from "../types";
import { getRating } from "~/pages/inventory/components/PolicyScoreCard/asset/assetOverviewHeader/assetOverviewHeader";
import { getRatingText } from "../../lib/GetRatingText";
import { useReportingPageLoaded } from "../../hooks/useReportingPageLoaded";
import { AdvisoryFindingsNode } from "~/pages/inventory/components/Advisories/types";
import { CVEFindingsNode } from "~/pages/inventory/components/Vulnerabilities/types";
import { extractAssetKey, extractAssetValue } from "~/utils/assetUtils";
import { PolicyChecksGroupProps } from "../Policies";

// Define the return type for the useAssetReport hook
type UseAssetReportReturnObject = {
  loading: boolean;
  error: ApolloError | undefined;
  asset: Asset | undefined | null;
  assetRating: { rating: ScoreRating; value: number };
  theme: Theme;
  functions: {
    generateText: {
      header: () => string;
    };
  };
  scoreDetailsData: {
    vulnerabilities: {
      title: string;
      sectionHighlights: {
        key: string;
        value: string;
        options?: { color?: string; weight?: number };
        startIcon?: ReactNode;
      }[];
      tableName: string;
      listItems: CVEFindingsNode[];
      description: string;
      totalChecks?: number;
    };
    security: {
      title: string;
      sectionHighlights: {
        key: string;
        value: string;
        options?: { color?: string; weight?: number };
        startIcon?: ReactNode;
      }[];
      tableName: string;
      listItems: PolicyChecksGroupProps["policy"][];
      description: string;
      totalChecks?: number;
    };
  };
  platformOverviewData: { id: string; key: string; value: string }[];
  assetTags: Asset["labels"] | null | undefined;
  policies: PolicyChecksGroupProps["policy"][];
  advisories: AdvisoryFindingsNode[];
  vulnerabilities: CVEFindingsNode[];
  securityScore: {
    [key: string]: {
      score: number | undefined;
      rating: string;
    };
  };
};

// Hook to fetch and process asset report data
export function useAssetReport(): UseAssetReportReturnObject {
  const { searchParams } = new URL(window.location.href);
  const assetMrn = searchParams.get("assetMrn") || "";
  let theme = useTheme();

  // Fetch asset data
  const {
    data: assetData,
    error: assetError,
    loading: assetLoading,
    networkStatus: assetNetworkStatus,
  } = useLoadAssetQuery({
    variables: {
      mrn: assetMrn,
    },
  });

  const { data: assetListData, networkStatus: assetListNetworkStatus } =
    useLoadAssetListPoliciesQuery({
      variables: {
        mrn: assetMrn,
        first: 100,
      },
    });

  // Fetch vulnerability scores
  const {
    data: cveFindingsData,
    error,
    loading: cveFindingsLoading,
    networkStatus: cveFindingsNetworkStatus,
  } = useGetCvEsQuery({
    variables: {
      scopeMrn: assetMrn,
      orderBy: {
        field: FindingsOrderField.RiskValue,
        direction: OrderDirection.Desc,
      },
      filter: {
        types: [FindingType.Cve],
      },
    },
  });

  // Fetch advisory scores
  const {
    data: advisoryFindingsData,
    networkStatus: advisoryFindingsNetworkStatus,
    loading: advisoryFindingsLoading,
  } = useGetAdvisoriesQuery({
    variables: {
      scopeMrn: assetMrn,
      orderBy: {
        field: FindingsOrderField.RiskValue,
        direction: OrderDirection.Desc,
      },
      filter: {
        types: [FindingType.Advisory],
      },
    },
  });

  const networkStatuses = [
    assetNetworkStatus,
    assetListNetworkStatus,
    advisoryFindingsNetworkStatus,
    cveFindingsNetworkStatus,
  ];

  // Determine if all network requests are ready
  useReportingPageLoaded(networkStatuses);
  const { asset } = assetData || {};
  const { asset: assetList } = assetListData || {};

  const totalVulnerabilities =
    cveFindingsData?.findings?.__typename === "FindingsConnection"
      ? cveFindingsData.findings.totalCount
      : 0;

  const cveFindingsUnion =
    cveFindingsData?.findings?.__typename === "FindingsConnection"
      ? cveFindingsData.findings
      : undefined;

  const cveFindings: CVEFindingsNode[] = (
    cveFindingsUnion?.edges || []
  ).flatMap((e) => (e?.node?.__typename === "CveFinding" ? e.node : []));

  // Process advisory scores
  const advisoryFindingsUnion =
    advisoryFindingsData?.findings?.__typename === "FindingsConnection"
      ? advisoryFindingsData?.findings
      : undefined;

  const advisoryFindings: AdvisoryFindingsNode[] = (
    advisoryFindingsUnion?.edges || []
  ).flatMap((e) => (e?.node?.__typename === "AdvisoryFinding" ? e.node : []));

  const advisories = advisoryFindings || [];
  const vulnerabilities = cveFindings || [];

  // Process policies
  const policies =
    assetList?.listPolicies?.edges.flatMap((e) => e.node ?? []) || [];

  // Calculate total checks
  const totalChecks = policies.reduce((acc, policy) => {
    return acc + (policy.scoreStats?.total ?? 0);
  }, 0);

  const totalPassingChecks = policies.reduce((acc, policy) => {
    return acc + (policy.scoreStats?.passed ?? 0);
  }, 0);

  const totalFailingChecks = policies.reduce((acc, policy) => {
    return acc + (policy.scoreStats?.failed ?? 0);
  }, 0);

  // Calculate total critical vulnerabilities
  const totalCriticalVulnerabilities = cveFindings?.filter(
    (vuln) => vuln?.rating === ScoreRating.Critical,
  ).length;

  // Calculate total high vulnerabilities
  const totalHighVulnerabilities = cveFindings.filter(
    (vuln) => vuln?.rating === ScoreRating.High,
  ).length;

  // Gather security scores
  const mapRating = (weight: number | undefined): ScoreRating => {
    const ratingNumber = getRating(weight || 0);
    const rating = getRatingText(ratingNumber);
    return rating;
  };

  const securityScore = {
    vulnerability: {
      score: asset?.insights?.vulnerability?.score.riskValue,
      rating: mapRating(asset?.insights?.vulnerability?.score.weight),
    },
    security: {
      score: asset?.insights?.security.score.riskValue,
      rating: mapRating(asset?.insights?.security.score.weight),
    },
  };

  const assetScoreRating = mapRating(asset?.score.weight);

  // extract the overviewGroup with the title "Platform overview"
  const platformOverview = asset?.overviewGroups?.find(
    (group) => group.title === "Platform overview",
  );

  // loop through each item in the platformOverview and create key value pairs
  const platformOverviewData =
    platformOverview?.items.map((item) => {
      const id = item.key;
      const key = extractAssetKey(item);
      const value = extractAssetValue(item);

      return { id, key, value };
    }) || [];

  // Extract the asset tags
  const assetTags = asset?.labels;

  const severeStatement =
    totalCriticalVulnerabilities > 0
      ? " The most severe CVSS score is 10.0."
      : "";
  // Generate text for the header.
  const generateText = {
    header: () => {
      return `Mondoo ran ${totalChecks} security checks on the ${asset?.name} ${asset?.platform?.name} asset. ${totalPassingChecks} passed and ${totalFailingChecks} failed. Mondoo found ${totalCriticalVulnerabilities} critical and ${totalHighVulnerabilities} high vulnerabilities.${severeStatement} Considering all data and contextual risk factors, Mondoo gives this asset a vulnerability score of ${securityScore.vulnerability.score} (${securityScore.vulnerability.rating.toLowerCase()}) and a security score of ${securityScore.security.score} (${securityScore.security.rating.toLowerCase()}).`;
    },
  };

  // Return the processed data
  return {
    loading: assetLoading || cveFindingsLoading || advisoryFindingsLoading,
    error: assetError || error,
    asset,
    assetRating: { rating: assetScoreRating, value: asset?.score.value || 0 },
    theme,
    functions: {
      generateText: {
        header: generateText.header,
      },
    },
    scoreDetailsData: {
      vulnerabilities: {
        title: "Vulnerabilities",
        sectionHighlights: [
          { key: "vuln-found", value: `${totalVulnerabilities} found` },
          // {
          //   key: "exceptions",
          //   value: "2 Exceptions", // TODO: GET EXCEPTIONS COUNT
          //   options: { color: theme.palette.text.disabled, weight: 400 },
          //   startIcon: <DoNotDisturbOnOutlined sx={{ fontSize: 15 }} />,
          // },
        ],
        tableName: "ASSET_REPORT_SECURITY_VULNERABILITIES",
        listItems: vulnerabilities,
        description:
          "The asset vulnerability score is based on the highest risk vulnerability on this asset.",
      },
      security: {
        title: "Security",
        sectionHighlights: [
          { key: "checks", value: `${totalChecks} checks` },
          // {
          //   key: "exceptions",
          //   value: "3 Exceptions", // TODO: GET EXCEPTIONS COUNT
          //   options: { color: theme.palette.text.disabled, weight: 400 },
          //   startIcon: <DoNotDisturbOnOutlined sx={{ fontSize: 15 }} />,
          // },
        ],
        tableName: "ASSET_REPORT_SECURITY",
        listItems: policies,
        totalChecks,
        description:
          "The asset security score is calculated by averaging each of the scores of your enabled policies, which each make up a certain percentage of the security score. The percentage of the overall security score is based on the number of checks in each policy. Each policy has its own scoring method which can be set on the policy page.",
      },
    },
    assetTags,
    platformOverviewData,
    policies,
    advisories,
    vulnerabilities,
    securityScore,
  };
}
