import { Box, Theme, alpha, useTheme } from "@mui/material";
import {
  ArrowRightAltIcon,
  CheckIcon,
  CloseIcon,
  RemoveIcon,
  WarningAmberIcon,
} from "~/components/icons";
import { ScoreResultType, ScoreState } from "~/operations";
import { BlockOutlined } from "@mui/icons-material";
import { getColor } from "~/lib/colors";

type Props = {
  result: ScoreResultType | undefined;
  scoreState: ScoreState | undefined;
};

export function CheckResultIcon({ result, scoreState }: Props) {
  const theme = useTheme();
  const iconStyles = {
    fontSize: 24,
    diameter: theme.spacing(4),
  };

  const color = getCheckColor({ theme, scoreState, result });
  const getIcon = () => {
    if (result === ScoreResultType.Error) {
      return <WarningAmberIcon sx={{ ...iconStyles, color }} />;
    }

    if (result === ScoreResultType.Skipped) {
      return <ArrowRightAltIcon sx={{ ...iconStyles, color }} />;
    }

    switch (scoreState) {
      case ScoreState.Closed:
        return <CheckIcon sx={{ ...iconStyles, color }} />;

      case ScoreState.Open:
        return <CloseIcon sx={{ ...iconStyles, color }} />;

      case ScoreState.Disabled:
        return <BlockOutlined sx={{ ...iconStyles, color }} />;

      default:
        //skipped
        return <RemoveIcon sx={{ ...iconStyles, color }} />;
    }
  };

  return (
    <Box
      sx={{
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        width: iconStyles.diameter,
        height: iconStyles.diameter,
        borderRadius: "50%",
        background: alpha(color, 0.1),
      }}
    >
      {getIcon()}
    </Box>
  );
}

type GetCheckColorProps = {
  theme: Theme;
  scoreState: ScoreState | undefined;
  result: ScoreResultType | undefined;
};

export const getCheckColor = ({
  theme,
  scoreState,
  result,
}: GetCheckColorProps) => {
  if (!scoreState) {
    return getColor(theme, "unknown");
  }

  const color = getColor(theme, scoreState);

  if (result && result !== ScoreResultType.Result) {
    if (result === ScoreResultType.Error) {
      return getColor(theme, "errorscan");
    }
    return getColor(theme, result);
  }
  return color;
};
