import {
  FindingsOrderField,
  FindingType,
  OrderDirection,
  ScoreState,
  useGetAdvisoriesQuery,
} from "~/operations";
import { Table, TableBody, TableContainer, TableRow } from "@mui/material";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import { Header } from "~/types/table";
import { TableHead } from "~/pages/inventory/components/TableHead";
import { ImpactCell, PublishedCell, RiskFactorsCell } from "../Cells";
import { AdvisoryFindingsNode } from "~/pages/inventory/components/Advisories/types";
import { TitleCell } from "~/pages/inventory/components/Findings/FindingsTable/TitleCell";

export type PrintAdvisoriesProps = {
  assetMrn: string;
};

export function PrintAdvisories(props: PrintAdvisoriesProps) {
  const { assetMrn } = props;

  const { data, error, loading } = useGetAdvisoriesQuery({
    variables: {
      scopeMrn: assetMrn,
      first: 100,
      orderBy: {
        direction: OrderDirection.Desc,
        field: FindingsOrderField.CvssScore,
      },
      filter: {
        types: [FindingType.Advisory],
      },
    },
  });

  if (loading) {
    return <LoadingPage what="packages" />;
  }

  const advisoryFindingsUnion =
    data?.findings?.__typename === "FindingsConnection"
      ? data?.findings
      : undefined;

  const advisoryFindings: AdvisoryFindingsNode[] = (
    advisoryFindingsUnion?.edges || []
  ).flatMap((e) => (e?.node?.__typename === "AdvisoryFinding" ? e.node : []));

  if (error || !advisoryFindings) {
    return <LoadingFailedPage what="packages" />;
  }

  const tableHeaders: Header[] = [
    {
      id: "IMPACT",
      label: "Impact",
      options: { width: "10%" },
      sortable: false,
    },
    {
      id: "ADVISORY",
      label: "Advisory",
      options: { width: "50%" },
      sortable: false,
    },
    {
      id: "RISK_FACTORS",
      label: "Risk factors",
      sortable: false,
    },
    {
      id: "PUBLISHED",
      label: "Published",
      sortable: false,
      options: {
        width: "15%",
      },
    },
  ];

  return (
    <TableContainer>
      <Table>
        <TableHead tableHeaders={tableHeaders} />
        <TableBody>
          {advisoryFindings.map((advisory) => {
            return (
              <TableRow key={advisory.id}>
                <ImpactCell
                  data-testid="advisory-impact"
                  rating={advisory.rating}
                  impact={advisory.riskValue || 0}
                  isActive={advisory.state === ScoreState.Open}
                  cellProps={{ width: "10%" }}
                />
                <TitleCell
                  id={advisory.id}
                  title={advisory.title}
                  tags={advisory.tags || []}
                  iconId={advisory.iconId}
                />
                <RiskFactorsCell riskFactors={advisory.riskFactors} printView />
                <PublishedCell date={advisory.publishedAt || ""} />
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
