import {
  Access,
  ExceptionGroup,
  ListExceptionGroupsDocument,
  SpaceCheckException,
  Tag,
} from "~/operations";
import { Exceptions } from "~/components/exceptions/exceptions";
import { usePolicyOutletContext } from "~/components/policy-gql/policy-gql";
import {
  Box,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { CheckStats } from "~/components/impact";
import getQueryMrn from "../../utils/getQueryMrn";
import { Header } from "~/types/table";
import { ImpactUpdated } from "~/components/impact/Updated/impact-updated";
import { Clamp } from "../clamp/clamp";
import { FirewatchChip } from "../FirewatchRow/ChipText";

const tableHeaders: Header[] = [
  { id: "IMPACT", label: "Impact" },
  { id: "SCORE", label: "Scores", options: { width: 150 } },
  { id: "QUERY", label: "Check" },
  {
    id: "ASSETS",
    label: "Assets",
    options: { textAlign: "right", width: 150 },
  },
];

export const ExceptionsWrapper = () => {
  const { availablePermissions, space, exceptionGroups, policy } =
    usePolicyOutletContext();
  const isPrivatePolicy = policy.access === Access.Private;
  const { policyId } = useParams();

  return (
    <Exceptions
      availablePermissions={availablePermissions}
      emptyStateType="check-exceptions"
      refetchQueries={[ListExceptionGroupsDocument]}
      exceptionGroups={exceptionGroups}
      renderTable={function (exceptionGroup: ExceptionGroup) {
        const { exceptions } = exceptionGroup;
        return (
          <>
            <Table sx={{ backgroundColor: "background.lighter" }}>
              <TableHead>
                <TableRow>
                  {tableHeaders.map((header: Header) => (
                    <TableCell key={header.id}>{header.label}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {(exceptions as SpaceCheckException[]).map((exception) => {
                const { mrn, riskValue, rating, title, assetsStats } =
                  exception;

                const policyNameTag: Tag = {
                  key: "policy",
                  value: exception.policyName,
                  __typename: "Tag",
                };

                const uid = mrn.split("/").pop();

                // needs different link for private policies
                let namespace = isPrivatePolicy ? space.id : "mondoohq";

                const checkMrn = getQueryMrn({ namespace, uid: uid as string });

                const urlLink = `/space/security/check?spaceId=${space.id}&findingMrn=${checkMrn}&policyId=${policyId}`;

                return (
                  <TableRow key={mrn} component={Link} to={urlLink}>
                    <TableCell>
                      {rating && (
                        <ImpactUpdated
                          impact={{ rating, value: riskValue ?? 0 }}
                          isRiskScore
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <CheckStats
                        results={{
                          passed: assetsStats.passed,
                          failed: assetsStats.failed,
                          errored: assetsStats.errored,
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Clamp>
                        <Typography
                          sx={{
                            fontSize: 14,
                            fontWeight: 700,
                            lineHeight: "16px",
                          }}
                        >
                          {title}
                        </Typography>
                      </Clamp>
                      <Box display="flex" gap={1} mt={1}>
                        <FirewatchChip
                          key={policyNameTag.key}
                          tag={policyNameTag}
                        />
                      </Box>
                    </TableCell>
                    <TableCell>{assetsStats.total}</TableCell>
                  </TableRow>
                );
              })}
            </Table>
          </>
        );
      }}
    />
  );
};
