import { styled } from "@mui/material/styles";
import {
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Button,
  Box,
  Typography,
} from "@mui/material";
import { DataTable } from "~/components/data-table";
import { Loading, LoadingFailed } from "../components/loading";
import { CheckCircleIcon, OrgIcon, SpaceIcon } from "../components/icons";
import { useSearchParams } from "react-router-dom";
import {
  GetSharedSpacesCountDocument,
  ListSharedSpacesDocument,
  LoadUserInvitationsDocument,
  LoadUserInvitationsQuery,
  LoadViewerDocument,
  useAcceptInvitationMutation,
  useDeclineInvitationMutation,
  useLoadUserInvitationsQuery,
} from "~/operations";
import { useViewer } from "~/providers/viewer";
import { useSnackbar } from "notistack";
import { setDocumentTitle } from "~/utils/commonUtils";

type Invitations = NonNullable<LoadUserInvitationsQuery["invitations"]>;
type InvitationsEdge = NonNullable<NonNullable<Invitations["edges"]>[0]>;
type InvitationsNode = NonNullable<InvitationsEdge["node"]>;

// TODO: harmonize with members.js
const ROLE_OWNER_MRN = "//iam.api.mondoo.app/roles/owner";
const ROLE_EDITOR_MRN = "//iam.api.mondoo.app/roles/editor";
const ROLE_VIEWER_MRN = "//iam.api.mondoo.app/roles/viewer";
const ROLE_NAMES = {
  [ROLE_OWNER_MRN]: "Owner",
  [ROLE_EDITOR_MRN]: "Editor",
  [ROLE_VIEWER_MRN]: "Viewer",
};

const isKeyOfObject = <T extends object>(
  key: string | number | symbol,
  obj: T,
): key is keyof T => {
  return key in obj;
};

const roleName = (key: string) => {
  return isKeyOfObject<typeof ROLE_NAMES>(key, ROLE_NAMES)
    ? ROLE_NAMES[key]
    : undefined;
};

const OrgIconS = styled(OrgIcon)`
  margin-right: ${(props) => props.theme.spacing(1)};
`;

const SpaceIconS = styled(SpaceIcon)`
  margin-right: ${(props) => props.theme.spacing(1)};
`;

const FlexRow = styled("div")`
  display: flex;
  align-items: center;
`;

const reSpaceOrgMrn = /\/(spaces|organizations)\/([\w\d-]+)\/{0,1}/;

const renderResource = (resourceMrn: string) => {
  const matches = resourceMrn.match(reSpaceOrgMrn);
  if (matches == null) {
    throw new Error("failed to match mrn to org/space MRN");
  }

  let icon = <SpaceIconS />;
  if (resourceMrn.includes("organizations")) {
    icon = <OrgIconS />;
  }

  return (
    <FlexRow>
      {icon} {matches[2]}
    </FlexRow>
  );
};

export const GreenCheckCircleIcon = styled(CheckCircleIcon)`
  color: lightgreen;
`;

export function Invitations() {
  const { viewer, findRegion, setSelectedRegion } = useViewer();
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();

  const regionParam = searchParams.get("region");
  const region = regionParam ? findRegion(regionParam) : undefined;
  if (region) {
    // note that this call will refresh the page if a change in region occurs
    setSelectedRegion(region);
  }

  const [accept] = useAcceptInvitationMutation({
    refetchQueries: [
      LoadViewerDocument,
      LoadUserInvitationsDocument,
      ListSharedSpacesDocument,
      GetSharedSpacesCountDocument,
    ],
  });

  const [decline] = useDeclineInvitationMutation({
    refetchQueries: [LoadUserInvitationsDocument],
  });

  const { error, loading, data } = useLoadUserInvitationsQuery({
    variables: { inviteeMrn: viewer?.mrn, first: 10 },
    notifyOnNetworkStatusChange: true,
  });

  if (loading) {
    return <Loading what="invitations" />;
  }

  if (error || !data?.invitations) {
    return <LoadingFailed what="invitations" />;
  }

  const handleAcceptClick = async (item: InvitationsNode) => {
    try {
      await accept({ variables: { invitationMrn: item.mrn } });
      enqueueSnackbar("Invitation has been accepted!", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("Failed to accept an invitation!", { variant: "error" });
    }
  };

  const handleDeclineClick = async (item: InvitationsNode) => {
    try {
      await decline({ variables: { invitationMrn: item.mrn } });
      enqueueSnackbar("Invitation has been declined!", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("Failed to decline an invitation!", { variant: "error" });
    }
  };

  const invitations =
    data.invitations.edges?.flatMap((edge) => edge.node ?? []) || [];

  setDocumentTitle("Invitations");

  return (
    <Box>
      {/* Heading */}
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 4 }}>
        <Typography variant="h4" component="h2" fontWeight={700}>
          Invitations
        </Typography>
      </Box>
      {invitations.length ? (
        <DataTable>
          <TableHead>
            <TableRow>
              <TableCell>From</TableCell>
              <TableCell>To</TableCell>
              <TableCell>Resource</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>State</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invitations.map((item) => (
              <TableRow key={item.mrn}>
                <TableCell>{item.senderEmail}</TableCell>
                <TableCell>{item.inviteeEmail}</TableCell>
                <TableCell>{renderResource(item.resourceMrn)}</TableCell>
                <TableCell>{roleName(item.roleMrn)}</TableCell>
                <TableCell>{item.state}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    onClick={() => handleAcceptClick(item)}
                  >
                    Accept
                  </Button>
                  <Button onClick={() => handleDeclineClick(item)}>
                    Decline
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </DataTable>
      ) : (
        <Typography color="text.secondary">No invitations found</Typography>
      )}
    </Box>
  );
}
