import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import {
  LoadViewerDocument,
  useActivateUserInRegionMutation,
} from "~/operations";

export function SetupRegion() {
  const { enqueueSnackbar } = useSnackbar();
  const [activateUserInRegion, { loading, error }] =
    useActivateUserInRegionMutation({
      refetchQueries: [LoadViewerDocument],
    });

  const activate = async () => {
    try {
      await activateUserInRegion();
    } catch (error) {
      enqueueSnackbar("Failed to enable region", { variant: "error" });
    }
  };

  useEffect(() => {
    activate();
  }, []);

  return (
    <Box id="enable-region">
      {loading && <LoadingPage what="Region" />}
      {error && <LoadingFailedPage what="Region" />}
    </Box>
  );
}
