import { useFindingOnAsset } from "~/pages/findings/FindingOnAsset/hooks";
import { PolicyMrnToURIEncodedId } from "~/lib/mrn";
import { PolicyListRow } from "~/pages/security/policies/components/PolicyListRow";
import { isCheckFinding } from "~/pages/findings/FindingOnAsset/utils";
import { useNavigate } from "react-router-dom";
import { SectionHeading } from "~/components/DetailsPage/components";
import { Paper } from "@mui/material";

export const RelatedPolicies = () => {
  const { finding, activeScope } = useFindingOnAsset();
  const navigate = useNavigate();

  if (!isCheckFinding(finding) || !activeScope) return;

  return (
    <>
      <SectionHeading heading="Related policies" />
      {(finding?.relatedPolicies || []).map((policy) => {
        if (!policy) return undefined;

        const { mrn } = policy;

        const href = `/space/findings/policies/${PolicyMrnToURIEncodedId(
          mrn,
        )}/checks?${activeScope.params}`;

        return (
          <Paper
            sx={{
              borderCollapse: "collapse",
              "& td": {
                borderBottom: "none",
              },
              "& tr + tr": {
                borderTop: (theme) =>
                  theme.palette.tables.tableCell.borderBottom,
              },
            }}
          >
            <PolicyListRow
              key={mrn}
              onClick={() => navigate(href)}
              policy={policy}
              isSelected={false}
            />
          </Paper>
        );
      })}
    </>
  );
};
