import { useState } from "react";
import {
  Link as RouterLink,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { TableRow, TableCell, Typography, Box } from "@mui/material";
import { useControlContext } from "../control";
import { INITIAL_PAGE_RANGE } from "~/components/pagination";
import { ComplianceTable, Header } from "../../compliance-table";
import { EmptyState } from "~/components/empty-state/empty-state";
import {
  ChecksOrderField,
  OrderDirection,
  useGetComplianceControlDataQueriesQuery,
} from "~/operations";
import { useSearch } from "~/components/search/useSearch";
import { Loading, LoadingFailed } from "~/components/loading";
import { DedupRefsNames } from "./checks";

export function DataQueries() {
  const { space, controlMrn, frameworkMrn } = useControlContext();
  const [searchParams] = useSearchParams();
  const { searchFilters } = useSearch();
  const [pageItems, setPageItems] = useState(INITIAL_PAGE_RANGE);
  const { frameworkId, controlId } = useParams();

  const {
    data: { complianceControl } = {},
    error,
    loading,
    fetchMore,
  } = useGetComplianceControlDataQueriesQuery({
    variables: {
      input: {
        controlMrn,
        frameworkMrn,
        scopeMrn: space.mrn,
      },
      dataQueriesInput: {
        frameworkMrn,
        scopeMrn: space.mrn,
        orderBy: {
          field:
            (searchParams.get("field") as ChecksOrderField) ||
            ChecksOrderField.AssetsCount,
          direction:
            (searchParams.get("direction") as OrderDirection) ||
            OrderDirection.Desc,
        },
        query: searchFilters.join(","),
      },
      first: 50,
      after: null,
    },
  });

  const { dataQueries } = complianceControl || {};
  const { edges } = dataQueries || {};

  if (loading && !dataQueries) {
    return <Loading what="data queries" />;
  }

  if (error) {
    return <LoadingFailed what="data queries" />;
  }

  if (!edges || edges.length === 0) {
    return <EmptyState contentType="data-queries" space={space} />;
  }

  const buildUrl = (queryMrn: string | undefined) => {
    if (!queryMrn) return "#";
    return `/space/compliance/${frameworkId}/controls/${controlId}/data-query?spaceId=${space.id}&queryMrn=${queryMrn}`;
  };

  return (
    <Box>
      {/* Checks does not currently have search enabled */}
      {/* <FilterBar
        searchId="compliance-checks"
        placeholder="compliance_checks"
        searchFilters={searchFilters}
        handleFilterQuery={handleFilterQuery}
      /> */}
      <ComplianceTable
        tableHeaders={tableHeaders}
        defaultActiveColumn={"QUERY"}
        paginationProps={{
          fetchMore,
          totalCount: dataQueries?.totalCount || 0,
          pageInfo: dataQueries?.pageInfo,
          setPageItems: setPageItems,
        }}
      >
        {edges.slice(pageItems.from, pageItems.to).map((queryEdge) => {
          const { node } = queryEdge;

          return (
            <TableRow
              key={node?.mquery.mrn}
              component={RouterLink}
              to={buildUrl(node?.mquery.mrn)}
              sx={{
                "& .MuiTableCell-root": { fontWeight: 400 },
              }}
            >
              <TableCell>
                <Typography>{node?.mquery.title}</Typography>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  fontWeight={600}
                  display="block"
                >
                  {DedupRefsNames(node?.refs || [])
                    .map((ref) => ref.name)
                    .join(", ")}
                </Typography>
              </TableCell>
              <TableCell sx={{ width: "15%", maxWidth: 150 }}>
                {node?.assets || node?.assets === 0 ? node.assets : "—"}
              </TableCell>
            </TableRow>
          );
        })}
      </ComplianceTable>
    </Box>
  );
}

const tableHeaders: Header[] = [
  {
    id: "QUERY",
    label: "Query",
  },
  {
    id: "ASSETS",
    label: "Assets",
  },
];
