import { Fragment, useEffect, useState } from "react";
import {
  Box,
  Button,
  Link,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { ChevronRightIcon, OpenInNewIcon } from "~/components/icons";
import { Command } from "~/components/guides/components";
import { Space } from "~/lib/types";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  ActionType,
  ClientIntegration,
  ClientIntegrationType,
  GetClientIntegrationDocument,
  GetIntegrationsSummaryDocument,
  IntegrationType,
  useCreateClientIntegrationMutation,
  useTriggerActionLazyQuery,
  useUpdateClientIntegrationConfigurationMutation,
} from "~/operations";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { getError } from "~/lib/handle-error";
import { helperTextStyles, ValidationMessage } from "../../validations/helpers";
import { IntegrationAddHeader } from "../../headers/integration-add-header";
import useGenerateIntegrationName from "../../utils/useGenerateIntegrationName";
import { UpdateFlowData } from "../../types";
import { ToggleOption } from "../../components/ToggleOption/ToggleOption";
import { ConfigurePreferences } from "./ConfigurePreferences";
import { PasswordField } from "~/components/Form/components/PasswordField";

type CloudFormInput = {
  integrationName: string;
  account: string;
  host: string;
  clientSecret: string;
  createAssets: boolean;
};

const defaultValues: CloudFormInput = {
  integrationName: "",
  account: "",
  host: "",
  clientSecret: "",
  createAssets: false,
};

const CLIENT_SECRET_BLOCK = "**********************";
const clientSecretBlockRegex = /^\*+$/;

export function SentinelOneIntegrationForm({
  space,
  updateFlow,
}: {
  space: Space;
  updateFlow?: UpdateFlowData;
}) {
  let navigate = useNavigate();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [currentIntegrationMrn, setCurrentIntegrationMrn] = useState<
    ClientIntegration["mrn"] | undefined
  >(undefined);
  const [view, setView] = useState<"setup" | "configure">("setup");
  const [isFinalized, setIsFinalized] = useState(false);
  const defaultIntegrationName = useGenerateIntegrationName({ space });

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    getFieldState,
    formState: { errors, isValid, isSubmitSuccessful },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      integrationName: defaultIntegrationName,
    },
  });

  const [integrationName, account, host, clientSecret, createAssets] = watch([
    "integrationName",
    "account",
    "host",
    "clientSecret",
    "createAssets",
  ]);

  const [createIntegration] = useCreateClientIntegrationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      if (data.createClientIntegration.integration) {
        setCurrentIntegrationMrn(data.createClientIntegration.integration.mrn);
      }
    },
    refetchQueries: [
      {
        query: GetIntegrationsSummaryDocument,
        variables: { input: { spaceMrn: space.mrn } },
      },
    ],
  });

  const [updateIntegration] = useUpdateClientIntegrationConfigurationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    refetchQueries: [
      {
        query: GetClientIntegrationDocument,
        variables: {
          mrn: `//integration.api.mondoo.app/spaces/${
            space.id
          }/integrations/${updateFlow?.integration.mrn.split("/").pop()}`,
        },
      },
    ],
  });

  const [TriggerClientIntegrationScan] = useTriggerActionLazyQuery({
    variables: {
      input: { mrn: currentIntegrationMrn!, type: ActionType.RunImport },
    },
  });

  const handleTriggerScan = async () => {
    try {
      await TriggerClientIntegrationScan();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (updateFlow) {
      if (
        updateFlow?.integration.configurationOptions?.__typename !==
        "SentinelOneConfigurationOptions"
      )
        return;

      reset({
        integrationName: updateFlow.integration.name,
        clientSecret: CLIENT_SECRET_BLOCK,
        account: updateFlow.integration.configurationOptions?.account || "",
        host: updateFlow.integration.configurationOptions?.host,
        createAssets:
          updateFlow.integration.configurationOptions?.createAssets || false,
      });
    }
  }, []);

  useEffect(() => {
    if (isSubmitSuccessful && isFinalized && !updateFlow) {
      reset(defaultValues);
    }
  }, [isSubmitSuccessful]);

  const onSubmit: SubmitHandler<CloudFormInput> = async (data) => {
    if (!updateFlow) {
      if (!clientSecret) {
        throw new Error("You must supply a valid API token");
      }
    }

    const sentinelOneConfigurationOptions = {
      host: data.host,
      account: data.account,
      createAssets: data.createAssets,
      ...(clientSecret && !clientSecretBlockRegex.test(clientSecret)
        ? { clientSecret }
        : {}),
    };

    try {
      if (updateFlow) {
        const integrationId = updateFlow.integration.mrn.split("/").pop();
        const mrn = `//integration.api.mondoo.app/spaces/${space.id}/integrations/${integrationId}`;
        await updateIntegration({
          variables: {
            input: {
              mrn,
              name: data.integrationName.trim(),
              type: ClientIntegrationType.SentinelOne,
              configurationOptions: {
                sentinelOneConfigurationOptions,
              },
            },
          },
        });

        setCurrentIntegrationMrn(mrn);
        setView("configure");
      } else {
        const newIntegration = await createIntegration({
          variables: {
            input: {
              spaceMrn: space.mrn,
              name: data.integrationName.trim(),
              type: ClientIntegrationType.SentinelOne,
              longLivedToken: false,
              configurationOptions: {
                sentinelOneConfigurationOptions,
              },
            },
          },
        });
        setCurrentIntegrationMrn(
          newIntegration.data?.createClientIntegration.integration?.mrn,
        );
        setView("configure");
      }
    } catch (e) {
      const msg = getError(e);
      enqueueSnackbar(msg, { variant: "error" });
    }
  };

  const preferencesForm = (
    // Step 5
    <Box pb={4}>
      <Command
        number={5}
        options={{
          fontSize: { xs: 16 },
          dotColor: theme.palette.background.lightest,
        }}
      >
        Configure preferences
      </Command>
      <Box sx={{ mt: 3 }}>
        <ToggleOption
          title="Create new assets for unique detections"
          description="When a third-party data integration discovers an asset that doesn't match any existing assets in this space, add the new asset."
          formOptions={{ control, name: "createAssets" }}
        />
      </Box>
    </Box>
  );

  const onFinalizeSetup = async () => {
    if (!currentIntegrationMrn) {
      return;
    }

    try {
      await updateIntegration({
        variables: {
          input: {
            mrn: currentIntegrationMrn,
            name: integrationName,
            type: ClientIntegrationType.SentinelOne,
            configurationOptions: {
              sentinelOneConfigurationOptions: {
                account,
                host,
                createAssets,
                ...(clientSecret ? { clientSecret } : {}),
              },
            },
          },
        },
      });

      setIsFinalized(true);
      // kick off the import
      handleTriggerScan();

      enqueueSnackbar("Successfully updated configuration", {
        variant: "success",
      });
      navigate(
        `/space/integrations/sentinelone/${currentIntegrationMrn.split("/").pop()}/?spaceId=${space.id}`,
      );
    } catch (error) {
      console.error("Error updating integration", error);
      enqueueSnackbar("Error updating integration", { variant: "error" });
    }
  };

  return (
    <Fragment>
      {view === "setup" ? (
        <Fragment>
          <IntegrationAddHeader {...{ type: IntegrationType.SentinelOne }} />
          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* Step 1 */}
              <Box pb={4}>
                <Command
                  number={1}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  Choose an integration name
                </Command>
                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2, mt: 2 }}
                  >
                    Please choose a descriptive name that lets you easily
                    identify your integration.
                  </Typography>
                  <Controller
                    name="integrationName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        sx={{
                          background: theme.palette.code.background,
                          borderRadius: 1,
                          color: "text.primary",
                          ...helperTextStyles,
                        }}
                        placeholder="Your integration name..."
                        error={Boolean(errors.integrationName)}
                        helperText={
                          Boolean(errors.integrationName) && (
                            <ValidationMessage error={errors.integrationName} />
                          )
                        }
                      />
                    )}
                  />
                </Box>
              </Box>

              {/* step 2 */}
              <Box pb={4}>
                <Command
                  number={2}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  Enter the host URL
                </Command>
                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2, mt: 2 }}
                  >
                    Provide the URL for the SentinelOne account from which you
                    want to import data.
                  </Typography>
                  <Controller
                    name="host"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        sx={{
                          background: theme.palette.code.background,
                          borderRadius: 1,
                          color: "text.primary",
                          ...helperTextStyles,
                        }}
                        placeholder="https://my-target-installation.sentinelone.net/"
                        error={
                          getFieldState("host").isTouched &&
                          Boolean(errors.host)
                        }
                        helperText={
                          Boolean(errors.host) &&
                          getFieldState("host").isTouched && (
                            <ValidationMessage error={errors.host} />
                          )
                        }
                      />
                    )}
                  />
                </Box>
              </Box>

              {/* Step 3 */}
              <Box pb={4}>
                <Command
                  number={3}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  Create an API token in your SentinelOne account
                </Command>
                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 3, mt: 2 }}
                  >
                    Mondoo needs an API token to access SentinelOne data. You
                    can create one in the SentinelOne management console. To
                    learn more, read the{" "}
                    <Link
                      href="https://mondoo.com/docs/platform/infra/imports/sentinelone/"
                      target="_blank"
                      rel="noopener"
                    >
                      Mondoo documentation
                    </Link>
                    .
                  </Typography>

                  <Button
                    href={`${host}/settings/users/service-users`}
                    target="_blank"
                    variant="outlined"
                    color="secondary"
                    disabled={
                      !host ||
                      Boolean(errors.host) ||
                      !getFieldState("host").isDirty
                    }
                    endIcon={<OpenInNewIcon />}
                  >
                    GO TO PROVIDED ACCOUNT
                  </Button>
                </Box>
              </Box>

              {/* step 4 */}
              <Box pb={4}>
                <Command
                  number={4}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  Provide the SentinelOne API token
                </Command>
                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2, mt: 2 }}
                  >
                    Paste the API token you generated in the SentinelOne
                    console.
                  </Typography>
                  <Controller
                    name="clientSecret"
                    control={control}
                    rules={{
                      required: updateFlow ? false : true,
                    }}
                    render={({ field }) => (
                      <PasswordField
                        {...field}
                        fullWidth
                        sx={{
                          background: theme.palette.code.background,
                          borderRadius: 1,
                          color: "text.primary",
                          ...helperTextStyles,
                        }}
                        placeholder="e.g. 1234abcd5678efgh9012ijkl3456mnop"
                        error={
                          getFieldState("clientSecret").isTouched &&
                          Boolean(errors.clientSecret)
                        }
                        helperText={
                          Boolean(errors.clientSecret) &&
                          getFieldState("clientSecret").isTouched && (
                            <ValidationMessage error={errors.clientSecret} />
                          )
                        }
                      />
                    )}
                  />
                </Box>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  endIcon={<ChevronRightIcon />}
                  // setValue doesn't trigger the required validation so we're doing it manually here
                  disabled={updateFlow ? !isValid : !isValid || !clientSecret}
                >
                  {updateFlow ? "update configuration" : "create integration"}
                </Button>
              </Box>
            </form>
          </Box>
        </Fragment>
      ) : (
        <ConfigurePreferences
          {...{
            integrationType: IntegrationType.SentinelOne,
            preferencesForm,
            onFinalizeSetup,
          }}
        />
      )}
    </Fragment>
  );
}
