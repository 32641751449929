import { MouseEventHandler, useState } from "react";
import { Box, Divider, Tooltip, Typography, useTheme } from "@mui/material";
import * as d3 from "d3";
import {
  GetOrganizationOverviewQuery,
  useGetOrganizationOverviewQuery,
} from "~/operations";
import DashboardSkeleton from "~/pages/organization/dashboard/DashboardSkeleton";

type RawData = NonNullable<
  GetOrganizationOverviewQuery["organizationOverview"]
>["vulnerabilities"];

type Datum = NonNullable<RawData>[0];
type Data = Datum[];

export type TopVulnerabilitiesFoundProps = {
  organizationMrn: string;
};

export function TopVulnerabilitiesFound({
  organizationMrn,
}: TopVulnerabilitiesFoundProps) {
  const theme = useTheme();

  const result = useGetOrganizationOverviewQuery({
    variables: { input: { organizationMrn } },
  });

  const [tipOpen, setTipOpen] = useState(false);
  const [tipData, setTipData] = useState<Data[0] | null>(null);
  const [tipAnchorEl, setTipAnchorEl] = useState<null | SVGGElement>(null);

  if (result.loading) {
    return <DashboardSkeleton />;
  }

  const rawData = result.data?.organizationOverview?.vulnerabilities || [];

  if (!rawData?.length) {
    return (
      <Typography
        sx={{
          fontWeight: "bold",
          color: "text.secondary",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          height: "100%",
        }}
      >
        Mondoo didn't detect any new critical or high CVSS vulnerabilities in
        the past week
      </Typography>
    );
  }

  const data = parseData(rawData);

  const canvasWidth = 536;
  const canvasHeight = 112;
  const canvasPaddingTop = 8;
  const canvasPaddingBottom = 24;
  const canvasPaddingLeft = 64;
  const canvasPaddingRight = 24;

  const xDomain = data.map((d) => d.date);
  const xRange: [number, number] = [
    0 + canvasPaddingLeft,
    canvasWidth - canvasPaddingRight,
  ];
  const xScale = d3
    .scaleBand()
    .domain(xDomain)
    .range(xRange)
    .paddingInner(0.5)
    .paddingOuter(0.5);

  const yMin = 0;
  const yMax = d3.max(data, (d) => d.critical + d.high) || 0;
  const yDomain = [yMin, yMax];
  const yRange: [number, number] = [
    canvasHeight - canvasPaddingBottom,
    0 + canvasPaddingTop,
  ];
  const yScale = d3.scaleLinear().domain(yDomain).range(yRange);

  const statusDomain = ["low", "medium", "critical", "high", "error"];
  const statusRange = [
    theme.palette.low.main,
    theme.palette.medium.main,
    theme.palette.critical.main,
    theme.palette.high.main,
    theme.palette.error.main,
  ];
  const statusScale = d3
    .scaleOrdinal<string>()
    .domain(statusDomain)
    .range(statusRange);
  const statusLightRange = [
    theme.palette.low.light,
    theme.palette.medium.light,
    theme.palette.critical.light,
    theme.palette.high.light,
    theme.palette.error.light,
  ];
  const statusLightScale = d3
    .scaleOrdinal<string>()
    .domain(statusDomain)
    .range(statusLightRange);

  const xAxisTickValues = [
    xScale.domain().at(0),
    xScale.domain().at(Math.floor(xScale.domain().length / 4)),
    xScale.domain().at(Math.floor(xScale.domain().length / 2)),
    xScale
      .domain()
      .at(Math.floor(xScale.domain().length - xScale.domain().length / 4)),
    xScale.domain().at(xScale.domain().length - 1),
  ].flatMap((d) => d ?? []);

  const yAxisTickValues = [
    yScale.domain().at(0),
    Math.round(d3.median(yScale.domain()) || 0),
    yScale.domain().at(yScale.domain().length - 1),
  ].flatMap((d) => d ?? []);

  const totalCountByDay = data
    .filter((d) => !isEmptyDay(parseApiDate(d.date)!, rawData))
    .reduce(
      (acc, curr, i) => {
        const { __typename, date, ...statuses } = curr;
        acc[i] = d3.sum(Object.values(statuses));
        return acc;
      },
      [0],
    );
  const totalDayCount = d3.sum(totalCountByDay);
  const avgDayCount = d3.mean(totalCountByDay) || 0;

  const formatDateTick = d3.utcFormat("%b %_d");
  const formatDateTooltip = d3.utcFormat("%b %_d, %Y");

  let tipContent = <></>;
  if (tipData) {
    const { __typename, date, ...statuses } = tipData;
    const isEmpty = isEmptyDay(parseApiDate(date)!, rawData);
    tipContent = (
      <Box>
        <Typography fontSize={10} fontWeight="bold" textAlign="center">
          {formatDateTooltip(parseApiDate(date)!)}
        </Typography>
        <Divider sx={{ my: 1, borderColor: "rgba(0,0,0,0.3)" }} />
        <Box sx={{ display: "flex" }}>
          {Object.entries(statuses).map(([status, value], index) => (
            <Box
              key={status}
              sx={{
                ml: index === 0 ? 0 : 1,
                color: isEmpty ? "text.secondary" : statusLightScale(status),
              }}
            >
              <Typography fontSize={10} textTransform="uppercase">
                {status}
              </Typography>
              <Typography fontSize={10} fontWeight="bold">
                {isEmpty ? "-" : value}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        height: "100%",
        overflow: "hidden",
        display: "flex",

        ".chart-canvas": {
          display: "block",
          width: "536px",
          direction: "ltr",
        },

        ".chart-values": {
          ".chart-value rect": {
            transition: "fill-opacity 0.2s",
            fillOpacity: "0.5",
          },

          ".chart-value:nth-last-of-type(-1n+8) rect": {
            fillOpacity: "1",
          },

          "&:hover .chart-value rect": {
            fillOpacity: "0.3",
          },

          "&:hover .chart-value:hover rect": {
            fillOpacity: "1",
          },
        },

        ".chart-box": {
          width: "80%",
          overflow: "auto",
          direction: "rtl",
        },
        ".chart-summary": {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "end",
          textAlign: "right",
          ml: 1,
          fontSize: "100%",

          [theme.breakpoints.only("xs")]: {
            fontSize: "70%",
          },
          [theme.breakpoints.only("md")]: {
            fontSize: "70%",
          },

          ".summary-value": {
            fontSize: "2.5em",
            fontWeight: 800,
            lineHeight: "1.2em",
            whiteSpace: "nowrap",
          },
          ".summary-label": {
            fontSize: "1em",
            fontWeight: 800,
            lineHeight: "1.5em",
            whiteSpace: "nowrap",
          },
          ".summary-caption": {
            fontSize: "1em",
            color: "text.secondary",
            lineHeight: "1.5em",
            whiteSpace: "nowrap",
          },
        },
      }}
    >
      <Box className="chart-box">
        <svg
          className="chart-canvas"
          viewBox={`0 0 ${canvasWidth} ${canvasHeight}`}
        >
          <g className="chart-axis x">
            {xAxisTickValues.map((t, i) => (
              <g className="tick x" key={`${t}-${i}`}>
                <text
                  className="tick-label"
                  x={xScale(t)}
                  y={108}
                  width={50}
                  fontSize={12}
                  textAnchor="middle"
                  fill={theme.palette.text.primary}
                >
                  {formatDateTick(parseApiDate(t)!)}
                </text>
                <line
                  className="tick-line"
                  x1={(xScale(t) || 0) + xScale.bandwidth() / 2}
                  x2={(xScale(t) || 0) + xScale.bandwidth() / 2}
                  y1={yScale.range()[1]}
                  y2={yScale.range()[0]}
                  stroke={theme.palette.background.lighter}
                  strokeDasharray="2"
                  shapeRendering="crispEdges"
                />
              </g>
            ))}
          </g>
          <g className="chart-axis y">
            {yAxisTickValues.map((t, i) => (
              <g className="tick y" key={`${t}-${i}`}>
                <text
                  className="tick-label"
                  x={48}
                  y={yScale(t)}
                  fill={theme.palette.text.secondary}
                  textAnchor="end"
                  fontSize={12}
                  dominantBaseline="middle"
                >
                  {t}
                </text>
                <line
                  className="tick-line"
                  x1={56}
                  x2={xScale.range()[1] + 8}
                  y1={yScale(t)}
                  y2={yScale(t)}
                  stroke={theme.palette.background.lighter}
                  shapeRendering="crispEdges"
                />
              </g>
            ))}
          </g>
          <Tooltip
            open={tipOpen}
            title={tipContent}
            arrow
            placement="top"
            disableInteractive
            onOpen={() => setTipOpen(true)}
            onClose={() => setTipOpen(false)}
            PopperProps={{
              sx: {
                ".MuiTooltip-tooltip": {
                  p: 1,
                  fontSize: 14,
                  lineHeight: "24px",
                  fontWeight: (theme) => theme.typography.fontWeightRegular,
                },
              },
              anchorEl: tipAnchorEl,
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -12],
                  },
                },
              ],
            }}
          >
            <g className="chart-values">
              {data.map((d, i) => {
                const { __typename, date, ...statuses } = d;
                const stackGen = d3.stack().keys(["high", "critical"]);
                const stackedData = stackGen([statuses]);

                const handleBarMouseOver: MouseEventHandler<SVGGElement> = (
                  event,
                ) => {
                  setTipAnchorEl(event.currentTarget);
                  setTipData(d);
                };

                const maskId = `stack-mask-${crypto.randomUUID()}`;
                const lastStackedDatum = stackedData.at(-1);

                return (
                  <g key={d.date} className="chart-value">
                    <g
                      mask={`url(#${maskId})`}
                      onMouseOver={handleBarMouseOver}
                    >
                      {stackedData.map((stackedDatum) => {
                        const w = xScale.bandwidth();
                        const x = xScale(d.date);
                        const y = stackedDatum[0];
                        const y1 = yScale(y[1]) || 0;
                        const y2 = yScale(y[0]) || 0;
                        const h = y2 - y1;
                        const fill = isFutureDay(parseApiDate(d.date)!)
                          ? theme.palette.background.lightest
                          : statusScale(stackedDatum.key);
                        return (
                          <rect
                            x={x}
                            width={w}
                            y={y1}
                            height={h}
                            fill={fill}
                            key={stackedDatum.key}
                          />
                        );
                      })}
                      <rect
                        x={(xScale(d.date) || 0) - xScale.bandwidth() / 2}
                        width={xScale.bandwidth() * 2}
                        y={0}
                        height={yScale.range()[0]}
                        fill="transparent"
                        key="pill-hover"
                      />
                    </g>
                    <mask id={maskId}>
                      <rect
                        x={xScale(d.date)}
                        width={xScale.bandwidth()}
                        y={yScale(lastStackedDatum?.at(0)?.at(1) || 0) || 0}
                        height={
                          (yScale.range().at(0) || 0) -
                          (yScale(d3.sum(Object.values(statuses))) || 0)
                        }
                        fill="white"
                        rx={xScale.bandwidth() / 2}
                        key="pill-mask"
                      />
                      {stackedData.map((stackedDatum, stackedDatumIndex) => {
                        const w = xScale.bandwidth();
                        const x = xScale(d.date);
                        const y1 = yScale(stackedDatum[0][1]) || 0;
                        if (stackedDatumIndex === stackedData.length - 1)
                          return undefined;
                        return (
                          <rect
                            x={x}
                            width={w}
                            y={y1}
                            height={1}
                            fill="black"
                            key={stackedDatum.key}
                          />
                        );
                      })}
                    </mask>
                  </g>
                );
              })}
            </g>
          </Tooltip>
        </svg>
      </Box>
      <Box className="chart-summary">
        <Typography className="summary-value">{totalDayCount}</Typography>
        <Typography className="summary-label">
          Last{" "}
          {totalCountByDay.length > 1
            ? `${totalCountByDay.length} days`
            : "24 hours"}
        </Typography>
        <Typography className="summary-caption">
          Avg. {Math.floor(avgDayCount)} per day
        </Typography>
      </Box>
    </Box>
  );
}

const parseApiDate = d3.utcParse("%Y-%m-%d");
const formatApiDate = d3.utcFormat("%Y-%m-%d");

function parseData(rawData: RawData): Data {
  // From end of week + following sunday
  const end = d3.utcDay.offset(d3.utcWeek.ceil(d3.utcDay()), 1);
  // To 4 weeks prior
  const start = d3.utcDay.offset(end, -29);
  const timeRange = d3.utcDay.range(start, end);

  return timeRange.map((d) => {
    const currDatum = rawData?.find((ad) =>
      isEqualDay(d, parseApiDate(ad.date)!),
    );
    const lastDatum = rawData?.at(-1);
    const zeroDatum: Data[0] = {
      date: formatApiDate(d),
      critical: isFutureDay(d) ? lastDatum?.critical || 0 : 0,
      high: isFutureDay(d) ? lastDatum?.high || 0 : 0,
      __typename: "Vulnerabilities",
    };
    return currDatum || zeroDatum;
  });
}

function isEqualDay(a: Date, b: Date) {
  return +d3.utcDay.floor(a) === +d3.utcDay.floor(b);
}

function isFutureDay(date: Date) {
  const today = +d3.utcDay.floor(d3.utcDay());
  const other = +d3.utcDay.floor(date);
  return other > today;
}

function isEmptyDay(date: Date, data: RawData) {
  return !data?.some((ad) => isEqualDay(date, parseApiDate(ad.date)!));
}
