import { useState } from "react";
import { Space } from "~/lib/types";
import {
  alpha,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { ComplianceTable, Header } from "../compliance/compliance-table";
import { FormatDate, FormatRelativeDate } from "~/lib/date";
import { UseReportExports, useReports } from "./hooks/useReports";
import {
  DEFAULT_PAGE_SIZE,
  INITIAL_PAGE_RANGE,
  PAGE_SIZE_PARAM,
} from "~/components/pagination";
import { SelectionToolbar } from "~/components/data-table";
import { NoReports } from "./components/NoReports";
import {
  DeleteIcon,
  NavDrawerReportingIcon,
  SaveAltIcon,
} from "~/components/icons";
import { ContentFlag } from "~/components/ui-library";
import { DocumentStatus } from "~/operations";
import { LoadingFailed, LoadingPage } from "~/components/loading";
import { Flex } from "~/components/Flex";
import { AddButton } from "~/components/add-button";
import ReportTypesPicker from "~/pages/reporting/components/ReportTypesPicker";
import { AnimatePresence } from "framer-motion";
import { getDocumentTypeText } from "~/utils/generateReportUtils";
import { useSearchParams } from "react-router-dom";

type Props = {
  space: Space;
};

export function Reports({ space }: Props) {
  const [pageItems, setPageItems] = useState(INITIAL_PAGE_RANGE);
  const [openGenerateDialog, setOpenGenerateDialog] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageSizeParam = Number(searchParams.get(PAGE_SIZE_PARAM));

  const {
    selectedReports,
    handle,
    documentList: { loading, error, reports = [], data },
  } = useReports({
    space,
    onDeleteSucceed: () => {
      // We need this for delete bulk reports to reload the table
      setPageItems({ from: 0, to: pageSizeParam || DEFAULT_PAGE_SIZE });
    },
  });

  const paginatedReports = reports.slice(pageItems.from, pageItems.to);

  const tableHeaders: Header[] = [
    {
      id: "SELECT",
      label: (
        <Checkbox
          onChange={(_, checked) =>
            handle.selectAll(checked, pageItems.from, pageItems.to)
          }
          checked={
            selectedReports.length > 0 &&
            paginatedReports.length > 0 &&
            paginatedReports.every((report) =>
              selectedReports.includes(report.mrn),
            )
          }
          indeterminate={
            selectedReports.length > 0 &&
            paginatedReports.some((report) =>
              selectedReports.includes(report.mrn),
            ) &&
            !paginatedReports.every((report) =>
              selectedReports.includes(report.mrn),
            )
          }
        />
      ),
      sortable: false,
    },
    { id: "NAME", label: "Document name" },
    { id: "TYPE", label: "Report type" },
    { id: "CREATED_BY", label: "Created by" },
    { id: "CREATED_AT", label: "Date created" },
    { id: "ACTIONS", label: "", sortable: false },
  ];

  if (loading && !data) {
    return <LoadingPage what="reports" />;
  }

  if (error) {
    return <LoadingFailed what="reports" />;
  }

  return (
    <Grid container rowGap={5}>
      <Grid item xs={12}>
        <Breadcrumbs sx={{ mb: 0, overflowWrap: "anywhere" }} separator="›">
          <Flex sx={{ placeItems: "center" }} key={1}>
            <NavDrawerReportingIcon
              sx={{ mr: 1, color: (theme) => theme.palette.primary.light }}
            />
            Reporting
          </Flex>
        </Breadcrumbs>
      </Grid>

      <Grid
        item
        xs={12}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Typography
          variant="h4"
          fontWeight={700}
          sx={{ textTransform: "uppercase" }}
        >
          {openGenerateDialog ? "Generate Reports" : "Reporting"}
        </Typography>

        <AddButton
          id="generate-report"
          aria-label="Generate Reports"
          close={openGenerateDialog}
          onClick={() => setOpenGenerateDialog(!openGenerateDialog)}
        />
      </Grid>

      <AnimatePresence mode="wait">
        {openGenerateDialog && (
          <ReportTypesPicker
            space={space}
            onSuccess={() => setOpenGenerateDialog(false)}
          />
        )}

        {!openGenerateDialog && (
          <Grid item xs={12}>
            {data && !loading && !reports.length ? (
              <NoReports />
            ) : (
              <>
                <ComplianceTable
                  tableHeaders={tableHeaders}
                  selectable={true}
                  selection={selectedReports}
                  paginationProps={{
                    fetchMore: handle.fetchMore,
                    pageInfo: data?.pageInfo,
                    setPageItems,
                    totalCount: data?.totalCount || 0,
                    goToFirstPage: pageItems.from === 0,
                  }}
                >
                  {paginatedReports.map((report) => {
                    //Determine if the report is Queued or Running
                    const jobInProcess = [
                      DocumentStatus.Queued,
                      DocumentStatus.Running,
                    ].includes(report.status);

                    const jobFailure = report.status === DocumentStatus.Failed;
                    const requestedAt = `${FormatRelativeDate(
                      report.requestedAt,
                    )} - ${FormatDate(report.requestedAt)}`;

                    return (
                      <TableRow
                        key={report.mrn}
                        sx={{
                          // only display the icon buttons on hover
                          "& .action-button": {
                            opacity: 0,
                          },
                          "&:hover": {
                            "& .action-button": {
                              opacity: 1,
                            },
                          },
                          ...(jobInProcess && {
                            // if the job is in process, make the row grey and the text lighter
                            "& .MuiTableCell-root": {
                              color: "text.secondary",
                              opacity: 0.5,
                              cursor: "default",
                            },
                            // hide the checkbox when the job is in process
                            "& .select-checkbox": {
                              display: "none",
                            },
                            // if the job is in process, hide the delete and download buttons
                            "& .action-button": {
                              display: "none",
                            },
                          }),
                        }}
                      >
                        <TableCell>
                          <Checkbox
                            className="select-checkbox"
                            onChange={(_e, checked) =>
                              handle.selectionChange(checked, report.mrn)
                            }
                            checked={selectedReports.includes(report.mrn)}
                          />
                        </TableCell>
                        <TableCell>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            {report.name}
                            {jobInProcess && (
                              <CircularProgress
                                size={16}
                                sx={{ ml: 1, color: "text.primary" }}
                              />
                            )}
                            {jobFailure && <JobFailureFlag />}
                          </Box>
                        </TableCell>
                        <TableCell>
                          {getDocumentTypeText(report.type)}
                        </TableCell>
                        <TableCell>{report.createdBy?.name}</TableCell>
                        <TableCell
                          sx={{ fontSize: (theme) => theme.spacing(1.25) }}
                        >
                          {requestedAt}
                        </TableCell>
                        <TableCell align="right">
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                              gap: (theme) => theme.spacing(1),
                            }}
                          >
                            {/* If the download fails, we'll show a retry icon in place of the download icon */}
                            <Tooltip
                              title={jobFailure ? "Retry" : "Download"}
                              placement="top"
                              arrow
                            >
                              <span>
                                {!jobFailure && (
                                  <IconButton
                                    className="action-button"
                                    onClick={() =>
                                      handle.downloadSingle(report)
                                    }
                                  >
                                    <SaveAltIcon fontSize="small" />
                                  </IconButton>
                                )}
                              </span>
                            </Tooltip>

                            <Tooltip title="Delete" placement="top" arrow>
                              <span>
                                <IconButton
                                  className="action-button"
                                  onClick={() =>
                                    handle.deleteSingle(report.mrn)
                                  }
                                >
                                  <DeleteIcon fontSize="small" color="error" />
                                </IconButton>
                              </span>
                            </Tooltip>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </ComplianceTable>
                {selectedReports.length > 0 && (
                  <ReportSelectionToolbar
                    {...{
                      selectedReports,
                      handle,
                      reportsTotalCount: data?.totalCount,
                    }}
                  />
                )}
              </>
            )}
          </Grid>
        )}
      </AnimatePresence>
    </Grid>
  );
}

type ReportSelectionToolbarProps = {
  selectedReports: UseReportExports["selectedReports"];
  handle: UseReportExports["handle"];
  reportsTotalCount?: number;
};

const ReportSelectionToolbar = ({
  selectedReports,
  handle,
  reportsTotalCount,
}: ReportSelectionToolbarProps) => {
  return (
    <SelectionToolbar>
      <Typography>
        Selected {selectedReports.length} of {reportsTotalCount} reports
      </Typography>

      <Button
        variant="contained"
        onClick={handle.delete}
        startIcon={<DeleteIcon />}
        sx={{
          background: (theme) => alpha(theme.palette.error.main, 0.5),
          "&:hover": {
            background: (theme) => alpha(theme.palette.error.main, 0.7),
          },
        }}
      >
        Delete
      </Button>
      <Button onClick={handle.cancel}>Cancel</Button>
    </SelectionToolbar>
  );
};

const JobFailureFlag = () => {
  return (
    <Tooltip
      title="Mondoo couldn't generate this report. Please try again."
      placement="top"
      arrow
      PopperProps={{
        sx: {
          ".MuiTooltip-tooltip": {
            maxWidth: 220,
            textAlign: "center",
          },
        },
      }}
    >
      <span>
        <ContentFlag flag="error" color="error" layout={{ ml: 1 }} />
      </span>
    </Tooltip>
  );
};
