import { Box, Divider } from "@mui/material";
import { Flex } from "~/components/Flex";
import { TextTitle } from "~/components/ui-library";

type SectionHeadingProps = {
  heading: string;
  dividerContent?: string | JSX.Element;
};

export const SectionHeading = ({
  heading,
  dividerContent,
}: SectionHeadingProps) => {
  return (
    <Flex center gap={3} my={4} className="section-header">
      <TextTitle bold sx={{ whiteSpace: "nowrap", flexShrink: 0 }}>
        {heading}
      </TextTitle>
      <Box width={1}>
        <Divider
          textAlign="right"
          sx={{ "&::before": { width: "100%" }, "&::after": { width: 0 } }}
        >
          {dividerContent}
        </Divider>
      </Box>
    </Flex>
  );
};
