import { useSearchParams } from "react-router-dom";
import { useJobs } from "~/providers/jobs";
import { Space } from "~/lib/types";
import { LoadingPage } from "~/components/loading";
import { AssetPage } from "~/pages/inventory/asset";
import { setDocumentTitle } from "~/utils/commonUtils";

type Props = {
  space: Space;
};

export function Job({ space }: Props) {
  const { cicdProjectJobs } = useJobs();
  const [searchParams] = useSearchParams();

  if (!cicdProjectJobs) return <LoadingPage what="Project Jobs" />;

  const { project } = cicdProjectJobs;

  setDocumentTitle(["Job", project.name, "CI/CD"]);

  return (
    <AssetPage
      space={space}
      scope={space}
      isCicd={true}
      project={project}
      assetMrn={searchParams.get("jobId") || undefined}
    />
  );
}
