import { useEffect } from "react";
import { Grid, Grid2, Link, Typography } from "@mui/material";
import { useFindingRiskFactors } from "~/pages/space/security/components/RiskFactors/hooks/useFindingRiskFactors";
import {
  AggregateScoreOrderField,
  AggregateScoreType,
  FindingType,
  NotFoundError,
  OrderDirection,
  RequestError,
  ScoreStateFilter,
  useGetSpaceSoftwareQuery,
} from "~/operations";
import { Space } from "~/lib/types";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { StatsProps } from "~/components/DetailsPage/components/Stats/Stats";
import { FlagOutlined, Radar } from "@mui/icons-material";
import { HomeIcon, PackageIcon } from "~/components/icons";
import { MessageSeverity, WarningMessage } from "~/components/user-warning";
import { DynamicIcon } from "~/components/icons/DynamicIcon";
import { SoftwareRiskFactors } from "~/pages/space/software/Software/components/SoftwareRiskFactors";
import { SoftwareVersions } from "~/pages/space/software/Software/components/SoftwareVersions";
import { LoadingPage } from "~/components/loading";
import { VersionsIcon } from "~/components/icons/mondoo/versions";
import { pluralize } from "~/lib/pluralize";
import { trimSoftwareMrnVersion } from "~/pages/space/software/Software/utils";
import { Flex } from "~/components/Flex";
import { SoftwareAdvisories } from "./components/SoftwareAdvisories";
import { SoftwareVulnerabilities } from "./components/SoftwareVulnerabilities";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";
import { DetailPageTopSection } from "~/components/DetailPageLayouts/DetailPageTopSection";
import { ScoreBlock } from "../../security/components/Check/ScoreBlock";
import {
  AffectedAssetsAdapter,
  AssetContextualLinkType,
} from "~/pages/space/vulnerabilities/components/AffectedAssets";
import { InventoryPageHeader, InventoryPageLayout } from "~/routes";

type SoftwareProps = { space: Space; scope: SpaceOrWorkspaceScope };

export const Software = ({ space, scope }: SoftwareProps) => {
  const [searchParams] = useSearchParams();
  const genericFindingMrn = searchParams.get("genericFindingMrn") || "";
  const versionedFindingMrn = searchParams.get("versionedFindingMrn") || "";
  const isVersionedSoftware = versionedFindingMrn.length > 0;

  if (!genericFindingMrn && !versionedFindingMrn) {
    return (
      <Grid item xs={12}>
        <WarningMessage
          severity={MessageSeverity.Error}
          msg="No Software MRN provided!"
        />
      </Grid>
    );
  }

  const {
    data: versionedSoftwareDetailsData,
    loading: versionedSoftwareDetailsLoading,
    error: versionedSoftwareAggDataError,
  } = useGetSpaceSoftwareQuery({
    variables: {
      entityMrn: scope.mrn || "",
      orderBy: {
        field: AggregateScoreOrderField.Rank,
        direction: OrderDirection.Asc,
      },
      filter: {
        softwareFindingMrn: versionedFindingMrn || genericFindingMrn,
        scoreType: AggregateScoreType.VersionedSoftware,
      },
    },
    skip: !scope.mrn,
  });

  const {
    data: genericSoftwareDetailsData,
    loading: genericSoftwareDetailsLoading,
    error: genericSoftwareAggDataError,
  } = useGetSpaceSoftwareQuery({
    variables: {
      entityMrn: scope.mrn || "",
      orderBy: {
        field: AggregateScoreOrderField.Rank,
        direction: OrderDirection.Asc,
      },
      filter: {
        findingMrn: genericFindingMrn,
        scoreType: AggregateScoreType.Software,
      },
    },
    skip: Boolean(versionedFindingMrn) || !scope.mrn,
  });

  const {
    riskFactorsWithDocs,
    riskFactors,
    loading: riskFactorsLoading,
  } = useFindingRiskFactors({
    spaceMrn: space.mrn,
    findingMrn: versionedFindingMrn || genericFindingMrn,
    scoreType: isVersionedSoftware
      ? AggregateScoreType.VersionedSoftware
      : AggregateScoreType.Software,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [genericFindingMrn, versionedFindingMrn]);

  const genericSoftwareNotFoundError =
    genericSoftwareDetailsData?.aggregateScores?.__typename === "NotFoundError"
      ? genericSoftwareDetailsData?.aggregateScores
      : undefined;

  const genericSoftwareRequestError =
    genericSoftwareDetailsData?.aggregateScores?.__typename === "RequestError"
      ? genericSoftwareDetailsData?.aggregateScores
      : undefined;

  const versionedSoftwareNotFoundError =
    versionedSoftwareDetailsData?.aggregateScores?.__typename ===
    "NotFoundError"
      ? versionedSoftwareDetailsData?.aggregateScores
      : undefined;

  const versionedSoftwareRequestError =
    versionedSoftwareDetailsData?.aggregateScores?.__typename === "RequestError"
      ? versionedSoftwareDetailsData?.aggregateScores
      : undefined;

  const loading =
    genericSoftwareDetailsLoading ||
    versionedSoftwareDetailsLoading ||
    riskFactorsLoading;

  if (loading) {
    return <LoadingPage what="Software" />;
  }

  if (
    genericSoftwareRequestError ||
    versionedSoftwareRequestError ||
    versionedSoftwareAggDataError ||
    genericSoftwareAggDataError
  ) {
    const error = genericSoftwareRequestError || versionedSoftwareRequestError;
    return (
      <Grid item xs={12}>
        <WarningMessage
          severity={MessageSeverity.Error}
          msg={(error as RequestError).message}
        />
      </Grid>
    );
  }

  if (versionedSoftwareNotFoundError || genericSoftwareNotFoundError) {
    const error =
      versionedSoftwareNotFoundError || genericSoftwareNotFoundError;
    return (
      <Grid item xs={12}>
        <WarningMessage
          severity={MessageSeverity.Error}
          msg={(error as NotFoundError).message}
        />
      </Grid>
    );
  }

  const versionedSoftwareScores =
    versionedSoftwareDetailsData?.aggregateScores?.__typename ===
    "AggregateScoresConnection"
      ? versionedSoftwareDetailsData.aggregateScores
      : undefined;

  const genericSoftwareScores =
    genericSoftwareDetailsData?.aggregateScores?.__typename ===
    "AggregateScoresConnection"
      ? genericSoftwareDetailsData.aggregateScores
      : undefined;

  const softwareEdges = isVersionedSoftware
    ? versionedSoftwareScores?.edges
    : genericSoftwareScores?.edges;
  const software = softwareEdges?.[0]?.node;

  const findingMrn = isVersionedSoftware
    ? versionedFindingMrn
    : genericFindingMrn;

  const version = isVersionedSoftware
    ? versionedFindingMrn.split("/").pop()
    : null;

  const stats: StatsProps["stats"] =
    [
      {
        label: "Scanned",
        value: software?.versionDistribution?.assets?.toString() || "---",
        icon: <Radar fontSize="inherit" />,
      },
      {
        label: "Installs",
        value:
          software?.versionDistribution?.installations?.toString() || "---",
        icon: <FlagOutlined fontSize="inherit" />,
        onClick: () => {
          document
            .querySelector(`#software-assets`)
            ?.scrollIntoView({ behavior: "smooth" });
        },
      },
      !isVersionedSoftware
        ? {
            label: pluralize(
              "Version",
              genericSoftwareScores?.edges?.[0]?.node?.versionDistribution
                ?.distribution?.length || 0,
            ),
            value:
              genericSoftwareScores?.edges?.[0]?.node?.versionDistribution?.distribution?.length?.toString() ||
              "---",
            icon: <VersionsIcon fontSize="inherit" />,
            onClick: () => {
              document
                .querySelector(`#software-versions`)
                ?.scrollIntoView({ behavior: "smooth" });
            },
          }
        : null,
    ].flatMap((e) => e ?? []) || [];

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?${scope.params}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Link
      key="/space/software"
      component={RouterLink}
      to={`/space/inventory/software?${scope.params}`}
      display="flex"
    >
      Software
    </Link>,
    isVersionedSoftware && (
      <Flex gap={1}>
        <DynamicIcon iconId={software?.iconId} defaultIcon={PackageIcon} />
        <Link
          key="/space/software"
          component={RouterLink}
          to={`/space/inventory/software/${software?.title}?${scope.params}&genericFindingMrn=${trimSoftwareMrnVersion(versionedFindingMrn)}`}
          display="flex"
        >
          {trimSoftwareMrnVersion(versionedFindingMrn).split("/").pop()}
        </Link>
      </Flex>
    ),
    <Typography key="/space/vulnerabilities">{software?.title}</Typography>,
  ];

  if (!software) {
    return (
      <Grid2 size={{ xs: 12 }}>
        <WarningMessage
          severity={MessageSeverity.Error}
          msg={"Software Not Found!"}
        />
      </Grid2>
    );
  }

  return (
    <>
      <DetailPageTopSection
        content={{
          breadcrumbs: breadcrumbs,
          triageBar: {
            title: software?.title || "",
            linkBack: `/space/inventory/software?${scope.params}`,
            titleIcon: (
              <DynamicIcon
                iconId={software?.iconId}
                defaultIcon={PackageIcon}
                size="40px"
              />
            ),
            riskFactors,
            isLoading: loading,
            subheaders: [
              {
                id: "created",
                label: "Created",
                value: software?.firstDetectedAt,
              },
              {
                id: "lastModified",
                label: "Last Modified",
                value: software?.lastScannedAt,
              },
            ],
          },
          stats: stats,
          summary: software.description
            ? software.description
            : "There is no description available for this package.",
          right: (
            <ScoreBlock
              mainScore={software?.riskValue}
              epssScore={software?.epss}
              cvssScore={software?.cvss}
              riskFactors={software?.riskFactors}
              rating={software?.rating}
              blastRadius={software?.blastRadius}
              hasScore={Boolean(software)}
              hasError={false}
            />
          ),
        }}
      />
      <InventoryPageLayout>
        {riskFactorsWithDocs.length > 0 && (
          <SoftwareRiskFactors riskFactorsWithDocs={riskFactorsWithDocs} />
        )}

        {!isVersionedSoftware && (
          <SoftwareVersions
            space={space}
            scope={scope}
            loading={versionedSoftwareDetailsLoading}
            edges={versionedSoftwareScores?.edges}
            riskFactors={riskFactors}
            findingMrn={genericFindingMrn}
            versionDistribution={
              isVersionedSoftware
                ? versionedSoftwareScores?.edges?.[0]?.node?.versionDistribution
                : genericSoftwareScores?.edges?.[0]?.node?.versionDistribution
            }
            showVersionDistribution={!isVersionedSoftware}
          />
        )}

        <AffectedAssetsAdapter
          scope={scope}
          contextId={software?.title}
          urlContextType={AssetContextualLinkType.Software}
          filter={{
            types: [FindingType.Package],
            state: ScoreStateFilter.Open,
            mrn: isVersionedSoftware ? versionedFindingMrn : genericFindingMrn,
          }}
          emptyStateMessage="There are currently no exposed assets for this package."
        />

        {/*<SoftwareAssets
        scope={scope}
        findingMrn={
          isVersionedSoftware ? versionedFindingMrn : genericFindingMrn
        }
        version={
          isVersionedSoftware ? versionedFindingMrn.split("/").pop() : null
        }
        riskFactors={riskFactors}
        softwareTitle={software?.title}
      />*/}

        {isVersionedSoftware && (
          <SoftwareVulnerabilities
            space={space}
            scope={scope}
            findingMrn={versionedFindingMrn || genericFindingMrn}
          />
        )}

        {isVersionedSoftware && (
          <SoftwareAdvisories
            space={space}
            scope={scope}
            findingMrn={versionedFindingMrn || genericFindingMrn}
          />
        )}
      </InventoryPageLayout>
    </>
  );
};
