import { Box, Button } from "@mui/material";

type RiskFactorsActionRowProps = {
  isDefaults: boolean;
  onResetToDefault?: () => void;
  isValid: boolean;
  isDirty: boolean;
  onCancel: () => void;
};

export const RiskFactorsActionRow = ({
  isDefaults,
  onResetToDefault,
  isValid,
  isDirty,
  onCancel,
}: RiskFactorsActionRowProps) => {
  return (
    <Box
      className="risk-factors-config-form-actions"
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        gap: 2,
        position: "sticky",
        top: 64,
        py: 2,
        bgcolor: "background.paper",
        zIndex: 1,
      }}
    >
      {onResetToDefault && (
        <Button variant="text" disabled={isDefaults} onClick={onResetToDefault}>
          Reset to defaults
        </Button>
      )}
      <Button
        variant="outlined"
        color="primary"
        disabled={!isValid || !isDirty}
        onClick={onCancel}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={!isValid || !isDirty}
      >
        Save Changes
      </Button>
    </Box>
  );
};
