import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid2,
  InputAdornment,
  Link,
  Slider,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { Fragment, useState } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import {
  AssetFieldFiltersMutation,
  GetSpaceRiskFactorsDocument,
  GetSpaceRiskFactorsQuery,
  KeyValueInput,
  RiskFactorAction,
  useModifySpaceRiskFactorsMutation,
} from "~/operations";
import { useSpaceSettingsOutletContext } from "../../space-settings";
import { RiskFactorsActionRow } from "./RiskFactorsActionRow";
import { RiskFactorTagEditor } from "./RiskFactorTagEditor";
import { get } from "lodash";
import { getRiskFactorIcon } from "~/pages/space/security/components/RiskFactors/utils";

export const formatMagnitude = new Intl.NumberFormat("en-US", {
  signDisplay: "exceptZero",
}).format;

export const idFromMrn = (mrn: string) =>
  mrn.split("//policy.api.mondoo.app").pop() || "";

export const fieldId = (mrn: string) => {
  const id = idFromMrn(mrn);
  return id.replaceAll(".", "");
};

const range = (start: number, stop: number, step = 1) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

const min = -100;
const max = 100;
const marks = range(min, max, 20).map((value) => ({ label: "", value }));

export enum RiskFactorConditionKeyValueField {
  /** Annotations field */
  Annotations = "ANNOTATIONS",
  /** Labels field */
  Labels = "LABELS",

  Unknown = "UNKNOWN",
}

export enum RiskFactorConditionKeyValueOperator {
  /** Contains operator */
  Contains = "CONTAINS",
  Unknown = "UNKNOWN",
}

export type RiskFactorKeyValueConditionInput = {
  /** Field to match */
  field: RiskFactorConditionKeyValueField;
  /** Operator to use */
  operator: RiskFactorConditionKeyValueOperator;
  /** Values to match. Values are ORed together */
  values: Array<KeyValueInput>;
};

export enum RiskFactorConditionOperator {
  Or = "OR",
  Unknown = "UNKNOWN",
}

export type ConditionType = {
  operator: RiskFactorConditionOperator;
  keyValueCondition?: RiskFactorKeyValueConditionInput;
  // formKey and formValues fields are temporary form fields that will be used later when submitting form to case values
  // either to intCondition or stringCondition objects
  formKey: RiskFactorConditionKeyValueField | "";
};

export type SelectionType = {
  conditions: Array<ConditionType>;
};

export const getInitialCondition = (): ConditionType => {
  return {
    formKey: "",
    operator: RiskFactorConditionOperator.Or,
    keyValueCondition: {
      field: RiskFactorConditionKeyValueField.Unknown,
      operator: RiskFactorConditionKeyValueOperator.Contains,
      values: [],
    },
  };
};

export type RiskFactorItemInput = {
  enabled: boolean;
  magnitude: number;
  rawMagnitude: string;
  isToxic: boolean;
  selections?: Array<SelectionType>;
};

export type RiskFactorsConfigurationInput = {
  [id: string]: RiskFactorItemInput;
};

export type RiskFactorItems = NonNullable<
  GetSpaceRiskFactorsQuery["riskFactors"]["edges"]
>;
export type RiskFactorItem = RiskFactorItems[0];

export type RiskFactorsConfigurationFormProps = {
  riskFactors: RiskFactorItems;
  defaultValues: RiskFactorsConfigurationInput;
};

export function RiskFactorsConfigurationForm({
  riskFactors,
  defaultValues,
}: RiskFactorsConfigurationFormProps) {
  const { space } = useSpaceSettingsOutletContext();
  const spaceMrn = space.mrn;
  const { enqueueSnackbar } = useSnackbar();
  const [showAdvanced, setShowAdvanced] = useState(false);

  const [modifySpaceRiskFactors] = useModifySpaceRiskFactorsMutation({
    refetchQueries: [GetSpaceRiskFactorsDocument],
  });

  const form = useForm<RiskFactorsConfigurationInput>({
    mode: "onBlur",
    defaultValues,
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors, isValid, isDirty, dirtyFields },
  } = form;

  const isFieldDirty = (fieldId: string): boolean => {
    const dirtyField = get(dirtyFields, fieldId);
    return deepSome(dirtyField, (v) => v === true);
  };

  const getDirtyFields = (): typeof dirtyFields => {
    return deepFilter(dirtyFields, (value) => value !== false);
  };

  // Disable "Reset to Defaults" button if every field matches the Mondoo defaults
  const isDefaults = riskFactors.every((rf) => {
    const { mrn } = rf;
    const { magnitude, isToxic, enabled } = getValues(`${fieldId(mrn)}`);
    const defaultEnabled = true;
    const defaultMagnitude = rf.defaultMagnitude.value;
    const defaultIsToxic = rf.defaultMagnitude.isToxic;
    return (
      enabled === defaultEnabled &&
      magnitude === defaultMagnitude &&
      isToxic === defaultIsToxic
    );
  });

  const onSubmit: SubmitHandler<RiskFactorsConfigurationInput> = async () => {
    try {
      const riskFactors = Object.entries(getDirtyFields()).map(
        ([id, field]) => {
          const action = field?.enabled
            ? getValues(`${id}.enabled`)
              ? RiskFactorAction.Enable
              : RiskFactorAction.Disable
            : undefined;
          const value = field?.magnitude
            ? getValues(`${id}.magnitude`)
            : undefined;
          const isToxic = field?.isToxic
            ? getValues(`${id}.isToxic`)
            : undefined;
          const magnitude =
            value !== undefined || isToxic !== undefined
              ? {
                  value: getValues(`${id}.magnitude`),
                  isToxic: getValues(`${id}.isToxic`),
                }
              : undefined;

          const fieldSelection = field?.selections?.at(0);
          const selection = fieldSelection
            ? getValues(`${id}.selections.0`)
            : undefined;
          const labelConditons =
            selection?.conditions.filter(
              (c) => c.formKey === RiskFactorConditionKeyValueField.Labels,
            ) || [];
          const annotationConditons =
            selection?.conditions.filter(
              (c) => c.formKey === RiskFactorConditionKeyValueField.Annotations,
            ) || [];
          const assetFieldFiltersMutation: AssetFieldFiltersMutation | null =
            selection
              ? {
                  labelBasedFilters: labelConditons?.flatMap(
                    (c) =>
                      c.keyValueCondition?.values.flatMap((v) => v ?? []) ?? [],
                  ),
                  annotationBasedFilters: annotationConditons?.flatMap(
                    (c) =>
                      c.keyValueCondition?.values.flatMap((v) => v ?? []) ?? [],
                  ),
                }
              : null;
          return {
            mrn: "//policy.api.mondoo.app" + id,
            ...(action !== undefined && { action }),
            ...(magnitude !== undefined && { magnitude }),
            ...(assetFieldFiltersMutation && { assetFieldFiltersMutation }),
          };
        },
      );
      await modifySpaceRiskFactors({ variables: { spaceMrn, riskFactors } });
      reset(getValues());
      enqueueSnackbar("Successfully updated configuration", {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar("Failed to update configuration", { variant: "error" });
    }
  };

  const onCancel = () => {
    reset();
  };

  const onResetToDefault = () => {
    riskFactors.forEach((rf) => {
      const { mrn } = rf;
      const { magnitude, isToxic, enabled } = getValues(`${fieldId(mrn)}`);
      const defaultEnabled = true;
      const defaultMagnitude = rf.defaultMagnitude.value;
      const defaultIsToxic = rf.defaultMagnitude.isToxic;
      if (enabled !== defaultEnabled) {
        setValue(`${fieldId(mrn)}.enabled`, defaultEnabled, {
          shouldDirty: true,
        });
      }
      if (magnitude !== defaultMagnitude) {
        setValue(`${fieldId(mrn)}.magnitude`, defaultMagnitude, {
          shouldDirty: true,
        });
        setValue(
          `${fieldId(mrn)}.rawMagnitude`,
          formatMagnitude(defaultMagnitude),
          { shouldDirty: true },
        );
      }
      if (isToxic !== defaultIsToxic) {
        setValue(`${fieldId(mrn)}.isToxic`, defaultIsToxic, {
          shouldDirty: true,
        });
      }
    });
  };

  return (
    <FormProvider {...form}>
      <Box sx={{ p: 2 }}>
        <Grid2 container spacing={3} className="risk-factors-config-header">
          <Grid2 size="grow" className="risk-factors-config-description">
            <Typography>
              Mondoo considers multiple risk factors when evaluating the
              security of your assets. To customize per your business's security
              priorities, you can choose which risk factors affect your assets'
              scores. For even finer control, select SHOW ADVANCED SETTINGS. To
              learn more, read the{" "}
              <Link
                href={
                  "https://mondoo.com/docs/platform/security/customize/risk/"
                }
                target="_blank"
                rel="noopener"
              >
                Mondoo documentation
              </Link>
              .
            </Typography>
          </Grid2>
          <Grid2 size="auto" className="risk-factors-config-actions">
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setShowAdvanced(!showAdvanced)}
            >
              {showAdvanced ? "Hide" : "Show"} Advanced Settings
            </Button>
          </Grid2>
        </Grid2>
        <Box className="risk-factors-config-content">
          {
            <Box
              className="risk-factors-config-form"
              component="form"
              onSubmit={handleSubmit(onSubmit)}
            >
              {(showAdvanced || isDirty) && (
                <RiskFactorsActionRow
                  {...{
                    isDefaults,
                    isValid,
                    isDirty,
                    onCancel,
                    ...(showAdvanced && { onResetToDefault }),
                  }}
                />
              )}
              <Divider sx={{ py: 1 }} />
              <Box className="risk-factors-config-form-content" sx={{ pt: 2 }}>
                {/* When its time to group the risk factors, use the <TitleAccordion /> Component */}
                {riskFactors.map((rf) => (
                  <Box
                    key={rf.mrn}
                    className="risk-factor-config-item"
                    sx={{
                      // display: "flex",
                      // alignItems: "flex-start",
                      // gap: 2,
                      mb: 3,
                      borderLeft: "2px solid",
                      borderColor: isFieldDirty(fieldId(rf.mrn))
                        ? "primary.main"
                        : "transparent",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        gap: 2,
                        mb: 2,
                      }}
                    >
                      <Box
                        className="risk-factor-config-item-toggle"
                        sx={{ display: "flex" }}
                      >
                        <Controller
                          name={`${fieldId(rf.mrn)}.enabled`}
                          control={control}
                          render={({ field }) => (
                            <Switch checked={field.value} {...field} />
                          )}
                        />
                      </Box>
                      <Box
                        className="risk-factor-config-item-info"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 0.5,
                        }}
                      >
                        <Box
                          className="risk-factor-config-item-header"
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          {getRiskFactorIcon({ indicator: rf.indicator })}
                          <Typography
                            fontWeight={700}
                            color={
                              getValues(`${fieldId(rf.mrn)}.enabled`)
                                ? "text.primary"
                                : "text.secondary"
                            }
                          >
                            {rf.title}
                          </Typography>
                          {/* TODO: Risk Factor asset stats */}
                          {/* <Typography
                      fontWeight={700}
                      fontSize={14}
                      color="text.secondary"
                    >
                      1109 Assets
                    </Typography> */}
                        </Box>
                        <Box
                          className="risk-factor-config-item-description"
                          sx={{ flex: 1 }}
                        >
                          <Typography color="text.secondary" fontSize={14}>
                            {getValues(`${fieldId(rf.mrn)}.enabled`)
                              ? rf.docs?.active ||
                                "Tags, labels, and annotations on these assets raise their priority."
                              : rf.docs?.inactive ||
                                "Tags, labels, and annotations on these assets raise their priority."}
                          </Typography>
                        </Box>
                      </Box>
                      {showAdvanced && (
                        <Fragment>
                          {getValues(`${fieldId(rf.mrn)}.enabled`) && (
                            <Box
                              className="risk-factor-config-item-adjuster"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 2,
                                ml: "auto",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 2,
                                }}
                              >
                                <Typography
                                  fontSize={14}
                                  sx={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setValue(
                                      `${fieldId(rf.mrn)}.magnitude`,
                                      min,
                                      {
                                        shouldDirty: true,
                                      },
                                    );
                                    setValue(
                                      `${fieldId(rf.mrn)}.rawMagnitude`,
                                      formatMagnitude(min),
                                      {
                                        shouldDirty: true,
                                      },
                                    );
                                  }}
                                >
                                  {formatMagnitude(min)}%
                                </Typography>
                                <Controller
                                  name={`${fieldId(rf.mrn)}.magnitude`}
                                  control={control}
                                  render={({ field }) => (
                                    <Slider
                                      {...field}
                                      min={min}
                                      max={max}
                                      marks={marks}
                                      sx={{ width: "226px" }}
                                      onChange={(e, v) => {
                                        field.onChange(e, v);
                                        setValue(
                                          `${fieldId(rf.mrn)}.rawMagnitude`,
                                          formatMagnitude(v as number),
                                        );
                                      }}
                                    />
                                  )}
                                />
                                <Typography
                                  fontSize={14}
                                  sx={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setValue(
                                      `${fieldId(rf.mrn)}.magnitude`,
                                      max,
                                      {
                                        shouldDirty: true,
                                      },
                                    );
                                    setValue(
                                      `${fieldId(rf.mrn)}.rawMagnitude`,
                                      formatMagnitude(max),
                                      {
                                        shouldDirty: true,
                                      },
                                    );
                                  }}
                                >
                                  {formatMagnitude(max)}%
                                </Typography>
                              </Box>
                              <Controller
                                name={`${fieldId(rf.mrn)}.rawMagnitude`}
                                control={control}
                                rules={{
                                  required: "Required",
                                  validate: {
                                    numberWithinRange: (v) => {
                                      const n = parseInt(v);
                                      const isNumber = !isNaN(n);
                                      const numberWithinRange =
                                        isNumber && n >= min && n <= max;
                                      return (
                                        numberWithinRange ||
                                        `Must be a number between ${min} and ${max}`
                                      );
                                    },
                                  },
                                }}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    size="small"
                                    sx={{ width: "80px" }}
                                    InputProps={{
                                      sx: {
                                        "&, .MuiTypography-root": {
                                          fontSize: 14,
                                        },
                                      },
                                      endAdornment: (
                                        <InputAdornment
                                          position="end"
                                          children="%"
                                        />
                                      ),
                                    }}
                                    onChange={(e) => {
                                      field.onChange(e);
                                      const magnitude = parseInt(
                                        e.target.value,
                                      );
                                      if (!isNaN(magnitude)) {
                                        setValue(
                                          `${fieldId(rf.mrn)}.magnitude`,
                                          magnitude,
                                          { shouldDirty: true },
                                        );
                                      }
                                    }}
                                    error={Boolean(
                                      errors[`${fieldId(rf.mrn)}`]
                                        ?.rawMagnitude,
                                    )}
                                  />
                                )}
                              />

                              <FormControlLabel
                                control={
                                  <Controller
                                    name={`${fieldId(rf.mrn)}.isToxic`}
                                    control={control}
                                    render={({ field }) => (
                                      <Checkbox
                                        checked={field.value}
                                        {...field}
                                        size="small"
                                      />
                                    )}
                                  />
                                }
                                label="Critical risk"
                                sx={{
                                  mr: 0,
                                  ".MuiFormControlLabel-label": {
                                    fontSize: 14,
                                    whiteSpace: "nowrap",
                                  },
                                }}
                              />
                            </Box>
                          )}
                        </Fragment>
                      )}
                    </Box>
                    {rf.mrn.endsWith("-priority") && (
                      <Box className="risk-factor-config-tags" sx={{ pl: 9 }}>
                        <Typography sx={{ fontWeight: 700, mb: 1 }}>
                          Define priority tags, labels, and annotations
                        </Typography>
                        <RiskFactorTagEditor
                          riskFactor={rf}
                          fieldId={fieldId(rf.mrn)}
                        />
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
          }
        </Box>
      </Box>
    </FormProvider>
  );
}

function deepSome(obj: any, callback: (obj: any) => boolean) {
  if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      if (deepSome(obj[i], callback)) {
        return true;
      }
    }
  } else if (typeof obj === "object" && obj !== null) {
    for (const key in obj) {
      if (deepSome(obj[key], callback)) {
        return true;
      }
    }
  } else {
    return callback(obj);
  }
  return false;
}

function deepFilter(obj: any, callback: (value: any) => boolean) {
  if (Array.isArray(obj)) {
    const filteredArray = obj.reduce<any[]>((acc, item) => {
      const result = deepFilter(item, callback);
      if (result !== undefined) {
        acc.push(result);
      }
      return acc;
    }, []);
    return filteredArray.length > 0 ? filteredArray : undefined;
  } else if (typeof obj === "object" && obj !== null) {
    const filteredObject = Object.keys(obj).reduce<{ [k: string]: any }>(
      (acc, key) => {
        const result = deepFilter(obj[key], callback);
        if (result !== undefined) {
          acc[key] = result;
        }
        return acc;
      },
      {},
    );
    return Object.keys(filteredObject).length > 0 ? filteredObject : undefined;
  } else {
    return callback(obj) ? obj : undefined;
  }
}
