import { Fragment, ReactNode, useState } from "react";
import {
  Link as RouterLink,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
  Alert,
  AlertTitle,
} from "@mui/material";
import { capitalizeFirstLetter } from "~/lib/helpers";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import { NestedServiceAccountsSection } from "../components/integration-nested-service-accounts-section";
import { ContentFlag } from "~/components/content-flag";
import { Markdown } from "~/components/markdown";
import {
  AccessTimeIcon,
  ArrowForwardIcon,
  HomeIcon,
  PersonIcon,
} from "~/components/icons";
import { Space } from "~/lib/types";
import {
  ClientIntegrationType,
  GetClientIntegrationQuery,
  GetIntegrationsSummaryDocument,
  IntegrationMessageStatus,
  ListClientIntegrationsDocument,
  useDeleteClientIntegrationMutation,
  useGetClientIntegrationDeleteCommandQuery,
  useGetClientIntegrationQuery,
} from "~/operations";
import { pluralize } from "~/lib/pluralize";
import { NodesGradientIcon } from "~/components/icons/mondoo/nodes-gradient";
import { PodsGradientIcon } from "~/components/icons/mondoo/pods-gradient";
import { ContainersGradientIcon } from "~/components/icons/mondoo/containers-gradient";
import { useSnackbar } from "notistack";
import { ConfirmDeleteDialog } from "../components/confirm-delete-dialogue";
import { OthersGradientIcon } from "~/components/icons/mondoo/others-gradient";
import { FormatDate } from "~/lib/date";
import { GetInfoIcon } from "~/lib/info-icons";
import { getColor } from "~/lib/colors";
import { IamActions } from "~/lib/iam";
import { setDocumentTitle } from "~/utils/commonUtils";

type Integration =
  GetClientIntegrationQuery["clientIntegration"]["integration"];
type AssetCounts = NonNullable<Integration["assetCounts"]>;

type Props = {
  space: Space;
  availablePermissions: string[];
};

export function K8IntegrationPage({ space, availablePermissions }: Props) {
  const { integrationId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  let navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [searchParams, _setSearchParams] = useSearchParams();
  const mrn: string = `//integration.api.mondoo.app/spaces/${space.id}/integrations/${integrationId}`;
  const { data, error, loading } = useGetClientIntegrationQuery({
    variables: { mrn },
  });

  const deleteCommandData = useGetClientIntegrationDeleteCommandQuery({
    variables: { input: { mrn: space.mrn, type: ClientIntegrationType.K8S } },
  });

  const [deleteIntegration] = useDeleteClientIntegrationMutation({
    refetchQueries: [
      ListClientIntegrationsDocument,
      GetIntegrationsSummaryDocument,
    ],
  });

  if (error) {
    return <LoadingFailedPage what="Integration" />;
  }

  if (loading || !data?.clientIntegration.integration) {
    return <LoadingPage what="Integration" />;
  }

  const { integration } = data.clientIntegration;

  // integration
  let integrationStatus: Integration["status"] = integration.status;
  if (integration.status.toLowerCase() === "not_ready") {
    integrationStatus = "pending";
  }

  // need full iconset
  const getIcon = (name: string): JSX.Element => {
    switch (name.toLowerCase()) {
      case "containers":
        return <ContainersGradientIcon sx={{ fontSize: 40 }} />;
      case "nodes":
        return <NodesGradientIcon sx={{ fontSize: 40 }} />;
      case "pods":
        return <PodsGradientIcon sx={{ fontSize: 40 }} />;
      default:
        return <OthersGradientIcon sx={{ fontSize: 40 }} />;
    }
  };

  type CountsObject = { name: string; total: number; icon: JSX.Element };

  const groupsList = ["k8s.pod", "asset", "container_image"];
  const notedGroups =
    integration.assetCounts?.filter((x) => groupsList.includes(x.assetType)) ||
    [];
  const totalAssets =
    integration.assetCounts
      ?.filter((x) => x.assetType === "all")
      .map((y) => y.total)
      .pop() || 0;

  const buildAssetCounts = (group: AssetCounts): CountsObject[] => {
    const formatGroupName = (assetType: AssetCounts[0]["assetType"]) => {
      let formattedType: string;
      const regex = new RegExp("k8s.");

      switch (assetType.toLowerCase()) {
        case "asset":
          formattedType = "node";
          break;
        case "container_image":
          formattedType = "container";
          break;
        default:
          formattedType = assetType;
          break;
      }
      formattedType = pluralize(formattedType.replace(regex, ""), 2);
      return formattedType;
    };

    // Return the counts of each of our most interested groups
    // Pods, Nodes, and Containers
    const notedCounts = group
      .filter((x) => x.total > 0)
      .map((y) => {
        const name = formatGroupName(y?.assetType);
        const total = y?.total || 0;
        const icon = getIcon(name);
        return { name, total, icon };
      });

    // Calculate the value of all other groups.  We don't return all of them
    // in the API, so the formula for this is to subtract the total of our
    // Noted groups from the total of all existing groups.
    const totalNoted = notedCounts.reduce((agg, x) => agg + x.total, 0);
    const totalOther = totalAssets - totalNoted;
    const otherCounts = {
      name: "Others",
      total: totalOther,
      icon: getIcon("others"),
    };

    if (totalOther > 0) {
      return [...notedCounts, otherCounts];
    }
    return notedCounts;
  };

  const assetCounts = buildAssetCounts(notedGroups);

  // needs types -> codegen throwing error
  const buildEvaluatedConfigurations = (
    evaluatedConfigurations: Integration["evaluatedConfigurations"],
  ) => {
    if (!evaluatedConfigurations) return [];

    const transformConfigName = (name?: string | null) => {
      if (!name) return "";
      return name.split(/(?=[A-Z])/).join(" ");
    };

    const sortByName = (a: string, b: string): number => {
      if (a > b) {
        return 1;
      } else if (a < b) {
        return -1;
      } else {
        return 0;
      }
    };

    const evaled = evaluatedConfigurations.map((x) => {
      const mismatch = Boolean(x.configured !== x.applied);

      let configStatus = "Pending";
      if (x.configured === true) {
        configStatus = "Enabled";
      } else if (x.configured === false) {
        configStatus = "Disabled";
      }

      return {
        id: x.option,
        name: transformConfigName(x?.option),
        mismatch,
        configStatus,
        // Message/Status regarding possible mismatch of settings that
        // have been configured but not yet applied
        evaledStatus: x.msgStatus,
        evaledMsg: x.msg,
        // Message/Status regarding possible errors or warnings coming from
        // integration checkins
        operatorStatus: x.operatorMsgStatus || "NONE",
        operatorMsg: x.operatorMsg,
      };
    });

    evaled.sort((a, b) => sortByName(a.name, b.name));

    // Add the Operator version to the set of configurations
    // because its data is coming from a different portion of the API
    evaled.unshift({
      id: "OperatorVersion",
      name: "Operator Version",
      mismatch: false,
      configStatus: integration.lastStateInfo?.operatorVersion || "unknown",
      evaledStatus: IntegrationMessageStatus.Info,
      evaledMsg: "",
      operatorStatus: IntegrationMessageStatus.Info,
      operatorMsg: integration.lastStateInfo?.operatorVersion || "unknown",
    });

    return evaled;
  };

  const evaluatedConfigs = buildEvaluatedConfigurations(
    integration?.evaluatedConfigurations,
  );

  const nameSpaceDisplay = integration.lastStateInfo?.namespace
    ? `${integration.lastStateInfo.namespace} / `
    : "";

  const getAssetsUrl = (integrationMrn: string) => {
    const queryterms = JSON.stringify({
      "mondoo.com/integration-mrn": integrationMrn,
    });
    searchParams.set("queryTerms", queryterms);
    return `/space/inventory?${searchParams}`;
  };

  // Delete Functions
  const DELETE_COPY =
    "To remove the Mondoo Kubernetes Integration, paste the following code into your CLI:\n\n```bash\n%COMMAND%\n```\n";

  const handleDelete = async () => {
    try {
      await deleteIntegration({
        variables: { input: { mrn: integration.mrn } },
      });
      enqueueSnackbar(`Successfully removed Kubernetes cluster from Mondoo`, {
        variant: "success",
      });
      navigate(`/space/integrations/kubernetes?spaceId=${space.id}`);
    } catch {
      enqueueSnackbar(`Could not remove Kubernetes cluster from Mondoo`, {
        variant: "error",
      });
    }
  };

  const deleteDetails = () => {
    const deleteCommand =
      deleteCommandData.data?.getClientIntegrationDeleteCommand.message;
    const finalCommand = deleteCommand
      ? deleteCommand
      : "Error retrieving command";
    const finalCopy = DELETE_COPY.replace(/\%COMMAND\%/g, finalCommand);
    return <Markdown source={finalCopy} />;
  };

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?spaceId=${space.id}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Link
      key="/space/integrations"
      component={RouterLink}
      to={`/space/integrations?spaceId=${space.id}`}
    >
      Integrations
    </Link>,
    <Link
      key="/space/integrations/kubernetes"
      component={RouterLink}
      to={`/space/integrations/kubernetes?spaceId=${space.id}`}
    >
      Kubernetes
    </Link>,
    // Namespace will only display if present
    // > <namespace> / <name>
    <Typography key={1} color="text.primary">
      {nameSpaceDisplay}
      {integration.name}
    </Typography>,
  ];

  const hasUpdateK8IntegrationsPermission = availablePermissions?.includes(
    IamActions.INTEGRATIONS_INTEGRATIONSMANAGER_UPDATE,
  );

  const hasError =
    integration.messages?.find(
      (message) => message.status === IntegrationMessageStatus.Error,
    ) ||
    integration.evaluatedConfigurations?.find(
      (config) => config.operatorMsgStatus === IntegrationMessageStatus.Error,
    );

  // If we have an error, we need to extract it from the integration messages
  // or the evaluated configurations
  let errorMsg = null;
  if (
    hasError?.__typename === "IntegrationMessage" &&
    hasError.message.length > 0
  ) {
    errorMsg = hasError?.message;
  } else if (hasError?.__typename === "EvaluatedConfiguration") {
    errorMsg = hasError?.operatorMsg;
  }

  setDocumentTitle([integration.name, "Kubernetes", "Integrations"]);

  return (
    <Box>
      <Breadcrumbs sx={{ mb: 5 }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>
      {/* Header Block */}
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "inherit", sm: "flex-start" },
          justifyContent: "space-between",
          mb: 6.5,
        }}
      >
        {errorMsg && (
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Alert severity="error" variant="outlined">
              <AlertTitle>Error</AlertTitle>
              {capitalizeFirstLetter(errorMsg)}
            </Alert>
          </Grid>
        )}
        <Grid container item xs sm={12} md={8}>
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <Typography
              variant="h1"
              fontSize={32}
              fontWeight={700}
              sx={{ mr: 4, textTransform: "uppercase", lineHeight: "inherit" }}
            >
              {integration.name}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ mb: 1 }}>
            <ContentFlag flag={integrationStatus} color={integration.status} />
          </Grid>

          <Grid container item columnGap={2} mb={{ sm: 1, md: 0 }}>
            <Grid item xs={12} sm="auto">
              <AccessTimeIcon
                sx={{
                  fontSize: 16,
                  height: 28,
                  color: "text.secondary",
                  verticalAlign: "middle",
                }}
              />
              <Typography
                display="inline"
                variant="caption"
                color="text.secondary"
                sx={{ ml: 0.75 }}
              >
                {/* Last scanned: April 05, 10:10am */}
                Last scanned: {FormatDate(integration.lastCheckin)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm="auto">
              {integration.createdBy?.name != "" && (
                <Fragment>
                  <PersonIcon
                    sx={{
                      fontSize: 16,
                      height: 28,
                      color: "text.secondary",
                      verticalAlign: "middle",
                    }}
                  />
                  <Typography
                    display="inline"
                    variant="caption"
                    color="text.secondary"
                    sx={{ ml: 0.75 }}
                  >
                    Created by: {integration.createdBy?.name}
                  </Typography>
                </Fragment>
              )}
            </Grid>
          </Grid>
        </Grid>
        {hasUpdateK8IntegrationsPermission && (
          // Don't show the delete or edit buttons if a user does not have permission
          <Grid item sx={{ mt: { xs: 2, sm: 0 }, alignSelf: "flex-start" }}>
            <Button
              variant="outlined"
              color="error"
              onClick={() => setDialogOpen(true)}
            >
              Delete
            </Button>
            <Button
              component={RouterLink}
              to={`/space/integrations/kubernetes/${integrationId}/edit?spaceId=${space.id}`}
              variant="outlined"
              color="secondary"
              sx={{ ml: 2 }}
            >
              Edit Configuration
            </Button>
          </Grid>
        )}
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          alignItems: { xs: "flex-start", sm: "center" },
          justifyContent: "space-between",
          mb: 4,
        }}
      >
        <Typography variant="h5" component="h2" fontWeight={700}>
          Integration Details
        </Typography>
        {/* If the integration status is pending, we don't want to show a link to assets */}
        {integrationStatus !== "pending" && totalAssets > 0 && (
          <Button
            color="secondary"
            component={RouterLink}
            to={getAssetsUrl(integration.mrn)}
            endIcon={<ArrowForwardIcon />}
          >
            Show Assets
          </Button>
        )}
      </Box>
      {/* Groupings */}
      <Grid container component={Paper} mb={3} p={1}>
        <Grid item xs={12} sx={{ p: 2 }}>
          <Typography
            variant="body2"
            fontWeight={500}
            sx={{
              textTransform: "uppercase",
              borderBottom: "1px solid",
              borderColor: (theme) => theme.palette.background.lightest,
              pb: 2,
            }}
          >
            Discovered Resources ({totalAssets})
          </Typography>
        </Grid>
        {totalAssets < 1 ? (
          <NoDiscoveredAssets />
        ) : (
          assetCounts?.map((x) => (
            <Grid item key={x.name} xs={12} sm={6} md={3}>
              <Grid container sx={{ p: 2 }}>
                <Grid item sx={{ mr: 2 }}>
                  {x.icon}
                </Grid>
                <Grid item xs>
                  <Typography
                    fontWeight={700}
                    sx={{ mb: -0.75, textTransform: "capitalize" }}
                  >
                    {x.name}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    textTransform="uppercase"
                  >
                    {x.total} Discovered
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))
        )}
      </Grid>
      {/* Configuration and Overview Information */}
      <Paper sx={{ p: 3, mb: 3 }}>
        <Grid
          container
          columnSpacing={{ xs: 0, sm: 3 }}
          rowSpacing={{ xs: 3, sm: 0 }}
        >
          <Grid item xs={12} sm={6}>
            <KubernetesDataTable title="Operator Configuration">
              {evaluatedConfigs.map((config) => (
                <TableRow key={config.id}>
                  <TableCell
                    sx={{
                      pt: 1,
                      pl: 0,
                      pb: 0,
                    }}
                    width="50%"
                  >
                    {config.name}
                  </TableCell>
                  <TableCell
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      pt: 1,
                      pl: 0,
                      pb: 0,
                    }}
                    width="50%"
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        display: "inline-block",
                        mr: 1,
                        color: (theme) =>
                          config.mismatch
                            ? modifiedGetColor(theme, config.evaledStatus)
                            : "text.primary",
                      }}
                    >
                      {config.configStatus}
                    </Typography>
                    <Tooltip
                      title={config.evaledMsg || ""}
                      placement="top"
                      arrow
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {config.mismatch && (
                          <GetInfoIcon status={config.evaledStatus} />
                        )}
                      </Box>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </KubernetesDataTable>
          </Grid>
          {/* Labels and Tags */}
          <Grid item xs={12} sm={6}>
            <KubernetesDataTable title="Platform Overview Information">
              <TableRow>
                <TableCell
                  sx={{ pt: 1, pl: 0, pb: 0, color: "text.secondary" }}
                  width="50%"
                >
                  Kubernetes Version
                </TableCell>
                <TableCell sx={{ pt: 1, pl: 0, pb: 0 }} width="50%">
                  {integration.lastStateInfo?.k8sVersion}
                </TableCell>
              </TableRow>
            </KubernetesDataTable>
          </Grid>
        </Grid>
      </Paper>

      {/* Service Accounts */}
      <NestedServiceAccountsSection {...{ space, mrn, availablePermissions }} />
      {/* Delete Confirmation Dialog */}
      <ConfirmDeleteDialog
        id={`confirm-delete-single-k8-item`}
        value={"hello"}
        open={dialogOpen}
        onCancel={() => setDialogOpen(false)}
        onConfirm={handleDelete}
        title="Remove Kubernetes Integration"
        content={deleteDetails()}
      />
    </Box>
  );
}

const NoDiscoveredAssets = () => {
  return (
    <Grid
      item
      key="empty"
      xs={12}
      sx={{
        p: 2,
        display: "flex",
        placeContent: "center",
      }}
    >
      You do not currently have any discovered assets
    </Grid>
  );
};

// Standard styling for each of the Table sections
type DataTableProps = {
  title: string;
  children: ReactNode;
};
const KubernetesDataTable = ({ title, children }: DataTableProps) => {
  return (
    <TableContainer>
      <Table
        sx={{
          "tr:hover": {
            background: "inherit",
          },
        }}
      >
        <TableHead sx={{ backgroundColor: "inherit" }}>
          <TableRow
            sx={{
              "&:hover": {
                pointerEvents: "none",
                background: "inherit",
              },
            }}
          >
            <TableCell
              colSpan={2}
              sx={{
                pt: 0,
                pl: 0,
                pb: 1,
                borderBottom: (theme) =>
                  `1px solid ${theme.palette.background.lightest}`,
                textTransform: "uppercase",
              }}
            >
              {title}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};

// We've added a new blue color to our palette but it doesn't yet appear
// anywhere else, so we're modifying the getColor function for this specific
// use case
const modifiedGetColor = (
  theme: Theme,
  status?: IntegrationMessageStatus | null,
) => {
  if (!status) return getColor(theme, "primaryText");
  switch (status.toLowerCase()) {
    case "info":
      return "#29B6F6";
    default:
      return getColor(theme, status);
  }
};
