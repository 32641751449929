import { DocumentType } from "~/operations";
import { Space } from "~/lib/types";
import { isFeatureEnabled } from "~/login/features";

interface ReportType {
  title: string;
  description: string;
  type: DocumentType;
}

export const REPORT_TYPES: ReportType[] = [
  ...(isFeatureEnabled("Reporting")
    ? [
        {
          title: "Assets report",
          description:
            "Consolidate the overall security posture of all assets in one document.",
          type: DocumentType.SecurityReport,
        },
        {
          title: "Vulnerabilities report",
          description:
            "Consolidate all vulnerabilities and advisories in this space into one document.",
          type: DocumentType.VulnerabilitiesReport,
        },
        {
          title: "Checks export",
          description:
            "Consolidate all misconfigurations in this space into one document.",
          type: DocumentType.ChecksReport,
        },
      ]
    : []),
  {
    title: "Data export",
    description:
      "Export all the data in your space as a .csv file to use with third party software, such as Microsoft Excel or Airtable.",
    type: DocumentType.Export,
  },
];

export const getDocumentTypeText = (type: DocumentType) => {
  switch (type) {
    case DocumentType.ChecksReport:
      return "Checks";
    case DocumentType.CheckReport:
      return "Check";
    case DocumentType.Export:
      return "Export";
    case DocumentType.SecurityReport:
      return "Assets";
    case DocumentType.VulnerabilitiesReport:
      return "Vulnerabilities";
    case DocumentType.FrameworkReport:
      return "Framework";
    case DocumentType.ControlReport:
      return "Control";
    case DocumentType.AssetReport:
      return "Asset";
    case DocumentType.AdvisoryReport:
      return "Advisory";
    case DocumentType.VulnerabilityReport:
      return "Vulnerability";
    default:
      return "";
  }
};

export interface SelectedReport {
  title: string;
  type: DocumentType;
}

export const buildReportObject = (
  selectedReport: SelectedReport | null,
  space: Space,
) => {
  const type = selectedReport?.type || DocumentType.Export;

  const baseProps = {
    documentType: type,
    title: selectedReport?.title,
    space,
  };

  switch (type) {
    case DocumentType.AssetReport:
      return {
        ...baseProps,
        assetMrn: space.mrn,
      };
    case DocumentType.ChecksReport:
    case DocumentType.VulnerabilitiesReport:
    case DocumentType.Export:
      return baseProps;
    default:
      return baseProps;
  }
};

// Title Format: {hyphenated-title}_{YYYY-MM-DD}-{HHMM}
// Example: Data-export_2025-03-12-1624
export const getReportTitle = (title: string): string => {
  const date = new Date();
  const formattedDate = date.toISOString().split("T")[0];
  const formattedTime = date
    .toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    })
    .replace(":", "");

  const sanitizedTitle = title.replace(/\s+/g, "-");

  return `${sanitizedTitle}_${formattedDate}-${formattedTime}`;
};
